// From https://github.com/lichess-org/scalachess/blob/master/src/main/scala/opening/FullOpeningPartA.scala
// https://github.com/lichess-org/scalachess/blob/master/src/main/scala/opening/FullOpeningPartB.scala

export class FullOpening {
  constructor(public eco: string, public name: string, public fen: string, public uci: string, public pgn: string) {
  }
}

export const MaxNumberOfMove = 27;

export const FullOpenings = [
  new FullOpening("A00", "Amar Gambit", "rn1qkbnr/ppp2ppp/8/3p4/5p2/6PB/PPPPP2P/RNBQK2R w KQkq -", "g1h3 d7d5 g2g3 e7e5 f2f4 c8h3 f1h3 e5f4", "1. Nh3 d5 2. g3 e5 3. f4 Bxh3 4. Bxh3 exf4"),
  new FullOpening("A00", "Amar Opening", "rnbqkbnr/pppppppp/8/8/8/7N/PPPPPPPP/RNBQKB1R b KQkq -", "g1h3", "1. Nh3"),
  new FullOpening("A00", "Amar Opening: Gent Gambit", "rn1qkbnr/ppp2ppp/8/3p4/8/6PB/PPPPP3/RNBQ1RK1 b kq -", "g1h3 d7d5 g2g3 e7e5 f2f4 c8h3 f1h3 e5f4 e1g1 f4g3 h2g3", "1. Nh3 d5 2. g3 e5 3. f4 Bxh3 4. Bxh3 exf4 5. O-O fxg3 6. hxg3"),
  new FullOpening("A00", "Amar Opening: Paris Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/5P2/6PN/PPPPP2P/RNBQKB1R b KQkq -", "g1h3 d7d5 g2g3 e7e5 f2f4", "1. Nh3 d5 2. g3 e5 3. f4"),
  new FullOpening("A00", "Amsterdam Attack", "r1bqkb1r/ppp2ppp/2np1n2/4p3/2P5/1PN1P3/P2P1PPP/R1BQKBNR w KQkq -", "e2e3 e7e5 c2c4 d7d6 b1c3 b8c6 b2b3 g8f6", "1. e3 e5 2. c4 d6 3. Nc3 Nc6 4. b3 Nf6"),
  new FullOpening("A00", "Anderssen's Opening", "rnbqkbnr/pppppppp/8/8/8/P7/1PPPPPPP/RNBQKBNR b KQkq -", "a2a3", "1. a3"),
  new FullOpening("A00", "Anderssen's Opening: Polish Gambit", "rnbqkbnr/1ppppppp/8/p7/1P6/P7/2PPPPPP/RNBQKBNR b KQkq -", "a2a3 a7a5 b2b4", "1. a3 a5 2. b4"),
  new FullOpening("A00", "Barnes Opening: Fool's Mate", "rnb1kbnr/pppp1ppp/8/4p3/6Pq/5P2/PPPPP2P/RNBQKBNR w KQkq -", "f2f3 e7e5 g2g4 d8h4", "1. f3 e5 2. g4 Qh4#"),
  new FullOpening("A00", "Barnes Opening: Gedult Gambit", "rnbqkbnr/ppp1pp1p/6p1/8/3Pp3/2P2P2/PP4PP/RNBQKBNR b KQkq -", "f2f3 d7d5 e2e4 g7g6 d2d4 d5e4 c2c3", "1. f3 d5 2. e4 g6 3. d4 dxe4 4. c3"),
  new FullOpening("A00", "Barnes Opening: Gedult Gambit", "rnbqkbnr/ppppp1pp/8/8/4p3/2N2P2/PPPP2PP/R1BQKBNR b KQkq -", "f2f3 f7f5 e2e4 f5e4 b1c3", "1. f3 f5 2. e4 fxe4 3. Nc3"),
  new FullOpening("A00", "Barnes Opening: Hammerschlag", "rnbqkbnr/pppp1ppp/8/4p3/8/5P2/PPPPPKPP/RNBQ1BNR b kq -", "f2f3 e7e5 e1f2", "1. f3 e5 2. Kf2"),
  new FullOpening("A00", "Clemenz Opening", "rnbqkbnr/pppppppp/8/8/8/7P/PPPPPPP1/RNBQKBNR b KQkq -", "h2h3", "1. h3"),
  new FullOpening("A00", "Clemenz Opening: Spike Lee Gambit", "rnbqkbnr/ppppppp1/8/7p/6P1/7P/PPPPPP2/RNBQKBNR b KQkq -", "h2h3 h7h5 g2g4", "1. h3 h5 2. g4"),
  new FullOpening("A00", "Crab Opening", "rnbqkbnr/pppp1ppp/8/4p3/P6P/8/1PPPPPP1/RNBQKBNR b KQkq -", "a2a4 e7e5 h2h4", "1. a4 e5 2. h4"),
  new FullOpening("A00", "Creepy Crawly Formation: Classical Defense", "rnbqkbnr/ppp2ppp/8/3pp3/8/P6P/1PPPPPP1/RNBQKBNR w KQkq -", "h2h3 d7d5 a2a3 e7e5", "1. h3 d5 2. a3 e5"),
  new FullOpening("A00", "Formation: Cabbage Attack", "r1bq1rk1/ppp2ppp/2nb1n2/3pp3/P7/1PP1P1P1/1B1P1P1P/RN1QKBNR b KQ -", "c2c3 e7e5 a2a3 d7d5 b2b3 g8f6 c1b2 b8c6 a3a4 f8d6 g2g3 e8g8 e2e3", "1. c3 e5 2. a3 d5 3. b3 Nf6 4. Bb2 Nc6 5. a4 Bd6 6. g3 O-O 7. e3"),
  new FullOpening("A00", "Formation: Hippopotamus Attack", "r1bq1rk1/ppp2ppp/2nb1n2/3pp3/8/PPPPPPP1/7P/RNBQKBNR b KQ -", "a2a3 e7e5 b2b3 d7d5 c2c3 g8f6 d2d3 b8c6 e2e3 f8d6 f2f3 e8g8 g2g3", "1. a3 e5 2. b3 d5 3. c3 Nf6 4. d3 Nc6 5. e3 Bd6 6. f3 O-O 7. g3"),
  new FullOpening("A00", "Formation: Shy Attack", "r1bq1rk1/ppp2ppp/2nb1n2/3pp3/8/P2PP1PP/1PPN1PB1/R1BQK1NR b KQ -", "a2a3 e7e5 g2g3 d7d5 f1g2 g8f6 d2d3 b8c6 b1d2 f8d6 e2e3 e8g8 h2h3", "1. a3 e5 2. g3 d5 3. Bg2 Nf6 4. d3 Nc6 5. Nd2 Bd6 6. e3 O-O 7. h3"),
  new FullOpening("A00", "Gedult's Opening", "rnbqkbnr/pppppppp/8/8/8/5P2/PPPPP1PP/RNBQKBNR b KQkq -", "f2f3", "1. f3"),
  new FullOpening("A00", "Global Opening", "rnbqkbnr/pppp1ppp/8/4p3/8/P6P/1PPPPPP1/RNBQKBNR b KQkq -", "h2h3 e7e5 a2a3", "1. h3 e5 2. a3"),
  new FullOpening("A00", "Grob Opening", "rnbqkbnr/pppppppp/8/8/6P1/8/PPPPPP1P/RNBQKBNR b KQkq -", "g2g4", "1. g4"),
  new FullOpening("A00", "Grob Opening: Alessi Gambit", "rnbqkbnr/ppppp1pp/8/5p2/6P1/8/PPPPPP1P/RNBQKBNR w KQkq -", "g2g4 f7f5", "1. g4 f5"),
  new FullOpening("A00", "Grob Opening: Double Grob", "rnbqkbnr/pppppp1p/8/6p1/6P1/8/PPPPPP1P/RNBQKBNR w KQkq -", "g2g4 g7g5", "1. g4 g5"),
  new FullOpening("A00", "Grob Opening: Double Grob, Coca-Cola Gambit", "rnbqkbnr/pppppp1p/8/6p1/5PP1/8/PPPPP2P/RNBQKBNR b KQkq -", "g2g4 g7g5 f2f4", "1. g4 g5 2. f4"),
  new FullOpening("A00", "Grob Opening: Grob Gambit", "rnbqkbnr/ppp1pppp/8/3p4/6P1/8/PPPPPPBP/RNBQK1NR b KQkq -", "g2g4 d7d5 f1g2", "1. g4 d5 2. Bg2"),
  new FullOpening("A00", "Grob Opening: Grob Gambit Declined", "rnbqkbnr/pp2pppp/2p5/3p4/6P1/8/PPPPPPBP/RNBQK1NR w KQkq -", "g2g4 d7d5 f1g2 c7c6", "1. g4 d5 2. Bg2 c6"),
  new FullOpening("A00", "Grob Opening: Grob Gambit, Basman Gambit", "rnbqkbnr/ppp1ppp1/8/3p3P/8/8/PPPPPPBP/RNBQK1NR b KQkq -", "g2g4 d7d5 f1g2 h7h5 g4h5", "1. g4 d5 2. Bg2 h5 3. gxh5"),
  new FullOpening("A00", "Grob Opening: Grob Gambit, Fritz Gambit", "rn1qkbnr/ppp1pppp/8/3p4/2P3b1/8/PP1PPPBP/RNBQK1NR b KQkq -", "g2g4 d7d5 f1g2 c8g4 c2c4", "1. g4 d5 2. Bg2 Bxg4 3. c4"),
  new FullOpening("A00", "Grob Opening: Grob Gambit, Fritz Gambit, Romford Countergambit", "q3kbnr/p1pnpppp/8/8/2Pp2b1/8/PP1PPP1P/RNBQK1NR w KQk -", "g2g4 d7d5 f1g2 c8g4 c2c4 d5d4 g2b7 b8d7 b7a8 d8a8", "1. g4 d5 2. Bg2 Bxg4 3. c4 d4 4. Bxb7 Nd7 5. Bxa8 Qxa8"),
  new FullOpening("A00", "Grob Opening: Grob Gambit, Keres Gambit", "rnbqkbnr/ppp2ppp/8/3p4/3p2P1/2P5/PP2PPBP/RNBQK1NR b KQkq -", "g2g4 d7d5 f1g2 e7e5 d2d4 e5d4 c2c3", "1. g4 d5 2. Bg2 e5 3. d4 exd4 4. c3"),
  new FullOpening("A00", "Grob Opening: Grob Gambit, Richter-Grob Gambit", "rnbqkbnr/pp2pppp/2p5/8/2p3P1/1P6/P2PPPBP/RNBQK1NR b KQkq -", "g2g4 d7d5 f1g2 c7c6 c2c4 d5c4 b2b3", "1. g4 d5 2. Bg2 c6 3. c4 dxc4 4. b3"),
  new FullOpening("A00", "Grob Opening: Keene Defense", "rnbqkbnr/pp3ppp/2p5/3pp3/6P1/7P/PPPPPPB1/RNBQK1NR w KQkq -", "g2g4 d7d5 h2h3 e7e5 f1g2 c7c6", "1. g4 d5 2. h3 e5 3. Bg2 c6"),
  new FullOpening("A00", "Grob Opening: Keene Defense, Main Line", "rnbqk2r/pp2nppp/2pb4/3p4/2PPp1P1/2N4P/PP2PPB1/R1BQK1NR w KQkq -", "g2g4 d7d5 h2h3 e7e5 f1g2 c7c6 d2d4 e5e4 c2c4 f8d6 b1c3 g8e7", "1. g4 d5 2. h3 e5 3. Bg2 c6 4. d4 e4 5. c4 Bd6 6. Nc3 Ne7"),
  new FullOpening("A00", "Grob Opening: London Defense", "r1bqkbnr/pppp1ppp/2n5/4p3/6P1/7P/PPPPPP2/RNBQKBNR w KQkq -", "g2g4 e7e5 h2h3 b8c6", "1. g4 e5 2. h3 Nc6"),
  new FullOpening("A00", "Grob Opening: Romford Countergambit", "rn1qkbnr/ppp1pppp/8/8/2Pp2b1/8/PP1PPPBP/RNBQK1NR w KQkq -", "g2g4 d7d5 f1g2 c8g4 c2c4 d5d4", "1. g4 d5 2. Bg2 Bxg4 3. c4 d4"),
  new FullOpening("A00", "Grob Opening: Spike Attack", "rnbqkbnr/pp2pppp/2p5/3p2P1/8/8/PPPPPPBP/RNBQK1NR b KQkq -", "g2g4 d7d5 f1g2 c7c6 g4g5", "1. g4 d5 2. Bg2 c6 3. g5"),
  new FullOpening("A00", "Grob Opening: Spike, Hurst Attack", "rnbqkbnr/ppp2ppp/8/3pp3/2P3P1/8/PP1PPPBP/RNBQK1NR b KQkq -", "g2g4 e7e5 f1g2 d7d5 c2c4", "1. g4 e5 2. Bg2 d5 3. c4"),
  new FullOpening("A00", "Grob Opening: Zilbermints Gambit", "rnbqkbnr/ppp1pppp/8/8/4p1P1/2N5/PPPP1P1P/R1BQKBNR b KQkq -", "g2g4 d7d5 e2e4 d5e4 b1c3", "1. g4 d5 2. e4 dxe4 3. Nc3"),
  new FullOpening("A00", "Grob Opening: Zilbermints Gambit, Schiller Defense", "rnbqkbnr/ppp1ppp1/8/7p/4p1P1/2N5/PPPP1P1P/R1BQKBNR w KQkq -", "g2g4 d7d5 e2e4 d5e4 b1c3 h7h5", "1. g4 d5 2. e4 dxe4 3. Nc3 h5"),
  new FullOpening("A00", "Grob Opening: Zilbermints Gambit, Zilbermints-Hartlaub Gambit", "rnbqkbnr/ppp2ppp/8/4p3/4p1P1/2NP4/PPP2P1P/R1BQKBNR b KQkq -", "g2g4 d7d5 e2e4 d5e4 b1c3 e7e5 d2d3", "1. g4 d5 2. e4 dxe4 3. Nc3 e5 4. d3"),
  new FullOpening("A00", "Hungarian Opening", "rnbqkbnr/pppppppp/8/8/8/6P1/PPPPPP1P/RNBQKBNR b KQkq -", "g2g3", "1. g3"),
  new FullOpening("A00", "Hungarian Opening: Asten Gambit", "r1bqkbnr/ppp3pp/2n5/4Pp2/3pN3/6P1/PPP1PP1P/R1BQKBNR w KQkq f6", "g2g3 b8c6 b1c3 d7d5 d2d4 e7e5 d4e5 d5d4 c3e4 f7f5", "1. g3 Nc6 2. Nc3 d5 3. d4 e5 4. dxe5 d4 5. Ne4 f5"),
  new FullOpening("A00", "Hungarian Opening: Burk Gambit", "rnbqk1nr/ppp1bppp/8/3p4/4p2N/P2P2P1/1PP1PP1P/RNBQKB1R b KQkq -", "g2g3 e7e5 a2a3 d7d5 g1f3 e5e4 f3h4 f8e7 d2d3", "1. g3 e5 2. a3 d5 3. Nf3 e4 4. Nh4 Be7 5. d3"),
  new FullOpening("A00", "Hungarian Opening: Bücker Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/1P6/6P1/P1PPPPBP/RNBQK1NR b KQkq -", "g2g3 d7d5 f1g2 e7e5 b2b4", "1. g3 d5 2. Bg2 e5 3. b4"),
  new FullOpening("A00", "Hungarian Opening: Catalan Formation", "rnbqkbnr/ppp2ppp/4p3/3p4/8/6P1/PPPPPPBP/RNBQK1NR w KQkq -", "g2g3 d7d5 f1g2 e7e6", "1. g3 d5 2. Bg2 e6"),
  new FullOpening("A00", "Hungarian Opening: Dutch Defense", "rnbqkbnr/ppppp1pp/8/5p2/8/6P1/PPPPPP1P/RNBQKBNR w KQkq -", "g2g3 f7f5", "1. g3 f5"),
  new FullOpening("A00", "Hungarian Opening: Indian Defense", "rnbqkb1r/pppppppp/5n2/8/8/6P1/PPPPPP1P/RNBQKBNR w KQkq -", "g2g3 g8f6", "1. g3 Nf6"),
  new FullOpening("A00", "Hungarian Opening: Myers Defense", "rnbqkbnr/pppppp1p/8/6p1/8/6P1/PPPPPP1P/RNBQKBNR w KQkq -", "g2g3 g7g5", "1. g3 g5"),
  new FullOpening("A00", "Hungarian Opening: Pachman Gambit", "rnbqkbnr/ppppp2p/6p1/7Q/4p3/6P1/PPPP1P1P/RNB1KBNR w KQkq -", "g2g3 f7f5 e2e4 f5e4 d1h5 g7g6", "1. g3 f5 2. e4 fxe4 3. Qh5+ g6"),
  new FullOpening("A00", "Hungarian Opening: Paris Gambit", "rn1qkbnr/ppp2ppp/8/3p4/5p2/6PB/PPPPP2P/RNBQ1RK1 b kq -", "g2g3 e7e5 g1h3 d7d5 f2f4 c8h3 f1h3 e5f4 e1g1", "1. g3 e5 2. Nh3 d5 3. f4 Bxh3 4. Bxh3 exf4 5. O-O"),
  new FullOpening("A00", "Hungarian Opening: Reversed Alekhine", "rnbqkbnr/pppp1ppp/8/4p3/8/5NP1/PPPPPP1P/RNBQKB1R b KQkq -", "g2g3 e7e5 g1f3", "1. g3 e5 2. Nf3"),
  new FullOpening("A00", "Hungarian Opening: Reversed Brooklyn Defense, Brooklyn Benko Gambit", "rnbqkb1r/pppp1ppp/5n2/8/1P2p3/6P1/P1PPPP1P/RNBQKBNR b KQkq -", "g2g3 e7e5 g1f3 e5e4 f3g1 g8f6 b2b4", "1. g3 e5 2. Nf3 e4 3. Ng1 Nf6 4. b4"),
  new FullOpening("A00", "Hungarian Opening: Reversed Modern Defense", "rnbqkbnr/pp2pppp/8/2pp4/8/6P1/PPPPPPBP/RNBQK1NR w KQkq -", "g2g3 d7d5 f1g2 c7c5", "1. g3 d5 2. Bg2 c5"),
  new FullOpening("A00", "Hungarian Opening: Reversed Norwegian Defense", "rnbqkbnr/pppp1ppp/8/8/4p2N/6P1/PPPPPP1P/RNBQKB1R b KQkq -", "g2g3 e7e5 g1f3 e5e4 f3h4", "1. g3 e5 2. Nf3 e4 3. Nh4"),
  new FullOpening("A00", "Hungarian Opening: Sicilian Invitation", "rnbqkbnr/pp1ppppp/8/2p5/8/6P1/PPPPPP1P/RNBQKBNR w KQkq -", "g2g3 c7c5", "1. g3 c5"),
  new FullOpening("A00", "Hungarian Opening: Slav Formation", "rnbqkbnr/pp2pppp/2p5/3p4/8/6P1/PPPPPPBP/RNBQK1NR w KQkq -", "g2g3 d7d5 f1g2 c7c6", "1. g3 d5 2. Bg2 c6"),
  new FullOpening("A00", "Hungarian Opening: Symmetrical Variation", "rnbqkbnr/pppppp1p/6p1/8/8/6P1/PPPPPP1P/RNBQKBNR w KQkq -", "g2g3 g7g6", "1. g3 g6"),
  new FullOpening("A00", "Hungarian Opening: Van Kuijk Gambit", "rnbqkbnr/ppppppp1/8/8/7p/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g2g3 h7h5 g1f3 h5h4", "1. g3 h5 2. Nf3 h4"),
  new FullOpening("A00", "Hungarian Opening: Winterberg Gambit", "rnbqkbnr/ppp2ppp/8/4p3/2p5/1P4P1/P2PPPBP/RNBQK1NR b KQkq -", "g2g3 d7d5 f1g2 e7e5 c2c4 d5c4 b2b3", "1. g3 d5 2. Bg2 e5 3. c4 dxc4 4. b3"),
  new FullOpening("A00", "Kádas Opening", "rnbqkbnr/pppppppp/8/8/7P/8/PPPPPPP1/RNBQKBNR b KQkq -", "h2h4", "1. h4"),
  new FullOpening("A00", "Kádas Opening: Beginner's Trap", "rnbqkbnr/ppp1pppp/8/3p4/7P/7R/PPPPPPP1/RNBQKBN1 b Qkq -", "h2h4 d7d5 h1h3", "1. h4 d5 2. Rh3"),
  new FullOpening("A00", "Kádas Opening: Kádas Gambit", "rnbqkbnr/pp1ppppp/8/2p5/1P5P/8/P1PPPPP1/RNBQKBNR b KQkq -", "h2h4 c7c5 b2b4", "1. h4 c5 2. b4"),
  new FullOpening("A00", "Kádas Opening: Kádas Gambit", "rnbqkbnr/pp2pppp/8/3p4/3p3P/2P2N2/PP2PPP1/RNBQKB1R b KQkq -", "h2h4 d7d5 d2d4 c7c5 g1f3 c5d4 c2c3", "1. h4 d5 2. d4 c5 3. Nf3 cxd4 4. c3"),
  new FullOpening("A00", "Kádas Opening: Kádas Gambit", "rnbqkbnr/pppp1ppp/8/8/3p3P/2P5/PP2PPP1/RNBQKBNR b KQkq -", "h2h4 e7e5 d2d4 e5d4 c2c3", "1. h4 e5 2. d4 exd4 3. c3"),
  new FullOpening("A00", "Kádas Opening: Myers Variation", "rnbqkbnr/pp2pppp/8/2pp4/3PP2P/8/PPP2PP1/RNBQKBNR b KQkq -", "h2h4 d7d5 d2d4 c7c5 e2e4", "1. h4 d5 2. d4 c5 3. e4"),
  new FullOpening("A00", "Kádas Opening: Schneider Gambit", "rnbqkbnr/pppppp1p/8/6p1/7P/8/PPPPPPP1/RNBQKBNR w KQkq -", "h2h4 g7g5", "1. h4 g5"),
  new FullOpening("A00", "Kádas Opening: Steinbok Gambit", "rnbqkbnr/ppppp1pp/8/8/4p2P/3P4/PPP2PP1/RNBQKBNR b KQkq -", "h2h4 f7f5 e2e4 f5e4 d2d3", "1. h4 f5 2. e4 fxe4 3. d3"),
  new FullOpening("A00", "Lasker Simul Special", "rnbqkbnr/ppppppp1/8/7p/8/6P1/PPPPPP1P/RNBQKBNR w KQkq -", "g2g3 h7h5", "1. g3 h5"),
  new FullOpening("A00", "Mieses Opening", "rnbqkbnr/pppppppp/8/8/8/3P4/PPP1PPPP/RNBQKBNR b KQkq -", "d2d3", "1. d3"),
  new FullOpening("A00", "Mieses Opening: Myers Spike Attack", "rnbqkbnr/pppppp1p/6p1/8/6P1/3P4/PPP1PP1P/RNBQKBNR b KQkq -", "d2d3 g7g6 g2g4", "1. d3 g6 2. g4"),
  new FullOpening("A00", "Mieses Opening: Reversed Rat", "rnbqkbnr/pppp1ppp/8/4p3/8/3P4/PPP1PPPP/RNBQKBNR w KQkq -", "d2d3 e7e5", "1. d3 e5"),
  new FullOpening("A00", "Polish Opening", "rnbqkbnr/pppppppp/8/8/1P6/8/P1PPPPPP/RNBQKBNR b KQkq -", "b2b4", "1. b4"),
  new FullOpening("A00", "Polish Opening: Baltic Defense", "rn1qkbnr/ppp1pppp/8/3p1b2/1P6/8/PBPPPPPP/RN1QKBNR w KQkq -", "b2b4 d7d5 c1b2 c8f5", "1. b4 d5 2. Bb2 Bf5"),
  new FullOpening("A00", "Polish Opening: Birmingham Gambit", "rnbqkbnr/pp1ppppp/8/2p5/1P6/8/P1PPPPPP/RNBQKBNR w KQkq -", "b2b4 c7c5", "1. b4 c5"),
  new FullOpening("A00", "Polish Opening: Bugayev Advance Variation", "rnbqkbnr/pppp2pp/5p2/1P2p3/8/8/PBPPPPPP/RN1QKBNR b KQkq -", "b2b4 e7e5 c1b2 f7f6 b4b5", "1. b4 e5 2. Bb2 f6 3. b5"),
  new FullOpening("A00", "Polish Opening: Bugayev Attack", "rnbqkbnr/pppp1ppp/8/4p3/1P6/P7/2PPPPPP/RNBQKBNR b KQkq -", "b2b4 e7e5 a2a3", "1. b4 e5 2. a3"),
  new FullOpening("A00", "Polish Opening: Czech Defense", "rnbqkbnr/ppp2ppp/3p4/4p3/1P6/8/PBPPPPPP/RN1QKBNR w KQkq -", "b2b4 e7e5 c1b2 d7d6", "1. b4 e5 2. Bb2 d6"),
  new FullOpening("A00", "Polish Opening: Dutch Defense", "rnbqkbnr/ppppp1pp/8/5p2/1P6/8/P1PPPPPP/RNBQKBNR w KQkq -", "b2b4 f7f5", "1. b4 f5"),
  new FullOpening("A00", "Polish Opening: German Defense", "rnb1kbnr/ppp1pppp/3q4/3p4/1P6/8/PBPPPPPP/RN1QKBNR w KQkq -", "b2b4 d7d5 c1b2 d8d6", "1. b4 d5 2. Bb2 Qd6"),
  new FullOpening("A00", "Polish Opening: Grigorian Variation", "r1bqkbnr/pppppppp/2n5/8/1P6/8/P1PPPPPP/RNBQKBNR w KQkq -", "b2b4 b8c6", "1. b4 Nc6"),
  new FullOpening("A00", "Polish Opening: Karniewski Variation", "rnbqkb1r/pppppppp/7n/8/1P6/8/P1PPPPPP/RNBQKBNR w KQkq -", "b2b4 g8h6", "1. b4 Nh6"),
  new FullOpening("A00", "Polish Opening: King's Indian Variation", "rnbqkb1r/pppppp1p/5np1/8/1P6/8/PBPPPPPP/RN1QKBNR w KQkq -", "b2b4 g8f6 c1b2 g7g6", "1. b4 Nf6 2. Bb2 g6"),
  new FullOpening("A00", "Polish Opening: King's Indian Variation, Schiffler Attack", "rnbqkb1r/pppppp1p/5np1/8/1P2P3/8/PBPP1PPP/RN1QKBNR b KQkq -", "b2b4 g8f6 c1b2 g7g6 e2e4", "1. b4 Nf6 2. Bb2 g6 3. e4"),
  new FullOpening("A00", "Polish Opening: King's Indian Variation, Sokolsky Attack", "rnbq1rk1/ppp1ppbp/3p1np1/8/1PPP4/4PN2/PB3PPP/RN1QKB1R b KQ -", "b2b4 g8f6 c1b2 g7g6 c2c4 f8g7 e2e3 d7d6 g1f3 e8g8 d2d4", "1. b4 Nf6 2. Bb2 g6 3. c4 Bg7 4. e3 d6 5. Nf3 O-O 6. d4"),
  new FullOpening("A00", "Polish Opening: Myers Variation", "rnbqkbnr/pp2pppp/2p5/3p4/PP6/8/1BPPPPPP/RN1QKBNR b KQkq -", "b2b4 d7d5 c1b2 c7c6 a2a4", "1. b4 d5 2. Bb2 c6 3. a4"),
  new FullOpening("A00", "Polish Opening: Orangutan-Diemer Gambit", "rnb1kbnr/ppp2ppp/3q4/4p3/1P2p3/P4P2/1BPP2PP/RN1QKBNR b KQkq -", "b2b4 d7d5 c1b2 d8d6 a2a3 e7e5 e2e4 d5e4 f2f3", "1. b4 d5 2. Bb2 Qd6 3. a3 e5 4. e4 dxe4 5. f3"),
  new FullOpening("A00", "Polish Opening: Orangutan-Hartlaub Gambit", "rnbqkb1r/1p3ppp/2p1pn2/P2p4/4P3/P2P4/1BP2PPP/RN1QKBNR b KQkq -", "b2b4 g8f6 c1b2 e7e6 a2a3 c7c6 d2d3 a7a5 b4a5 d7d5 e2e4", "1. b4 Nf6 2. Bb2 e6 3. a3 c6 4. d3 a5 5. bxa5 d5 6. e4"),
  new FullOpening("A00", "Polish Opening: Outflank Variation", "rnbqkbnr/pp1ppppp/2p5/8/1P6/8/P1PPPPPP/RNBQKBNR w KQkq -", "b2b4 c7c6", "1. b4 c6"),
  new FullOpening("A00", "Polish Opening: Queen's Indian Variation", "rnbqkb1r/p1pp1ppp/1p2pn2/1P6/8/8/PBPPPPPP/RN1QKBNR w KQkq -", "b2b4 e7e6 c1b2 g8f6 b4b5 b7b6", "1. b4 e6 2. Bb2 Nf6 3. b5 b6"),
  new FullOpening("A00", "Polish Opening: Queenside Defense", "rnbqkb1r/1ppp1ppp/p3pn2/1P6/8/8/PBPPPPPP/RN1QKBNR w KQkq -", "b2b4 e7e6 c1b2 g8f6 b4b5 a7a6", "1. b4 e6 2. Bb2 Nf6 3. b5 a6"),
  new FullOpening("A00", "Polish Opening: Rooks Swap Line", "1nbqkb1r/1ppp1ppp/4pn2/1P6/8/8/2PPPPPP/BN1QKBNR b Kk -", "b2b4 e7e6 c1b2 g8f6 b4b5 a7a6 a2a4 a6b5 a4b5 a8a1 b2a1", "1. b4 e6 2. Bb2 Nf6 3. b5 a6 4. a4 axb5 5. axb5 Rxa1 6. Bxa1"),
  new FullOpening("A00", "Polish Opening: Schiffler-Sokolsky Variation", "rnbqkb1r/ppp2ppp/4pn2/1P1p4/8/4P3/PBPP1PPP/RN1QKBNR b KQkq -", "b2b4 e7e6 c1b2 g8f6 b4b5 d7d5 e2e3", "1. b4 e6 2. Bb2 Nf6 3. b5 d5 4. e3"),
  new FullOpening("A00", "Polish Opening: Schuehler Gambit", "rnbqkbnr/1p1ppppp/8/pp6/4P3/8/PBPP1PPP/RN1QKBNR b KQkq -", "b2b4 c7c6 c1b2 a7a5 b4b5 c6b5 e2e4", "1. b4 c6 2. Bb2 a5 3. b5 cxb5 4. e4"),
  new FullOpening("A00", "Polish Opening: Symmetrical Variation", "rnbqkbnr/p1pppppp/8/1p6/1P6/8/P1PPPPPP/RNBQKBNR w KQkq -", "b2b4 b7b5", "1. b4 b5"),
  new FullOpening("A00", "Polish Opening: Tartakower Gambit", "rnbqkbnr/pppp2pp/5p2/4p3/1P2P3/8/PBPP1PPP/RN1QKBNR b KQkq -", "b2b4 e7e5 c1b2 f7f6 e2e4", "1. b4 e5 2. Bb2 f6 3. e4"),
  new FullOpening("A00", "Polish Opening: Tartakower Gambit, Brinckmann Variation", "r1b1k1nr/ppppq2p/2n2pp1/4pP2/1bB1P3/8/PBPP2PP/RN1QK1NR w KQkq -", "b2b4 e7e5 c1b2 f7f6 e2e4 f8b4 f1c4 b8c6 f2f4 d8e7 f4f5 g7g6", "1. b4 e5 2. Bb2 f6 3. e4 Bxb4 4. Bc4 Nc6 5. f4 Qe7 6. f5 g6"),
  new FullOpening("A00", "Polish Opening: Wolferts Gambit", "rnbqkbnr/pp1p1ppp/8/2p1p3/1P6/8/PBPPPPPP/RN1QKBNR w KQkq -", "b2b4 e7e5 c1b2 c7c5", "1. b4 e5 2. Bb2 c5"),
  new FullOpening("A00", "Saragossa Opening", "rnbqkbnr/pppppppp/8/8/8/2P5/PP1PPPPP/RNBQKBNR b KQkq -", "c2c3", "1. c3"),
  new FullOpening("A00", "Sodium Attack", "rnbqkbnr/pppppppp/8/8/8/N7/PPPPPPPP/R1BQKBNR b KQkq -", "b1a3", "1. Na3"),
  new FullOpening("A00", "Sodium Attack: Celadon Variation", "rnbqk1nr/pp3ppp/8/2ppp3/8/P2PP3/P1P2PPP/1RBQKBNR b Kkq -", "b1a3 e7e5 d2d3 f8a3 b2a3 d7d5 e2e3 c7c5 a1b1", "1. Na3 e5 2. d3 Bxa3 3. bxa3 d5 4. e3 c5 5. Rb1"),
  new FullOpening("A00", "Sodium Attack: Chenoboskion Variation", "rnbqkbnr/pppppp1p/6p1/8/6P1/N7/PPPPPP1P/R1BQKBNR b KQkq -", "b1a3 g7g6 g2g4", "1. Na3 g6 2. g4"),
  new FullOpening("A00", "Sodium Attack: Durkin Gambit", "r1bqkbnr/pppp2pp/2n5/4pp2/2N1P3/8/PPPP1PPP/R1BQKBNR w KQkq -", "b1a3 e7e5 a3c4 b8c6 e2e4 f7f5", "1. Na3 e5 2. Nc4 Nc6 3. e4 f5"),
  new FullOpening("A00", "Valencia Opening", "rnbqkbnr/pppp1ppp/8/4p3/8/3P4/PPPNPPPP/R1BQKBNR b KQkq -", "d2d3 e7e5 b1d2", "1. d3 e5 2. Nd2"),
  new FullOpening("A00", "Van Geet Opening", "rnbqkbnr/pppppppp/8/8/8/2N5/PPPPPPPP/R1BQKBNR b KQkq -", "b1c3", "1. Nc3"),
  new FullOpening("A00", "Van Geet Opening: Battambang Variation", "rnbqkbnr/pppp1ppp/8/4p3/8/P1N5/1PPPPPPP/R1BQKBNR b KQkq -", "a2a3 e7e5 b1c3", "1. a3 e5 2. Nc3"),
  new FullOpening("A00", "Van Geet Opening: Billockus-Johansen Gambit", "rnbqk1nr/pppp1ppp/8/2b1p3/8/2N2N2/PPPPPPPP/R1BQKB1R w KQkq -", "b1c3 e7e5 g1f3 f8c5", "1. Nc3 e5 2. Nf3 Bc5"),
  new FullOpening("A00", "Van Geet Opening: Caro-Kann Variation, St. Patrick's Attack", "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N4P/PPPP1PP1/R1BQKBNR b KQkq -", "b1c3 d7d5 e2e4 c7c6 h2h3", "1. Nc3 d5 2. e4 c6 3. h3"),
  new FullOpening("A00", "Van Geet Opening: Damhaug Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/5P2/2N5/PPPPP1PP/R1BQKBNR w KQkq -", "b1c3 d7d5 f2f4 e7e5", "1. Nc3 d5 2. f4 e5"),
  new FullOpening("A00", "Van Geet Opening: Dougherty Gambit", "rnbqkbnr/ppp1pppp/8/8/4p3/2N2P2/PPPP2PP/R1BQKBNR b KQkq -", "b1c3 d7d5 e2e4 d5e4 f2f3", "1. Nc3 d5 2. e4 dxe4 3. f3"),
  new FullOpening("A00", "Van Geet Opening: Dunst-Perrenet Gambit", "rnbqkbnr/ppp1pppp/8/8/4p3/2NP4/PPP2PPP/R1BQKBNR b KQkq -", "b1c3 d7d5 e2e4 d5e4 d2d3", "1. Nc3 d5 2. e4 dxe4 3. d3"),
  new FullOpening("A00", "Van Geet Opening: Düsseldorf Gambit", "rnbqkbnr/pp1ppppp/8/2p5/1P6/2N5/P1PPPPPP/R1BQKBNR b KQkq -", "b1c3 c7c5 b2b4", "1. Nc3 c5 2. b4"),
  new FullOpening("A00", "Van Geet Opening: Gladbacher Gambit", "rnbqkbnr/ppp2ppp/8/4p3/4p3/1PNP4/P1P2PPP/R1BQKBNR b KQkq -", "b1c3 e7e5 b2b3 d7d5 e2e4 d5e4 d2d3", "1. Nc3 e5 2. b3 d5 3. e4 dxe4 4. d3"),
  new FullOpening("A00", "Van Geet Opening: Hector Gambit", "rnbqkbnr/ppp1pppp/8/8/2B1p3/2N5/PPPP1PPP/R1BQK1NR b KQkq -", "b1c3 d7d5 e2e4 d5e4 f1c4", "1. Nc3 d5 2. e4 dxe4 3. Bc4"),
  new FullOpening("A00", "Van Geet Opening: Hergert Gambit", "r1bqkbnr/ppp2ppp/2np4/4P3/8/2N5/PPPPP1PP/R1BQKBNR w KQkq -", "b1c3 d7d6 f2f4 e7e5 f4e5 b8c6", "1. Nc3 d6 2. f4 e5 3. fxe5 Nc6"),
  new FullOpening("A00", "Van Geet Opening: Hulsemann Gambit", "rn1qkbnr/ppp2ppp/4b3/3pp2Q/8/2N1P3/PPPP1PPP/R1B1KBNR w KQkq -", "b1c3 e7e5 e2e3 d7d5 d1h5 c8e6", "1. Nc3 e5 2. e3 d5 3. Qh5 Be6"),
  new FullOpening("A00", "Van Geet Opening: Jendrossek Gambit", "rnbqkb1r/pp2p1pp/5n2/2p2p2/1P1p1P2/5N2/P1PPPNPP/R1BQKB1R b KQkq -", "b1c3 d7d5 f2f4 d5d4 c3e4 f7f5 e4f2 g8f6 g1f3 c7c5 b2b4", "1. Nc3 d5 2. f4 d4 3. Ne4 f5 4. Nf2 Nf6 5. Nf3 c5 6. b4"),
  new FullOpening("A00", "Van Geet Opening: Kluever Gambit", "rnbqkbnr/ppppp1pp/8/8/4p3/2NP4/PPP2PPP/R1BQKBNR b KQkq -", "b1c3 f7f5 e2e4 f5e4 d2d3", "1. Nc3 f5 2. e4 fxe4 3. d3"),
  new FullOpening("A00", "Van Geet Opening: Laroche Gambit", "rnbqkbnr/p1pppppp/8/1p6/8/2N5/PPPPPPPP/R1BQKBNR w KQkq -", "b1c3 b7b5", "1. Nc3 b5"),
  new FullOpening("A00", "Van Geet Opening: Liebig Gambit", "rnbqkb1r/ppp2ppp/5n2/3pp2Q/8/2N1P3/PPPP1PPP/R1B1KBNR w KQkq -", "b1c3 e7e5 e2e3 d7d5 d1h5 g8f6", "1. Nc3 e5 2. e3 d5 3. Qh5 Nf6"),
  new FullOpening("A00", "Van Geet Opening: Melleby Gambit", "rnbqkbnr/pp2pppp/8/2p5/3pNP2/8/PPPPP1PP/R1BQKBNR w KQkq -", "b1c3 d7d5 f2f4 d5d4 c3e4 c7c5", "1. Nc3 d5 2. f4 d4 3. Ne4 c5"),
  new FullOpening("A00", "Van Geet Opening: Myers Attack", "rnbqkbnr/pppppp1p/6p1/8/7P/2N5/PPPPPPP1/R1BQKBNR b KQkq -", "b1c3 g7g6 h2h4", "1. Nc3 g6 2. h4"),
  new FullOpening("A00", "Van Geet Opening: Napoleon Attack", "r1bqkbnr/pppp1ppp/2n5/4p3/3P4/2N2N2/PPP1PPPP/R1BQKB1R b KQkq -", "b1c3 e7e5 g1f3 b8c6 d2d4", "1. Nc3 e5 2. Nf3 Nc6 3. d4"),
  new FullOpening("A00", "Van Geet Opening: Novosibirsk Variation", "r1bqkbnr/pp1ppppp/2n5/8/7Q/2N5/PPP1PPPP/R1B1KBNR b KQkq -", "b1c3 c7c5 d2d4 c5d4 d1d4 b8c6 d4h4", "1. Nc3 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qh4"),
  new FullOpening("A00", "Van Geet Opening: Nowokunski Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/2N5/PPPP2PP/R1BQKBNR b KQkq e3", "b1c3 e7e5 f2f4 e5f4 e2e4", "1. Nc3 e5 2. f4 exf4 3. e4"),
  new FullOpening("A00", "Van Geet Opening: Pfeiffer Gambit", "rnbqkbnr/ppp2ppp/8/4p3/3pNP2/8/PPPPP1PP/R1BQKBNR w KQkq -", "b1c3 d7d5 f2f4 d5d4 c3e4 e7e5", "1. Nc3 d5 2. f4 d4 3. Ne4 e5"),
  new FullOpening("A00", "Van Geet Opening: Pfeiffer Gambit, Sleipnir Countergambit", "rnbqkbnr/ppp2ppp/8/4p3/3pNP2/5N2/PPPPP1PP/R1BQKB1R b KQkq -", "b1c3 d7d5 f2f4 d5d4 c3e4 e7e5 g1f3", "1. Nc3 d5 2. f4 d4 3. Ne4 e5 4. Nf3"),
  new FullOpening("A00", "Van Geet Opening: Reversed Nimzowitsch", "rnbqkbnr/pppp1ppp/8/4p3/8/2N5/PPPPPPPP/R1BQKBNR w KQkq -", "b1c3 e7e5", "1. Nc3 e5"),
  new FullOpening("A00", "Van Geet Opening: Reversed Scandinavian", "r1bqkbnr/pppp1ppp/2n5/8/Q7/2N5/PPP1PPPP/R1B1KBNR b KQkq -", "b1c3 e7e5 d2d4 e5d4 d1d4 b8c6 d4a4", "1. Nc3 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qa4"),
  new FullOpening("A00", "Van Geet Opening: Sicilian Two Knights", "r1bqkbnr/pp1ppppp/2n5/8/3N4/2N5/PPP1PPPP/R1BQKB1R b KQkq -", "b1c3 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4", "1. Nc3 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4"),
  new FullOpening("A00", "Van Geet Opening: Sleipnir Gambit", "rnbqk1nr/ppp2ppp/8/3pp3/1b1P4/2N1P3/PPP2PPP/R1BQKBNR w KQkq -", "b1c3 d7d5 e2e3 e7e5 d2d4 f8b4", "1. Nc3 d5 2. e3 e5 3. d4 Bb4"),
  new FullOpening("A00", "Van Geet Opening: Twyble Attack", "rnbqkbnr/pp1ppppp/8/2p5/8/2N5/PPPPPPPP/1RBQKBNR b Kkq -", "b1c3 c7c5 a1b1", "1. Nc3 c5 2. Rb1"),
  new FullOpening("A00", "Van Geet Opening: Tübingen Gambit", "rnbqkb1r/pppppppp/5n2/8/6P1/2N5/PPPPPP1P/R1BQKBNR b KQkq -", "b1c3 g8f6 g2g4", "1. Nc3 Nf6 2. g4"),
  new FullOpening("A00", "Van Geet Opening: Venezolana Variation", "rnbqkb1r/ppp1pppp/5n2/3p4/8/2NP2P1/PPP1PP1P/R1BQKBNR b KQkq -", "b1c3 d7d5 d2d3 g8f6 g2g3", "1. Nc3 d5 2. d3 Nf6 3. g3"),
  new FullOpening("A00", "Van Geet Opening: Warsteiner Gambit", "rnbqkbnr/ppp1pp1p/8/3p2p1/5P2/2N5/PPPPP1PP/R1BQKBNR w KQkq -", "b1c3 d7d5 f2f4 g7g5", "1. Nc3 d5 2. f4 g5"),
  new FullOpening("A00", "Van't Kruijs Opening", "rnbqkbnr/pppppppp/8/8/8/4P3/PPPP1PPP/RNBQKBNR b KQkq -", "e2e3", "1. e3"),
  new FullOpening("A00", "Van't Kruijs Opening: Bouncing Bishop Variation", "rnbqkbnr/p1pp1ppp/8/1p2p3/8/1B2P3/PPPP1PPP/RNBQK1NR b KQkq -", "e2e3 e7e5 f1c4 b7b5 c4b3", "1. e3 e5 2. Bc4 b5 3. Bb3"),
  new FullOpening("A00", "Van't Kruijs Opening: Keoni-Hiva Gambit Delayed", "rnbqkb1r/ppp2ppp/5n2/3p4/5p2/P1N1PN2/1PPP2PP/R1BQKB1R b KQkq -", "e2e3 d7d5 b1c3 g8f6 a2a3 e7e5 f2f4 e5f4 g1f3", "1. e3 d5 2. Nc3 Nf6 3. a3 e5 4. f4 exf4 5. Nf3"),
  new FullOpening("A00", "Van't Kruijs Opening: Keoni-Hiva Gambit, Akahi Variation", "rnbqkb1r/pppp1ppp/5n2/8/5p2/2N1PN2/PPPP2PP/R1BQKB1R b KQkq -", "e2e3 e7e5 b1c3 g8f6 f2f4 e5f4 g1f3", "1. e3 e5 2. Nc3 Nf6 3. f4 exf4 4. Nf3"),
  new FullOpening("A00", "Van't Kruijs Opening: Keoni-Hiva Gambit, Alua Variation", "r1bqkbnr/pppp1ppp/2n5/8/5p2/2N1PN2/PPPP2PP/R1BQKB1R b KQkq -", "e2e3 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3", "1. e3 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3"),
  new FullOpening("A00", "Van't Kruijs Opening: Keoni-Hiva Gambit, Ekolu Variation", "rnbqkbnr/ppp2ppp/8/3p4/5p2/2N1PN2/PPPP2PP/R1BQKB1R b KQkq -", "e2e3 e7e5 b1c3 d7d5 f2f4 e5f4 g1f3", "1. e3 e5 2. Nc3 d5 3. f4 exf4 4. Nf3"),
  new FullOpening("A00", "Venezolana Opening", "r1bqkbnr/pp1ppppp/2n5/2p5/8/2NP2P1/PPP1PP1P/R1BQKBNR b KQkq -", "d2d3 c7c5 b1c3 b8c6 g2g3", "1. d3 c5 2. Nc3 Nc6 3. g3"),
  new FullOpening("A00", "Ware Opening", "rnbqkbnr/pppppppp/8/8/P7/8/1PPPPPPP/RNBQKBNR b KQkq -", "a2a4", "1. a4"),
  new FullOpening("A00", "Ware Opening: Cologne Gambit", "r1bqkbnr/p1pnpppp/1p6/3p4/P2P4/2N5/1PP1PPPP/R1BQKBNR w KQkq -", "a2a4 b7b6 d2d4 d7d5 b1c3 b8d7", "1. a4 b6 2. d4 d5 3. Nc3 Nd7"),
  new FullOpening("A00", "Ware Opening: Meadow Hay Trap", "rnbqkbnr/pppp1ppp/8/4p3/P7/R7/1PPPPPPP/1NBQKBNR b Kkq -", "a2a4 e7e5 a1a3", "1. a4 e5 2. Ra3"),
  new FullOpening("A00", "Ware Opening: Ware Gambit", "rnbqkbnr/ppp3pp/P7/3ppp2/8/4P3/1PPP1PPP/RNBQKBNR b KQkq -", "a2a4 e7e5 a4a5 d7d5 e2e3 f7f5 a5a6", "1. a4 e5 2. a5 d5 3. e3 f5 4. a6"),
  new FullOpening("A00", "Ware Opening: Wing Gambit", "rn1qkbnr/pbpppppp/8/1P6/8/8/1PPPPPPP/RNBQKBNR w KQkq -", "a2a4 b7b5 a4b5 c8b7", "1. a4 b5 2. axb5 Bb7"),
  new FullOpening("A01", "Nimzo-Larsen Attack", "rnbqkbnr/pppppppp/8/8/8/1P6/P1PPPPPP/RNBQKBNR b KQkq -", "b2b3", "1. b3"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Classical Variation", "rnbqkbnr/ppp1pppp/8/3p4/8/1P6/P1PPPPPP/RNBQKBNR w KQkq -", "b2b3 d7d5", "1. b3 d5"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Dutch Variation", "rnbqkbnr/ppppp1pp/8/5p2/8/1P6/P1PPPPPP/RNBQKBNR w KQkq -", "b2b3 f7f5", "1. b3 f5"),
  new FullOpening("A01", "Nimzo-Larsen Attack: English Variation", "rnbqkbnr/pp1ppppp/8/2p5/8/1P6/P1PPPPPP/RNBQKBNR w KQkq -", "b2b3 c7c5", "1. b3 c5"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Graz Attack", "rnbqkbnr/ppp1pppp/8/3p4/8/BP6/P1PPPPPP/RN1QKBNR b KQkq -", "b2b3 d7d5 c1a3", "1. b3 d5 2. Ba3"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Indian Variation", "rnbqkb1r/pppppppp/5n2/8/8/1P6/P1PPPPPP/RNBQKBNR w KQkq -", "b2b3 g8f6", "1. b3 Nf6"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Modern Variation", "rnbqkbnr/pppp1ppp/8/4p3/8/1P6/P1PPPPPP/RNBQKBNR w KQkq -", "b2b3 e7e5", "1. b3 e5"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Modern Variation", "r1bqkbnr/pppp1ppp/2n5/4p3/8/1P6/PBPPPPPP/RN1QKBNR w KQkq -", "b2b3 e7e5 c1b2 b8c6", "1. b3 e5 2. Bb2 Nc6"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Modern Variation", "r1bqkbnr/pppp1ppp/2n5/4p3/8/1P2P3/PBPP1PPP/RN1QKBNR b KQkq -", "b2b3 e7e5 c1b2 b8c6 e2e3", "1. b3 e5 2. Bb2 Nc6 3. e3"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Pachman Gambit", "r1bqkbnr/pppp1ppp/2n5/4p3/5P2/1P6/PBPPP1PP/RN1QKBNR b KQkq -", "b2b3 e7e5 c1b2 b8c6 f2f4", "1. b3 e5 2. Bb2 Nc6 3. f4"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Polish Variation", "rnbqkbnr/p1pppppp/8/1p6/8/1P6/P1PPPPPP/RNBQKBNR w KQkq -", "b2b3 b7b5", "1. b3 b5"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Ringelbach Gambit", "rnbqkbnr/pppp2pp/4p3/5p2/4P3/1P6/PBPP1PPP/RN1QKBNR b KQkq -", "b2b3 f7f5 c1b2 e7e6 e2e4", "1. b3 f5 2. Bb2 e6 3. e4"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Spike Variation", "rnbqkb1r/pppppp1p/5np1/8/6P1/1P6/PBPPPP1P/RN1QKBNR b KQkq -", "b2b3 g8f6 c1b2 g7g6 g2g4", "1. b3 Nf6 2. Bb2 g6 3. g4"),
  new FullOpening("A01", "Nimzo-Larsen Attack: Symmetrical Variation", "rnbqkbnr/p1pppppp/1p6/8/8/1P6/P1PPPPPP/RNBQKBNR w KQkq -", "b2b3 b7b6", "1. b3 b6"),
  new FullOpening("A02", "Bird Opening", "rnbqkbnr/pppppppp/8/8/5P2/8/PPPPP1PP/RNBQKBNR b KQkq -", "f2f4", "1. f4"),
  new FullOpening("A02", "Bird Opening", "rnbqkb1r/pppppppp/5n2/8/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 g8f6", "1. f4 Nf6"),
  new FullOpening("A02", "Bird Opening: Batavo-Polish Attack", "rnbqkb1r/pppppp1p/5np1/8/1P3P2/5N2/P1PPP1PP/RNBQKB1R b KQkq -", "f2f4 g8f6 g1f3 g7g6 b2b4", "1. f4 Nf6 2. Nf3 g6 3. b4"),
  new FullOpening("A02", "Bird Opening: From's Gambit", "rnbqkbnr/pppp1ppp/8/4p3/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 e7e5", "1. f4 e5"),
  new FullOpening("A02", "Bird Opening: From's Gambit, Bahr Gambit", "rnbqkbnr/pppp1ppp/8/4p3/5P2/2N5/PPPPP1PP/R1BQKBNR b KQkq -", "f2f4 e7e5 b1c3", "1. f4 e5 2. Nc3"),
  new FullOpening("A02", "Bird Opening: From's Gambit, Langheld Gambit", "rnbqkb1r/ppp2ppp/3P1n2/8/8/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 e7e5 f4e5 d7d6 e5d6 g8f6", "1. f4 e5 2. fxe5 d6 3. exd6 Nf6"),
  new FullOpening("A02", "Bird Opening: From's Gambit, Lasker Variation", "rnbqk1nr/ppp2p1p/3b4/6p1/8/5N2/PPPPP1PP/RNBQKB1R w KQkq -", "f2f4 e7e5 f4e5 d7d6 e5d6 f8d6 g1f3 g7g5", "1. f4 e5 2. fxe5 d6 3. exd6 Bxd6 4. Nf3 g5"),
  new FullOpening("A02", "Bird Opening: From's Gambit, Lipke Variation", "rnbqk2r/ppp2ppp/3b3n/8/3P4/5N2/PPP1P1PP/RNBQKB1R b KQkq -", "f2f4 e7e5 f4e5 d7d6 e5d6 f8d6 g1f3 g8h6 d2d4", "1. f4 e5 2. fxe5 d6 3. exd6 Bxd6 4. Nf3 Nh6 5. d4"),
  new FullOpening("A02", "Bird Opening: Hobbs Gambit", "rnbqkbnr/pppppp1p/8/6p1/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 g7g5", "1. f4 g5"),
  new FullOpening("A02", "Bird Opening: Hobbs-Zilbermints Gambit", "rnbqkbnr/pppppp2/7p/6p1/5P2/5N2/PPPPP1PP/RNBQKB1R w KQkq -", "f2f4 h7h6 g1f3 g7g5", "1. f4 h6 2. Nf3 g5"),
  new FullOpening("A02", "Bird Opening: Horsefly Defense", "rnbqkb1r/pppppppp/7n/8/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 g8h6", "1. f4 Nh6"),
  new FullOpening("A02", "Bird Opening: Lasker Gambit", "rnbqkbnr/pppp2pp/5p2/4P3/8/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 e7e5 f4e5 f7f6", "1. f4 e5 2. fxe5 f6"),
  new FullOpening("A02", "Bird Opening: Mujannah", "rnbqkb1r/pppppppp/5n2/8/2P2P2/8/PP1PP1PP/RNBQKBNR b KQkq -", "f2f4 g8f6 c2c4", "1. f4 Nf6 2. c4"),
  new FullOpening("A02", "Bird Opening: Myers Defense", "rnbqkbnr/p1pppppp/8/1p6/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 b7b5", "1. f4 b5"),
  new FullOpening("A02", "Bird Opening: Platz Gambit", "rnbqkb1r/ppppnppp/8/4P3/8/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 e7e5 f4e5 g8e7", "1. f4 e5 2. fxe5 Ne7"),
  new FullOpening("A02", "Bird Opening: Schlechter Gambit", "r1bqkbnr/pppp1ppp/2n5/4P3/8/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 e7e5 f4e5 b8c6", "1. f4 e5 2. fxe5 Nc6"),
  new FullOpening("A02", "Bird Opening: Siegener Gambit", "rnbqkbnr/pp1p1ppp/8/2p5/3p1P2/2P2N2/PP2P1PP/RNBQKB1R b KQkq -", "f2f4 e7e5 d2d4 e5d4 g1f3 c7c5 c2c3", "1. f4 e5 2. d4 exd4 3. Nf3 c5 4. c3"),
  new FullOpening("A02", "Bird Opening: Swiss Gambit", "rnbqkb1r/ppppp1pp/5n2/8/4pPP1/2N5/PPPP3P/R1BQKBNR b KQkq -", "f2f4 f7f5 e2e4 f5e4 b1c3 g8f6 g2g4", "1. f4 f5 2. e4 fxe4 3. Nc3 Nf6 4. g4"),
  new FullOpening("A02", "Bird Opening: Wagner-Zwitersch Gambit", "rnbqkbnr/ppppp1pp/8/5p2/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -", "f2f4 f7f5 e2e4", "1. f4 f5 2. e4"),
  new FullOpening("A02", "System: Double Duck Formation", "rnbqkbnr/ppp1p1pp/8/3p1p2/3P1P2/8/PPP1P1PP/RNBQKBNR w KQkq -", "f2f4 f7f5 d2d4 d7d5", "1. f4 f5 2. d4 d5"),
  new FullOpening("A03", "Bird Opening: Dutch Variation", "rnbqkbnr/ppp1pppp/8/3p4/5P2/8/PPPPP1PP/RNBQKBNR w KQkq -", "f2f4 d7d5", "1. f4 d5"),
  new FullOpening("A03", "Bird Opening: Dutch Variation, Dudweiler Gambit", "rnbqkbnr/ppp1pppp/8/3p4/5PP1/8/PPPPP2P/RNBQKBNR b KQkq -", "f2f4 d7d5 g2g4", "1. f4 d5 2. g4"),
  new FullOpening("A03", "Bird Opening: Lasker Variation", "rnbqkb1r/pp2pppp/5n2/2pp4/5P2/4PN2/PPPP2PP/RNBQKB1R w KQkq -", "f2f4 d7d5 g1f3 g8f6 e2e3 c7c5", "1. f4 d5 2. Nf3 Nf6 3. e3 c5"),
  new FullOpening("A03", "Bird Opening: Sturm Gambit", "rnbqkbnr/ppp1pppp/8/3p4/2P2P2/8/PP1PP1PP/RNBQKBNR b KQkq -", "f2f4 d7d5 c2c4", "1. f4 d5 2. c4"),
  new FullOpening("A03", "Bird Opening: Thomas Gambit", "rnbqkb1r/pp2pppp/5n2/2p5/3p1P2/1P2PN2/PBPP2PP/RN1QKB1R b KQkq -", "f2f4 d7d5 b2b3 g8f6 c1b2 d5d4 g1f3 c7c5 e2e3", "1. f4 d5 2. b3 Nf6 3. Bb2 d4 4. Nf3 c5 5. e3"),
  new FullOpening("A03", "Bird Opening: Williams Gambit", "rnbqkbnr/ppp1pppp/8/3p4/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -", "f2f4 d7d5 e2e4", "1. f4 d5 2. e4"),
  new FullOpening("A03", "Bird Opening: Williams Gambit", "rnbqkb1r/ppp1pppp/5n2/8/4pP2/2N5/PPPPQ1PP/R1B1KBNR b KQkq -", "f2f4 d7d5 e2e4 d5e4 b1c3 g8f6 d1e2", "1. f4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Qe2"),
  new FullOpening("A03", "Bird Opening: Williams-Zilbermints Gambit", "rnbqkb1r/ppp1pppp/5n2/8/4pP2/2N5/PPPPN1PP/R1BQKB1R b KQkq -", "f2f4 d7d5 e2e4 d5e4 b1c3 g8f6 g1e2", "1. f4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Nge2"),
  new FullOpening("A04", "Colle System: Rhamphorhynchus Variation", "rnb1k1nr/pp1pppbp/6p1/q1P5/8/4PN2/PPP2PPP/RNBQKB1R w KQkq -", "g1f3 c7c5 e2e3 g7g6 d2d4 f8g7 d4c5 d8a5", "1. Nf3 c5 2. e3 g6 3. d4 Bg7 4. dxc5 Qa5+"),
  new FullOpening("A04", "Modern Defense: Semi-Averbakh Variation, Polish Variation", "rnb1k1nr/pp1pppbp/1q4p1/2p5/2PPP3/5N2/PP3PPP/RNBQKB1R w KQkq -", "g1f3 c7c5 c2c4 g7g6 d2d4 f8g7 e2e4 d8b6", "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qb6"),
  new FullOpening("A04", "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation", "rnb1k1nr/pp1pppbp/6p1/q1p5/2PPP3/5N2/PP3PPP/RNBQKB1R w KQkq -", "g1f3 c7c5 c2c4 g7g6 d2d4 f8g7 e2e4 d8a5", "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qa5+"),
  new FullOpening("A04", "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation Accepted", "rnb1k1nr/pp2ppbp/3p2p1/q1P5/2P1P3/5N2/PP3PPP/RNBQKB1R w KQkq -", "g1f3 g7g6 d2d4 f8g7 e2e4 d7d6 c2c4 c7c5 d4c5 d8a5", "1. Nf3 g6 2. d4 Bg7 3. e4 d6 4. c4 c5 5. dxc5 Qa5+"),
  new FullOpening("A04", "Zukertort Defense: Kingside Variation", "rnbqkb1r/pppppp1p/6pn/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "g1f3 g8h6 d2d4 g7g6", "1. Nf3 Nh6 2. d4 g6"),
  new FullOpening("A04", "Zukertort Defense: Sicilian Knight Variation", "r1bqkbnr/pp1ppppp/n7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "g1f3 b8a6 e2e4 c7c5", "1. Nf3 Na6 2. e4 c5"),
  new FullOpening("A04", "Zukertort Opening", "rnbqkbnr/pppppppp/8/8/8/5N2/PPPPPPPP/RNBQKB1R b KQkq -", "g1f3", "1. Nf3"),
  new FullOpening("A04", "Zukertort Opening: Arctic Defense", "rnbqkbnr/ppppp1pp/5p2/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 f7f6", "1. Nf3 f6"),
  new FullOpening("A04", "Zukertort Opening: Arctic Defense, Drunken Knight Variation", "rnbqkb1r/pppppnpp/5p2/8/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "g1f3 f7f6 e2e4 g8h6 d2d4 h6f7", "1. Nf3 f6 2. e4 Nh6 3. d4 Nf7"),
  new FullOpening("A04", "Zukertort Opening: Basman Defense", "rnbqkbnr/ppppppp1/7p/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 h7h6", "1. Nf3 h6"),
  new FullOpening("A04", "Zukertort Opening: Black Mustang Defense", "r1bqkbnr/pppppppp/2n5/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 b8c6", "1. Nf3 Nc6"),
  new FullOpening("A04", "Zukertort Opening: Drunken Cavalry Variation", "r1bqkb1r/pppppppp/n6n/8/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "g1f3 b8a6 e2e4 g8h6", "1. Nf3 Na6 2. e4 Nh6"),
  new FullOpening("A04", "Zukertort Opening: Dutch Variation", "rnbqkbnr/ppppp1pp/8/5p2/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 f7f5", "1. Nf3 f5"),
  new FullOpening("A04", "Zukertort Opening: Herrstrom Gambit", "rnbqkbnr/pppppp1p/8/6p1/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 g7g5", "1. Nf3 g5"),
  new FullOpening("A04", "Zukertort Opening: Kingside Fianchetto", "rnbqkbnr/pppppp1p/6p1/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 g7g6", "1. Nf3 g6"),
  new FullOpening("A04", "Zukertort Opening: Lisitsyn Gambit", "rnbqkbnr/ppppp1pp/8/5p2/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "g1f3 f7f5 e2e4", "1. Nf3 f5 2. e4"),
  new FullOpening("A04", "Zukertort Opening: Lisitsyn Gambit Deferred", "rnbqkb1r/ppppp1pp/5n2/5p2/4P3/3P1N2/PPP2PPP/RNBQKB1R b KQkq -", "g1f3 f7f5 d2d3 g8f6 e2e4", "1. Nf3 f5 2. d3 Nf6 3. e4"),
  new FullOpening("A04", "Zukertort Opening: Pirc Invitation", "rnbqkbnr/ppp1pppp/3p4/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 d7d6", "1. Nf3 d6"),
  new FullOpening("A04", "Zukertort Opening: Polish Defense", "rnbqkbnr/p1pppppp/8/1p6/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 b7b5", "1. Nf3 b5"),
  new FullOpening("A04", "Zukertort Opening: Queen's Gambit Invitation", "rnbqkbnr/pppp1ppp/4p3/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 e7e6", "1. Nf3 e6"),
  new FullOpening("A04", "Zukertort Opening: Queenside Fianchetto Variation", "rnbqkbnr/p1pppppp/1p6/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 b7b6", "1. Nf3 b6"),
  new FullOpening("A04", "Zukertort Opening: Ross Gambit", "rnbqkbnr/pppp1ppp/8/4p3/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 e7e5", "1. Nf3 e5"),
  new FullOpening("A04", "Zukertort Opening: Shabalov Gambit", "rnbqkbnr/3p1ppp/p3p3/1pp5/2P5/2N2NP1/PP1PPP1P/R1BQKB1R w KQkq -", "g1f3 e7e6 c2c4 a7a6 b1c3 c7c5 g2g3 b7b5", "1. Nf3 e6 2. c4 a6 3. Nc3 c5 4. g3 b5"),
  new FullOpening("A04", "Zukertort Opening: Sicilian Invitation", "rnbqkbnr/pp1ppppp/8/2p5/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 c7c5", "1. Nf3 c5"),
  new FullOpening("A04", "Zukertort Opening: Slav Invitation", "rnbqkbnr/pp1ppppp/2p5/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 c7c6", "1. Nf3 c6"),
  new FullOpening("A04", "Zukertort Opening: Speelsmet Gambit", "rnbqkbnr/pp1ppppp/8/8/3p4/4PN2/PPP2PPP/RNBQKB1R b KQkq -", "g1f3 c7c5 d2d4 c5d4 e2e3", "1. Nf3 c5 2. d4 cxd4 3. e3"),
  new FullOpening("A04", "Zukertort Opening: St. George Defense", "rnbqkbnr/1ppppppp/p7/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 a7a6", "1. Nf3 a6"),
  new FullOpening("A04", "Zukertort Opening: The Walrus", "r1bqkbnr/ppp2ppp/2p5/8/8/8/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 e7e5 f3e5 b8c6 e5c6 d7c6", "1. Nf3 e5 2. Nxe5 Nc6 3. Nxc6 dxc6"),
  new FullOpening("A04", "Zukertort Opening: Vos Gambit", "rnbqkbnr/ppp2ppp/3p4/4p3/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "g1f3 d7d6 d2d4 e7e5", "1. Nf3 d6 2. d4 e5"),
  new FullOpening("A04", "Zukertort Opening: Wade Defense", "rn1qkbnr/ppp1pppp/3p4/8/4P1b1/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "g1f3 d7d6 e2e4 c8g4", "1. Nf3 d6 2. e4 Bg4"),
  new FullOpening("A04", "Zukertort Opening: Ware Defense", "rnbqkbnr/1ppppppp/8/p7/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 a7a5", "1. Nf3 a5"),
  new FullOpening("A05", "King's Indian Attack", "rnbqkb1r/ppp1pppp/5n2/3p4/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g1f3 g8f6 g2g3 d7d5", "1. Nf3 Nf6 2. g3 d5"),
  new FullOpening("A05", "King's Indian Attack: Smyslov Variation", "rnbqkb1r/pppppp1p/5np1/8/1P6/5NP1/P1PPPP1P/RNBQKB1R b KQkq -", "g1f3 g8f6 g2g3 g7g6 b2b4", "1. Nf3 Nf6 2. g3 g6 3. b4"),
  new FullOpening("A05", "King's Indian Attack: Spassky Variation", "rnbqkb1r/p1pppppp/5n2/1p6/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g1f3 g8f6 g2g3 b7b5", "1. Nf3 Nf6 2. g3 b5"),
  new FullOpening("A05", "King's Indian Attack: Symmetrical Defense", "rnbqkb1r/pppppp1p/5np1/8/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g1f3 g8f6 g2g3 g7g6", "1. Nf3 Nf6 2. g3 g6"),
  new FullOpening("A05", "King's Indian Attack: Wahls Defense", "rnbq1rk1/ppp1ppbp/5np1/3p4/8/3P1NP1/PPP1PPBP/RNBQ1RK1 w - -", "g1f3 g8f6 g2g3 g7g6 f1g2 f8g7 e1g1 e8g8 d2d3 d7d5", "1. Nf3 Nf6 2. g3 g6 3. Bg2 Bg7 4. O-O O-O 5. d3 d5"),
  new FullOpening("A05", "Polish Opening: Zukertort System", "rnbqkb1r/pppppppp/5n2/8/1P6/5N2/P1PPPPPP/RNBQKB1R b KQkq -", "g1f3 g8f6 b2b4", "1. Nf3 Nf6 2. b4"),
  new FullOpening("A05", "Zukertort Opening", "rnbqkb1r/pppppppp/5n2/8/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 g8f6", "1. Nf3 Nf6"),
  new FullOpening("A05", "Zukertort Opening", "r1bqkb1r/pppppppp/2n2n2/8/8/2N2N2/PPPPPPPP/R1BQKB1R w KQkq -", "g1f3 g8f6 b1c3 b8c6", "1. Nf3 Nf6 2. Nc3 Nc6"),
  new FullOpening("A05", "Zukertort Opening: Double Fianchetto Attack", "rnbq1rk1/ppp1ppbp/3p1np1/8/8/1P3NP1/PBPPPPBP/RN1Q1RK1 b - -", "g1f3 g8f6 g2g3 g7g6 b2b3 f8g7 c1b2 e8g8 f1g2 d7d6 e1g1", "1. Nf3 Nf6 2. g3 g6 3. b3 Bg7 4. Bb2 O-O 5. Bg2 d6 6. O-O"),
  new FullOpening("A05", "Zukertort Opening: Lemberger Gambit", "rnbqkb1r/pppppppp/5n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "g1f3 g8f6 e2e4", "1. Nf3 Nf6 2. e4"),
  new FullOpening("A05", "Zukertort Opening: Myers Polish Attack", "rnbqkb1r/pppppp1p/5np1/8/PP6/5N2/2PPPPPP/RNBQKB1R b KQkq -", "g1f3 g8f6 a2a4 g7g6 b2b4", "1. Nf3 Nf6 2. a4 g6 3. b4"),
  new FullOpening("A05", "Zukertort Opening: Nimzo-Larsen Variation", "rnbqkb1r/pppppppp/5n2/8/8/1P3N2/P1PPPPPP/RNBQKB1R b KQkq -", "g1f3 g8f6 b2b3", "1. Nf3 Nf6 2. b3"),
  new FullOpening("A05", "Zukertort Opening: Quiet System", "rnbqkb1r/pppppppp/5n2/8/8/4PN2/PPPP1PPP/RNBQKB1R b KQkq -", "g1f3 g8f6 e2e3", "1. Nf3 Nf6 2. e3"),
  new FullOpening("A06", "Nimzo-Larsen Attack: Classical Variation", "rnbqkbnr/ppp1pppp/8/3p4/8/1P3N2/P1PPPPPP/RNBQKB1R b KQkq -", "g1f3 d7d5 b2b3", "1. Nf3 d5 2. b3"),
  new FullOpening("A06", "Nimzo-Larsen Attack: Norfolk Gambit", "rnbqkbnr/pp2pppp/8/2pp4/4P3/1P3N2/P1PP1PPP/RNBQKB1R b KQkq -", "g1f3 d7d5 b2b3 c7c5 e2e4", "1. Nf3 d5 2. b3 c5 3. e4"),
  new FullOpening("A06", "Nimzo-Larsen Attack: Norfolk Gambit", "rnbqkb1r/pp2pppp/5n2/2pp4/4P3/1P3N2/PBPP1PPP/RN1QKB1R b KQkq -", "g1f3 d7d5 b2b3 g8f6 c1b2 c7c5 e2e4", "1. Nf3 d5 2. b3 Nf6 3. Bb2 c5 4. e4"),
  new FullOpening("A06", "Zukertort Opening", "rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKB1R w KQkq -", "g1f3 d7d5", "1. Nf3 d5"),
  new FullOpening("A06", "Zukertort Opening: Ampel Variation", "rnbqkbnr/ppp1pppp/8/3p4/8/5N2/PPPPPPPP/RNBQKBR1 b Qkq -", "g1f3 d7d5 h1g1", "1. Nf3 d5 2. Rg1"),
  new FullOpening("A06", "Zukertort Opening: Old Indian Attack", "rnbqkbnr/ppp1pppp/8/3p4/8/3P1N2/PPP1PPPP/RNBQKB1R b KQkq -", "g1f3 d7d5 d2d3", "1. Nf3 d5 2. d3"),
  new FullOpening("A06", "Zukertort Opening: Pachman Gambit", "rnbqkbnr/pp2pppp/8/2p5/2p5/1P2PN2/P2P1PPP/RNBQKB1R b KQkq -", "g1f3 d7d5 e2e3 c7c5 c2c4 d5c4 b2b3", "1. Nf3 d5 2. e3 c5 3. c4 dxc4 4. b3"),
  new FullOpening("A06", "Zukertort Opening: Regina-Nu Gambit", "rnbqkbnr/pp2pppp/8/2p5/2p5/1PN2N2/P2PPPPP/R1BQKB1R b KQkq -", "g1f3 d7d5 b2b3 c7c5 c2c4 d5c4 b1c3", "1. Nf3 d5 2. b3 c5 3. c4 dxc4 4. Nc3"),
  new FullOpening("A06", "Zukertort Opening: Reversed Mexican Defense", "rnbqkbnr/ppp1pppp/8/3p4/8/2N2N2/PPPPPPPP/R1BQKB1R b KQkq -", "g1f3 d7d5 b1c3", "1. Nf3 d5 2. Nc3"),
  new FullOpening("A06", "Zukertort Opening: Santasiere's Folly", "rnbqkbnr/ppp1pppp/8/3p4/1P6/5N2/P1PPPPPP/RNBQKB1R b KQkq -", "b2b4 d7d5 g1f3", "1. b4 d5 2. Nf3"),
  new FullOpening("A06", "Zukertort Opening: Tennison Gambit", "rnbqkbnr/ppp1pppp/8/3p4/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 d7d5 g1f3", "1. e4 d5 2. Nf3"),
  new FullOpening("A06", "Zukertort Opening: The Potato", "rnbqkbnr/ppp1pppp/8/3p4/P7/5N2/1PPPPPPP/RNBQKB1R b KQkq -", "g1f3 d7d5 a2a4", "1. Nf3 d5 2. a4"),
  new FullOpening("A07", "Hungarian Opening: Wiedenhagen-Beta Gambit", "rnbqkbnr/ppp1pp1p/8/3p2p1/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g2g3 d7d5 g1f3 g7g5", "1. g3 d5 2. Nf3 g5"),
  new FullOpening("A07", "King's Indian Attack", "rnbqkbnr/ppp1pppp/8/3p4/8/5NP1/PPPPPP1P/RNBQKB1R b KQkq -", "g1f3 d7d5 g2g3", "1. Nf3 d5 2. g3"),
  new FullOpening("A07", "King's Indian Attack: Double Fianchetto", "rnbqkbnr/ppp1pp1p/6p1/3p4/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g1f3 d7d5 g2g3 g7g6", "1. Nf3 d5 2. g3 g6"),
  new FullOpening("A07", "King's Indian Attack: Keres Variation", "rn1qkbnr/ppp1pppp/8/3p4/6b1/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g1f3 d7d5 g2g3 c8g4", "1. Nf3 d5 2. g3 Bg4"),
  new FullOpening("A07", "King's Indian Attack: Keres Variation", "r2qkbnr/pppnpppp/8/3p4/6b1/5NP1/PPPPPPBP/RNBQK2R w KQkq -", "g1f3 d7d5 g2g3 c8g4 f1g2 b8d7", "1. Nf3 d5 2. g3 Bg4 3. Bg2 Nd7"),
  new FullOpening("A07", "King's Indian Attack: Omega-Delta Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g1f3 d7d5 g2g3 e7e5", "1. Nf3 d5 2. g3 e5"),
  new FullOpening("A07", "King's Indian Attack: Pachman System", "rnbqk2r/ppp1npbp/6p1/3pp3/8/3P1NP1/PPP1PPBP/RNBQ1RK1 w kq -", "g1f3 d7d5 g2g3 g7g6 f1g2 f8g7 e1g1 e7e5 d2d3 g8e7", "1. Nf3 d5 2. g3 g6 3. Bg2 Bg7 4. O-O e5 5. d3 Ne7"),
  new FullOpening("A07", "King's Indian Attack: Sicilian Variation", "rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPP1P/RNBQKB1R w KQkq -", "g1f3 d7d5 g2g3 c7c5", "1. Nf3 d5 2. g3 c5"),
  new FullOpening("A07", "King's Indian Attack: Yugoslav Variation", "rn1qkb1r/pp2pppp/2p2n2/3p4/6b1/5NP1/PPPPPPBP/RNBQ1RK1 w kq -", "g1f3 g8f6 g2g3 d7d5 f1g2 c7c6 e1g1 c8g4", "1. Nf3 Nf6 2. g3 d5 3. Bg2 c6 4. O-O Bg4"),
  new FullOpening("A08", "King's Indian Attack: French Variation", "r1bqkbnr/pp2pppp/2n5/2pp4/8/5NP1/PPPPPPBP/RNBQK2R w KQkq -", "g1f3 d7d5 g2g3 c7c5 f1g2 b8c6", "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6"),
  new FullOpening("A08", "King's Indian Attack: Sicilian Variation", "r1bq1rk1/pp2bppp/2n1pn2/2pp4/4P3/3P1NP1/PPPN1PBP/R1BQR1K1 b - -", "e2e4 e7e6 d2d3 d7d5 b1d2 g8f6 g1f3 c7c5 g2g3 b8c6 f1g2 f8e7 e1g1 e8g8 f1e1", "1. e4 e6 2. d3 d5 3. Nd2 Nf6 4. Ngf3 c5 5. g3 Nc6 6. Bg2 Be7 7. O-O O-O 8. Re1"),
  new FullOpening("A08", "King's Indian Attack: Sicilian Variation", "rnbqkbnr/pp2pppp/8/2pp4/8/5NP1/PPPPPPBP/RNBQK2R b KQkq -", "g1f3 d7d5 g2g3 c7c5 f1g2", "1. Nf3 d5 2. g3 c5 3. Bg2"),
  new FullOpening("A08", "Zukertort Opening: Grünfeld Reversed", "r1bqkbnr/pp3ppp/2n1p3/2pp4/3P4/5NP1/PPP1PPBP/RNBQ1RK1 b kq -", "g1f3 d7d5 g2g3 c7c5 f1g2 b8c6 d2d4 e7e6 e1g1", "1. Nf3 d5 2. g3 c5 3. Bg2 Nc6 4. d4 e6 5. O-O"),
  new FullOpening("A09", "Réti Opening", "rnbqkbnr/ppp1pppp/8/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq -", "g1f3 d7d5 c2c4", "1. Nf3 d5 2. c4"),
  new FullOpening("A09", "Réti Opening: Advance Variation", "rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 d5d4", "1. Nf3 d5 2. c4 d4"),
  new FullOpening("A09", "Réti Opening: Advance Variation, Michel Gambit", "rnbqkbnr/pp2pppp/8/2p5/1PPp4/5N2/P2PPPPP/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 d5d4 b2b4 c7c5", "1. Nf3 d5 2. c4 d4 3. b4 c5"),
  new FullOpening("A09", "Réti Opening: Penguin Variation", "rnbqkbnr/ppp1pppp/8/8/2Pp4/5N2/PP1PPPPP/RNBQKBR1 b Qkq -", "g1f3 d7d5 c2c4 d5d4 h1g1", "1. Nf3 d5 2. c4 d4 3. Rg1"),
  new FullOpening("A09", "Réti Opening: Reversed Blumenfeld Gambit", "rnbqkbnr/pp2pppp/8/2p5/1PPp4/4PN2/P2P1PPP/RNBQKB1R b KQkq -", "g1f3 d7d5 c2c4 d5d4 e2e3 c7c5 b2b4", "1. Nf3 d5 2. c4 d4 3. e3 c5 4. b4"),
  new FullOpening("A09", "Réti Opening: Réti Accepted", "rnbqkbnr/ppp1pppp/8/8/2p5/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 d5c4", "1. Nf3 d5 2. c4 dxc4"),
  new FullOpening("A09", "Réti Opening: Réti Gambit, Keres Variation", "rn1qkbnr/ppp1pppp/4b3/8/2p5/4PN2/PP1P1PPP/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 d5c4 e2e3 c8e6", "1. Nf3 d5 2. c4 dxc4 3. e3 Be6"),
  new FullOpening("A09", "Réti Opening: Zilbermints Gambit", "rnbqkbnr/p1p1pppp/8/1p1p4/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 b7b5", "1. Nf3 d5 2. c4 b5"),
  new FullOpening("A10", "English Opening", "rnbqkbnr/pppppppp/8/8/2P5/8/PP1PPPPP/RNBQKBNR b KQkq -", "c2c4", "1. c4"),
  new FullOpening("A10", "English Opening: Achilles-Omega Gambit", "rnbqkb1r/pppppppp/5n2/8/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq -", "c2c4 g8f6 e2e4", "1. c4 Nf6 2. e4"),
  new FullOpening("A10", "English Opening: Adorjan Defense", "rnbqkbnr/pppp1p1p/6p1/4p3/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq -", "c2c4 g7g6 e2e4 e7e5", "1. c4 g6 2. e4 e5"),
  new FullOpening("A10", "English Opening: Anglo-Dutch Defense", "rnbqkbnr/ppppp1pp/8/5p2/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 f7f5", "1. c4 f5"),
  new FullOpening("A10", "English Opening: Anglo-Dutch Defense, Hickmann Gambit", "rnbqkbnr/ppppp1pp/8/5p2/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq -", "c2c4 f7f5 e2e4", "1. c4 f5 2. e4"),
  new FullOpening("A10", "English Opening: Anglo-Dutch Variation, Chabanon Gambit", "rnbqkbnr/ppp1p1pp/3p4/5p2/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq -", "c2c4 f7f5 g1f3 d7d6 e2e4", "1. c4 f5 2. Nf3 d6 3. e4"),
  new FullOpening("A10", "English Opening: Anglo-Dutch Variation, Ferenc Gambit", "rnbqkb1r/ppppp1pp/5n2/5p2/2P1P3/2N5/PP1P1PPP/R1BQKBNR b KQkq -", "c2c4 f7f5 b1c3 g8f6 e2e4", "1. c4 f5 2. Nc3 Nf6 3. e4"),
  new FullOpening("A10", "English Opening: Anglo-Lithuanian Variation", "r1bqkbnr/pppppppp/2n5/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 b8c6", "1. c4 Nc6"),
  new FullOpening("A10", "English Opening: Anglo-Scandinavian Defense", "rnbqkbnr/ppp1pppp/8/3p4/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 d7d5", "1. c4 d5"),
  new FullOpening("A10", "English Opening: Anglo-Scandinavian Defense, Löhn Gambit", "rnbqkbnr/ppp2ppp/4p3/3P4/8/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 d7d5 c4d5 e7e6", "1. c4 d5 2. cxd5 e6"),
  new FullOpening("A10", "English Opening: Anglo-Scandinavian Defense, Malvinas Variation", "rnb1kbnr/ppp1pppp/8/q7/8/2N5/PP1PPPPP/R1BQKBNR w KQkq -", "c2c4 d7d5 c4d5 d8d5 b1c3 d5a5", "1. c4 d5 2. cxd5 Qxd5 3. Nc3 Qa5"),
  new FullOpening("A10", "English Opening: Anglo-Scandinavian Defense, Schulz Gambit", "rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 d7d5 c4d5 g8f6", "1. c4 d5 2. cxd5 Nf6"),
  new FullOpening("A10", "English Opening: Great Snake Variation", "rnbqkbnr/pppppp1p/6p1/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 g7g6", "1. c4 g6"),
  new FullOpening("A10", "English Opening: Jaenisch Gambit", "rnbqkbnr/p1pppppp/8/1p6/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 b7b5", "1. c4 b5"),
  new FullOpening("A10", "English Opening: King's English Variation, Botvinnik System, Prickly Pawn Pass System", "rnbq1rk1/1p3pbp/p1pp1np1/4p3/2P1P3/2NP2P1/PP2NPBP/R1BQ1RK1 w - -", "c2c4 g7g6 b1c3 f8g7 g2g3 g8f6 f1g2 e8g8 e2e4 d7d6 g1e2 e7e5 e1g1 c7c6 d2d3 a7a6", "1. c4 g6 2. Nc3 Bg7 3. g3 Nf6 4. Bg2 O-O 5. e4 d6 6. Nge2 e5 7. O-O c6 8. d3 a6"),
  new FullOpening("A10", "English Opening: Myers Variation", "rnbqk1nr/ppppppbp/8/6p1/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "c2c4 g7g5 d2d4 f8g7", "1. c4 g5 2. d4 Bg7"),
  new FullOpening("A10", "English Opening: Porcupine Variation", "rnbqkb1r/ppppp1pp/5n2/8/2P1p1P1/2N5/PP1P1P1P/R1BQKBNR b KQkq -", "c2c4 f7f5 b1c3 g8f6 e2e4 f5e4 g2g4", "1. c4 f5 2. Nc3 Nf6 3. e4 fxe4 4. g4"),
  new FullOpening("A10", "English Opening: Wade Gambit", "rnbqkbnr/ppppp1pp/8/5p2/2P3P1/8/PP1PPP1P/RNBQKBNR b KQkq -", "c2c4 f7f5 g2g4", "1. c4 f5 2. g4"),
  new FullOpening("A10", "English Opening: Zilbermints Gambit", "rnbqkbnr/pppp1p1p/8/4p1p1/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "c2c4 g7g5 d2d4 e7e5", "1. c4 g5 2. d4 e5"),
  new FullOpening("A11", "English Opening: Caro-Kann Defensive System", "rnbqkbnr/pp1ppppp/2p5/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 c7c6", "1. c4 c6"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, Bled Variation", "rnbqkb1r/pp2pp1p/2p2np1/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R w KQkq -", "g1f3 d7d5 b2b3 g8f6 c1b2 g7g6 c2c4 c7c6", "1. Nf3 d5 2. b3 Nf6 3. Bb2 g6 4. c4 c6"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, Bogoljubov Variation", "rnbqkbnr/pp2pppp/2p5/3p4/2P5/1P3N2/P2PPPPP/RNBQKB1R b KQkq -", "g1f3 d7d5 c2c4 c7c6 b2b3", "1. Nf3 d5 2. c4 c6 3. b3"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, Bogoljubov Variation", "rn1qkbnr/pp2pppp/2p5/3p4/2P3b1/1P3N2/P2PPPPP/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 c7c6 b2b3 c8g4", "1. Nf3 d5 2. c4 c6 3. b3 Bg4"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, Bogoljubov Variation", "rnbqkb1r/pp2pppp/2p2n2/3p4/2P5/1P3N2/PB1PPPPP/RN1QKB1R b KQkq -", "g1f3 g8f6 c2c4 c7c6 b2b3 d7d5 c1b2", "1. Nf3 Nf6 2. c4 c6 3. b3 d5 4. Bb2"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, Capablanca Variation", "rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/1P3N2/PB1PPPPP/RN1QKB1R w KQkq -", "c2c4 g8f6 g1f3 c7c6 b2b3 d7d5 c1b2 c8g4", "1. c4 Nf6 2. Nf3 c6 3. b3 d5 4. Bb2 Bg4"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, London Defensive System", "rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/1P3NP1/P2PPP1P/RNBQKB1R w KQkq -", "c2c4 g8f6 g2g3 c7c6 g1f3 d7d5 b2b3 c8f5", "1. c4 Nf6 2. g3 c6 3. Nf3 d5 4. b3 Bf5"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, New York System", "rn1qkb1r/pp2pppp/2p2n2/3p1b2/2P5/1P3N2/PB1PPPPP/RN1QKB1R w KQkq -", "g1f3 g8f6 c2c4 c7c6 b2b3 d7d5 c1b2 c8f5", "1. Nf3 Nf6 2. c4 c6 3. b3 d5 4. Bb2 Bf5"),
  new FullOpening("A12", "Réti Opening: Anglo-Slav Variation, Torre System", "rn1qkb1r/pp2pppp/2p2n2/3p4/2P3b1/1P3NP1/P2PPP1P/RNBQKB1R w KQkq -", "c2c4 g8f6 g2g3 c7c6 g1f3 d7d5 b2b3 c8g4", "1. c4 Nf6 2. g3 c6 3. Nf3 d5 4. b3 Bg4"),
  new FullOpening("A13", "English Opening: Agincourt Defense", "rnbqkbnr/pppp1ppp/4p3/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 e7e6", "1. c4 e6"),
  new FullOpening("A13", "English Opening: Agincourt Defense", "rnbqkbnr/pppp1ppp/4p3/8/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq -", "c2c4 e7e6 g1f3", "1. c4 e6 2. Nf3"),
  new FullOpening("A13", "English Opening: Agincourt Defense", "rnbqkbnr/ppp2ppp/4p3/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "c2c4 e7e6 g1f3 d7d5", "1. c4 e6 2. Nf3 d5"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Bogoljubov Defense", "rnbqk2r/ppp2ppp/3bpn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8d6", "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Bd6"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Catalan Defense", "rn1qkbnr/pbp2ppp/1p2p3/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1 b kq -", "g1f3 d7d5 c2c4 e7e6 g2g3 b7b6 f1g2 c8b7 e1g1", "1. Nf3 d5 2. c4 e6 3. g3 b6 4. Bg2 Bb7 5. O-O"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Catalan Defense", "rnbqkbnr/pp3ppp/4p3/2pp4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 e7e6 g2g3 c7c5", "1. Nf3 d5 2. c4 e6 3. g3 c5"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Catalan Defense Accepted", "rnbqkb1r/ppp2ppp/4pn2/8/2p5/5NP1/PP1PPPBP/RNBQK2R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 d5c4", "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Catalan Defense, Semi-Slav Defense", "rnbqkb1r/pp3ppp/2p1pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 c7c6", "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 c6"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Kurajica Defense", "rnbqkbnr/pp3ppp/2p1p3/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq -", "g1f3 d7d5 c2c4 e7e6 g2g3 c7c6", "1. Nf3 d5 2. c4 e6 3. g3 c6"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Tarrasch Defense", "r1bqk2r/pp2bppp/2n1pn2/2pp4/2P5/1P3NP1/P2PPPBP/RNBQ1RK1 w kq -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5 b2b3 c7c5 f1g2 b8c6 e1g1 f8e7", "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. b3 c5 5. Bg2 Nc6 6. O-O Be7"),
  new FullOpening("A13", "English Opening: Agincourt Defense, Wimpy System", "rnbqkb1r/pp3ppp/4pn2/2pp4/2P5/1P2PN2/PB1P1PPP/RN1QKB1R b KQkq -", "g1f3 g8f6 c2c4 e7e6 b2b3 d7d5 c1b2 c7c5 e2e3", "1. Nf3 Nf6 2. c4 e6 3. b3 d5 4. Bb2 c5 5. e3"),
  new FullOpening("A13", "English Opening: Neo-Catalan", "rnbqkb1r/ppp2ppp/4pn2/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5", "1. Nf3 Nf6 2. c4 e6 3. g3 d5"),
  new FullOpening("A13", "English Opening: Neo-Catalan Declined", "rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8e7", "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7"),
  new FullOpening("A13", "English Opening: Romanishin Gambit", "rnbqkb1r/2pp1ppp/p3pn2/1p6/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 a7a6 f1g2 b7b5", "1. Nf3 Nf6 2. c4 e6 3. g3 a6 4. Bg2 b5"),
  new FullOpening("A14", "English Opening: Agincourt Defense, Keres Defense", "r1bqk2r/pp2bppp/2n1p3/2pn4/8/2N2NP1/PP1PPPBP/R1BQ1RK1 w kq -", "c2c4 c7c5 g1f3 g8f6 b1c3 e7e6 g2g3 d7d5 c4d5 f6d5 f1g2 b8c6 e1g1 f8e7", "1. c4 c5 2. Nf3 Nf6 3. Nc3 e6 4. g3 d5 5. cxd5 Nxd5 6. Bg2 Nc6 7. O-O Be7"),
  new FullOpening("A14", "English Opening: Agincourt Defense, Neo-Catalan Declined", "rnbqk2r/ppp1bppp/4pn2/3p4/2P5/5NP1/PP1PPPBP/RNBQ1RK1 b kq -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8e7 e1g1", "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. O-O"),
  new FullOpening("A14", "Réti Opening: Anglo-Slav Variation, Bogoljubov Variation, Stonewall Line", "rnbq1rk1/pp2bppp/2p1pn2/3p4/2P5/1P3NP1/PB1PPPBP/RN1Q1RK1 b - -", "g1f3 d7d5 c2c4 e7e6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 b2b3 c7c6 c1b2", "1. Nf3 d5 2. c4 e6 3. g3 Nf6 4. Bg2 Be7 5. O-O O-O 6. b3 c6 7. Bb2"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense", "rnbqkb1r/pppppppp/5n2/8/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 g8f6", "1. c4 Nf6"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Anti-Anti-Grünfeld", "rnbqk2r/ppppppbp/5np1/8/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R b KQkq -", "g1f3 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4", "1. Nf3 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Grünfeld Formation", "rnbqkb1r/ppp1pp1p/5np1/3p4/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq -", "c2c4 g8f6 g1f3 g7g6 g2g3 d7d5", "1. c4 Nf6 2. Nf3 g6 3. g3 d5"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, King's Indian Formation", "rnbqkb1r/pppppp1p/5np1/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 g7g6", "1. Nf3 Nf6 2. c4 g6"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, King's Indian Formation, Double Fianchetto", "rn1qkb1r/pbpppp1p/1p3np1/8/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq -", "g1f3 g8f6 c2c4 b7b6 g2g3 c8b7 f1g2 g7g6", "1. Nf3 Nf6 2. c4 b6 3. g3 Bb7 4. Bg2 g6"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, King's Knight Variation", "rnbqkb1r/pppppppp/5n2/8/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq -", "g1f3 g8f6 c2c4", "1. Nf3 Nf6 2. c4"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Old Indian Formation", "rnbqkb1r/ppp1pppp/3p1n2/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 d7d6", "1. Nf3 Nf6 2. c4 d6"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Queen's Indian Formation", "rnbqkb1r/p1pppppp/1p3n2/8/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 b7b6", "1. Nf3 Nf6 2. c4 b6"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Queen's Indian Formation", "rn1qkb1r/pbpp1ppp/1p2pn2/8/2P5/5NP1/PP1PPPBP/RNBQK2R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 b7b6 f1g2 c8b7", "1. Nf3 Nf6 2. c4 e6 3. g3 b6 4. Bg2 Bb7"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Romanishin Variation", "rnbqkb1r/1ppp1ppp/p3pn2/8/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 e7e6 g2g3 a7a6", "1. Nf3 Nf6 2. c4 e6 3. g3 a6"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Scandinavian Defense", "rnbqkb1r/ppp1pppp/5n2/3p4/2P5/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 d7d5", "1. Nf3 Nf6 2. c4 d5"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Scandinavian Defense, Exchange Variation", "rnbqkb1r/ppp1pppp/8/3n4/8/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 d7d5 c4d5 f6d5", "1. Nf3 Nf6 2. c4 d5 3. cxd5 Nxd5"),
  new FullOpening("A15", "English Opening: Anglo-Indian Defense, Slav Formation", "rnbqkb1r/pp1ppp1p/2p2np1/8/2P5/5NP1/PP1PPP1P/RNBQKB1R w KQkq -", "g1f3 g8f6 c2c4 g7g6 g2g3 c7c6", "1. Nf3 Nf6 2. c4 g6 3. g3 c6"),
  new FullOpening("A15", "English Orangutan", "rnbqkb1r/pppppppp/5n2/8/1PP5/8/P2PPPPP/RNBQKBNR b KQkq -", "c2c4 g8f6 b2b4", "1. c4 Nf6 2. b4"),
  new FullOpening("A16", "English Opening: Anglo-Grünfeld Defense", "rnbqkb1r/ppp1pppp/5n2/3p4/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq -", "c2c4 g8f6 b1c3 d7d5", "1. c4 Nf6 2. Nc3 d5"),
  new FullOpening("A16", "English Opening: Anglo-Grünfeld Defense, Korchnoi Variation", "rnbqk2r/ppp2pbp/6p1/3np3/8/2N2NP1/PP1PPPBP/R1BQK2R w KQkq -", "g1f3 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 g2g3 f8g7 f1g2 e7e5", "1. Nf3 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. g3 Bg7 6. Bg2 e5"),
  new FullOpening("A16", "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation", "rnbqkb1r/ppp1pppp/8/3n4/8/2N2N2/PP1PPPPP/R1BQKB1R b KQkq -", "c2c4 g8f6 b1c3 d7d5 c4d5 f6d5 g1f3", "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. Nf3"),
  new FullOpening("A16", "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation", "rnbqkb1r/ppp1pp1p/1n4p1/8/8/2N3P1/PP1PPPBP/R1BQK1NR w KQkq -", "c2c4 g8f6 b1c3 d7d5 c4d5 f6d5 g2g3 g7g6 f1g2 d5b6", "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. g3 g6 5. Bg2 Nb6"),
  new FullOpening("A16", "English Opening: Anglo-Indian Defense, Anglo-Grünfeld Variation", "rnbqkb1r/ppp1pp1p/6p1/8/8/2n3P1/PP1PPPBP/R1BQK1NR w KQkq -", "c2c4 g8f6 b1c3 d7d5 c4d5 f6d5 g2g3 g7g6 f1g2 d5c3", "1. c4 Nf6 2. Nc3 d5 3. cxd5 Nxd5 4. g3 g6 5. Bg2 Nxc3"),
  new FullOpening("A16", "English Opening: Anglo-Indian Defense, Queen's Knight Variation", "rnbqkb1r/pppppppp/5n2/8/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq -", "c2c4 g8f6 b1c3", "1. c4 Nf6 2. Nc3"),
  new FullOpening("A17", "English Opening: Anglo-Indian Defense, Hedgehog System", "rnbqkb1r/pppp1ppp/4pn2/8/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq -", "c2c4 g8f6 b1c3 e7e6", "1. c4 Nf6 2. Nc3 e6"),
  new FullOpening("A17", "English Opening: Anglo-Indian Defense, Nimzo-English", "rnbqk2r/pppp1ppp/4pn2/8/1bP5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -", "g1f3 g8f6 c2c4 e7e6 b1c3 f8b4", "1. Nf3 Nf6 2. c4 e6 3. Nc3 Bb4"),
  new FullOpening("A17", "English Opening: Anglo-Indian Defense, Queen's Indian Formation", "rnbqkb1r/p1pp1ppp/1p2pn2/8/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -", "c2c4 g8f6 b1c3 e7e6 g1f3 b7b6", "1. c4 Nf6 2. Nc3 e6 3. Nf3 b6"),
  new FullOpening("A17", "English Opening: Anglo-Indian Defense, Queen's Indian Variation", "rn1qkb1r/pbpp1ppp/1p2pn2/8/2P1P3/2NB1N2/PP1P1PPP/R1BQK2R b KQkq -", "c2c4 g8f6 b1c3 e7e6 g1f3 b7b6 e2e4 c8b7 f1d3", "1. c4 Nf6 2. Nc3 e6 3. Nf3 b6 4. e4 Bb7 5. Bd3"),
  new FullOpening("A17", "English Opening: Anglo-Indian Defense, Zvjaginsev-Krasenkow Attack", "rnbqk2r/pppp1ppp/4pn2/8/1bP3P1/2N2N2/PP1PPP1P/R1BQKB1R b KQkq -", "c2c4 g8f6 b1c3 e7e6 g1f3 f8b4 g2g4", "1. c4 Nf6 2. Nc3 e6 3. Nf3 Bb4 4. g4"),
  new FullOpening("A18", "English Opening: Mikenas-Carls Variation", "rnbqkb1r/pppp1ppp/4pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR b KQkq -", "c2c4 g8f6 b1c3 e7e6 e2e4", "1. c4 Nf6 2. Nc3 e6 3. e4"),
  new FullOpening("A18", "English Opening: Mikenas-Carls Variation", "r1bqkb1r/pppp1ppp/2n1pn2/8/2P1P3/2N5/PP1P1PPP/R1BQKBNR w KQkq -", "c2c4 g8f6 b1c3 e7e6 e2e4 b8c6", "1. c4 Nf6 2. Nc3 e6 3. e4 Nc6"),
  new FullOpening("A18", "English Opening: Mikenas-Carls Variation", "rnbqkb1r/ppp2ppp/4pn2/3pP3/2P5/2N5/PP1P1PPP/R1BQKBNR b KQkq -", "c2c4 g8f6 b1c3 e7e6 e2e4 d7d5 e4e5", "1. c4 Nf6 2. Nc3 e6 3. e4 d5 4. e5"),
  new FullOpening("A19", "English Opening: Anglo-Indian Defense, Flohr-Mikenas-Carls Variation, Nei Gambit", "rnbqkbnr/pp1p1ppp/4p3/2p1P3/2P5/2N5/PP1P1PPP/R1BQKBNR w KQkq -", "c2c4 g8f6 b1c3 e7e6 e2e4 c7c5 e4e5 f6g8", "1. c4 Nf6 2. Nc3 e6 3. e4 c5 4. e5 Ng8"),
  new FullOpening("A19", "English Opening: Mikenas-Carls, Sicilian", "rnbqkb1r/pp1p1ppp/4pn2/2p5/2P1P3/2N5/PP1P1PPP/R1BQKBNR w KQkq -", "c2c4 g8f6 b1c3 e7e6 e2e4 c7c5", "1. c4 Nf6 2. Nc3 e6 3. e4 c5"),
  new FullOpening("A20", "English Opening: Drill Variation", "rnbqkbnr/pppp1pp1/8/4p2p/2P5/6P1/PP1PPP1P/RNBQKBNR w KQkq -", "c2c4 e7e5 g2g3 h7h5", "1. c4 e5 2. g3 h5"),
  new FullOpening("A20", "English Opening: King's English Variation", "rnbqkbnr/pppp1ppp/8/4p3/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 e7e5", "1. c4 e5"),
  new FullOpening("A20", "English Opening: King's English Variation, Kahiko-Hula Gambit", "rnbqkb1r/pppp1ppp/5n2/8/2P2p2/4PN2/PP1P2PP/RNBQKB1R b KQkq -", "c2c4 e7e5 e2e3 g8f6 f2f4 e5f4 g1f3", "1. c4 e5 2. e3 Nf6 3. f4 exf4 4. Nf3"),
  new FullOpening("A20", "English Opening: King's English Variation, Nimzowitsch Variation", "rnbqkbnr/pppp1ppp/8/4p3/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq -", "c2c4 e7e5 g1f3", "1. c4 e5 2. Nf3"),
  new FullOpening("A20", "English Opening: King's English Variation, Nimzowitsch-Flohr Variation", "rnbqkbnr/pppp1ppp/8/8/2P1p3/5N2/PP1PPPPP/RNBQKB1R w KQkq -", "c2c4 e7e5 g1f3 e5e4", "1. c4 e5 2. Nf3 e4"),
  new FullOpening("A21", "English Opening: King's English Variation", "rnbqkbnr/ppp2ppp/3p4/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R b KQkq -", "c2c4 e7e5 b1c3 d7d6 g1f3", "1. c4 e5 2. Nc3 d6 3. Nf3"),
  new FullOpening("A21", "English Opening: King's English Variation, Keres Defense", "rnbqkbnr/pp3ppp/2pp4/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 d7d6 g2g3 c7c6", "1. c4 e5 2. Nc3 d6 3. g3 c6"),
  new FullOpening("A21", "English Opening: King's English Variation, Kramnik-Shirov Counterattack", "rnbqk1nr/pppp1ppp/8/4p3/1bP5/2N5/PP1PPPPP/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 f8b4", "1. c4 e5 2. Nc3 Bb4"),
  new FullOpening("A21", "English Opening: King's English Variation, Reversed Sicilian", "rnbqkbnr/pppp1ppp/8/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq -", "c2c4 e7e5 b1c3", "1. c4 e5 2. Nc3"),
  new FullOpening("A21", "English Opening: King's English Variation, Smyslov Defense", "rn1qkbnr/ppp2ppp/3p4/4p3/2P3b1/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -", "c2c4 e7e5 b1c3 d7d6 g1f3 c8g4", "1. c4 e5 2. Nc3 d6 3. Nf3 Bg4"),
  new FullOpening("A21", "English Opening: King's English Variation, Troger Defense", "r2qkbnr/ppp2ppp/2npb3/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR w KQkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 d7d6 f1g2 c8e6", "1. c4 e5 2. Nc3 Nc6 3. g3 d6 4. Bg2 Be6"),
  new FullOpening("A22", "English Opening: Carls-Bremen System", "rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR b KQkq -", "c2c4 e7e5 b1c3 g8f6 g2g3", "1. c4 e5 2. Nc3 Nf6 3. g3"),
  new FullOpening("A22", "English Opening: King's English Variation, Bellon Gambit", "rnbqkb1r/p1pp1ppp/5n2/1p4N1/2P1p3/2N5/PP1PPPPP/R1BQKB1R w KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 e5e4 f3g5 b7b5", "1. c4 e5 2. Nc3 Nf6 3. Nf3 e4 4. Ng5 b5"),
  new FullOpening("A22", "English Opening: King's English Variation, Two Knights Variation", "rnbqkb1r/pppp1ppp/5n2/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 g8f6", "1. c4 e5 2. Nc3 Nf6"),
  new FullOpening("A22", "English Opening: King's English Variation, Two Knights Variation, Reversed Dragon", "rnbqkb1r/ppp2ppp/5n2/3pp3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 g8f6 g2g3 d7d5", "1. c4 e5 2. Nc3 Nf6 3. g3 d5"),
  new FullOpening("A22", "English Opening: King's English Variation, Two Knights Variation, Smyslov System", "rnbqk2r/pppp1ppp/5n2/4p3/1bP5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 g8f6 g2g3 f8b4", "1. c4 e5 2. Nc3 Nf6 3. g3 Bb4"),
  new FullOpening("A22", "English Opening: King's English, Erbenheimer Gambit", "rnbqkb1r/pppp1ppp/8/6N1/2P1p1n1/2N5/PP1PPPPP/R1BQKB1R w KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 e5e4 f3g5 f6g4", "1. c4 e5 2. Nc3 Nf6 3. Nf3 e4 4. Ng5 Ng4"),
  new FullOpening("A22", "English Opening: King's English, Mazedonisch", "rnbqkb1r/pppp1ppp/5n2/4p3/2P2P2/2N5/PP1PP1PP/R1BQKBNR b KQkq -", "c2c4 e7e5 b1c3 g8f6 f2f4", "1. c4 e5 2. Nc3 Nf6 3. f4"),
  new FullOpening("A23", "English Opening: King's English Variation, Two Knights Variation, Keres Variation", "rnbqkb1r/pp1p1ppp/2p2n2/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 g8f6 g2g3 c7c6", "1. c4 e5 2. Nc3 Nf6 3. g3 c6"),
  new FullOpening("A24", "English Opening: King's English Variation, Two Knights Variation, Fianchetto Line", "rnbqkb1r/pppp1p1p/5np1/4p3/2P5/2N3P1/PP1PPP1P/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 g8f6 g2g3 g7g6", "1. c4 e5 2. Nc3 Nf6 3. g3 g6"),
  new FullOpening("A25", "English Opening: Closed, Taimanov Variation", "r1bqk2r/pppp1pbp/2n3pn/4p3/2P5/2N3P1/PP1PPPBP/1RBQK1NR w Kkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 a1b1 g8h6 f1g2 f8g7", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Rb1 Nh6 5. Bg2 Bg7"),
  new FullOpening("A25", "English Opening: Closed, Taimanov Variation", "r1bqk2r/ppp2pbp/2np2pn/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R w KQkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 e2e3 d7d6 g1e2 g8h6", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 d6 6. Nge2 Nh6"),
  new FullOpening("A25", "English Opening: King's English Variation, Bremen-Hort Variation", "r2qk1nr/ppp2pbp/2npb1p1/4p3/2P5/2N1P1P1/PP1PNPBP/R1BQK2R w KQkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 e2e3 d7d6 g1e2 c8e6", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. e3 d6 6. Nge2 Be6"),
  new FullOpening("A25", "English Opening: King's English Variation, Closed System", "r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR b KQkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3"),
  new FullOpening("A25", "English Opening: King's English Variation, Hungarian Attack", "r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/1RBQK1NR b Kkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 a1b1", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Rb1"),
  new FullOpening("A25", "English Opening: King's English Variation, Reversed Closed Sicilian", "r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq -", "c2c4 e7e5 b1c3 b8c6", "1. c4 e5 2. Nc3 Nc6"),
  new FullOpening("A25", "English Opening: King's English Variation, Taimanov Variation", "r1bqk1nr/pppp1pbp/2n3p1/4p3/2P5/2N3P1/PP1PPPBP/R1BQK1NR w KQkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7"),
  new FullOpening("A26", "English Opening: King's English Variation, Botvinnik System", "r1bqk1nr/ppp2pbp/2np2p1/4p3/2P1P3/2NP2P1/PP3PBP/R1BQK1NR b KQkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6 e2e4", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. e4"),
  new FullOpening("A26", "English Opening: King's English Variation, Closed System, Full Symmetry", "r1bqk1nr/ppp2pbp/2np2p1/4p3/2P5/2NP2P1/PP2PPBP/R1BQK1NR w KQkq -", "c2c4 e7e5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6", "1. c4 e5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6"),
  new FullOpening("A27", "English Opening: King's English Variation, Three Knights System", "r1bqkbnr/pppp1ppp/2n5/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R b KQkq -", "c2c4 e7e5 b1c3 b8c6 g1f3", "1. c4 e5 2. Nc3 Nc6 3. Nf3"),
  new FullOpening("A28", "English Opening: Four Knights System, Nimzowitsch Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P1P3/2N2N2/PP1P1PPP/R1BQKB1R b KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 e2e4", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e4"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation", "r1bqk2r/pppp1pp1/5n1p/4n3/2PN3B/2P5/P3PPPP/R2QKB1R w KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 d2d4 e5d4 f3d4 f8b4 c1g5 h7h6 g5h4 b4c3 b2c3 c6e5", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d4 exd4 5. Nxd4 Bb4 6. Bg5 h6 7. Bh4 Bxc3+ 8. bxc3 Ne5"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation, Bradley Beach Variation", "r1bqkb1r/pppp1ppp/2n2n2/8/2PPp3/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 d2d4 e5e4", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d4 e4"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation, Flexible Line", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2NP1N2/PP2PPPP/R1BQKB1R b KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 d2d3", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. d3"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation, Korchnoi Line", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/P1N2N2/1P1PPPPP/R1BQKB1R b KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 a2a3", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. a3"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation, Quiet Line", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N1PN2/PP1P1PPP/R1BQKB1R b KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 e2e3", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e3"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation, Quiet Line", "r1bqk2r/pppp1ppp/2n2n2/4p3/2P5/2b1PN2/PPQP1PPP/R1B1KB1R w KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 e2e3 f8b4 d1c2 b4c3", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e3 Bb4 5. Qc2 Bxc3"),
  new FullOpening("A28", "English Opening: King's English Variation, Four Knights Variation, Quiet Line", "r1bqr1k1/pppp1ppp/2n2n2/3NpQ2/1bP5/4PN2/PP1P1PPP/R1B1KB1R b KQ -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 e2e3 f8b4 d1c2 e8g8 c3d5 f8e8 c2f5", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. e3 Bb4 5. Qc2 O-O 6. Nd5 Re8 7. Qf5"),
  new FullOpening("A29", "English Opening: King's English Variation, Four Knights Variation, Fianchetto Line", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2P5/2N2NP1/PP1PPP1P/R1BQKB1R b KQkq -", "c2c4 e7e5 b1c3 g8f6 g1f3 b8c6 g2g3", "1. c4 e5 2. Nc3 Nf6 3. Nf3 Nc6 4. g3"),
  new FullOpening("A30", "English Opening: Symmetrical Variation", "rnbqkbnr/pp1ppppp/8/2p5/2P5/8/PP1PPPPP/RNBQKBNR w KQkq -", "c2c4 c7c5", "1. c4 c5"),
  new FullOpening("A30", "English Opening: Symmetrical Variation", "rnbqkbnr/pp1ppppp/8/2p5/2P5/5N2/PP1PPPPP/RNBQKB1R b KQkq -", "c2c4 c7c5 g1f3", "1. c4 c5 2. Nf3"),
  new FullOpening("A30", "English Opening: Symmetrical Variation, Hedgehog Defense", "rn1qk2r/pb1pbppp/1p2pn2/2p5/2P5/2N2NP1/PP1PPPBP/R1BQ1RK1 w kq -", "g1f3 g8f6 c2c4 c7c5 b1c3 e7e6 g2g3 b7b6 f1g2 c8b7 e1g1 f8e7", "1. Nf3 Nf6 2. c4 c5 3. Nc3 e6 4. g3 b6 5. Bg2 Bb7 6. O-O Be7"),
  new FullOpening("A30", "English Opening: Symmetrical Variation, Napolitano Gambit", "rnbqkb1r/pp1ppppp/5n2/2p5/1PP5/5N2/P2PPPPP/RNBQKB1R b KQkq -", "c2c4 c7c5 g1f3 g8f6 b2b4", "1. c4 c5 2. Nf3 Nf6 3. b4"),
  new FullOpening("A30", "English Opening: Symmetrical, Hedgehog, Flexible Formation", "r2qk2r/1b1nbppp/pp1ppn2/8/2PQ4/1PN2NP1/P3PPBP/R1BR2K1 w kq -", "g1f3 c7c5 c2c4 g8f6 b1c3 e7e6 g2g3 b7b6 f1g2 c8b7 e1g1 f8e7 d2d4 c5d4 d1d4 d7d6 f1d1 a7a6 b2b3 b8d7", "1. Nf3 c5 2. c4 Nf6 3. Nc3 e6 4. g3 b6 5. Bg2 Bb7 6. O-O Be7 7. d4 cxd4 8. Qxd4 d6 9. Rd1 a6 10. b3 Nbd7"),
  new FullOpening("A30", "English Opening: Wing Gambit", "rnbqkbnr/pp1ppppp/8/2p5/1PP5/8/P2PPPPP/RNBQKBNR b KQkq -", "c2c4 c7c5 b2b4", "1. c4 c5 2. b4"),
  new FullOpening("A31", "English Opening: Symmetrical Variation, Anti-Benoni Variation", "rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 c7c5 g1f3", "1. d4 Nf6 2. c4 c5 3. Nf3"),
  new FullOpening("A32", "English Opening: Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense", "rnbqkb1r/pp1p1ppp/4pn2/8/2PN4/8/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 c7c5 g1f3 c5d4 f3d4 e7e6", "1. d4 Nf6 2. c4 c5 3. Nf3 cxd4 4. Nxd4 e6"),
  new FullOpening("A33", "English Opening: Symmetrical Variation, Anti-Benoni Variation, Geller Variation", "r1b1kb1r/pp1p1ppp/1qn1pn2/8/2PN4/2N3P1/PP2PP1P/R1BQKB1R w KQkq -", "g1f3 g8f6 c2c4 c7c5 b1c3 b8c6 d2d4 c5d4 f3d4 e7e6 g2g3 d8b6", "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 e6 6. g3 Qb6"),
  new FullOpening("A33", "English Opening: Symmetrical Variation, Anti-Benoni Variation, Spielmann Defense", "r1bqkb1r/pp1p1ppp/2n1pn2/8/2PN4/2N5/PP2PPPP/R1BQKB1R w KQkq -", "g1f3 g8f6 c2c4 c7c5 b1c3 b8c6 d2d4 c5d4 f3d4 e7e6", "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. d4 cxd4 5. Nxd4 e6"),
  new FullOpening("A34", "English Opening: Symmetrical Variation, Fianchetto Variation", "rnbqkb1r/pp1ppppp/5n2/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR b KQkq -", "c2c4 g8f6 b1c3 c7c5 g2g3", "1. c4 Nf6 2. Nc3 c5 3. g3"),
  new FullOpening("A34", "English Opening: Symmetrical Variation, Normal Variation", "rnbqkbnr/pp1ppppp/8/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR b KQkq -", "c2c4 c7c5 b1c3", "1. c4 c5 2. Nc3"),
  new FullOpening("A34", "English Opening: Symmetrical Variation, Rubinstein Variation", "rnbqkb1r/ppn1pppp/8/2p5/8/2N3P1/PP1PPPBP/R1BQK1NR w KQkq -", "c2c4 g8f6 b1c3 c7c5 g2g3 d7d5 c4d5 f6d5 f1g2 d5c7", "1. c4 Nf6 2. Nc3 c5 3. g3 d5 4. cxd5 Nxd5 5. Bg2 Nc7"),
  new FullOpening("A34", "English Opening: Symmetrical Variation, Three Knights Variation", "rnbqkb1r/pp2pppp/8/2pn4/8/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -", "g1f3 g8f6 c2c4 c7c5 b1c3 d7d5 c4d5 f6d5", "1. Nf3 Nf6 2. c4 c5 3. Nc3 d5 4. cxd5 Nxd5"),
  new FullOpening("A35", "English Opening: Symmetrical Variation, Four Knights Variation", "r1bqkb1r/pp1ppppp/2n2n2/2p5/2P5/2N2N2/PP1PPPPP/R1BQKB1R w KQkq -", "g1f3 g8f6 c2c4 c7c5 b1c3 b8c6", "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6"),
  new FullOpening("A35", "English Opening: Symmetrical Variation, Two Knights Variation", "r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N5/PP1PPPPP/R1BQKBNR w KQkq -", "c2c4 c7c5 b1c3 b8c6", "1. c4 c5 2. Nc3 Nc6"),
  new FullOpening("A36", "English Opening: Symmetrical Variation, Botvinnik System", "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P1P3/2N3P1/PP1P1PBP/R1BQK1NR b KQkq -", "e2e4 c7c5 c2c4 b8c6 b1c3 g7g6 g2g3 f8g7 f1g2", "1. e4 c5 2. c4 Nc6 3. Nc3 g6 4. g3 Bg7 5. Bg2"),
  new FullOpening("A36", "English Opening: Symmetrical Variation, Botvinnik System Reversed", "r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N1P1P1/PP1P1PBP/R1BQK1NR w KQkq -", "c2c4 c7c5 b1c3 g7g6 g2g3 f8g7 f1g2 b8c6 e2e3 e7e5", "1. c4 c5 2. Nc3 g6 3. g3 Bg7 4. Bg2 Nc6 5. e3 e5"),
  new FullOpening("A36", "English Opening: Symmetrical Variation, Fianchetto Variation", "r1bqkbnr/pp1ppppp/2n5/2p5/2P5/2N3P1/PP1PPP1P/R1BQKBNR b KQkq -", "c2c4 c7c5 b1c3 b8c6 g2g3", "1. c4 c5 2. Nc3 Nc6 3. g3"),
  new FullOpening("A36", "English Opening: Symmetrical Variation, Symmetrical Variation", "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N3P1/PP1PPPBP/R1BQK1NR w KQkq -", "c2c4 c7c5 g2g3 g7g6 f1g2 f8g7 b1c3 b8c6", "1. c4 c5 2. g3 g6 3. Bg2 Bg7 4. Nc3 Nc6"),
  new FullOpening("A37", "English Opening: Symmetrical Variation, Botvinnik System Reversed", "r1bqk1nr/pp1p1pbp/2n3p1/2p1p3/2P5/2N2NP1/PP1PPPBP/R1BQK2R w KQkq -", "g1f3 c7c5 c2c4 b8c6 b1c3 e7e5 g2g3 g7g6 f1g2 f8g7", "1. Nf3 c5 2. c4 Nc6 3. Nc3 e5 4. g3 g6 5. Bg2 Bg7"),
  new FullOpening("A37", "English Opening: Symmetrical Variation, Two Knights Line", "r1bqk1nr/pp1pppbp/2n3p1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R b KQkq -", "c2c4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 g1f3", "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Nf3"),
  new FullOpening("A38", "English Opening: Symmetrical Variation, Double Fianchetto", "r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/1PN2NP1/P2PPPBP/R1BQ1RK1 b - -", "c2c4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 g1f3 g8f6 e1g1 e8g8 b2b3", "1. c4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. Nf3 Nf6 6. O-O O-O 7. b3"),
  new FullOpening("A38", "English Opening: Symmetrical Variation, Duchamp Variation", "r1bq1rk1/pp1pppbp/2n2np1/2p5/2P5/2NP1NP1/PP2PPBP/R1BQ1RK1 b - -", "g1f3 g8f6 g2g3 g7g6 f1g2 f8g7 e1g1 e8g8 c2c4 c7c5 b1c3 b8c6 d2d3", "1. Nf3 Nf6 2. g3 g6 3. Bg2 Bg7 4. O-O O-O 5. c4 c5 6. Nc3 Nc6 7. d3"),
  new FullOpening("A38", "English Opening: Symmetrical Variation, Full Symmetry Line", "r1bqk2r/pp1pppbp/2n2np1/2p5/2P5/2N2NP1/PP1PPPBP/R1BQK2R w KQkq -", "g1f3 g8f6 c2c4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7", "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. g3 g6 5. Bg2 Bg7"),
  new FullOpening("A39", "English Opening: Symmetrical Variation, Mecking Variation", "r1bq1rk1/pp1pppbp/2n2np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 b - -", "g1f3 g8f6 c2c4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 e1g1 e8g8 d2d4", "1. Nf3 Nf6 2. c4 c5 3. Nc3 Nc6 4. g3 g6 5. Bg2 Bg7 6. O-O O-O 7. d4"),
  new FullOpening("A40", "Australian Defense", "r1bqkbnr/pppppppp/n7/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 b8a6", "1. d4 Na6"),
  new FullOpening("A40", "Benoni Defense: Franco-Sicilian Hybrid", "rnbqk2r/pp2npbp/3p2p1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R w KQkq -", "d2d4 e7e6 c2c4 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 e2e4 f8g7 g1f3 g8e7", "1. d4 e6 2. c4 c5 3. d5 exd5 4. cxd5 d6 5. Nc3 g6 6. e4 Bg7 7. Nf3 Ne7"),
  new FullOpening("A40", "Borg Defense: Borg Gambit", "rnbqkbnr/pppppp1p/8/6p1/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 g7g5", "1. d4 g5"),
  new FullOpening("A40", "Colle System: Pterodactyl Variation", "rnb1k1nr/pp1pppbp/6p1/q1p5/3P4/3BPN2/PPP2PPP/RNBQK2R w KQkq -", "d2d4 g7g6 g1f3 f8g7 e2e3 c7c5 f1d3 d8a5", "1. d4 g6 2. Nf3 Bg7 3. e3 c5 4. Bd3 Qa5+"),
  new FullOpening("A40", "Colle System: Siroccopteryx Variation", "rnb1k1nr/pp1pppbp/6p1/q7/3N4/3BP3/PPP2PPP/RNBQK2R w KQkq -", "d2d4 g7g6 g1f3 f8g7 e2e3 c7c5 f1d3 c5d4 f3d4 d8a5", "1. d4 g6 2. Nf3 Bg7 3. e3 c5 4. Bd3 cxd4 5. Nxd4 Qa5+"),
  new FullOpening("A40", "Dutch Defense: Senechaud Gambit", "rnbqkbnr/pppp2pp/4p3/5p2/3P1BP1/8/PPP1PP1P/RN1QKBNR b KQkq -", "d2d4 e7e6 c1f4 f7f5 g2g4", "1. d4 e6 2. Bf4 f5 3. g4"),
  new FullOpening("A40", "Dutch Defense: Stonewall Variation, Modern Variation", "rnbqk2r/pp4pp/2pbpn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w kq -", "d2d4 e7e6 g1f3 f7f5 g2g3 g8f6 f1g2 d7d5 e1g1 f8d6 c2c4 c7c6", "1. d4 e6 2. Nf3 f5 3. g3 Nf6 4. Bg2 d5 5. O-O Bd6 6. c4 c6"),
  new FullOpening("A40", "English Defense", "rnbqkbnr/p1pppppp/1p6/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 b7b6", "1. d4 b6"),
  new FullOpening("A40", "English Defense", "rnbqkbnr/p1pp1ppp/1p2p3/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 e7e6 c2c4 b7b6", "1. d4 e6 2. c4 b6"),
  new FullOpening("A40", "English Defense: Blumenfeld-Hiva Gambit", "rnbqkbnr/p2p2pp/4p3/1PpP1p2/4P3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 b7b6 c2c4 c7c5 d4d5 e7e6 e2e4 b6b5 c4b5 f7f5", "1. d4 b6 2. c4 c5 3. d5 e6 4. e4 b5 5. cxb5 f5"),
  new FullOpening("A40", "English Defense: Eastbourne Gambit", "rn1qkbnr/pbpp1ppp/1p6/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 b7b6 c2c4 c8b7 b1c3 e7e5", "1. d4 b6 2. c4 Bb7 3. Nc3 e5"),
  new FullOpening("A40", "English Defense: Hartlaub Gambit Accepted", "rn1qkb1r/pbpp2pp/1p2pn2/5P2/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq -", "c2c4 e7e6 d2d4 b7b6 b1c3 c8b7 e2e4 f7f5 e4f5 g8f6", "1. c4 e6 2. d4 b6 3. Nc3 Bb7 4. e4 f5 5. exf5 Nf6"),
  new FullOpening("A40", "English Defense: Hartlaub Gambit Declined", "rn1qkbnr/pbpp2pp/1p2p3/3P1p2/2P1P3/2N5/PP3PPP/R1BQKBNR b KQkq -", "c2c4 e7e6 d2d4 b7b6 b1c3 c8b7 e2e4 f7f5 d4d5", "1. c4 e6 2. d4 b6 3. Nc3 Bb7 4. e4 f5 5. d5"),
  new FullOpening("A40", "English Defense: Perrin Variation", "r2qkbnr/pbpp1ppp/1pn1p3/8/2PPP3/3B4/PP3PPP/RNBQK1NR w KQkq -", "d2d4 e7e6 c2c4 b7b6 e2e4 c8b7 f1d3 b8c6", "1. d4 e6 2. c4 b6 3. e4 Bb7 4. Bd3 Nc6"),
  new FullOpening("A40", "English Defense: Poli Gambit", "rn1qkb1r/pbpp2pp/1p2p2n/5P2/2PP4/5P2/PP4PP/RNBQKBNR w KQkq -", "d2d4 e7e6 c2c4 b7b6 e2e4 c8b7 f2f3 f7f5 e4f5 g8h6", "1. d4 e6 2. c4 b6 3. e4 Bb7 4. f3 f5 5. exf5 Nh6"),
  new FullOpening("A40", "Englund Gambit", "rnbqkbnr/pppp1ppp/8/4p3/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 e7e5", "1. d4 e5"),
  new FullOpening("A40", "Englund Gambit Complex Declined", "rnbqkbnr/pppp1ppp/8/3Pp3/8/8/PPP1PPPP/RNBQKBNR b KQkq -", "d2d4 e7e5 d4d5", "1. d4 e5 2. d5"),
  new FullOpening("A40", "Englund Gambit Complex Declined: Diemer Counterattack", "rnb1k1nr/pppp1ppp/8/2bPp3/4P2q/8/PPP2PPP/RNBQKBNR w KQkq -", "d2d4 e7e5 d4d5 f8c5 e2e4 d8h4", "1. d4 e5 2. d5 Bc5 3. e4 Qh4"),
  new FullOpening("A40", "Englund Gambit Complex: Englund Gambit", "r1b1kbnr/ppppqppp/2n5/4P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 e7e5 d4e5 b8c6 g1f3 d8e7", "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7"),
  new FullOpening("A40", "Englund Gambit Complex: Felbecker Gambit", "r1bqk1nr/pppp1ppp/2n5/2b1P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 e7e5 d4e5 b8c6 g1f3 f8c5", "1. d4 e5 2. dxe5 Nc6 3. Nf3 Bc5"),
  new FullOpening("A40", "Englund Gambit Complex: Hartlaub-Charlick Gambit", "rnbqkbnr/ppp2ppp/3p4/4P3/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 e7e5 d4e5 d7d6", "1. d4 e5 2. dxe5 d6"),
  new FullOpening("A40", "Englund Gambit Complex: Mosquito Gambit", "rnb1kbnr/pppp1ppp/8/4P3/7q/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 e7e5 d4e5 d8h4", "1. d4 e5 2. dxe5 Qh4"),
  new FullOpening("A40", "Englund Gambit Complex: Soller Gambit", "rnbqkbnr/pppp2pp/5p2/4P3/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 e7e5 d4e5 f7f6", "1. d4 e5 2. dxe5 f6"),
  new FullOpening("A40", "Englund Gambit Complex: Soller Gambit Deferred", "r1bqkbnr/pppp2pp/2n2p2/4P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 e7e5 d4e5 b8c6 g1f3 f7f6", "1. d4 e5 2. dxe5 Nc6 3. Nf3 f6"),
  new FullOpening("A40", "Englund Gambit Complex: Stockholm Variation", "r1b1kbnr/ppppqppp/2n5/3QP3/8/5N2/PPP1PPPP/RNB1KB1R b KQkq -", "d2d4 e7e5 d4e5 b8c6 g1f3 d8e7 d1d5", "1. d4 e5 2. dxe5 Nc6 3. Nf3 Qe7 4. Qd5"),
  new FullOpening("A40", "Englund Gambit Complex: Zilbermints Gambit", "r1bqkb1r/ppppnppp/2n5/4P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 e7e5 d4e5 b8c6 g1f3 g8e7", "1. d4 e5 2. dxe5 Nc6 3. Nf3 Nge7"),
  new FullOpening("A40", "Englund Gambit Complex: Zilbermints Gambit", "r1bqkbnr/pppp1pp1/2n4p/4P3/8/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 e7e5 d4e5 b8c6 g1f3 h7h6", "1. d4 e5 2. dxe5 Nc6 3. Nf3 h6"),
  new FullOpening("A40", "Englund Gambit Declined: Reversed Alekhine", "rnbqkbnr/pppp1ppp/8/4p3/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq -", "d2d4 e7e5 g1f3", "1. d4 e5 2. Nf3"),
  new FullOpening("A40", "Englund Gambit Declined: Reversed Brooklyn", "rnbqkbnr/pppp1ppp/8/8/3Pp3/8/PPP1PPPP/RNBQKBNR b KQkq -", "d2d4 e7e5 g1f3 e5e4 f3g1", "1. d4 e5 2. Nf3 e4 3. Ng1"),
  new FullOpening("A40", "Englund Gambit Declined: Reversed French", "rnbqkbnr/pppp1ppp/8/4p3/3P4/4P3/PPP2PPP/RNBQKBNR b KQkq -", "d2d4 e7e5 e2e3", "1. d4 e5 2. e3"),
  new FullOpening("A40", "Englund Gambit Declined: Reversed Krebs", "rnbqkbnr/pppp1ppp/8/8/3Pp3/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 e7e5 g1f3 e5e4", "1. d4 e5 2. Nf3 e4"),
  new FullOpening("A40", "Englund Gambit Declined: Reversed Mokele Mbembe", "rnbqkbnr/pppp1ppp/8/4N3/3Pp3/8/PPP1PPPP/RNBQKB1R b KQkq -", "d2d4 e7e5 g1f3 e5e4 f3e5", "1. d4 e5 2. Nf3 e4 3. Ne5"),
  new FullOpening("A40", "Horwitz Defense", "rnbqkbnr/pppp1ppp/4p3/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 e7e6", "1. d4 e6"),
  new FullOpening("A40", "Horwitz Defense: Zilbermints Gambit", "rnbqkbnr/pppp1ppp/8/4p3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 e7e6 c2c4 e6e5", "1. d4 e6 2. c4 e5"),
  new FullOpening("A40", "Kangaroo Defense", "rnbqk1nr/pppp1ppp/4p3/8/1bPP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 e7e6 c2c4 f8b4", "1. d4 e6 2. c4 Bb4+"),
  new FullOpening("A40", "Kangaroo Defense: Keres Defense, Transpositional Variation", "rnbqk1nr/pppp1ppp/4p3/8/1bPP4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 e7e6 c2c4 f8b4 b1c3", "1. d4 e6 2. c4 Bb4+ 3. Nc3"),
  new FullOpening("A40", "Mikenas Defense", "r1bqkbnr/pppppppp/2n5/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 b8c6", "1. d4 Nc6"),
  new FullOpening("A40", "Mikenas Defense: Cannstatter Variation", "r1bqkbnr/pppp1ppp/8/3Pp3/2Pn4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 b8c6 c2c4 e7e5 d4d5 c6d4", "1. d4 Nc6 2. c4 e5 3. d5 Nd4"),
  new FullOpening("A40", "Mikenas Defense: Lithuanian Variation", "r1bqkbnr/ppppnppp/8/3Pp3/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 b8c6 c2c4 e7e5 d4d5 c6e7", "1. d4 Nc6 2. c4 e5 3. d5 Nce7"),
  new FullOpening("A40", "Mikenas Defense: Pozarek Gambit", "r1bqkbnr/pppp1ppp/8/8/2n5/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 b8c6 c2c4 e7e5 d4e5 c6e5 b1c3 e5c4", "1. d4 Nc6 2. c4 e5 3. dxe5 Nxe5 4. Nc3 Nxc4"),
  new FullOpening("A40", "Modern Defense", "rnbqkbnr/pppppp1p/6p1/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 g7g6", "1. d4 g6"),
  new FullOpening("A40", "Modern Defense: Beefeater Variation", "rnbqk1nr/pp1pp2p/6p1/2pP1p2/2P5/2P5/P3PPPP/R1BQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 c7c5 d4d5 g7c3 b2c3 f7f5", "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 f5"),
  new FullOpening("A40", "Modern Defense: Lizard Defense, Pirc-Diemer Gambit", "rnbqkb1r/pppppp1p/5np1/7P/3P4/8/PPP1PPP1/RNBQKBNR b KQkq -", "d2d4 g7g6 h2h4 g8f6 h4h5", "1. d4 g6 2. h4 Nf6 3. h5"),
  new FullOpening("A40", "Montevideo Defense", "rnbqkbnr/pppppppp/8/3P4/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 b8c6 d4d5 c6b8", "1. d4 Nc6 2. d5 Nb8"),
  new FullOpening("A40", "Polish Defense", "rnbqkbnr/p1pppppp/8/1p6/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 b7b5", "1. d4 b5"),
  new FullOpening("A40", "Polish Defense: Spassky Gambit Accepted", "rn1qkbnr/pbpppppp/8/1B6/3PP3/8/PPP2PPP/RNBQK1NR b KQkq -", "d2d4 b7b5 e2e4 c8b7 f1b5", "1. d4 b5 2. e4 Bb7 3. Bxb5"),
  new FullOpening("A40", "Pterodactyl Defense: Central, Benoni Beefeater Pterodactyl", "rnb1k1nr/pp2pp1p/3p2p1/q1pP4/2P1P3/2P5/P4PPP/R1BQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 c7c5 d4d5 d7d6 e2e4 g7c3 b2c3 d8a5", "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 d6 5. e4 Bxc3+ 6. bxc3 Qa5"),
  new FullOpening("A40", "Pterodactyl Defense: Central, Benoni Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1pP4/2P1P3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 e2e4 c7c5 d4d5 d8a5", "1. d4 g6 2. c4 Bg7 3. e4 c5 4. d5 Qa5+"),
  new FullOpening("A40", "Pterodactyl Defense: Fianchetto, Queen Benoni Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1pP4/2P5/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 c7c5 d4d5 d8a5", "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Qa5"),
  new FullOpening("A40", "Pterodactyl Defense: Fianchetto, Queen Pteranodon", "rnb1k1nr/pp1ppp1p/6p1/q1pP4/2P5/2P5/P3PPPP/R1BQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 c7c5 d4d5 g7c3 b2c3 d8a5", "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 Qa5"),
  new FullOpening("A40", "Pterodactyl Defense: Fianchetto, Queen Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1p5/3P4/5NP1/PPP1PPBP/RNBQK2R w KQkq -", "d2d4 g7g6 g1f3 f8g7 g2g3 c7c5 f1g2 d8a5", "1. d4 g6 2. Nf3 Bg7 3. g3 c5 4. Bg2 Qa5+"),
  new FullOpening("A40", "Pterodactyl Defense: Queen Pterodactyl, Quiet Line", "rnbqk1nr/pp1pppbp/6p1/2p5/2PP4/2N1P3/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 c7c5 e2e3", "1. d4 g6 2. c4 Bg7 3. Nc3 c5 4. e3"),
  new FullOpening("A40", "Queen's Pawn Game", "rnbqkbnr/pppppppp/8/8/3P4/8/PPP1PPPP/RNBQKBNR b KQkq -", "d2d4", "1. d4"),
  new FullOpening("A40", "Queen's Pawn Game: Anglo-Slav Opening", "rnbqkbnr/pp2pppp/2pp4/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 c7c6 c2c4 d7d6", "1. d4 c6 2. c4 d6"),
  new FullOpening("A40", "Slav Indian: Kudischewitsch Gambit", "rnbqkb1r/p2ppppp/2p2n2/1p6/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 c7c6 g1f3 g8f6 c2c4 b7b5", "1. d4 c6 2. Nf3 Nf6 3. c4 b5"),
  new FullOpening("A40", "Zaire Defense", "rnbqkbnr/pppppppp/8/3PP3/8/8/PPP2PPP/RNBQKBNR w KQkq -", "d2d4 b8c6 d4d5 c6b8 e2e4 g8f6 e4e5 f6g8", "1. d4 Nc6 2. d5 Nb8 3. e4 Nf6 4. e5 Ng8"),
  new FullOpening("A41", "English Rat: Pounds Gambit", "rn1qkbnr/ppp2ppp/3pb3/4P3/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d6 c2c4 e7e5 d4e5 c8e6", "1. d4 d6 2. c4 e5 3. dxe5 Be6"),
  new FullOpening("A41", "Modern Defense", "rnbqk1nr/ppp1ppbp/3p2p1/8/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 d7d6", "1. d4 g6 2. c4 Bg7 3. Nc3 d6"),
  new FullOpening("A41", "Modern Defense: Neo-Modern Defense", "rnbqk1nr/pppp1pbp/6p1/4p3/2PPP3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 e2e4 e7e5", "1. d4 g6 2. c4 Bg7 3. e4 e5"),
  new FullOpening("A41", "Old Indian Defense", "rnbqkbnr/ppp1pppp/3p4/8/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 d7d6 c2c4", "1. d4 d6 2. c4"),
  new FullOpening("A41", "Queen's Pawn Game", "rnbqkbnr/ppp1pppp/3p4/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 d7d6", "1. d4 d6"),
  new FullOpening("A41", "Rat Defense: English Rat", "rnbqkbnr/ppp2ppp/3p4/4p3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d6 c2c4 e7e5", "1. d4 d6 2. c4 e5"),
  new FullOpening("A41", "Rat Defense: English Rat, Lisbon Gambit", "r1bqkbnr/ppp2ppp/2np4/4P3/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d6 c2c4 e7e5 d4e5 b8c6", "1. d4 d6 2. c4 e5 3. dxe5 Nc6"),
  new FullOpening("A41", "Robatsch Defense", "rn1qk1nr/ppp1ppbp/3p2p1/8/2PPP1b1/5N2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d6 g1f3 g7g6 c2c4 f8g7 e2e4 c8g4", "1. d4 d6 2. Nf3 g6 3. c4 Bg7 4. e4 Bg4"),
  new FullOpening("A41", "Wade Defense", "rn1qkbnr/ppp1pppp/3p4/8/3P2b1/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 d7d6 g1f3 c8g4", "1. d4 d6 2. Nf3 Bg4"),
  new FullOpening("A41", "Zukertort Opening: Wade Defense, Chigorin Plan", "1r1qkbnr/pppnpppp/3p4/8/2PP2b1/1Q3N2/PP2PPPP/RNB1KB1R w KQk -", "d2d4 d7d6 g1f3 c8g4 c2c4 b8d7 d1b3 a8b8", "1. d4 d6 2. Nf3 Bg4 3. c4 Nd7 4. Qb3 Rb8"),
  new FullOpening("A42", "Modern Defense: Averbakh System", "rnbqk1nr/ppp1ppbp/3p2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 d7d6 e2e4", "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4"),
  new FullOpening("A42", "Modern Defense: Averbakh Variation, Pseudo-Sämisch", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/4BP2/PP4PP/RN1QKBNR b KQkq -", "d2d4 g7g6 c2c4 f8g7 e2e4 d7d6 c1e3 g8f6 f2f3", "1. d4 g6 2. c4 Bg7 3. e4 d6 4. Be3 Nf6 5. f3"),
  new FullOpening("A42", "Modern Defense: Kotov Variation", "r1bqk1nr/ppp1ppbp/2np2p1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 d7d6 e2e4 b8c6", "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 Nc6"),
  new FullOpening("A42", "Modern Defense: Randspringer Variation", "rnbqk1nr/ppp1p1bp/3p2p1/5p2/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -", "d2d4 g7g6 c2c4 f8g7 b1c3 d7d6 e2e4 f7f5", "1. d4 g6 2. c4 Bg7 3. Nc3 d6 4. e4 f5"),
  new FullOpening("A42", "Pterodactyl Defense", "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/2N2N2/PP3PPP/R1BQKB1R w KQkq -", "g1f3 c7c5 c2c4 g7g6 d2d4 f8g7 e2e4 d8a5 b1c3 d7d6", "1. Nf3 c5 2. c4 g6 3. d4 Bg7 4. e4 Qa5+ 5. Nc3 d6"),
  new FullOpening("A42", "Pterodactyl Defense: Central, Bogolubovia", "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/2N5/PP2NPPP/R1BQKB1R w KQkq -", "d2d4 g7g6 c2c4 f8g7 e2e4 d7d6 b1c3 c7c5 g1e2 d8a5", "1. d4 g6 2. c4 Bg7 3. e4 d6 4. Nc3 c5 5. Nge2 Qa5"),
  new FullOpening("A43", "Benoni Defense: Benoni Gambit Accepted", "rnbqkbnr/pp1ppppp/8/2P5/8/8/PPP1PPPP/RNBQKBNR b KQkq -", "d2d4 c7c5 d4c5", "1. d4 c5 2. dxc5"),
  new FullOpening("A43", "Benoni Defense: Benoni Gambit, Schlenker Defense", "r1bqkbnr/pp1ppppp/n7/2P5/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 c7c5 d4c5 b8a6", "1. d4 c5 2. dxc5 Na6"),
  new FullOpening("A43", "Benoni Defense: Benoni-Indian Defense", "rnbqkb1r/pp1ppppp/5n2/2pP4/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 c7c5 d4d5 g8f6", "1. d4 c5 2. d5 Nf6"),
  new FullOpening("A43", "Benoni Defense: Benoni-Indian Defense, Kingside Move Order", "rnbqkb1r/pp1ppppp/5n2/2pP4/8/5N2/PPP1PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 g1f3 c7c5 d4d5", "1. d4 Nf6 2. Nf3 c5 3. d5"),
  new FullOpening("A43", "Benoni Defense: Benoni-Staunton Gambit", "rnbqkbnr/pp1pp1pp/8/2pP1p2/4P3/8/PPP2PPP/RNBQKBNR b KQkq -", "d2d4 c7c5 d4d5 f7f5 e2e4", "1. d4 c5 2. d5 f5 3. e4"),
  new FullOpening("A43", "Benoni Defense: Cormorant Gambit", "rnbqkbnr/p2ppppp/1p6/2P5/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 c7c5 d4c5 b7b6", "1. d4 c5 2. dxc5 b6"),
  new FullOpening("A43", "Benoni Defense: French Benoni", "rnbqkbnr/pp1p1ppp/4p3/2pP4/4P3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 c7c5 d4d5", "1. e4 e6 2. d4 c5 3. d5"),
  new FullOpening("A43", "Benoni Defense: Hawk Variation", "rnbqkb1r/pp1ppppp/5n2/3P4/2p5/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 c7c5 d4d5 c5c4", "1. d4 Nf6 2. Nf3 c5 3. d5 c4"),
  new FullOpening("A43", "Benoni Defense: Old Benoni", "rnbqkbnr/pp1ppppp/8/2p5/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 c7c5", "1. d4 c5"),
  new FullOpening("A43", "Benoni Defense: Old Benoni", "rnbqkbnr/pp1ppppp/8/2pP4/8/8/PPP1PPPP/RNBQKBNR b KQkq -", "d2d4 c7c5 d4d5", "1. d4 c5 2. d5"),
  new FullOpening("A43", "Benoni Defense: Old Benoni", "rnbqkbnr/pp2pppp/3p4/2pP4/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 c7c5 d4d5 d7d6", "1. d4 c5 2. d5 d6"),
  new FullOpening("A43", "Benoni Defense: Old Benoni, Mujannah Formation", "rnbqkbnr/pp1pp1pp/8/2pP1p2/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 c7c5 d4d5 f7f5", "1. d4 c5 2. d5 f5"),
  new FullOpening("A43", "Benoni Defense: Old Benoni, Schmid Variation", "rnbqkbnr/pp2pp1p/3p2p1/2pP4/8/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 c7c5 d4d5 d7d6 b1c3 g7g6", "1. d4 c5 2. d5 d6 3. Nc3 g6"),
  new FullOpening("A43", "Benoni Defense: Snail Variation", "r1bqkbnr/pp1ppppp/n7/2pP4/8/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 c7c5 d4d5 b8a6", "1. d4 c5 2. d5 Na6"),
  new FullOpening("A43", "Benoni Defense: Woozle", "rnb1kb1r/pp1ppppp/5n2/q1pP4/8/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 c7c5 d4d5 g8f6 b1c3 d8a5", "1. d4 c5 2. d5 Nf6 3. Nc3 Qa5"),
  new FullOpening("A43", "Benoni Defense: Zilbermints-Benoni Gambit", "rnbqkbnr/pp1ppppp/8/2p5/1P1P4/8/P1P1PPPP/RNBQKBNR b KQkq -", "d2d4 c7c5 b2b4", "1. d4 c5 2. b4"),
  new FullOpening("A43", "Benoni Defense: Zilbermints-Benoni Gambit", "rnbqkbnr/pp1ppppp/8/8/1P1p4/5N2/P1P1PPPP/RNBQKB1R b KQkq -", "d2d4 c7c5 g1f3 c5d4 b2b4", "1. d4 c5 2. Nf3 cxd4 3. b4"),
  new FullOpening("A43", "Benoni Defense: Zilbermints-Benoni Gambit, Tamarkin Countergambit", "rnbqkbnr/pp1p1ppp/8/4p3/1P1p4/5N2/P1P1PPPP/RNBQKB1R w KQkq -", "d2d4 c7c5 g1f3 c5d4 b2b4 e7e5", "1. d4 c5 2. Nf3 cxd4 3. b4 e5"),
  new FullOpening("A43", "Indian Defense: Pseudo-Benko", "rnbqkb1r/p2ppppp/5n2/1ppP4/8/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 c7c5 d4d5 b7b5", "1. d4 Nf6 2. Nf3 c5 3. d5 b5"),
  new FullOpening("A43", "Queen's Pawn Game: Liedmann Gambit", "rnbqkbnr/pp1ppppp/8/8/2Pp4/4P3/PP3PPP/RNBQKBNR b KQkq -", "d2d4 c7c5 c2c4 c5d4 e2e3", "1. d4 c5 2. c4 cxd4 3. e3"),
  new FullOpening("A44", "Benoni Defense: Old Benoni", "rnbqkbnr/pp1p1ppp/8/2pPp3/8/8/PPP1PPPP/RNBQKBNR w KQkq e6", "d2d4 c7c5 d4d5 e7e5", "1. d4 c5 2. d5 e5"),
  new FullOpening("A44", "Benoni Defense: Semi-Benoni", "rnbqkbnr/pp3ppp/3p4/2pPp3/4P3/8/PPP2PPP/RNBQKBNR w KQkq -", "d2d4 c7c5 d4d5 e7e5 e2e4 d7d6", "1. d4 c5 2. d5 e5 3. e4 d6"),
  new FullOpening("A45", "Amazon Attack: Siberian Attack", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2NQ4/PPP1PPPP/R1B1KBNR b KQkq -", "d2d4 g8f6 b1c3 d7d5 d1d3", "1. d4 Nf6 2. Nc3 d5 3. Qd3"),
  new FullOpening("A45", "Bronstein Gambit", "rnbqkb1r/pppppppp/5n2/8/3P2P1/8/PPP1PP1P/RNBQKBNR b KQkq -", "d2d4 g8f6 g2g4", "1. d4 Nf6 2. g4"),
  new FullOpening("A45", "Canard Opening", "rnbqkb1r/pppppppp/5n2/8/3P1P2/8/PPP1P1PP/RNBQKBNR b KQkq -", "d2d4 g8f6 f2f4", "1. d4 Nf6 2. f4"),
  new FullOpening("A45", "Indian Defense", "rnbqkb1r/pppppppp/5n2/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6", "1. d4 Nf6"),
  new FullOpening("A45", "Indian Defense: Gedult Attack, Gedult Attack", "rnbqkb1r/ppp1pppp/5n2/3p4/3P2P1/5P2/PPP1P2P/RNBQKBNR b KQkq -", "d2d4 g8f6 f2f3 d7d5 g2g4", "1. d4 Nf6 2. f3 d5 3. g4"),
  new FullOpening("A45", "Indian Defense: Gibbins-Wiedenhagen Gambit Accepted", "rnbqkb1r/pppppppp/8/8/3P2n1/8/PPP1PP1P/RNBQKBNR w KQkq -", "d2d4 g8f6 g2g4 f6g4", "1. d4 Nf6 2. g4 Nxg4"),
  new FullOpening("A45", "Indian Defense: Gibbins-Wiedenhagen Gambit, Maltese Falcon", "rnbqkb1r/pppppppp/5n2/8/3PP3/5P2/PPP4P/RNBQKBNR b KQkq -", "d2d4 g8f6 g2g4 f6g4 f2f3 g4f6 e2e4", "1. d4 Nf6 2. g4 Nxg4 3. f3 Nf6 4. e4"),
  new FullOpening("A45", "Indian Defense: Gibbins-Wiedenhagen Gambit, Oshima Defense", "rnbqkb1r/pppp1ppp/5n2/4p3/3P2P1/8/PPP1PP1P/RNBQKBNR w KQkq -", "d2d4 g8f6 g2g4 e7e5", "1. d4 Nf6 2. g4 e5"),
  new FullOpening("A45", "Indian Defense: Gibbins-Wiedenhagen Gambit, Stummer Gambit", "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/2N5/PPP1BP1P/R1BQK1NR b KQkq -", "d2d4 g8f6 g2g4 f6g4 e2e4 d7d6 f1e2 g4f6 b1c3", "1. d4 Nf6 2. g4 Nxg4 3. e4 d6 4. Be2 Nf6 5. Nc3"),
  new FullOpening("A45", "Indian Defense: Lazard Gambit", "rnbqkb1r/pppp1ppp/5n2/4p3/3P4/8/PPPNPPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1d2 e7e5", "1. d4 Nf6 2. Nd2 e5"),
  new FullOpening("A45", "Indian Defense: Maddigan Gambit", "rnbqkb1r/pppp1ppp/5n2/4p3/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1c3 e7e5", "1. d4 Nf6 2. Nc3 e5"),
  new FullOpening("A45", "Indian Defense: Omega Gambit", "rnbqkb1r/pppppppp/5n2/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "d2d4 g8f6 e2e4", "1. d4 Nf6 2. e4"),
  new FullOpening("A45", "Indian Defense: Omega Gambit, Arafat Gambit", "rnbqkb1r/pppppppp/5n2/6B1/3P4/3B4/PPP2PPP/RN1QK1NR b KQkq -", "d2d4 g8f6 e2e4 f6e4 f1d3 e4f6 c1g5", "1. d4 Nf6 2. e4 Nxe4 3. Bd3 Nf6 4. Bg5"),
  new FullOpening("A45", "Indian Defense: Paleface Attack, Blackmar-Diemer Gambit Deferred", "rnbqkb1r/ppp1pppp/5n2/3p4/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq -", "d2d4 g8f6 f2f3 d7d5 e2e4", "1. d4 Nf6 2. f3 d5 3. e4"),
  new FullOpening("A45", "Indian Defense: Pawn Push Variation", "rnbqkb1r/pppppppp/5n2/3P4/8/8/PPP1PPPP/RNBQKBNR b KQkq -", "d2d4 g8f6 d4d5", "1. d4 Nf6 2. d5"),
  new FullOpening("A45", "Indian Defense: Reversed Chigorin Defense", "rnbqkb1r/pp1ppppp/5n2/2p5/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1c3 c7c5", "1. d4 Nf6 2. Nc3 c5"),
  new FullOpening("A45", "Indian Defense: Tartakower Attack", "rnbqkb1r/pppppppp/5n2/8/3P4/6P1/PPP1PP1P/RNBQKBNR b KQkq -", "d2d4 g8f6 g2g3", "1. d4 Nf6 2. g3"),
  new FullOpening("A45", "Paleface Attack", "rnbqkb1r/pppppppp/5n2/8/3P4/5P2/PPP1P1PP/RNBQKBNR b KQkq -", "d2d4 g8f6 f2f3", "1. d4 Nf6 2. f3"),
  new FullOpening("A45", "Queen's Pawn Game: Chigorin Variation", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1c3 d7d5", "1. d4 Nf6 2. Nc3 d5"),
  new FullOpening("A45", "Queen's Pawn Game: Veresov, Richter Attack", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/2N2P2/PPP1P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 f2f3 d7d5 b1c3", "1. d4 Nf6 2. f3 d5 3. Nc3"),
  new FullOpening("A45", "Trompowsky Attack", "rnbqkb1r/pppppppp/5n2/6B1/3P4/8/PPP1PPPP/RN1QKBNR b KQkq -", "d2d4 g8f6 c1g5", "1. d4 Nf6 2. Bg5"),
  new FullOpening("A45", "Trompowsky Attack: Borg Variation", "rnbqkb1r/pppppp1p/8/6p1/3PnB2/8/PPP1PPPP/RN1QKBNR w KQkq -", "d2d4 g8f6 c1g5 f6e4 g5f4 g7g5", "1. d4 Nf6 2. Bg5 Ne4 3. Bf4 g5"),
  new FullOpening("A45", "Trompowsky Attack: Classical Defense", "rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/8/PPP1PPPP/RN1QKBNR w KQkq -", "d2d4 g8f6 c1g5 e7e6", "1. d4 Nf6 2. Bg5 e6"),
  new FullOpening("A45", "Trompowsky Attack: Classical Defense, Big Center Variation", "rnbqkb1r/pppp1ppp/4pn2/6B1/3PP3/8/PPP2PPP/RN1QKBNR b KQkq -", "d2d4 g8f6 c1g5 e7e6 e2e4", "1. d4 Nf6 2. Bg5 e6 3. e4"),
  new FullOpening("A45", "Trompowsky Attack: Edge Variation", "rnbqkb1r/pppppppp/8/8/3Pn2B/8/PPP1PPPP/RN1QKBNR b KQkq -", "d2d4 g8f6 c1g5 f6e4 g5h4", "1. d4 Nf6 2. Bg5 Ne4 3. Bh4"),
  new FullOpening("A45", "Trompowsky Attack: Edge Variation, Hergert Gambit", "rnb1kb1r/pp2pppp/2p5/q2p4/3PP2B/2P5/PP1Q1PPP/R3KBNR b KQkq -", "d2d4 g8f6 c1g5 f6e4 g5h4 c7c6 b1d2 d8a5 c2c3 e4d2 d1d2 d7d5 e2e4", "1. d4 Nf6 2. Bg5 Ne4 3. Bh4 c6 4. Nd2 Qa5 5. c3 Nxd2 6. Qxd2 d5 7. e4"),
  new FullOpening("A45", "Trompowsky Attack: Edge Variation, Hergert Gambit", "rn1qkb1r/ppp1pppp/5n2/3p1b2/3PP2B/2N2P2/PPP3PP/R2QKBNR b KQkq -", "d2d4 g8f6 c1g5 f6e4 g5h4 d7d5 f2f3 e4f6 b1c3 c8f5 e2e4", "1. d4 Nf6 2. Bg5 Ne4 3. Bh4 d5 4. f3 Nf6 5. Nc3 Bf5 6. e4"),
  new FullOpening("A45", "Trompowsky Attack: Poisoned Pawn Variation", "rnb1kb1r/pp1ppppp/1q3n2/2pP2B1/8/2N5/PPP1PPPP/R2QKBNR b KQkq -", "d2d4 g8f6 c1g5 c7c5 d4d5 d8b6 b1c3", "1. d4 Nf6 2. Bg5 c5 3. d5 Qb6 4. Nc3"),
  new FullOpening("A45", "Trompowsky Attack: Raptor Variation", "rnbqkb1r/pppppppp/8/6B1/3Pn2P/8/PPP1PPP1/RN1QKBNR b KQkq -", "d2d4 g8f6 c1g5 f6e4 h2h4", "1. d4 Nf6 2. Bg5 Ne4 3. h4"),
  new FullOpening("A45", "Trompowsky Attack: Raptor Variation, Hergert Gambit", "rnbqkb1r/pppp1ppp/8/4p1P1/3P4/8/PPP1PPP1/RN1QKBNR w KQkq -", "d2d4 g8f6 c1g5 f6e4 h2h4 e4g5 h4g5 e7e5", "1. d4 Nf6 2. Bg5 Ne4 3. h4 Nxg5 4. hxg5 e5"),
  new FullOpening("A46", "Döry Defense", "rnbqkb1r/pppppppp/8/8/3Pn3/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 f6e4", "1. d4 Nf6 2. Nf3 Ne4"),
  new FullOpening("A46", "Indian Defense: Czech-Indian", "rnbqkb1r/pp1ppppp/2p2n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 c7c6", "1. d4 Nf6 2. Nf3 c6"),
  new FullOpening("A46", "Indian Defense: Knights Variation", "rnbqkb1r/pppppppp/5n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 g1f3", "1. d4 Nf6 2. Nf3"),
  new FullOpening("A46", "Indian Defense: Knights Variation, Alburt-Miles Variation", "rnbqkb1r/1ppppppp/p4n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 a7a6", "1. d4 Nf6 2. Nf3 a6"),
  new FullOpening("A46", "Indian Defense: London System", "rnbqkb1r/pppp1ppp/4pn2/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq -", "d2d4 g8f6 g1f3 e7e6 c1f4", "1. d4 Nf6 2. Nf3 e6 3. Bf4"),
  new FullOpening("A46", "Indian Defense: Polish Variation", "rnbqkb1r/p1pppppp/5n2/1p6/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 b7b5", "1. d4 Nf6 2. Nf3 b5"),
  new FullOpening("A46", "Indian Defense: Spielmann-Indian", "rnbqkb1r/pp1ppppp/5n2/2p5/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 c7c5", "1. d4 Nf6 2. Nf3 c5"),
  new FullOpening("A46", "Indian Defense: Wade-Tartakower Defense", "rnbqkb1r/ppp1pppp/3p1n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 d7d6", "1. d4 Nf6 2. Nf3 d6"),
  new FullOpening("A46", "Old Indian Defense: Czech Variation", "rnbqkb1r/pp2pppp/2pp1n2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 c7c6 c2c4 d7d6", "1. d4 Nf6 2. Nf3 c6 3. c4 d6"),
  new FullOpening("A46", "Queen's Pawn Game: Torre Attack, Breyer Variation", "rnb1kb1r/pp3ppp/1q2pn2/2pp2B1/3P4/2P1PN2/PP3PPP/RN1QKB1R w KQkq -", "d2d4 g8f6 g1f3 e7e6 c1g5 d7d5 e2e3 c7c5 c2c3 d8b6", "1. d4 Nf6 2. Nf3 e6 3. Bg5 d5 4. e3 c5 5. c3 Qb6"),
  new FullOpening("A46", "Queen's Pawn Game: Veresov Attack, Classical Defense", "rnbqkb1r/ppp2ppp/4pn2/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 g1f3 e7e6 b1c3 d7d5 c1g5", "1. d4 Nf6 2. Nf3 e6 3. Nc3 d5 4. Bg5"),
  new FullOpening("A46", "Torre Attack", "rnbqkb1r/pppp1ppp/4pn2/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq -", "d2d4 g8f6 g1f3 e7e6 c1g5", "1. d4 Nf6 2. Nf3 e6 3. Bg5"),
  new FullOpening("A46", "Torre Attack: Classical Defense, Nimzowitsch Variation", "rnbqkb1r/pppp1pp1/4pn1p/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 g1f3 e7e6 c1g5 h7h6", "1. d4 Nf6 2. Nf3 e6 3. Bg5 h6"),
  new FullOpening("A46", "Torre Attack: Classical Defense, Petrosian Gambit", "rnbqkb1r/p2p1ppp/1p2pn2/2pP2B1/8/4PN2/PPP2PPP/RN1QKB1R b KQkq -", "d2d4 g8f6 g1f3 e7e6 c1g5 c7c5 e2e3 b7b6 d4d5", "1. d4 Nf6 2. Nf3 e6 3. Bg5 c5 4. e3 b6 5. d5"),
  new FullOpening("A46", "Torre Attack: Wagner Gambit", "rnbqkb1r/pp1p1ppp/4pn2/2p3B1/3PP3/5N2/PPP2PPP/RN1QKB1R b KQkq -", "d2d4 g8f6 g1f3 e7e6 c1g5 c7c5 e2e4", "1. d4 Nf6 2. Nf3 e6 3. Bg5 c5 4. e4"),
  new FullOpening("A46", "Yusupov-Rubinstein System", "rnbqkb1r/pppp1ppp/4pn2/8/3P4/4PN2/PPP2PPP/RNBQKB1R b KQkq -", "d2d4 g8f6 g1f3 e7e6 e2e3", "1. d4 Nf6 2. Nf3 e6 3. e3"),
  new FullOpening("A47", "Indian Defense: Schnepper Gambit", "rnbqkb1r/p1pp1ppp/1p3n2/4p3/3P4/2P2N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 b7b6 c2c3 e7e5", "1. d4 Nf6 2. Nf3 b6 3. c3 e5"),
  new FullOpening("A47", "Marienbad System", "rn1qkb1r/pb1ppppp/1p3n2/2p5/3P4/5NP1/PPP1PPBP/RNBQK2R w KQkq -", "d2d4 g8f6 g1f3 b7b6 g2g3 c8b7 f1g2 c7c5", "1. d4 Nf6 2. Nf3 b6 3. g3 Bb7 4. Bg2 c5"),
  new FullOpening("A47", "Queen's Indian Defense", "rnbqkb1r/p1pppppp/1p3n2/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 b7b6", "1. d4 Nf6 2. Nf3 b6"),
  new FullOpening("A47", "Queen's Indian Defense: Marienbad System, Berg Variation", "rn1qkb1r/pb1ppppp/1p3n2/8/2PQ4/5NP1/PP2PPBP/RNB1K2R b KQkq -", "d2d4 g8f6 g1f3 b7b6 g2g3 c8b7 f1g2 c7c5 c2c4 c5d4 d1d4", "1. d4 Nf6 2. Nf3 b6 3. g3 Bb7 4. Bg2 c5 5. c4 cxd4 6. Qxd4"),
  new FullOpening("A48", "East Indian Defense", "rnbqkb1r/pppppp1p/5np1/8/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 g7g6", "1. d4 Nf6 2. Nf3 g6"),
  new FullOpening("A48", "Indian Defense: Colle System, King's Indian Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/3P4/3BPN2/PPP2PPP/RNBQK2R w KQkq -", "d2d4 g8f6 g1f3 g7g6 e2e3 f8g7 f1d3 d7d6", "1. d4 Nf6 2. Nf3 g6 3. e3 Bg7 4. Bd3 d6"),
  new FullOpening("A48", "London System", "rnbqkb1r/pppppp1p/5np1/8/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq -", "d2d4 g8f6 g1f3 g7g6 c1f4", "1. d4 Nf6 2. Nf3 g6 3. Bf4"),
  new FullOpening("A48", "Queen's Pawn Game: Barry Attack, Grünfeld Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/3P1B2/2N1PN2/PPP1BPPP/R2QK2R b KQ -", "d2d4 g8f6 g1f3 g7g6 b1c3 d7d5 c1f4 f8g7 e2e3 e8g8 f1e2", "1. d4 Nf6 2. Nf3 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Be2"),
  new FullOpening("A48", "Queen's Pawn Game: Torre Attack, Grünfeld Variation, Main Line", "rnbq1rk1/ppp1ppbp/5np1/3p2B1/3P4/4PN2/PPPN1PPP/R2QKB1R w KQ -", "d2d4 g8f6 g1f3 g7g6 c1g5 f8g7 b1d2 d7d5 e2e3 e8g8", "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. Nbd2 d5 5. e3 O-O"),
  new FullOpening("A48", "Torre Attack", "rnbqkb1r/pppppp1p/5np1/6B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq -", "d2d4 g8f6 g1f3 g7g6 c1g5", "1. d4 Nf6 2. Nf3 g6 3. Bg5"),
  new FullOpening("A48", "Torre Attack: Fianchetto Defense, Euwe Variation", "rnbqk2r/pp1pppbp/5np1/2p3B1/3P4/5N2/PPPNPPPP/R2QKB1R w KQkq -", "d2d4 g8f6 g1f3 g7g6 c1g5 f8g7 b1d2 c7c5", "1. d4 Nf6 2. Nf3 g6 3. Bg5 Bg7 4. Nbd2 c5"),
  new FullOpening("A49", "Indian Defense: Przepiorka Variation", "rnbqkb1r/pppppp1p/5np1/8/3P4/5NP1/PPP1PP1P/RNBQKB1R b KQkq -", "d2d4 g8f6 g1f3 g7g6 g2g3", "1. d4 Nf6 2. Nf3 g6 3. g3"),
  new FullOpening("A50", "Indian Defense: Medusa Gambit", "rnbqkb1r/pppppp1p/5n2/6p1/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g5", "1. d4 Nf6 2. c4 g5"),
  new FullOpening("A50", "Indian Defense: Normal Variation", "rnbqkb1r/pppppppp/5n2/8/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4", "1. d4 Nf6 2. c4"),
  new FullOpening("A50", "Indian Defense: Pyrenees Gambit", "rnbqkb1r/p1pppppp/5n2/1p6/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 b7b5", "1. d4 Nf6 2. c4 b5"),
  new FullOpening("A50", "Mexican Defense", "r1bqkb1r/pppppppp/2n2n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 b8c6", "1. d4 Nf6 2. c4 Nc6"),
  new FullOpening("A50", "Mexican Defense: Horsefly Gambit", "r1bqkb1r/pppppppp/5n2/3Pn3/2P2P2/8/PP2P1PP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 b8c6 d4d5 c6e5 f2f4", "1. d4 Nf6 2. c4 Nc6 3. d5 Ne5 4. f4"),
  new FullOpening("A50", "Queen's Indian Accelerated", "rnbqkb1r/p1pppppp/1p3n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 b7b6", "1. d4 Nf6 2. c4 b6"),
  new FullOpening("A50", "Slav Indian", "rnbqkb1r/pp1ppppp/2p2n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c6", "1. d4 Nf6 2. c4 c6"),
  new FullOpening("A51", "Indian Defense: Budapest Defense", "rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e5", "1. d4 Nf6 2. c4 e5"),
  new FullOpening("A51", "Indian Defense: Budapest Defense, Fajarowicz Defense, Bonsdorf Variation", "rnbqkb1r/p1pp1ppp/1p6/4P3/2P1n3/P7/1P2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6e4 a2a3 b7b6", "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4 4. a3 b6"),
  new FullOpening("A51", "Indian Defense: Budapest Defense, Fajarowicz Variation", "rnbqkb1r/pppp1ppp/8/4P3/2P1n3/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6e4", "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4"),
  new FullOpening("A51", "Indian Defense: Budapest Defense, Fajarowicz-Steiner Variation", "rnbqkb1r/pppp1ppp/8/4P3/2P1n3/8/PPQ1PPPP/RNB1KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6e4 d1c2", "1. d4 Nf6 2. c4 e5 3. dxe5 Ne4 4. Qc2"),
  new FullOpening("A52", "Indian Defense: Budapest Defense", "rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6g4", "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4"),
  new FullOpening("A52", "Indian Defense: Budapest Defense, Adler Variation", "rnbqkb1r/pppp1ppp/8/4P3/2P3n1/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6g4 g1f3", "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Nf3"),
  new FullOpening("A52", "Indian Defense: Budapest Defense, Alekhine Variation", "rnbqkb1r/pppp1ppp/8/4P3/2P1P1n1/8/PP3PPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6g4 e2e4", "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4"),
  new FullOpening("A52", "Indian Defense: Budapest Defense, Alekhine Variation, Abonyi Variation", "rnbqkb1r/pppp1ppp/2n5/8/2P1PP2/8/PP4PP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6g4 e2e4 g4e5 f2f4 e5c6", "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4 Nxe5 5. f4 Nec6"),
  new FullOpening("A52", "Indian Defense: Budapest Defense, Alekhine Variation, Tartakower Defense", "rnbqkb1r/ppp2ppp/3p4/4P3/2P1P1n1/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6g4 e2e4 d7d6", "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. e4 d6"),
  new FullOpening("A52", "Indian Defense: Budapest Defense, Rubinstein Variation", "rnbqkb1r/pppp1ppp/8/4P3/2P2Bn1/8/PP2PPPP/RN1QKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e5 d4e5 f6g4 c1f4", "1. d4 Nf6 2. c4 e5 3. dxe5 Ng4 4. Bf4"),
  new FullOpening("A53", "Old Indian Defense", "rnbqkb1r/ppp1pppp/3p1n2/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 d7d6", "1. d4 Nf6 2. c4 d6"),
  new FullOpening("A53", "Old Indian Defense: Aged Gibbon Gambit", "rnbqkb1r/ppp1pppp/3p1n2/8/2PP2P1/8/PP2PP1P/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 d7d6 g2g4", "1. d4 Nf6 2. c4 d6 3. g4"),
  new FullOpening("A53", "Old Indian Defense: Czech Variation", "rnbqkb1r/pp2pppp/2pp1n2/8/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 c7c6", "1. d4 Nf6 2. c4 d6 3. Nc3 c6"),
  new FullOpening("A53", "Old Indian Defense: Janowski Variation", "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 c8f5", "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5"),
  new FullOpening("A53", "Old Indian Defense: Janowski Variation, Fianchetto Variation", "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 c8f5 g1f3", "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. Nf3"),
  new FullOpening("A53", "Old Indian Defense: Janowski Variation, Fianchetto Variation", "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N3P1/PP2PP1P/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 c8f5 g2g3", "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. g3"),
  new FullOpening("A53", "Old Indian Defense: Janowski Variation, Grinberg Gambit", "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 c8f5 e2e4", "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. e4"),
  new FullOpening("A53", "Old Indian Defense: Janowski Variation, Main Line", "rn1qkb1r/ppp1pppp/3p1n2/5b2/2PP4/2N2P2/PP2P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 c8f5 f2f3", "1. d4 Nf6 2. c4 d6 3. Nc3 Bf5 4. f3"),
  new FullOpening("A54", "Old Indian Defense: Dus-Khotimirsky Variation", "r1bqkb1r/pppn1ppp/3p1n2/4p3/2PP4/2NBP3/PP3PPP/R1BQK1NR b KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 e7e5 e2e3 b8d7 f1d3", "1. d4 Nf6 2. c4 d6 3. Nc3 e5 4. e3 Nbd7 5. Bd3"),
  new FullOpening("A54", "Old Indian Defense: Tartakower-Indian", "rn1qkb1r/ppp1pppp/3p1n2/8/2PP2b1/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 d7d6 g1f3 c8g4", "1. d4 Nf6 2. c4 d6 3. Nf3 Bg4"),
  new FullOpening("A54", "Old Indian Defense: Two Knights Variation", "rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 e7e5 g1f3", "1. d4 Nf6 2. c4 d6 3. Nc3 e5 4. Nf3"),
  new FullOpening("A54", "Old Indian Defense: Ukrainian Variation", "rnbqkb1r/ppp2ppp/3p1n2/4p3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 e7e5", "1. d4 Nf6 2. c4 d6 3. Nc3 e5"),
  new FullOpening("A55", "Old Indian Defense: Normal Variation", "r1bqkb1r/pppn1ppp/3p1n2/4p3/2PPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 d7d6 b1c3 b8d7 e2e4 e7e5 g1f3", "1. d4 Nf6 2. c4 d6 3. Nc3 Nbd7 4. e4 e5 5. Nf3"),
  new FullOpening("A56", "Benoni Defense", "rnbqkb1r/pp1ppppp/5n2/2p5/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5", "1. d4 Nf6 2. c4 c5"),
  new FullOpening("A56", "Benoni Defense: Czech Benoni Defense", "rnbqkb1r/pp1p1ppp/5n2/2pPp3/2P5/8/PP2PPPP/RNBQKBNR w KQkq e6", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e5", "1. d4 Nf6 2. c4 c5 3. d5 e5"),
  new FullOpening("A56", "Benoni Defense: Hromádka System", "rnbqkb1r/pp2pppp/3p1n2/2pP4/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 d7d6", "1. d4 Nf6 2. c4 c5 3. d5 d6"),
  new FullOpening("A56", "Benoni Defense: King's Indian System", "rnbqkb1r/pp3p1p/3p1np1/2pPp3/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e5 b1c3 d7d6 e2e4 g7g6", "1. d4 Nf6 2. c4 c5 3. d5 e5 4. Nc3 d6 5. e4 g6"),
  new FullOpening("A56", "Benoni Defense: Weenink Variation", "rnbqkb1r/pp1p1ppp/4pn2/2P5/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4c5 e7e6", "1. d4 Nf6 2. c4 c5 3. dxc5 e6"),
  new FullOpening("A56", "Grünfeld Defense: Three Knights Variation, Burille Variation, Reversed Tarrasch", "r1bq1rk1/pp2ppbp/2n2np1/3p4/2PP4/2N2N2/PP2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 c7c5 e2e3 g7g6 b1c3 f8g7 g1f3 e8g8 f1e2 c5d4 e3d4 d7d5 e1g1 b8c6", "1. d4 Nf6 2. c4 c5 3. e3 g6 4. Nc3 Bg7 5. Nf3 O-O 6. Be2 cxd4 7. exd4 d5 8. O-O Nc6"),
  new FullOpening("A56", "Vulture Defense", "rnbqkb1r/pp1ppppp/8/2pP4/2P1n3/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 f6e4", "1. d4 Nf6 2. c4 c5 3. d5 Ne4"),
  new FullOpening("A57", "Benko Gambit", "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5", "1. d4 Nf6 2. c4 c5 3. d5 b5"),
  new FullOpening("A57", "Benko Gambit Accepted", "rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6"),
  new FullOpening("A57", "Benko Gambit Accepted: Dlugy Variation", "rnbqkb1r/3ppppp/p4n2/1PpP4/8/5P2/PP2P1PP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 f2f3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. f3"),
  new FullOpening("A57", "Benko Gambit Accepted: Modern Variation", "rnbqkb1r/3ppppp/p4n2/1PpP4/8/4P3/PP3PPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 e2e3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. e3"),
  new FullOpening("A57", "Benko Gambit Accepted: Pawn Return Variation", "rnbqkb1r/3ppppp/pP3n2/2pP4/8/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5b6", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. b6"),
  new FullOpening("A57", "Benko Gambit Declined: Bishop Attack", "rnbqkb1r/p2ppppp/5n2/1ppP2B1/2P5/8/PP2PPPP/RN1QKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c1g5", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Bg5"),
  new FullOpening("A57", "Benko Gambit Declined: Hjørring Countergambit", "rnbqkb1r/p2ppppp/5n2/1ppP4/2P1P3/8/PP3PPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 e2e4", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. e4"),
  new FullOpening("A57", "Benko Gambit Declined: Main Line", "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 g1f3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Nf3"),
  new FullOpening("A57", "Benko Gambit Declined: Pseudo-Sämisch", "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/5P2/PP2P1PP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 f2f3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. f3"),
  new FullOpening("A57", "Benko Gambit Declined: Quiet Line", "rnbqkb1r/p2ppppp/5n2/1ppP4/2P5/8/PP1NPPPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 b1d2", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. Nd2"),
  new FullOpening("A57", "Benko Gambit Declined: Sosonko Variation", "rnbqkb1r/p2ppppp/5n2/1ppP4/P1P5/8/1P2PPPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 a2a4", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. a4"),
  new FullOpening("A57", "Benko Gambit: Mutkin Countergambit", "rnbqkb1r/p2ppppp/5n2/1ppP4/2P3P1/8/PP2PP1P/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 g2g4", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. g4"),
  new FullOpening("A57", "Benko Gambit: Nescafe Frappe Attack", "rnbqkb1r/4pppp/3p1n2/1NpP4/1pB1P3/8/PP3PPP/R1BQK1NR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b1c3 a6b5 e2e4 b5b4 c3b5 d7d6 f1c4", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. Nc3 axb5 6. e4 b4 7. Nb5 d6 8. Bc4"),
  new FullOpening("A57", "Benko Gambit: Zaitsev System", "rnbqkb1r/3ppppp/p4n2/1PpP4/8/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b1c3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. Nc3"),
  new FullOpening("A57", "Benko Gambit: Zaitsev Variation, Nescafe Frappe Attack", "rnbqkb1r/3ppppp/5n2/1NpP4/1p2P3/8/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b1c3 a6b5 e2e4 b5b4 c3b5", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. Nc3 axb5 6. e4 b4 7. Nb5"),
  new FullOpening("A58", "Benko Gambit Accepted: Central Storming Variation", "rn1qkb1r/3ppp1p/b4np1/2pP4/5P2/2N5/PP2P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 g7g6 b1c3 c8a6 f2f4", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 g6 6. Nc3 Bxa6 7. f4"),
  new FullOpening("A58", "Benko Gambit Accepted: Fianchetto Variation", "rn1qk2r/4ppbp/b2p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 g7g6 b1c3 c8a6 g2g3 d7d6 f1g2 f8g7 g1f3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 g6 6. Nc3 Bxa6 7. g3 d6 8. Bg2 Bg7 9. Nf3"),
  new FullOpening("A58", "Benko Gambit Accepted: Fully Accepted Variation", "rnbqkb1r/3ppppp/P4n2/2pP4/8/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6"),
  new FullOpening("A58", "Benko Gambit: Fianchetto Variation", "rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 c8a6 b1c3 d7d6 g1f3 g7g6 g2g3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 Bxa6 6. Nc3 d6 7. Nf3 g6 8. g3"),
  new FullOpening("A58", "Benko Gambit: Nd2 Variation", "rn1qkb1r/4pp1p/b2p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 c8a6 b1c3 d7d6 g1f3 g7g6 f3d2", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 Bxa6 6. Nc3 d6 7. Nf3 g6 8. Nd2"),
  new FullOpening("A59", "Benko Gambit", "rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N3P1/PP3P1P/R1BQ1KNR b kq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 g7g6 b1c3 c8a6 e2e4 a6f1 e1f1 d7d6 g2g3", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 g6 6. Nc3 Bxa6 7. e4 Bxf1 8. Kxf1 d6 9. g3"),
  new FullOpening("A59", "Benko Gambit Accepted: King Walk Variation", "rn1q1rk1/4ppbp/3p1np1/2pP4/4P3/2N2NP1/PP3PKP/R1BQ3R b - -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 g7g6 b1c3 c8a6 g1f3 d7d6 e2e4 a6f1 e1f1 f8g7 g2g3 e8g8 f1g2", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 g6 6. Nc3 Bxa6 7. Nf3 d6 8. e4 Bxf1 9. Kxf1 Bg7 10. g3 O-O 11. Kg2"),
  new FullOpening("A59", "Benko Gambit Accepted: Yugoslav", "rn1qkb1r/4pppp/b2p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 c8a6 b1c3 d7d6 e2e4", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 Bxa6 6. Nc3 d6 7. e4"),
  new FullOpening("A59", "Benko Gambit Accepted: Yugoslav", "rn1qkb1r/4pp1p/3p1np1/2pP4/4P3/2N5/PP2NPPP/R1BQ1K1R b kq -", "d2d4 g8f6 c2c4 c7c5 d4d5 b7b5 c4b5 a7a6 b5a6 g7g6 b1c3 c8a6 e2e4 a6f1 e1f1 d7d6 g1e2", "1. d4 Nf6 2. c4 c5 3. d5 b5 4. cxb5 a6 5. bxa6 g6 6. Nc3 Bxa6 7. e4 Bxf1 8. Kxf1 d6 9. Nge2"),
  new FullOpening("A60", "Benoni Defense: Modern Variation", "rnbqkb1r/pp1p1ppp/4pn2/2pP4/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6", "1. d4 Nf6 2. c4 c5 3. d5 e6"),
  new FullOpening("A60", "Benoni Defense: Modern Variation, Snake Variation", "rnbqk2r/pp1p1ppp/3b1n2/2pP4/8/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 f8d6", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 Bd6"),
  new FullOpening("A61", "Benoni Defense", "rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6"),
  new FullOpening("A61", "Benoni Defense: Fianchetto Variation", "rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N2NP1/PP2PP1P/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 g2g3", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. g3"),
  new FullOpening("A61", "Benoni Defense: Knight's Tour Variation", "rnbqkb1r/pp3p1p/3p1np1/2pP4/8/2N5/PP1NPPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 f3d2", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. Nd2"),
  new FullOpening("A61", "Benoni Defense: Uhlmann Variation", "rnbqkb1r/pp3p1p/3p1np1/2pP2B1/8/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 c1g5", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. Bg5"),
  new FullOpening("A62", "Benoni Defense: Fianchetto Variation", "rnbq1rk1/pp3pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 e7e6 g2g3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 f1g2 f8g7 g1f3 e8g8", "1. d4 Nf6 2. c4 e6 3. g3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. Bg2 Bg7 8. Nf3 O-O"),
  new FullOpening("A63", "Benoni Defense: Fianchetto Variation, Hastings Defense", "r1bq1rk1/pp1n1pbp/3p1np1/2pP4/8/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g2g3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 f1g2 f8g7 g1f3 e8g8 e1g1 b8d7", "1. d4 Nf6 2. c4 e6 3. g3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. Bg2 Bg7 8. Nf3 O-O 9. O-O Nbd7"),
  new FullOpening("A64", "Benoni Defense: Fianchetto Variation, Hastings Defense, Main Line", "r1bqr1k1/1p1n1pbp/p2p1np1/2pP4/P7/2N3P1/1P1NPPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g2g3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 f1g2 f8g7 g1f3 e8g8 e1g1 a7a6 a2a4 b8d7 f3d2 f8e8", "1. d4 Nf6 2. c4 e6 3. g3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. Bg2 Bg7 8. Nf3 O-O 9. O-O a6 10. a4 Nbd7 11. Nd2 Re8"),
  new FullOpening("A65", "Benoni Defense: King's Pawn Line", "rnbqkb1r/pp3ppp/3p1n2/2pP4/4P3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4"),
  new FullOpening("A66", "Benoni Defense: Mikenas Variation", "rnbqk2r/pp3pbp/3p1np1/2pPP3/5P2/2N5/PP4PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 f2f4 f8g7 e4e5", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. f4 Bg7 8. e5"),
  new FullOpening("A66", "Benoni Defense: Pawn Storm Variation", "rnbqkb1r/pp3p1p/3p1np1/2pP4/4PP2/2N5/PP4PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 f2f4", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. f4"),
  new FullOpening("A67", "Benoni Defense: Taimanov Variation", "rnbqk2r/pp3pbp/3p1np1/1BpP4/4PP2/2N5/PP4PP/R1BQK1NR b KQkq -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 f2f4 f8g7 f1b5", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. f4 Bg7 8. Bb5+"),
  new FullOpening("A68", "Benoni Defense: Four Pawns Attack", "rnbq1rk1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP4PP/R1BQKB1R w KQ -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 f2f4 f8g7 g1f3 e8g8", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. f4 Bg7 8. Nf3 O-O"),
  new FullOpening("A69", "Benoni Defense: Four Pawns Attack, Main Line", "rnbqr1k1/pp3pbp/3p1np1/2pP4/4PP2/2N2N2/PP2B1PP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 e8g8 g1f3 c7c5 d4d5 e7e6 f1e2 e6d5 c4d5 f8e8", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 c5 7. d5 e6 8. Be2 exd5 9. cxd5 Re8"),
  new FullOpening("A70", "Benoni Defense: Classical Variation", "rnbqkb1r/pp3p1p/3p1np1/2pP4/4P3/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 e2e4", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. e4"),
  new FullOpening("A70", "Benoni Defense: Classical Variation, New York Variation", "rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N1P/PP3PP1/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 e2e4 f8g7 h2h3", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. e4 Bg7 8. h3"),
  new FullOpening("A70", "Benoni Defense: Classical Variation, Traditional Variation", "rnbqk2r/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 e2e4 f8g7 f1e2", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. e4 Bg7 8. Be2"),
  new FullOpening("A71", "Benoni Defense: Classical Variation, Averbakh-Grivas Attack", "rnbqk2r/pp3pbp/3p1np1/2pP2B1/4P3/2N2N2/PP3PPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 e2e4 f8g7 c1g5", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. e4 Bg7 8. Bg5"),
  new FullOpening("A72", "Benoni Defense: Classical", "rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 g1f3 f8g7 f1e2 e8g8", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. Nf3 Bg7 8. Be2 O-O"),
  new FullOpening("A73", "Benoni Defense: Classical Variation, Main Line", "rnbq1rk1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. Nf3 Bg7 8. Be2 O-O 9. O-O"),
  new FullOpening("A74", "Benoni Defense: Classical Variation, Full Line", "rnbq1rk1/1p3pbp/p2p1np1/2pP4/P3P3/2N2N2/1P2BPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1 a7a6 a2a4", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. Nf3 Bg7 8. Be2 O-O 9. O-O a6 10. a4"),
  new FullOpening("A75", "Benoni Defense: Classical Variation, Argentine Counterattack", "rn1q1rk1/1p3pbp/p2p1np1/2pP4/P3P1b1/2N2N2/1P2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 e2e4 f8g7 f1e2 e8g8 e1g1 a7a6 a2a4 c8g4", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. e4 Bg7 8. Be2 O-O 9. O-O a6 10. a4 Bg4"),
  new FullOpening("A76", "Benoni Defense: Classical Variation, Czerniak Defense", "rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N2N2/PP2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1 f8e8", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. Nf3 Bg7 8. Be2 O-O 9. O-O Re8"),
  new FullOpening("A77", "Benoni Defense: Classical Variation, Czerniak Defense, Tal Line", "rnbqr1k1/pp3pbp/3p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1 f8e8 f3d2", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. Nf3 Bg7 8. Be2 O-O 9. O-O Re8 10. Nd2"),
  new FullOpening("A78", "Benoni Defense: Classical Variation, Czerniak Defense", "r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N5/PP1NBPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 e6d5 c4d5 d7d6 b1c3 g7g6 e2e4 f8g7 f1e2 e8g8 e1g1 f8e8 f3d2 b8a6", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 exd5 5. cxd5 d6 6. Nc3 g6 7. e4 Bg7 8. Be2 O-O 9. O-O Re8 10. Nd2 Na6"),
  new FullOpening("A79", "Benoni Defense: Classical Variation, Czerniak Defense", "r1bqr1k1/pp3pbp/n2p1np1/2pP4/4P3/2N2P2/PP1NB1PP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 c7c5 d4d5 e7e6 b1c3 e6d5 c4d5 d7d6 e2e4 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1 f8e8 f3d2 b8a6 f2f3", "1. d4 Nf6 2. c4 c5 3. d5 e6 4. Nc3 exd5 5. cxd5 d6 6. e4 g6 7. Nf3 Bg7 8. Be2 O-O 9. O-O Re8 10. Nd2 Na6 11. f3"),
  new FullOpening("A80", "Dutch Defense", "rnbqkbnr/ppppp1pp/8/5p2/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 f7f5", "1. d4 f5"),
  new FullOpening("A80", "Dutch Defense: Alapin Variation", "rnbqkbnr/ppppp1pp/8/5p2/3P4/3Q4/PPP1PPPP/RNB1KBNR b KQkq -", "d2d4 f7f5 d1d3", "1. d4 f5 2. Qd3"),
  new FullOpening("A80", "Dutch Defense: Hevendehl Gambit", "rnbqkbnr/pppp2pp/8/4pp2/3P2P1/8/PPP1PP1P/RNBQKBNR w KQkq -", "d2d4 f7f5 g2g4 e7e5", "1. d4 f5 2. g4 e5"),
  new FullOpening("A80", "Dutch Defense: Hopton Attack", "rnbqkbnr/ppppp1pp/8/5pB1/3P4/8/PPP1PPPP/RN1QKBNR b KQkq -", "d2d4 f7f5 c1g5", "1. d4 f5 2. Bg5"),
  new FullOpening("A80", "Dutch Defense: Janzen-Korchnoi Gambit", "rnbqkb1r/ppppp1pp/5n2/5p2/3P2P1/7P/PPP1PP2/RNBQKBNR b KQkq -", "d2d4 f7f5 h2h3 g8f6 g2g4", "1. d4 f5 2. h3 Nf6 3. g4"),
  new FullOpening("A80", "Dutch Defense: Kingfisher Gambit", "rnbqkbnr/ppp1p1pp/8/3p1p2/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "d2d4 f7f5 b1c3 d7d5 e2e4", "1. d4 f5 2. Nc3 d5 3. e4"),
  new FullOpening("A80", "Dutch Defense: Korchnoi Attack", "rnbqkbnr/ppppp1pp/8/5p2/3P4/7P/PPP1PPP1/RNBQKBNR b KQkq -", "d2d4 f7f5 h2h3", "1. d4 f5 2. h3"),
  new FullOpening("A80", "Dutch Defense: Krejcik Gambit", "rnbqkbnr/ppppp1pp/8/5p2/3P2P1/8/PPP1PP1P/RNBQKBNR b KQkq -", "d2d4 f7f5 g2g4", "1. d4 f5 2. g4"),
  new FullOpening("A80", "Dutch Defense: Krejcik Gambit, Tate Gambit", "rnbqkbnr/ppp1p1pp/8/3p4/3PP1p1/2N5/PPP2P1P/R1BQKBNR b KQkq -", "d2d4 f7f5 g2g4 f5g4 e2e4 d7d5 b1c3", "1. d4 f5 2. g4 fxg4 3. e4 d5 4. Nc3"),
  new FullOpening("A80", "Dutch Defense: Manhattan Gambit, Anti-Classical Line", "rnbqkbnr/pppp2pp/4p3/5p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq -", "d2d4 f7f5 d1d3 e7e6 g2g4", "1. d4 f5 2. Qd3 e6 3. g4"),
  new FullOpening("A80", "Dutch Defense: Manhattan Gambit, Anti-Leningrad", "rnbqkbnr/ppppp2p/6p1/5p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq -", "d2d4 f7f5 d1d3 g7g6 g2g4", "1. d4 f5 2. Qd3 g6 3. g4"),
  new FullOpening("A80", "Dutch Defense: Manhattan Gambit, Anti-Modern", "rnbqkbnr/ppp1p1pp/3p4/5p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq -", "d2d4 f7f5 d1d3 d7d6 g2g4", "1. d4 f5 2. Qd3 d6 3. g4"),
  new FullOpening("A80", "Dutch Defense: Manhattan Gambit, Anti-Stonewall", "rnbqkbnr/ppp1p1pp/8/3p1p2/3P2P1/3Q4/PPP1PP1P/RNB1KBNR b KQkq -", "d2d4 f7f5 d1d3 d7d5 g2g4", "1. d4 f5 2. Qd3 d5 3. g4"),
  new FullOpening("A80", "Dutch Defense: Omega-Isis Gambit", "rnbqkbnr/pppp2pp/8/4pp2/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 f7f5 g1f3 e7e5", "1. d4 f5 2. Nf3 e5"),
  new FullOpening("A80", "Dutch Defense: Raphael Variation", "rnbqkbnr/ppppp1pp/8/5p2/3P4/2N5/PPP1PPPP/R1BQKBNR b KQkq -", "d2d4 f7f5 b1c3", "1. d4 f5 2. Nc3"),
  new FullOpening("A80", "Dutch Defense: Spielmann Gambit", "rnbqkb1r/ppppp1pp/5n2/5p2/3P2P1/2N5/PPP1PP1P/R1BQKBNR b KQkq -", "d2d4 f7f5 b1c3 g8f6 g2g4", "1. d4 f5 2. Nc3 Nf6 3. g4"),
  new FullOpening("A80", "Queen's Pawn Game: Veresov Attack, Dutch System", "rnbqkbnr/ppp1p1pp/8/3p1p2/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 f7f5 b1c3 d7d5", "1. d4 f5 2. Nc3 d5"),
  new FullOpening("A81", "Dutch Defense: Blackburne Variation", "rnbqkb1r/pppp2pp/4pn2/5p2/3P4/6PN/PPP1PPBP/RNBQK2R b KQkq -", "d2d4 f7f5 g2g3 g8f6 f1g2 e7e6 g1h3", "1. d4 f5 2. g3 Nf6 3. Bg2 e6 4. Nh3"),
  new FullOpening("A81", "Dutch Defense: Fianchetto Attack", "rnbqkbnr/ppppp1pp/8/5p2/3P4/6P1/PPP1PP1P/RNBQKBNR b KQkq -", "d2d4 f7f5 g2g3", "1. d4 f5 2. g3"),
  new FullOpening("A81", "Dutch Defense: Leningrad Variation, Karlsbad Variation", "rnbqk1nr/ppppp1bp/6p1/5p2/3P4/6PN/PPP1PPBP/RNBQK2R b KQkq -", "d2d4 f7f5 g2g3 g7g6 f1g2 f8g7 g1h3", "1. d4 f5 2. g3 g6 3. Bg2 Bg7 4. Nh3"),
  new FullOpening("A81", "Dutch Defense: Leningrad, Basman System", "rnbqk2r/pp1pp1bp/2p3pn/5p2/3P4/5NP1/PPP1PPBP/RNBQ1RK1 w kq -", "d2d4 f7f5 g2g3 c7c6 f1g2 g7g6 g1f3 f8g7 e1g1 g8h6", "1. d4 f5 2. g3 c6 3. Bg2 g6 4. Nf3 Bg7 5. O-O Nh6"),
  new FullOpening("A81", "Dutch Defense: Semi-Leningrad Variation", "rnbqkb1r/ppppp2p/5np1/5p2/3P4/6P1/PPP1PPBP/RNBQK1NR w KQkq -", "d2d4 f7f5 g2g3 g8f6 f1g2 g7g6", "1. d4 f5 2. g3 Nf6 3. Bg2 g6"),
  new FullOpening("A82", "Dutch Defense: Blackmar's Second Gambit", "rnbqkb1r/ppppp1pp/5n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR b KQkq -", "d2d4 f7f5 e2e4 f5e4 b1c3 g8f6 f2f3", "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. f3"),
  new FullOpening("A82", "Dutch Defense: Staunton Gambit", "rnbqkbnr/ppppp1pp/8/5p2/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "d2d4 f7f5 e2e4", "1. d4 f5 2. e4"),
  new FullOpening("A82", "Dutch Defense: Staunton Gambit Accepted", "rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPP2PPP/RNBQKBNR w KQkq -", "d2d4 f7f5 e2e4 f5e4", "1. d4 f5 2. e4 fxe4"),
  new FullOpening("A82", "Dutch Defense: Staunton Gambit, American Attack", "rnbqkbnr/ppppp1pp/8/8/3Pp3/8/PPPN1PPP/R1BQKBNR b KQkq -", "d2d4 f7f5 e2e4 f5e4 b1d2", "1. d4 f5 2. e4 fxe4 3. Nd2"),
  new FullOpening("A82", "Dutch Defense: Staunton Gambit, Tartakower Variation", "rnbqkb1r/ppppp1pp/5n2/8/3Pp1P1/2N5/PPP2P1P/R1BQKBNR b KQkq -", "d2d4 f7f5 e2e4 f5e4 b1c3 g8f6 g2g4", "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. g4"),
  new FullOpening("A82", "French Defense: Franco-Hiva Gambit", "rnbqkbnr/pppp2pp/4p3/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "d2d4 f7f5 e2e4 e7e6", "1. d4 f5 2. e4 e6"),
  new FullOpening("A82", "Rat Defense: Balogh Defense", "rnbqkbnr/ppp1p1pp/3p4/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d6 d2d4 f7f5", "1. e4 d6 2. d4 f5"),
  new FullOpening("A83", "Dutch Defense: Staunton Gambit", "rnbqkb1r/ppppp1pp/5n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR b KQkq -", "d2d4 f7f5 e2e4 f5e4 b1c3 g8f6 c1g5", "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5"),
  new FullOpening("A83", "Dutch Defense: Staunton Gambit, Alekhine Variation", "rnbqkb1r/ppppp2p/5np1/6B1/3Pp2P/2N5/PPP2PP1/R2QKBNR b KQkq -", "d2d4 f7f5 e2e4 f5e4 b1c3 g8f6 c1g5 g7g6 h2h4", "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 g6 5. h4"),
  new FullOpening("A83", "Dutch Defense: Staunton Gambit, Chigorin Variation", "rnbqkb1r/pp1pp1pp/2p2n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR w KQkq -", "d2d4 f7f5 e2e4 f5e4 b1c3 g8f6 c1g5 c7c6", "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 c6"),
  new FullOpening("A83", "Dutch Defense: Staunton Gambit, Lasker Variation", "rnbqkb1r/ppppp2p/5np1/6B1/3Pp3/2N2P2/PPP3PP/R2QKBNR b KQkq -", "d2d4 f7f5 e2e4 f5e4 b1c3 g8f6 c1g5 g7g6 f2f3", "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 g6 5. f3"),
  new FullOpening("A83", "Dutch Defense: Staunton Gambit, Nimzowitsch Variation", "rnbqkb1r/p1ppp1pp/1p3n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR w KQkq -", "d2d4 f7f5 e2e4 f5e4 b1c3 g8f6 c1g5 b7b6", "1. d4 f5 2. e4 fxe4 3. Nc3 Nf6 4. Bg5 b6"),
  new FullOpening("A84", "Dutch Defense", "rnbqkbnr/ppppp1pp/8/5p2/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 f7f5 c2c4", "1. d4 f5 2. c4"),
  new FullOpening("A84", "Dutch Defense: Bellon Gambit", "rnbqkbnr/pppp2pp/4p3/5p2/2PPP3/8/PP3PPP/RNBQKBNR b KQkq -", "d2d4 f7f5 c2c4 e7e6 e2e4", "1. d4 f5 2. c4 e6 3. e4"),
  new FullOpening("A84", "Dutch Defense: Bladel Variation", "rnbqkb1r/ppppp2p/6pn/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 f7f5 c2c4 g7g6 b1c3 g8h6", "1. d4 f5 2. c4 g6 3. Nc3 Nh6"),
  new FullOpening("A84", "Dutch Defense: Classical Variation", "rnbqkbnr/pppp2pp/4p3/5p2/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 e7e6 c2c4 f7f5", "1. d4 e6 2. c4 f5"),
  new FullOpening("A84", "Dutch Defense: Krause Variation", "r1bqkb1r/ppp1p1pp/2np1n2/5p2/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 f7f5 c2c4 g8f6 b1c3 d7d6 g1f3 b8c6", "1. d4 f5 2. c4 Nf6 3. Nc3 d6 4. Nf3 Nc6"),
  new FullOpening("A84", "Dutch Defense: Normal Variation", "rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 f7f5 c2c4 g8f6", "1. d4 f5 2. c4 Nf6"),
  new FullOpening("A84", "Dutch Defense: Rubinstein Variation", "rnbqkbnr/pppp2pp/4p3/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 f7f5 c2c4 e7e6 b1c3", "1. d4 f5 2. c4 e6 3. Nc3"),
  new FullOpening("A85", "Dutch Defense: Queen's Knight Variation", "rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 f7f5 c2c4 g8f6 b1c3", "1. d4 f5 2. c4 Nf6 3. Nc3"),
  new FullOpening("A86", "Dutch Defense: Fianchetto Variation", "rnbqkb1r/ppppp1pp/5n2/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq -", "d2d4 f7f5 c2c4 g8f6 g2g3", "1. d4 f5 2. c4 Nf6 3. g3"),
  new FullOpening("A86", "Dutch Defense: Hort-Antoshin System", "rnb1kb1r/ppq1p1pp/2pp1n2/5p2/2PP4/2N3P1/PP2PPBP/R1BQK1NR w KQkq -", "c2c4 f7f5 g2g3 g8f6 f1g2 d7d6 b1c3 c7c6 d2d4 d8c7", "1. c4 f5 2. g3 Nf6 3. Bg2 d6 4. Nc3 c6 5. d4 Qc7"),
  new FullOpening("A86", "Dutch Defense: Leningrad Variation", "rnbqkb1r/ppppp2p/5np1/5p2/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq -", "d2d4 f7f5 c2c4 g8f6 g2g3 g7g6", "1. d4 f5 2. c4 Nf6 3. g3 g6"),
  new FullOpening("A87", "Dutch Defense: Leningrad Variation", "rnbqk2r/ppppp1bp/5np1/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R b KQkq -", "d2d4 f7f5 c2c4 g8f6 g2g3 g7g6 f1g2 f8g7 g1f3", "1. d4 f5 2. c4 Nf6 3. g3 g6 4. Bg2 Bg7 5. Nf3"),
  new FullOpening("A88", "Dutch Defense: Leningrad Variation, Warsaw Variation", "rnbq1rk1/pp2p1bp/2pp1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 f7f5 g2g3 g8f6 f1g2 g7g6 g1f3 f8g7 e1g1 e8g8 c2c4 d7d6 b1c3 c7c6", "1. d4 f5 2. g3 Nf6 3. Bg2 g6 4. Nf3 Bg7 5. O-O O-O 6. c4 d6 7. Nc3 c6"),
  new FullOpening("A89", "Dutch Defense: Leningrad Variation, Matulovic Variation", "r1bq1rk1/ppp1p1bp/2np1np1/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 f7f5 g2g3 g8f6 f1g2 g7g6 g1f3 f8g7 e1g1 e8g8 c2c4 d7d6 b1c3 b8c6", "1. d4 f5 2. g3 Nf6 3. Bg2 g6 4. Nf3 Bg7 5. O-O O-O 6. c4 d6 7. Nc3 Nc6"),
  new FullOpening("A90", "Dutch Defense: Classical Variation", "rnbqkb1r/pppp2pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR b KQkq -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2"),
  new FullOpening("A90", "Dutch Defense: Nimzo-Dutch Variation", "rnbqk2r/pppp2pp/4pn2/5p2/1bPP4/6P1/PP2PPBP/RNBQK1NR w KQkq -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8b4", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Bb4+"),
  new FullOpening("A90", "Dutch Defense: Nimzo-Dutch Variation, Alekhine Variation", "rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP1BPPBP/RN1QK1NR w KQkq -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8b4 c1d2 b4e7", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Bb4+ 5. Bd2 Be7"),
  new FullOpening("A91", "Dutch Defense: Classical Variation", "rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6P1/PP2PPBP/RNBQK1NR w KQkq -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7"),
  new FullOpening("A91", "Dutch Defense: Classical Variation, Blackburne Attack", "rnbqk2r/ppppb1pp/4pn2/5p2/2PP4/6PN/PP2PPBP/RNBQK2R b KQkq -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1h3", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nh3"),
  new FullOpening("A92", "Dutch Defense: Alekhine Variation", "rnbq1rk1/ppppb1pp/4p3/5p2/2PPn3/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 e8g8 e1g1 f6e4", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 O-O 6. O-O Ne4"),
  new FullOpening("A92", "Dutch Defense: Classical Variation", "rnbq1rk1/ppppb1pp/4pn2/5p2/2PP4/5NP1/PP2PPBP/RNBQK2R w KQ -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 e8g8", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 O-O"),
  new FullOpening("A92", "Dutch Defense: Stonewall", "rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 b - -", "d2d4 e7e6 g1f3 f7f5 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c2c4 d7d5 b1c3", "1. d4 e6 2. Nf3 f5 3. g3 Nf6 4. Bg2 Be7 5. O-O O-O 6. c4 d5 7. Nc3"),
  new FullOpening("A92", "Dutch Defense: Stonewall Variation", "rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 d7d5 e1g1 e8g8", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 d5 6. O-O O-O"),
  new FullOpening("A93", "Dutch Defense: Classical Variation, Stonewall Variation, Botvinnik Variation", "rnbq1rk1/ppp1b1pp/4pn2/3p1p2/2PP4/1P3NP1/P3PPBP/RNBQ1RK1 b - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 d7d5 e1g1 e8g8 b2b3", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 d5 6. O-O O-O 7. b3"),
  new FullOpening("A94", "Dutch Defense: Classical Variation, Stonewall Variation", "rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/BP3NP1/P3PPBP/RN1Q1RK1 b - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 d7d5 e1g1 e8g8 b2b3 c7c6 c1a3", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 d5 6. O-O O-O 7. b3 c6 8. Ba3"),
  new FullOpening("A95", "Dutch Defense: Classical Variation, Stonewall Variation", "rnbq1rk1/pp2b1pp/2p1pn2/3p1p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 d7d5 g1f3 f8e7 e1g1 e8g8 b1c3 c7c6", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 d5 5. Nf3 Be7 6. O-O O-O 7. Nc3 c6"),
  new FullOpening("A95", "Dutch Defense: Stonewall, Chekhover Variation", "rnb1qrk1/pp2b1pp/2p1pn2/3p1pB1/2PP4/2N2NP1/PPQ1PPBP/R4RK1 b - -", "d2d4 f7f5 c2c4 g8f6 g2g3 e7e6 f1g2 f8e7 g1f3 e8g8 e1g1 d7d5 b1c3 c7c6 d1c2 d8e8 c1g5", "1. d4 f5 2. c4 Nf6 3. g3 e6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d5 7. Nc3 c6 8. Qc2 Qe8 9. Bg5"),
  new FullOpening("A96", "Dutch Defense: Classical Variation", "rnbq1rk1/ppp1b1pp/3ppn2/5p2/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 e8g8 e1g1 d7d6", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d6"),
  new FullOpening("A96", "Dutch Defense: Classical Variation, Buenos Aires Variation", "rnbq1rk1/1pp1b1pp/3ppn2/p4p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 e8g8 e1g1 d7d6 b1c3 a7a5", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d6 7. Nc3 a5"),
  new FullOpening("A96", "Dutch Defense: Classical Variation, Huisl Variation", "rnbq1rk1/ppp1b1pp/3pp3/5p2/2PPn3/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 e8g8 e1g1 d7d6 b1c3 f6e4", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d6 7. Nc3 Ne4"),
  new FullOpening("A97", "Dutch Defense: Classical Variation, Ilyin-Zhenevsky Variation", "rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 f7f5 g1f3 g8f6 g2g3 e7e6 f1g2 f8e7 e1g1 e8g8 c2c4 d7d6 b1c3 d8e8", "1. d4 f5 2. Nf3 Nf6 3. g3 e6 4. Bg2 Be7 5. O-O O-O 6. c4 d6 7. Nc3 Qe8"),
  new FullOpening("A97", "Dutch Defense: Classical Variation, Ilyin-Zhenevsky Variation, Winter Variation", "rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PP2PPBP/R1BQR1K1 b - -", "d2d4 f7f5 g1f3 g8f6 g2g3 e7e6 f1g2 f8e7 e1g1 e8g8 c2c4 d7d6 b1c3 d8e8 f1e1", "1. d4 f5 2. Nf3 Nf6 3. g3 e6 4. Bg2 Be7 5. O-O O-O 6. c4 d6 7. Nc3 Qe8 8. Re1"),
  new FullOpening("A98", "Dutch Defense: Classical Variation, Ilyin-Zhenevsky Variation, Alatortsev-Lisitsyn Line", "rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/2N2NP1/PPQ1PPBP/R1B2RK1 b - -", "d2d4 f7f5 g1f3 g8f6 g2g3 e7e6 f1g2 f8e7 e1g1 e8g8 c2c4 d7d6 b1c3 d8e8 d1c2", "1. d4 f5 2. Nf3 Nf6 3. g3 e6 4. Bg2 Be7 5. O-O O-O 6. c4 d6 7. Nc3 Qe8 8. Qc2"),
  new FullOpening("A99", "Dutch Defense: Classical Variation, Ilyin-Zhenevsky Variation, Modern Main Line", "rnb1qrk1/ppp1b1pp/3ppn2/5p2/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1 b - -", "d2d4 e7e6 c2c4 f7f5 g2g3 g8f6 f1g2 f8e7 g1f3 e8g8 e1g1 d7d6 b1c3 d8e8 b2b3", "1. d4 e6 2. c4 f5 3. g3 Nf6 4. Bg2 Be7 5. Nf3 O-O 6. O-O d6 7. Nc3 Qe8 8. b3"),
  new FullOpening("B00", "Barnes Defense", "rnbqkbnr/ppppp1pp/5p2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 f7f6", "1. e4 f6"),
  new FullOpening("B00", "Borg Defense", "rnbqkbnr/pppppp1p/8/6p1/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 g7g5", "1. e4 g5"),
  new FullOpening("B00", "Borg Defense: Borg Gambit", "rnbqk1nr/ppppppbp/8/6p1/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g7g5 d2d4 f8g7", "1. e4 g5 2. d4 Bg7"),
  new FullOpening("B00", "Borg Defense: Langhorst Gambit", "rnbqkbnr/p2p1p1p/1p2p3/2P3p1/4P3/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 g7g5 d2d4 e7e6 c2c3 c7c5 d4c5 b7b6", "1. e4 g5 2. d4 e6 3. c3 c5 4. dxc5 b6"),
  new FullOpening("B00", "Borg Defense: Troon Gambit", "rnbqkbnr/pppppp2/7p/8/3PP1pP/8/PPP2PP1/RNBQKBNR w KQkq -", "e2e4 g7g5 d2d4 h7h6 h2h4 g5g4", "1. e4 g5 2. d4 h6 3. h4 g4"),
  new FullOpening("B00", "Borg Opening: Zilbermints Gambit", "rnbqkbnr/pppp1p1p/8/4p1p1/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g7g5 d2d4 e7e5", "1. e4 g5 2. d4 e5"),
  new FullOpening("B00", "Carr Defense", "rnbqkbnr/ppppppp1/7p/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 h7h6", "1. e4 h6"),
  new FullOpening("B00", "Carr Defense: Zilbermints Gambit", "rnbqkbnr/pppp1pp1/7p/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 h7h6 d2d4 e7e5", "1. e4 h6 2. d4 e5"),
  new FullOpening("B00", "Duras Gambit", "rnbqkbnr/ppppp1pp/8/5p2/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 f7f5", "1. e4 f5"),
  new FullOpening("B00", "Fried Fox Defense", "rnbq1bnr/pppppkpp/5p2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQ -", "e2e4 f7f6 d2d4 e8f7", "1. e4 f6 2. d4 Kf7"),
  new FullOpening("B00", "Goldsmith Defense", "rnbqkbnr/ppppppp1/8/7p/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 h7h5", "1. e4 h5"),
  new FullOpening("B00", "Goldsmith Defense: Picklepuss Defense", "rnbqkb1r/ppppppp1/5n2/7p/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 h7h5 d2d4 g8f6", "1. e4 h5 2. d4 Nf6"),
  new FullOpening("B00", "Guatemala Defense", "rn1qkbnr/p1pppppp/bp6/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b7b6 d2d4 c8a6", "1. e4 b6 2. d4 Ba6"),
  new FullOpening("B00", "Hippopotamus Defense", "rnbqkb1r/pppppppp/7n/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 g8h6", "1. e4 Nh6"),
  new FullOpening("B00", "Hippopotamus Defense", "rnbqkb1r/ppppp2p/5ppn/8/2PPP3/8/PP3PPP/RNBQKBNR w KQkq -", "e2e4 g8h6 d2d4 g7g6 c2c4 f7f6", "1. e4 Nh6 2. d4 g6 3. c4 f6"),
  new FullOpening("B00", "King's Pawn", "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq -", "e2e4", "1. e4"),
  new FullOpening("B00", "Lemming Defense", "r1bqkbnr/pppppppp/n7/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 b8a6", "1. e4 Na6"),
  new FullOpening("B00", "Lion Defense: Lion's Jaw", "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 f2f3", "1. e4 d6 2. d4 Nf6 3. f3"),
  new FullOpening("B00", "Nimzowitsch Defense", "r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 b8c6", "1. e4 Nc6"),
  new FullOpening("B00", "Nimzowitsch Defense", "r1bqkbnr/pppppppp/2n5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 b8c6 d2d4", "1. e4 Nc6 2. d4"),
  new FullOpening("B00", "Nimzowitsch Defense: Breyer Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 b1c3 g8f6 d2d4 e7e5", "1. e4 Nc6 2. Nc3 Nf6 3. d4 e5"),
  new FullOpening("B00", "Nimzowitsch Defense: Colorado Countergambit", "r1bqkbnr/ppppp1pp/2n5/5p2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 b8c6 g1f3 f7f5", "1. e4 Nc6 2. Nf3 f5"),
  new FullOpening("B00", "Nimzowitsch Defense: Colorado Countergambit Accepted", "r1bqkbnr/ppppp1pp/2n5/5P2/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 b8c6 g1f3 f7f5 e4f5", "1. e4 Nc6 2. Nf3 f5 3. exf5"),
  new FullOpening("B00", "Nimzowitsch Defense: Declined Variation", "r1bqkbnr/pppppppp/2n5/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 b8c6 g1f3", "1. e4 Nc6 2. Nf3"),
  new FullOpening("B00", "Nimzowitsch Defense: El Columpio Defense", "r1bqkb1r/pppppppp/2n5/4P3/6n1/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 b8c6 g1f3 g8f6 e4e5 f6g4", "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4"),
  new FullOpening("B00", "Nimzowitsch Defense: El Columpio Defense, El Columpio Gambit", "r1bqkb1r/ppp1pppp/2npP2n/8/3P4/5N1P/PPP2PP1/RNBQKB1R b KQkq -", "e2e4 b8c6 g1f3 g8f6 e4e5 f6g4 d2d4 d7d6 h2h3 g4h6 e5e6", "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. e6"),
  new FullOpening("B00", "Nimzowitsch Defense: El Columpio Defense, Exchange Variation", "r1bqkb1r/ppp1pppp/2nP3n/8/3P4/5N1P/PPP2PP1/RNBQKB1R b KQkq -", "e2e4 b8c6 g1f3 g8f6 e4e5 f6g4 d2d4 d7d6 h2h3 g4h6 e5d6", "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. exd6"),
  new FullOpening("B00", "Nimzowitsch Defense: El Columpio Defense, Pin Variation", "r1bqkb1r/ppp1pppp/2np3n/1B2P3/3P4/5N1P/PPP2PP1/RNBQK2R b KQkq -", "e2e4 b8c6 g1f3 g8f6 e4e5 f6g4 d2d4 d7d6 h2h3 g4h6 f1b5", "1. e4 Nc6 2. Nf3 Nf6 3. e5 Ng4 4. d4 d6 5. h3 Nh6 6. Bb5"),
  new FullOpening("B00", "Nimzowitsch Defense: Franco-Hiva Gambit", "r1bqkb1r/pppp2pp/2n1pn2/5P2/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e6 b1c3 f7f5 e4f5 g8f6", "1. e4 Nc6 2. d4 e6 3. Nc3 f5 4. exf5 Nf6"),
  new FullOpening("B00", "Nimzowitsch Defense: Franco-Hiva Gambit", "r1bqkb1r/pppp2pp/2n1pn2/5P2/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 b8c6 d2d4 e7e6 g1f3 f7f5 e4f5 g8f6", "1. e4 Nc6 2. d4 e6 3. Nf3 f5 4. exf5 Nf6"),
  new FullOpening("B00", "Nimzowitsch Defense: Franco-Nimzowitsch Variation", "r1bqkbnr/pppp1ppp/2n1p3/8/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 b8c6 g1f3 e7e6", "1. e4 Nc6 2. Nf3 e6"),
  new FullOpening("B00", "Nimzowitsch Defense: French Connection", "r1bqkbnr/pppp1ppp/2n1p3/8/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 b1c3 e7e6", "1. e4 Nc6 2. Nc3 e6"),
  new FullOpening("B00", "Nimzowitsch Defense: Hornung Gambit", "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/4B3/PPP2PPP/RN1QKBNR b KQkq -", "e2e4 b8c6 d2d4 d7d5 c1e3", "1. e4 Nc6 2. d4 d5 3. Be3"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation", "r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e5", "1. e4 Nc6 2. d4 e5"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Bielefelder Gambit", "r1bqk1nr/pppp1ppp/2n5/2b1P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 f8c5", "1. e4 Nc6 2. d4 e5 3. dxe5 Bc5"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Hammer Gambit", "r1bqkbnr/pppp2pp/2n2p2/4P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 f7f6", "1. e4 Nc6 2. d4 e5 3. dxe5 f6"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Herford Gambit", "r1b1kbnr/pppp1ppp/2n5/4P3/4P2q/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 d8h4", "1. e4 Nc6 2. d4 e5 3. dxe5 Qh4"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Keres Attack", "r1bqkbnr/pppp1ppp/8/4n3/4P3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 c6e5 b1c3", "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. Nc3"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Linksspringer Variation", "r1bqkbnr/pppp1ppp/2n5/3Pp3/4P3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 b8c6 d2d4 e7e5 d4d5", "1. e4 Nc6 2. d4 e5 3. d5"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Main Line", "r1bqkbnr/pppp1ppp/6n1/8/4PP2/8/PPP3PP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 c6e5 f2f4 e5g6", "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. f4 Ng6"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Paulsen Attack", "r1bqkbnr/pppp1ppp/8/4n3/4P3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 c6e5 g1f3", "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. Nf3"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, Riemann Defense", "r1bqkbnr/pppp1ppp/2n5/8/4PP2/8/PPP3PP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 c6e5 f2f4 e5c6", "1. e4 Nc6 2. d4 e5 3. dxe5 Nxe5 4. f4 Nc6"),
  new FullOpening("B00", "Nimzowitsch Defense: Kennedy Variation, de Smet Gambit", "r1bqkbnr/ppp2ppp/2np4/4P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 e7e5 d4e5 d7d6", "1. e4 Nc6 2. d4 e5 3. dxe5 d6"),
  new FullOpening("B00", "Nimzowitsch Defense: Mikenas Variation", "r1bqkbnr/ppp1pppp/2np4/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d6", "1. e4 Nc6 2. d4 d6"),
  new FullOpening("B00", "Nimzowitsch Defense: Neo-Mongoloid Defense", "r1bqkbnr/ppppp1pp/2n2p2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 f7f6", "1. e4 Nc6 2. d4 f6"),
  new FullOpening("B00", "Nimzowitsch Defense: Pirc Connection", "r1bqkbnr/pppppp1p/2n3p1/8/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 b1c3 g7g6", "1. e4 Nc6 2. Nc3 g6"),
  new FullOpening("B00", "Nimzowitsch Defense: Pseudo-Spanish Variation", "r1bqkbnr/pppppppp/2n5/1B6/4P3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 b8c6 f1b5", "1. e4 Nc6 2. Bb5"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation", "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5", "1. e4 Nc6 2. d4 d5"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Aachen Gambit", "r1bqkbnr/ppp1pppp/8/3P4/1n1P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5 e4d5 c6b4", "1. e4 Nc6 2. d4 d5 3. exd5 Nb4"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Advance Variation", "r1bqkbnr/ppp1pppp/2n5/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 b8c6 d2d4 d7d5 e4e5", "1. e4 Nc6 2. d4 d5 3. e5"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation", "r1bqkbnr/ppp1pppp/2n5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3", "1. e4 Nc6 2. d4 d5 3. Nc3"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Brandics Gambit", "r1bqkbnr/1pp1pppp/p1n5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3 a7a6", "1. e4 Nc6 2. d4 d5 3. Nc3 a6"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Erben Gambit", "r1bqkbnr/ppp1pp1p/2n3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3 g7g6", "1. e4 Nc6 2. d4 d5 3. Nc3 g6"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Heinola-Deppe Gambit", "r1bqkbnr/ppp2ppp/2n5/3pp3/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3 e7e5", "1. e4 Nc6 2. d4 d5 3. Nc3 e5"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Nimzowitsch Gambit", "r1bqkbnr/ppp1pppp/8/3Pn3/4p3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3 d5e4 d4d5 c6e5", "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5 Ne5"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Richter Gambit", "rnbqkbnr/ppp1pppp/8/3P4/4p3/2N2P2/PPP3PP/R1BQKBNR b KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3 d5e4 d4d5 c6b8 f2f3", "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5 Nb8 5. f3"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Bogoljubov Variation, Vehre Variation", "r1bqkb1r/ppp1pppp/2n2n2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3 g8f6", "1. e4 Nc6 2. d4 d5 3. Nc3 Nf6"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Exchange Variation", "r1b1kbnr/ppp1pppp/2n5/3q4/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 d7d5 e4d5 d8d5", "1. e4 Nc6 2. d4 d5 3. exd5 Qxd5"),
  new FullOpening("B00", "Nimzowitsch Defense: Scandinavian Variation, Exchange Variation, Marshall Gambit", "r1b1kbnr/ppp1pppp/2n5/3q4/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 b8c6 d2d4 d7d5 e4d5 d8d5 b1c3", "1. e4 Nc6 2. d4 d5 3. exd5 Qxd5 4. Nc3"),
  new FullOpening("B00", "Nimzowitsch Defense: Wheeler Gambit", "r1bqkbnr/pppppppp/2n5/8/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 b8c6 b2b4", "1. e4 Nc6 2. b4"),
  new FullOpening("B00", "Nimzowitsch Defense: Williams Variation", "r1bqkbnr/ppp1pppp/2np4/8/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 b8c6 g1f3 d7d6", "1. e4 Nc6 2. Nf3 d6"),
  new FullOpening("B00", "Nimzowitsch Defense: Woodchuck Variation", "r1bqkbnr/1ppppppp/p1n5/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b8c6 d2d4 a7a6", "1. e4 Nc6 2. d4 a6"),
  new FullOpening("B00", "Owen Defense", "rnbqkbnr/p1pppppp/1p6/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 b7b6", "1. e4 b6"),
  new FullOpening("B00", "Owen Defense: Hekili-Loa Gambit", "r1bqkbnr/p2ppppp/1pn5/2P5/4P3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 b7b6 d2d4 c7c5 d4c5 b8c6", "1. e4 b6 2. d4 c5 3. dxc5 Nc6"),
  new FullOpening("B00", "Owen Defense: Matovinsky Gambit", "rn1qkbnr/p1ppp2p/1p4p1/5P1Q/3P4/3B4/PPP2PbP/RNB1K1NR w KQkq -", "e2e4 b7b6 d2d4 c8b7 f1d3 f7f5 e4f5 b7g2 d1h5 g7g6", "1. e4 b6 2. d4 Bb7 3. Bd3 f5 4. exf5 Bxg2 5. Qh5+ g6"),
  new FullOpening("B00", "Owen Defense: Naselwaus Gambit", "rn1qkbnr/pbpppppp/1p6/6B1/3PP3/8/PPP2PPP/RN1QKBNR b KQkq -", "e2e4 b7b6 d2d4 c8b7 c1g5", "1. e4 b6 2. d4 Bb7 3. Bg5"),
  new FullOpening("B00", "Owen Defense: Smith Gambit", "rn1qkbnr/pbpppppp/1p6/8/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 b7b6 d2d4 c8b7 g1f3", "1. e4 b6 2. d4 Bb7 3. Nf3"),
  new FullOpening("B00", "Owen Defense: Unicorn Variation", "rn1qkbnr/pbppp1pp/1p3p2/8/2PPP3/8/PP3PPP/RNBQKBNR w KQkq -", "e2e4 f7f6 d2d4 b7b6 c2c4 c8b7", "1. e4 f6 2. d4 b6 3. c4 Bb7"),
  new FullOpening("B00", "Owen Defense: Wind Gambit", "rn1qkbnr/pbpp1ppp/1p6/4p3/3PP3/5P2/PPP3PP/RNBQKBNR w KQkq -", "e2e4 b7b6 d2d4 c8b7 f2f3 e7e5", "1. e4 b6 2. d4 Bb7 3. f3 e5"),
  new FullOpening("B00", "Pirc Defense", "rnbqkbnr/ppp1pppp/3p4/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d6", "1. e4 d6"),
  new FullOpening("B00", "Pirc Defense", "rnbqkbnr/ppp1pppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 d7d6 d2d4", "1. e4 d6 2. d4"),
  new FullOpening("B00", "Pirc Defense", "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d6 d2d4 g8f6", "1. e4 d6 2. d4 Nf6"),
  new FullOpening("B00", "Pirc Defense: Roscher Gambit", "rnbqkb1r/ppp1pppp/3p1n2/8/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 d7d6 d2d4 g8f6 g1f3", "1. e4 d6 2. d4 Nf6 3. Nf3"),
  new FullOpening("B00", "Rat Defense: Antal Defense", "r1bqkbnr/pppnpppp/3p4/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d6 d2d4 b8d7", "1. e4 d6 2. d4 Nd7"),
  new FullOpening("B00", "Rat Defense: Fuller Gambit", "rnbqkb1r/ppp1pppp/5n2/3P4/5P2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 d7d6 f2f4 d6d5 e4d5 g8f6", "1. e4 d6 2. f4 d5 3. exd5 Nf6"),
  new FullOpening("B00", "Rat Defense: Harmonist", "rnbqkbnr/ppp1pppp/3p4/8/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -", "e2e4 d7d6 f2f4", "1. e4 d6 2. f4"),
  new FullOpening("B00", "Rat Defense: Petruccioli Attack", "rnbqkbnr/ppp1pppp/3p4/8/4P2P/8/PPPP1PP1/RNBQKBNR b KQkq -", "e2e4 d7d6 h2h4", "1. e4 d6 2. h4"),
  new FullOpening("B00", "Rat Defense: Spike Attack", "rnbqkbnr/ppp1pppp/3p4/8/4P1P1/8/PPPP1P1P/RNBQKBNR b KQkq -", "e2e4 d7d6 g2g4", "1. e4 d6 2. g4"),
  new FullOpening("B00", "St. George Defense", "rnbqkbnr/1ppppppp/p7/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 a7a6", "1. e4 a6"),
  new FullOpening("B00", "St. George Defense: Polish Variation", "rn1qkbnr/1bpp1ppp/p3p3/1p6/3PP3/3B1N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 a7a6 d2d4 b7b5 g1f3 c8b7 f1d3 e7e6", "1. e4 a6 2. d4 b5 3. Nf3 Bb7 4. Bd3 e6"),
  new FullOpening("B00", "St. George Defense: San Jorge Variation", "rn1qk1nr/1bp1ppbp/p2p2p1/1p6/3PP3/2PB1N2/PP3PPP/RNBQ1RK1 w kq -", "e2e4 a7a6 d2d4 b7b5 g1f3 c8b7 f1d3 d7d6 e1g1 g7g6 c2c3 f8g7", "1. e4 a6 2. d4 b5 3. Nf3 Bb7 4. Bd3 d6 5. O-O g6 6. c3 Bg7"),
  new FullOpening("B00", "St. George Defense: Zilbermints Gambit", "rnbqkbnr/1ppp1ppp/p7/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 a7a6 d2d4 e7e5", "1. e4 a6 2. d4 e5"),
  new FullOpening("B00", "Van Geet Opening: Berlin Gambit", "r1bqkbnr/ppp1pppp/2n5/3P4/4p3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 b8c6 d2d4 d7d5 b1c3 d5e4 d4d5", "1. e4 Nc6 2. d4 d5 3. Nc3 dxe4 4. d5"),
  new FullOpening("B00", "Ware Defense", "rnbqkbnr/1ppppppp/8/p7/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 a7a5", "1. e4 a5"),
  new FullOpening("B00", "Ware Defense: Snagglepuss Defense", "r1bqkbnr/1ppppppp/2n5/p7/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 a7a5 d2d4 b8c6", "1. e4 a5 2. d4 Nc6"),
  new FullOpening("B01", "Scandinavian Defense", "rnbqkbnr/ppp1pppp/8/3p4/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5", "1. e4 d5"),
  new FullOpening("B01", "Scandinavian Defense", "rnbqkbnr/ppp1pppp/8/3p4/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 d7d5 b2b3", "1. e4 d5 2. b3"),
  new FullOpening("B01", "Scandinavian Defense: Anderssen Counterattack", "rnb1kbnr/ppp2ppp/8/q3p3/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 e7e5", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5"),
  new FullOpening("B01", "Scandinavian Defense: Anderssen Counterattack, Collijn Variation", "rn2kbnr/ppp2ppp/8/q3p3/3P2b1/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 e7e5 g1f3 c8g4", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. Nf3 Bg4"),
  new FullOpening("B01", "Scandinavian Defense: Anderssen Counterattack, Goteborg System", "rnb1kbnr/ppp2ppp/8/q3p3/3P4/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 e7e5 g1f3", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. Nf3"),
  new FullOpening("B01", "Scandinavian Defense: Anderssen Counterattack, Orthodox Attack", "r1b1k1nr/ppp2ppp/2n5/q3P3/1b6/2N2N2/PPPB1PPP/R2QKB1R b KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 e7e5 d4e5 b8c6 g1f3 f8b4 c1d2", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 e5 5. dxe5 Nc6 6. Nf3 Bb4 7. Bd2"),
  new FullOpening("B01", "Scandinavian Defense: Blackburne Gambit", "r1bqkbnr/pp2pppp/2n5/8/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 c7c6 d5c6 b8c6", "1. e4 d5 2. exd5 c6 3. dxc6 Nxc6"),
  new FullOpening("B01", "Scandinavian Defense: Blackburne-Kloosterboer Gambit", "rnbqkbnr/pp2pppp/2p5/3P4/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 c7c6", "1. e4 d5 2. exd5 c6"),
  new FullOpening("B01", "Scandinavian Defense: Boehnke Gambit", "rn1qkbnr/ppp2ppp/4b3/8/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 e7e5 d5e6 c8e6", "1. e4 d5 2. exd5 e5 3. dxe6 Bxe6"),
  new FullOpening("B01", "Scandinavian Defense: Bronstein Variation", "rnb1kb1r/1pp1pppp/p2q1n2/8/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5d6 d2d4 g8f6 g1f3 a7a6", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 Nf6 5. Nf3 a6"),
  new FullOpening("B01", "Scandinavian Defense: Classical Variation", "rn2kb1r/ppp1pppp/5n2/q4b2/3P4/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 g8f6 g1f3 c8f5", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bf5"),
  new FullOpening("B01", "Scandinavian Defense: Grünfeld Variation", "rn2kb1r/pp2pppp/2p2n2/q3Nb2/3P2P1/2N5/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 g8f6 g1f3 c8f5 f3e5 c7c6 g2g4", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bf5 6. Ne5 c6 7. g4"),
  new FullOpening("B01", "Scandinavian Defense: Gubinsky-Melts Defense", "rnb1kbnr/ppp1pppp/3q4/8/8/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5d6", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6"),
  new FullOpening("B01", "Scandinavian Defense: Icelandic-Palme Gambit", "rnbqkb1r/ppp2ppp/4pn2/3P4/2P5/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 c2c4 e7e6", "1. e4 d5 2. exd5 Nf6 3. c4 e6"),
  new FullOpening("B01", "Scandinavian Defense: Kiel Variation", "rnbqkb1r/ppp1pppp/8/8/1nPP4/8/PP3PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 f6d5 c2c4 d5b4", "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. c4 Nb4"),
  new FullOpening("B01", "Scandinavian Defense: Kloosterboer Gambit", "rnbqkbnr/pp3ppp/2P5/4p3/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 c7c6 d5c6 e7e5", "1. e4 d5 2. exd5 c6 3. dxc6 e5"),
  new FullOpening("B01", "Scandinavian Defense: Kádas Gambit", "rnbqkb1r/pp3ppp/2P2n2/4p3/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 c7c6 d5c6 e7e5", "1. e4 d5 2. exd5 Nf6 3. d4 c6 4. dxc6 e5"),
  new FullOpening("B01", "Scandinavian Defense: Lasker Variation", "rn2kb1r/ppp1pppp/5n2/q7/3P2b1/2N2N1P/PPP2PP1/R1BQKB1R b KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 g8f6 g1f3 c8g4 h2h3", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6 5. Nf3 Bg4 6. h3"),
  new FullOpening("B01", "Scandinavian Defense: Main Line", "rnb1kbnr/ppp1pppp/8/q7/8/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5"),
  new FullOpening("B01", "Scandinavian Defense: Main Line, Leonhardt Gambit", "rnb1kbnr/ppp1pppp/8/q7/1P6/2N5/P1PP1PPP/R1BQKBNR b KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 b2b4", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. b4"),
  new FullOpening("B01", "Scandinavian Defense: Main Line, Mieses Variation", "rnb1kb1r/ppp1pppp/5n2/q7/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5a5 d2d4 g8f6", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qa5 4. d4 Nf6"),
  new FullOpening("B01", "Scandinavian Defense: Marshall Variation", "rnbqkb1r/ppp1pppp/8/3n4/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 f6d5", "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5"),
  new FullOpening("B01", "Scandinavian Defense: Mieses-Kotroc Variation", "rnb1kbnr/ppp1pppp/8/3q4/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 d8d5", "1. e4 d5 2. exd5 Qxd5"),
  new FullOpening("B01", "Scandinavian Defense: Modern Variation", "rnbqkb1r/ppp1pppp/5n2/3P4/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6", "1. e4 d5 2. exd5 Nf6"),
  new FullOpening("B01", "Scandinavian Defense: Modern Variation", "rnbqkb1r/ppp1pppp/5n2/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4", "1. e4 d5 2. exd5 Nf6 3. d4"),
  new FullOpening("B01", "Scandinavian Defense: Modern Variation, Gipslis Variation", "rn1qkb1r/ppp1pppp/8/3n4/3P2b1/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 f6d5 g1f3 c8g4", "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. Nf3 Bg4"),
  new FullOpening("B01", "Scandinavian Defense: Modern Variation, Wing Gambit", "rnbqkb1r/p1p1pp1p/5np1/1p1P4/2PP4/8/PP3PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 g7g6 c2c4 b7b5", "1. e4 d5 2. exd5 Nf6 3. d4 g6 4. c4 b5"),
  new FullOpening("B01", "Scandinavian Defense: Panov Transfer", "rnbqkb1r/pp2pppp/2p2n2/3P4/2P5/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 c2c4 c7c6", "1. e4 d5 2. exd5 Nf6 3. c4 c6"),
  new FullOpening("B01", "Scandinavian Defense: Portuguese Variation", "rn1qkb1r/ppp1pppp/5n2/3P4/3P2b1/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 c8g4", "1. e4 d5 2. exd5 Nf6 3. d4 Bg4"),
  new FullOpening("B01", "Scandinavian Defense: Portuguese Variation, Portuguese Gambit", "r2qkb1r/pppnpppp/5n2/1B1P1b2/3P4/5P2/PPP3PP/RNBQK1NR w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 c8g4 f1b5 b8d7 f2f3 g4f5", "1. e4 d5 2. exd5 Nf6 3. d4 Bg4 4. Bb5+ Nbd7 5. f3 Bf5"),
  new FullOpening("B01", "Scandinavian Defense: Richter Variation", "rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 f6d5 g1f3 g7g6", "1. e4 d5 2. exd5 Nf6 3. d4 Nxd5 4. Nf3 g6"),
  new FullOpening("B01", "Scandinavian Defense: Richter Variation", "rnbqkb1r/ppp1pp1p/5np1/3P4/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d5 e4d5 g8f6 d2d4 g7g6", "1. e4 d5 2. exd5 Nf6 3. d4 g6"),
  new FullOpening("B01", "Scandinavian Defense: Schiller-Pytel Variation", "rnb1kbnr/pp2pppp/2pq4/8/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5d6 d2d4 c7c6", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 c6"),
  new FullOpening("B01", "Scandinavian Defense: Schiller-Pytel Variation, Modern Variation", "rn2kb1r/pp2pppp/2p2n2/5b2/1qBP1B2/2N5/PPP1NPPP/R2QK2R w KQkq -", "e2e4 d7d5 e4d5 d8d5 b1c3 d5d6 d2d4 g8f6 f1c4 c7c6 g1e2 c8f5 c1f4 d6b4", "1. e4 d5 2. exd5 Qxd5 3. Nc3 Qd6 4. d4 Nf6 5. Bc4 c6 6. Nge2 Bf5 7. Bf4 Qb4"),
  new FullOpening("B01", "Scandinavian Defense: Zilbermints Gambit", "rnbqkbnr/ppp1pppp/8/3p4/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 d7d5 b2b4", "1. e4 d5 2. b4"),
  new FullOpening("B01", "Van Geet Opening: Grünfeld Defense", "rnbqkbnr/ppp2ppp/8/4p3/4N3/8/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 d7d5 b1c3 d5e4 c3e4 e7e5", "1. e4 d5 2. Nc3 dxe4 3. Nxe4 e5"),
  new FullOpening("B02", "Alekhine Defense", "rnbqkb1r/pppppppp/5n2/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 g8f6", "1. e4 Nf6"),
  new FullOpening("B02", "Alekhine Defense: Brooklyn Variation", "rnbqkbnr/pppppppp/8/4P3/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6g8", "1. e4 Nf6 2. e5 Ng8"),
  new FullOpening("B02", "Alekhine Defense: Brooklyn Variation, Everglades Variation", "rnbqkbnr/ppppp1pp/8/4Pp2/3P4/8/PPP2PPP/RNBQKBNR w KQkq f6", "e2e4 g8f6 e4e5 f6g8 d2d4 f7f5", "1. e4 Nf6 2. e5 Ng8 3. d4 f5"),
  new FullOpening("B02", "Alekhine Defense: Buckley Attack", "rnbqkb1r/pppppppp/8/3nP3/8/N7/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 b1a3", "1. e4 Nf6 2. e5 Nd5 3. Na3"),
  new FullOpening("B02", "Alekhine Defense: Hunt Variation, Lasker Simul Gambit", "rnbqkb1r/pppp1ppp/4p3/2PnP3/2B5/2N5/PP1P1PPP/R1BQK1NR b KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4 d5b6 c4c5 b6d5 f1c4 e7e6 b1c3", "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Bc4 e6 6. Nc3"),
  new FullOpening("B02", "Alekhine Defense: Hunt Variation, Matsukevich Gambit", "rnbqkb1r/ppp1pppp/3p4/2P1P1B1/8/2P5/PP3PPP/R2QKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4 d5b6 c4c5 b6d5 b1c3 d5c3 d2c3 d7d6 c1g5", "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Nc3 Nxc3 6. dxc3 d6 7. Bg5"),
  new FullOpening("B02", "Alekhine Defense: Hunt Variation, Mikenas Gambit", "rnbqkb1r/ppp2ppp/3p4/2PBP3/8/8/PP1P1PPP/R1BQK1NR b KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4 d5b6 c4c5 b6d5 f1c4 e7e6 b1c3 d7d6 c3d5 e6d5 c4d5", "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Bc4 e6 6. Nc3 d6 7. Nxd5 exd5 8. Bxd5"),
  new FullOpening("B02", "Alekhine Defense: Kmoch Variation", "rnbqkb1r/pp1ppppp/1n6/2p1P3/8/1B1P4/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 g8f6 e4e5 f6d5 f1c4 d5b6 c4b3 c7c5 d2d3", "1. e4 Nf6 2. e5 Nd5 3. Bc4 Nb6 4. Bb3 c5 5. d3"),
  new FullOpening("B02", "Alekhine Defense: Krejcik Variation", "rnbqkb1r/pppppppp/5n2/8/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 g8f6 f1c4", "1. e4 Nf6 2. Bc4"),
  new FullOpening("B02", "Alekhine Defense: Krejcik Variation, Krejcik Gambit", "rnbqkb1r/pppppBpp/8/8/4n3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 g8f6 f1c4 f6e4 c4f7", "1. e4 Nf6 2. Bc4 Nxe4 3. Bxf7+"),
  new FullOpening("B02", "Alekhine Defense: Maróczy Variation", "rnbqkb1r/pppppppp/5n2/8/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 d2d3", "1. e4 Nf6 2. d3"),
  new FullOpening("B02", "Alekhine Defense: Mokele Mbembe", "rnbqkb1r/pppppppp/8/4P3/4n3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6e4", "1. e4 Nf6 2. e5 Ne4"),
  new FullOpening("B02", "Alekhine Defense: Mokele Mbembe, Modern Line", "rnbqkb1r/ppppp1pp/5p2/4P3/3Pn3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6e4 d2d4 f7f6", "1. e4 Nf6 2. e5 Ne4 3. d4 f6"),
  new FullOpening("B02", "Alekhine Defense: Mokele Mbembe, Vavra Defense", "rnbqkb1r/pppp1ppp/4p3/4P3/3Pn3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6e4 d2d4 e7e6", "1. e4 Nf6 2. e5 Ne4 3. d4 e6"),
  new FullOpening("B02", "Alekhine Defense: Normal Variation", "rnbqkb1r/pppppppp/8/3nP3/8/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6d5", "1. e4 Nf6 2. e5 Nd5"),
  new FullOpening("B02", "Alekhine Defense: Scandinavian Variation", "rnbqkb1r/ppp1pppp/5n2/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 g8f6 b1c3 d7d5", "1. e4 Nf6 2. Nc3 d5"),
  new FullOpening("B02", "Alekhine Defense: Scandinavian Variation, Geschev Gambit", "rnbqkb1r/pp2pppp/2p2n2/3P4/8/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 g8f6 b1c3 d7d5 e4d5 c7c6", "1. e4 Nf6 2. Nc3 d5 3. exd5 c6"),
  new FullOpening("B02", "Alekhine Defense: Scandinavian Variation, Myers Gambit", "rnbqkb1r/ppp1pppp/5n2/6B1/4p3/2NP4/PPP2PPP/R2QKBNR b KQkq -", "e2e4 g8f6 b1c3 d7d5 d2d3 d5e4 c1g5", "1. e4 Nf6 2. Nc3 d5 3. d3 dxe4 4. Bg5"),
  new FullOpening("B02", "Alekhine Defense: Spielmann Gambit", "rnbqkb1r/pppnpppp/4P3/3p4/8/2N5/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 g8f6 b1c3 d7d5 e4e5 f6d7 e5e6", "1. e4 Nf6 2. Nc3 d5 3. e5 Nfd7 4. e6"),
  new FullOpening("B02", "Alekhine Defense: Steiner Variation", "rnbqkb1r/pppppppp/1n6/4P3/2P5/1P6/P2P1PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4 d5b6 b2b3", "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. b3"),
  new FullOpening("B02", "Alekhine Defense: Sämisch Attack", "rnbqkb1r/pppppppp/8/3nP3/8/2N5/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 b1c3", "1. e4 Nf6 2. e5 Nd5 3. Nc3"),
  new FullOpening("B02", "Alekhine Defense: The Squirrel", "rnbqkb1r/pppppppp/8/4P3/2P2n2/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4 d5f4", "1. e4 Nf6 2. e5 Nd5 3. c4 Nf4"),
  new FullOpening("B02", "Alekhine Defense: Two Pawn Attack", "rnbqkb1r/pppppppp/8/3nP3/2P5/8/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4", "1. e4 Nf6 2. e5 Nd5 3. c4"),
  new FullOpening("B02", "Alekhine Defense: Two Pawn Attack, Lasker Variation", "rnbqkb1r/pppppppp/1n6/2P1P3/8/8/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4 d5b6 c4c5", "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5"),
  new FullOpening("B02", "Alekhine Defense: Two Pawns Attack, Mikenas Variation", "rnbqkb1r/ppp2ppp/3pp3/2PnP3/2B5/2N5/PP1P1PPP/R1BQK1NR w KQkq -", "e2e4 g8f6 e4e5 f6d5 c2c4 d5b6 c4c5 b6d5 f1c4 e7e6 b1c3 d7d6", "1. e4 Nf6 2. e5 Nd5 3. c4 Nb6 4. c5 Nd5 5. Bc4 e6 6. Nc3 d6"),
  new FullOpening("B02", "Alekhine Defense: Welling Variation", "rnbqkb1r/pppppppp/8/3nP3/8/1P6/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 b2b3", "1. e4 Nf6 2. e5 Nd5 3. b3"),
  new FullOpening("B03", "Alekhine Defense", "rnbqkb1r/pppppppp/8/3nP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4", "1. e4 Nf6 2. e5 Nd5 3. d4"),
  new FullOpening("B03", "Alekhine Defense", "rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6"),
  new FullOpening("B03", "Alekhine Defense", "rnbqkb1r/ppp1pppp/3p4/3nP3/2PP4/8/PP3PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4"),
  new FullOpening("B03", "Alekhine Defense: Balogh Variation", "rnbqkb1r/ppp1pppp/3p4/3nP3/2BP4/8/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 f1c4", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Bc4"),
  new FullOpening("B03", "Alekhine Defense: Exchange Variation", "rnbqkb1r/ppp1pppp/1n1P4/8/2PP4/8/PP3PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 e5d6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. exd6"),
  new FullOpening("B03", "Alekhine Defense: Exchange Variation, Karpov Variation", "r2q1rk1/pp2ppbp/1nnp2p1/5b2/2PP1B2/2N2N1P/PP2BPP1/R2Q1RK1 b - -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 e5d6 c7d6 b1c3 g7g6 h2h3 f8g7 g1f3 e8g8 f1e2 b8c6 e1g1 c8f5 c1f4", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. exd6 cxd6 6. Nc3 g6 7. h3 Bg7 8. Nf3 O-O 9. Be2 Nc6 10. O-O Bf5 11. Bf4"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack", "rnbqkb1r/ppp1pppp/1n1p4/4P3/2PP1P2/8/PP4PP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack, Cambridge Gambit", "rnbqkb1r/ppp1pp1p/1n1p4/4P1p1/2PP1P2/8/PP4PP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 g7g5", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 g5"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack, Fianchetto Variation", "rnbqkb1r/ppp1pp1p/1n1p2p1/4P3/2PP1P2/8/PP4PP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 g7g6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 g6"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack, Ilyin-Zhenevsky Variation", "r2qkb1r/ppp1p1pp/1nn1p3/2P5/3P2b1/5N2/PP4PP/RNBQKB1R b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 d6e5 f4e5 b8c6 g1f3 c8g4 e5e6 f7e6 c4c5", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 dxe5 6. fxe5 Nc6 7. Nf3 Bg4 8. e6 fxe6 9. c5"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack, Korchnoi Variation", "rn1q1rk1/ppp1b1pp/1n2pp2/4Pb2/2PP4/2N2N2/PP2B1PP/R1BQ1RK1 w - -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 d6e5 f4e5 c8f5 b1c3 e7e6 g1f3 f8e7 f1e2 e8g8 e1g1 f7f6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 dxe5 6. fxe5 Bf5 7. Nc3 e6 8. Nf3 Be7 9. Be2 O-O 10. O-O f6"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack, Main Line", "r1bqkb1r/ppp1pppp/1nn5/4P3/2PP4/4B3/PP4PP/RN1QKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 d6e5 f4e5 b8c6 c1e3", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 dxe5 6. fxe5 Nc6 7. Be3"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack, Tartakower Variation", "2kr3r/pppqbppp/1nn1p3/4Pb2/2PP4/2N1BN2/PP2B1PP/R2Q1RK1 w - -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 d6e5 f4e5 b8c6 c1e3 c8f5 b1c3 e7e6 g1f3 d8d7 f1e2 e8c8 e1g1 f8e7", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 dxe5 6. fxe5 Nc6 7. Be3 Bf5 8. Nc3 e6 9. Nf3 Qd7 10. Be2 O-O-O 11. O-O Be7"),
  new FullOpening("B03", "Alekhine Defense: Four Pawns Attack, Trifunovic Variation", "rn1qkb1r/ppp1pppp/1n1p4/4Pb2/2PP1P2/8/PP4PP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 f2f4 c8f5", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. f4 Bf5"),
  new FullOpening("B03", "Alekhine Defense: Hunt Variation", "rnbqkb1r/ppp1pppp/1n1p4/2P1P3/3P4/8/PP3PPP/RNBQKBNR b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 c4c5", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. c5"),
  new FullOpening("B03", "Alekhine Defense: Modern Variation, Alekhine Gambit", "rn1qkb1r/ppp1pppp/1n1p4/4P3/2PP2b1/5N2/PP2BPPP/RNBQK2R b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 c2c4 d5b6 g1f3 c8g4 f1e2", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. c4 Nb6 5. Nf3 Bg4 6. Be2"),
  new FullOpening("B03", "Alekhine Defense: O'Sullivan Gambit", "rnbqkb1r/p1pppppp/8/1p1nP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 b7b5", "1. e4 Nf6 2. e5 Nd5 3. d4 b5"),
  new FullOpening("B04", "Alekhine Defense: Modern Variation", "rnbqkb1r/ppp1pppp/3p4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3"),
  new FullOpening("B04", "Alekhine Defense: Modern Variation, Alburt Variation", "rnbqkb1r/ppp1pp1p/3p2p1/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 g7g6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 g6"),
  new FullOpening("B04", "Alekhine Defense: Modern Variation, Keres Variation", "rnbqk2r/ppp1ppbp/1n1p2p1/4P3/P2P4/1B3N2/1PP2PPP/RNBQK2R b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 g7g6 f1c4 d5b6 c4b3 f8g7 a2a4", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 g6 5. Bc4 Nb6 6. Bb3 Bg7 7. a4"),
  new FullOpening("B04", "Alekhine Defense: Modern Variation, Larsen Variation", "rnbqkb1r/ppp1pppp/8/3np3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 d6e5", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 dxe5"),
  new FullOpening("B04", "Alekhine Defense: Modern Variation, Larsen-Haakert Variation", "r1bqkb1r/ppp1pppp/2np4/3nP3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 b8c6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Nc6"),
  new FullOpening("B04", "Alekhine Defense: Modern Variation, Schmid Variation", "rnbqkb1r/ppp1pppp/1n1p4/4P3/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 d5b6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Nb6"),
  new FullOpening("B05", "Alekhine Defense: Modern Variation, Alekhine Variation", "rn1qkb1r/ppp1pppp/3p4/3nP3/2PP2b1/5N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 c8g4 c2c4", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. c4"),
  new FullOpening("B05", "Alekhine Defense: Modern Variation, Flohr Variation", "rn1qkb1r/pp2pppp/2pp4/3nP3/3P2b1/5N2/PPP1BPPP/RNBQK2R w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 c8g4 f1e2 c7c6", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. Be2 c6"),
  new FullOpening("B05", "Alekhine Defense: Modern Variation, Main Line", "rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 c8g4", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4"),
  new FullOpening("B05", "Alekhine Defense: Modern Variation, Panov Variation", "rn1qkb1r/ppp1pppp/3p4/3nP3/3P2b1/5N1P/PPP2PP1/RNBQKB1R b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 c8g4 h2h3", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. h3"),
  new FullOpening("B05", "Alekhine Defense: Modern Variation, Vitolins Attack", "rn1qkb1r/ppp1pppp/1n1p4/3PP3/2P3b1/5N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 g8f6 e4e5 f6d5 d2d4 d7d6 g1f3 c8g4 c2c4 d5b6 d4d5", "1. e4 Nf6 2. e5 Nd5 3. d4 d6 4. Nf3 Bg4 5. c4 Nb6 6. d5"),
  new FullOpening("B06", "Modern Defense", "rnbqkbnr/pppppp1p/6p1/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 g7g6", "1. e4 g6"),
  new FullOpening("B06", "Modern Defense", "rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7", "1. e4 g6 2. d4 Bg7"),
  new FullOpening("B06", "Modern Defense: Anti-Modern", "rnbqk1nr/pp2ppbp/2pp2p1/8/2BPP3/2N5/PPP1QPPP/R1B1K1NR b KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c6 f1c4 d7d6 d1e2", "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. Bc4 d6 5. Qe2"),
  new FullOpening("B06", "Modern Defense: Bishop Attack", "rnbqk1nr/ppppppbp/6p1/8/2BPP3/8/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 g7g6 d2d4 f8g7 f1c4", "1. e4 g6 2. d4 Bg7 3. Bc4"),
  new FullOpening("B06", "Modern Defense: Bishop Attack, Bücker Gambit", "rnbqk1nr/p1ppppbp/6p1/1p6/2BPP3/8/PPP2PPP/RNBQK1NR w KQkq -", "e2e4 g7g6 d2d4 f8g7 f1c4 b7b5", "1. e4 g6 2. d4 Bg7 3. Bc4 b5"),
  new FullOpening("B06", "Modern Defense: Bishop Attack, Monkey's Bum", "rnbqk1nr/pppp1p1p/4p1p1/8/2BbP3/5Q2/PPP2PPP/RNB1K1NR w KQkq -", "e2e4 g7g6 f1c4 f8g7 d1f3 e7e6 d2d4 g7d4", "1. e4 g6 2. Bc4 Bg7 3. Qf3 e6 4. d4 Bxd4"),
  new FullOpening("B06", "Modern Defense: Dunworthy Variation", "rnbqk1nr/pp2pp1p/2P3p1/8/2Pb4/8/PP3PPP/RNBQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 c2c4 d7d5 e4d5 c7c6 d5c6 g7d4", "1. e4 g6 2. d4 Bg7 3. c4 d5 4. exd5 c6 5. dxc6 Bxd4"),
  new FullOpening("B06", "Modern Defense: Fianchetto Gambit", "rnbqkbnr/ppppp2p/6p1/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g7g6 d2d4 f7f5", "1. e4 g6 2. d4 f5"),
  new FullOpening("B06", "Modern Defense: Gurgenidze Defense", "rnbqk1nr/pp2ppb1/2p3p1/3pP2p/3P1P2/2N5/PPP3PP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c6 f2f4 d7d5 e4e5 h7h5", "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. f4 d5 5. e5 h5"),
  new FullOpening("B06", "Modern Defense: Lizard Defense, Mittenberger Gambit", "rnbqk1nr/ppp1ppbp/6p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 d7d5", "1. e4 g6 2. d4 Bg7 3. Nc3 d5"),
  new FullOpening("B06", "Modern Defense: Masur Gambit", "rnbq1rk1/ppppp2p/6pb/5P2/3P4/2N5/PPP2PPP/R2QKBNR w KQ -", "e2e4 g7g6 d2d4 g8h6 b1c3 f7f5 c1h6 f8h6 e4f5 e8g8", "1. e4 g6 2. d4 Nh6 3. Nc3 f5 4. Bxh6 Bxh6 5. exf5 O-O"),
  new FullOpening("B06", "Modern Defense: Modern Pterodactyl", "rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5", "1. e4 g6 2. d4 Bg7 3. Nc3 c5"),
  new FullOpening("B06", "Modern Defense: Mongredien Defense", "rnbqk1nr/p1ppppbp/1p4p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 b7b6", "1. e4 g6 2. d4 Bg7 3. Nc3 b6"),
  new FullOpening("B06", "Modern Defense: Mongredien Defense", "rnbqk1nr/p1ppppbp/1p4p1/8/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 g7g6 d2d4 f8g7 g1f3 b7b6", "1. e4 g6 2. d4 Bg7 3. Nf3 b6"),
  new FullOpening("B06", "Modern Defense: Norwegian Defense", "rnbqkb1r/pppppp1p/5np1/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 g7g6 d2d4 g8f6", "1. e4 g6 2. d4 Nf6"),
  new FullOpening("B06", "Modern Defense: Norwegian Defense, Norwegian Gambit", "rnbqkb1r/ppp1pp1p/3p2p1/4P2n/3P4/8/PPP1BPPP/RNBQK1NR w KQkq -", "e2e4 g7g6 d2d4 g8f6 e4e5 f6h5 f1e2 d7d6", "1. e4 g6 2. d4 Nf6 3. e5 Nh5 4. Be2 d6"),
  new FullOpening("B06", "Modern Defense: Pseudo-Austrian Attack", "rnbqk1nr/ppp1ppbp/3p2p1/8/3PPP2/2N5/PPP3PP/R1BQKBNR b KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 d7d6 f2f4", "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. f4"),
  new FullOpening("B06", "Modern Defense: Semi-Averbakh Variation, Pterodactyl Variation Declined", "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/5N2/PP2BPPP/RNBQK2R w KQkq -", "e2e4 g7g6 d2d4 f8g7 c2c4 c7c5 g1f3 d7d6 f1e2 d8a5", "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nf3 d6 5. Be2 Qa5+"),
  new FullOpening("B06", "Modern Defense: Standard Defense", "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 d7d6", "1. e4 g6 2. d4 Bg7 3. Nc3 d6"),
  new FullOpening("B06", "Modern Defense: Standard Line", "rnbqk1nr/ppppppbp/6p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3", "1. e4 g6 2. d4 Bg7 3. Nc3"),
  new FullOpening("B06", "Modern Defense: Three Pawns Attack", "rnbqk1nr/ppppppbp/6p1/8/3PPP2/8/PPP3PP/RNBQKBNR b KQkq -", "e2e4 g7g6 d2d4 f8g7 f2f4", "1. e4 g6 2. d4 Bg7 3. f4"),
  new FullOpening("B06", "Modern Defense: Two Knights Variation", "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 d7d6 g1f3", "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Nf3"),
  new FullOpening("B06", "Modern Defense: Two Knights Variation, Suttles Variation", "rnbqk1nr/pp2ppbp/2pp2p1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c6 g1f3 d7d6", "1. e4 g6 2. d4 Bg7 3. Nc3 c6 4. Nf3 d6"),
  new FullOpening("B06", "Modern Defense: Two Knights Variation, Suttles Variation, Tal Gambit", "rnb1k1nr/pp2ppbp/2pp2p1/6B1/3PP3/2N2N2/PqPQ1PPP/R3KB1R w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 d7d6 g1f3 c7c6 c1g5 d8b6 d1d2 b6b2", "1. e4 g6 2. d4 Bg7 3. Nc3 d6 4. Nf3 c6 5. Bg5 Qb6 6. Qd2 Qxb2"),
  new FullOpening("B06", "Modern Defense: Westermann Gambit", "rnbqk1nr/ppppppbp/6p1/8/3PP3/8/PPPB1PPP/RN1QKBNR b KQkq -", "e2e4 g7g6 d2d4 f8g7 c1d2", "1. e4 g6 2. d4 Bg7 3. Bd2"),
  new FullOpening("B06", "Modern Defense: Wind Gambit", "rnbqk1nr/ppppppbp/6p1/8/3PP3/3B4/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 g7g6 d2d4 f8g7 f1d3", "1. e4 g6 2. d4 Bg7 3. Bd3"),
  new FullOpening("B06", "Norwegian Defense", "rnbqkb1r/ppppppnp/6p1/4P3/3P2P1/8/PPP2P1P/RNBQKBNR w KQkq -", "e2e4 g7g6 d2d4 g8f6 e4e5 f6h5 g2g4 h5g7", "1. e4 g6 2. d4 Nf6 3. e5 Nh5 4. g4 Ng7"),
  new FullOpening("B06", "Pterodactyl Defense: Austrian, Austriadactylus Western", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PPP2/5N2/PPP3PP/RNBQKB1R w KQkq -", "e2e4 g7g6 d2d4 f8g7 f2f4 c7c5 g1f3 d8a5", "1. e4 g6 2. d4 Bg7 3. f4 c5 4. Nf3 Qa5+"),
  new FullOpening("B06", "Pterodactyl Defense: Austrian, Grand Prix Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1p5/4PP2/2N2N2/PPPP2PP/R1BQKB1R w KQkq -", "e2e4 g7g6 b1c3 f8g7 f2f4 c7c5 g1f3 d8a5", "1. e4 g6 2. Nc3 Bg7 3. f4 c5 4. Nf3 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Austrian, Pteranodon", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PPP2/2P5/PP4PP/RNBQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 f2f4 c7c5 c2c3 d8a5", "1. e4 g6 2. d4 Bg7 3. f4 c5 4. c3 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Central, Anhanguera", "rnb1k1nr/pp2ppbp/3p2p1/q1p5/2PPP3/2N1B3/PP3PPP/R2QKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 c2c4 c7c5 b1c3 d7d6 c1e3 d8a5", "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nc3 d6 5. Be3 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Central, Benoni Quetzalcoatlus", "rnb1k1nr/pp2ppbp/3p2p1/q1pP4/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 c2c4 c7c5 d4d5 d7d6 b1c3 d8a5", "1. e4 g6 2. d4 Bg7 3. c4 c5 4. d5 d6 5. Nc3 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Central, Quetzalcoatlus Gambit", "rnb1k1nr/pp2ppbp/3p2p1/q1P5/2P1P3/2N5/PP3PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 c2c4 c7c5 b1c3 d7d6 d4c5 d8a5", "1. e4 g6 2. d4 Bg7 3. c4 c5 4. Nc3 d6 5. dxc5 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Eastern, Anhanguera", "rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/2N1B3/PPP2PPP/R2QKBNR b KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5 c1e3", "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Be3"),
  new FullOpening("B06", "Pterodactyl Defense: Eastern, Benoni", "rnbqk1nr/pp1pppbp/6p1/2pP4/4P3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "d2d4 g7g6 e2e4 f8g7 b1c3 c7c5 d4d5", "1. d4 g6 2. e4 Bg7 3. Nc3 c5 4. d5"),
  new FullOpening("B06", "Pterodactyl Defense: Eastern, Benoni Pteranodon", "rnb1k1nr/pp1ppp1p/6p1/q1pP4/4P3/2P5/P1P2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5 d4d5 g7c3 b2c3 d8a5", "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. d5 Bxc3+ 5. bxc3 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Eastern, Benoni Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1pP4/4P3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "d2d4 g7g6 b1c3 f8g7 e2e4 c7c5 d4d5 d8a5", "1. d4 g6 2. Nc3 Bg7 3. e4 c5 4. d5 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Eastern, Pteranodon", "rnb1k1nr/pp1ppp1p/6p1/q1P5/4P3/2P5/P1P2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5 d4c5 g7c3 b2c3 d8a5", "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5 Bxc3+ 5. bxc3 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Eastern, Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5 d4c5 d8a5", "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5 Qa5"),
  new FullOpening("B06", "Pterodactyl Defense: Eastern, Rhamphorhynchus", "rnbqk1nr/pp1pppbp/6p1/2P5/4P3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5 d4c5", "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. dxc5"),
  new FullOpening("B06", "Pterodactyl Defense: Fianchetto, King Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/5NP1/PPP2P1P/RNBQKB1R w KQkq -", "e2e4 g7g6 d2d4 f8g7 g2g3 c7c5 g1f3 d8a5", "1. e4 g6 2. d4 Bg7 3. g3 c5 4. Nf3 Qa5+"),
  new FullOpening("B06", "Pterodactyl Defense: Fianchetto, Rhamphorhynchus", "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/6P1/PPP2P1P/RNBQKBNR w KQkq -", "e2e4 g7g6 d2d4 f8g7 g2g3 c7c5 d4c5 d8a5", "1. e4 g6 2. d4 Bg7 3. g3 c5 4. dxc5 Qa5+"),
  new FullOpening("B06", "Pterodactyl Defense: Sicilian, Quetzalcoatlus", "rnb1k1nr/pp2ppbp/3p2p1/q1p5/3PP3/2N2N2/PPP1BPPP/R1BQK2R w KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5 g1f3 d8a5 f1e2 d7d6", "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Nf3 Qa5 5. Be2 d6"),
  new FullOpening("B06", "Pterodactyl Defense: Sicilian, Siroccopteryx", "rnb1k1nr/pp1pppbp/6p1/q1p5/2BPP3/2N2N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 g7g6 d2d4 f8g7 b1c3 c7c5 g1f3 d8a5 f1c4", "1. e4 g6 2. d4 Bg7 3. Nc3 c5 4. Nf3 Qa5 5. Bc4"),
  new FullOpening("B06", "Pterodactyl Defense: Western, Anhanguera", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/4BN2/PPP2PPP/RN1QKB1R w KQkq -", "e2e4 g7g6 d2d4 f8g7 g1f3 c7c5 c1e3 d8a5", "1. e4 g6 2. d4 Bg7 3. Nf3 c5 4. Be3 Qa5+"),
  new FullOpening("B06", "Pterodactyl Defense: Western, Siroccopteryx", "rnb1k1nr/pp1pppbp/6p1/q7/2BNP3/8/PPP2PPP/RNBQK2R w KQkq -", "e2e4 g7g6 g1f3 f8g7 d2d4 c7c5 f1c4 c5d4 f3d4 d8a5", "1. e4 g6 2. Nf3 Bg7 3. d4 c5 4. Bc4 cxd4 5. Nxd4 Qa5+"),
  new FullOpening("B06", "Rat Defense: Accelerated Gurgenidze", "rnbqkbnr/pp2pp1p/2pp2p1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 g7g6 d2d4 d7d6 b1c3 c7c6", "1. e4 g6 2. d4 d6 3. Nc3 c6"),
  new FullOpening("B07", "Czech Defense", "rnbqkb1r/pp2pppp/2pp1n2/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 c7c6", "1. e4 d6 2. d4 Nf6 3. Nc3 c6"),
  new FullOpening("B07", "King's Pawn Game: Maróczy Defense", "rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 d7d6 d2d4 e7e5", "1. e4 d6 2. d4 e5"),
  new FullOpening("B07", "Lion Defense: Anti-Philidor", "r1bqkb1r/pppnpppp/3p1n2/8/3PPP2/2N5/PPP3PP/R1BQKBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 b8d7 f2f4", "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f4"),
  new FullOpening("B07", "Lion Defense: Anti-Philidor, Lion's Cave", "r1bqkb1r/pppn1ppp/3p1n2/4p3/3PPP2/2N5/PPP3PP/R1BQKBNR w KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 b8d7 f2f4 e7e5", "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f4 e5"),
  new FullOpening("B07", "Lion Defense: Anti-Philidor, Lion's Cave, Lion Claw Gambit", "r1bqkb1r/pp1n1ppp/2p2n2/3p4/2BQPP2/2N2N2/PPP3PP/R1B1K2R w KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 b8d7 f2f4 e7e5 g1f3 e5d4 d1d4 c7c6 f1c4 d6d5", "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. f4 e5 5. Nf3 exd4 6. Qxd4 c6 7. Bc4 d5"),
  new FullOpening("B07", "Lion Defense: Bayonet Attack", "r1bqkb1r/pppnpppp/3p1n2/8/3PP1P1/2N5/PPP2P1P/R1BQKBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 b8d7 g2g4", "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. g4"),
  new FullOpening("B07", "Modern Defense: Geller's System", "rnbqk1nr/ppp1ppbp/3p2p1/8/3PP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 g7g6 d2d4 f8g7 g1f3 d7d6 c2c3", "1. e4 g6 2. d4 Bg7 3. Nf3 d6 4. c3"),
  new FullOpening("B07", "Philidor Defense: Lion Variation, Lion's Claw", "r1bqk2r/pp1nbppp/2pp1n2/4p3/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1 w kq -", "e2e4 d7d6 d2d4 g8f6 b1c3 c7c6 f1e2 b8d7 g1f3 e7e5 e1g1 f8e7", "1. e4 d6 2. d4 Nf6 3. Nc3 c6 4. Be2 Nbd7 5. Nf3 e5 6. O-O Be7"),
  new FullOpening("B07", "Pirc Defense", "rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6", "1. e4 d6 2. d4 Nf6 3. Nc3 g6"),
  new FullOpening("B07", "Pirc Defense: 150 Attack", "rnbqkb1r/pp2pp1p/2pp1np1/8/3PP3/2N1B3/PPPQ1PPP/R3KBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 c1e3 c7c6 d1d2", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. Qd2"),
  new FullOpening("B07", "Pirc Defense: 150 Attack, Inner Doll Defense", "rn1qkb1r/pp2pp1p/2pp1np1/8/3PP1b1/2N1B3/PPPQ1PPP/R3KBNR w KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 c1e3 c7c6 d1d2 c8g4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. Qd2 Bg4"),
  new FullOpening("B07", "Pirc Defense: 150 Attack, Sveshnikov-Jansa Attack", "rnbqkb1r/pp2pp1p/2pp1np1/8/3PP3/2N1B2P/PPP2PP1/R2QKBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 c1e3 c7c6 h2h3", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 c6 5. h3"),
  new FullOpening("B07", "Pirc Defense: Bayonet Attack", "rnbqk2r/ppp1ppbp/3p1np1/8/3PP2P/2N5/PPP1BPP1/R1BQK1NR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f1e2 f8g7 h2h4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2 Bg7 5. h4"),
  new FullOpening("B07", "Pirc Defense: Byrne Variation", "rnbqkb1r/ppp1pp1p/3p1np1/6B1/3PP3/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 c1g5", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bg5"),
  new FullOpening("B07", "Pirc Defense: Chinese Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/3PP1P1/2N5/PPP1BP1P/R1BQK1NR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f1e2 f8g7 g2g4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2 Bg7 5. g4"),
  new FullOpening("B07", "Pirc Defense: Classical Variation", "rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N5/PPP1BPPP/R1BQK1NR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f1e2", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be2"),
  new FullOpening("B07", "Pirc Defense: Kholmov System", "rnbqkb1r/ppp1pp1p/3p1np1/8/2BPP3/2N5/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f1c4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Bc4"),
  new FullOpening("B07", "Pirc Defense: Sveshnikov System", "rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N3P1/PPP2P1P/R1BQKBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g2g3", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. g3"),
  new FullOpening("B08", "Pirc Defense: Classical Variation", "rnbqkb1r/ppp1pp1p/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g1f3", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3"),
  new FullOpening("B08", "Pirc Defense: Classical Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g1f3 f8g7", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7"),
  new FullOpening("B08", "Pirc Defense: Classical Variation, Quiet System", "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQK2R b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g1f3 f8g7 f1e2", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2"),
  new FullOpening("B08", "Pirc Defense: Classical Variation, Quiet System, Chigorin Line", "r1bq1rk1/ppp1ppbp/2np1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1 w - -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1 b8c6", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O Nc6"),
  new FullOpening("B08", "Pirc Defense: Classical Variation, Quiet System, Czech Defense", "rnbq1rk1/pp2ppbp/2pp1np1/8/3PP3/2N2N2/PPP1BPPP/R1BQ1RK1 w - -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1 c7c6", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O c6"),
  new FullOpening("B08", "Pirc Defense: Classical Variation, Quiet System, Parma Defense", "rn1q1rk1/ppp1ppbp/3p1np1/8/3PP1b1/2N2N2/PPP1BPPP/R1BQ1RK1 w - -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g1f3 f8g7 f1e2 e8g8 e1g1 c8g4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. Be2 O-O 6. O-O Bg4"),
  new FullOpening("B08", "Pirc Defense: Classical Variation, Schlechter Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/3PP3/2N2N1P/PPP2PP1/R1BQKB1R b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 g1f3 f8g7 h2h3", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Nf3 Bg7 5. h3"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack", "rnbqkb1r/ppp1pp1p/3p1np1/8/3PPP2/2N5/PPP3PP/R1BQKBNR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack", "rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N2N2/PPP3PP/R1BQKB1R w KQ -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4 f8g7 g1f3 e8g8", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack, Dragon Formation", "rnbqk2r/pp2ppbp/3p1np1/2p5/3PPP2/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4 f8g7 g1f3 c7c5", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 c5"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack, Kurajica Variation", "rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2N1BN2/PPP3PP/R2QKB1R b KQ -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4 f8g7 g1f3 e8g8 c1e3", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. Be3"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack, Ljubojevic Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/2BPPP2/2N5/PPP3PP/R1BQK1NR b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4 f8g7 f1c4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Bc4"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack, Unzicker Attack", "rnbq1rk1/ppp1ppbp/3p1np1/4P3/3P1P2/2N2N2/PPP3PP/R1BQKB1R b KQ -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4 f8g7 g1f3 e8g8 e4e5", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. e5"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack, Unzicker Attack, Bronstein Variation", "rnbq1rk1/pppnppbp/3p2p1/4P3/3P1P1P/2N2N2/PPP3P1/R1BQKB1R b KQ -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4 f8g7 g1f3 e8g8 e4e5 f6d7 h2h4", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. e5 Nfd7 7. h4"),
  new FullOpening("B09", "Pirc Defense: Austrian Attack, Weiss Variation", "rnbq1rk1/ppp1ppbp/3p1np1/8/3PPP2/2NB1N2/PPP3PP/R1BQK2R b KQ -", "e2e4 d7d6 d2d4 g8f6 b1c3 g7g6 f2f4 f8g7 g1f3 e8g8 f1d3", "1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. f4 Bg7 5. Nf3 O-O 6. Bd3"),
  new FullOpening("B10", "Caro-Kann Defense", "rnbqkbnr/pp1ppppp/2p5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 c7c6", "1. e4 c6"),
  new FullOpening("B10", "Caro-Kann Defense", "rnbqkbnr/pp1ppppp/2p5/8/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 c7c6 b1c3", "1. e4 c6 2. Nc3"),
  new FullOpening("B10", "Caro-Kann Defense", "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 b1c3 d7d5", "1. e4 c6 2. Nc3 d5"),
  new FullOpening("B10", "Caro-Kann Defense: Accelerated Panov Attack", "rnbqkbnr/pp1ppppp/2p5/8/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 c2c4", "1. e4 c6 2. c4"),
  new FullOpening("B10", "Caro-Kann Defense: Accelerated Panov Attack", "rnbqkbnr/pp2pppp/2p5/3p4/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 c2c4 d7d5", "1. e4 c6 2. c4 d5"),
  new FullOpening("B10", "Caro-Kann Defense: Accelerated Panov Attack, Modern Variation", "rnbqkb1r/pp2pppp/5n2/3P4/8/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 c2c4 d7d5 e4d5 c6d5 c4d5 g8f6", "1. e4 c6 2. c4 d5 3. exd5 cxd5 4. cxd5 Nf6"),
  new FullOpening("B10", "Caro-Kann Defense: Accelerated Panov Attack, Open Variation", "rnbqkbnr/pp1p1ppp/2p5/4p3/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 c2c4 e7e5", "1. e4 c6 2. c4 e5"),
  new FullOpening("B10", "Caro-Kann Defense: Accelerated Panov Attack, Pseudo-Scandinavian", "rnb1kbnr/pp2pppp/2p5/3q4/2P5/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 c2c4 d7d5 e4d5 d8d5", "1. e4 c6 2. c4 d5 3. exd5 Qxd5"),
  new FullOpening("B10", "Caro-Kann Defense: Accelerated Panov Attack, Van Weersel Attack", "rnbqkbnr/pp2pppp/8/3p4/4P3/1Q6/PP1P1PPP/RNB1KBNR b KQkq -", "e2e4 c7c6 c2c4 d7d5 c4d5 c6d5 d1b3", "1. e4 c6 2. c4 d5 3. cxd5 cxd5 4. Qb3"),
  new FullOpening("B10", "Caro-Kann Defense: Apocalypse Attack", "rnbqkbnr/pp2pppp/8/3pN3/8/8/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c6 g1f3 d7d5 e4d5 c6d5 f3e5", "1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. Ne5"),
  new FullOpening("B10", "Caro-Kann Defense: Breyer Variation", "rnbqkbnr/pp1ppppp/2p5/8/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d3", "1. e4 c6 2. d3"),
  new FullOpening("B10", "Caro-Kann Defense: Breyer Variation, Stein Attack", "rnbq1rk1/pp2npbp/2p3p1/3pp3/1P2P3/3P1NP1/P1PN1PBP/R1BQ1RK1 b - -", "e2e4 c7c6 d2d3 d7d5 b1d2 g7g6 g1f3 f8g7 g2g3 e7e5 f1g2 g8e7 e1g1 e8g8 b2b4", "1. e4 c6 2. d3 d5 3. Nd2 g6 4. Ngf3 Bg7 5. g3 e5 6. Bg2 Ne7 7. O-O O-O 8. b4"),
  new FullOpening("B10", "Caro-Kann Defense: Euwe Attack", "rnbqkbnr/pp1ppppp/2p5/8/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 b2b3", "1. e4 c6 2. b3"),
  new FullOpening("B10", "Caro-Kann Defense: Goldman Variation", "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2Q2/PPPP1PPP/R1B1KBNR b KQkq -", "e2e4 c7c6 b1c3 d7d5 d1f3", "1. e4 c6 2. Nc3 d5 3. Qf3"),
  new FullOpening("B10", "Caro-Kann Defense: Hector Gambit", "rnbqkbnr/pp2pppp/2p5/6N1/4p3/2N5/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 c7c6 b1c3 d7d5 g1f3 d5e4 f3g5", "1. e4 c6 2. Nc3 d5 3. Nf3 dxe4 4. Ng5"),
  new FullOpening("B10", "Caro-Kann Defense: Hillbilly Attack", "rnbqkbnr/pp1ppppp/2p5/8/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 c7c6 f1c4", "1. e4 c6 2. Bc4"),
  new FullOpening("B10", "Caro-Kann Defense: Hillbilly Attack, Schaeffer Gambit", "rnbqkbnr/pp2pppp/2p5/7Q/4p3/1B6/PPPP1PPP/RNB1K1NR b KQkq -", "e2e4 c7c6 f1c4 d7d5 c4b3 d5e4 d1h5", "1. e4 c6 2. Bc4 d5 3. Bb3 dxe4 4. Qh5"),
  new FullOpening("B10", "Caro-Kann Defense: Labahn Attack", "rnbqkbnr/pp1ppppp/2p5/8/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 b2b4", "1. e4 c6 2. b4"),
  new FullOpening("B10", "Caro-Kann Defense: Labahn Attack, Double Gambit", "rnbqkbnr/pp2pppp/2p5/1P1p4/4P3/8/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 b2b4 d7d5 b4b5", "1. e4 c6 2. b4 d5 3. b5"),
  new FullOpening("B10", "Caro-Kann Defense: Labahn Attack, Polish Variation", "rnbqkbnr/pp1p1ppp/2p5/4p3/1P2P3/8/PBPP1PPP/RN1QKBNR b KQkq -", "e2e4 c7c6 b2b4 e7e5 c1b2", "1. e4 c6 2. b4 e5 3. Bb2"),
  new FullOpening("B10", "Caro-Kann Defense: Scorpion-Horus Gambit", "rnbqkbnr/pp2pppp/2p5/6B1/4p3/2NP4/PPP2PPP/R2QKBNR b KQkq -", "e2e4 c7c6 b1c3 d7d5 d2d3 d5e4 c1g5", "1. e4 c6 2. Nc3 d5 3. d3 dxe4 4. Bg5"),
  new FullOpening("B10", "Caro-Kann Defense: Spike Variation", "rnbqkbnr/pp1ppppp/2p5/8/4P1P1/8/PPPP1P1P/RNBQKBNR b KQkq -", "e2e4 c7c6 g2g4", "1. e4 c6 2. g4"),
  new FullOpening("B10", "Caro-Kann Defense: Spike Variation, Scorpion-Grob Gambit", "rnbqkbnr/pp2pppp/2p5/8/4p1P1/2NP4/PPP2P1P/R1BQKBNR b KQkq -", "e2e4 c7c6 g2g4 d7d5 b1c3 d5e4 d2d3", "1. e4 c6 2. g4 d5 3. Nc3 dxe4 4. d3"),
  new FullOpening("B10", "Caro-Kann Defense: Toikkanen Gambit", "rnbqkbnr/pp2pppp/2p5/3pP3/2P5/8/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 c2c4 d7d5 e4e5", "1. e4 c6 2. c4 d5 3. e5"),
  new FullOpening("B10", "Caro-Kann Defense: Two Knights Attack", "rnbqkbnr/pp2pppp/2p5/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 c7c6 b1c3 d7d5 g1f3", "1. e4 c6 2. Nc3 d5 3. Nf3"),
  new FullOpening("B11", "Caro-Kann Defense: Two Knights Attack, Mindeno Variation", "rn1qkbnr/pp2pppp/2p5/3p4/4P1b1/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -", "e2e4 c7c6 b1c3 d7d5 g1f3 c8g4", "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4"),
  new FullOpening("B11", "Caro-Kann Defense: Two Knights Attack, Mindeno Variation, Exchange Line", "rn1qkbnr/pp2pppp/2p5/3p4/4P3/2N2b1P/PPPP1PP1/R1BQKB1R w KQkq -", "e2e4 c7c6 b1c3 d7d5 g1f3 c8g4 h2h3 g4f3", "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4 4. h3 Bxf3"),
  new FullOpening("B11", "Caro-Kann Defense: Two Knights Attack, Mindeno Variation, Retreat Line", "rn1qkbnr/pp2pppp/2p5/3p3b/4P3/2N2N1P/PPPP1PP1/R1BQKB1R w KQkq -", "e2e4 c7c6 b1c3 d7d5 g1f3 c8g4 h2h3 g4h5", "1. e4 c6 2. Nc3 d5 3. Nf3 Bg4 4. h3 Bh5"),
  new FullOpening("B12", "Caro-Kann Defense", "rnbqkbnr/pp1ppppp/2p5/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4", "1. e4 c6 2. d4"),
  new FullOpening("B12", "Caro-Kann Defense", "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5", "1. e4 c6 2. d4 d5"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation", "rnbqkbnr/pp2pppp/2p5/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5", "1. e4 c6 2. d4 d5 3. e5"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Bayonet Attack", "rn1qkbnr/pp2pppp/2p5/3pPb2/3P2P1/8/PPP2P1P/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 g2g4", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. g4"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Botvinnik-Carls Defense", "rnbqkbnr/pp2pppp/8/2ppP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c6c5", "1. e4 c6 2. d4 d5 3. e5 c5"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Bronstein Variation", "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/8/PPP1NPPP/RNBQKB1R b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 g1e2", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Ne2"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Prins Attack", "rn1qkbnr/pp2pppp/2p5/3pPb2/1P1P4/8/P1P2PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 b2b4", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. b4"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Short Variation", "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 g1f3", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nf3"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Tal Variation", "rn1qkbnr/pp2pppp/2p5/3pPb2/3P3P/8/PPP2PP1/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 h2h4", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. h4"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Van der Wiel Attack", "rn1qkbnr/pp2pppp/2p5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 b1c3", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Van der Wiel Attack", "rn1qkbnr/pp3ppp/4p1b1/2ppP3/3P2PP/2N5/PPP1NP2/R1BQKB1R b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 b1c3 e7e6 g2g4 f5g6 g1e2 c6c5 h2h4", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3 e6 5. g4 Bg6 6. Nge2 c5 7. h4"),
  new FullOpening("B12", "Caro-Kann Defense: Advance Variation, Van der Wiel Attack, Dreyev Defense", "rn2kbnr/pp2pppp/1qp5/3pPb2/3P4/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 b1c3 d8b6", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. Nc3 Qb6"),
  new FullOpening("B12", "Caro-Kann Defense: Advance, Short Variation", "rn1qkbnr/pp3ppp/2p1p3/3pPb2/3P4/2P5/PP2BPPP/RNBQK1NR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4e5 c8f5 c2c3 e7e6 f1e2", "1. e4 c6 2. d4 d5 3. e5 Bf5 4. c3 e6 5. Be2"),
  new FullOpening("B12", "Caro-Kann Defense: De Bruycker Defense", "r1bqkbnr/pp1ppppp/n1p5/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 d2d4 b8a6", "1. e4 c6 2. d4 Na6"),
  new FullOpening("B12", "Caro-Kann Defense: De Bruycker Defense", "r1bqkbnr/ppnppppp/2p5/8/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 b8a6 b1c3 a6c7", "1. e4 c6 2. d4 Na6 3. Nc3 Nc7"),
  new FullOpening("B12", "Caro-Kann Defense: Edinburgh Variation", "rnb1kbnr/pp2pppp/1qp5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d8b6", "1. e4 c6 2. d4 d5 3. Nd2 Qb6"),
  new FullOpening("B12", "Caro-Kann Defense: Maróczy Variation", "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/5P2/PPP3PP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 f2f3", "1. e4 c6 2. d4 d5 3. f3"),
  new FullOpening("B12", "Caro-Kann Defense: Maróczy Variation, Maróczy Gambit", "rnbqkbnr/pp3ppp/2p5/8/2BpP3/5N2/PPP3PP/RNBQK2R b KQkq -", "e2e4 c7c6 d2d4 d7d5 f2f3 d5e4 f3e4 e7e5 g1f3 e5d4 f1c4", "1. e4 c6 2. d4 d5 3. f3 dxe4 4. fxe4 e5 5. Nf3 exd4 6. Bc4"),
  new FullOpening("B12", "Caro-Kann Defense: Masi Variation", "rnbqkb1r/pp1ppppp/2p2n2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 d2d4 g8f6", "1. e4 c6 2. d4 Nf6"),
  new FullOpening("B12", "Caro-Kann Defense: Massachusetts Defense", "rnbqkbnr/pp1pp1pp/2p5/5p2/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 c7c6 d2d4 f7f5", "1. e4 c6 2. d4 f5"),
  new FullOpening("B12", "Caro-Kann Defense: Mieses Attack, Landau Gambit", "rnbqkb1r/pp1npppp/2p1P3/3p4/3P4/3B4/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 c7c6 d2d4 d7d5 f1d3 g8f6 e4e5 f6d7 e5e6", "1. e4 c6 2. d4 d5 3. Bd3 Nf6 4. e5 Nfd7 5. e6"),
  new FullOpening("B12", "Caro-Kann Defense: Mieses Gambit", "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/4B3/PPP2PPP/RN1QKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 c1e3", "1. e4 c6 2. d4 d5 3. Be3"),
  new FullOpening("B12", "Caro-Kann Defense: Modern Variation", "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/8/PPPN1PPP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2", "1. e4 c6 2. d4 d5 3. Nd2"),
  new FullOpening("B12", "Caro-Kann Defense: Ulysses Gambit", "rnbqkbnr/pp2pppp/2p5/6N1/3Pp3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c6 d2d4 d7d5 g1f3 d5e4 f3g5", "1. e4 c6 2. d4 d5 3. Nf3 dxe4 4. Ng5"),
  new FullOpening("B13", "Caro-Kann Defense: Exchange Variation", "rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5", "1. e4 c6 2. d4 d5 3. exd5"),
  new FullOpening("B13", "Caro-Kann Defense: Exchange Variation, Bulla Attack", "rnbqkbnr/pp2pppp/8/3p4/3P2P1/8/PPP2P1P/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 g2g4", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. g4"),
  new FullOpening("B13", "Caro-Kann Defense: Exchange Variation, Rubinstein Variation", "r1bqkb1r/pp2pppp/2n2n2/3p4/3P1B2/2PB4/PP3PPP/RN1QK1NR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 f1d3 b8c6 c2c3 g8f6 c1f4", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. Bd3 Nc6 5. c3 Nf6 6. Bf4"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack", "rnbqkbnr/pp2pppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack", "rnbqkb1r/pp2pppp/5n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack, Gunderam Attack", "rnbqkb1r/pp2pppp/5n2/2Pp4/3P4/8/PP3PPP/RNBQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 c4c5", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. c5"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack, Modern Defense", "r1bqkb1r/pp2pppp/2n2n2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 b8c6", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack, Modern Defense, Carlsbad Line", "r1bqkb1r/pp3ppp/2n1pn2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 b8c6 c1g5 e7e6", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 e6"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack, Modern Defense, Czerniak Line", "r1b1kb1r/pp2pppp/2n2n2/q2p2B1/2PP4/2N5/PP3PPP/R2QKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 b8c6 c1g5 d8a5", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 Qa5"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack, Modern Defense, Mieses Line", "r2qkb1r/pp2pppp/2n2n2/3p4/2PP2b1/2N2N2/PP3PPP/R1BQKB1R w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 b8c6 g1f3 c8g4", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Nf3 Bg4"),
  new FullOpening("B13", "Caro-Kann Defense: Panov Attack, Modern Defense, Reifir-Spielmann Line", "r1b1kb1r/pp2pppp/1qn2n2/3p2B1/2PP4/2N5/PP3PPP/R2QKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 b8c6 c1g5 d8b6", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 Qb6"),
  new FullOpening("B13", "Caro-Kann Defense: Panov-Botvinnik, Herzog Defense", "r1bqkb1r/pp2pppp/5n2/n2P2B1/2p5/2N5/PP3PPP/R2QKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 b8c6 c1g5 d5c4 d4d5 c6a5", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 Nc6 6. Bg5 dxc4 7. d5 Na5"),
  new FullOpening("B14", "Caro-Kann Defense: Panov Attack", "rnbqkb1r/pp3ppp/4pn2/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 e7e6", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 e6"),
  new FullOpening("B14", "Caro-Kann Defense: Panov Attack, Fianchetto Defense", "rnbqkb1r/pp2pp1p/5np1/3p4/2PP4/2N5/PP3PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 g7g6", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 g6"),
  new FullOpening("B14", "Caro-Kann Defense: Panov Attack, Fianchetto Defense, Fianchetto Gambit", "rnbqk2r/pp2ppbp/5np1/3P4/3P4/2N5/PP3PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 g7g6 c4d5 f8g7", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 g6 6. cxd5 Bg7"),
  new FullOpening("B14", "Caro-Kann Defense: Panov Attack, Main Line", "rnbqk2r/pp3ppp/4pn2/3p4/1bPP4/2N2N2/PP3PPP/R1BQKB1R w KQkq -", "e2e4 c7c6 d2d4 d7d5 e4d5 c6d5 c2c4 g8f6 b1c3 e7e6 g1f3 f8b4", "1. e4 c6 2. d4 d5 3. exd5 cxd5 4. c4 Nf6 5. Nc3 e6 6. Nf3 Bb4"),
  new FullOpening("B15", "Caro-Kann Defense", "rnbqkbnr/pp2pppp/2p5/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3", "1. e4 c6 2. d4 d5 3. Nc3"),
  new FullOpening("B15", "Caro-Kann Defense: Alekhine Gambit", "rnbqkb1r/pp2pppp/2p2n2/8/3PN3/3B4/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 d5e4 c3e4 g8f6 f1d3", "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Bd3"),
  new FullOpening("B15", "Caro-Kann Defense: Campomanes Attack", "rnbqkb1r/pp2pppp/2p2n2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 g8f6", "1. e4 c6 2. d4 d5 3. Nc3 Nf6"),
  new FullOpening("B15", "Caro-Kann Defense: Forgacs Variation", "rnbqkb1r/pp3ppp/2p2p2/8/2BP4/8/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 d5e4 c3e4 g8f6 e4f6 e7f6 f1c4", "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ exf6 6. Bc4"),
  new FullOpening("B15", "Caro-Kann Defense: Gurgenidze Counterattack", "rnbqkbnr/p3pppp/2p5/1p1p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 b7b5", "1. e4 c6 2. d4 d5 3. Nc3 b5"),
  new FullOpening("B15", "Caro-Kann Defense: Gurgenidze System", "rnbqkbnr/pp2pp1p/2p3p1/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 g7g6", "1. e4 c6 2. d4 d5 3. Nc3 g6"),
  new FullOpening("B15", "Caro-Kann Defense: Main Line", "rnbqkbnr/pp2pppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4"),
  new FullOpening("B15", "Caro-Kann Defense: Rasa-Studier Gambit", "rnbqkbnr/pp2pppp/2p5/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 d5e4 f2f3", "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. f3"),
  new FullOpening("B15", "Caro-Kann Defense: Tartakower Variation", "rnbqkb1r/pp3ppp/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 d5e4 c3e4 g8f6 e4f6 e7f6", "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ exf6"),
  new FullOpening("B15", "Caro-Kann Defense: von Hennig Gambit", "rnbqkbnr/pp2pppp/2p5/8/2BPp3/2N5/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 d5e4 f1c4", "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Bc4"),
  new FullOpening("B16", "Caro-Kann Defense: Bronstein-Larsen Variation", "rnbqkb1r/pp2pp1p/2p2p2/8/3P4/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 d5e4 c3e4 g8f6 e4f6 g7f6", "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nf6 5. Nxf6+ gxf6"),
  new FullOpening("B16", "Caro-Kann Defense: Finnish Variation", "rnbqkbnr/pp2ppp1/2p4p/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 h7h6", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 h6"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation", "r1bqkbnr/pp1npppp/2p5/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation, Modern Main Line", "r1bqk2r/pp1n1pp1/2pbp2p/8/3PQ3/3B1N2/PPP2PPP/R1B1K2R b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7 e4g5 g8f6 f1d3 e7e6 g1f3 f8d6 d1e2 h7h6 g5e4 f6e4 e2e4", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Ng5 Ngf6 6. Bd3 e6 7. N1f3 Bd6 8. Qe2 h6 9. Ne4 Nxe4 10. Qxe4"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation, Modern Variation", "r1bqkbnr/pp1npppp/2p5/6N1/3P4/8/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7 e4g5", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Ng5"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation, Modern Variation, Ivanchuk Defense", "r1bqkbnr/pp2pppp/2p2n2/6N1/3P4/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7 e4g5 d7f6", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Ng5 Ndf6"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation, Modern Variation, Kasparov Attack", "r1bqkb1r/pp1npppp/2p2n2/8/3P4/5NN1/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1c3 d5e4 c3e4 b8d7 g1f3 g8f6 e4g3", "1. e4 c6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7 5. Nf3 Ngf6 6. Ng3"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation, Smyslov Variation", "r1bqkb1r/pp3ppp/1np1pn2/6N1/2BP4/8/PPP1QPPP/R1B1K1NR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7 f1c4 g8f6 e4g5 e7e6 d1e2 d7b6", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Bc4 Ngf6 6. Ng5 e6 7. Qe2 Nb6"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation, Smyslov Variation, Main Line", "r1bqkb1r/pp3ppp/1np1pn2/6N1/3P4/1B6/PPP1QPPP/R1B1K1NR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7 f1c4 g8f6 e4g5 e7e6 d1e2 d7b6 c4b3", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Bc4 Ngf6 6. Ng5 e6 7. Qe2 Nb6 8. Bb3"),
  new FullOpening("B17", "Caro-Kann Defense: Karpov Variation, Tiviakov-Fischer Attack", "r1bqkb1r/pp2pppp/2p2n2/8/2BP4/8/PPP2PPP/R1BQK1NR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7 f1c4 g8f6 e4f6 d7f6", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Bc4 Ngf6 6. Nxf6+ Nxf6"),
  new FullOpening("B18", "Caro-Kann Defense: Classical Variation", "rn1qkbnr/pp2pppp/2p5/5b2/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5"),
  new FullOpening("B18", "Caro-Kann Defense: Classical Variation, Flohr Variation", "rn1qkbnr/pp2pppp/2p3b1/8/3P4/6NN/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 g1h3", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. Nh3"),
  new FullOpening("B18", "Caro-Kann Defense: Classical Variation, Main Line", "rn1qkbnr/pp2pppp/2p3b1/8/3P3P/6N1/PPP2PP1/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 h2h4", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4"),
  new FullOpening("B18", "Caro-Kann Defense: Classical Variation, Maróczy Attack", "rn1qkbnr/pp2pppp/2p3b1/8/3P1P2/6N1/PPP3PP/R1BQKBNR b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 f2f4", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. f4"),
  new FullOpening("B19", "Caro-Kann Defense: Classical Variation", "r2qkbnr/pp1nppp1/2p3bp/8/3P3P/5NN1/PPP2PP1/R1BQKB1R w KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 h2h4 h7h6 g1f3 b8d7", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. Nf3 Nd7"),
  new FullOpening("B19", "Caro-Kann Defense: Classical Variation, Lobron System", "r2qk2r/pp1nbpp1/2p1pn1p/7P/3P4/3Q1NN1/PPPB1PP1/2KR3R w kq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 h2h4 h7h6 g1f3 b8d7 h4h5 g6h7 f1d3 h7d3 d1d3 e7e6 c1d2 g8f6 e1c1 f8e7", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. Nf3 Nd7 8. h5 Bh7 9. Bd3 Bxd3 10. Qxd3 e6 11. Bd2 Ngf6 12. O-O-O Be7"),
  new FullOpening("B19", "Caro-Kann Defense: Classical Variation, Seirawan Variation", "r2qk2r/pp1n1pp1/2pbpn1p/7P/3P4/3Q1NN1/PPPB1PP1/2KR3R w kq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 h2h4 h7h6 g1f3 b8d7 h4h5 g6h7 f1d3 h7d3 d1d3 g8f6 c1d2 e7e6 e1c1 f8d6", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. Nf3 Nd7 8. h5 Bh7 9. Bd3 Bxd3 10. Qxd3 Ngf6 11. Bd2 e6 12. O-O-O Bd6"),
  new FullOpening("B19", "Caro-Kann Defense: Classical Variation, Spassky Variation", "r2qkbnr/pp1nppp1/2p3bp/7P/3P4/5NN1/PPP2PP1/R1BQKB1R b KQkq -", "e2e4 c7c6 d2d4 d7d5 b1d2 d5e4 d2e4 c8f5 e4g3 f5g6 h2h4 h7h6 g1f3 b8d7 h4h5", "1. e4 c6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Bf5 5. Ng3 Bg6 6. h4 h6 7. Nf3 Nd7 8. h5"),
  new FullOpening("B20", "Caro-Kann Defense: Euwe Attack, Prins Gambit", "rnbqkbnr/pp2pppp/8/2pp4/4P3/1P6/PBPP1PPP/RN1QKBNR b KQkq -", "e2e4 c7c5 b2b3 d7d5 c1b2", "1. e4 c5 2. b3 d5 3. Bb2"),
  new FullOpening("B20", "Sicilian Defense", "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 c7c5", "1. e4 c5"),
  new FullOpening("B20", "Sicilian Defense: Amazon Attack", "rnbqkbnr/pp1ppppp/8/2p5/4P1Q1/8/PPPP1PPP/RNB1KBNR b KQkq -", "e2e4 c7c5 d1g4", "1. e4 c5 2. Qg4"),
  new FullOpening("B20", "Sicilian Defense: Big Clamp Formation", "r1bqkbnr/pp2pppp/2np4/2p5/4PP2/2PP4/PP4PP/RNBQKBNR b KQkq -", "e2e4 c7c5 d2d3 b8c6 c2c3 d7d6 f2f4", "1. e4 c5 2. d3 Nc6 3. c3 d6 4. f4"),
  new FullOpening("B20", "Sicilian Defense: Bowdler Attack", "rnbqkbnr/pp1ppppp/8/2p5/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 c7c5 f1c4", "1. e4 c5 2. Bc4"),
  new FullOpening("B20", "Sicilian Defense: Brick Variation", "rnbqkbnr/pp1ppppp/8/2p5/4P3/7N/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1h3", "1. e4 c5 2. Nh3"),
  new FullOpening("B20", "Sicilian Defense: Gloria Variation", "r1bqkbnr/pp2ppp1/2np4/2p4p/2P1P3/2N3P1/PP1P1P1P/R1BQKBNR w KQkq -", "e2e4 c7c5 c2c4 d7d6 b1c3 b8c6 g2g3 h7h5", "1. e4 c5 2. c4 d6 3. Nc3 Nc6 4. g3 h5"),
  new FullOpening("B20", "Sicilian Defense: Grob Variation", "rnbqkbnr/pp1ppppp/8/2p5/4P1P1/8/PPPP1P1P/RNBQKBNR b KQkq -", "e2e4 c7c5 g2g4", "1. e4 c5 2. g4"),
  new FullOpening("B20", "Sicilian Defense: Keres Variation", "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPPNPPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1e2", "1. e4 c5 2. Ne2"),
  new FullOpening("B20", "Sicilian Defense: King David's Opening", "rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPPKPPP/RNBQ1BNR b kq -", "e2e4 c7c5 e1e2", "1. e4 c5 2. Ke2"),
  new FullOpening("B20", "Sicilian Defense: Kronberger Variation", "rnbqkbnr/pp1ppppp/8/2p5/4P3/N7/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 c7c5 b1a3", "1. e4 c5 2. Na3"),
  new FullOpening("B20", "Sicilian Defense: Kronberger Variation, Nemeth Gambit", "r1bqkbnr/pp1ppppp/2n5/8/2BpP3/N7/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 c7c5 b1a3 b8c6 d2d4 c5d4 f1c4", "1. e4 c5 2. Na3 Nc6 3. d4 cxd4 4. Bc4"),
  new FullOpening("B20", "Sicilian Defense: Lasker-Dunne Attack", "rnbqkbnr/pp1ppppp/8/2p5/4P3/6P1/PPPP1P1P/RNBQKBNR b KQkq -", "e2e4 c7c5 g2g3", "1. e4 c5 2. g3"),
  new FullOpening("B20", "Sicilian Defense: Mengarini Variation", "rnbqkbnr/pp1ppppp/8/2p5/4P3/P7/1PPP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 a2a3", "1. e4 c5 2. a3"),
  new FullOpening("B20", "Sicilian Defense: Myers Attack", "rnbqkbnr/pp1ppppp/8/2p5/P3P3/8/1PPP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 a2a4", "1. e4 c5 2. a4"),
  new FullOpening("B20", "Sicilian Defense: Myers Attack", "rnbqkbnr/pp1ppppp/8/2p5/4P2P/8/PPPP1PP1/RNBQKBNR b KQkq -", "e2e4 c7c5 h2h4", "1. e4 c5 2. h4"),
  new FullOpening("B20", "Sicilian Defense: Snyder Variation", "rnbqkbnr/pp1ppppp/8/2p5/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 b2b3", "1. e4 c5 2. b3"),
  new FullOpening("B20", "Sicilian Defense: Snyder Variation, Queen Fianchetto Variation", "rnbqkbnr/p2ppppp/1p6/2p5/4P3/1P6/P1PP1PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 b2b3 b7b6", "1. e4 c5 2. b3 b6"),
  new FullOpening("B20", "Sicilian Defense: Staunton-Cochrane Variation", "rnbqkbnr/pp1ppppp/8/2p5/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 c2c4", "1. e4 c5 2. c4"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit", "rnbqkbnr/pp1ppppp/8/2p5/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 b2b4", "1. e4 c5 2. b4"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit, Abrahams Variation", "rnbqkbnr/pp1ppppp/8/8/1p2P3/8/PBPP1PPP/RN1QKBNR b KQkq -", "e2e4 c7c5 b2b4 c5b4 c1b2", "1. e4 c5 2. b4 cxb4 3. Bb2"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit, Carlsbad Variation", "rnbqkbnr/pp1ppppp/8/8/4P3/p7/2PP1PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 b2b4 c5b4 a2a3 b4a3", "1. e4 c5 2. b4 cxb4 3. a3 bxa3"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit, Marienbad Variation", "rnb1kbnr/pp2pppp/8/3q4/1p6/P7/1BPP1PPP/RN1QKBNR b KQkq -", "e2e4 c7c5 b2b4 c5b4 a2a3 d7d5 e4d5 d8d5 c1b2", "1. e4 c5 2. b4 cxb4 3. a3 d5 4. exd5 Qxd5 5. Bb2"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit, Marshall Variation", "rnbqkbnr/pp1ppppp/8/8/1p2P3/P7/2PP1PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 b2b4 c5b4 a2a3", "1. e4 c5 2. b4 cxb4 3. a3"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit, Nanu Gambit", "rnb1kbnr/pp3ppp/4q3/4p3/1pP5/P2B1N2/3P1PPP/RNBQK2R b KQkq -", "e2e4 c7c5 b2b4 c5b4 a2a3 d7d5 e4d5 d8d5 g1f3 e7e5 c2c4 d5e6 f1d3", "1. e4 c5 2. b4 cxb4 3. a3 d5 4. exd5 Qxd5 5. Nf3 e5 6. c4 Qe6 7. Bd3"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit, Romanian Defense", "r1b1kbnr/pp3ppp/2n1q3/4p3/1pP5/P4N2/1B1P1PPP/RN1QKB1R w KQkq -", "e2e4 c7c5 b2b4 c5b4 a2a3 d7d5 e4d5 d8d5 g1f3 e7e5 c1b2 b8c6 c2c4 d5e6", "1. e4 c5 2. b4 cxb4 3. a3 d5 4. exd5 Qxd5 5. Nf3 e5 6. Bb2 Nc6 7. c4 Qe6"),
  new FullOpening("B20", "Sicilian Defense: Wing Gambit, Santasiere Variation", "rnbqkbnr/pp1ppppp/8/8/1pP1P3/8/P2P1PPP/RNBQKBNR b KQkq c3", "e2e4 c7c5 b2b4 c5b4 c2c4", "1. e4 c5 2. b4 cxb4 3. c4"),
  new FullOpening("B21", "Bird Opening: Dutch Variation, Batavo Gambit", "rnbqkbnr/pp2pppp/8/2p5/4pP2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 c7c5 f2f4 d7d5 g1f3 d5e4", "1. e4 c5 2. f4 d5 3. Nf3 dxe4"),
  new FullOpening("B21", "Sicilian Defense: Coles Sicilian Gambit", "r1bqkb1r/pp1ppppp/2n2n2/8/2B1P3/8/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 c7c5 d2d4 c5d4 d1d4 b8c6 d4d1 g8f6 f1c4", "1. e4 c5 2. d4 cxd4 3. Qxd4 Nc6 4. Qd1 Nf6 5. Bc4"),
  new FullOpening("B21", "Sicilian Defense: Halasz Gambit", "rnbqkbnr/pp1ppppp/8/8/3pPP2/8/PPP3PP/RNBQKBNR b KQkq -", "e2e4 c7c5 d2d4 c5d4 f2f4", "1. e4 c5 2. d4 cxd4 3. f4"),
  new FullOpening("B21", "Sicilian Defense: McDonnell Attack", "rnbqkbnr/pp1ppppp/8/2p5/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -", "e2e4 c7c5 f2f4", "1. e4 c5 2. f4"),
  new FullOpening("B21", "Sicilian Defense: McDonnell Attack, Tal Gambit", "rnbqkb1r/pp2pppp/5n2/2pP4/5P2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 c7c5 f2f4 d7d5 e4d5 g8f6", "1. e4 c5 2. f4 d5 3. exd5 Nf6"),
  new FullOpening("B21", "Sicilian Defense: Morphy Gambit", "rnbqkbnr/pp1ppppp/8/8/3pP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 d2d4 c5d4 g1f3", "1. e4 c5 2. d4 cxd4 3. Nf3"),
  new FullOpening("B21", "Sicilian Defense: Morphy Gambit, Andreaschek Gambit", "rnbqkbnr/pp1p1ppp/8/4p3/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 d2d4 c5d4 g1f3 e7e5 c2c3", "1. e4 c5 2. d4 cxd4 3. Nf3 e5 4. c3"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit", "rnbqkbnr/pp1ppppp/8/2p5/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 d2d4", "1. e4 c5 2. d4"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit", "rnbqkbnr/pp1ppppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3", "1. e4 c5 2. d4 cxd4 3. c3"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Chicago Defense", "rnbqkb1r/1p3ppp/p2ppn2/8/2B1P3/2N2N2/PP3PPP/R1BQ1RK1 w kq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 d7d6 g1f3 e7e6 f1c4 g8f6 e1g1 a7a6", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 d6 5. Nf3 e6 6. Bc4 Nf6 7. O-O a6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Classical Formation", "r1bqkb1r/1p2pppp/p1np1n2/8/2B1P3/2N2N2/PP3PPP/R1BQ1RK1 w kq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 d7d6 f1c4 a7a6 e1g1 g8f6", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 d6 6. Bc4 a6 7. O-O Nf6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Fianchetto Defense", "r1bqkbnr/pp1ppp1p/2n3p1/8/4P3/2N2N2/PP3PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 g7g6", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 g6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Kan Formation", "rnbqkbnr/1p1p1ppp/p3p3/8/4P3/2N2N2/PP3PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 e7e6 g1f3 a7a6", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e6 5. Nf3 a6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Larsen Defense", "r1b1k1nr/1pqp1ppp/p1nbp3/8/2B1P3/2N2N2/PP2QPPP/R1B2RK1 w kq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 e7e6 f1c4 d8c7 d1e2 a7a6 e1g1 f8d6", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Qc7 7. Qe2 a6 8. O-O Bd6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Morphy Defense", "r1bqk1nr/pp1p1ppp/2n1p3/2b5/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 e7e6 f1c4 f8c5", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Bc5"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Morphy Defense Deferred", "r1bqk1nr/3p1ppp/p1n1p3/1pb5/4P3/1BN2N2/PP3PPP/R1BQ1RK1 w kq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 e7e6 f1c4 a7a6 e1g1 b7b5 c4b3 f8c5", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 a6 7. O-O b5 8. Bb3 Bc5"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Paulsen Formation", "r1bqkbnr/1p1p1ppp/p1n1p3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 e7e6 f1c4 a7a6", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 a6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Pin Defense", "r1bqk1nr/pp1p1ppp/2n1p3/8/1bB1P3/2N2N2/PP3PPP/R1BQK2R w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 e7e6 f1c4 f8b4", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 e6 6. Bc4 Bb4"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Scheveningen Formation", "r1bqkbnr/pp3ppp/2npp3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 g1f3 d7d6 f1c4 e7e6", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Nf3 d6 6. Bc4 e6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Sozin Formation", "r1bqkbnr/5ppp/p1npp3/1p6/2B1P3/2N2N2/PP2QPPP/R1B2RK1 w kq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 f1c4 e7e6 g1f3 d7d6 e1g1 a7a6 d1e2 b7b5", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Bc4 e6 6. Nf3 d6 7. O-O a6 8. Qe2 b5"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Accepted, Taimanov Formation", "rnbqkb1r/1p1pnppp/p3p3/8/2B1P3/2N2N2/PP3PPP/R1BQK2R w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 e7e6 f1c4 a7a6 g1f3 g8e7", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 e6 5. Bc4 a6 6. Nf3 Ne7"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Declined, Alapin Formation", "rnbqkb1r/pp1ppppp/5n2/8/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 g8f6", "1. e4 c5 2. d4 cxd4 3. c3 Nf6"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Declined, Center Formation", "rnbqkbnr/pp1p1ppp/8/4p3/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 e7e5", "1. e4 c5 2. d4 cxd4 3. c3 e5"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Declined, Dubois Variation", "rnbqkbnr/pp1ppppp/8/8/2P1P3/3p4/PP3PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4d3 c3c4", "1. e4 c5 2. d4 cxd4 3. c3 d3 4. c4"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Declined, Push Variation", "rnbqkbnr/pp1ppppp/8/8/4P3/2Pp4/PP3PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4d3", "1. e4 c5 2. d4 cxd4 3. c3 d3"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Declined, Scandinavian Formation", "rnbqkbnr/pp2pppp/8/3p4/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d7d5", "1. e4 c5 2. d4 cxd4 3. c3 d5"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit Declined, Wing Formation", "rnb1kbnr/pp1ppppp/8/q7/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d8a5", "1. e4 c5 2. d4 cxd4 3. c3 Qa5"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit, Chicago Defense", "2bqkbnr/r4ppp/p1npp3/1p6/4P3/1BN2N2/PP2QPPP/R1B2RK1 w k -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 b1c3 b8c6 f1c4 e7e6 g1f3 d7d6 e1g1 a7a6 d1e2 b7b5 c4b3 a8a7", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nxc3 Nc6 5. Bc4 e6 6. Nf3 d6 7. O-O a6 8. Qe2 b5 9. Bb3 Ra7"),
  new FullOpening("B21", "Sicilian Defense: Smith-Morra Gambit, Danish Variation", "rnbqkbnr/pp1ppppp/8/8/4P3/2p2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 d2d4 c5d4 c2c3 d4c3 g1f3", "1. e4 c5 2. d4 cxd4 3. c3 dxc3 4. Nf3"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation", "rnbqkbnr/pp1ppppp/8/2p5/4P3/2P5/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 c7c5 c2c3", "1. e4 c5 2. c3"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Barmen Defense", "rnb1kbnr/pp2pppp/8/2pq4/8/2P5/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 c2c3 d7d5 e4d5 d8d5", "1. e4 c5 2. c3 d5 3. exd5 Qxd5"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Barmen Defense, Central Exchange", "r3kbnr/pp2pppp/2n5/3q4/3P2b1/5N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 c2c3 d7d5 e4d5 d8d5 d2d4 c5d4 c3d4 b8c6 g1f3 c8g4", "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 cxd4 5. cxd4 Nc6 6. Nf3 Bg4"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Barmen Defense, Endgame Variation", "r3kbnr/pp2pppp/8/8/3n4/2N2P2/PP3P1P/R1B1KB1R w KQkq -", "e2e4 c7c5 c2c3 d7d5 e4d5 d8d5 d2d4 c5d4 c3d4 b8c6 g1f3 c8g4 b1c3 g4f3 g2f3 d5d4 d1d4 c6d4", "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 cxd4 5. cxd4 Nc6 6. Nf3 Bg4 7. Nc3 Bxf3 8. gxf3 Qxd4 9. Qxd4 Nxd4"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Barmen Defense, Milner-Barry Attack", "r1b1k1nr/pp3ppp/2n5/3qp3/1b1P4/2N2N2/PP2BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 c2c3 d7d5 e4d5 d8d5 d2d4 b8c6 g1f3 c5d4 c3d4 e7e5 b1c3 f8b4 f1e2", "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 Nc6 5. Nf3 cxd4 6. cxd4 e5 7. Nc3 Bb4 8. Be2"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Barmen Defense, Modern Line", "rn2kb1r/pp2pppp/5n2/2pq4/3P2b1/2P2N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 c2c3 d7d5 e4d5 d8d5 d2d4 g8f6 g1f3 c8g4", "1. e4 c5 2. c3 d5 3. exd5 Qxd5 4. d4 Nf6 5. Nf3 Bg4"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Smith-Morra Declined", "rnbqkb1r/pp1ppppp/8/3nP3/3p4/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 c7c5 c2c3 g8f6 e4e5 f6d5 d2d4 c5d4", "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. d4 cxd4"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Stoltz Attack", "r1bqkb1r/pp1ppppp/1nn5/2p1P3/8/1BP2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 c7c5 c2c3 g8f6 e4e5 f6d5 g1f3 b8c6 f1c4 d5b6 c4b3", "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. Nf3 Nc6 5. Bc4 Nb6 6. Bb3"),
  new FullOpening("B22", "Sicilian Defense: Alapin Variation, Stoltz Attack, Ivanchuk Line", "r1b1kb1r/ppqppp1p/1nn5/4P1p1/2p5/2P2N2/PPBPQPPP/RNB1K2R w KQkq -", "e2e4 c7c5 c2c3 g8f6 e4e5 f6d5 g1f3 b8c6 f1c4 d5b6 c4b3 c5c4 b3c2 d8c7 d1e2 g7g5", "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. Nf3 Nc6 5. Bc4 Nb6 6. Bb3 c4 7. Bc2 Qc7 8. Qe2 g5"),
  new FullOpening("B22", "Sicilian Defense: Heidenfeld Variation", "r1bqkb1r/pp1ppppp/2n5/2pnP3/8/N1P2N2/PP1P1PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 c2c3 g8f6 e4e5 f6d5 g1f3 b8c6 b1a3", "1. e4 c5 2. c3 Nf6 3. e5 Nd5 4. Nf3 Nc6 5. Na3"),
  new FullOpening("B23", "Sicilian Defense: Closed", "rnbqkbnr/pp1ppppp/8/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 c7c5 b1c3", "1. e4 c5 2. Nc3"),
  new FullOpening("B23", "Sicilian Defense: Closed", "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 c7c5 b1c3 e7e6", "1. e4 c5 2. Nc3 e6"),
  new FullOpening("B23", "Sicilian Defense: Closed", "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq -", "e2e4 c7c5 b1c3 e7e6 g2g3", "1. e4 c5 2. Nc3 e6 3. g3"),
  new FullOpening("B23", "Sicilian Defense: Closed, Chameleon Variation", "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPPNPPP/R1BQKB1R b KQkq -", "e2e4 c7c5 b1c3 b8c6 g1e2", "1. e4 c5 2. Nc3 Nc6 3. Nge2"),
  new FullOpening("B23", "Sicilian Defense: Closed, Grob Attack", "r1bqkbnr/pp1ppppp/2n5/2p5/4P1P1/2N5/PPPP1P1P/R1BQKBNR b KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g4", "1. e4 c5 2. Nc3 Nc6 3. g4"),
  new FullOpening("B23", "Sicilian Defense: Closed, Korchnoi Defense", "rnbqkbnr/pp3ppp/4p3/2pp4/4P3/2N3P1/PPPP1P1P/R1BQKBNR w KQkq -", "e2e4 c7c5 b1c3 e7e6 g2g3 d7d5", "1. e4 c5 2. Nc3 e6 3. g3 d5"),
  new FullOpening("B23", "Sicilian Defense: Closed, Traditional", "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 c7c5 b1c3 b8c6", "1. e4 c5 2. Nc3 Nc6"),
  new FullOpening("B23", "Sicilian Defense: Grand Prix Attack", "r1bqkbnr/pp1ppppp/2n5/2p5/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 c7c5 b1c3 b8c6 f2f4", "1. e4 c5 2. Nc3 Nc6 3. f4"),
  new FullOpening("B23", "Sicilian Defense: Grand Prix Attack, Schofman Variation", "r1bqk1nr/pp1p1pbp/2n1p1p1/2p2P2/2B1P3/2N2N2/PPPP2PP/R1BQK2R b KQkq -", "e2e4 c7c5 b1c3 b8c6 f2f4 g7g6 g1f3 f8g7 f1c4 e7e6 f4f5", "1. e4 c5 2. Nc3 Nc6 3. f4 g6 4. Nf3 Bg7 5. Bc4 e6 6. f5"),
  new FullOpening("B24", "Sicilian Defense: Closed", "r1bqkbnr/pp1ppp1p/2n3p1/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR w KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6", "1. e4 c5 2. Nc3 Nc6 3. g3 g6"),
  new FullOpening("B24", "Sicilian Defense: Closed", "r1bqk1nr/pp1pppbp/2n3p1/2p5/4P3/2N3P1/PPPP1PBP/R1BQK1NR w KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7"),
  new FullOpening("B24", "Sicilian Defense: Closed, Fianchetto Variation", "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3", "1. e4 c5 2. Nc3 Nc6 3. g3"),
  new FullOpening("B24", "Sicilian Defense: Closed, Smyslov Variation", "r1bqk1nr/pp1p1pbp/4p1p1/2p5/3nP3/3PB1P1/PPP1NPBP/R2QK1NR b KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 e7e6 c1e3 c6d4 c3e2", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 e6 6. Be3 Nd4 7. Nce2"),
  new FullOpening("B25", "Sicilian Defense: Closed", "r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NP2P1/PPP2PBP/R1BQK1NR w KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6"),
  new FullOpening("B25", "Sicilian Defense: Closed", "r1bqk1nr/pp2ppbp/2np2p1/2p5/4PP2/2NP2P1/PPP3BP/R1BQK1NR b KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6 f2f4", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. f4"),
  new FullOpening("B25", "Sicilian Defense: Closed, Botvinnik Defense", "r1bqk1nr/pp3pbp/2np2p1/2p1p3/4PP2/2NP2P1/PPP3BP/R1BQK1NR w KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6 f2f4 e7e5", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. f4 e5"),
  new FullOpening("B25", "Sicilian Defense: Closed, Botvinnik Defense", "r1bqk1nr/pp3pbp/2np2p1/2p1p3/4P3/2NP2P1/PPP1NPBP/R1BQK2R w KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6 g1e2 e7e5", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. Nge2 e5"),
  new FullOpening("B25", "Sicilian Defense: Closed, Botvinnik Defense, Edge Variation", "r1bqk2r/pp2npbp/2np2p1/2p1p3/4PP2/2NP2PN/PPP3BP/R1BQK2R w KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6 f2f4 e7e5 g1h3 g8e7", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. f4 e5 7. Nh3 Nge7"),
  new FullOpening("B26", "Sicilian Defense: Closed", "r1bqk1nr/pp2ppbp/2np2p1/2p5/4P3/2NPB1P1/PPP2PBP/R2QK1NR b KQkq -", "e2e4 c7c5 b1c3 b8c6 g2g3 g7g6 f1g2 f8g7 d2d3 d7d6 c1e3", "1. e4 c5 2. Nc3 Nc6 3. g3 g6 4. Bg2 Bg7 5. d3 d6 6. Be3"),
  new FullOpening("B27", "Modern Defense: Pterodactyl Variation", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 b1c3 d8a5", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5"),
  new FullOpening("B27", "Pterodactyl Defense: Sicilian, Anhanguera", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2N1BN2/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 b1c3 d8a5 c1e3", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. Be3"),
  new FullOpening("B27", "Pterodactyl Defense: Sicilian, Benoni Gambit", "rnb1k1nr/pp1pppbp/6p1/q1pP4/4P3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 b1c3 d8a5 d4d5", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. d5"),
  new FullOpening("B27", "Pterodactyl Defense: Sicilian, Pteranodon", "rnb1k1nr/pp1ppp1p/6p1/q1P5/4P3/2P2N2/P1P2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 d4c5 d8a5 b1c3 g7c3 b2c3", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3 Bxc3+ 6. bxc3"),
  new FullOpening("B27", "Pterodactyl Defense: Sicilian, Rhamphorhynchus", "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 d4c5 d8a5 b1c3", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3"),
  new FullOpening("B27", "Pterodactyl Defense: Sicilian, Unpin", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2N2N2/PPPB1PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 b1c3 d8a5 c1d2", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. Nc3 Qa5 5. Bd2"),
  new FullOpening("B27", "Pterodactyl Defense: Western, Pterodactyl", "rnb1k1nr/pp1pppbp/6p1/q1p5/3PP3/2P2N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6 c2c3 f8g7 d2d4 d8a5", "1. e4 c5 2. Nf3 g6 3. c3 Bg7 4. d4 Qa5"),
  new FullOpening("B27", "Pterodactyl Defense: Western, Rhamphorhynchus", "rnb1k1nr/pp1pppbp/6p1/q1P5/4P3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 d4c5 d8a5", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+"),
  new FullOpening("B27", "Sicilian Defense", "rnbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3", "1. e4 c5 2. Nf3"),
  new FullOpening("B27", "Sicilian Defense: Acton Extension", "rnbqk1nr/pp1ppp1p/6pb/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6 c2c4 f8h6", "1. e4 c5 2. Nf3 g6 3. c4 Bh6"),
  new FullOpening("B27", "Sicilian Defense: Brussels Gambit", "rnbqkbnr/pp1pp1pp/8/2p2p2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 f7f5", "1. e4 c5 2. Nf3 f5"),
  new FullOpening("B27", "Sicilian Defense: Bücker Variation", "rnbqkbnr/pp1pppp1/7p/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 h7h6", "1. e4 c5 2. Nf3 h6"),
  new FullOpening("B27", "Sicilian Defense: Double-Dutch Gambit", "rnbqkb1r/pp1pp1pp/7n/2p2P2/8/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 f7f5 e4f5 g8h6", "1. e4 c5 2. Nf3 f5 3. exf5 Nh6"),
  new FullOpening("B27", "Sicilian Defense: Frederico Variation", "rnbqkbnr/pp1pp2p/6p1/2p2p2/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f7f5", "1. e4 c5 2. Nf3 g6 3. d4 f5"),
  new FullOpening("B27", "Sicilian Defense: Hyperaccelerated Dragon", "rnbqkbnr/pp1ppp1p/6p1/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6", "1. e4 c5 2. Nf3 g6"),
  new FullOpening("B27", "Sicilian Defense: Hyperaccelerated Fianchetto", "rnbqkbnr/pp1ppp1p/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4", "1. e4 c5 2. Nf3 g6 3. d4"),
  new FullOpening("B27", "Sicilian Defense: Hyperaccelerated Pterodactyl", "rnbqk1nr/pp1pppbp/6p1/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7", "1. e4 c5 2. Nf3 g6 3. d4 Bg7"),
  new FullOpening("B27", "Sicilian Defense: Hyperaccelerated Pterodactyl, Exchange Variation", "rnb1k1nr/pp1ppp1p/6p1/2P5/4P3/2q2N2/P1P2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 g7g6 d2d4 f8g7 d4c5 d8a5 b1c3 g7c3 b2c3 a5c3", "1. e4 c5 2. Nf3 g6 3. d4 Bg7 4. dxc5 Qa5+ 5. Nc3 Bxc3+ 6. bxc3 Qxc3+"),
  new FullOpening("B27", "Sicilian Defense: Jalalabad Variation", "rnbqkbnr/pp1p1ppp/8/2p1p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e5", "1. e4 c5 2. Nf3 e5"),
  new FullOpening("B27", "Sicilian Defense: Katalimov Variation", "rnbqkbnr/p2ppppp/1p6/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b7b6", "1. e4 c5 2. Nf3 b6"),
  new FullOpening("B27", "Sicilian Defense: Mongoose Variation", "rnb1kbnr/pp1ppppp/8/q1p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d8a5", "1. e4 c5 2. Nf3 Qa5"),
  new FullOpening("B27", "Sicilian Defense: Polish Gambit", "rnbqkbnr/p2ppppp/8/1pp5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b7b5", "1. e4 c5 2. Nf3 b5"),
  new FullOpening("B27", "Sicilian Defense: Quinteros Variation", "rnb1kbnr/ppqppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d8c7", "1. e4 c5 2. Nf3 Qc7"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation", "rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6", "1. e4 c5 2. Nf3 a6"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Aronin System", "rnbqkbnr/1p1ppppp/p7/2p5/4P3/5N2/PPPPBPPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 a7a6 f1e2", "1. e4 c5 2. Nf3 a6 3. Be2"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Kieseritzky System", "rnbqkbnr/1p1ppppp/p7/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 b2b3", "1. e4 c5 2. Nf3 a6 3. b3"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Maróczy Bind", "rnbqkbnr/1p1ppppp/p7/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c4", "1. e4 c5 2. Nf3 a6 3. c4"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Paulsen Line", "rnbqkbnr/1p1p1ppp/p3p3/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c4 e7e6", "1. e4 c5 2. Nf3 a6 3. c4 e6"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Robatsch Line", "rnbqkbnr/1p2pppp/p2p4/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c4 d7d6", "1. e4 c5 2. Nf3 a6 3. c4 d6"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Normal System", "rnbqkbnr/1p1ppppp/p7/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 d2d4", "1. e4 c5 2. Nf3 a6 3. d4"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Normal System, Cortlever Gambit", "rnbqkbnr/1p1ppppp/p7/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 a7a6 d2d4 c5d4 f1c4", "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Bc4"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Normal System, Smith-Morra Line", "rnbqkbnr/1p1ppppp/p7/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 d2d4 c5d4 c2c3", "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. c3"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Normal System, Taimanov Line", "rnbqkbnr/1p1p1ppp/p7/4p3/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6 d2d4 c5d4 f3d4 e7e5", "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Nxd4 e5"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Normal System, Zagorovsky Line", "rnbqkbnr/1p1ppppp/p7/8/3QP3/5N2/PPP2PPP/RNB1KB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 d2d4 c5d4 d1d4", "1. e4 c5 2. Nf3 a6 3. d4 cxd4 4. Qxd4"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Quiet System", "rnbqkbnr/1p1ppppp/p7/2p5/4P3/3P1N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 d2d3", "1. e4 c5 2. Nf3 a6 3. d3"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Réti System", "rnbqkbnr/1p1ppppp/p7/2p5/4P3/5NP1/PPPP1P1P/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 g2g3", "1. e4 c5 2. Nf3 a6 3. g3"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Venice System", "rnbqkbnr/1p1ppppp/p7/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c3", "1. e4 c5 2. Nf3 a6 3. c3"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Venice System, Barcza Line", "rnbqkb1r/1p1ppppp/p4n2/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c3 g8f6", "1. e4 c5 2. Nf3 a6 3. c3 Nf6"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Venice System, Gambit Line", "rnbqkb1r/1p2pppp/p4n2/2pP4/8/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c3 d7d5 e4d5 g8f6", "1. e4 c5 2. Nf3 a6 3. c3 d5 4. exd5 Nf6"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Venice System, Ljubojevic Line", "rnbqkbnr/3ppppp/p7/1pp5/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c3 b7b5", "1. e4 c5 2. Nf3 a6 3. c3 b5"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Venice System, Steiner Line", "rnbqkbnr/1p2pppp/p2p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 a7a6 c2c3 d7d6", "1. e4 c5 2. Nf3 a6 3. c3 d6"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Wing Gambit", "rnbqkbnr/1p1ppppp/p7/2p5/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 b2b4", "1. e4 c5 2. Nf3 a6 3. b4"),
  new FullOpening("B28", "Sicilian Defense: O'Kelly Variation, Yerevan System", "rnbqkbnr/1p1ppppp/p7/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 a7a6 b1c3", "1. e4 c5 2. Nf3 a6 3. Nc3"),
  new FullOpening("B29", "Sicilian Defense: Nimzowitsch Variation", "rnbqkb1r/pp1ppppp/5n2/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 g8f6", "1. e4 c5 2. Nf3 Nf6"),
  new FullOpening("B29", "Sicilian Defense: Nimzowitsch Variation, Advance Variation", "rnbqkb1r/pp1ppppp/5n2/2p1P3/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 g8f6 e4e5", "1. e4 c5 2. Nf3 Nf6 3. e5"),
  new FullOpening("B29", "Sicilian Defense: Nimzowitsch Variation, Closed Variation", "rnbqkb1r/pp1ppppp/5n2/2p5/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 g8f6 b1c3", "1. e4 c5 2. Nf3 Nf6 3. Nc3"),
  new FullOpening("B29", "Sicilian Defense: Nimzowitsch Variation, Exchange Variation", "rnbqkb1r/pp1ppppp/8/2p1P3/8/2n2N2/PPPP1PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 g8f6 e4e5 f6d5 b1c3 d5c3", "1. e4 c5 2. Nf3 Nf6 3. e5 Nd5 4. Nc3 Nxc3"),
  new FullOpening("B29", "Sicilian Defense: Nimzowitsch Variation, Main Line", "r1bqkb1r/pp1p1ppp/2n5/2ppP3/3P4/5N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 g8f6 e4e5 f6d5 b1c3 e7e6 c3d5 e6d5 d2d4 b8c6", "1. e4 c5 2. Nf3 Nf6 3. e5 Nd5 4. Nc3 e6 5. Nxd5 exd5 6. d4 Nc6"),
  new FullOpening("B30", "Sicilian Defense: Closed, Anti-Sveshnikov Variation, Kharlov-Kramnik Line", "r1bqk1nr/pp3ppp/2np4/2p1p1b1/2B1P3/2NP4/PPPN1PPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 b8c6 b1c3 e7e5 f1c4 f8e7 d2d3 d7d6 f3d2 e7g5", "1. e4 c5 2. Nf3 Nc6 3. Nc3 e5 4. Bc4 Be7 5. d3 d6 6. Nd2 Bg5"),
  new FullOpening("B30", "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack", "r1bqkbnr/pp1ppppp/2n5/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 f1b5", "1. e4 c5 2. Nf3 Nc6 3. Bb5"),
  new FullOpening("B30", "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, San Francisco Gambit", "r1bqkbnr/pp1ppppp/8/nBp5/1P2P3/5N2/P1PP1PPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 f1b5 c6a5 b2b4", "1. e4 c5 2. Nf3 Nc6 3. Bb5 Na5 4. b4"),
  new FullOpening("B30", "Sicilian Defense: Old Sicilian", "r1bqkbnr/pp1ppppp/2n5/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6", "1. e4 c5 2. Nf3 Nc6"),
  new FullOpening("B30", "Sicilian Defense: Rossolimo Variation, Brooklyn Retreat Defense", "rnbqkbnr/pp1ppppp/8/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 c7c5 g1f3 b8c6 f1b5 c6b8", "1. e4 c5 2. Nf3 Nc6 3. Bb5 Nb8"),
  new FullOpening("B31", "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation", "r1bqkbnr/pp1ppp1p/2n3p1/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 c7c5 g1f3 b8c6 f1b5 g7g6", "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6"),
  new FullOpening("B31", "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Gufeld Gambit", "r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/3PP3/2P2N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 c7c5 g1f3 b8c6 f1b5 g7g6 e1g1 f8g7 c2c3 e7e5 d2d4", "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 e5 6. d4"),
  new FullOpening("B31", "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Lutikov Gambit", "r1bqk2r/pp1pppbp/2n2np1/1Bp5/3PP3/2P2N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 c7c5 g1f3 b8c6 f1b5 g7g6 e1g1 f8g7 c2c3 g8f6 d2d4", "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 Nf6 6. d4"),
  new FullOpening("B31", "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Fianchetto Variation, Totsky Attack", "r1bqk2r/pp1pppbp/2n2np1/1Bp5/Q3P3/2P2N2/PP1P1PPP/RNB2RK1 b kq -", "e2e4 c7c5 g1f3 b8c6 f1b5 g7g6 e1g1 f8g7 c2c3 g8f6 d1a4", "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. c3 Nf6 6. Qa4"),
  new FullOpening("B31", "Sicilian Defense: Nyezhmetdinov-Rossolimo Attack, Gurgenidze Variation", "r1bqk1nr/pp1p1pbp/2n3p1/1Bp1p3/1P2P3/5N2/P1PP1PPP/RNBQR1K1 b kq -", "e2e4 c7c5 g1f3 b8c6 f1b5 g7g6 e1g1 f8g7 f1e1 e7e5 b2b4", "1. e4 c5 2. Nf3 Nc6 3. Bb5 g6 4. O-O Bg7 5. Re1 e5 6. b4"),
  new FullOpening("B32", "Sicilian Defense: Accelerated Dragon", "r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6"),
  new FullOpening("B32", "Sicilian Defense: Flohr Variation", "r1b1kbnr/ppqppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 d8c7", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qc7"),
  new FullOpening("B32", "Sicilian Defense: Franco-Sicilian Variation", "r1bqkbnr/pp1p1ppp/2n1p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 e7e6", "1. e4 c5 2. Nf3 Nc6 3. d4 e6"),
  new FullOpening("B32", "Sicilian Defense: Godiva Variation", "r1b1kbnr/pp1ppppp/1qn5/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 d8b6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Qb6"),
  new FullOpening("B32", "Sicilian Defense: Kalashnikov Variation", "r1bqkbnr/pp3ppp/2np4/1N2p3/4P3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 e7e5 d4b5 d7d6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5 5. Nb5 d6"),
  new FullOpening("B32", "Sicilian Defense: Löwenthal Variation", "r1bqkbnr/pp1p1ppp/2n5/4p3/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 e7e5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 e5"),
  new FullOpening("B32", "Sicilian Defense: Nimzo-American Variation", "r1bqkbnr/pp2pppp/2n5/3p4/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 d7d5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 d5"),
  new FullOpening("B32", "Sicilian Defense: O'Kelly Variation, Maróczy Bind, Geller Line", "r1bqkbnr/1p1p1ppp/p1n5/4p3/2PNP3/8/PP3PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 a7a6 c2c4 e7e5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 a6 5. c4 e5"),
  new FullOpening("B32", "Sicilian Defense: Open", "r1bqkbnr/pp1ppppp/2n5/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4", "1. e4 c5 2. Nf3 Nc6 3. d4"),
  new FullOpening("B32", "Sicilian Defense: Open", "r1bqkbnr/pp1ppppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4"),
  new FullOpening("B32", "Sicilian Defense: Open", "r1bqkbnr/pp1ppppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4"),
  new FullOpening("B33", "Sicilian Defense: Four Knights Variation, Cobra Variation", "r1bqk2r/pp1p1ppp/2n1pn2/1Nb5/4P3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e6 d4b5 f8c5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e6 6. Ndb5 Bc5"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation", "r1bqkb1r/pp1p1ppp/2n2n2/4p3/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Bird Variation", "r2qkb1r/1p3ppp/p1npbn2/4p1B1/4P3/N1N5/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 c8e6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Bg5 a6 8. Na3 Be6"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Exchange Variation", "r1bqkb1r/pp1p1ppp/2N2n2/4p3/4P3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4c6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nxc6"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Retreat Variation", "r1bqkb1r/pp1p1ppp/2n2n2/4p3/4P3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4f3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nf3"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Schlechter Variation", "r1bqkb1r/pp1p1ppp/2n2n2/4p3/4P3/1NN5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4b3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Nb3"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Sveshnikov Variation", "r1bqkb1r/5ppp/p1np1n2/1p2p1B1/4P3/N1N5/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 b7b5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Bg5 a6 8. Na3 b5"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Sveshnikov Variation", "r1bqkb1r/5p1p/p1np4/1p1Npp2/4P3/N7/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 b7b5 g5f6 g7f6 c3d5 f6f5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Bg5 a6 8. Na3 b5 9. Bxf6 gxf6 10. Nd5 f5"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Sveshnikov Variation, Chelyabinsk Variation", "r1bqkb1r/5ppp/p1np1n2/1p1Np1B1/4P3/N7/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 b7b5 c3d5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Bg5 a6 8. Na3 b5 9. Nd5"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Sveshnikov Variation, Novosibirsk Variation", "r1bqk2r/5pbp/p1np1p2/1p1Np3/4P3/N7/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 b7b5 g5f6 g7f6 c3d5 f8g7", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Bg5 a6 8. Na3 b5 9. Bxf6 gxf6 10. Nd5 Bg7"),
  new FullOpening("B33", "Sicilian Defense: Lasker-Pelikan Variation, Sveshnikov Variation, Peresypkin's Sacrifice", "r1bqkb1r/5p1p/p1np4/1B1Npp2/4P3/N7/PPP2PPP/R2QK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 d4b5 d7d6 c1g5 a7a6 b5a3 b7b5 g5f6 g7f6 c3d5 f6f5 f1b5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Ndb5 d6 7. Bg5 a6 8. Na3 b5 9. Bxf6 gxf6 10. Nd5 f5 11. Bxb5"),
  new FullOpening("B33", "Sicilian Defense: Open", "r1bqkb1r/pp1ppppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6"),
  new FullOpening("B34", "Sicilian Defense: Accelerated Dragon, Exchange Variation", "r1bqkbnr/pp1ppp1p/2N3p1/8/4P3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 d4c6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nxc6"),
  new FullOpening("B34", "Sicilian Defense: Accelerated Dragon, Modern Variation", "r1bqkbnr/pp1ppp1p/2n3p1/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 b1c3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3"),
  new FullOpening("B35", "Sicilian Defense: Accelerated Dragon, Modern Bc4 Variation", "r1bqk2r/pp1pppbp/2n2np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 b1c3 f8g7 c1e3 g8f6 f1c4", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3 Bg7 6. Be3 Nf6 7. Bc4"),
  new FullOpening("B35", "Sicilian Defense: Dragon Variation, Modern Bc4 Variation", "r1bqk2r/pp2ppbp/2np1np1/8/2BNP3/2N1B3/PPP2PPP/R2QK2R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 b1c3 f8g7 c1e3 g8f6 f1c4 d7d6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3 Bg7 6. Be3 Nf6 7. Bc4 d6"),
  new FullOpening("B36", "Sicilian Defense: Accelerated Dragon, Maróczy Bind", "r1bqkbnr/pp1ppp1p/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 c2c4", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4"),
  new FullOpening("B36", "Sicilian Defense: Accelerated Dragon, Maróczy Bind, Gurgenidze Variation", "r1bqkb1r/pp2pp1p/3p1np1/8/2PQP3/2N5/PP3PPP/R1B1KB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 c2c4 g8f6 b1c3 c6d4 d1d4 d7d6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Nf6 6. Nc3 Nxd4 7. Qxd4 d6"),
  new FullOpening("B37", "Sicilian Defense: Accelerated Fianchetto, Maróczy Bind", "r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/8/PP3PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 c2c4 f8g7", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7"),
  new FullOpening("B37", "Sicilian Defense: Accelerated Fianchetto, Simagin Variation", "r1bqk2r/pp2ppbp/2np2pn/8/2P1P3/8/PPN1BPPP/RNBQK2R w KQkq -", "g1f3 c7c5 c2c4 g7g6 d2d4 c5d4 f3d4 b8c6 d4c2 f8g7 e2e4 d7d6 f1e2 g8h6", "1. Nf3 c5 2. c4 g6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc2 Bg7 6. e4 d6 7. Be2 Nh6"),
  new FullOpening("B38", "Sicilian Defense: Accelerated Dragon, Maróczy Bind", "r1bqk1nr/pp1pppbp/2n3p1/8/2PNP3/4B3/PP3PPP/RN1QKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 c2c4 f8g7 c1e3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7 6. Be3"),
  new FullOpening("B39", "Sicilian Defense: Accelerated Dragon, Maróczy Bind, Breyer Variation", "r1bqk2r/pp1pppbp/2n3p1/8/2PNP1n1/2N1B3/PP3PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 c2c4 f8g7 c1e3 g8f6 b1c3 f6g4", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. c4 Bg7 6. Be3 Nf6 7. Nc3 Ng4"),
  new FullOpening("B40", "Sicilian Defense: Alapin Variation, Sherzer Variation", "r1bqkb1r/pp1p1ppp/2n1p3/2pnP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 c2c3 g8f6 e4e5 f6d5 d2d4 b8c6", "1. e4 c5 2. Nf3 e6 3. c3 Nf6 4. e5 Nd5 5. d4 Nc6"),
  new FullOpening("B40", "Sicilian Defense: Delayed Alapin Variation", "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 c2c3", "1. e4 c5 2. Nf3 e6 3. c3"),
  new FullOpening("B40", "Sicilian Defense: Drazic Variation", "rnbqkbnr/1p1p1ppp/p3p3/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 a7a6", "1. e4 c5 2. Nf3 e6 3. d4 a6"),
  new FullOpening("B40", "Sicilian Defense: Four Knights Variation", "r1bqkb1r/pp1p1ppp/2n1pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6"),
  new FullOpening("B40", "Sicilian Defense: Four Knights Variation, Exchange Variation", "r1bqkb1r/pp1p1ppp/2N1pn2/8/4P3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 d4c6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Nxc6"),
  new FullOpening("B40", "Sicilian Defense: French Variation", "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6", "1. e4 c5 2. Nf3 e6"),
  new FullOpening("B40", "Sicilian Defense: French Variation, Normal", "rnbqkb1r/pp1p1ppp/4pn2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6"),
  new FullOpening("B40", "Sicilian Defense: French Variation, Open", "rnbqkbnr/pp1p1ppp/4p3/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4"),
  new FullOpening("B40", "Sicilian Defense: French Variation, Westerinen Attack", "rnbqkbnr/pp1p1ppp/4p3/2p5/4P3/1P3N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 b2b3", "1. e4 c5 2. Nf3 e6 3. b3"),
  new FullOpening("B40", "Sicilian Defense: Gaw-Paw Variation", "rnb1kb1r/pp1p1ppp/1q2pn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d8b6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Qb6"),
  new FullOpening("B40", "Sicilian Defense: Kan Variation, Wing Attack, Fianchetto Variation", "rnbqkbnr/3p1ppp/p3p3/1p6/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 b1c3 a7a6 g2g3 b7b5 d2d4 c5d4 f3d4", "1. e4 c5 2. Nf3 e6 3. Nc3 a6 4. g3 b5 5. d4 cxd4 6. Nxd4"),
  new FullOpening("B40", "Sicilian Defense: Kramnik Variation", "rnbqkbnr/pp1p1ppp/4p3/2p5/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 c2c4", "1. e4 c5 2. Nf3 e6 3. c4"),
  new FullOpening("B40", "Sicilian Defense: Kveinis Variation", "rnb1kbnr/pp1p1ppp/1q2p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 d8b6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Qb6"),
  new FullOpening("B40", "Sicilian Defense: Marshall Counterattack", "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 d7d5", "1. e4 c5 2. Nf3 e6 3. d4 d5"),
  new FullOpening("B40", "Sicilian Defense: Paulsen-Basman Defense", "rnbqk1nr/pp1p1ppp/4p3/2b5/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 f8c5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Bc5"),
  new FullOpening("B40", "Sicilian Defense: Pin Variation", "rnbqk2r/pp1p1ppp/4pn2/8/1b1NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 f8b4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4"),
  new FullOpening("B40", "Sicilian Defense: Pin Variation, Jaffe Variation", "rnbqk2r/pp1p1ppp/5n2/4p3/1b1NP3/2NB4/PPP2PPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 f8b4 f1d3 e6e5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. Bd3 e5"),
  new FullOpening("B40", "Sicilian Defense: Pin Variation, Koch Variation", "rnbqk2r/pp1p1ppp/4pn2/4P3/1b1N4/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 f8b4 e4e5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bb4 6. e5"),
  new FullOpening("B40", "Sicilian Defense: Smith-Morra Gambit Deferred", "rnbqkbnr/pp1p1ppp/4p3/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 c2c3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. c3"),
  new FullOpening("B40", "Sicilian Defense: Wing Gambit Deferred", "rnbqkbnr/pp1p1ppp/4p3/2p5/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 b2b4", "1. e4 c5 2. Nf3 e6 3. b4"),
  new FullOpening("B41", "Sicilian Defense: Kan Variation", "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6"),
  new FullOpening("B41", "Sicilian Defense: Kan Variation, Maróczy Bind, Bronstein Variation", "r1bqk2r/1p1p1ppp/p1n1pn2/8/1bPNP3/2NB4/PP3PPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 c2c4 g8f6 b1c3 f8b4 f1d3 b8c6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4 Nf6 6. Nc3 Bb4 7. Bd3 Nc6"),
  new FullOpening("B41", "Sicilian Defense: Kan Variation, Maróczy Bind, Bronstein Variation", "r1bqk2r/1p1p1ppp/p1n1pn2/8/1bPNP3/2N5/PPB2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 c2c4 g8f6 b1c3 f8b4 f1d3 b8c6 d3c2", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4 Nf6 6. Nc3 Bb4 7. Bd3 Nc6 8. Bc2"),
  new FullOpening("B41", "Sicilian Defense: Kan Variation, Maróczy Bind, Hedgehog Variation", "rnbqkbnr/1p1p1p1p/p3p1p1/8/2PNP3/8/PP3PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 c2c4 g7g6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4 g6"),
  new FullOpening("B41", "Sicilian Defense: Kan Variation, Maróczy Bind, Réti Variation", "rnbqkbnr/1p1p1ppp/p3p3/8/2PNP3/8/PP3PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 c2c4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. c4"),
  new FullOpening("B42", "Sicilian Defense: Kan Variation, Gipslis Variation", "rnbqkb1r/1p3p1p/p2ppnp1/8/2PNP3/3B4/PP3PPP/RNBQ1RK1 w kq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 f1d3 g8f6 e1g1 d7d6 c2c4 g7g6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3 Nf6 6. O-O d6 7. c4 g6"),
  new FullOpening("B42", "Sicilian Defense: Kan Variation, Modern Variation", "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/3B4/PPP2PPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 f1d3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3"),
  new FullOpening("B42", "Sicilian Defense: Kan Variation, Polugaevsky Variation", "rnbqk1nr/1p1p1ppp/p3p3/2b5/3NP3/3B4/PPP2PPP/RNBQK2R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 f1d3 f8c5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3 Bc5"),
  new FullOpening("B42", "Sicilian Defense: Kan Variation, Swiss Cheese Variation", "rnbqkbnr/1p1p1p1p/p3p1p1/8/3NP3/3B4/PPP2PPP/RNBQK2R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 f1d3 g7g6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Bd3 g6"),
  new FullOpening("B43", "Sicilian Defense: Kan Variation, Knight Variation", "rnbqkbnr/1p1p1ppp/p3p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 b1c3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3"),
  new FullOpening("B43", "Sicilian Defense: Kan Variation, Wing Attack", "rnbqkbnr/3p1ppp/p3p3/1p6/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 b1c3 b7b5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 b5"),
  new FullOpening("B43", "Sicilian Defense: Kan Variation, Wing Attack, Christiansen's Dream", "rnb1k1nr/3p1ppp/pq2p3/1pb5/3NP1Q1/2NBB3/PPP2PPP/R3K2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 b1c3 b7b5 f1d3 d8b6 c1e3 f8c5 d1g4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 b5 6. Bd3 Qb6 7. Be3 Bc5 8. Qg4"),
  new FullOpening("B43", "Sicilian Defense: Kan Variation, Wing Attack, Spraggett Attack", "rnb1kbnr/3p1ppp/pq2p3/1p6/4P3/2NB1N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 a7a6 b1c3 b7b5 f1d3 d8b6 d4f3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 a6 5. Nc3 b5 6. Bd3 Qb6 7. Nf3"),
  new FullOpening("B44", "Sicilian Defense: Taimanov Variation", "r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6"),
  new FullOpening("B44", "Sicilian Defense: Taimanov Variation, Gary Gambit", "r1bqkb1r/1p3ppp/p1n1pn2/3p4/2P1P3/N1N5/PP3PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 d4b5 d7d6 c2c4 g8f6 b1c3 a7a6 b5a3 d6d5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb5 d6 6. c4 Nf6 7. N1c3 a6 8. Na3 d5"),
  new FullOpening("B44", "Sicilian Defense: Taimanov Variation, Modern Line", "r1bq1rk1/4bppp/ppnppn2/8/2P1P3/N1N5/PP2BPPP/R1BQ1RK1 w - -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 d4b5 d7d6 c2c4 g8f6 b1c3 a7a6 b5a3 f8e7 f1e2 e8g8 e1g1 b7b6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb5 d6 6. c4 Nf6 7. N1c3 a6 8. Na3 Be7 9. Be2 O-O 10. O-O b6"),
  new FullOpening("B44", "Sicilian Defense: Taimanov Variation, Szén Variation", "r1bqkbnr/pp1p1ppp/2n1p3/1N6/4P3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 d4b5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb5"),
  new FullOpening("B45", "Sicilian Defense: Taimanov Variation, American Attack", "r1bqk2r/pp1p1ppp/2nNpn2/8/1b2P3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 g8f6 d4b5 f8b4 b5d6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Nf6 6. Ndb5 Bb4 7. Nd6+"),
  new FullOpening("B45", "Sicilian Defense: Taimanov Variation, Normal Variation", "r1bqkbnr/pp1p1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3"),
  new FullOpening("B46", "Sicilian Defense: Taimanov Variation", "r1bqkbnr/1p1p1ppp/p1n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 a7a6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 a6"),
  new FullOpening("B47", "Sicilian Defense: Taimanov Variation, Bastrikov Variation", "r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7"),
  new FullOpening("B47", "Sicilian Defense: Taimanov Variation, Bastrikov Variation, Ponomariov Gambit", "rqb1kbnr/1p1p1ppp/pBn1p3/1N6/4P3/2N5/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 d4b5 c7b8 c1e3 a7a6 e3b6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Ndb5 Qb8 7. Be3 a6 8. Bb6"),
  new FullOpening("B48", "Sicilian Defense: Taimanov Variation, Bastrikov Variation", "r1b1kbnr/ppqp1ppp/2n1p3/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 c1e3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be3"),
  new FullOpening("B48", "Sicilian Defense: Taimanov Variation, Bastrikov Variation, English Attack", "r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1BP2/PPP3PP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 c1e3 a7a6 f2f3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be3 a6 7. f3"),
  new FullOpening("B48", "Sicilian Defense: Taimanov Variation, Bastrikov Variation, English Attack", "r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPPQ1PPP/R3KB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 c1e3 a7a6 d1d2", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be3 a6 7. Qd2"),
  new FullOpening("B49", "Sicilian Defense: Taimanov Variation, Bastrikov Variation", "r1b1kbnr/1pqp1ppp/p1n1p3/8/3NP3/2N1B3/PPP1BPPP/R2QK2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 c1e3 a7a6 f1e2", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be3 a6 7. Be2"),
  new FullOpening("B50", "Sicilian Defense", "rnbqkbnr/pp2pppp/3p4/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4", "1. e4 c5 2. Nf3 d6 3. d4"),
  new FullOpening("B50", "Sicilian Defense", "rnbqkbnr/pp2pppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4"),
  new FullOpening("B50", "Sicilian Defense: Delayed Alapin", "rnbqkbnr/pp2pppp/3p4/2p5/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 c2c3", "1. e4 c5 2. Nf3 d6 3. c3"),
  new FullOpening("B50", "Sicilian Defense: Delayed Alapin, Basman-Palatnik Double Gambit", "r1b1kb1r/pp2pppp/2np4/q2P4/8/2P2N2/P3BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 c2c3 g8f6 f1e2 b8c6 d2d4 c5d4 c3d4 f6e4 d4d5 d8a5 b1c3 e4c3 b2c3", "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Be2 Nc6 5. d4 cxd4 6. cxd4 Nxe4 7. d5 Qa5+ 8. Nc3 Nxc3 9. bxc3"),
  new FullOpening("B50", "Sicilian Defense: Delayed Alapin, Basman-Palatnik Gambit", "r1bqkb1r/pp2pppp/2np4/8/3Pn3/5N2/PP2BPPP/RNBQK2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 c2c3 g8f6 f1e2 b8c6 d2d4 c5d4 c3d4 f6e4", "1. e4 c5 2. Nf3 d6 3. c3 Nf6 4. Be2 Nc6 5. d4 cxd4 6. cxd4 Nxe4"),
  new FullOpening("B50", "Sicilian Defense: Kotov Gambit", "rnbqkbnr/p3pppp/3p4/1pp5/4P3/5NP1/PPPP1P1P/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 g2g3 b7b5", "1. e4 c5 2. Nf3 d6 3. g3 b5"),
  new FullOpening("B50", "Sicilian Defense: Modern Variations", "rnbqkbnr/pp2pppp/3p4/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6", "1. e4 c5 2. Nf3 d6"),
  new FullOpening("B50", "Sicilian Defense: Modern Variations, Anti-Qxd4 Move Order", "rnbqkb1r/pp2pppp/3p1n2/2p5/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 g8f6", "1. e4 c5 2. Nf3 d6 3. d4 Nf6"),
  new FullOpening("B50", "Sicilian Defense: Modern Variations, Anti-Qxd4 Move Order Accepted", "rnbqkb1r/pp2pppp/3p4/2P5/4n3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 g8f6 d4c5 f6e4", "1. e4 c5 2. Nf3 d6 3. d4 Nf6 4. dxc5 Nxe4"),
  new FullOpening("B50", "Sicilian Defense: Modern Variations, Tartakower", "rnbqkbnr/pp2pppp/3p4/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 c2c3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. c3"),
  new FullOpening("B50", "Sicilian Defense: Wing Gambit, Deferred Variation", "rnbqkbnr/pp2pppp/3p4/2p5/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 b2b4", "1. e4 c5 2. Nf3 d6 3. b4"),
  new FullOpening("B51", "Sicilian Defense: Canal Attack, Dorfman Gambit", "r2qkbnr/pp1bpp1p/2np2p1/1Bp1P3/8/5N2/PPPPQPPP/RNB2RK1 b kq -", "e2e4 c7c5 g1f3 d7d6 f1b5 b8c6 e1g1 c8d7 d1e2 g7g6 e4e5", "1. e4 c5 2. Nf3 d6 3. Bb5+ Nc6 4. O-O Bd7 5. Qe2 g6 6. e5"),
  new FullOpening("B51", "Sicilian Defense: Canal Attack, Moscow Gambit", "r2qkb1r/1p2pppp/p2p1n2/2p3B1/3Pb3/2P2N2/PP3PPP/RN1QR1K1 b kq -", "e2e4 c7c5 g1f3 d7d6 f1b5 b8c6 e1g1 c8d7 c2c3 g8f6 f1e1 a7a6 b5c6 d7c6 d2d4 c6e4 c1g5", "1. e4 c5 2. Nf3 d6 3. Bb5+ Nc6 4. O-O Bd7 5. c3 Nf6 6. Re1 a6 7. Bxc6 Bxc6 8. d4 Bxe4 9. Bg5"),
  new FullOpening("B51", "Sicilian Defense: Moscow Variation", "rnbqkbnr/pp2pppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 f1b5", "1. e4 c5 2. Nf3 d6 3. Bb5+"),
  new FullOpening("B52", "Sicilian Defense: Canal Attack, Haag Gambit", "r3kb1r/pp1qpppp/2np1n2/2p5/3PP3/2P2N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 c7c5 g1f3 d7d6 f1b5 c8d7 b5d7 d8d7 e1g1 b8c6 c2c3 g8f6 d2d4", "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7 4. Bxd7+ Qxd7 5. O-O Nc6 6. c3 Nf6 7. d4"),
  new FullOpening("B52", "Sicilian Defense: Canal Attack, Main Line", "rn1qkbnr/pp1bpppp/3p4/1Bp5/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 f1b5 c8d7", "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7"),
  new FullOpening("B52", "Sicilian Defense: Moscow Variation, Sokolsky Variation", "rn2kbnr/pp1qpppp/3p4/2p5/2P1P3/5N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 f1b5 c8d7 b5d7 d8d7 c2c4", "1. e4 c5 2. Nf3 d6 3. Bb5+ Bd7 4. Bxd7+ Qxd7 5. c4"),
  new FullOpening("B53", "Sicilian Defense: Chekhover Variation", "rnbqkbnr/pp2pppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 d1d4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4"),
  new FullOpening("B53", "Sicilian Defense: Chekhover Variation, Zaitsev Defense", "r1b1kbnr/pp1qpppp/2np4/1B6/3QP3/5N2/PPP2PPP/RNB1K2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 d1d4 b8c6 f1b5 d8d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Qxd4 Nc6 5. Bb5 Qd7"),
  new FullOpening("B54", "Sicilian Defense: Accelerated Dragon", "rnbqkbnr/pp2pp1p/3p2p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g7g6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 g6"),
  new FullOpening("B54", "Sicilian Defense: Modern Variations, Ginsberg Gambit", "rnbqkb1r/pp2pppp/3p1n2/8/2BNP3/8/PPP2PPP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 f1c4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Bc4"),
  new FullOpening("B54", "Sicilian Defense: Modern Variations, Main Line", "rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6"),
  new FullOpening("B54", "Sicilian Defense: Prins Variation", "rnbqkb1r/pp2pppp/3p1n2/8/3NP3/5P2/PPP3PP/RNBQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 f2f3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3"),
  new FullOpening("B55", "Sicilian Defense: Prins Variation, Venice Attack", "rnbqkb1r/pp3ppp/3p1n2/1B2p3/3NP3/5P2/PPP3PP/RNBQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 f2f3 e7e5 f1b5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. f3 e5 6. Bb5+"),
  new FullOpening("B56", "Sicilian Defense: Classical Variation", "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6"),
  new FullOpening("B56", "Sicilian Defense: Classical Variation, Fianchetto Variation", "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 g2g3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. g3"),
  new FullOpening("B56", "Sicilian Defense: Kupreichik Variation", "rn1qkb1r/pp1bpppp/3p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 c8d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Bd7"),
  new FullOpening("B56", "Sicilian Defense: Spielmann Variation", "r1bqkb1r/pp2pppp/2np1n2/8/4P3/2N5/PPP1NPPP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 d4e2", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Nde2"),
  new FullOpening("B56", "Sicilian Defense: Venice Attack", "rnbqkb1r/pp3ppp/3p1n2/1B2p3/3NP3/2N5/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 e7e5 f1b5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 e5 6. Bb5+"),
  new FullOpening("B56", "Sicilian Defense: Yates Variation", "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2NB4/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1d3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bd3"),
  new FullOpening("B57", "Sicilian Defense: Classical Variation, Anti-Sozin Variation", "r1b1kb1r/pp2pppp/1qnp1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4 d8b6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 Qb6"),
  new FullOpening("B57", "Sicilian Defense: Magnus Smith Trap", "r1bqkb1r/p3pp1p/2pp1np1/4P3/2B5/2N5/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4 g7g6 d4c6 b7c6 e4e5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 g6 7. Nxc6 bxc6 8. e5"),
  new FullOpening("B57", "Sicilian Defense: Sozin, not Scheveningen", "r1bqkb1r/pp2pppp/2np1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4"),
  new FullOpening("B58", "Sicilian Defense: Boleslavsky Variation", "r1bqkb1r/pp3ppp/2np1n2/4p3/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 f1e2 e7e5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Be2 e5"),
  new FullOpening("B58", "Sicilian Defense: Boleslavsky Variation, Louma Variation", "r1bqkb1r/pp3ppp/2Np1n2/4p3/4P3/2N5/PPP1BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2 e7e5 d4c6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2 e5 7. Nxc6"),
  new FullOpening("B58", "Sicilian Defense: Classical Variation", "r1bqkb1r/pp2pppp/2np1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 f1e2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Be2"),
  new FullOpening("B58", "Sicilian Defense: Classical Variation, Dragon Transfer", "r1bqkb1r/pp2pp1p/3p1np1/8/3QP3/2N5/PPP1BPPP/R1B1K2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 f1e2 c6d4 d1d4 g7g6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Be2 Nxd4 7. Qxd4 g6"),
  new FullOpening("B59", "Sicilian Defense: Boleslavsky Variation", "r1bqkb1r/pp3ppp/2np1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2 e7e5 d4b3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2 e5 7. Nb3"),
  new FullOpening("B60", "Sicilian Defense: Richter-Rauzer Variation", "r1bqkb1r/pp2pppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5"),
  new FullOpening("B60", "Sicilian Defense: Richter-Rauzer Variation, Dragon Variation", "r1bqkb1r/pp2pp1p/2np1np1/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 c1g5 g7g6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 g6"),
  new FullOpening("B60", "Sicilian Defense: Richter-Rauzer Variation, Modern Variation", "r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 c8d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 Bd7"),
  new FullOpening("B61", "Sicilian Defense: Richter-Rauzer Variation, Modern Variation", "r2qkb1r/pp1bpppp/2np1n2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 c1g5 c8d7 d1d2", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 Bd7 7. Qd2"),
  new FullOpening("B62", "Sicilian Defense: Richter-Rauzer Variation", "r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6"),
  new FullOpening("B62", "Sicilian Defense: Richter-Rauzer Variation", "r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2NQ4/PPP2PPP/R3KB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd3"),
  new FullOpening("B62", "Sicilian Defense: Richter-Rauzer Variation, Exchange Variation", "r1bqkb1r/pp3ppp/2Nppn2/6B1/4P3/2N5/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d4c6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Nxc6"),
  new FullOpening("B62", "Sicilian Defense: Richter-Rauzer Variation, Podebrady Variation", "r1bqkb1r/pp3ppp/2nppn2/6B1/4P3/1NN5/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 c1g5 e7e6 d4b3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 e6 7. Nb3"),
  new FullOpening("B62", "Sicilian Defense: Richter-Rauzer Variation, Vitolins Variation", "r1bqkb1r/pp3ppp/2nppn2/1B4B1/3NP3/2N5/PPP2PPP/R2QK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 f1b5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Bb5"),
  new FullOpening("B63", "Sicilian Defense: Richter-Rauzer Variation, Classical Variation", "r1bqk2r/pp2bppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2 f8e7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2 Be7"),
  new FullOpening("B63", "Sicilian Defense: Richter-Rauzer Variation, Classical Variation, Kantscher Line", "r1bqk2r/4bppp/p2ppn2/1p4B1/3QPP2/2N5/PPP3PP/2KR1B1R w kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2 f8e7 e1c1 c6d4 d2d4 a7a6 f2f4 b7b5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2 Be7 8. O-O-O Nxd4 9. Qxd4 a6 10. f4 b5"),
  new FullOpening("B63", "Sicilian Defense: Richter-Rauzer Variation, Ivanov Variation", "r1b1kb1r/pp3ppp/1qnppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2 d8b6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2 Qb6"),
  new FullOpening("B63", "Sicilian Defense: Richter-Rauzer Variation, Traditional Variation", "r1bqkb1r/pp3ppp/2nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2"),
  new FullOpening("B64", "Sicilian Defense: Richter-Rauzer Variation, Classical Variation", "r1bq1rk1/pp2bppp/2np1n2/4p1B1/3NPP2/2N5/PPPQ2PP/2KR1B1R w - -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 c1g5 e7e6 d1d2 f8e7 e1c1 e8g8 f2f4 e6e5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 e6 7. Qd2 Be7 8. O-O-O O-O 9. f4 e5"),
  new FullOpening("B64", "Sicilian Defense: Richter-Rauzer Variation, Classical Variation", "r1bq1rk1/pp2bppp/2nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R b - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2 f8e7 e1c1 e8g8 f2f4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2 Be7 8. O-O-O O-O 9. f4"),
  new FullOpening("B65", "Sicilian Defense: Richter-Rauzer Variation, Classical Variation", "r1bq1rk1/pp2bppp/3ppn2/6B1/3QPP2/2N5/PPP3PP/2KR1B1R b - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2 f8e7 e1c1 e8g8 f2f4 c6d4 d2d4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2 Be7 8. O-O-O O-O 9. f4 Nxd4 10. Qxd4"),
  new FullOpening("B65", "Sicilian Defense: Richter-Rauzer Variation, Rauzer Attack", "r1bq1rk1/pp2bppp/3ppn2/6B1/3nPP2/2N5/PPPQ2PP/2KR1B1R w - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2 f8e7 e1c1 e8g8 f2f4 c6d4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2 Be7 8. O-O-O O-O 9. f4 Nxd4"),
  new FullOpening("B66", "Sicilian Defense: Richter-Rauzer Variation, Neo-Modern Variation, Early Deviations", "r1bqkb1r/1p3ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/R3KB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 c1g5 e7e6 d1d2 a7a6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bg5 e6 7. Qd2 a6"),
  new FullOpening("B67", "Sicilian Defense: Richter-Rauzer Variation, Neo-Modern Variation", "r2qkb1r/1p1b1ppp/p1nppn2/6B1/3NP3/2N5/PPPQ1PPP/2KR1B1R w kq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 c1g5 e7e6 d1d2 a7a6 e1c1 c8d7", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 e6 7. Qd2 a6 8. O-O-O Bd7"),
  new FullOpening("B68", "Sicilian Defense: Richter-Rauzer Variation, Neo-Modern Variation", "r2qk2r/1p1bbppp/p1nppn2/6B1/3NPP2/2N5/PPPQ2PP/2KR1B1R w kq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 c1g5 e7e6 d1d2 a7a6 e1c1 c8d7 f2f4 f8e7", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 e6 7. Qd2 a6 8. O-O-O Bd7 9. f4 Be7"),
  new FullOpening("B69", "Sicilian Defense: Richter-Rauzer Variation, Neo-Modern Variation, Nyezhmetdinov Attack", "r2qk2r/3bbppp/p1nppB2/1p6/4PP2/2N2N2/PPPQ2PP/2KR1B1R b kq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 c1g5 e7e6 d1d2 a7a6 e1c1 c8d7 f2f4 f8e7 d4f3 b7b5 g5f6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bg5 e6 7. Qd2 a6 8. O-O-O Bd7 9. f4 Be7 10. Nf3 b5 11. Bxf6"),
  new FullOpening("B70", "Sicilian Defense: Dragon Variation", "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6"),
  new FullOpening("B70", "Sicilian Defense: Dragon Variation, Classical Variation", "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f1e2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2"),
  new FullOpening("B70", "Sicilian Defense: Dragon Variation, Fianchetto Variation", "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 g2g3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. g3"),
  new FullOpening("B71", "Sicilian Defense: Dragon Variation, Levenfish Variation", "rnbqkb1r/pp2pp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f2f4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. f4"),
  new FullOpening("B71", "Sicilian Defense: Dragon Variation, Levenfish Variation, Main Line", "r1bqkb1r/pp1npp1p/3p1np1/8/3NPP2/2N5/PPP3PP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f2f4 b8d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. f4 Nbd7"),
  new FullOpening("B72", "Sicilian Defense: Dragon Variation", "rnbqkb1r/pp2pp1p/3p1np1/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3"),
  new FullOpening("B72", "Sicilian Defense: Dragon Variation, Classical Variation", "rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f1e2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. Be2"),
  new FullOpening("B72", "Sicilian Defense: Dragon Variation, Classical Variation", "r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2QK2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f1e2 b8c6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. Be2 Nc6"),
  new FullOpening("B72", "Sicilian Defense: Dragon Variation, Classical Variation", "r1bqk2r/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2QK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f1e2 b8c6 d4b3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. Be2 Nc6 8. Nb3"),
  new FullOpening("B72", "Sicilian Defense: Dragon Variation, Classical Variation, Amsterdam Variation", "r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPPQBPPP/R3K2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2 g7g6 c1e3 f8g7 d1d2", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2 g6 7. Be3 Bg7 8. Qd2"),
  new FullOpening("B72", "Sicilian Defense: Dragon Variation, Classical Variation, Grigoriev Variation", "r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPPQBPPP/2KR3R b - -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2 g7g6 c1e3 f8g7 d1d2 e8g8 e1c1", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2 g6 7. Be3 Bg7 8. Qd2 O-O 9. O-O-O"),
  new FullOpening("B73", "Sicilian Defense: Dragon Variation, Classical Variation", "r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPP1BPPP/R2Q1RK1 b kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f1e2 f8g7 e1g1 b8c6 c1e3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2 Bg7 7. O-O Nc6 8. Be3"),
  new FullOpening("B73", "Sicilian Defense: Dragon Variation, Classical Variation, Battery Variation", "r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1B3/PPPQBPPP/R4RK1 b - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f1e2 f8g7 e1g1 e8g8 c1e3 b8c6 d1d2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2 Bg7 7. O-O O-O 8. Be3 Nc6 9. Qd2"),
  new FullOpening("B73", "Sicilian Defense: Dragon Variation, Classical Variation, Zollner Gambit", "r1b2rk1/pp2ppbp/1qnp1np1/4P3/3N1P2/2N1B3/PPP1B1PP/R2Q1RK1 b - -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 c1e3 f8g7 f1e2 g8f6 b1c3 e8g8 e1g1 d7d6 f2f4 d8b6 e4e5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Be3 Bg7 6. Be2 Nf6 7. Nc3 O-O 8. O-O d6 9. f4 Qb6 10. e5"),
  new FullOpening("B74", "Sicilian Defense: Dragon Variation, Classical Variation, Alekhine Line", "r1bq1rk1/1p2ppbp/2np1np1/p7/4P3/1NN1B3/PPP1BPPP/R2Q1RK1 w - -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 b1c3 f8g7 c1e3 g8f6 f1e2 e8g8 e1g1 d7d6 d4b3 a7a5", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3 Bg7 6. Be3 Nf6 7. Be2 O-O 8. O-O d6 9. Nb3 a5"),
  new FullOpening("B74", "Sicilian Defense: Dragon Variation, Classical Variation, Bernard Defense", "r2q1rk1/pp2ppbp/5np1/n2p1P2/4P3/1NNPB3/PP4PP/R2Q1RK1 w - -", "e2e4 c7c5 g1f3 g7g6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2 f8g7 e1g1 e8g8 c1e3 b8c6 d4b3 c8e6 f2f4 c6a5 f4f5 e6c4 e2d3 c4d3 c2d3 d6d5", "1. e4 c5 2. Nf3 g6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2 Bg7 7. O-O O-O 8. Be3 Nc6 9. Nb3 Be6 10. f4 Na5 11. f5 Bc4 12. Bd3 Bxd3 13. cxd3 d5"),
  new FullOpening("B74", "Sicilian Defense: Dragon Variation, Classical Variation, Maróczy Line", "r2q1rk1/pp2ppbp/3pbnp1/n7/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1 w - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f1e2 f8g7 e1g1 e8g8 c1e3 b8c6 d4b3 c8e6 f2f4 c6a5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2 Bg7 7. O-O O-O 8. Be3 Nc6 9. Nb3 Be6 10. f4 Na5"),
  new FullOpening("B74", "Sicilian Defense: Dragon Variation, Classical Variation, Normal Line", "r1bq1rk1/pp2ppbp/2np1np1/8/4P3/1NN1B3/PPP1BPPP/R2Q1RK1 b - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f1e2 f8g7 e1g1 e8g8 c1e3 b8c6 d4b3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2 Bg7 7. O-O O-O 8. Be3 Nc6 9. Nb3"),
  new FullOpening("B74", "Sicilian Defense: Dragon Variation, Classical Variation, Spielmann Variation", "r2q1rk1/pp2ppbp/3p1np1/n4P2/2b1P3/1NNBB3/PPP3PP/R2Q1RK1 b - -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g7g6 b1c3 f8g7 c1e3 g8f6 f1e2 e8g8 d4b3 d7d6 e1g1 c8e6 f2f4 c6a5 f4f5 e6c4 e2d3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 g6 5. Nc3 Bg7 6. Be3 Nf6 7. Be2 O-O 8. Nb3 d6 9. O-O Be6 10. f4 Na5 11. f5 Bc4 12. Bd3"),
  new FullOpening("B74", "Sicilian Defense: Dragon Variation, Classical Variation, Stockholm Attack", "r4rk1/pp2ppbp/3p1np1/q4P2/4P1P1/2N1B3/PPP1Q2P/R4RK1 b - -", "e2e4 c7c5 b1c3 d7d6 f2f4 b8c6 g1f3 g7g6 d2d4 c5d4 f3d4 f8g7 c1e3 g8f6 f1e2 e8g8 d4b3 c8e6 e1g1 c6a5 f4f5 e6c4 b3a5 c4e2 d1e2 d8a5 g2g4", "1. e4 c5 2. Nc3 d6 3. f4 Nc6 4. Nf3 g6 5. d4 cxd4 6. Nxd4 Bg7 7. Be3 Nf6 8. Be2 O-O 9. Nb3 Be6 10. O-O Na5 11. f5 Bc4 12. Nxa5 Bxe2 13. Qxe2 Qxa5 14. g4"),
  new FullOpening("B74", "Sicilian Defense: Dragon Variation, Classical Variation, Tartakower Line", "r1q2rk1/pp2ppbp/2npbnp1/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1 w - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 f1e2 f8g7 e1g1 e8g8 c1e3 b8c6 d4b3 c8e6 f2f4 d8c8", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be2 Bg7 7. O-O O-O 8. Be3 Nc6 9. Nb3 Be6 10. f4 Qc8"),
  new FullOpening("B75", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Belezky Line", "r1bqk2r/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 b8c6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 Nc6"),
  new FullOpening("B75", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Early Deviations", "rnbqk2r/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3"),
  new FullOpening("B76", "Sicilian Defense: Dragon Variation, Yugoslav Attack", "rnbq1rk1/pp2ppbp/3p1np1/8/3NP3/2N1BP2/PPP3PP/R2QKB1R w KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O"),
  new FullOpening("B76", "Sicilian Defense: Dragon Variation, Yugoslav Attack", "r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/R3KB1R w KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6"),
  new FullOpening("B76", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Modern Line", "r1bq1rk1/pp2ppbp/2np1np1/8/3NP3/2N1BP2/PPPQ2PP/2KR1B1R b - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 b8c6 d1d2 e8g8 e1c1", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 Nc6 8. Qd2 O-O 9. O-O-O"),
  new FullOpening("B76", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Panov Variation", "r1bq1rk1/pp2ppbp/2np1np1/8/3NP1P1/2N1BP2/PPPQ3P/R3KB1R b KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 g2g4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. g4"),
  new FullOpening("B77", "Sicilian Defense: Dragon Variation, Yugoslav Attack", "r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R w KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 f1c4 c8d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. Bc4 Bd7"),
  new FullOpening("B77", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Byrne Variation", "r1bq1rk1/1p2ppbp/2np1np1/p7/2BNP3/2N1BP2/PPPQ2PP/R3K2R w KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 f1c4 a7a5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. Bc4 a5"),
  new FullOpening("B77", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Czerniak Variation", "r2q1rk1/pp2ppbp/3pbnp1/8/2BBP3/2N2P2/PPPQ2PP/R3K2R w KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 b8c6 d1d2 e8g8 f1c4 c6d4 e3d4 c8e6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 Nc6 8. Qd2 O-O 9. Bc4 Nxd4 10. Bxd4 Be6"),
  new FullOpening("B77", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Main Line", "r1bq1rk1/pp2ppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R b KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 f1c4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. Bc4"),
  new FullOpening("B77", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Sosonko Variation", "r1bq1rk1/pp1nppbp/2np2p1/8/2BNP3/2N1BP2/PPPQ2PP/R3K2R w KQ -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 b8c6 d1d2 e8g8 f1c4 f6d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 Nc6 8. Qd2 O-O 9. Bc4 Nd7"),
  new FullOpening("B78", "Sicilian Defense: Dragon Variation, Yugoslav Attack", "r2q1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R b - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 f1c4 c8d7 e1c1", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. Bc4 Bd7 10. O-O-O"),
  new FullOpening("B78", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Old Line", "2rq1rk1/pp1bppbp/2np1np1/8/2BNP3/2N1BP2/PPPQ2PP/2KR3R w - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 f1c4 c8d7 e1c1 a8c8", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. Bc4 Bd7 10. O-O-O Rc8"),
  new FullOpening("B79", "Sicilian Defense: Dragon Variation, Yugoslav Attack", "r1r3k1/pp1bppbp/2np1np1/q7/3NP2P/1BN1BP2/PPPQ2P1/2KR3R b - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 f1c4 c8d7 h2h4 d8a5 e1c1 f8c8 c4b3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. Bc4 Bd7 10. h4 Qa5 11. O-O-O Rfc8 12. Bb3"),
  new FullOpening("B79", "Sicilian Defense: Dragon Variation, Yugoslav Attack, Soltis Variation", "r1r3k1/pp1bppb1/2np1np1/q6p/3NP2P/1BN1BP2/PPPQ2P1/2KR3R w - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 g7g6 c1e3 f8g7 f2f3 e8g8 d1d2 b8c6 f1c4 c8d7 e1c1 d8a5 h2h4 f8c8 c4b3 h7h5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 g6 6. Be3 Bg7 7. f3 O-O 8. Qd2 Nc6 9. Bc4 Bd7 10. O-O-O Qa5 11. h4 Rfc8 12. Bb3 h5"),
  new FullOpening("B80", "Sicilian Defense: Scheveningen Variation", "rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6"),
  new FullOpening("B80", "Sicilian Defense: Scheveningen Variation, English Attack", "rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1B3/PPPQ1PPP/R3KB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1e3 e7e6 d1d2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e6 7. Qd2"),
  new FullOpening("B80", "Sicilian Defense: Scheveningen Variation, Fianchetto Variation", "rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 g2g3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. g3"),
  new FullOpening("B80", "Sicilian Defense: Scheveningen Variation, Vitolins Variation", "rnbqkb1r/pp3ppp/3ppn2/1B6/3NP3/2N5/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1b5", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bb5+"),
  new FullOpening("B81", "Sicilian Defense: Scheveningen Variation, Keres Attack", "rnbqkb1r/pp3ppp/3ppn2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 g2g4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. g4"),
  new FullOpening("B82", "Sicilian Defense: Scheveningen Variation, Matanovic Attack", "rnbqkb1r/pp3ppp/3ppn2/8/3NPP2/2N5/PPP3PP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f2f4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. f4"),
  new FullOpening("B82", "Sicilian Defense: Scheveningen Variation, Tal Variation", "r1bqk2r/pp2bppp/2nppn2/8/3NPP2/2N1BQ2/PPP3PP/R3KB1R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f2f4 b8c6 c1e3 f8e7 d1f3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. f4 Nc6 7. Be3 Be7 8. Qf3"),
  new FullOpening("B83", "Sicilian Defense: Scheveningen Variation, Classical Variation", "rnbqkb1r/pp3ppp/3ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2"),
  new FullOpening("B83", "Sicilian Defense: Scheveningen Variation, Modern Variation", "r1bqkb1r/pp3ppp/2nppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d7d6 f1e2 g8f6", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 d6 6. Be2 Nf6"),
  new FullOpening("B83", "Sicilian Defense: Scheveningen Variation, Modern Variation", "r1bq1rk1/pp2bppp/2nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1 b - -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2 f8e7 e1g1 e8g8 f2f4 b8c6 c1e3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2 Be7 7. O-O O-O 8. f4 Nc6 9. Be3"),
  new FullOpening("B83", "Sicilian Defense: Scheveningen Variation, Modern Variation", "r2q1rk1/pp1bbppp/2nppn2/8/4PP2/1NN1B3/PPP1B1PP/R2Q1RK1 b - -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1e2 f8e7 e1g1 e8g8 f2f4 b8c6 c1e3 c8d7 d4b3", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Be2 Be7 7. O-O O-O 8. f4 Nc6 9. Be3 Bd7 10. Nb3"),
  new FullOpening("B84", "Sicilian Defense: Najdorf Variation, Scheveningen Variation", "r1bqkb1r/1p1n1ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1 w kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1e2 e7e6 e1g1 b8d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e6 7. O-O Nbd7"),
  new FullOpening("B84", "Sicilian Defense: Scheveningen Variation, Classical Variation", "rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1e2 e7e6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e6"),
  new FullOpening("B84", "Sicilian Defense: Scheveningen Variation, Classical Variation", "rnb1kb1r/1pq2ppp/p2ppn2/8/3NP3/2N5/PPP1BPPP/R1BQ1RK1 w kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1e2 e7e6 e1g1 d8c7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e6 7. O-O Qc7"),
  new FullOpening("B85", "Sicilian Defense: Scheveningen Variation, Classical Main Line", "r1b2rk1/1pq1bppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R3QRK1 w - -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 f1e2 a7a6 e1g1 g8f6 c1e3 f8e7 f2f4 d7d6 d1e1 e8g8", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be2 a6 7. O-O Nf6 8. Be3 Be7 9. f4 d6 10. Qe1 O-O"),
  new FullOpening("B85", "Sicilian Defense: Scheveningen Variation, Classical Variation, Paulsen Variation", "r1b1kb1r/1pq2ppp/p1nppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1 w kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f2f4 e7e6 f1e2 d8c7 e1g1 b8c6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. f4 e6 7. Be2 Qc7 8. O-O Nc6"),
  new FullOpening("B85", "Sicilian Defense: Scheveningen Variation, Classical Variation, Paulsen Variation", "r1b1kb1r/1pq2ppp/p1nppn2/8/3NPP2/2N1B3/PPP1B1PP/R2Q1RK1 b kq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 f1e2 a7a6 e1g1 g8f6 c1e3 d7d6 f2f4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be2 a6 7. O-O Nf6 8. Be3 d6 9. f4"),
  new FullOpening("B85", "Sicilian Defense: Scheveningen Variation, Classical Variation, Paulsen Variation", "r1b1k2r/1pq1bppp/p1nppn2/8/P2NPP2/2N5/1PP1B1PP/R1BQ1R1K b kq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 b8c6 b1c3 d8c7 f1e2 a7a6 e1g1 g8f6 g1h1 f8e7 f2f4 d7d6 a2a4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nc3 Qc7 6. Be2 a6 7. O-O Nf6 8. Kh1 Be7 9. f4 d6 10. a4"),
  new FullOpening("B86", "Sicilian Defense: Sozin Attack", "rnbqkb1r/pp3ppp/3ppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 e7e6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4", "1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4"),
  new FullOpening("B87", "Sicilian Defense: Sozin Attack, Flank Variation", "rnbqkb1r/5ppp/p2ppn2/1p6/3NP3/1BN5/PPP2PPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1c4 e7e6 c4b3 b7b5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bc4 e6 7. Bb3 b5"),
  new FullOpening("B88", "Sicilian Defense: Sozin Attack, Fischer Variation", "r1bq1rk1/pp2bppp/2nppn2/8/3NPP2/1BN1B3/PPP3PP/R2QK2R b KQ -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4 e7e6 c4b3 f8e7 c1e3 e8g8 f2f4", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 e6 7. Bb3 Be7 8. Be3 O-O 9. f4"),
  new FullOpening("B88", "Sicilian Defense: Sozin Attack, Leonhardt Variation", "r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N5/PPP2PPP/R1BQK2R w KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4 e7e6", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 e6"),
  new FullOpening("B89", "Sicilian Defense: Sozin Attack, Main Line", "r1bqkb1r/pp3ppp/2nppn2/8/2BNP3/2N1B3/PPP2PPP/R2QK2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4 e7e6 c1e3", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 e6 7. Be3"),
  new FullOpening("B89", "Sicilian Defense: Sozin Attack, Main Line, Sherbakov Variation", "r1bq1rk1/p3bppp/1p1ppn2/n7/3NPP2/1BN1B3/PPP3PP/R2Q1RK1 w - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 b8c6 f1c4 e7e6 c1e3 f8e7 c4b3 e8g8 e1g1 c6a5 f2f4 b7b6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 Nc6 6. Bc4 e6 7. Be3 Be7 8. Bb3 O-O 9. O-O Na5 10. f4 b6"),
  new FullOpening("B89", "Sicilian Defense: Velimirovic Attack", "r1bqk2r/pp2bppp/2nppn2/8/2BNP3/2N1B3/PPP1QPPP/R3K2R b KQkq -", "e2e4 c7c5 g1f3 b8c6 d2d4 c5d4 f3d4 g8f6 b1c3 d7d6 f1c4 e7e6 c1e3 f8e7 d1e2", "1. e4 c5 2. Nf3 Nc6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 d6 6. Bc4 e6 7. Be3 Be7 8. Qe2"),
  new FullOpening("B90", "Sicilian Defense: Najdorf Variation", "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6"),
  new FullOpening("B90", "Sicilian Defense: Najdorf Variation, Adams Attack", "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N4P/PPP2PP1/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 h2h3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. h3"),
  new FullOpening("B90", "Sicilian Defense: Najdorf Variation, Dekker Gambit", "rnbqkb1r/1p2pppp/p2p1n2/8/3NP1P1/2N5/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 g2g4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. g4"),
  new FullOpening("B90", "Sicilian Defense: Najdorf Variation, English Attack", "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1e3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3"),
  new FullOpening("B90", "Sicilian Defense: Najdorf Variation, English Attack, Anti-English", "rnbqkb1r/1p2pppp/p2p4/8/3NP1n1/2N1B3/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1e3 f6g4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 Ng4"),
  new FullOpening("B90", "Sicilian Defense: Najdorf Variation, Freak Attack", "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP2PPP/R1BQKBR1 b Qkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 h1g1", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Rg1"),
  new FullOpening("B90", "Sicilian Defense: Najdorf Variation, Lipnitsky Attack", "rnbqkb1r/1p2pppp/p2p1n2/8/2BNP3/2N5/PPP2PPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1c4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bc4"),
  new FullOpening("B90", "Sicilian Defense: Scheveningen Variation, Delayed Keres Attack", "rnbqkb1r/1p3ppp/p2ppn2/8/3NP1P1/2N1B3/PPP2P1P/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1e3 e7e6 g2g4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e6 7. g4"),
  new FullOpening("B90", "Sicilian Defense: Scheveningen Variation, Delayed Keres Attack, Perenyi Gambit", "rnbqkb1r/1p3p1p/p2p1np1/4pNP1/4P3/2N1B3/PPP2P1P/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1e3 e7e6 g2g4 e6e5 d4f5 g7g6 g4g5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e6 7. g4 e5 8. Nf5 g6 9. g5"),
  new FullOpening("B90", "Sicilian Defense: Scheveningen Variation, English Attack", "rnbqkb1r/1p3ppp/p2ppn2/8/3NP3/2N1BP2/PPP3PP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1e3 e7e6 f2f3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be3 e6 7. f3"),
  new FullOpening("B91", "Sicilian Defense: Najdorf Variation, Zagreb Variation", "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N3P1/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 g2g3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. g3"),
  new FullOpening("B92", "Sicilian Defense: Najdorf Variation, Opocensky Variation", "rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N5/PPP1BPPP/R1BQK2R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1e2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2"),
  new FullOpening("B92", "Sicilian Defense: Najdorf Variation, Opocensky Variation, Modern Line", "rn1qk2r/1p2bppp/p2pbn2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1 w kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1e2 e7e5 d4b3 f8e7 e1g1 c8e6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e5 7. Nb3 Be7 8. O-O Be6"),
  new FullOpening("B92", "Sicilian Defense: Najdorf Variation, Opocensky Variation, Traditional Line", "rnbq1rk1/1p2bppp/p2p1n2/4p3/4P3/1NN5/PPP1BPPP/R1BQ1RK1 w - -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f1e2 e7e5 d4b3 f8e7 e1g1 e8g8", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Be2 e5 7. Nb3 Be7 8. O-O O-O"),
  new FullOpening("B93", "Sicilian Defense: Najdorf Variation, Amsterdam Variation", "rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 f2f4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. f4"),
  new FullOpening("B94", "Sicilian Defense: Najdorf Variation", "rnbqkb1r/1p2pppp/p2p1n2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5"),
  new FullOpening("B94", "Sicilian Defense: Najdorf Variation, Ivkov Variation", "r3kb1r/1b3ppp/p2ppn2/qpn1P1B1/3N4/1BN5/PPPQ1PPP/2KRR3 b kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 b8d7 f1c4 d8a5 d1d2 e7e6 e1c1 b7b5 c4b3 c8b7 h1e1 d7c5 e4e5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 Nbd7 7. Bc4 Qa5 8. Qd2 e6 9. O-O-O b5 10. Bb3 Bb7 11. Rhe1 Nc5 12. e5"),
  new FullOpening("B95", "Sicilian Defense: Najdorf Variation", "rnbqkb1r/1p3ppp/p2ppn2/6B1/3NP3/2N5/PPP2PPP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6"),
  new FullOpening("B96", "Sicilian Defense: Najdorf Variation", "rnbqkb1r/1p3ppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4"),
  new FullOpening("B96", "Sicilian Defense: Najdorf Variation, Neo-Classical Defense", "r1bqkb1r/1p3ppp/p1nppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 b8c6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Nc6"),
  new FullOpening("B96", "Sicilian Defense: Najdorf Variation, Polugaevsky Variation", "rnbqkb1r/5ppp/p2ppn2/1p4B1/3NPP2/2N5/PPP3PP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 b7b5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 b5"),
  new FullOpening("B96", "Sicilian Defense: Najdorf Variation, Polugaevsky Variation, Simagin Line", "rnb1kb1r/2q2ppp/p3pn2/1p2P1B1/3N4/2N5/PPP1Q1PP/R3KB1R b KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 b7b5 e4e5 d6e5 f4e5 d8c7 d1e2", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 b5 8. e5 dxe5 9. fxe5 Qc7 10. Qe2"),
  new FullOpening("B97", "Sicilian Defense: Najdorf Variation, Poisoned Pawn Accepted", "rnb1kb1r/1p3ppp/p2ppn2/6B1/3NPP2/q1N5/P1PQ2PP/1R2KB1R w Kkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 d8b6 d1d2 b6b2 a1b1 b2a3", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Qb6 8. Qd2 Qxb2 9. Rb1 Qa3"),
  new FullOpening("B97", "Sicilian Defense: Najdorf Variation, Poisoned Pawn Variation", "rnb1kb1r/1p3ppp/pq1ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 d8b6", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Qb6"),
  new FullOpening("B98", "Sicilian Defense: Najdorf Variation", "rnbqk2r/1p2bppp/p2ppn2/6B1/3NPP2/2N5/PPP3PP/R2QKB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 f8e7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Be7"),
  new FullOpening("B98", "Sicilian Defense: Najdorf Variation, Browne Variation", "rnb1k2r/1pq1bpp1/p2ppn1p/8/3NPP1B/2N2Q2/PPP3PP/R3KB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 f8e7 d1f3 h7h6 g5h4 d8c7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Be7 8. Qf3 h6 9. Bh4 Qc7"),
  new FullOpening("B98", "Sicilian Defense: Najdorf Variation, Goteborg Variation", "rnbqk2r/1p2bp2/p2ppn1p/6p1/3NPP1B/2N2Q2/PPP3PP/R3KB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 f8e7 d1f3 h7h6 g5h4 g7g5", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Be7 8. Qf3 h6 9. Bh4 g5"),
  new FullOpening("B98", "Sicilian Defense: Najdorf Variation, Traditional Line", "rnb1k2r/1pq1bppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/R3KB1R w KQkq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 f8e7 d1f3 d8c7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Be7 8. Qf3 Qc7"),
  new FullOpening("B99", "Sicilian Defense: Najdorf Variation, Main Line", "r1b1k2r/1pqnbppp/p2ppn2/6B1/3NPP2/2N2Q2/PPP3PP/2KR1B1R w kq -", "e2e4 c7c5 g1f3 d7d6 d2d4 c5d4 f3d4 g8f6 b1c3 a7a6 c1g5 e7e6 f2f4 f8e7 d1f3 d8c7 e1c1 b8d7", "1. e4 c5 2. Nf3 d6 3. d4 cxd4 4. Nxd4 Nf6 5. Nc3 a6 6. Bg5 e6 7. f4 Be7 8. Qf3 Qc7 9. O-O-O Nbd7"),
  new FullOpening("C00", "French Defense", "rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 e7e6", "1. e4 e6"),
  new FullOpening("C00", "French Defense", "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5", "1. e4 e6 2. d4 d5"),
  new FullOpening("C00", "French Defense: Alapin Gambit", "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/4B3/PPP2PPP/RN1QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 c1e3", "1. e4 e6 2. d4 d5 3. Be3"),
  new FullOpening("C00", "French Defense: Baeuerle Gambit", "rnbqkbnr/p1pp1ppp/4p3/1p6/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 b7b5", "1. e4 e6 2. d4 b5"),
  new FullOpening("C00", "French Defense: Banzai-Leong Gambit", "rnbqkbnr/pppp1ppp/4p3/8/1P2P3/8/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 b2b4", "1. e4 e6 2. b4"),
  new FullOpening("C00", "French Defense: Banzai-Leong Gambit, Pinova Gambit", "rnbqk1nr/pppp1ppp/4p3/4P3/1b6/8/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 b2b4 f8b4 e4e5", "1. e4 e6 2. b4 Bxb4 3. e5"),
  new FullOpening("C00", "French Defense: Bird Invitation", "rnbqkbnr/pppp1ppp/4p3/1B6/4P3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 e7e6 f1b5", "1. e4 e6 2. Bb5"),
  new FullOpening("C00", "French Defense: Carlson Gambit", "rnbqkbnr/ppp2ppp/4p3/4N3/3Pp3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 g1f3 d5e4 f3e5", "1. e4 e6 2. d4 d5 3. Nf3 dxe4 4. Ne5"),
  new FullOpening("C00", "French Defense: Chigorin Variation", "rnbqkbnr/pppp1ppp/4p3/8/4P3/8/PPPPQPPP/RNB1KBNR b KQkq -", "e2e4 e7e6 d1e2", "1. e4 e6 2. Qe2"),
  new FullOpening("C00", "French Defense: Franco-Hiva Gambit", "rnbqkbnr/pppp2pp/4p3/5p2/4P3/3P4/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d3 f7f5", "1. e4 e6 2. d3 f5"),
  new FullOpening("C00", "French Defense: Franco-Hiva Gambit", "rnbqkbnr/pppp2pp/4p3/5p2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e6 g1f3 f7f5", "1. e4 e6 2. Nf3 f5"),
  new FullOpening("C00", "French Defense: Franco-Hiva Gambit Accepted", "rnbqkb1r/pppp2pp/4pn2/5P2/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 f7f5 e4f5 g8f6", "1. e4 e6 2. d4 f5 3. exf5 Nf6"),
  new FullOpening("C00", "French Defense: Hoffmann Gambit", "rnbqkbnr/ppp2ppp/8/3p4/3PPp2/8/PPP1Q1PP/RNB1KBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 d1e2 e6e5 f2f4 e5f4", "1. e4 e6 2. d4 d5 3. Qe2 e5 4. f4 exf4"),
  new FullOpening("C00", "French Defense: Horwitz Attack", "rnbqkbnr/pppp1ppp/4p3/8/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 b2b3", "1. e4 e6 2. b3"),
  new FullOpening("C00", "French Defense: Horwitz Attack, Papa-Ticulat Gambit", "rnbqkbnr/ppp2ppp/4p3/3p4/4P3/1P6/PBPP1PPP/RN1QKBNR b KQkq -", "e2e4 e7e6 b2b3 d7d5 c1b2", "1. e4 e6 2. b3 d5 3. Bb2"),
  new FullOpening("C00", "French Defense: King's Indian Attack", "rnbqkbnr/pppp1ppp/4p3/8/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d3", "1. e4 e6 2. d3"),
  new FullOpening("C00", "French Defense: Knight Variation", "rnbqkbnr/pppp1ppp/4p3/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 g1f3", "1. e4 e6 2. Nf3"),
  new FullOpening("C00", "French Defense: La Bourdonnais Variation", "rnbqkbnr/pppp1ppp/4p3/8/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -", "e2e4 e7e6 f2f4", "1. e4 e6 2. f4"),
  new FullOpening("C00", "French Defense: La Bourdonnais Variation, Reuter Gambit", "rnbqkbnr/ppp2ppp/4p3/8/4pP2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e6 f2f4 d7d5 g1f3 d5e4", "1. e4 e6 2. f4 d5 3. Nf3 dxe4"),
  new FullOpening("C00", "French Defense: Mediterranean Defense", "rnbqkb1r/pppp1ppp/4pn2/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 g8f6", "1. e4 e6 2. d4 Nf6"),
  new FullOpening("C00", "French Defense: Morphy Gambit", "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/7N/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 g1h3", "1. e4 e6 2. d4 d5 3. Nh3"),
  new FullOpening("C00", "French Defense: Normal Variation", "rnbqkbnr/pppp1ppp/4p3/8/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4", "1. e4 e6 2. d4"),
  new FullOpening("C00", "French Defense: Orthoschnapp Gambit", "rnbqkbnr/ppp2ppp/8/3p4/4P3/1Q6/PP1P1PPP/RNB1KBNR b KQkq -", "e2e4 e7e6 c2c4 d7d5 c4d5 e6d5 d1b3", "1. e4 e6 2. c4 d5 3. cxd5 exd5 4. Qb3"),
  new FullOpening("C00", "French Defense: Pelikan Variation", "rnbqkbnr/ppp2ppp/4p3/3p4/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e6 b1c3 d7d5 f2f4", "1. e4 e6 2. Nc3 d5 3. f4"),
  new FullOpening("C00", "French Defense: Perseus Gambit", "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 g1f3", "1. e4 e6 2. d4 d5 3. Nf3"),
  new FullOpening("C00", "French Defense: Queen's Knight", "rnbqkbnr/pppp1ppp/4p3/8/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 b1c3", "1. e4 e6 2. Nc3"),
  new FullOpening("C00", "French Defense: Reversed Philidor Formation", "r1bqkb1r/ppp2ppp/2n1pn2/3p4/4P3/3P1N2/PPPNBPPP/R1BQK2R b KQkq -", "e2e4 e7e6 d2d3 d7d5 b1d2 g8f6 g1f3 b8c6 f1e2", "1. e4 e6 2. d3 d5 3. Nd2 Nf6 4. Ngf3 Nc6 5. Be2"),
  new FullOpening("C00", "French Defense: Réti-Spielmann Attack", "rnbqkbnr/pppp1ppp/4p3/8/4P3/6P1/PPPP1P1P/RNBQKBNR b KQkq -", "e2e4 e7e6 g2g3", "1. e4 e6 2. g3"),
  new FullOpening("C00", "French Defense: Schlechter Variation", "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/3B4/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 e7e6 d2d4 d7d5 f1d3", "1. e4 e6 2. d4 d5 3. Bd3"),
  new FullOpening("C00", "French Defense: Steiner Variation", "rnbqkbnr/pppp1ppp/4p3/8/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 c2c4", "1. e4 e6 2. c4"),
  new FullOpening("C00", "French Defense: Steinitz Attack", "rnbqkbnr/pppp1ppp/4p3/4P3/8/8/PPPP1PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 e4e5", "1. e4 e6 2. e5"),
  new FullOpening("C00", "French Defense: Two Knights Variation", "rnbqkbnr/ppp2ppp/4p3/3p4/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e6 g1f3 d7d5 b1c3", "1. e4 e6 2. Nf3 d5 3. Nc3"),
  new FullOpening("C00", "French Defense: Wing Gambit", "rnbqkbnr/pp3ppp/4p3/2ppP3/1P6/5N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 g1f3 d7d5 e4e5 c7c5 b2b4", "1. e4 e6 2. Nf3 d5 3. e5 c5 4. b4"),
  new FullOpening("C00", "Queen's Pawn Game: Franco-Sicilian Defense", "rnbqkbnr/pp1p1ppp/4p3/2p5/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 c7c5", "1. e4 e6 2. d4 c5"),
  new FullOpening("C00", "Rat Defense: Small Center Defense", "rnbqkbnr/ppp2ppp/3pp3/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "d2d4 e7e6 e2e4 d7d6", "1. d4 e6 2. e4 d6"),
  new FullOpening("C00", "St. George Defense", "rnbqkbnr/1ppp1ppp/p3p3/8/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 a7a6", "1. e4 e6 2. d4 a6"),
  new FullOpening("C00", "St. George Defense: New St. George, Sanky-George Gambit", "rnbqkbnr/2pp1ppp/p3p3/1p6/2PPP3/8/PP3PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 a7a6 c2c4 b7b5", "1. e4 e6 2. d4 a6 3. c4 b5"),
  new FullOpening("C00", "St. George Defense: New St. George, Three Pawn Attack", "rnbqkbnr/1ppp1ppp/p3p3/8/2PPP3/8/PP3PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 a7a6 c2c4", "1. e4 e6 2. d4 a6 3. c4"),
  new FullOpening("C00", "St. George Defense: New St. George, Traditional Line", "rn1qkb1r/1b1p1ppp/p3pn2/1pp5/3PP3/2PB1N2/PP3PPP/RNBQ1RK1 w kq -", "e2e4 e7e6 d2d4 a7a6 g1f3 b7b5 f1d3 c7c5 c2c3 c8b7 e1g1 g8f6", "1. e4 e6 2. d4 a6 3. Nf3 b5 4. Bd3 c5 5. c3 Bb7 6. O-O Nf6"),
  new FullOpening("C00", "St. George Defense: St. George Gambit", "rnbqkbnr/2pp1ppp/4p3/1p6/3PP3/8/PP3PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 a7a6 c2c4 b7b5 c4b5 a6b5", "1. e4 e6 2. d4 a6 3. c4 b5 4. cxb5 axb5"),
  new FullOpening("C01", "French Defense: Exchange Variation", "rnbqkbnr/ppp2ppp/4p3/3P4/3P4/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4d5", "1. e4 e6 2. d4 d5 3. exd5"),
  new FullOpening("C01", "French Defense: Exchange Variation, Bogoljubov Variation", "r1bqkb1r/ppp2ppp/2n2n2/3p2B1/3P4/2N5/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 e4d5 e6d5 b1c3 g8f6 c1g5 b8c6", "1. e4 e6 2. d4 d5 3. exd5 exd5 4. Nc3 Nf6 5. Bg5 Nc6"),
  new FullOpening("C01", "French Defense: Exchange Variation, Monte Carlo Variation", "rnbqkbnr/ppp2ppp/8/3p4/2PP4/8/PP3PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4d5 e6d5 c2c4", "1. e4 e6 2. d4 d5 3. exd5 exd5 4. c4"),
  new FullOpening("C01", "French Defense: Exchange Variation, Svenonius Variation", "rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4d5 e6d5 c1g5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. exd5 exd5 5. Bg5"),
  new FullOpening("C02", "French Defense: Advance Variation", "rnbqkbnr/ppp2ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5", "1. e4 e6 2. d4 d5 3. e5"),
  new FullOpening("C02", "French Defense: Advance Variation", "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5", "1. e4 e6 2. d4 d5 3. e5 c5"),
  new FullOpening("C02", "French Defense: Advance Variation", "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3"),
  new FullOpening("C02", "French Defense: Advance Variation", "r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3 b8c6", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6"),
  new FullOpening("C02", "French Defense: Advance Variation, Euwe Variation", "r2qkbnr/pp1b1ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3 b8c6 g1f3 c8d7", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Bd7"),
  new FullOpening("C02", "French Defense: Advance Variation, Extended Bishop Swap", "rn1qkbnr/pppb1ppp/4p3/3pP3/3P4/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c8d7", "1. e4 e6 2. d4 d5 3. e5 Bd7"),
  new FullOpening("C02", "French Defense: Advance Variation, Frenkel Gambit", "rnbqkbnr/pp3ppp/4p3/2ppP3/1P1P4/8/P1P2PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 b2b4", "1. e4 e6 2. d4 d5 3. e5 c5 4. b4"),
  new FullOpening("C02", "French Defense: Advance Variation, Lputian Variation", "r1b1kb1r/pp3ppp/1qn1p2n/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3 b8c6 g1f3 d8b6 a2a3 g8h6", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. a3 Nh6"),
  new FullOpening("C02", "French Defense: Advance Variation, Main Line", "r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3 b8c6 g1f3 d8b6 a2a3", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. a3"),
  new FullOpening("C02", "French Defense: Advance Variation, Milner-Barry Gambit", "r1b1kbnr/pp3ppp/1qn1p3/2ppP3/3P4/2PB1N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3 b8c6 g1f3 d8b6 f1d3", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3 Qb6 6. Bd3"),
  new FullOpening("C02", "French Defense: Advance Variation, Nimzowitsch Attack", "rnbqkbnr/pp3ppp/4p3/2ppP3/3P2Q1/8/PPP2PPP/RNB1KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 d1g4", "1. e4 e6 2. d4 d5 3. e5 c5 4. Qg4"),
  new FullOpening("C02", "French Defense: Advance Variation, Nimzowitsch Gambit", "rnbqkbnr/pp3ppp/4p3/3pP3/3p2Q1/5N2/PPP2PPP/RNB1KB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 d1g4 c5d4 g1f3", "1. e4 e6 2. d4 d5 3. e5 c5 4. Qg4 cxd4 5. Nf3"),
  new FullOpening("C02", "French Defense: Advance Variation, Nimzowitsch System", "rnbqkbnr/pp3ppp/4p3/2ppP3/3P4/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 g1f3", "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3"),
  new FullOpening("C02", "French Defense: Advance Variation, Paulsen Attack", "r1bqkbnr/pp3ppp/2n1p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3 b8c6 g1f3", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Nc6 5. Nf3"),
  new FullOpening("C02", "French Defense: Advance Variation, Ruisdonk Gambit", "rnbqkbnr/pp3ppp/4p3/3pP3/3p4/3B1N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 g1f3 c5d4 f1d3", "1. e4 e6 2. d4 d5 3. e5 c5 4. Nf3 cxd4 5. Bd3"),
  new FullOpening("C02", "French Defense: Advance Variation, Steinitz Variation", "rnbqkbnr/pp3ppp/4p3/2PpP3/8/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 d4c5", "1. e4 e6 2. d4 d5 3. e5 c5 4. dxc5"),
  new FullOpening("C02", "French Defense: Advance Variation, Wade Variation", "rn2kbnr/pp1b1ppp/1q2p3/2ppP3/3P4/2P2N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 e4e5 c7c5 c2c3 d8b6 g1f3 c8d7", "1. e4 e6 2. d4 d5 3. e5 c5 4. c3 Qb6 5. Nf3 Bd7"),
  new FullOpening("C03", "French Defense: Guimard Variation, Thunderbunny Variation", "r1bqkbnr/ppp2ppp/2n5/4p3/3PN3/2P5/PP3PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 b8c6 c2c3 d5e4 d2e4 e6e5", "1. e4 e6 2. d4 d5 3. Nd2 Nc6 4. c3 dxe4 5. Nxe4 e5"),
  new FullOpening("C03", "French Defense: Tarrasch Variation", "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2", "1. e4 e6 2. d4 d5 3. Nd2"),
  new FullOpening("C03", "French Defense: Tarrasch Variation, Guimard Defense", "r1bqkbnr/ppp2ppp/2n1p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 b8c6", "1. e4 e6 2. d4 d5 3. Nd2 Nc6"),
  new FullOpening("C03", "French Defense: Tarrasch Variation, Haberditz Variation", "rnbqkbnr/ppp3pp/4p3/3p1p2/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 f7f5", "1. e4 e6 2. d4 d5 3. Nd2 f5"),
  new FullOpening("C03", "French Defense: Tarrasch Variation, Modern System", "rnbqkbnr/1pp2ppp/p3p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 a7a6", "1. e4 e6 2. d4 d5 3. Nd2 a6"),
  new FullOpening("C03", "French Defense: Tarrasch Variation, Morozevich Variation", "rnbqk1nr/ppp1bppp/4p3/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 f8e7", "1. e4 e6 2. d4 d5 3. Nd2 Be7"),
  new FullOpening("C04", "French Defense: Tarrasch Variation, Guimard Defense, Main Line", "r1bqkb1r/ppp2ppp/2n1pn2/3p4/3PP3/5N2/PPPN1PPP/R1BQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 b8c6 g1f3 g8f6", "1. e4 e6 2. d4 d5 3. Nd2 Nc6 4. Ngf3 Nf6"),
  new FullOpening("C05", "French Defense: Tarrasch Variation, Botvinnik Variation", "rnbqkb1r/p2n1ppp/1p2p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 g8f6 e4e5 f6d7 f1d3 c7c5 c2c3 b7b6", "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 b6"),
  new FullOpening("C05", "French Defense: Tarrasch Variation, Closed Variation", "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 g8f6", "1. e4 e6 2. d4 d5 3. Nd2 Nf6"),
  new FullOpening("C05", "French Defense: Tarrasch Variation, Closed Variation", "r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P4/2PB4/PP1N1PPP/R1BQK1NR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 g8f6 e4e5 f6d7 f1d3 c7c5 c2c3 b8c6", "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 Nc6"),
  new FullOpening("C05", "French Defense: Tarrasch Variation, Pawn Center Variation", "rnbqkb1r/pppn1ppp/4p3/3pP3/3P1P2/8/PPPN2PP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 g8f6 e4e5 f6d7 f2f4", "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. f4"),
  new FullOpening("C06", "French Defense: Tarrasch Variation, Closed Variation, Main Line", "r1bqkb1r/pp1n1ppp/2n1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 g8f6 e4e5 f6d7 f1d3 c7c5 c2c3 b8c6 g1e2 c5d4 c3d4", "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 Nc6 7. Ne2 cxd4 8. cxd4"),
  new FullOpening("C06", "French Defense: Tarrasch Variation, Leningrad Variation", "r1bqkb1r/pp3ppp/1nn1p3/3pP3/3P4/3B4/PP1NNPPP/R1BQK2R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 g8f6 e4e5 f6d7 f1d3 c7c5 c2c3 b8c6 g1e2 c5d4 c3d4 d7b6", "1. e4 e6 2. d4 d5 3. Nd2 Nf6 4. e5 Nfd7 5. Bd3 c5 6. c3 Nc6 7. Ne2 cxd4 8. cxd4 Nb6"),
  new FullOpening("C07", "French Defense: Tarrasch Variation, Chistyakov Defense", "rnb1kbnr/pp3ppp/4p3/2pq4/3P4/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 e4d5 d8d5", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5"),
  new FullOpening("C07", "French Defense: Tarrasch Variation, Chistyakov Defense, Modern Line", "r1b1kb1r/1p3ppp/p2qpn2/8/2BN4/8/PPP2PPP/R1BQ1RK1 w kq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 e4d5 d8d5 g1f3 c5d4 f1c4 d5d6 e1g1 g8f6 d2b3 b8c6 b3d4 c6d4 f3d4 a7a6", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5 5. Ngf3 cxd4 6. Bc4 Qd6 7. O-O Nf6 8. Nb3 Nc6 9. Nbxd4 Nxd4 10. Nxd4 a6"),
  new FullOpening("C07", "French Defense: Tarrasch Variation, Eliskases Variation", "rnbqkbnr/pp3ppp/4p3/8/2Bp4/5N2/PPPN1PPP/R1BQK2R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 e4d5 d8d5 g1f3 c5d4 f1c4 d5d8", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Qxd5 5. Ngf3 cxd4 6. Bc4 Qd8"),
  new FullOpening("C07", "French Defense: Tarrasch Variation, Open System", "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5", "1. e4 e6 2. d4 d5 3. Nd2 c5"),
  new FullOpening("C07", "French Defense: Tarrasch Variation, Open System, Euwe-Keres Line", "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/5N2/PPPN1PPP/R1BQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 g1f3", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. Ngf3"),
  new FullOpening("C07", "French Defense: Tarrasch Variation, Open System, Shaposhnikov Gambit", "rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 e4d5 g8f6", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 Nf6"),
  new FullOpening("C07", "French Defense: Tarrasch Variation, Open System, Süchting Line", "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/2P5/PP1N1PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 c2c3", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. c3"),
  new FullOpening("C08", "French Defense: Tarrasch Variation, Open System", "rnbqkbnr/pp3ppp/8/2pp4/3P4/8/PPPN1PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 e4d5 e6d5", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5"),
  new FullOpening("C08", "French Defense: Tarrasch Variation, Open System, Advance Line", "rnbqkbnr/pp3ppp/8/3p4/2pP4/5N2/PPPN1PPP/R1BQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 e4d5 e6d5 g1f3 c5c4", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5 5. Ngf3 c4"),
  new FullOpening("C09", "French Defense: Tarrasch Variation, Open System, Main Line", "r1bqkbnr/pp3ppp/2n5/2pp4/3P4/5N2/PPPN1PPP/R1BQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 c7c5 e4d5 e6d5 g1f3 b8c6", "1. e4 e6 2. d4 d5 3. Nd2 c5 4. exd5 exd5 5. Ngf3 Nc6"),
  new FullOpening("C10", "French Defense: Classical Variation, Svenonius Variation", "r1bqkbnr/ppp2ppp/2n1p3/3P4/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 b8c6 e4d5", "1. e4 e6 2. d4 d5 3. Nc3 Nc6 4. exd5"),
  new FullOpening("C10", "French Defense: Paulsen Variation", "rnbqkbnr/ppp2ppp/4p3/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3", "1. e4 e6 2. d4 d5 3. Nc3"),
  new FullOpening("C10", "French Defense: Rubinstein Variation", "rnbqkbnr/ppp2ppp/4p3/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 d5e4", "1. e4 e6 2. d4 d5 3. Nc3 dxe4"),
  new FullOpening("C10", "French Defense: Rubinstein Variation, Blackburne Defense", "r1bqkbnr/pppn1ppp/4p3/8/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 d5e4 c3e4 b8d7", "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7"),
  new FullOpening("C10", "French Defense: Rubinstein Variation, Capablanca Line", "r1bqkb1r/ppp2ppp/4pn2/4N3/3P4/8/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 d5e4 c3e4 b8d7 g1f3 g8f6 e4f6 d7f6 f3e5", "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Nd7 5. Nf3 Ngf6 6. Nxf6+ Nxf6 7. Ne5"),
  new FullOpening("C10", "French Defense: Rubinstein Variation, Ellis Gambit", "rnbqkbnr/ppp2ppp/8/4p3/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 d5e4 c3e4 e6e5", "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 e5"),
  new FullOpening("C10", "French Defense: Rubinstein Variation, Fort Knox Variation", "rn1qkbnr/ppp2ppp/2b1p3/8/3PN3/5N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 d5e4 c3e4 c8d7 g1f3 d7c6", "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Bd7 5. Nf3 Bc6"),
  new FullOpening("C10", "French Defense: Rubinstein Variation, Kasparov Attack", "r1bqkb1r/ppp2ppp/4pn2/8/3P4/2P2N2/PP3PPP/R1BQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1d2 d5e4 d2e4 b8d7 g1f3 g8f6 e4f6 d7f6 c2c3", "1. e4 e6 2. d4 d5 3. Nd2 dxe4 4. Nxe4 Nd7 5. Nf3 Ngf6 6. Nxf6+ Nxf6 7. c3"),
  new FullOpening("C10", "French Defense: Rubinstein Variation, Maric Variation", "rnb1kbnr/ppp2ppp/4p3/3q4/3PN3/8/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 d5e4 c3e4 d8d5", "1. e4 e6 2. d4 d5 3. Nc3 dxe4 4. Nxe4 Qd5"),
  new FullOpening("C10", "Sicilian Defense: Marshall Gambit", "rnbqkbnr/pp3ppp/4p3/2pp4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 c7c5 b1c3 e7e6 d2d4 d7d5", "1. e4 c5 2. Nc3 e6 3. d4 d5"),
  new FullOpening("C11", "French Defense: Burn Variation", "rnbqkb1r/ppp2ppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5"),
  new FullOpening("C11", "French Defense: Classical Variation", "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6"),
  new FullOpening("C11", "French Defense: Classical Variation, Burn Variation, Main Line", "rnbq1rk1/ppp2ppp/4pb2/8/3PN3/5N2/PPP2PPP/R2QKB1R w KQ -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 d5e4 c3e4 f8e7 g5f6 e7f6 g1f3 e8g8", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 dxe4 5. Nxe4 Be7 6. Bxf6 Bxf6 7. Nf3 O-O"),
  new FullOpening("C11", "French Defense: Classical Variation, Burn Variation, Morozevich Line", "rnbqk2r/ppp1bp1p/4pp2/8/3PN3/8/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 d5e4 c3e4 f8e7 g5f6 g7f6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 dxe4 5. Nxe4 Be7 6. Bxf6 gxf6"),
  new FullOpening("C11", "French Defense: Classical Variation, Delayed Exchange Variation", "rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4d5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. exd5"),
  new FullOpening("C11", "French Defense: Classical Variation, Steinitz Variation", "rnbqkb1r/ppp2ppp/4pn2/3pP3/3P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4e5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5"),
  new FullOpening("C11", "French Defense: Classical Variation, Swiss Variation", "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2NB4/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 f1d3", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bd3"),
  new FullOpening("C11", "French Defense: Henneberger Variation", "rnbqkb1r/ppp2ppp/4pn2/3p4/3PP3/2N1B3/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1e3", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Be3"),
  new FullOpening("C11", "French Defense: Steinitz Variation", "r1bqkb1r/pp1n1ppp/2n1p3/2PpP3/5P2/2N5/PPP3PP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 c7c5 d4c5 b8c6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. dxc5 Nc6"),
  new FullOpening("C11", "French Defense: Steinitz Variation", "rnbqkb1r/pp1n1ppp/4p3/2ppP3/3P1P2/2N2N2/PPP3PP/R1BQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 c7c5 g1f3", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. Nf3"),
  new FullOpening("C11", "French Defense: Steinitz Variation, Boleslavsky Variation", "r1bqkb1r/pp1n1ppp/2n1p3/2ppP3/3P1P2/2N1BN2/PPP3PP/R2QKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 c7c5 g1f3 b8c6 c1e3", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. Nf3 Nc6 7. Be3"),
  new FullOpening("C11", "French Defense: Steinitz Variation, Bradford Attack Variation", "rnbqk2r/pp1n1ppp/4p3/2bpP3/5PQ1/2N5/PPP3PP/R1B1KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 c7c5 d4c5 f8c5 d1g4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. dxc5 Bxc5 7. Qg4"),
  new FullOpening("C11", "French Defense: Steinitz Variation, Brodsky-Jones Variation", "r1bq1rk1/pp1n2pp/2n1pp2/2bpP3/5PQ1/P1N2N2/1PP3PP/R1B1KB1R w KQ -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4e5 f6d7 f2f4 c7c5 d4c5 b8c6 a2a3 f8c5 d1g4 e8g8 g1f3 f7f6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. f4 c5 6. dxc5 Nc6 7. a3 Bxc5 8. Qg4 O-O 9. Nf3 f6"),
  new FullOpening("C11", "French Defense: Steinitz Variation, Gledhill Attack", "rnbqkb1r/pppn1ppp/4p3/3pP3/3P2Q1/2N5/PPP2PPP/R1B1KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 e4e5 f6d7 d1g4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. e5 Nfd7 5. Qg4"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation", "rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Advance Variation", "rnbqk2r/ppp2ppp/4pn2/3pP1B1/1b1P4/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Bernstein Variation", "rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P3B/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5 h7h6 g5h4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bh4"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Bogoljubov Variation", "rnb1k2r/ppp2p1p/4pp2/q7/1b1P4/2N5/PPPQ1PPP/R3KBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4d5 d8d5 g5f6 g7f6 d1d2 d5a5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. exd5 Qxd5 6. Bxf6 gxf6 7. Qd2 Qa5"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Chigorin Variation", "rnbqk2r/ppp2pp1/4pP1p/3p2B1/1b1P4/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5 h7h6 e5f6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. exf6"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Dr. Olland Variation", "rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5 h7h6 g5c1", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bc1"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Duras Variation", "rnbq1k1r/ppp2pp1/4p2p/3pP3/3Pn1Q1/2P5/P1P2PPP/R1B1KBNR b KQ -", "e2e4 e7e6 b1c3 d7d5 d2d4 g8f6 c1g5 f8b4 e4e5 h7h6 g5d2 b4c3 b2c3 f6e4 d1g4 e8f8 d2c1", "1. e4 e6 2. Nc3 d5 3. d4 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Bxc3 7. bxc3 Ne4 8. Qg4 Kf8 9. Bc1"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Exchange Variation", "rnbqk2r/ppp2ppp/4pn2/3P2B1/1b1P4/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4d5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. exd5"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Grigoriev Variation", "rnbqk1r1/ppp2pP1/4p3/3p4/1b1P2Qp/2N5/PPP2PP1/R3KBNR b KQq -", "d2d4 d7d5 b1c3 g8f6 c1g5 e7e6 e2e4 f8b4 e4e5 h7h6 e5f6 h6g5 f6g7 h8g8 h2h4 g5h4 d1g4", "1. d4 d5 2. Nc3 Nf6 3. Bg5 e6 4. e4 Bb4 5. e5 h6 6. exf6 hxg5 7. fxg7 Rg8 8. h4 gxh4 9. Qg4"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Janowski Variation", "rnbqk2r/ppp2pp1/4pn1p/3pP3/1b1P4/2N1B3/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5 h7h6 g5e3", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Be3"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Lasker Variation", "rnbqk2r/ppp2pp1/4pn1p/3pP3/3P4/2b5/PPPB1PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5 h7h6 g5d2 b4c3", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Bxc3"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Lasker Variation", "rnbqk2r/ppp2p2/4p1pp/3pP3/3Pn1Q1/2P5/P1PB1PPP/R3KBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5 h7h6 g5d2 b4c3 b2c3 f6e4 d1g4 g7g6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Bxc3 7. bxc3 Ne4 8. Qg4 g6"),
  new FullOpening("C12", "French Defense: MacCutcheon Variation, Tartakower Variation", "rnbqk2r/pppn1pp1/4p2p/3pP3/1b1P4/2N5/PPPB1PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8b4 e4e5 h7h6 g5d2 f6d7", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Bb4 5. e5 h6 6. Bd2 Nfd7"),
  new FullOpening("C13", "French Defense: Alekhine-Chatard Attack", "rnbqk2r/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 h2h4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4"),
  new FullOpening("C13", "French Defense: Alekhine-Chatard Attack, Albin-Chatard Gambit", "rnb1k2r/pppn1ppp/4p3/3pP1q1/3P4/2N5/PPP2PP1/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 h2h4 e7g5 h4g5 d8g5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 Bxg5 7. hxg5 Qxg5"),
  new FullOpening("C13", "French Defense: Alekhine-Chatard Attack, Breyer Variation", "rnbqk2r/pp1nbppp/4p3/2ppP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 h2h4 c7c5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 c5"),
  new FullOpening("C13", "French Defense: Alekhine-Chatard Attack, Maróczy Variation", "rnbqk2r/1ppnbppp/p3p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 h2h4 a7a6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 a6"),
  new FullOpening("C13", "French Defense: Alekhine-Chatard Attack, Spielmann Variation", "rnbq1rk1/pppnbppp/4p3/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQ -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 h2h4 e8g8", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 O-O"),
  new FullOpening("C13", "French Defense: Alekhine-Chatard Attack, Teichmann Variation", "rnbqk2r/pppnb1pp/4pp2/3pP1B1/3P3P/2N5/PPP2PP1/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 h2h4 f7f6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. h4 f6"),
  new FullOpening("C13", "French Defense: Classical Variation, Frankfurt Variation", "rnbqk1nr/p1p1bppp/1p2p3/3pP3/3P4/2N1B3/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6g8 g5e3 b7b6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ng8 6. Be3 b6"),
  new FullOpening("C13", "French Defense: Classical Variation, Normal Variation", "rnbqk2r/ppp1bppp/4pn2/3p2B1/3PP3/2N5/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7"),
  new FullOpening("C13", "French Defense: Classical Variation, Richter Attack", "rnbqk2r/ppp1bppp/4pB2/3p4/3PP3/2N5/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 g5f6", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6"),
  new FullOpening("C13", "French Defense: Classical Variation, Richter Attack", "rnbqk2r/ppp1bppp/4p3/3pP3/3P2Q1/2N5/PPP2PPP/R3KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 g5f6 e7f6 e4e5 f6e7 d1g4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6 Bxf6 6. e5 Be7 7. Qg4"),
  new FullOpening("C13", "French Defense: Classical Variation, Tartakower Variation", "rnbqk2r/ppp1bppp/4p3/3pP1B1/3Pn3/2N5/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6e4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ne4"),
  new FullOpening("C13", "French Defense: Classical Variation, Vistaneckis Variation", "rnbqk1nr/ppp1bppp/4p3/3pP1B1/3P4/2N5/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6g8", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Ng8"),
  new FullOpening("C14", "French Defense: Classical Variation", "rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPP2PPP/R2QKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 g5e7 d8e7", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7"),
  new FullOpening("C14", "French Defense: Classical Variation, Alapin Variation", "rnb1k2r/pppnqppp/4p3/1N1pP3/3P4/8/PPP2PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 g5e7 d8e7 c3b5", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. Nb5"),
  new FullOpening("C14", "French Defense: Classical Variation, Pollock Variation", "rnb1k2r/pppnqppp/4p3/3pP3/3P2Q1/2N5/PPP2PPP/R3KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 g5e7 d8e7 d1g4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. Qg4"),
  new FullOpening("C14", "French Defense: Classical Variation, Rubinstein Variation", "rnb1k2r/pppnqppp/4p3/3pP3/3P4/2N5/PPPQ1PPP/R3KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 g5e7 d8e7 d1d2", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. Qd2"),
  new FullOpening("C14", "French Defense: Classical Variation, Stahlberg Variation", "r1b2rk1/pp1nqppp/2n1p3/3pP3/2pP1P2/2N2N2/PPPQ2PP/2KR1B1R w - -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 g5e7 d8e7 f2f4 e8g8 g1f3 c7c5 d1d2 b8c6 e1c1 c5c4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. f4 O-O 8. Nf3 c5 9. Qd2 Nc6 10. O-O-O c4"),
  new FullOpening("C14", "French Defense: Classical Variation, Steinitz Variation", "rnb1k2r/pppnqppp/4p3/3pP3/3P1P2/2N5/PPP3PP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 g5e7 d8e7 f2f4", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. f4"),
  new FullOpening("C14", "French Defense: Classical Variation, Tarrasch Variation", "rnb1k2r/pppnqppp/4p3/3pP3/3P4/2NB4/PPP2PPP/R2QK1NR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 g8f6 c1g5 f8e7 e4e5 f6d7 g5e7 d8e7 f1d3", "1. e4 e6 2. d4 d5 3. Nc3 Nf6 4. Bg5 Be7 5. e5 Nfd7 6. Bxe7 Qxe7 7. Bd3"),
  new FullOpening("C15", "French Defense: MacCutcheon Variation, Wolf Gambit", "rnbqk2r/ppp2ppp/4pn2/3p2B1/1b1PP3/2N5/PPP1NPPP/R2QKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 g1e2 g8f6 c1g5", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 Nf6 5. Bg5"),
  new FullOpening("C15", "French Defense: Winawer Variation", "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4", "1. e4 e6 2. d4 d5 3. Nc3 Bb4"),
  new FullOpening("C15", "French Defense: Winawer Variation, Alekhine Gambit Accepted", "rnbqk1nr/ppp2ppp/4p3/8/3Pp3/P1b5/1PP1NPPP/R1BQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 g1e2 d5e4 a2a3 b4c3", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 dxe4 5. a3 Bxc3+"),
  new FullOpening("C15", "French Defense: Winawer Variation, Alekhine Gambit, Alatortsev Variation", "r1bq1rk1/ppp1bppp/2n1pn2/8/3PN3/P5N1/1PP1BPPP/R1BQK2R w KQ -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 g1e2 d5e4 a2a3 b4e7 c3e4 g8f6 e2g3 e8g8 f1e2 b8c6", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 dxe4 5. a3 Be7 6. Nxe4 Nf6 7. N2g3 O-O 8. Be2 Nc6"),
  new FullOpening("C15", "French Defense: Winawer Variation, Alekhine Gambit, Kan Variation", "r1bqk1nr/ppp2ppp/2n1p3/8/3Pp3/P1N5/1PP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 g1e2 d5e4 a2a3 b4c3 e2c3 b8c6", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2 dxe4 5. a3 Bxc3+ 6. Nxc3 Nc6"),
  new FullOpening("C15", "French Defense: Winawer Variation, Alekhine-Maróczy Gambit", "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPP1NPPP/R1BQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 g1e2", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Ne2"),
  new FullOpening("C15", "French Defense: Winawer Variation, Delayed Exchange Variation", "rnbqk1nr/ppp2ppp/4p3/3P4/1b1P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4d5", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. exd5"),
  new FullOpening("C15", "French Defense: Winawer Variation, Exchange Variation, Canal Attack", "rnbqk2r/ppp1nppp/8/3p3Q/1b1P4/2NB4/PPP2PPP/R1B1K1NR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4d5 e6d5 f1d3 g8e7 d1h5", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. exd5 exd5 5. Bd3 Ne7 6. Qh5"),
  new FullOpening("C15", "French Defense: Winawer Variation, Fingerslip Variation", "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/2N5/PPPB1PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 c1d2", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2"),
  new FullOpening("C15", "French Defense: Winawer Variation, Fingerslip Variation, Kunin Double Gambit", "rnb1k1nr/ppp2ppp/4p3/8/1b1qp1Q1/2N5/PPPB1PPP/R3KBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 c1d2 d5e4 d1g4 d8d4", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2 dxe4 5. Qg4 Qxd4"),
  new FullOpening("C15", "French Defense: Winawer Variation, Fingerslip Variation, Main Line", "rnbqk1r1/ppp2p1p/4pn1Q/8/1b1Pp3/2N5/PPPB1PPP/R3KBNR b KQq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 c1d2 d5e4 d1g4 g8f6 g4g7 h8g8 g7h6", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2 dxe4 5. Qg4 Nf6 6. Qxg7 Rg8 7. Qh6"),
  new FullOpening("C15", "French Defense: Winawer Variation, Fingerslip Variation, Schwarz's Line", "rnbqk2r/ppp1nppp/4p3/3p4/1b1PP3/8/PPPB1PPP/RN1QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 c1d2 g8e7 c3b1", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd2 Ne7 5. Nb1"),
  new FullOpening("C15", "French Defense: Winawer Variation, Kondratiyev Variation", "rnb1k1nr/pp3ppp/4p3/2pq4/1b1P4/2NB4/PPPB1PPP/R2QK1NR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 f1d3 c7c5 e4d5 d8d5 c1d2", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. Bd3 c5 5. exd5 Qxd5 6. Bd2"),
  new FullOpening("C15", "French Defense: Winawer Variation, Winckelmann-Riemer Gambit", "rnbqk1nr/ppp2ppp/4p3/3p4/1b1PP3/P1N5/1PP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 a2a3", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. a3"),
  new FullOpening("C16", "French Defense: Winawer Variation, Advance Variation", "rnbqk1nr/ppp2ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5"),
  new FullOpening("C16", "French Defense: Winawer Variation, Petrosian Variation", "rnb1k1nr/pppq1ppp/4p3/3pP3/1b1P4/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 d8d7", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 Qd7"),
  new FullOpening("C17", "French Defense: Winawer Variation, Advance Variation", "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5"),
  new FullOpening("C17", "French Defense: Winawer Variation, Advance Variation", "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/P1N5/1PP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3"),
  new FullOpening("C17", "French Defense: Winawer Variation, Advance Variation", "rnbqk1nr/pp3ppp/4p3/3pP3/1P6/2p2N2/1PP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 c5d4 a3b4 d4c3 g1f3", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 cxd4 6. axb4 dxc3 7. Nf3"),
  new FullOpening("C17", "French Defense: Winawer Variation, Advance Variation, Moscow Variation", "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P2Q1/2N5/PPP2PPP/R1B1KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 d1g4", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Qg4"),
  new FullOpening("C17", "French Defense: Winawer Variation, Bogoljubov Variation", "rnbqk1nr/pp3ppp/4p3/2ppP3/1b1P4/2N5/PPPB1PPP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 c1d2", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Bd2"),
  new FullOpening("C17", "French Defense: Winawer Variation, Bogoljubov Variation, Icelandic Defense", "rnbqk2r/pp2nppp/4p3/2ppP3/1b1P1P2/2N5/PPPB2PP/R2QKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 c1d2 g8e7 f2f4", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. Bd2 Ne7 6. f4"),
  new FullOpening("C17", "French Defense: Winawer Variation, Maróczy-Wallis Variation", "rnbqk1nr/pp3ppp/4p3/3pP3/1P6/2p5/1PP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 c5d4 a3b4 d4c3", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 cxd4 6. axb4 dxc3"),
  new FullOpening("C17", "French Defense: Winawer Variation, Retreat Variation", "rnbqk1nr/pp3ppp/4p3/b1ppP3/3P4/P1N5/1PP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4a5", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Ba5"),
  new FullOpening("C17", "French Defense: Winawer Variation, Retreat Variation, Armenian Line", "rnbqk1nr/pp3ppp/4p3/b2pP3/1P1p4/P1N5/2P2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4a5 b2b4 c5d4", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Ba5 6. b4 cxd4"),
  new FullOpening("C18", "French Defense: Winawer Variation, Advance Variation", "rnbqk1nr/pp3ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3"),
  new FullOpening("C18", "French Defense: Winawer Variation, Classical Variation", "rnb1k1nr/ppq2ppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3 d8c7", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Qc7"),
  new FullOpening("C19", "French Defense: Winawer Variation, Advance Variation", "rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P5/2P2PPP/R1BQKBNR w KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3 g8e7", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7"),
  new FullOpening("C19", "French Defense: Winawer Variation, Advance Variation", "rnbqk2r/pp2nppp/4p3/2ppP3/P2P4/2P5/2P2PPP/R1BQKBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3 g8e7 a3a4", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7 7. a4"),
  new FullOpening("C19", "French Defense: Winawer Variation, Poisoned Pawn Variation", "rnbqk2r/pp2nppp/4p3/2ppP3/3P2Q1/P1P5/2P2PPP/R1B1KBNR b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3 g8e7 d1g4", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7 7. Qg4"),
  new FullOpening("C19", "French Defense: Winawer Variation, Poisoned Pawn Variation, Main Line", "rnb1k1r1/ppq1np1Q/4p3/3pP3/3p4/P1P5/2P1NPPP/R1B1KB1R b KQq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3 g8e7 d1g4 d8c7 g4g7 h8g8 g7h7 c5d4 g1e2", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7 7. Qg4 Qc7 8. Qxg7 Rg8 9. Qxh7 cxd4 10. Ne2"),
  new FullOpening("C19", "French Defense: Winawer Variation, Poisoned Pawn Variation, Paoli Variation", "rnb1k1r1/ppq1np1Q/4p3/3pP3/3p4/P1P5/2P2PPP/R1BK1BNR b q -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3 g8e7 d1g4 d8c7 g4g7 h8g8 g7h7 c5d4 e1d1", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7 7. Qg4 Qc7 8. Qxg7 Rg8 9. Qxh7 cxd4 10. Kd1"),
  new FullOpening("C19", "French Defense: Winawer Variation, Positional Variation", "rnbqk2r/pp2nppp/4p3/2ppP3/3P4/P1P2N2/2P2PPP/R1BQKB1R b KQkq -", "e2e4 e7e6 d2d4 d7d5 b1c3 f8b4 e4e5 c7c5 a2a3 b4c3 b2c3 g8e7 g1f3", "1. e4 e6 2. d4 d5 3. Nc3 Bb4 4. e5 c5 5. a3 Bxc3+ 6. bxc3 Ne7 7. Nf3"),
  new FullOpening("C20", "Barnes Opening: Walkerling", "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/5P2/PPPP2PP/RNBQK1NR b KQkq -", "f2f3 e7e5 e2e4 g8f6 f1c4", "1. f3 e5 2. e4 Nf6 3. Bc4"),
  new FullOpening("C20", "Bongcloud Attack", "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPPKPPP/RNBQ1BNR b kq -", "e2e4 e7e5 e1e2", "1. e4 e5 2. Ke2"),
  new FullOpening("C20", "Center Game", "rnbqkbnr/pppp1ppp/8/4p3/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e5 d2d4", "1. e4 e5 2. d4"),
  new FullOpening("C20", "English Opening: The Whale", "rnbqkbnr/pppp1ppp/8/4p3/2P1P3/8/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 e7e5 c2c4", "1. e4 e5 2. c4"),
  new FullOpening("C20", "King's Pawn Game", "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -", "e2e4 e7e5", "1. e4 e5"),
  new FullOpening("C20", "King's Pawn Game: Alapin Opening", "rnbqkbnr/pppp1ppp/8/4p3/4P3/8/PPPPNPPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1e2", "1. e4 e5 2. Ne2"),
  new FullOpening("C20", "King's Pawn Game: Bavarian Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/2P1P3/8/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 c2c4 d7d5", "1. e4 e5 2. c4 d5"),
  new FullOpening("C20", "King's Pawn Game: Beyer Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/3PP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d4 d7d5", "1. e4 e5 2. d4 d5"),
  new FullOpening("C20", "King's Pawn Game: Clam Variation, King's Gambit Reversed", "rnbqkbnr/pppp2pp/8/4pp2/4P3/3P4/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d3 f7f5", "1. e4 e5 2. d3 f5"),
  new FullOpening("C20", "King's Pawn Game: Clam Variation, Radisch Gambit", "rnbqk2r/pppp1ppp/5n2/2b1p3/4PP2/3P4/PPP3PP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d3 g8f6 f2f4 f8c5", "1. e4 e5 2. d3 Nf6 3. f4 Bc5"),
  new FullOpening("C20", "King's Pawn Game: King's Head Opening", "rnbqkbnr/pppp1ppp/8/4p3/4P3/5P2/PPPP2PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f3", "1. e4 e5 2. f3"),
  new FullOpening("C20", "King's Pawn Game: King's Head Opening", "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N2P2/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 f2f3 g8f6 b1c3", "1. e4 e5 2. f3 Nf6 3. Nc3"),
  new FullOpening("C20", "King's Pawn Game: Leonardis Variation", "rnbqkbnr/pppp1ppp/8/4p3/4P3/3P4/PPP2PPP/RNBQKBNR b KQkq -", "e2e4 e7e5 d2d3", "1. e4 e5 2. d3"),
  new FullOpening("C20", "King's Pawn Game: Macleod Attack", "rnbqkbnr/pppp1ppp/8/4p3/4P3/2P5/PP1P1PPP/RNBQKBNR b KQkq -", "e2e4 e7e5 c2c3", "1. e4 e5 2. c3"),
  new FullOpening("C20", "King's Pawn Game: Macleod Attack, Lasa Gambit", "rnbqkbnr/pppp2pp/8/4pp2/4P3/2P5/PP1P1PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 c2c3 f7f5", "1. e4 e5 2. c3 f5"),
  new FullOpening("C20", "King's Pawn Game: Macleod Attack, Norwalde Gambit", "rnbqk1nr/ppp2ppp/3b4/3pp2Q/4P3/2P5/PP1P1PPP/RNB1KBNR w KQkq -", "e2e4 e7e5 c2c3 d7d5 d1h5 f8d6", "1. e4 e5 2. c3 d5 3. Qh5 Bd6"),
  new FullOpening("C20", "King's Pawn Game: Mengarini's Opening", "rnbqkbnr/pppp1ppp/8/4p3/4P3/P7/1PPP1PPP/RNBQKBNR b KQkq -", "e2e4 e7e5 a2a3", "1. e4 e5 2. a3"),
  new FullOpening("C20", "King's Pawn Game: Napoleon Attack", "rnbqkbnr/pppp1ppp/8/4p3/4P3/5Q2/PPPP1PPP/RNB1KBNR b KQkq -", "e2e4 e7e5 d1f3", "1. e4 e5 2. Qf3"),
  new FullOpening("C20", "King's Pawn Game: Philidor Gambit", "rn1qkbnr/pppb1ppp/3p4/4P3/4P3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d4 d7d6 d4e5 c8d7", "1. e4 e5 2. d4 d6 3. dxe5 Bd7"),
  new FullOpening("C20", "King's Pawn Game: Tortoise Opening", "rnbqkbnr/pppp1ppp/8/4p3/4P3/3B4/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1d3", "1. e4 e5 2. Bd3"),
  new FullOpening("C20", "King's Pawn Game: Wayward Queen Attack", "rnbqkbnr/pppp1ppp/8/4p2Q/4P3/8/PPPP1PPP/RNB1KBNR b KQkq -", "e2e4 e7e5 d1h5", "1. e4 e5 2. Qh5"),
  new FullOpening("C20", "King's Pawn Game: Wayward Queen Attack, Kiddie Countergambit", "rnbqkb1r/pppp1ppp/5n2/4p2Q/4P3/8/PPPP1PPP/RNB1KBNR w KQkq -", "e2e4 e7e5 d1h5 g8f6", "1. e4 e5 2. Qh5 Nf6"),
  new FullOpening("C20", "King's Pawn Game: Wayward Queen Attack, Mellon Gambit", "r1bqkb1r/ppp4p/2n2ppn/3pp3/2B1P3/3P1Q2/PPP1NPPP/RNB1K2R w KQkq -", "e2e4 e7e5 d1h5 b8c6 f1c4 g8h6 d2d3 g7g6 h5f3 f7f6 g1e2 d7d5", "1. e4 e5 2. Qh5 Nc6 3. Bc4 Nh6 4. d3 g6 5. Qf3 f6 6. Ne2 d5"),
  new FullOpening("C20", "King's Pawn Game: Weber Gambit", "r1bqkbnr/pp3ppp/2n5/4p3/8/3P4/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d3 d7d5 e4d5 c7c6 d5c6 b8c6", "1. e4 e5 2. d3 d5 3. exd5 c6 4. dxc6 Nxc6"),
  new FullOpening("C20", "King's Pawn Opening", "rnbqkbnr/pppp1ppp/8/4p3/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq -", "e2e4 e7e5 b2b3", "1. e4 e5 2. b3"),
  new FullOpening("C20", "King's Pawn Opening: Speers", "rnbqkb1r/pppp1ppp/5n2/4pQ2/4P3/8/PPPP1PPP/RNB1KBNR b KQkq -", "e2e4 e7e5 d1g4 g8f6 g4f5", "1. e4 e5 2. Qg4 Nf6 3. Qf5"),
  new FullOpening("C20", "King's Pawn Opening: Van Hooydoon Gambit", "r1bqk2r/pppp1ppp/5n2/2b5/3nP3/5N2/PP2QPPP/RNB1KB1R w KQkq -", "e2e4 e7e5 d1e2 b8c6 c2c3 g8f6 g1f3 f8c5 d2d4 e5d4 c3d4 c6d4", "1. e4 e5 2. Qe2 Nc6 3. c3 Nf6 4. Nf3 Bc5 5. d4 exd4 6. cxd4 Nxd4"),
  new FullOpening("C20", "Portuguese Opening", "rnbqkbnr/pppp1ppp/8/1B2p3/4P3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1b5", "1. e4 e5 2. Bb5"),
  new FullOpening("C20", "Portuguese Opening: Miguel Gambit", "rnbqk1nr/pppp1ppp/8/1Bb1p3/1P2P3/8/P1PP1PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1b5 f8c5 b2b4", "1. e4 e5 2. Bb5 Bc5 3. b4"),
  new FullOpening("C20", "Portuguese Opening: Portuguese Gambit", "rnbqkb1r/pppp1ppp/5n2/1B2p3/3PP3/8/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1b5 g8f6 d2d4", "1. e4 e5 2. Bb5 Nf6 3. d4"),
  new FullOpening("C21", "Center Game", "rnbqkbnr/pppp1ppp/8/8/3QP3/8/PPP2PPP/RNB1KBNR b KQkq -", "e2e4 e7e5 d2d4 e5d4 d1d4", "1. e4 e5 2. d4 exd4 3. Qxd4"),
  new FullOpening("C21", "Center Game Accepted", "rnbqkbnr/pppp1ppp/8/8/3pP3/8/PPP2PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d4 e5d4", "1. e4 e5 2. d4 exd4"),
  new FullOpening("C21", "Center Game: Halasz-McDonnell Gambit", "rnbqkbnr/pppp1ppp/8/8/3pPP2/8/PPP3PP/RNBQKBNR b KQkq -", "e2e4 e7e5 d2d4 e5d4 f2f4", "1. e4 e5 2. d4 exd4 3. f4"),
  new FullOpening("C21", "Center Game: Halasz-McDonnell Gambit, Crocodile Variation", "r1bqk1nr/pppp1ppp/2n5/2b5/3pPP2/2P2N2/PP4PP/RNBQKB1R b KQkq -", "e2e4 e7e5 d2d4 e5d4 f2f4 f8c5 g1f3 b8c6 c2c3", "1. e4 e5 2. d4 exd4 3. f4 Bc5 4. Nf3 Nc6 5. c3"),
  new FullOpening("C21", "Center Game: Kieseritzky Variation", "rnbqkbnr/pppp1ppp/8/8/3pP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 d2d4 e5d4 g1f3", "1. e4 e5 2. d4 exd4 3. Nf3"),
  new FullOpening("C21", "Center Game: Kieseritzky Variation", "rnbqkbnr/pp1p1ppp/8/2p5/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 d2d4 e5d4 g1f3 c7c5", "1. e4 e5 2. d4 exd4 3. Nf3 c5"),
  new FullOpening("C21", "Center Game: Kieseritzky Variation", "rnbqkbnr/pp1p1ppp/8/2p5/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 d2d4 e5d4 g1f3 c7c5 f1c4", "1. e4 e5 2. d4 exd4 3. Nf3 c5 4. Bc4"),
  new FullOpening("C21", "Center Game: Kieseritzky Variation", "rnbqkbnr/p2p1ppp/8/1pp5/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 d2d4 e5d4 g1f3 c7c5 f1c4 b7b5", "1. e4 e5 2. d4 exd4 3. Nf3 c5 4. Bc4 b5"),
  new FullOpening("C21", "Center Game: Lanc-Arnold Gambit", "rnbqk1nr/pppp1ppp/8/2b5/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 d2d4 e5d4 g1f3 f8c5 c2c3", "1. e4 e5 2. d4 exd4 3. Nf3 Bc5 4. c3"),
  new FullOpening("C21", "Center Game: Lanc-Arnold Gambit, Schippler Gambit", "rnbqk1nr/pppp1ppp/8/2b5/2B1P3/2p2N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 d2d4 e5d4 g1f3 f8c5 c2c3 d4c3 f1c4", "1. e4 e5 2. d4 exd4 3. Nf3 Bc5 4. c3 dxc3 5. Bc4"),
  new FullOpening("C21", "Center Game: Ross Gambit", "rnbqkbnr/pppp1ppp/8/8/3pP3/3B4/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 d2d4 e5d4 f1d3", "1. e4 e5 2. d4 exd4 3. Bd3"),
  new FullOpening("C21", "Center Game: von der Lasa Gambit", "rnbqkbnr/pppp1ppp/8/8/2BpP3/8/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 d2d4 e5d4 f1c4", "1. e4 e5 2. d4 exd4 3. Bc4"),
  new FullOpening("C21", "Danish Gambit", "rnbqkbnr/pppp1ppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR b KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3", "1. e4 e5 2. d4 exd4 3. c3"),
  new FullOpening("C21", "Danish Gambit Accepted", "rnbqkbnr/pppp1ppp/8/8/2B1P3/8/PB3PPP/RN1QK1NR b KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3 d4c3 f1c4 c3b2 c1b2", "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2"),
  new FullOpening("C21", "Danish Gambit Accepted: Chigorin Defense", "rnb1kbnr/ppppqppp/8/8/2B1P3/8/PB3PPP/RN1QK1NR w KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3 d4c3 f1c4 c3b2 c1b2 d8e7", "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Qe7"),
  new FullOpening("C21", "Danish Gambit Accepted: Classical Defense", "rnbqkb1r/pppp1ppp/5n2/8/2B1P3/8/PB3PPP/RN1QK1NR w KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3 d4c3 f1c4 c3b2 c1b2 g8f6", "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Nf6"),
  new FullOpening("C21", "Danish Gambit Accepted: Copenhagen Defense", "rnbqk1nr/pppp1ppp/8/8/1bB1P3/8/PB3PPP/RN1QK1NR w KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3 d4c3 f1c4 c3b2 c1b2 f8b4", "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 Bb4+"),
  new FullOpening("C21", "Danish Gambit Accepted: Schlechter Defense", "rnbqkbnr/ppp2ppp/8/3p4/2B1P3/8/PB3PPP/RN1QK1NR w KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3 d4c3 f1c4 c3b2 c1b2 d7d5", "1. e4 e5 2. d4 exd4 3. c3 dxc3 4. Bc4 cxb2 5. Bxb2 d5"),
  new FullOpening("C21", "Danish Gambit Accepted: Svenonius Defense", "rnbqkb1r/ppppnppp/8/8/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3 g8e7", "1. e4 e5 2. d4 exd4 3. c3 Ne7"),
  new FullOpening("C21", "Danish Gambit Declined: Sorensen Defense", "rnbqkbnr/ppp2ppp/8/3p4/3pP3/2P5/PP3PPP/RNBQKBNR w KQkq -", "e2e4 e7e5 d2d4 e5d4 c2c3 d7d5", "1. e4 e5 2. d4 exd4 3. c3 d5"),
  new FullOpening("C22", "Center Game: Berger Variation", "r1bqkb1r/pppp1ppp/2n2n2/8/4P3/4Q3/PPP2PPP/RNB1KBNR w KQkq -", "e2e4 e7e5 d2d4 e5d4 d1d4 b8c6 d4e3 g8f6", "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6"),
  new FullOpening("C22", "Center Game: Charousek Variation", "r1bqk1nr/ppppbppp/2n5/8/4P3/2P1Q3/PP3PPP/RNB1KBNR w KQkq -", "e2e4 e7e5 d2d4 e5d4 d1d4 b8c6 d4e3 f8b4 c2c3 b4e7", "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Bb4+ 5. c3 Be7"),
  new FullOpening("C22", "Center Game: Hall Variation", "r1bqkbnr/pppp1ppp/2n5/8/2Q1P3/8/PPP2PPP/RNB1KBNR b KQkq -", "e2e4 e7e5 d2d4 e5d4 d1d4 b8c6 d4c4", "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qc4"),
  new FullOpening("C22", "Center Game: Kupreichik Variation", "r1bqr1k1/ppp2ppp/2np1n2/8/1bB1P3/2N1Q2N/PPPB1PPP/2KR3R b - -", "e2e4 e7e5 d2d4 e5d4 d1d4 b8c6 d4e3 g8f6 b1c3 f8b4 c1d2 e8g8 e1c1 f8e8 f1c4 d7d6 g1h3", "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 Nf6 5. Nc3 Bb4 6. Bd2 O-O 7. O-O-O Re8 8. Bc4 d6 9. Nh3"),
  new FullOpening("C22", "Center Game: Normal Variation", "r1bqkbnr/pppp1ppp/2n5/8/3QP3/8/PPP2PPP/RNB1KBNR w KQkq -", "e2e4 e7e5 d2d4 e5d4 d1d4 b8c6", "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6"),
  new FullOpening("C22", "Center Game: Paulsen Attack Variation", "r1bqkbnr/pppp1ppp/2n5/8/4P3/4Q3/PPP2PPP/RNB1KBNR b KQkq -", "e2e4 e7e5 d2d4 e5d4 d1d4 b8c6 d4e3", "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3"),
  new FullOpening("C22", "Center Game: l'Hermet Variation", "r1bqkbnr/pppp2pp/2n5/5p2/4P3/4Q3/PPP2PPP/RNB1KBNR w KQkq -", "e2e4 e7e5 d2d4 e5d4 d1d4 b8c6 d4e3 f7f5", "1. e4 e5 2. d4 exd4 3. Qxd4 Nc6 4. Qe3 f5"),
  new FullOpening("C23", "Bishop's Opening", "rnbqkbnr/pppp1ppp/8/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4", "1. e4 e5 2. Bc4"),
  new FullOpening("C23", "Bishop's Opening: Anderssen Gambit", "rnbqkbnr/p2p1ppp/2p5/1B2p3/4P3/8/PPPP1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 b7b5 c4b5 c7c6", "1. e4 e5 2. Bc4 b5 3. Bxb5 c6"),
  new FullOpening("C23", "Bishop's Opening: Boi Variation", "rnbqk1nr/pppp1ppp/8/2b1p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 f8c5", "1. e4 e5 2. Bc4 Bc5"),
  new FullOpening("C23", "Bishop's Opening: Calabrese Countergambit", "rnbqkbnr/pppp2pp/8/4pp2/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 f7f5", "1. e4 e5 2. Bc4 f5"),
  new FullOpening("C23", "Bishop's Opening: Calabrese Countergambit, Jaenisch Variation", "rnbqkbnr/pppp2pp/8/4pp2/2B1P3/3P4/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f7f5 d2d3", "1. e4 e5 2. Bc4 f5 3. d3"),
  new FullOpening("C23", "Bishop's Opening: Four Pawns Gambit", "rnbqk1nr/pppp1ppp/8/8/2BPP2b/5N2/P1P4p/RNBQ1R1K b kq -", "e2e4 e7e5 f1c4 f8c5 b2b4 c5b4 f2f4 e5f4 g1f3 b4e7 d2d4 e7h4 g2g3 f4g3 e1g1 g3h2 g1h1", "1. e4 e5 2. Bc4 Bc5 3. b4 Bxb4 4. f4 exf4 5. Nf3 Be7 6. d4 Bh4+ 7. g3 fxg3 8. O-O gxh2+ 9. Kh1"),
  new FullOpening("C23", "Bishop's Opening: Khan Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 d7d5", "1. e4 e5 2. Bc4 d5"),
  new FullOpening("C23", "Bishop's Opening: Lewis Countergambit", "rnbqk1nr/ppp2ppp/8/2bpp3/2B1P3/2P5/PP1P1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 f8c5 c2c3 d7d5", "1. e4 e5 2. Bc4 Bc5 3. c3 d5"),
  new FullOpening("C23", "Bishop's Opening: Lewis Countergambit", "rnbqk2r/ppp2ppp/5n2/2bBp3/4P3/2P5/PP1P1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 f8c5 c2c3 d7d5 c4d5 g8f6", "1. e4 e5 2. Bc4 Bc5 3. c3 d5 4. Bxd5 Nf6"),
  new FullOpening("C23", "Bishop's Opening: Lewis Countergambit, Walker Variation", "rnbqk2r/ppp2ppp/5n2/2bBp3/3PP3/2P5/PP3PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 c2c3 d7d5 c4d5 g8f6 d2d4", "1. e4 e5 2. Bc4 Bc5 3. c3 d5 4. Bxd5 Nf6 5. d4"),
  new FullOpening("C23", "Bishop's Opening: Lewis Gambit", "rnbqk1nr/pppp1ppp/8/2b1p3/2BPP3/8/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 d2d4", "1. e4 e5 2. Bc4 Bc5 3. d4"),
  new FullOpening("C23", "Bishop's Opening: Lisitsyn Variation", "r2qkbnr/pp3ppp/8/3pn3/8/8/PPP1NPPP/RNBQK2R b KQkq -", "e2e4 e7e5 f1c4 c7c6 d2d4 d7d5 e4d5 c6d5 c4b5 c8d7 b5d7 b8d7 d4e5 d7e5 g1e2", "1. e4 e5 2. Bc4 c6 3. d4 d5 4. exd5 cxd5 5. Bb5+ Bd7 6. Bxd7+ Nxd7 7. dxe5 Nxe5 8. Ne2"),
  new FullOpening("C23", "Bishop's Opening: Lopez Gambit", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1PP2/2P5/PP1PQ1PP/RNB1K1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 d1e2 b8c6 c2c3 g8f6 f2f4", "1. e4 e5 2. Bc4 Bc5 3. Qe2 Nc6 4. c3 Nf6 5. f4"),
  new FullOpening("C23", "Bishop's Opening: Lopez Variation", "rnbqk1nr/pppp1ppp/8/2b1p3/2B1P3/8/PPPPQPPP/RNB1K1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 d1e2", "1. e4 e5 2. Bc4 Bc5 3. Qe2"),
  new FullOpening("C23", "Bishop's Opening: Lopez Variation, Lopez Gambit", "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1PP2/8/PPPPQ1PP/RNB1K1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 d1e2 g8f6 f2f4", "1. e4 e5 2. Bc4 Bc5 3. Qe2 Nf6 4. f4"),
  new FullOpening("C23", "Bishop's Opening: McDonnell Gambit", "rnbqk1nr/pppp1ppp/8/2b1p3/1PB1P3/8/P1PP1PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 b2b4", "1. e4 e5 2. Bc4 Bc5 3. b4"),
  new FullOpening("C23", "Bishop's Opening: McDonnell Gambit, La Bourdonnais-Denker Gambit", "rnbqk1nr/pppp1ppp/8/4p3/1bB1P3/2P5/P2P1PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 b2b4 c5b4 c2c3", "1. e4 e5 2. Bc4 Bc5 3. b4 Bxb4 4. c3"),
  new FullOpening("C23", "Bishop's Opening: McDonnell Gambit, McDonnell Double Gambit", "rnbqk1nr/pppp1ppp/8/4p3/1bB1PP2/8/P1PP2PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 b2b4 c5b4 f2f4", "1. e4 e5 2. Bc4 Bc5 3. b4 Bxb4 4. f4"),
  new FullOpening("C23", "Bishop's Opening: Philidor Counterattack", "rnbqkbnr/pp1p1ppp/2p5/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 c7c6", "1. e4 e5 2. Bc4 c6"),
  new FullOpening("C23", "Bishop's Opening: Philidor Variation", "rnbqk1nr/pppp1ppp/8/2b1p3/2B1P3/2P5/PP1P1PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 c2c3", "1. e4 e5 2. Bc4 Bc5 3. c3"),
  new FullOpening("C23", "Bishop's Opening: Pratt Variation", "rnbq1rk1/ppp2ppp/5P2/2b4Q/2pp4/2P5/PP3PPP/RNB1K1NR w KQ -", "e2e4 e7e5 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 e4e5 d7d5 e5f6 d5c4 d1h5 e8g8", "1. e4 e5 2. Bc4 Bc5 3. c3 Nf6 4. d4 exd4 5. e5 d5 6. exf6 dxc4 7. Qh5 O-O"),
  new FullOpening("C23", "Bishop's Opening: Stein Gambit", "rnbqk1nr/pppp1ppp/8/2b1p3/2B1PP2/8/PPPP2PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 f8c5 f2f4", "1. e4 e5 2. Bc4 Bc5 3. f4"),
  new FullOpening("C23", "Bishop's Opening: Thorold Gambit", "rnbqkbnr/p1pp2pp/8/1B2pp2/4P3/8/PPPP1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 b7b5 c4b5 f7f5", "1. e4 e5 2. Bc4 b5 3. Bxb5 f5"),
  new FullOpening("C23", "Bishop's Opening: del Rio Variation", "rnb1k1nr/pppp1ppp/8/2b1p1q1/2B1P3/2P5/PP1P1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 f8c5 c2c3 d8g5", "1. e4 e5 2. Bc4 Bc5 3. c3 Qg5"),
  new FullOpening("C24", "Bishop's Opening: Berlin Defense", "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/8/PPPP1PPP/RNBQK1NR w KQkq -", "e2e4 e7e5 f1c4 g8f6", "1. e4 e5 2. Bc4 Nf6"),
  new FullOpening("C24", "Bishop's Opening: Berlin Defense, Greco Gambit", "rnbqkb1r/pppp1ppp/5n2/4p3/2B1PP2/8/PPPP2PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 g8f6 f2f4", "1. e4 e5 2. Bc4 Nf6 3. f4"),
  new FullOpening("C24", "Bishop's Opening: Kitchener Folly", "rnbq1rk1/ppppbppp/5n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R w KQ -", "e2e4 e7e5 f1c4 g8f6 d2d3 f8e7 g1f3 e8g8", "1. e4 e5 2. Bc4 Nf6 3. d3 Be7 4. Nf3 O-O"),
  new FullOpening("C24", "Bishop's Opening: Krejcik Gambit", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/1PB1P3/5P2/P1PPN1PP/RNBQK2R b KQkq -", "e2e4 e7e5 f1c4 g8f6 f2f3 f8c5 g1e2 b8c6 b2b4", "1. e4 e5 2. Bc4 Nf6 3. f3 Bc5 4. Ne2 Nc6 5. b4"),
  new FullOpening("C24", "Bishop's Opening: Pachman Gambit", "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 f1c4 g8f6 g1e2 f6e4 e2c3", "1. e4 e5 2. Bc4 Nf6 3. Ne2 Nxe4 4. Nec3"),
  new FullOpening("C24", "Bishop's Opening: Ponziani Gambit", "rnbqkb1r/pppp1ppp/5n2/4p3/2BPP3/8/PPP2PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 g8f6 d2d4", "1. e4 e5 2. Bc4 Nf6 3. d4"),
  new FullOpening("C24", "Bishop's Opening: Urusov Gambit, Panov Variation", "rnb1k2r/ppp1qppp/5n2/3P4/1bBp4/2P2N2/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 f1c4 g8f6 d2d4 e5d4 g1f3 d7d5 e4d5 f8b4 c2c3 d8e7", "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3 d5 5. exd5 Bb4+ 6. c3 Qe7+"),
  new FullOpening("C24", "Bishop's Opening: Vienna Hybrid", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 e7e5 f1c4 g8f6 d2d3 b8c6 b1c3", "1. e4 e5 2. Bc4 Nf6 3. d3 Nc6 4. Nc3"),
  new FullOpening("C24", "Bishop's Opening: Warsaw Gambit", "rnbqkb1r/pppp1ppp/5n2/8/2BpP3/2P5/PP3PPP/RNBQK1NR b KQkq -", "e2e4 e7e5 f1c4 g8f6 d2d4 e5d4 c2c3", "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. c3"),
  new FullOpening("C25", "Vienna Game", "rnbqkbnr/pppp1ppp/8/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3", "1. e4 e5 2. Nc3"),
  new FullOpening("C25", "Vienna Game: Anderssen Defense", "rnbqk1nr/pppp1ppp/8/2b1p3/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 e7e5 b1c3 f8c5", "1. e4 e5 2. Nc3 Bc5"),
  new FullOpening("C25", "Vienna Game: Fyfe Gambit", "r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 b8c6 d2d4", "1. e4 e5 2. Nc3 Nc6 3. d4"),
  new FullOpening("C25", "Vienna Game: Giraffe Attack", "rnbqk1nr/pppp1ppp/8/2b1p3/4P1Q1/2N5/PPPP1PPP/R1B1KBNR b KQkq -", "e2e4 e7e5 b1c3 f8c5 d1g4", "1. e4 e5 2. Nc3 Bc5 3. Qg4"),
  new FullOpening("C25", "Vienna Game: Hamppe-Meitner Variation", "rnbqk1nr/pppp1ppp/8/2b1p3/N3P3/8/PPPP1PPP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 f8c5 c3a4", "1. e4 e5 2. Nc3 Bc5 3. Na4"),
  new FullOpening("C25", "Vienna Game: Hamppe-Muzio Gambit", "r1bqkbnr/pppp1p1p/2n5/8/2B1Ppp1/2N2N2/PPPP2PP/R1BQ1RK1 b kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. Bc4 g4 6. O-O"),
  new FullOpening("C25", "Vienna Game: Hamppe-Muzio, Dubois Variation", "r1b1kbnr/pppp1p1p/5q2/4n3/2B1PQ2/2N5/PPPP2PP/R1B2RK1 w kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 c6e5 f3f4 d8f6", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. Bc4 g4 6. O-O gxf3 7. Qxf3 Ne5 8. Qxf4 Qf6"),
  new FullOpening("C25", "Vienna Game: Max Lange Defense", "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 e7e5 b1c3 b8c6", "1. e4 e5 2. Nc3 Nc6"),
  new FullOpening("C25", "Vienna Game: Omaha Gambit", "rnbqkbnr/ppp2ppp/3p4/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 d7d6 f2f4", "1. e4 e5 2. Nc3 d6 3. f4"),
  new FullOpening("C25", "Vienna Game: Paulsen Variation", "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 b8c6 g2g3", "1. e4 e5 2. Nc3 Nc6 3. g3"),
  new FullOpening("C25", "Vienna Game: Paulsen Variation", "r1bqk2r/ppp2ppp/2n2n2/2bpp3/4P3/2N3P1/PPPPNPBP/R1BQK2R w KQkq -", "e2e4 e7e5 b1c3 b8c6 g2g3 g8f6 f1g2 f8c5 g1e2 d7d5", "1. e4 e5 2. Nc3 Nc6 3. g3 Nf6 4. Bg2 Bc5 5. Nge2 d5"),
  new FullOpening("C25", "Vienna Game: Paulsen Variation, Mariotti Gambit", "r1bqk1nr/pppp1pp1/2n5/2b1p3/4P2p/2N2NP1/PPPP1PBP/R1BQK2R w KQkq -", "e2e4 e7e5 b1c3 b8c6 g2g3 f8c5 f1g2 h7h5 g1f3 h5h4", "1. e4 e5 2. Nc3 Nc6 3. g3 Bc5 4. Bg2 h5 5. Nf3 h4"),
  new FullOpening("C25", "Vienna Game: Philidor Countergambit", "r1bqkbnr/pppp2pp/2n5/4pp2/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "e2e4 e7e5 b1c3 b8c6 d2d4 f7f5", "1. e4 e5 2. Nc3 Nc6 3. d4 f5"),
  new FullOpening("C25", "Vienna Game: Pierce Gambit, Rushmere Attack", "r2qkbnr/ppp2p1p/2P5/8/2BP1pb1/2N2p2/PPP3PP/R1BQ1RK1 b kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 g7g5 d2d4 g5g4 f1c4 g4f3 e1g1 d7d5 e4d5 c8g4 d5c6", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. d4 g4 6. Bc4 gxf3 7. O-O d5 8. exd5 Bg4 9. dxc6"),
  new FullOpening("C25", "Vienna Game: Stanley Variation, Meitner-Mieses Gambit", "r1b1k1nr/pppp1ppp/2n2q2/2bNp3/2B1P1Q1/8/PPPP1PPP/R1B1K1NR b KQkq -", "e2e4 e7e5 b1c3 b8c6 f1c4 f8c5 d1g4 d8f6 c3d5", "1. e4 e5 2. Nc3 Nc6 3. Bc4 Bc5 4. Qg4 Qf6 5. Nd5"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit", "r1bqkbnr/pppp1ppp/2n5/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4", "1. e4 e5 2. Nc3 Nc6 3. f4"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Cunningham Defense", "r1bqk1nr/ppppbppp/2n5/8/4Pp2/2N2N2/PPPP2PP/R1BQKB1R w KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 f8e7", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 Be7"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Hamppe-Allgaier Gambit", "r1bqkbnr/pppp1p1p/2n5/6N1/4PppP/2N5/PPPP2P1/R1BQKB1R b KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. h4 g4 6. Ng5"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Hamppe-Allgaier Gambit, Alapin Variation", "r1bqkbnr/ppp2p1p/2np4/6N1/4PppP/2N5/PPPP2P1/R1BQKB1R w KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 d7d6", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. h4 g4 6. Ng5 d6"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Hamppe-Allgaier Gambit, Thorold Variation", "r1bq1bnr/pppp1k2/2n4p/8/3PPppP/2N5/PPP3P1/R1BQKB1R b KQ -", "e2e4 e7e5 f2f4 e5f4 g1f3 b8c6 b1c3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7 e8f7 d2d4", "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. Nc3 g5 5. h4 g4 6. Ng5 h6 7. Nxf7 Kxf7 8. d4"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Hamppe-Muzio Gambit", "r1bqkbnr/pppp1p1p/2n5/8/2B1Pp2/2N2p2/PPPP2PP/R1BQ1RK1 w kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. Bc4 g4 6. O-O gxf3"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Pierce Gambit", "r1bqkbnr/pppp1p1p/2n5/6p1/3PPp2/2N2N2/PPP3PP/R1BQKB1R b KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3 g7g5 d2d4", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3 g5 5. d4"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Quelle Gambit", "r1bqk1nr/ppp2ppp/2np4/2b1P3/4P3/2N5/PPPP2PP/R1BQKBNR w KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4 f8c5 f4e5 d7d6", "1. e4 e5 2. Nc3 Nc6 3. f4 Bc5 4. fxe5 d6"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Steinitz Gambit", "r1bqkbnr/pppp1ppp/2n5/8/3PPp2/2N5/PPP3PP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 d2d4", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Steinitz Gambit, Fraser-Minckwitz Defense", "r1b1kbnr/p1pp1ppp/1pn5/8/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 d2d4 d8h4 e1e2 b7b6", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 b6"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Steinitz Gambit, Knight Variation", "r1bqkbnr/pppp1ppp/2n5/8/4Pp2/2N2N2/PPPP2PP/R1BQKB1R b KQkq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 g1f3", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. Nf3"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Steinitz Gambit, Main Line", "r1b1kbnr/pppp1ppp/2n5/8/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR b kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 d2d4 d8h4 e1e2", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Steinitz Gambit, Paulsen Defense", "r1b1kbnr/ppp2ppp/2np4/8/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 d2d4 d8h4 e1e2 d7d6", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 d6"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Steinitz Gambit, Sörensen Defense", "r1b1kbnr/pppp1p1p/2n5/6p1/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 d2d4 d8h4 e1e2 g7g5", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 g5"),
  new FullOpening("C25", "Vienna Game: Vienna Gambit, Steinitz Gambit, Zukertort Defense", "r1b1kbnr/ppp2ppp/2n5/3p4/3PPp1q/2N5/PPP1K1PP/R1BQ1BNR w kq -", "e2e4 e7e5 b1c3 b8c6 f2f4 e5f4 d2d4 d8h4 e1e2 d7d5", "1. e4 e5 2. Nc3 Nc6 3. f4 exf4 4. d4 Qh4+ 5. Ke2 d5"),
  new FullOpening("C25", "Vienna Game: Zhuravlev Countergambit", "rnbqk2r/pppp1ppp/5n2/4p3/1b2P1Q1/2N5/PPPP1PPP/R1B1KBNR w KQkq -", "e2e4 e7e5 b1c3 f8b4 d1g4 g8f6", "1. e4 e5 2. Nc3 Bb4 3. Qg4 Nf6"),
  new FullOpening("C26", "Bishop's Opening: Horwitz Gambit", "rnbqkb1r/p1pp1ppp/5n2/1p2p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -", "e2e4 e7e5 f1c4 g8f6 b1c3 b7b5", "1. e4 e5 2. Bc4 Nf6 3. Nc3 b5"),
  new FullOpening("C26", "Bishop's Opening: Vienna Hybrid, Spielmann Attack", "rnbqk2r/pppp1ppp/5n2/2b1p3/2B1P3/2NP4/PPP2PPP/R1BQK1NR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4 f8c5 d2d3", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bc5 4. d3"),
  new FullOpening("C26", "Vienna Game: Falkbeer Variation", "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N5/PPPP1PPP/R1BQKBNR w KQkq -", "e2e4 e7e5 b1c3 g8f6", "1. e4 e5 2. Nc3 Nf6"),
  new FullOpening("C26", "Vienna Game: Mengarini Variation", "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/P1N5/1PPP1PPP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 g8f6 a2a3", "1. e4 e5 2. Nc3 Nf6 3. a3"),
  new FullOpening("C26", "Vienna Game: Mieses Variation", "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N3P1/PPPP1P1P/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 g8f6 g2g3", "1. e4 e5 2. Nc3 Nf6 3. g3"),
  new FullOpening("C26", "Vienna Game: Mieses Variation, Erben Gambit", "rnbqkb1r/pp3ppp/2p2n2/3Pp3/8/2N3P1/PPPP1P1P/R1BQKBNR w KQkq -", "e2e4 e7e5 b1c3 g8f6 g2g3 d7d5 e4d5 c7c6", "1. e4 e5 2. Nc3 Nf6 3. g3 d5 4. exd5 c6"),
  new FullOpening("C26", "Vienna Game: Paulsen Variation, Pollock Gambit", "r1bqk2r/ppp2ppp/2n2n2/2bPp3/8/2N3P1/PPPPNPBP/R1BQK2R b KQkq -", "e2e4 e7e5 b1c3 g8f6 g2g3 f8c5 f1g2 b8c6 g1e2 d7d5 e4d5", "1. e4 e5 2. Nc3 Nf6 3. g3 Bc5 4. Bg2 Nc6 5. Nge2 d5 6. exd5"),
  new FullOpening("C26", "Vienna Game: Stanley Variation", "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4", "1. e4 e5 2. Nc3 Nf6 3. Bc4"),
  new FullOpening("C26", "Vienna Game: Stanley Variation, Eifel Gambit", "rnbqk2r/p1pp1ppp/5n2/1pb1p3/2B1P3/2N5/PPPPNPPP/R1BQK2R w KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4 f8c5 g1e2 b7b5", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bc5 4. Nge2 b5"),
  new FullOpening("C26", "Vienna Game: Stanley Variation, Reversed Spanish", "rnbqk2r/pppp1ppp/5n2/4p3/1bB1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4 f8b4", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Bb4"),
  new FullOpening("C27", "Bishop's Opening: Boden-Kieseritzky Gambit", "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f1c4 f6e4 b1c3", "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Nc3"),
  new FullOpening("C27", "Bishop's Opening: Boden-Kieseritzky Gambit, Lichtenhein Defense", "rnbqkb1r/ppp2ppp/8/3pp3/2B1n3/2N2N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f1c4 f6e4 b1c3 d7d5", "1. e4 e5 2. Nf3 Nf6 3. Bc4 Nxe4 4. Nc3 d5"),
  new FullOpening("C27", "Vienna Game: Adams' Gambit", "r1bqkb1r/pppp1ppp/2nn4/4p2Q/3P4/1BN5/PPP2PPP/R1B1K1NR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4 f6e4 d1h5 e4d6 c4b3 b8c6 d2d4", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Nc6 6. d4"),
  new FullOpening("C27", "Vienna Game: Frankenstein-Dracula Variation", "rnbqkb1r/pppp1ppp/8/4p3/2B1n3/2N5/PPPP1PPP/R1BQK1NR w KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4 f6e4", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4"),
  new FullOpening("C27", "Vienna Game: Stanley Variation, Alekhine Variation", "r1bqk2r/ppppbppp/2nn4/4N2Q/8/1BN5/PPPP1PPP/R1B1K2R b KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4 f6e4 d1h5 e4d6 c4b3 f8e7 g1f3 b8c6 f3e5", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Be7 6. Nf3 Nc6 7. Nxe5"),
  new FullOpening("C27", "Vienna Game: Stanley Variation, Frankenstein-Dracula Variation", "N1bk1b1r/p2pq2p/1pnn2p1/3Qpp2/8/1B6/PPPP1PPP/R1B1K1NR w KQ -", "e2e4 e7e5 b1c3 g8f6 f1c4 f6e4 d1h5 e4d6 c4b3 b8c6 c3b5 g7g6 h5f3 f7f5 f3d5 d8e7 b5c7 e8d8 c7a8 b7b6", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Nc6 6. Nb5 g6 7. Qf3 f5 8. Qd5 Qe7 9. Nxc7+ Kd8 10. Nxa8 b6"),
  new FullOpening("C27", "Vienna Game: Stanley Variation, Monster Declined", "rnbqk2r/ppppbppp/3n4/4p2Q/8/1BN5/PPPP1PPP/R1B1K1NR w KQkq -", "e2e4 e7e5 b1c3 g8f6 f1c4 f6e4 d1h5 e4d6 c4b3 f8e7", "1. e4 e5 2. Nc3 Nf6 3. Bc4 Nxe4 4. Qh5 Nd6 5. Bb3 Be7"),
  new FullOpening("C28", "Bishop's Opening: Vienna Hybrid, Hromádka Variation", "r1bqk2r/pppp1ppp/2n2n2/4p3/1bB1P3/2NP4/PPP1NPPP/R1BQK2R b KQkq -", "e2e4 e7e5 b1c3 b8c6 f1c4 g8f6 d2d3 f8b4 g1e2", "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. d3 Bb4 5. Ne2"),
  new FullOpening("C28", "Vienna Game: Stanley Variation, Bronstein Gambit", "r1bqkb1r/pppp1ppp/2n5/4p3/2B1nP2/2N2N2/PPPP2PP/R1BQK2R b KQkq -", "e2e4 e7e5 b1c3 b8c6 f1c4 g8f6 f2f4 f6e4 g1f3", "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6 4. f4 Nxe4 5. Nf3"),
  new FullOpening("C28", "Vienna Game: Stanley Variation, Three Knights Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N5/PPPP1PPP/R1BQK1NR w KQkq -", "e2e4 e7e5 b1c3 b8c6 f1c4 g8f6", "1. e4 e5 2. Nc3 Nc6 3. Bc4 Nf6"),
  new FullOpening("C29", "Vienna Game: Heyde Variation", "rnbqkb1r/ppp3pp/8/3pPp2/3Pn3/2N2Q2/PPP3PP/R1B1KBNR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 f4e5 f6e4 d1f3 f7f5 d2d4", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3 f5 6. d4"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit", "rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4", "1. e4 e5 2. Nc3 Nf6 3. f4"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Bardeleben Variation", "rnbqkb1r/ppp3pp/8/3pPp2/4n3/2N2Q2/PPPP2PP/R1B1KBNR w KQkq f6", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 f4e5 f6e4 d1f3 f7f5", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3 f5"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Breyer Variation", "rnbqk2r/ppp1bppp/8/3pP3/4n3/2N2N2/PPPP2PP/R1BQKB1R w KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 f4e5 f6e4 g1f3 f8e7", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Nf3 Be7"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Kaufmann Variation", "rn1qkb1r/ppp2ppp/8/3pP3/4n1b1/2N2N2/PPPPQ1PP/R1B1KB1R b KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 f4e5 f6e4 g1f3 c8g4 d1e2", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Nf3 Bg4 6. Qe2"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Main Line", "rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR w KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5", "1. e4 e5 2. Nc3 Nf6 3. f4 d5"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Modern Variation", "rnbqkb1r/ppp2ppp/8/3pP3/4n3/2NP4/PPP3PP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 f4e5 f6e4 d2d3", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. d3"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Paulsen Attack", "rnbqkb1r/ppp2ppp/8/3pP3/4n3/2N2Q2/PPPP2PP/R1B1KBNR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 f4e5 f6e4 d1f3", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. Qf3"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Steinitz Variation", "rnbqkb1r/ppp2ppp/5n2/3pp3/4PP2/2NP4/PPP3PP/R1BQKBNR b KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 d2d3", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. d3"),
  new FullOpening("C29", "Vienna Game: Vienna Gambit, Wurzburger Trap", "rnb1kb1r/ppp2ppp/8/3NP2q/8/3P1Nn1/PPP4P/R1BQKB1R b KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 f4e5 f6e4 d2d3 d8h4 g2g3 e4g3 g1f3 h4h5 c3d5", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. fxe5 Nxe4 5. d3 Qh4+ 6. g3 Nxg3 7. Nf3 Qh5 8. Nxd5"),
  new FullOpening("C30", "King's Gambit", "rnbqkbnr/pppp1ppp/8/4p3/4PP2/8/PPPP2PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4", "1. e4 e5 2. f4"),
  new FullOpening("C30", "King's Gambit Declined: Classical Variation", "rnbqk1nr/pppp1ppp/8/2b1p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 f8c5", "1. e4 e5 2. f4 Bc5"),
  new FullOpening("C30", "King's Gambit Declined: Classical Variation", "rnbqk1nr/ppp2ppp/3p4/2b1p3/4PP2/2P2N2/PP1P2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 d7d6 c2c3", "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3"),
  new FullOpening("C30", "King's Gambit Declined: Classical Variation, Euwe Attack", "rn1qk1nr/ppp2ppp/8/2b1p3/Q3P1b1/2P2N2/PP1P2PP/RNB1KB1R b KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 d7d6 c2c3 c8g4 f4e5 d6e5 d1a4", "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3 Bg4 5. fxe5 dxe5 6. Qa4+"),
  new FullOpening("C30", "King's Gambit Declined: Classical Variation, Rotlewi Countergambit", "rnbqk1nr/ppp2ppp/3p4/2b1p3/1P2PP2/5N2/P1PP2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 d7d6 b2b4", "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. b4"),
  new FullOpening("C30", "King's Gambit Declined: Classical Variation, Rubinstein Countergambit", "rnbqk1nr/ppp3pp/3p4/2b1pp2/4PP2/2P2N2/PP1P2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 d7d6 c2c3 f7f5", "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3 f5"),
  new FullOpening("C30", "King's Gambit Declined: Classical, Hanham Variation", "r1bqk1nr/pppn1ppp/3p4/2b1p3/4PP2/2N2N2/PPPP2PP/R1BQKB1R w KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 d7d6 b1c3 b8d7", "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. Nc3 Nd7"),
  new FullOpening("C30", "King's Gambit Declined: Classical, Réti Variation", "rnbqk1nr/ppp3pp/8/2b2p2/2BpP3/2P2N2/PP4PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 d7d6 c2c3 f7f5 f4e5 d6e5 d2d4 e5d4 f1c4", "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. c3 f5 5. fxe5 dxe5 6. d4 exd4 7. Bc4"),
  new FullOpening("C30", "King's Gambit Declined: Classical, Soldatenkov Variation", "rnbqk1nr/ppp2ppp/3p4/2b1P3/4P3/5N2/PPPP2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 d7d6 f4e5", "1. e4 e5 2. f4 Bc5 3. Nf3 d6 4. fxe5"),
  new FullOpening("C30", "King's Gambit Declined: Classical, Svenonius Variation", "r2qk2r/ppp2ppp/2np1n2/2b5/2B1Pp2/2NP1Q1P/PPP3P1/R1B1K2R w KQkq -", "e2e4 e7e5 f1c4 b8c6 b1c3 g8f6 d2d3 f8c5 f2f4 d7d6 g1f3 c8g4 h2h3 g4f3 d1f3 e5f4", "1. e4 e5 2. Bc4 Nc6 3. Nc3 Nf6 4. d3 Bc5 5. f4 d6 6. Nf3 Bg4 7. h3 Bxf3 8. Qxf3 exf4"),
  new FullOpening("C30", "King's Gambit Declined: Hobbs-Zilbermints Gambit", "r1bqkbnr/pppp1p2/2n4p/4p1P1/4P3/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 b8c6 g1f3 g7g5 f4g5 h7h6", "1. e4 e5 2. f4 Nc6 3. Nf3 g5 4. fxg5 h6"),
  new FullOpening("C30", "King's Gambit Declined: Keene Defense", "rnb1kbnr/ppppqppp/8/4p3/4PP2/6P1/PPPP3P/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d8h4 g2g3 h4e7", "1. e4 e5 2. f4 Qh4+ 3. g3 Qe7"),
  new FullOpening("C30", "King's Gambit Declined: Keene's Defense", "rnb1kbnr/pppp1ppp/8/4p3/4PP1q/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d8h4", "1. e4 e5 2. f4 Qh4+"),
  new FullOpening("C30", "King's Gambit Declined: Keene's Defense", "rnb1kbnr/pppp1ppp/8/4p3/4PP1q/6P1/PPPP3P/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 d8h4 g2g3", "1. e4 e5 2. f4 Qh4+ 3. g3"),
  new FullOpening("C30", "King's Gambit Declined: Mafia Defense", "rnbqkbnr/pp1p1ppp/8/2p1p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 c7c5 f2f4 e7e5", "1. e4 c5 2. f4 e5"),
  new FullOpening("C30", "King's Gambit Declined: Miles Defense", "r1bqkbnr/pppp2pp/2n5/4pp2/4PP2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 b8c6 g1f3 f7f5", "1. e4 e5 2. f4 Nc6 3. Nf3 f5"),
  new FullOpening("C30", "King's Gambit Declined: Norwalde Variation", "rnb1kbnr/pppp1ppp/5q2/4p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d8f6", "1. e4 e5 2. f4 Qf6"),
  new FullOpening("C30", "King's Gambit Declined: Norwalde Variation, Bücker Gambit", "rnb1k1nr/pppp1ppp/8/4p3/1bB1Pq2/2N2N2/PPPP2PP/R1BQK2R b KQkq -", "e2e4 e7e5 f2f4 d8f6 b1c3 f6f4 g1f3 f8b4 f1c4", "1. e4 e5 2. f4 Qf6 3. Nc3 Qxf4 4. Nf3 Bb4 5. Bc4"),
  new FullOpening("C30", "King's Gambit Declined: Norwalde Variation, Schubert Variation", "rnb1kbnr/pppp1ppp/8/4p3/3PPq2/2N5/PPP3PP/R1BQKBNR b KQkq -", "e2e4 e7e5 f2f4 d8f6 b1c3 f6f4 d2d4", "1. e4 e5 2. f4 Qf6 3. Nc3 Qxf4 4. d4"),
  new FullOpening("C30", "King's Gambit Declined: Panteldakis Countergambit", "rnbqkbnr/pppp2pp/8/4pp2/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 f7f5", "1. e4 e5 2. f4 f5"),
  new FullOpening("C30", "King's Gambit Declined: Panteldakis Countergambit, Greco Variation", "rnb1kbnr/pppp2pp/8/4pP2/5P1q/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 f7f5 e4f5 d8h4", "1. e4 e5 2. f4 f5 3. exf5 Qh4+"),
  new FullOpening("C30", "King's Gambit Declined: Panteldakis Countergambit, Pawn Sacrifice Line", "rnb1kbnr/ppppq2p/6P1/7Q/5p2/8/PPPP2PP/RNBK1BNR b kq -", "e2e4 e7e5 f2f4 f7f5 e4f5 e5f4 d1h5 g7g6 f5g6 d8e7 e1d1", "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Qh5+ g6 5. fxg6 Qe7+ 6. Kd1"),
  new FullOpening("C30", "King's Gambit Declined: Panteldakis Countergambit, Schiller's Defense", "rnbqk1nr/pppp2pp/8/2b1pP2/5P2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 f7f5 e4f5 f8c5", "1. e4 e5 2. f4 f5 3. exf5 Bc5"),
  new FullOpening("C30", "King's Gambit Declined: Panteldakis Countergambit, Shirazi Line", "rnbq1bnr/ppppk1pp/8/5P1Q/5p2/8/PPPP2PP/RNB1KBNR w KQ -", "e2e4 e7e5 f2f4 f7f5 e4f5 e5f4 d1h5 e8e7", "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Qh5+ Ke7"),
  new FullOpening("C30", "King's Gambit Declined: Panteldakis Countergambit, Symmetrical Variation", "rnbqk1nr/ppp3pp/3b4/3p1P2/3P1p2/3B1N2/PPP3PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 f7f5 e4f5 e5f4 g1f3 d7d5 d2d4 f8d6 f1d3", "1. e4 e5 2. f4 f5 3. exf5 exf4 4. Nf3 d5 5. d4 Bd6 6. Bd3"),
  new FullOpening("C30", "King's Gambit Declined: Petrov's Defense", "rnbqkb1r/pppp1ppp/5n2/4p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 g8f6", "1. e4 e5 2. f4 Nf6"),
  new FullOpening("C30", "King's Gambit Declined: Queen's Knight Defense", "r1bqkbnr/pppp1ppp/2n5/4p3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 b8c6", "1. e4 e5 2. f4 Nc6"),
  new FullOpening("C30", "King's Gambit Declined: Senechaud Countergambit", "rnbqk1nr/pppp1p1p/8/2b1p1p1/4PP2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 f8c5 g1f3 g7g5", "1. e4 e5 2. f4 Bc5 3. Nf3 g5"),
  new FullOpening("C30", "King's Gambit Declined: Soller-Zilbermints Gambit", "r1bqkbnr/pppp2pp/2n2p2/4P3/4P3/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 f7f6 f4e5 b8c6", "1. e4 e5 2. f4 f6 3. fxe5 Nc6"),
  new FullOpening("C30", "King's Gambit Declined: Zilbermints Double Countergambit", "rnbqkbnr/pppp1p1p/8/4p1p1/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 g7g5", "1. e4 e5 2. f4 g5"),
  new FullOpening("C30", "King's Gambit Declined: Zilbermints Double Gambit", "r1bqkbnr/pppp1p1p/2n5/4p1p1/4PP2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 b8c6 g1f3 g7g5", "1. e4 e5 2. f4 Nc6 3. Nf3 g5"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit", "rnbqkbnr/ppp2ppp/8/3pp3/4PP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d7d5", "1. e4 e5 2. f4 d5"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit Accepted", "rnbqkbnr/ppp2ppp/8/3Pp3/5P2/8/PPPP2PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5", "1. e4 e5 2. f4 d5 3. exd5"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Anderssen Attack", "rnbqkbnr/ppp2ppp/8/1B1P4/4pP2/8/PPPP2PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 f1b5", "1. e4 e5 2. f4 d5 3. exd5 e4 4. Bb5+"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Blackburne Attack", "rnbqkbnr/ppp2ppp/8/3pp3/4PP2/5N2/PPPP2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 d7d5 g1f3", "1. e4 e5 2. f4 d5 3. Nf3"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit", "rnbqkbnr/ppp2ppp/8/3P4/4pP2/3P4/PPP3PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Morphy Defense", "rnbqk2r/ppp2ppp/5n2/3P4/1b3P2/2NPp3/PPPB2PP/R2QKBNR w KQkq -", "e2e4 e7e5 b1c3 g8f6 f2f4 d7d5 e4d5 e5e4 d2d3 f8b4 c1d2 e4e3", "1. e4 e5 2. Nc3 Nf6 3. f4 d5 4. exd5 e4 5. d3 Bb4 6. Bd2 e3"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Hinrichsen Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/3PPP2/8/PPP3PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 d2d4", "1. e4 e5 2. f4 d5 3. d4"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Miles Gambit", "rnbqk1nr/ppp2ppp/8/2bPp3/5P2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 f8c5", "1. e4 e5 2. f4 d5 3. exd5 Bc5"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Milner-Barry Variation", "rnbqkbnr/ppp2ppp/8/3pp3/4PP2/2N5/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 b1c3", "1. e4 e5 2. f4 d5 3. Nc3"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Modern Transfer", "rnbqkbnr/ppp2ppp/8/3P4/5p2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5f4", "1. e4 e5 2. f4 d5 3. exd5 exf4"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Nimzowitsch-Marshall Countergambit", "rnbqkbnr/pp3ppp/2p5/3Pp3/5P2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 c7c6", "1. e4 e5 2. f4 d5 3. exd5 c6"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Pickler Gambit", "rnbqk1nr/pp3ppp/2P5/2b1p3/5P2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 c7c6 d5c6 f8c5", "1. e4 e5 2. f4 d5 3. exd5 c6 4. dxc6 Bc5"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Rubinstein Variation", "rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/2N5/PPPPQ1PP/R1B1KBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 b1c3 g8f6 d1e2", "1. e4 e5 2. f4 d5 3. exd5 e4 4. Nc3 Nf6 5. Qe2"),
  new FullOpening("C31", "King's Gambit Declined: Falkbeer Countergambit, Staunton Line", "rnbqkbnr/ppp2ppp/8/3P4/4pP2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4", "1. e4 e5 2. f4 d5 3. exd5 e4"),
  new FullOpening("C31", "Van Geet Opening: Grünfeld Defense, Steiner Gambit", "rnbqkbnr/ppp2ppp/8/4p3/4NP2/8/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 b1c3 d5e4 c3e4", "1. e4 e5 2. f4 d5 3. Nc3 dxe4 4. Nxe4"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Alapin Variation", "rnb1k2r/ppp2ppp/8/3q4/4nP2/8/PPPNQbPP/RNBK1B1R b kq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 d3e4 f6e4 g1f3 f8c5 d1e2 c5f2 e1d1 d8d5 f3d2", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4 Nxe4 6. Nf3 Bc5 7. Qe2 Bf2+ 8. Kd1 Qxd5+ 9. Nfd2"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit", "rnbqkb1r/ppp2ppp/8/3P4/4nP2/8/PPP1Q1PP/RNB1KBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 d3e4 f6e4 d1e2", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4 Nxe4 6. Qe2"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit Accepted", "rnbqkb1r/ppp2ppp/5n2/3P4/4PP2/8/PPP3PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 d3e4", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Keres Variation", "rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/3P4/PPPN2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 b1d2", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. Nd2"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Main Line", "rn1qk2r/ppp2ppp/8/2bP1b2/4nP2/5N2/PPP1Q1PP/RNB1KB1R w KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 d3e4 f6e4 g1f3 f8c5 d1e2 c8f5", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4 Nxe4 6. Nf3 Bc5 7. Qe2 Bf5"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Charousek Gambit, Old Line", "rnbqkb1r/ppp2ppp/5n2/3P4/4pP2/3P4/PPP1Q1PP/RNB1KBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 d1e2", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. Qe2"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Charousek Variation", "rnb1kb1r/ppp3pp/8/3q1p2/4nPP1/8/PPPNQ2P/R1B1KBNR b KQkq -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 d3e4 f6e4 d1e2 d8d5 b1d2 f7f5 g2g4", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4 Nxe4 6. Qe2 Qxd5 7. Nd2 f5 8. g4"),
  new FullOpening("C32", "King's Gambit Declined: Falkbeer Countergambit, Tarrasch Variation", "rn1q1rk1/ppp2ppp/8/2bP1b2/4nPP1/5N2/PPP1Q2P/RNB1KB1R w KQ -", "e2e4 e7e5 f2f4 d7d5 e4d5 e5e4 d2d3 g8f6 d3e4 f6e4 g1f3 f8c5 d1e2 c8f5 g2g4 e8g8", "1. e4 e5 2. f4 d5 3. exd5 e4 4. d3 Nf6 5. dxe4 Nxe4 6. Nf3 Bc5 7. Qe2 Bf5 8. g4 O-O"),
  new FullOpening("C33", "King's Gambit Accepted", "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP2PP/RNBQKBNR w KQkq -", "e2e4 e7e5 f2f4 e5f4", "1. e4 e5 2. f4 exf4"),
  new FullOpening("C33", "King's Gambit Accepted: Basman Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPPQ1PP/RNB1KBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 d1e2", "1. e4 e5 2. f4 exf4 3. Qe2"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit", "rnbqkbnr/pppp1ppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4", "1. e4 e5 2. f4 exf4 3. Bc4"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Anderssen Defense", "rnbqkbnr/pppp1p1p/8/6p1/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 g7g5", "1. e4 e5 2. f4 exf4 3. Bc4 g5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Anderssen Variation", "rnbqkbnr/pp3ppp/2p5/3B4/4Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d7d5 c4d5 c7c6", "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 c6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Bledow Countergambit", "rnbqkb1r/ppp2ppp/5n2/3B4/4Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d7d5 c4d5 g8f6", "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 Nf6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Bledow Variation", "rnbqkbnr/ppp2ppp/8/3p4/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d7d5", "1. e4 e5 2. f4 exf4 3. Bc4 d5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Boden Variation", "r1b1kbnr/pppp1ppp/2n5/8/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 b8c6", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Nc6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Bogoljubov Defense", "rnbqkb1r/pp1p1ppp/2p2n2/8/2B1Pp2/2N5/PPPP2PP/R1BQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 g8f6 b1c3 c7c6", "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3 c6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Bogoljubov Variation", "rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/2N5/PPPP2PP/R1BQK1NR b KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 g8f6 b1c3", "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Boren-Svenonius Variation", "rnb1k1nr/ppp2ppp/3b4/3B4/4Pp1q/8/PPPP2PP/RNBQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d7d5 c4d5 d8h4 e1f1 f8d6", "1. e4 e5 2. f4 exf4 3. Bc4 d5 4. Bxd5 Qh4+ 5. Kf1 Bd6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Bryan Countergambit", "rnb1kbnr/p1pp1ppp/8/1p6/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 b7b5", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 b5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Chigorin's Attack", "rnb1kbnr/ppp2p1p/8/3B2p1/4Pp1q/6P1/PPPP3P/RNBQ1KNR b kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 d7d5 c4d5 g7g5 g2g3", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 d5 5. Bxd5 g5 6. g3"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Classical Defense", "rnb1k2r/ppppnpbp/8/6p1/2BPPp1q/2N5/PPP3PP/R1BQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g8e7 b1c3 g7g5 d2d4 f8g7", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Ne7 5. Nc3 g5 6. d4 Bg7"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Classical Defense, Cozio Attack", "rnb1kbnr/pppp1p1p/8/6p1/2B1Pp1q/5Q2/PPPP2PP/RNB2KNR b kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g7g5 d1f3", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Qf3"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Cozio Defense", "rnbqkb1r/pppp1ppp/5n2/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 g8f6", "1. e4 e5 2. f4 exf4 3. Bc4 Nf6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Cozio Variation", "rnb1kbnr/ppp2ppp/3p4/8/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 d7d6", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 d6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, First Jaenisch Variation", "rnb1kb1r/pppp1ppp/5n2/8/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g8f6", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Nf6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Fraser Variation", "rnb1k1nr/pppp1pbp/8/6p1/2B1P2q/2N2Qp1/PPPP3P/R1B2KNR b kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g7g5 b1c3 f8g7 g2g3 f4g3 d1f3", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Nc3 Bg7 6. g3 fxg3 7. Qf3"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Gianutio Gambit", "rnbqkbnr/pppp2pp/8/5p2/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 f7f5", "1. e4 e5 2. f4 exf4 3. Bc4 f5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Greco Variation", "rnb1k1nr/pppp1ppp/8/2b5/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 f8c5", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 Bc5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Grimm Attack", "rnb1k1nr/ppp2pbp/3p4/4P1p1/2BP1p1q/2N5/PPP3PP/R1BQ1KNR b kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g7g5 b1c3 f8g7 d2d4 d7d6 e4e5", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Nc3 Bg7 6. d4 d6 7. e5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Kieseritzky Gambit", "rnbqkbnr/p1pp1ppp/8/1p6/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 b7b5", "1. e4 e5 2. f4 exf4 3. Bc4 b5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Lopez Defense", "rnbqkbnr/pp1p1ppp/2p5/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 c7c6", "1. e4 e5 2. f4 exf4 3. Bc4 c6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Lopez Variation", "rnb1kbnr/pppp1p1p/8/6p1/2B1Pp1q/8/PPPP2PP/RNBQ1KNR w kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g7g5", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Maurian Defense", "r1bqkbnr/pppp1ppp/2n5/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 b8c6", "1. e4 e5 2. f4 exf4 3. Bc4 Nc6"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, McDonnell Attack", "rnb1k2r/ppppnpbp/8/6p1/2BPPp1q/2N3P1/PPP4P/R1BQ1KNR b kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g7g5 b1c3 f8g7 d2d4 g8e7 g2g3", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Nc3 Bg7 6. d4 Ne7 7. g3"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, McDonnell Attack", "rnb1k1nr/pppp1pbp/8/6p1/2B1Pp1q/2N3P1/PPPP3P/R1BQ1KNR b kq -", "e2e4 e7e5 f2f4 e5f4 f1c4 d8h4 e1f1 g7g5 b1c3 f8g7 g2g3", "1. e4 e5 2. f4 exf4 3. Bc4 Qh4+ 4. Kf1 g5 5. Nc3 Bg7 6. g3"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Paulsen Attack", "rnbqk2r/pppp1ppp/5n2/4P3/1bB2p2/2N5/PPPP2PP/R1BQK1NR b KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 g8f6 b1c3 f8b4 e4e5", "1. e4 e5 2. f4 exf4 3. Bc4 Nf6 4. Nc3 Bb4 5. e5"),
  new FullOpening("C33", "King's Gambit Accepted: Bishop's Gambit, Steinitz Defense", "rnbqkb1r/ppppnppp/8/8/2B1Pp2/8/PPPP2PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1c4 g8e7", "1. e4 e5 2. f4 exf4 3. Bc4 Ne7"),
  new FullOpening("C33", "King's Gambit Accepted: Breyer Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/5Q2/PPPP2PP/RNB1KBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 d1f3", "1. e4 e5 2. f4 exf4 3. Qf3"),
  new FullOpening("C33", "King's Gambit Accepted: Carrera Gambit", "rnbqkbnr/pppp1ppp/8/7Q/4Pp2/8/PPPP2PP/RNB1KBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 d1h5", "1. e4 e5 2. f4 exf4 3. Qh5"),
  new FullOpening("C33", "King's Gambit Accepted: Dodo Variation", "rnbqkbnr/pppp1ppp/8/8/4PpQ1/8/PPPP2PP/RNB1KBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 d1g4", "1. e4 e5 2. f4 exf4 3. Qg4"),
  new FullOpening("C33", "King's Gambit Accepted: Eisenberg Variation", "rnbqkbnr/pppp1ppp/8/8/4Pp2/7N/PPPP2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1h3", "1. e4 e5 2. f4 exf4 3. Nh3"),
  new FullOpening("C33", "King's Gambit Accepted: Gaga Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/6P1/PPPP3P/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 g2g3", "1. e4 e5 2. f4 exf4 3. g3"),
  new FullOpening("C33", "King's Gambit Accepted: Lopez-Gianutio Countergambit, Hein Variation", "rnbk1bnr/pppp2pp/8/8/2B1pp1q/2N5/PPPPQ1PP/R1BK2NR w - -", "e2e4 e7e5 f2f4 e5f4 f1c4 f7f5 d1e2 d8h4 e1d1 f5e4 b1c3 e8d8", "1. e4 e5 2. f4 exf4 3. Bc4 f5 4. Qe2 Qh4+ 5. Kd1 fxe4 6. Nc3 Kd8"),
  new FullOpening("C33", "King's Gambit Accepted: Mason-Keres Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/2N5/PPPP2PP/R1BQKBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 b1c3", "1. e4 e5 2. f4 exf4 3. Nc3"),
  new FullOpening("C33", "King's Gambit Accepted: Orsini Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/1P6/P1PP2PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 b2b3", "1. e4 e5 2. f4 exf4 3. b3"),
  new FullOpening("C33", "King's Gambit Accepted: Paris Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPPN1PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1e2", "1. e4 e5 2. f4 exf4 3. Ne2"),
  new FullOpening("C33", "King's Gambit Accepted: Polerio Gambit", "rnbqkbnr/pppp1ppp/8/8/3PPp2/8/PPP3PP/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 d2d4", "1. e4 e5 2. f4 exf4 3. d4"),
  new FullOpening("C33", "King's Gambit Accepted: Schurig Gambit", "rnbqkbnr/pppp1ppp/8/1B6/4Pp2/8/PPPP2PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f2f4 e5f4 f1b5", "1. e4 e5 2. f4 exf4 3. Bb5"),
  new FullOpening("C33", "King's Gambit Accepted: Schurig Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/3B4/PPPP2PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f2f4 e5f4 f1d3", "1. e4 e5 2. f4 exf4 3. Bd3"),
  new FullOpening("C33", "King's Gambit Accepted: Stamma Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp1P/8/PPPP2P1/RNBQKBNR b KQkq -", "e2e4 e7e5 f2f4 e5f4 h2h4", "1. e4 e5 2. f4 exf4 3. h4"),
  new FullOpening("C33", "King's Gambit Accepted: Tartakower Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPPB1PP/RNBQK1NR b KQkq -", "e2e4 e7e5 f2f4 e5f4 f1e2", "1. e4 e5 2. f4 exf4 3. Be2"),
  new FullOpening("C33", "King's Gambit Accepted: Tartakower Gambit, Weiss Defense", "rnbqkbnr/ppp3pp/3p4/5P2/5p2/8/PPPPB1PP/RNBQK1NR w KQkq -", "e2e4 e7e5 f2f4 e5f4 f1e2 f7f5 e4f5 d7d6", "1. e4 e5 2. f4 exf4 3. Be2 f5 4. exf5 d6"),
  new FullOpening("C33", "King's Gambit Accepted: Tumbleweed", "rnbqkbnr/pppp1ppp/8/8/4Pp2/8/PPPP1KPP/RNBQ1BNR b kq -", "e2e4 e7e5 f2f4 e5f4 e1f2", "1. e4 e5 2. f4 exf4 3. Kf2"),
  new FullOpening("C34", "King's Gambit Accepted: Becker Defense", "rnbqkbnr/pppp1pp1/7p/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 h7h6", "1. e4 e5 2. f4 exf4 3. Nf3 h6"),
  new FullOpening("C34", "King's Gambit Accepted: Bonsch-Osmolovsky Variation", "rnbqkb1r/ppppnppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g8e7", "1. e4 e5 2. f4 exf4 3. Nf3 Ne7"),
  new FullOpening("C34", "King's Gambit Accepted: Fischer Defense", "rnbqkbnr/ppp2ppp/3p4/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d6", "1. e4 e5 2. f4 exf4 3. Nf3 d6"),
  new FullOpening("C34", "King's Gambit Accepted: Fischer Defense, Schulder Gambit", "rnbqkbnr/ppp2ppp/3p4/8/1P2Pp2/5N2/P1PP2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d6 b2b4", "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. b4"),
  new FullOpening("C34", "King's Gambit Accepted: Fischer Defense, Spanish Variation", "rnbqkb1r/ppp2ppp/3p1n2/8/3PPp2/3B1N2/PPP3PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d6 d2d4 g8f6 f1d3", "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. d4 Nf6 5. Bd3"),
  new FullOpening("C34", "King's Gambit Accepted: Gianutio Countergambit", "rnbqkbnr/pppp2pp/8/5p2/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 f7f5", "1. e4 e5 2. f4 exf4 3. Nf3 f5"),
  new FullOpening("C34", "King's Gambit Accepted: Greco Gambit", "rnbqk1nr/ppp2pb1/3p3p/6p1/2BPPp1P/5N2/PPP3P1/RNBQK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d6 f1c4 h7h6 d2d4 g7g5 h2h4 f8g7", "1. e4 e5 2. f4 exf4 3. Nf3 d6 4. Bc4 h6 5. d4 g5 6. h4 Bg7"),
  new FullOpening("C34", "King's Gambit Accepted: King's Knight's Gambit", "rnbqkbnr/pppp1p1p/8/6p1/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5", "1. e4 e5 2. f4 exf4 3. Nf3 g5"),
  new FullOpening("C34", "King's Gambit Accepted: King's Knight's Gambit", "rnbqkbnr/pppp1ppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3", "1. e4 e5 2. f4 exf4 3. Nf3"),
  new FullOpening("C34", "King's Gambit Accepted: MacLeod Defense", "r1bqkbnr/pppp1ppp/2n5/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 b8c6", "1. e4 e5 2. f4 exf4 3. Nf3 Nc6"),
  new FullOpening("C34", "King's Gambit Accepted: Schallopp Defense", "rnbqkb1r/pppp1ppp/5n2/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g8f6", "1. e4 e5 2. f4 exf4 3. Nf3 Nf6"),
  new FullOpening("C34", "King's Gambit Accepted: Schallopp Defense, Tashkent Attack", "rnbqkb1r/pppp1ppp/8/4P2n/5pP1/5N2/PPPP3P/RNBQKB1R b KQkq g3", "e2e4 e7e5 f2f4 e5f4 g1f3 g8f6 e4e5 f6h5 g2g4", "1. e4 e5 2. f4 exf4 3. Nf3 Nf6 4. e5 Nh5 5. g4"),
  new FullOpening("C35", "King's Gambit Accepted: Cunningham Defense", "rnbqk1nr/ppppbppp/8/8/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 f8e7", "1. e4 e5 2. f4 exf4 3. Nf3 Be7"),
  new FullOpening("C35", "King's Gambit Accepted: Cunningham Defense, Bertin Gambit", "rnbqk1nr/pppp1ppp/8/8/2B1Pp1b/5NP1/PPPP3P/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 f8e7 f1c4 e7h4 g2g3", "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Bh4+ 5. g3"),
  new FullOpening("C35", "King's Gambit Accepted: Cunningham Defense, Bertin Gambit", "rnbqk1nr/pppp1ppp/8/8/2B1P2b/5N2/PPPP3p/RNBQ1R1K b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 f8e7 f1c4 e7h4 g2g3 f4g3 e1g1 g3h2 g1h1", "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Bh4+ 5. g3 fxg3 6. O-O gxh2+ 7. Kh1"),
  new FullOpening("C35", "King's Gambit Accepted: Cunningham Defense, McCormick Defense", "rnbqk2r/ppppbppp/5n2/8/2B1Pp2/5N2/PPPP2PP/RNBQK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 f8e7 f1c4 g8f6", "1. e4 e5 2. f4 exf4 3. Nf3 Be7 4. Bc4 Nf6"),
  new FullOpening("C36", "King's Gambit Accepted: Abbazia Defense", "rnbqkb1r/ppp2ppp/5n2/3P4/5p2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d5 e4d5 g8f6", "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5 Nf6"),
  new FullOpening("C36", "King's Gambit Accepted: Abbazia Defense, Main Line", "rnbqkb1r/p4ppp/2p5/3n4/2B2p2/5N2/PPPP2PP/RNBQK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d5 e4d5 g8f6 f1b5 c7c6 d5c6 b7c6 b5c4 f6d5", "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5 Nf6 5. Bb5+ c6 6. dxc6 bxc6 7. Bc4 Nd5"),
  new FullOpening("C36", "King's Gambit Accepted: Modern Defense", "rnbqkbnr/ppp2ppp/8/3p4/4Pp2/5N2/PPPP2PP/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d5", "1. e4 e5 2. f4 exf4 3. Nf3 d5"),
  new FullOpening("C36", "King's Gambit Accepted: Modern Defense", "rnbqkbnr/ppp2ppp/8/3P4/5p2/5N2/PPPP2PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 d7d5 e4d5", "1. e4 e5 2. f4 exf4 3. Nf3 d5 4. exd5"),
  new FullOpening("C37", "King's Gambit Accepted: Australian Gambit", "rnbqkbnr/pppp1p1p/8/8/2B1PppP/5N2/PPPP2P1/RNBQK2R b KQkq h3", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 h2h4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. h4"),
  new FullOpening("C37", "King's Gambit Accepted: Blachly Gambit", "r1bqkbnr/pppp1p1p/2n5/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 b8c6 f1c4 g7g5", "1. e4 e5 2. f4 exf4 3. Nf3 Nc6 4. Bc4 g5"),
  new FullOpening("C37", "King's Gambit Accepted: Double Muzio Gambit", "rnb1kbnr/pppp1B1p/8/4q3/5p2/5Q2/PPPP2PP/RNB2RK1 b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 d8f6 e4e5 f6e5 c4f7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6 7. e5 Qxe5 8. Bxf7+"),
  new FullOpening("C37", "King's Gambit Accepted: Double Muzio Gambit, Baldwin Gambit", "rnb1kbnr/pppp1p1p/8/3N4/2q1Pp2/5Q2/PPPP2PP/R1B2R1K b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 d8f6 b1c3 f6d4 g1h1 d4c4 c3d5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6 7. Nc3 Qd4+ 8. Kh1 Qxc4 9. Nd5"),
  new FullOpening("C37", "King's Gambit Accepted: Double Muzio Gambit, Bello Gambit", "rnb1kbnr/pppp1p1p/5q2/8/2B1Pp2/2N2Q2/PPPP2PP/R1B2RK1 b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 d8f6 b1c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6 7. Nc3"),
  new FullOpening("C37", "King's Gambit Accepted: Double Muzio Gambit, Paulsen Defense", "r1b1k2r/ppppnp1p/2n4b/4q3/2B2p2/2NP1Q2/PPPB2PP/4RRK1 b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 d8f6 e4e5 f6e5 d2d3 f8h6 b1c3 g8e7 c1d2 b8c6 a1e1", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6 7. e5 Qxe5 8. d3 Bh6 9. Nc3 Ne7 10. Bd2 Nbc6 11. Rae1"),
  new FullOpening("C37", "King's Gambit Accepted: Double Muzio Gambit, Young Gambit", "rnb2bnr/pppp1k1p/5q2/8/4P3/2N1pQ2/PPP3PP/R4RK1 w - -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 d8f6 c4f7 e8f7 d2d4 f6d4 c1e3 d4f6 b1c3 f4e3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6 7. Bxf7+ Kxf7 8. d4 Qxd4+ 9. Be3 Qf6 10. Nc3 fxe3"),
  new FullOpening("C37", "King's Gambit Accepted: Ghulam-Kassim Gambit", "rnbqkbnr/pppp1p1p/8/8/2BPPpp1/5N2/PPP3PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 d2d4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4"),
  new FullOpening("C37", "King's Gambit Accepted: Ghulam-Kassim Gambit", "rnbqkbnr/pppp1p1p/8/8/2BPPp2/5Q2/PPP3PP/RNB1K2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 d2d4 g4f3 d1f3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4 gxf3 6. Qxf3"),
  new FullOpening("C37", "King's Gambit Accepted: King's Knight's Gambit", "rnbqkbnr/pppp1p1p/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4"),
  new FullOpening("C37", "King's Gambit Accepted: Kotov Gambit", "rnbqkbnr/pppp1p1p/8/8/2BPPB2/5p2/PPP3PP/RN1QK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 d2d4 g4f3 c1f4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. d4 gxf3 6. Bxf4"),
  new FullOpening("C37", "King's Gambit Accepted: Lolli Gambit", "rnbqkbnr/pppp1B1p/8/8/4Ppp1/5N2/PPPP2PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 c4f7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Bxf7+"),
  new FullOpening("C37", "King's Gambit Accepted: Lolli Gambit, Young Variation", "rnb2bnr/pppp1k1p/5q2/8/4Pp2/2N1BQ2/PPP3PP/R4RK1 b - -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 c4f7 e8f7 e1g1 g4f3 d1f3 d8f6 d2d4 f6d4 c1e3 d4f6 b1c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Bxf7+ Kxf7 6. O-O gxf3 7. Qxf3 Qf6 8. d4 Qxd4+ 9. Be3 Qf6 10. Nc3"),
  new FullOpening("C37", "King's Gambit Accepted: McDonnell Gambit", "rnbqkbnr/pppp1p1p/8/8/2B1Ppp1/2N2N2/PPPP2PP/R1BQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 b1c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Nc3"),
  new FullOpening("C37", "King's Gambit Accepted: Middleton Countergambit", "rn1qkbnr/ppp2p2/3p4/6p1/2B1Ppp1/5N2/PPPP2P1/RNBQ1RK1 w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 d7d6 e1g1 c8g4 h2h3 h7h5 h3g4 h5g4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 d6 5. O-O Bg4 6. h3 h5 7. hxg4 hxg4"),
  new FullOpening("C37", "King's Gambit Accepted: Muzio Gambit Accepted, From's Defense", "rnb1kbnr/ppppqp1p/8/8/2B1Pp2/5Q2/PPPP2PP/RNB2RK1 w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 d8e7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qe7"),
  new FullOpening("C37", "King's Gambit Accepted: Muzio Gambit, Brentano Defense", "rnbqkbnr/ppp2p1p/8/3p4/2B1Ppp1/5N2/PPPP2PP/RNBQ1RK1 w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 d7d5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O d5"),
  new FullOpening("C37", "King's Gambit Accepted: Muzio Gambit, Holloway Defense", "r1bqkbnr/pppp1p1p/2n5/8/2B1Pp2/5Q2/PPPP2PP/RNB2RK1 w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 b8c6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Nc6"),
  new FullOpening("C37", "King's Gambit Accepted: Muzio Gambit, Kling and Horwitz Counterattack", "rnb1kbnr/ppppqp1p/8/8/2B1Ppp1/5N2/PPPP2PP/RNBQ1RK1 w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 d8e7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O Qe7"),
  new FullOpening("C37", "King's Gambit Accepted: Muzio Gambit, Sarratt Defense", "rnb1kbnr/pppp1p1p/5q2/8/2B1Pp2/5Q2/PPPP2PP/RNB2RK1 w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1 g4f3 d1f3 d8f6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O gxf3 6. Qxf3 Qf6"),
  new FullOpening("C37", "King's Gambit Accepted: Muzio Gambit, Wild Muzio Gambit", "rnbqkbnr/pppp1p1p/8/8/2B1Ppp1/5N2/PPPP2PP/RNBQ1RK1 b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 e1g1", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. O-O"),
  new FullOpening("C37", "King's Gambit Accepted: Quade Gambit", "rnbqkbnr/pppp1p1p/8/6p1/4Pp2/2N2N2/PPPP2PP/R1BQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 b1c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Nc3"),
  new FullOpening("C37", "King's Gambit Accepted: Rosentreter Gambit", "rnbqkbnr/pppp1p1p/8/6p1/3PPp2/5N2/PPP3PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 d2d4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4"),
  new FullOpening("C37", "King's Gambit Accepted: Rosentreter Gambit, Bird Gambit", "rnb1kbnr/pppp1p1p/8/4N3/3PPppq/6P1/PPP4P/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 d2d4 g5g4 f3e5 d8h4 g2g3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Ne5 Qh4+ 6. g3"),
  new FullOpening("C37", "King's Gambit Accepted: Rosentreter Gambit, Sörensen Gambit", "rnbqkbnr/pppp1p1p/8/8/3PPpp1/2N2N2/PPP3PP/R1BQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 d2d4 g5g4 b1c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Nc3"),
  new FullOpening("C37", "King's Gambit Accepted: Rosentreter Gambit, Testa Variation", "rnbqkbnr/pppp1p1p/8/8/3PPBp1/5N2/PPP3PP/RN1QKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 d2d4 g5g4 c1f4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Bxf4"),
  new FullOpening("C37", "King's Gambit Accepted: Salvio Gambit", "rnbqkbnr/pppp1p1p/8/4N3/2B1Ppp1/8/PPPP2PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 f3e5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5"),
  new FullOpening("C37", "King's Gambit Accepted: Salvio Gambit, Anderssen Counterattack", "rnb1kb1r/ppp2p1p/3p3n/4N3/2BPPppq/8/PPP3PP/RNBQ1K1R w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 f3e5 d8h4 e1f1 g8h6 d2d4 d7d6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nh6 7. d4 d6"),
  new FullOpening("C37", "King's Gambit Accepted: Salvio Gambit, Cochrane Gambit", "rnb1kbnr/pppp1p1p/8/4N3/2B1P1pq/5p2/PPPP2PP/RNBQ1K1R w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 f3e5 d8h4 e1f1 f4f3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 f3"),
  new FullOpening("C37", "King's Gambit Accepted: Salvio Gambit, Santa Maria Defense", "rnb1kb1r/pppp1p1p/5n2/4N3/2B1Pppq/8/PPPP2PP/RNBQ1K1R w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 f3e5 d8h4 e1f1 g8f6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nf6"),
  new FullOpening("C37", "King's Gambit Accepted: Salvio Gambit, Silberschmidt Defense", "rnb1kb1r/pppp1p1p/7n/4N3/2B1Pppq/8/PPPP2PP/RNBQ1K1R w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 f3e5 d8h4 e1f1 g8h6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nh6"),
  new FullOpening("C37", "King's Gambit Accepted: Salvio Gambit, Viennese Variation", "r1b1kbnr/pppp1p1p/2n5/4N3/2B1Pppq/8/PPPP2PP/RNBQ1K1R w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 f3e5 d8h4 e1f1 b8c6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nc6"),
  new FullOpening("C37", "King's Gambit Accepted: Silberschmidt Gambit", "rnb1kb1r/pppp1p1p/7n/4N3/2BPP1pq/5p2/PPP3PP/RNBQ1K1R w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 g5g4 f3e5 d8h4 e1f1 g8h6 d2d4 f4f3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 g4 5. Ne5 Qh4+ 6. Kf1 Nh6 7. d4 f3"),
  new FullOpening("C37", "King's Gambit Accepted: Sörensen Gambit", "rnbqkbnr/pppp1p1p/8/4N3/3PPpp1/8/PPP3PP/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 d2d4 g5g4 f3e5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. d4 g4 5. Ne5"),
  new FullOpening("C38", "King's Gambit Accepted: Greco Gambit", "rnbqk1nb/pp3p2/2pp4/4N1p1/2BPPp2/2N5/PPP3P1/R1BQK3 b Qq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 f8g7 h2h4 h7h6 d2d4 d7d6 b1c3 c7c6 h4g5 h6g5 h1h8 g7h8 f3e5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. h4 h6 6. d4 d6 7. Nc3 c6 8. hxg5 hxg5 9. Rxh8 Bxh8 10. Ne5"),
  new FullOpening("C38", "King's Gambit Accepted: Hanstein Gambit", "rnbqk1nr/pppp1pbp/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQ1RK1 b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 f8g7 e1g1", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. O-O"),
  new FullOpening("C38", "King's Gambit Accepted: Mayet Gambit", "rnbqk1nr/ppp2pbp/3p4/6p1/2BPPp2/2P2N2/PP4PP/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 f8g7 d2d4 d7d6 c2c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. d4 d6 6. c3"),
  new FullOpening("C38", "King's Gambit Accepted: Philidor Gambit", "rnbqk1nr/pppp1pbp/8/6p1/2B1Pp1P/5N2/PPPP2P1/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 f8g7 h2h4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7 5. h4"),
  new FullOpening("C38", "King's Gambit Accepted: Philidor Gambit, Schultz Variation", "rnbqk1nr/ppp2pb1/3p3p/6p1/2BPPp1P/3Q1N2/PPP3P1/RNB1K2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 h7h6 f1c4 d7d6 d2d4 g7g5 h2h4 f8g7 d1d3", "1. e4 e5 2. f4 exf4 3. Nf3 h6 4. Bc4 d6 5. d4 g5 6. h4 Bg7 7. Qd3"),
  new FullOpening("C38", "King's Gambit Accepted: Traditional Variation", "rnbqk1nr/pppp1pbp/8/6p1/2B1Pp2/5N2/PPPP2PP/RNBQK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 f1c4 f8g7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. Bc4 Bg7"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier Gambit", "rnbqkbnr/pppp1p1p/8/6N1/4PppP/8/PPPP2P1/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier Gambit, Thorold Attack", "rnbq1bnr/pppp1k2/7p/8/3PPppP/8/PPP3P1/RNBQKB1R b KQ -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7 e8f7 d2d4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7 Kxf7 7. d4"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier Gambit, Urusov Attack", "rnbq1bnr/pppp1k2/7p/8/2B1PppP/8/PPPP2P1/RNBQK2R b KQ -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7 e8f7 f1c4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7 Kxf7 7. Bc4+"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier, Blackburne Gambit", "rnbq1bnr/pppp1k2/7p/8/4PppP/2N5/PPPP2P1/R1BQKB1R b KQ -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7 e8f7 b1c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7 Kxf7 7. Nc3"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier, Cook Variation", "rnbq1bnr/ppp3k1/7p/4B3/2BPp1pP/8/PPP3P1/RN1QK2R b KQ -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7 e8f7 d2d4 d7d5 c1f4 d5e4 f1c4 f7g7 f4e5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7 Kxf7 7. d4 d5 8. Bxf4 dxe4 9. Bc4+ Kg7 10. Be5+"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier, Horny Defense", "rnbq3r/pppp1k2/3b1n1p/8/4PQ1P/8/PPPP2P1/RNB1KB1R w KQ -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7 e8f7 d1g4 g8f6 g4f4 f8d6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7 Kxf7 7. Qxg4 Nf6 8. Qxf4 Bd6"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier, Schlechter Defense", "rnbqkb1r/pppp1p1p/5n2/6N1/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 g8f6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 Nf6"),
  new FullOpening("C39", "King's Gambit Accepted: Allgaier, Urusov Attack", "rnbq1bnr/ppp3k1/7p/3B4/3PPppP/8/PPP3P1/RNBQK2R b KQ -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7 e8f7 f1c4 d7d5 c4d5 f7g7 d2d4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7 Kxf7 7. Bc4+ d5 8. Bxd5+ Kg7 9. d4"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit", "rnbqkbnr/pppp1p1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Anderssen Defense", "rnbqk2r/ppp2p1p/3b1n2/3PN3/2B2ppP/8/PPPP2P1/RNBQK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6 f1c4 d7d5 e4d5 f8d6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Bc4 d5 7. exd5 Bd6"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Anderssen-Cordel Gambit", "rnbqk2r/ppp2p1p/3b4/3PN3/2BP1npP/8/PPP3P1/RN1QK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6 f1c4 d7d5 e4d5 f8d6 d2d4 f6h5 c1f4 h5f4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Bc4 d5 7. exd5 Bd6 8. d4 Nh5 9. Bxf4 Nxf4"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense", "rnbqkb1r/pppp1p1p/5n2/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense, Rubinstein Variation", "rnbqkb1r/pppp1p1p/5n2/4N3/3PPppP/8/PPP3P1/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6 d2d4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. d4"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Berlin Defense, de Riviere Variation", "rnbqkb1r/ppp2p1p/5n2/3p4/4PpNP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6 e5g4 d7d5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Nxg4 d5"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Brentano Defense", "rnbqkbnr/ppp2p1p/8/3pN3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 d7d5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d5"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Brentano Defense", "rnbqkb1r/ppp2p1p/5n2/3pN3/3PPBpP/8/PPP3P1/RN1QKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 d7d5 d2d4 g8f6 c1f4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d5 6. d4 Nf6 7. Bxf4"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Brentano Defense, Caro Variation", "rnbqkb1r/ppp2p1p/8/3pN3/3PnBpP/8/PPPN2P1/R2QKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 d7d5 d2d4 g8f6 c1f4 f6e4 b1d2", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d5 6. d4 Nf6 7. Bxf4 Nxe4 8. Nd2"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Brentano Defense, Kaplanek Variation", "rnb1k2r/ppp2p1p/5n2/3qN3/1b1P1ppP/2N5/PPP2KP1/R1BQ1B1R b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 d7d5 d2d4 g8f6 e4d5 d8d5 b1c3 f8b4 e1f2", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d5 6. d4 Nf6 7. exd5 Qxd5 8. Nc3 Bb4 9. Kf2"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Cotter Gambit", "rnbqkbnr/pppp1N2/7p/8/4PppP/8/PPPP2P1/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3g5 h7h6 g5f7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ng5 h6 6. Nxf7"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Kolisch Defense", "rnbqkbnr/ppp2p1p/3p4/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 d7d6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 d6"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Long Whip", "rnbqkbnr/pppp1p2/8/4N2p/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 h7h5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 h5"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Neumann Defense", "r1bqkbnr/pppp1p1p/2n5/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 b8c6", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nc6"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Paulsen Defense", "rnbqk1nr/pppp1pbp/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 f8g7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Bg7"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Paulsen Defense Deferred", "rnbqk2r/ppp2pbp/5n2/3PN3/2B2ppP/8/PPPP2P1/RNBQK2R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6 f1c4 d7d5 e4d5 f8g7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Bc4 d5 7. exd5 Bg7"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Rice Gambit", "rnbqk2r/ppp2p1p/5n2/3Pb3/2B2ppP/8/PPPP2P1/RNBQ1RK1 w kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6 f1c4 d7d5 e4d5 f8d6 e1g1 d6e5", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Bc4 d5 7. exd5 Bd6 8. O-O Bxe5"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky Gambit, Rosenthal Defense", "rnb1kbnr/ppppqp1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 d8e7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Qe7"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky, Long Whip Defense, Jaenisch Variation", "rnbqk1n1/pppp1p1r/7b/4N2p/2BPPppP/2N5/PPP3P1/R1BQK2R b KQq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 h7h5 f1c4 h8h7 d2d4 f8h6 b1c3", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 h5 6. Bc4 Rh7 7. d4 Bh6 8. Nc3"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky, Polerio Defense", "rnbqk1nr/ppppbp1p/8/4N3/4PppP/8/PPPP2P1/RNBQKB1R w KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 f8e7", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Be7"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky, Rice Gambit", "rnbqk2r/ppp2p1p/3b1n2/3PN3/2B2ppP/8/PPPP2P1/RNBQ1RK1 b kq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 g8f6 f1c4 d7d5 e4d5 f8d6 e1g1", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Nf6 6. Bc4 d5 7. exd5 Bd6 8. O-O"),
  new FullOpening("C39", "King's Gambit Accepted: Kieseritzky, Salvio Defense, Cozio Variation", "rnb1kbnr/ppppq2p/8/4Np2/2BPPppP/8/PPP3P1/RNBQK2R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4 g5g4 f3e5 d8e7 d2d4 f7f5 f1c4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4 g4 5. Ne5 Qe7 6. d4 f5 7. Bc4"),
  new FullOpening("C39", "King's Gambit Accepted: King's Knight's Gambit", "rnbqkbnr/pppp1p1p/8/6p1/4Pp1P/5N2/PPPP2P1/RNBQKB1R b KQkq -", "e2e4 e7e5 f2f4 e5f4 g1f3 g7g5 h2h4", "1. e4 e5 2. f4 exf4 3. Nf3 g5 4. h4"),
  new FullOpening("C40", "Elephant Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d5", "1. e4 e5 2. Nf3 d5"),
  new FullOpening("C40", "Elephant Gambit: Maróczy Gambit", "rnbqk1nr/ppp2ppp/3b4/3Pp3/8/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d5 e4d5 f8d6", "1. e4 e5 2. Nf3 d5 3. exd5 Bd6"),
  new FullOpening("C40", "Elephant Gambit: Paulsen Countergambit", "rnbqkbnr/ppp2ppp/8/3P4/4p3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d5 e4d5 e5e4", "1. e4 e5 2. Nf3 d5 3. exd5 e4"),
  new FullOpening("C40", "Elephant Gambit: Wasp Variation", "rnb1kbnr/ppp2ppp/8/4N1q1/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 d7d5 f3e5 d5e4 f1c4 d8g5", "1. e4 e5 2. Nf3 d5 3. Nxe5 dxe4 4. Bc4 Qg5"),
  new FullOpening("C40", "Gunderam Defense", "rnb1kbnr/ppppqppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d8e7", "1. e4 e5 2. Nf3 Qe7"),
  new FullOpening("C40", "King's Knight Opening", "rnbqkbnr/pppp1ppp/8/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3", "1. e4 e5 2. Nf3"),
  new FullOpening("C40", "King's Pawn Game: Busch-Gass Gambit", "rnbqk1nr/pppp1ppp/8/2b1p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 f8c5", "1. e4 e5 2. Nf3 Bc5"),
  new FullOpening("C40", "King's Pawn Game: Busch-Gass Gambit, Chiodini Gambit", "r1bqk1nr/pppp1ppp/2n5/2b1N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 f8c5 f3e5 b8c6", "1. e4 e5 2. Nf3 Bc5 3. Nxe5 Nc6"),
  new FullOpening("C40", "King's Pawn Game: Damiano Defense", "rnbqkbnr/pppp2pp/5p2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 f7f6", "1. e4 e5 2. Nf3 f6"),
  new FullOpening("C40", "King's Pawn Game: Damiano Defense, Damiano Gambit", "rnb1kbnQ/ppppq2p/6p1/8/4P3/8/PPPP1PPP/RNB1KB1R b KQq -", "e2e4 e7e5 g1f3 f7f6 f3e5 f6e5 d1h5 g7g6 h5e5 d8e7 e5h8", "1. e4 e5 2. Nf3 f6 3. Nxe5 fxe5 4. Qh5+ g6 5. Qxe5+ Qe7 6. Qxh8"),
  new FullOpening("C40", "King's Pawn Game: Damiano Defense, Damiano Gambit, Chigorin Gambit", "rnb1kbnr/ppp1q1pp/5p2/3p4/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 f7f6 f3e5 d8e7 e5f3 d7d5", "1. e4 e5 2. Nf3 f6 3. Nxe5 Qe7 4. Nf3 d5"),
  new FullOpening("C40", "King's Pawn Game: Gunderam Defense, Gunderam Gambit", "rnb1kbnr/ppppq1pp/8/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 d8e7 f1c4 f7f5", "1. e4 e5 2. Nf3 Qe7 3. Bc4 f5"),
  new FullOpening("C40", "King's Pawn Game: Gunderam Gambit", "rnbqkbnr/pp1p1ppp/2p5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 c7c6", "1. e4 e5 2. Nf3 c6"),
  new FullOpening("C40", "King's Pawn Game: La Bourdonnais Gambit", "rnb1kbnr/pppp1ppp/6q1/4p3/2B1P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 d8f6 f1c4 f6g6 e1g1", "1. e4 e5 2. Nf3 Qf6 3. Bc4 Qg6 4. O-O"),
  new FullOpening("C40", "King's Pawn Game: McConnell Defense", "rnb1kbnr/pppp1ppp/5q2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d8f6", "1. e4 e5 2. Nf3 Qf6"),
  new FullOpening("C40", "Latvian Gambit", "rnbqkbnr/pppp2pp/8/4pp2/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 f7f5", "1. e4 e5 2. Nf3 f5"),
  new FullOpening("C40", "Latvian Gambit Accepted", "rnbqkbnr/pppp2pp/8/4pP2/8/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 e4f5", "1. e4 e5 2. Nf3 f5 3. exf5"),
  new FullOpening("C40", "Latvian Gambit Accepted: Bilguer Variation", "rnb1kbnr/ppp3pp/3p1q2/5p2/2NPP3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 d2d4 d7d6 e5c4", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4"),
  new FullOpening("C40", "Latvian Gambit Accepted: Bronstein Attack", "rnb1kbnr/ppp3pp/3p1q2/8/2NPp3/8/PPP1BPPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 d2d4 d7d6 e5c4 f5e4 f1e2", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4 fxe4 6. Be2"),
  new FullOpening("C40", "Latvian Gambit Accepted: Bronstein Gambit", "rnb1kbnr/ppp4p/3p1qp1/8/2NPp3/8/PPP1QPPP/RNB1KB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 d2d4 d7d6 e5c4 f5e4 d1h5 g7g6 h5e2", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4 fxe4 6. Qh5+ g6 7. Qe2"),
  new FullOpening("C40", "Latvian Gambit Accepted: Foltys Variation", "rnb1kbnr/pppp2pp/5q2/8/2N1p3/3P4/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 e5c4 f5e4 d2d3", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4 fxe4 5. d3"),
  new FullOpening("C40", "Latvian Gambit Accepted: Foltys-Leonhardt Variation", "rnb1kbnr/pppp2pp/5q2/5p2/2N1P3/8/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 e5c4", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4"),
  new FullOpening("C40", "Latvian Gambit Accepted: Leonhardt Variation", "rnb1kbnr/pppp2pp/5q2/8/2N1p3/2N5/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 e5c4 f5e4 b1c3", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. Nc4 fxe4 5. Nc3"),
  new FullOpening("C40", "Latvian Gambit Accepted: Main Line", "rnb1kbnr/pppp2pp/5q2/4Np2/3PP3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 d2d4", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4"),
  new FullOpening("C40", "Latvian Gambit Accepted: Nimzowitsch Attack", "rnb1kbnr/ppp3pp/3p1q2/8/3Pp3/4N3/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8f6 d2d4 d7d6 e5c4 f5e4 c4e3", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qf6 4. d4 d6 5. Nc4 fxe4 6. Ne3"),
  new FullOpening("C40", "Latvian Gambit: Behting Variation", "rnb1kb1N/ppp3pp/5n2/3p4/2B1p3/8/PPPP1PqP/RNBQKR2 w Qq -", "e2e4 e7e5 g1f3 f7f5 f1c4 f5e4 f3e5 d8g5 e5f7 g5g2 h1f1 d7d5 f7h8 g8f6", "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 Qg5 5. Nf7 Qxg2 6. Rf1 d5 7. Nxh8 Nf6"),
  new FullOpening("C40", "Latvian Gambit: Corkscrew Countergambit", "rnbqkb1r/pppp2pp/5n2/4N3/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 f7f5 f1c4 f5e4 f3e5 g8f6", "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 Nf6"),
  new FullOpening("C40", "Latvian Gambit: Corkscrew Gambit", "rnb1kb1N/ppp1q1pp/5n2/3p4/2B1p3/8/PPPP1PPP/RNBQK2R w KQq -", "e2e4 e7e5 g1f3 f7f5 f3e5 g8f6 f1c4 f5e4 e5f7 d8e7 f7h8 d7d5", "1. e4 e5 2. Nf3 f5 3. Nxe5 Nf6 4. Bc4 fxe4 5. Nf7 Qe7 6. Nxh8 d5"),
  new FullOpening("C40", "Latvian Gambit: Diepstraten Countergambit", "rnbqkbnr/pppp2pp/8/4pp2/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 c2c4", "1. e4 e5 2. Nf3 f5 3. c4"),
  new FullOpening("C40", "Latvian Gambit: Fraser Defense", "r1bqkbnr/pppp2pp/2n5/4Np2/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 b8c6", "1. e4 e5 2. Nf3 f5 3. Nxe5 Nc6"),
  new FullOpening("C40", "Latvian Gambit: Greco Variation", "rnb1kbnr/ppppq1pp/8/4Np2/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 f7f5 f3e5 d8e7", "1. e4 e5 2. Nf3 f5 3. Nxe5 Qe7"),
  new FullOpening("C40", "Latvian Gambit: Lobster Gambit", "rnbqkbnr/pppp2pp/8/4pp2/4P1P1/5N2/PPPP1P1P/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 g2g4", "1. e4 e5 2. Nf3 f5 3. g4"),
  new FullOpening("C40", "Latvian Gambit: Mason Countergambit", "rnbqkbnr/pppp2pp/8/4pp2/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 d2d4", "1. e4 e5 2. Nf3 f5 3. d4"),
  new FullOpening("C40", "Latvian Gambit: Mayet Attack", "rnbqkbnr/pppp2pp/8/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 f7f5 f1c4", "1. e4 e5 2. Nf3 f5 3. Bc4"),
  new FullOpening("C40", "Latvian Gambit: Mayet Attack, Morgado Defense", "rnbqkb1r/pppp2pp/5n2/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 f7f5 f1c4 g8f6", "1. e4 e5 2. Nf3 f5 3. Bc4 Nf6"),
  new FullOpening("C40", "Latvian Gambit: Mayet Attack, Poisoned Pawn Variation", "rnb1kbnr/pppp2pp/8/4N3/2BPp3/8/PPP2PqP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 f7f5 f1c4 f5e4 f3e5 d8g5 d2d4 g5g2", "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 Qg5 5. d4 Qxg2"),
  new FullOpening("C40", "Latvian Gambit: Mayet Attack, Polerio-Svedenborg Variation", "rnbqkbnr/ppp3pp/8/3pN3/2B1p3/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 f7f5 f1c4 f5e4 f3e5 d7d5", "1. e4 e5 2. Nf3 f5 3. Bc4 fxe4 4. Nxe5 d5"),
  new FullOpening("C40", "Latvian Gambit: Mayet Attack, Strautins Gambit", "rnbqkbnr/p1pp2pp/8/1p2pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 f7f5 f1c4 b7b5", "1. e4 e5 2. Nf3 f5 3. Bc4 b5"),
  new FullOpening("C40", "Latvian Gambit: Mlotkowski Variation", "rnbqkbnr/pppp2pp/8/4pp2/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 b1c3", "1. e4 e5 2. Nf3 f5 3. Nc3"),
  new FullOpening("C40", "Latvian Gambit: Senechaud Gambit", "rnbqkbnr/pppp2pp/8/4pp2/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 f7f5 b2b4", "1. e4 e5 2. Nf3 f5 3. b4"),
  new FullOpening("C41", "Philidor Defense", "rnbqkbnr/ppp2ppp/3p4/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6", "1. e4 e5 2. Nf3 d6"),
  new FullOpening("C41", "Philidor Defense", "rnbqkbnr/ppp2ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4", "1. e4 e5 2. Nf3 d6 3. d4"),
  new FullOpening("C41", "Philidor Defense", "rnbqkbnr/ppp2ppp/3p4/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 f1c4", "1. e4 e5 2. Nf3 d6 3. Bc4"),
  new FullOpening("C41", "Philidor Defense", "rnbqk1nr/ppp1bppp/3p4/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 d7d6 f1c4 f8e7", "1. e4 e5 2. Nf3 d6 3. Bc4 Be7"),
  new FullOpening("C41", "Philidor Defense: Albin-Blackburne Gambit", "r2qkbnr/pppn1ppp/3p4/4P3/4P1b1/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 c8g4 d4e5 b8d7", "1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Nd7"),
  new FullOpening("C41", "Philidor Defense: Berger Variation", "r2q1rk1/pp2bppp/2npbn2/2p3B1/4P3/2N2N2/PPP1BPPP/R2QR1K1 b - -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 f3d4 g8f6 b1c3 f8e7 f1e2 e8g8 e1g1 c7c5 d4f3 b8c6 c1g5 c8e6 f1e1", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Nf6 5. Nc3 Be7 6. Be2 O-O 7. O-O c5 8. Nf3 Nc6 9. Bg5 Be6 10. Re1"),
  new FullOpening("C41", "Philidor Defense: Bird Gambit", "rnbqkbnr/ppp2ppp/3p4/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 c2c3", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. c3"),
  new FullOpening("C41", "Philidor Defense: Boden Variation", "rn1qkbnr/pppb1ppp/3p4/8/3QP3/5N2/PPP2PPP/RNB1KB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 d1d4 c8d7", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Qxd4 Bd7"),
  new FullOpening("C41", "Philidor Defense: Exchange Variation", "rnbqkbnr/ppp2ppp/3p4/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4", "1. e4 e5 2. Nf3 d6 3. d4 exd4"),
  new FullOpening("C41", "Philidor Defense: Exchange Variation", "rnbqkbnr/ppp2ppp/3p4/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 f3d4", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4"),
  new FullOpening("C41", "Philidor Defense: Exchange Variation", "rnbqkb1r/ppp2ppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 f3d4 g8f6", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 Nf6"),
  new FullOpening("C41", "Philidor Defense: Hanham Variation", "r1bqkbnr/pppn1ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7", "1. e4 e5 2. Nf3 d6 3. d4 Nd7"),
  new FullOpening("C41", "Philidor Defense: Hanham Variation, Delmar Variation", "r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/2P2N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 f1c4 c7c6 c2c3", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. c3"),
  new FullOpening("C41", "Philidor Defense: Hanham Variation, Krause Variation", "r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 f1c4 c7c6 e1g1", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. O-O"),
  new FullOpening("C41", "Philidor Defense: Hanham Variation, Schlechter Variation", "r1bqkbnr/pp1n1ppp/2pp4/4p3/2BPP3/2N2N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 f1c4 c7c6 b1c3", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. Nc3"),
  new FullOpening("C41", "Philidor Defense: Hanham Variation, Sharp Variation", "r1bqkbnr/ppp2ppp/1n1p4/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 f1c4 d7b6", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 Nb6"),
  new FullOpening("C41", "Philidor Defense: Hanham Variation, Steiner Variation", "r1bqk1nr/pp1nbppp/2pp4/4P3/2B1P3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 f1c4 c7c6 e1g1 f8e7 d4e5", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. O-O Be7 6. dxe5"),
  new FullOpening("C41", "Philidor Defense: Hanham, Berger Variation", "r1bq1rk1/pp1nbppp/2p4n/3pp1N1/2BPPP2/2P5/PP4PP/RNBQ1RK1 w - -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 f1c4 c7c6 f3g5 g8h6 f2f4 f8e7 e1g1 e8g8 c2c3 d6d5", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. Ng5 Nh6 6. f4 Be7 7. O-O O-O 8. c3 d5"),
  new FullOpening("C41", "Philidor Defense: Hanham, Kmoch Variation", "r1bqkbnr/pp1n1ppp/2pp4/4p1N1/2BPP3/8/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 f1c4 c7c6 f3g5", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Bc4 c6 5. Ng5"),
  new FullOpening("C41", "Philidor Defense: Larsen Variation", "rnbqkbnr/ppp2p1p/3p2p1/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 f3d4 g7g6", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 g6"),
  new FullOpening("C41", "Philidor Defense: Lion Variation", "r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 b1c3 b8d7", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7"),
  new FullOpening("C41", "Philidor Defense: Lion Variation, Bishop Sacrifice", "r1bqk2r/pppnbBpp/3p1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 b1c3 b8d7 f1c4 f8e7 c4f7", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 Be7 6. Bxf7+"),
  new FullOpening("C41", "Philidor Defense: Lion Variation, Delayed Bishop Sacrifice", "r1bqk2r/pppnbBpp/5n2/4p3/4P3/2N2N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 d7d6 d2d4 g8f6 b1c3 b8d7 g1f3 e7e5 f1c4 f8e7 d4e5 d6e5 c4f7", "1. e4 d6 2. d4 Nf6 3. Nc3 Nbd7 4. Nf3 e5 5. Bc4 Be7 6. dxe5 dxe5 7. Bxf7+"),
  new FullOpening("C41", "Philidor Defense: Lion Variation, Forcing Line", "r1bq2k1/pppnbrpp/3pNn2/4p3/3PP3/2N5/PPP2PPP/R1BQK2R b KQ -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 b1c3 b8d7 f1c4 f8e7 f3g5 e8g8 c4f7 f8f7 g5e6", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 Be7 6. Ng5 O-O 7. Bxf7+ Rxf7 8. Ne6"),
  new FullOpening("C41", "Philidor Defense: Lion Variation, Lion's Claw", "r1bqk2r/pppnbpp1/3p1n1p/4p3/2BPP3/2N2N2/PPP2PPP/R1BQ1RK1 w kq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 b1c3 b8d7 f1c4 h7h6 e1g1 f8e7", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 h6 6. O-O Be7"),
  new FullOpening("C41", "Philidor Defense: Lion Variation, Shirov Gambit", "r1bqkb1r/pppn1ppp/3p1n2/4p3/3PP1P1/2N2N2/PPP2P1P/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 b8d7 b1c3 g8f6 g2g4", "1. e4 e5 2. Nf3 d6 3. d4 Nd7 4. Nc3 Ngf6 5. g4"),
  new FullOpening("C41", "Philidor Defense: Lion Variation, Sozin Variation", "r1bq1rk1/pp1nbppp/2pp1n2/8/P1BpP3/2N2N2/1PP1QPPP/R1B2RK1 w - -", "e2e4 d7d6 d2d4 g8f6 b1c3 e7e5 g1f3 b8d7 f1c4 f8e7 e1g1 e8g8 d1e2 c7c6 a2a4 e5d4", "1. e4 d6 2. d4 Nf6 3. Nc3 e5 4. Nf3 Nbd7 5. Bc4 Be7 6. O-O O-O 7. Qe2 c6 8. a4 exd4"),
  new FullOpening("C41", "Philidor Defense: Lopez Countergambit", "rnbqkbnr/ppp3pp/3p4/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 d7d6 f1c4 f7f5", "1. e4 e5 2. Nf3 d6 3. Bc4 f5"),
  new FullOpening("C41", "Philidor Defense: Lopez Countergambit, Jaenisch Variation", "rnbqkb1r/ppp3pN/3p3n/5p2/2BpP3/8/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 f7f5 f1c4 e5d4 f3g5 g8h6 g5h7", "1. e4 e5 2. Nf3 d6 3. d4 f5 4. Bc4 exd4 5. Ng5 Nh6 6. Nxh7"),
  new FullOpening("C41", "Philidor Defense: Morphy Gambit", "rnbqkbnr/ppp2ppp/3p4/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 f1c4", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Bc4"),
  new FullOpening("C41", "Philidor Defense: Nimzowitsch Variation", "rnbqkb1r/ppp2ppp/3p1n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6", "1. e4 e5 2. Nf3 d6 3. d4 Nf6"),
  new FullOpening("C41", "Philidor Defense: Nimzowitsch Variation", "rnbqkb1r/ppp2ppp/3p1n2/4P3/4P3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 d4e5", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5"),
  new FullOpening("C41", "Philidor Defense: Nimzowitsch Variation, Klein Variation", "rnbqkb1r/ppp2ppp/3p1n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 f1c4", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Bc4"),
  new FullOpening("C41", "Philidor Defense: Nimzowitsch Variation, Rellstab Variation", "rnbqkb1r/ppp2ppp/3p4/3QP3/4n3/5N2/PPP2PPP/RNB1KB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 d4e5 f6e4 d1d5", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5 Nxe4 5. Qd5"),
  new FullOpening("C41", "Philidor Defense: Nimzowitsch Variation, Sokolsky Variation", "rnbqkb1r/ppp2ppp/3p4/4P3/4n3/5N2/PPPN1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 d4e5 f6e4 b1d2", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. dxe5 Nxe4 5. Nbd2"),
  new FullOpening("C41", "Philidor Defense: Nimzowitsch, Larobok Variation", "r1bq1rk1/pppnbBpp/3p1n2/4p1N1/3PP3/2N5/PPP2PPP/R1BQK2R b KQ -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 b1c3 b8d7 f1c4 f8e7 f3g5 e8g8 c4f7", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Nc3 Nbd7 5. Bc4 Be7 6. Ng5 O-O 7. Bxf7+"),
  new FullOpening("C41", "Philidor Defense: Nimzowitsch, Locock Variation", "rnbqkb1r/ppp2ppp/3p1n2/4p1N1/3PP3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 g8f6 f3g5", "1. e4 e5 2. Nf3 d6 3. d4 Nf6 4. Ng5"),
  new FullOpening("C41", "Philidor Defense: Paulsen Attack", "rnbqkbnr/ppp2ppp/8/3P4/3N4/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 e5d4 f3d4 d6d5 e4d5", "1. e4 e5 2. Nf3 d6 3. d4 exd4 4. Nxd4 d5 5. exd5"),
  new FullOpening("C41", "Philidor Defense: Philidor Countergambit", "rnbqkbnr/ppp3pp/3p4/4pp2/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 f7f5", "1. e4 e5 2. Nf3 d6 3. d4 f5"),
  new FullOpening("C41", "Philidor Defense: Philidor Countergambit, Berger Variation", "rnbqk1nr/ppp3pp/4P3/2bp2N1/4p3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 f7f5 d4e5 f5e4 f3g5 d6d5 e5e6 f8c5 b1c3", "1. e4 e5 2. Nf3 d6 3. d4 f5 4. dxe5 fxe4 5. Ng5 d5 6. e6 Bc5 7. Nc3"),
  new FullOpening("C41", "Philidor Defense: Philidor Countergambit, Zukertort Variation", "rnbqkbnr/ppp3pp/3p4/4pp2/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 f7f5 b1c3", "1. e4 e5 2. Nf3 d6 3. d4 f5 4. Nc3"),
  new FullOpening("C41", "Philidor Defense: Philidor Countergambit, del Rio Attack", "rnbqkbnr/ppp3pp/4P3/3p2N1/4p3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 f7f5 d4e5 f5e4 f3g5 d6d5 e5e6", "1. e4 e5 2. Nf3 d6 3. d4 f5 4. dxe5 fxe4 5. Ng5 d5 6. e6"),
  new FullOpening("C41", "Philidor Defense: Philidor Gambit", "rn1qkbnr/pppb1ppp/3p4/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 d7d6 d2d4 c8d7", "1. e4 e5 2. Nf3 d6 3. d4 Bd7"),
  new FullOpening("C41", "Philidor Defense: Steinitz Variation", "rnbqk1nr/ppp1bppp/3p4/4p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 d7d6 f1c4 f8e7 c2c3", "1. e4 e5 2. Nf3 d6 3. Bc4 Be7 4. c3"),
  new FullOpening("C42", "Russian Game", "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6", "1. e4 e5 2. Nf3 Nf6"),
  new FullOpening("C42", "Russian Game", "rnbqkb1r/pppp1ppp/5n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5", "1. e4 e5 2. Nf3 Nf6 3. Nxe5"),
  new FullOpening("C42", "Russian Game", "rnbqkb1r/ppp2ppp/3p1n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6"),
  new FullOpening("C42", "Russian Game", "rnbqkb1r/ppp2ppp/3p1n2/8/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3"),
  new FullOpening("C42", "Russian Game", "rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4"),
  new FullOpening("C42", "Russian Game: Classical Attack", "rnbqkb1r/ppp2ppp/3p4/8/3Pn3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4"),
  new FullOpening("C42", "Russian Game: Classical Attack, Berger Variation", "r2qk2r/ppp1b1pp/2n5/3p1p2/3Pn1b1/2PB1N2/PP1N1PPP/R1BQR1K1 b kq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8e7 e1g1 b8c6 f1e1 c8g4 c2c3 f7f5 b1d2", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Be7 7. O-O Nc6 8. Re1 Bg4 9. c3 f5 10. Nbd2"),
  new FullOpening("C42", "Russian Game: Classical Attack, Chigorin Variation", "r1bqk2r/ppp1bppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8e7 e1g1 b8c6 f1e1", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Be7 7. O-O Nc6 8. Re1"),
  new FullOpening("C42", "Russian Game: Classical Attack, Chigorin Variation, Browne Attack", "r1bqk2r/ppp1bppp/8/3P4/1n1Pn3/3B1N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 b8c6 e1g1 f8e7 c2c4 c6b4 c4d5", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Nc6 7. O-O Be7 8. c4 Nb4 9. cxd5"),
  new FullOpening("C42", "Russian Game: Classical Attack, Chigorin Variation, Main Line", "r1bqk2r/ppp1bppp/8/3p4/1nPPn3/5N2/PP2BPPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 b8c6 e1g1 f8e7 c2c4 c6b4 d3e2", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Nc6 7. O-O Be7 8. c4 Nb4 9. Be2"),
  new FullOpening("C42", "Russian Game: Classical Attack, Closed Variation", "rnbqkb1r/ppp2ppp/3p1n2/8/3P4/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 e4f6", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 Nf6"),
  new FullOpening("C42", "Russian Game: Classical Attack, Jaenisch Variation", "r1bqk2r/ppp1bppp/2n5/3p4/2PPn3/3B1N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 b8c6 e1g1 f8e7 c2c4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Nc6 7. O-O Be7 8. c4"),
  new FullOpening("C42", "Russian Game: Classical Attack, Krause Variation", "r2qk2r/ppp1b1pp/2n5/3p1p2/2PPn1b1/3B1N2/PP3PPP/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 b8c6 e1g1 f8e7 f1e1 c8g4 c2c3 f7f5 c3c4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Nc6 7. O-O Be7 8. Re1 Bg4 9. c3 f5 10. c4"),
  new FullOpening("C42", "Russian Game: Classical Attack, Marshall Trap", "rn1q1rk1/ppp3pp/8/3P1p2/3Pn1b1/3B1N2/PP3PPb/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8d6 e1g1 e8g8 c2c4 c8g4 c4d5 f7f5 f1e1 d6h2", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6 7. O-O O-O 8. c4 Bg4 9. cxd5 f5 10. Re1 Bxh2+"),
  new FullOpening("C42", "Russian Game: Classical Attack, Marshall Variation", "rnbqk2r/ppp2ppp/3b4/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8d6", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6"),
  new FullOpening("C42", "Russian Game: Classical Attack, Marshall Variation, Chinese Gambit", "rn1q1rk1/pp3ppp/2pb4/3p4/2PPn1b1/3B1N2/PP3PPP/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8d6 e1g1 e8g8 c2c4 c7c6 f1e1 c8g4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6 7. O-O O-O 8. c4 c6 9. Re1 Bg4"),
  new FullOpening("C42", "Russian Game: Classical Attack, Maróczy Variation", "r2qk2r/ppp3pp/2n5/3p1p2/2PPn1bb/3B1N2/PP3PPP/RNBQR1K1 w kq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8e7 e1g1 b8c6 f1e1 c8g4 c2c3 f7f5 c3c4 e7h4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Be7 7. O-O Nc6 8. Re1 Bg4 9. c3 f5 10. c4 Bh4"),
  new FullOpening("C42", "Russian Game: Classical Attack, Mason Variation", "rnbq1rk1/ppp1bppp/8/3p4/3Pn3/3B1N2/PPP2PPP/RNBQ1RK1 w - -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8e7 e1g1 e8g8", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Be7 7. O-O O-O"),
  new FullOpening("C42", "Russian Game: Classical Attack, Mason-Showalter Variation", "r1bqkb1r/ppp2ppp/2n5/3p4/3Pn3/3B1N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 b8c6", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Nc6"),
  new FullOpening("C42", "Russian Game: Classical Attack, Staunton Variation", "rnbq1rk1/pp3ppp/2pb4/3p4/2PPn3/3B1N2/PP3PPP/RNBQ1RK1 w - -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8d6 e1g1 e8g8 c2c4 c7c6", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6 7. O-O O-O 8. c4 c6"),
  new FullOpening("C42", "Russian Game: Classical Attack, Tarrasch Variation", "rn1q1rk1/ppp2ppp/3b4/3p4/2PPn1b1/3B1N2/PP3PPP/RNBQ1RK1 w - -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d4 d6d5 f1d3 f8d6 e1g1 e8g8 c2c4 c8g4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d4 d5 6. Bd3 Bd6 7. O-O O-O 8. c4 Bg4"),
  new FullOpening("C42", "Russian Game: Cochrane Gambit", "rnbqkb1r/ppp2Npp/3p1n2/8/4P3/8/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f7", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7"),
  new FullOpening("C42", "Russian Game: Cochrane Gambit, Bishop Check Line", "rnbq1b1r/ppp2kpp/3p1n2/8/2B1P3/8/PPPP1PPP/RNBQK2R b KQ -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f7 e8f7 f1c4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7 Kxf7 5. Bc4+"),
  new FullOpening("C42", "Russian Game: Cochrane Gambit, Center Variation", "rnbq1b1r/ppp2kpp/3p1n2/8/3PP3/8/PPP2PPP/RNBQKB1R b KQ -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f7 e8f7 d2d4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nxf7 Kxf7 5. d4"),
  new FullOpening("C42", "Russian Game: Cozio Attack", "rnbqkb1r/ppp2ppp/3p4/8/4n3/5N2/PPPPQPPP/RNB1KB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d1e2", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Qe2"),
  new FullOpening("C42", "Russian Game: Damiano Variation", "rnbqkb1r/pppp1ppp/8/4N3/4n3/8/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 f6e4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4"),
  new FullOpening("C42", "Russian Game: Damiano Variation, Kholmov Gambit", "rnb1kb1r/ppppqppp/8/4N3/4n3/8/PPPPQPPP/RNB1KB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 f6e4 d1e2 d8e7", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nxe4 4. Qe2 Qe7"),
  new FullOpening("C42", "Russian Game: French Attack", "rnbqkb1r/ppp2ppp/3p4/8/4n3/3P1N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 d2d3", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. d3"),
  new FullOpening("C42", "Russian Game: Italian Variation", "rnbqkb1r/pppp1ppp/5n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f1c4", "1. e4 e5 2. Nf3 Nf6 3. Bc4"),
  new FullOpening("C42", "Russian Game: Karklins-Martinovsky Variation", "rnbqkb1r/ppp2ppp/3p1n2/8/4P3/3N4/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5d3", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nd3"),
  new FullOpening("C42", "Russian Game: Kaufmann Attack", "rnbqkb1r/ppp2ppp/3p4/8/2P1n3/5N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 c2c4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. c4"),
  new FullOpening("C42", "Russian Game: Millennium Attack", "rnbqkb1r/ppp2ppp/3p4/8/4n3/3B1N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 f1d3", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Bd3"),
  new FullOpening("C42", "Russian Game: Moody Gambit", "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/5N2/PPP1QPPP/RNB1KB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 d1e2 b8c6 d2d4", "1. e4 e5 2. Nf3 Nf6 3. Qe2 Nc6 4. d4"),
  new FullOpening("C42", "Russian Game: Nimzowitsch Attack", "rnbqkb1r/ppp2ppp/3p4/8/4n3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5f3 f6e4 b1c3", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nf3 Nxe4 5. Nc3"),
  new FullOpening("C42", "Russian Game: Paulsen Attack", "rnbqkb1r/ppp2ppp/3p1n2/8/2N1P3/8/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 d7d6 e5c4", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 d6 4. Nc4"),
  new FullOpening("C42", "Russian Game: Stafford Gambit", "r1bqkb1r/pppp1ppp/2n2n2/4N3/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 b8c6", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nc6"),
  new FullOpening("C42", "Russian Game: Stafford Gambit", "r1bqkb1r/ppp2ppp/2p2n2/8/4P3/8/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 f3e5 b8c6 e5c6 d7c6", "1. e4 e5 2. Nf3 Nf6 3. Nxe5 Nc6 4. Nxc6 dxc6"),
  new FullOpening("C42", "Russian Game: Three Knights Game", "rnbqkb1r/pppp1ppp/5n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 b1c3", "1. e4 e5 2. Nf3 Nf6 3. Nc3"),
  new FullOpening("C43", "Bishop's Opening: Urusov Gambit", "rnbqkb1r/pppp1ppp/5n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 f1c4 g8f6 d2d4 e5d4 g1f3", "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3"),
  new FullOpening("C43", "Bishop's Opening: Urusov Gambit, Keidansky Gambit", "rnbqkb1r/pppp1ppp/8/8/2BQn3/5N2/PPP2PPP/RNB1K2R b KQkq -", "e2e4 e7e5 f1c4 g8f6 d2d4 e5d4 g1f3 f6e4 d1d4", "1. e4 e5 2. Bc4 Nf6 3. d4 exd4 4. Nf3 Nxe4 5. Qxd4"),
  new FullOpening("C43", "Russian Game: Modern Attack", "rnbqkb1r/pppp1ppp/5n2/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4", "1. e4 e5 2. Nf3 Nf6 3. d4"),
  new FullOpening("C43", "Russian Game: Modern Attack", "rnbqkb1r/pppp1ppp/5n2/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 e5d4", "1. e4 e5 2. Nf3 Nf6 3. d4 exd4"),
  new FullOpening("C43", "Russian Game: Modern Attack, Bardeleben Variation", "r1bqkb1r/pppp1ppp/2n5/2n1P3/3N4/8/PPP1QPPP/RNB1KB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 e5d4 e4e5 f6e4 d1e2 e4c5 f3d4 b8c6", "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qe2 Nc5 6. Nxd4 Nc6"),
  new FullOpening("C43", "Russian Game: Modern Attack, Center Attack", "rnbqkb1r/pppp1ppp/8/4P3/3Qn3/5N2/PPP2PPP/RNB1KB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 e5d4 e4e5 f6e4 d1d4", "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qxd4"),
  new FullOpening("C43", "Russian Game: Modern Attack, Center Variation", "rnbqkb1r/pppp1ppp/8/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 f6e4 f1d3", "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3"),
  new FullOpening("C43", "Russian Game: Modern Attack, Murrey Variation", "r1bqkb1r/pppp1ppp/2n5/4p3/3Pn3/3B1N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 f6e4 f1d3 b8c6", "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3 Nc6"),
  new FullOpening("C43", "Russian Game: Modern Attack, Steinitz Variation", "rnbqkb1r/pppp1ppp/8/4P3/3pn3/5N2/PPP1QPPP/RNB1KB1R b KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 e5d4 e4e5 f6e4 d1e2", "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Qe2"),
  new FullOpening("C43", "Russian Game: Modern Attack, Symmetrical Variation", "rnbqkb1r/ppp2ppp/5n2/3pp3/3PP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 d7d5", "1. e4 e5 2. Nf3 Nf6 3. d4 d5"),
  new FullOpening("C43", "Russian Game: Modern Attack, Symmetrical Variation", "rnbqkb1r/pppp1ppp/8/4p3/3Pn3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 f6e4", "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4"),
  new FullOpening("C43", "Russian Game: Modern Attack, Tal Gambit", "rnbqkb1r/pppp1ppp/8/1B2P3/3pn3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 g8f6 d2d4 e5d4 e4e5 f6e4 f1b5", "1. e4 e5 2. Nf3 Nf6 3. d4 exd4 4. e5 Ne4 5. Bb5"),
  new FullOpening("C43", "Russian Game: Modern Attack, Trifunovic Variation", "rnbq1rk1/ppp2ppp/8/3pb3/2PPn3/3B4/PP3PPP/RNBQ1RK1 w - -", "e2e4 e7e5 g1f3 g8f6 d2d4 f6e4 f1d3 d7d5 f3e5 f8d6 e1g1 e8g8 c2c4 d6e5", "1. e4 e5 2. Nf3 Nf6 3. d4 Nxe4 4. Bd3 d5 5. Nxe5 Bd6 6. O-O O-O 7. c4 Bxe5"),
  new FullOpening("C44", "Dresden Opening: The Goblin", "r1bqkb1r/pppp1ppp/2n2n2/4N3/2P1P3/8/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c4 g8f6 f3e5", "1. e4 e5 2. Nf3 Nc6 3. c4 Nf6 4. Nxe5"),
  new FullOpening("C44", "Irish Gambit", "r1bqkbnr/pppp1ppp/2n5/4N3/4P3/8/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f3e5", "1. e4 e5 2. Nf3 Nc6 3. Nxe5"),
  new FullOpening("C44", "King's Knight Opening: Konstantinopolsky", "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5NP1/PPPP1P1P/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 g2g3", "1. e4 e5 2. Nf3 Nc6 3. g3"),
  new FullOpening("C44", "King's Knight Opening: Normal Variation", "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPP1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6", "1. e4 e5 2. Nf3 Nc6"),
  new FullOpening("C44", "King's Pawn Game: Dresden Opening", "r1bqkbnr/pppp1ppp/2n5/4p3/2P1P3/5N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c4", "1. e4 e5 2. Nf3 Nc6 3. c4"),
  new FullOpening("C44", "King's Pawn Game: Pachman Wing Gambit", "r1bqkbnr/pppp1ppp/2n5/4p3/1P2P3/5N2/P1PP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b2b4", "1. e4 e5 2. Nf3 Nc6 3. b4"),
  new FullOpening("C44", "King's Pawn Game: Schulze-Müller Gambit", "r1bqkbnr/pppp1ppp/8/4n3/3PP3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f3e5 c6e5 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Nxe5 Nxe5 4. d4"),
  new FullOpening("C44", "King's Pawn Game: Tayler Opening", "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/5N2/PPPPBPPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1e2", "1. e4 e5 2. Nf3 Nc6 3. Be2"),
  new FullOpening("C44", "King's Pawn Game: Tayler Opening", "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/5N2/PPP1BPPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1e2 g8f6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d4"),
  new FullOpening("C44", "King's Pawn Game: Tayler Opening, Basman Gambit", "r1bqkb1r/pppp1ppp/2n2n2/4P3/3p4/5N2/PPP1BPPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1e2 g8f6 d2d4 e5d4 e4e5", "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d4 exd4 5. e5"),
  new FullOpening("C44", "King's Pawn Game: Tayler Opening, Inverted Hanham", "r1bqkb1r/ppp2ppp/2n2n2/3pp3/4P3/3P1N2/PPPNBPPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1e2 g8f6 d2d3 d7d5 b1d2", "1. e4 e5 2. Nf3 Nc6 3. Be2 Nf6 4. d3 d5 5. Nbd2"),
  new FullOpening("C44", "Latvian Gambit: Clam Gambit", "r1bqkbnr/pppp2pp/2n5/4pP2/8/3P1N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d3 f7f5 e4f5", "1. e4 e5 2. Nf3 Nc6 3. d3 f5 4. exf5"),
  new FullOpening("C44", "Ponziani Opening", "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3", "1. e4 e5 2. Nf3 Nc6 3. c3"),
  new FullOpening("C44", "Ponziani Opening: Caro Gambit", "r2qkbnr/pppb1ppp/2n5/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 d7d5 d1a4 c8d7", "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 Bd7"),
  new FullOpening("C44", "Ponziani Opening: Jaenisch Counterattack", "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 g8f6", "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6"),
  new FullOpening("C44", "Ponziani Opening: Leonhardt Variation", "r1bqkb1r/ppp2ppp/2n2n2/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 d7d5 d1a4 g8f6", "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 Nf6"),
  new FullOpening("C44", "Ponziani Opening: Neumann Gambit", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 g8f6 f1c4", "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. Bc4"),
  new FullOpening("C44", "Ponziani Opening: Ponziani Countergambit", "r1bqkbnr/pppp2pp/2n5/4pp2/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. c3 f5"),
  new FullOpening("C44", "Ponziani Opening: Ponziani Countergambit, Cordel Variation", "rnbqk2r/ppp1b1pp/3p1n2/3Pp3/4N3/2PB4/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 f7f5 d2d4 d7d6 d4d5 f5e4 f3g5 c6b8 g5e4 g8f6 f1d3 f8e7", "1. e4 e5 2. Nf3 Nc6 3. c3 f5 4. d4 d6 5. d5 fxe4 6. Ng5 Nb8 7. Nxe4 Nf6 8. Bd3 Be7"),
  new FullOpening("C44", "Ponziani Opening: Ponziani Countergambit, Schmidt Attack", "r1bqkbnr/ppp3pp/2np4/3Ppp2/4P3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 f7f5 d2d4 d7d6 d4d5", "1. e4 e5 2. Nf3 Nc6 3. c3 f5 4. d4 d6 5. d5"),
  new FullOpening("C44", "Ponziani Opening: Romanishin Variation", "r1bqk1nr/ppppbppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 f8e7", "1. e4 e5 2. Nf3 Nc6 3. c3 Be7"),
  new FullOpening("C44", "Ponziani Opening: Réti Variation", "r1bqkb1r/ppppnppp/2n5/4p3/4P3/2P2N2/PP1P1PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 g8e7", "1. e4 e5 2. Nf3 Nc6 3. c3 Nge7"),
  new FullOpening("C44", "Ponziani Opening: Spanish Variation", "r1bqkbnr/ppp2ppp/2n5/1B1pp3/4P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 d7d5 f1b5", "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Bb5"),
  new FullOpening("C44", "Ponziani Opening: Spanish Variation, Harrwitz Attack, Nikitin Gambit", "r1b1kbnr/ppp2ppp/2n5/1B1qN3/Q3p3/2P5/PP1P1PPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 d7d5 f1b5 d5e4 f3e5 d8d5 d1a4", "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Bb5 dxe4 5. Nxe5 Qd5 6. Qa4"),
  new FullOpening("C44", "Ponziani Opening: Steinitz Variation", "r1bqkbnr/ppp3pp/2n2p2/3pp3/Q3P3/2P2N2/PP1P1PPP/RNB1KB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 d7d5 d1a4 f7f6", "1. e4 e5 2. Nf3 Nc6 3. c3 d5 4. Qa4 f6"),
  new FullOpening("C44", "Ponziani Opening: Vukovic Gambit", "r1bqk2r/pppp1ppp/2n5/2bPp3/4n3/2P2N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 c2c3 g8f6 d2d4 f6e4 d4d5 f8c5", "1. e4 e5 2. Nf3 Nc6 3. c3 Nf6 4. d4 Nxe4 5. d5 Bc5"),
  new FullOpening("C44", "Scotch Game", "r1bqkbnr/pppp1ppp/2n5/4p3/3PP3/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. d4"),
  new FullOpening("C44", "Scotch Game", "r1bqkbnr/pppp1ppp/2n5/8/3pP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4"),
  new FullOpening("C44", "Scotch Game: Benima Defense", "r1bqk1nr/ppppbppp/2n5/8/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8e7 d2d4 e5d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7 4. d4 exd4"),
  new FullOpening("C44", "Scotch Game: Cochrane Variation", "r1bqkb1r/pp1p1Npp/2p1nn2/8/2B1P3/8/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 c6d4 f3e5 d4e6 f1c4 c7c6 e1g1 g8f6 e5f7", "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxe5 Ne6 5. Bc4 c6 6. O-O Nf6 7. Nxf7"),
  new FullOpening("C44", "Scotch Game: Cochrane Variation", "r1bqk1nr/pppp1ppp/2n5/b3P3/2B5/2P2N2/P4PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8b4 c2c3 d4c3 b2c3 b4a5 e4e5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bb4+ 5. c3 dxc3 6. bxc3 Ba5 7. e5"),
  new FullOpening("C44", "Scotch Game: Cochrane-Shumov Defense", "r1bq3r/ppp2k1p/2n3p1/2Qp4/3pP3/8/PPP2PPP/RNB1K2R w KQ -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8c5 f3g5 g8h6 g5f7 h6f7 c4f7 e8f7 d1h5 g7g6 h5c5 d7d5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. Ng5 Nh6 6. Nxf7 Nxf7 7. Bxf7+ Kxf7 8. Qh5+ g6 9. Qxc5 d5"),
  new FullOpening("C44", "Scotch Game: Göring Gambit", "r1bqkbnr/pppp1ppp/2n5/8/3pP3/2P2N2/PP3PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 c2c3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3"),
  new FullOpening("C44", "Scotch Game: Göring Gambit, Bardeleben Variation", "r1bqk2r/pppp1ppp/2n2n2/8/1bB1P3/2N2N2/PP3PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 c2c3 d4c3 f1c4 g8f6 b1c3 f8b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Bc4 Nf6 6. Nxc3 Bb4"),
  new FullOpening("C44", "Scotch Game: Göring Gambit, Double Pawn Sacrifice", "r1bqkbnr/pppp1ppp/2n5/8/2B1P3/2p2N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 c2c3 d4c3 f1c4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Bc4"),
  new FullOpening("C44", "Scotch Game: Göring Gambit, Main Line", "r1bqk1nr/pppp1ppp/2n5/8/1b2P3/2N2N2/PP3PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 c2c3 d4c3 b1c3 f8b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Nxc3 Bb4"),
  new FullOpening("C44", "Scotch Game: Hanneken Variation", "r1bq1rk1/pppp1ppp/5n2/4n1N1/1bB5/8/PB3PPP/RN1Q1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8b4 c2c3 d4c3 e1g1 c3b2 c1b2 g8f6 f3g5 e8g8 e4e5 c6e5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bb4+ 5. c3 dxc3 6. O-O cxb2 7. Bxb2 Nf6 8. Ng5 O-O 9. e5 Nxe5"),
  new FullOpening("C44", "Scotch Game: Haxo Gambit", "r1bqk1nr/pppp1ppp/2n5/2b5/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8c5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5"),
  new FullOpening("C44", "Scotch Game: Lolli Variation", "r1bqkbnr/pppp1ppp/8/4p3/3nP3/5N2/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 c6d4", "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4"),
  new FullOpening("C44", "Scotch Game: Napoleon Gambit", "r1bqkbnr/pppp1ppp/8/8/2BpP3/8/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 c6d4 f3d4 e5d4 f1c4", "1. e4 e5 2. Nf3 Nc6 3. d4 Nxd4 4. Nxd4 exd4 5. Bc4"),
  new FullOpening("C44", "Scotch Game: Relfsson Gambit", "r1bqkbnr/pppp1ppp/2n5/1B6/3pP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1b5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bb5"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit", "r1bqkbnr/pppp1ppp/2n5/8/2BpP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit, Advance Variation", "r1bqkb1r/pppp1ppp/2n2n2/4P3/2Bp4/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e4e5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. e5"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit, Cochrane-Anderssen Variation", "r2qk1nr/ppp2ppp/2np4/2b5/2BpP1b1/2P2N2/PP3PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8c5 e1g1 d7d6 c2c3 c8g4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. O-O d6 6. c3 Bg4"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit, Dubois Réti Defense", "r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit, Göring Gambit Declined", "r1bqkbnr/ppp2ppp/2n5/3p4/3pP3/2P2N2/PP3PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 c2c3 d7d5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 d5"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit, Kingside Variation", "r1bqkb1r/pppp1ppp/2n5/4P3/2Bp2n1/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e4e5 f6g4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. e5 Ng4"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit, London Defense", "r1bqk1nr/pppp1ppp/2n5/8/1bBpP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bb4+"),
  new FullOpening("C44", "Scotch Game: Scotch Gambit, Sarratt Variation", "r1bqk1nr/pppp1ppp/2n5/2b3N1/2BpP3/8/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8c5 f3g5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. Ng5"),
  new FullOpening("C44", "Scotch Game: Sea-cadet Mate", "r2q1bnr/ppp1kBpp/3p4/3NN3/4P3/8/PP3PPP/R1Bb1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 c2c3 d4c3 b1c3 d7d6 f1c4 c8g4 e1g1 c6e5 f3e5 g4d1 c4f7 e8e7 c3d5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. c3 dxc3 5. Nxc3 d6 6. Bc4 Bg4 7. O-O Ne5 8. Nxe5 Bxd1 9. Bxf7+ Ke7 10. Nd5#"),
  new FullOpening("C44", "Scotch Game: Vitzthum Attack", "r1bqk2r/pppp1ppp/2n4n/2b3NQ/2BpP3/8/PPP2PPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8c5 f3g5 g8h6 d1h5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. Ng5 Nh6 6. Qh5"),
  new FullOpening("C45", "Scotch Game", "r1bqkbnr/pppp1ppp/2n5/8/3NP3/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4"),
  new FullOpening("C45", "Scotch Game", "r1b1k1nr/pppp1ppp/2n5/1N6/1b2P2q/8/PPPB1PPP/RN1QKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4b5 f8b4 c1d2", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Bd2"),
  new FullOpening("C45", "Scotch Game: Alekhine Gambit", "r1bqkb1r/pppp1ppp/2n2n2/4P3/3N4/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 g8f6 e4e5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. e5"),
  new FullOpening("C45", "Scotch Game: Berger Variation", "N1bk3r/pp1p1ppp/2n2n2/8/1b6/P4B1q/1PPN1P1P/R1BQK2R b KQ -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4b5 f8b4 b1d2 h4e4 f1e2 e4g2 e2f3 g2h3 b5c7 e8d8 c7a8 g8f6 a2a3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Nd2 Qxe4+ 7. Be2 Qxg2 8. Bf3 Qh3 9. Nxc7+ Kd8 10. Nxa8 Nf6 11. a3"),
  new FullOpening("C45", "Scotch Game: Blumenfeld Attack", "r1b1k1nr/pppp1ppp/2n2q2/1Nb5/4P3/4B3/PPP2PPP/RN1QKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 c1e3 d8f6 d4b5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. Nb5"),
  new FullOpening("C45", "Scotch Game: Braune Variation", "r1b1kbnr/pppp1ppp/2n5/8/3NP2q/4B3/PPP2PPP/RN1QKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 c1e3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Be3"),
  new FullOpening("C45", "Scotch Game: Classical Variation", "r1bqk1nr/pppp1ppp/2n5/2b5/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5"),
  new FullOpening("C45", "Scotch Game: Classical Variation, Blackburne Attack", "r1b1k2r/ppppnppp/2n2q2/2b5/3NP3/2P1B3/PP1Q1PPP/RN2KB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 c1e3 d8f6 c2c3 g8e7 d1d2", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Nge7 7. Qd2"),
  new FullOpening("C45", "Scotch Game: Classical Variation, Intermezzo Variation", "r1b1k1nr/pppp1ppp/2N2q2/2b5/4P3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 d4c6 d8f6", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nxc6 Qf6"),
  new FullOpening("C45", "Scotch Game: Classical Variation, Millennium Variation", "r1b1k1nr/pppp1ppp/2n3q1/2b5/3NP3/2P1B3/PP3PPP/RN1QKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 c1e3 d8f6 c2c3 f6g6", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Qg6"),
  new FullOpening("C45", "Scotch Game: Fraser Variation", "r1b1kbnr/pppp1ppp/2n5/8/4P2q/5N2/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4f3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nf3"),
  new FullOpening("C45", "Scotch Game: Ghulam-Kassim Variation", "r1bqkbnr/ppp2ppp/3p4/8/3QP3/3B4/PPP2PPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 c6d4 d1d4 d7d6 f1d3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nxd4 5. Qxd4 d6 6. Bd3"),
  new FullOpening("C45", "Scotch Game: Gottschall Variation", "1rb2rk1/pp3ppp/5q2/3P4/1n6/2P1Q3/PP3PPP/RN2KB1R w KQ -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 c1e3 d8f6 c2c3 g8e7 d1d2 d7d5 d4b5 c5e3 d2e3 e8g8 b5c7 a8b8 c7d5 e7d5 e4d5 c6b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Nge7 7. Qd2 d5 8. Nb5 Bxe3 9. Qxe3 O-O 10. Nxc7 Rb8 11. Nxd5 Nxd5 12. exd5 Nb4"),
  new FullOpening("C45", "Scotch Game: Horwitz Attack", "r1b1kbnr/pppp1ppp/2n5/1N6/4P2q/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4b5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5"),
  new FullOpening("C45", "Scotch Game: Horwitz Attack, Blackburne Variation", "r1bk2nr/pppp1ppp/2n5/1N6/4q3/8/PPPQBPPP/RN3RK1 b - -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4b5 f8b4 c1d2 h4e4 f1e2 e8d8 e1g1 b4d2 d1d2", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Bd2 Qxe4+ 7. Be2 Kd8 8. O-O Bxd2 9. Qxd2"),
  new FullOpening("C45", "Scotch Game: Horwitz Attack, McDonnell Variation", "r1bk2nr/pppp1ppp/2n5/1N6/P4q2/8/1PPNBPPP/R2Q1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4b5 f8b4 c1d2 h4e4 f1e2 e8d8 e1g1 b4d2 b1d2 e4f4 a2a4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Bd2 Qxe4+ 7. Be2 Kd8 8. O-O Bxd2 9. Nxd2 Qf4 10. a4"),
  new FullOpening("C45", "Scotch Game: Horwitz Attack, Vienna Variation", "r1bk2nr/pppp1ppp/2n5/1N6/2P2q2/8/PP1NBPPP/R2Q1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4b5 f8b4 c1d2 h4e4 f1e2 e8d8 e1g1 b4d2 b1d2 e4f4 c2c4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Bd2 Qxe4+ 7. Be2 Kd8 8. O-O Bxd2 9. Nxd2 Qf4 10. c4"),
  new FullOpening("C45", "Scotch Game: Malaniuk Variation", "r1bqk1nr/pppp1ppp/2n5/8/1b1NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bb4+"),
  new FullOpening("C45", "Scotch Game: Meitner Variation", "r1b1k2r/ppppnppp/2n2q2/2b5/4P3/2P1B3/PPN2PPP/RN1QKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 c1e3 d8f6 c2c3 g8e7 d4c2", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Nge7 7. Nc2"),
  new FullOpening("C45", "Scotch Game: Mieses Variation", "r1bqkb1r/p1pp1ppp/2p2n2/4P3/8/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 g8f6 d4c6 b7c6 e4e5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. e5"),
  new FullOpening("C45", "Scotch Game: Modern Defense", "r1b1k1nr/pppp1ppp/2n5/8/1b1NP2q/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 b1c3 f8b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nc3 Bb4"),
  new FullOpening("C45", "Scotch Game: Paulsen Attack", "r1b1k2r/ppppnppp/2n2q2/1Bb5/3NP3/2P1B3/PP3PPP/RN1QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 c1e3 d8f6 c2c3 g8e7 f1b5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Nge7 7. Bb5"),
  new FullOpening("C45", "Scotch Game: Paulsen Variation", "r1b1kbnr/pppp1ppp/2n5/5N2/4P2q/8/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4f5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nf5"),
  new FullOpening("C45", "Scotch Game: Paulsen, Gunsberg Defense", "r1bnk2r/ppppnppp/5q2/1Bb5/3NP3/2P1B3/PP3PPP/RN1QK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 c1e3 d8f6 c2c3 g8e7 f1b5 c6d8", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Be3 Qf6 6. c3 Nge7 7. Bb5 Nd8"),
  new FullOpening("C45", "Scotch Game: Potter Variation", "r1bqk1nr/pppp1ppp/2n5/2b5/4P3/1N6/PPP2PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 d4b3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nb3"),
  new FullOpening("C45", "Scotch Game: Romanishin Variation", "r1bqk1nr/pppp1ppp/2n5/8/1b2P3/1N6/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 f8c5 d4b3 c5b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Bc5 5. Nb3 Bb4+"),
  new FullOpening("C45", "Scotch Game: Rosenthal Variation", "r1bk2nr/pppp1ppp/2n3q1/1N6/8/8/PPPNBPPP/R2Q1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 d4b5 f8b4 c1d2 h4e4 f1e2 e8d8 e1g1 b4d2 b1d2 e4g6", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nb5 Bb4+ 6. Bd2 Qxe4+ 7. Be2 Kd8 8. O-O Bxd2 9. Nxd2 Qg6"),
  new FullOpening("C45", "Scotch Game: Schmidt Variation", "r1bqkb1r/pppp1ppp/2n2n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 g8f6", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6"),
  new FullOpening("C45", "Scotch Game: Steinitz Variation", "r1b1kbnr/pppp1ppp/2n5/8/3NP2q/8/PPP2PPP/RNBQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4"),
  new FullOpening("C45", "Scotch Game: Steinitz Variation", "r1b1kbnr/pppp1ppp/2n5/8/3NP2q/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 d8h4 b1c3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Qh4 5. Nc3"),
  new FullOpening("C45", "Scotch Game: Tartakower Variation", "r1bqkb1r/p1pp1ppp/2p2n2/8/4P3/8/PPPN1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f3d4 g8f6 d4c6 b7c6 b1d2", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Nxd4 Nf6 5. Nxc6 bxc6 6. Nd2"),
  new FullOpening("C46", "Three Knights Opening", "r1bqkbnr/pppp1ppp/2n5/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Nc3"),
  new FullOpening("C46", "Three Knights Opening", "r1bqk1nr/pppp1ppp/2n5/4p3/1b2P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 f8b4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4"),
  new FullOpening("C46", "Three Knights Opening: Schlechter Variation", "r1bqk2r/pppp1ppp/2n2n2/3Np3/1b2P3/5N2/PPPP1PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 f8b4 c3d5 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Bb4 4. Nd5 Nf6"),
  new FullOpening("C46", "Three Knights Opening: Steinitz Defense", "r1bqkbnr/pppp1p1p/2n3p1/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g7g6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6"),
  new FullOpening("C46", "Three Knights Opening: Steinitz-Rosenthal Variation", "r1bqkbnr/pppp1p1p/2n3p1/3N4/3pP3/5N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g7g6 d2d4 e5d4 c3d5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 g6 4. d4 exd4 5. Nd5"),
  new FullOpening("C46", "Three Knights Opening: Winawer Defense", "r1bqkbnr/pppp2pp/2n5/4pp2/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 f5"),
  new FullOpening("C47", "Four Knights Game", "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6"),
  new FullOpening("C47", "Four Knights Game: Gunsberg Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/4P3/P1N2N2/1PPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 a2a3", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. a3"),
  new FullOpening("C47", "Four Knights Game: Halloween Gambit", "r1bqkb1r/pppp1ppp/2n2n2/4N3/4P3/2N5/PPPP1PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f3e5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Nxe5"),
  new FullOpening("C47", "Four Knights Game: Halloween Gambit, Oldtimer Variation", "r1bqk1nr/pppp2pp/6n1/4Pp2/1bBP4/2N2Q2/PPP2PPP/R1B1K2R w KQkq f6", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f3e5 c6e5 d2d4 e5g6 e4e5 f6g8 f1c4 f8b4 d1f3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Nxe5 Nxe5 5. d4 Ng6 6. e5 Ng8 7. Bc4 Bb4 8. Qf3 f5"),
  new FullOpening("C47", "Four Knights Game: Halloween Gambit, Plasma Variation", "1rb1kbnr/pp1p1ppp/3P1qn1/1N6/5P2/8/PPP3PP/R1BQKB1R w KQk -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f3e5 c6e5 d2d4 e5c6 d4d5 c6e5 f2f4 e5g6 e4e5 f6g8 d5d6 c7d6 e5d6 d8f6 c3b5 a8b8", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Nxe5 Nxe5 5. d4 Nc6 6. d5 Ne5 7. f4 Ng6 8. e5 Ng8 9. d6 cxd6 10. exd6 Qf6 11. Nb5 Rb8"),
  new FullOpening("C47", "Four Knights Game: Italian Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3"),
  new FullOpening("C47", "Four Knights Game: Italian Variation, Noa Gambit", "r1bqkb1r/pppp1Bpp/2n5/4p3/4n3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 b1c3 f6e4 c4f7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Nc3 Nxe4 5. Bxf7+"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation Accepted", "r1bqkb1r/pppp1ppp/2n2n2/8/3pP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4 e5d4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation, Belgrade Gambit", "r1bqkb1r/pppp1ppp/2n2n2/3N4/3pP3/5N2/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4 e5d4 c3d5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nd5"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation, Belgrade Gambit, Modern Defense", "r1bqkb1r/pppp2pp/2n5/3N1p2/3pn3/5N2/PPP1QPPP/R1B1KB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4 e5d4 c3d5 f6e4 d1e2 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nd5 Nxe4 6. Qe2 f5"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation, Krause Gambit", "r1bqk2r/pppp1ppp/2n2n2/4N3/1b1PP3/2N5/PPP2PPP/R1BQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4 f8b4 f3e5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. Nxe5"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation, Krause Gambit, Leonhardt Defense", "r1b1k2r/ppppqppp/2n2n2/4N3/1b1PP3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4 f8b4 f3e5 d8e7", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. Nxe5 Qe7"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation, Oxford Gambit", "r1bqk2r/pppp1ppp/5n2/3Pp3/1b1nP3/2N2N2/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4 f8b4 d4d5 c6d4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 Bb4 5. d5 Nd4"),
  new FullOpening("C47", "Four Knights Game: Scotch Variation, Schmid Defense", "r1bqkb1r/pppp1ppp/2n5/8/3Nn3/2N5/PPP2PPP/R1BQKB1R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 d2d4 e5d4 f3d4 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. d4 exd4 5. Nxd4 Nxe4"),
  new FullOpening("C48", "Four Knights Game: Bardeleben Variation", "r1bq1rk1/pppp1ppp/2n2n2/1B2P3/1b1P1P2/2N5/PPP3PP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8c5 e1g1 e8g8 f3e5 c6e5 d2d4 c5d6 f2f4 e5c6 e4e5 d6b4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bc5 5. O-O O-O 6. Nxe5 Nxe5 7. d4 Bd6 8. f4 Nc6 9. e5 Bb4"),
  new FullOpening("C48", "Four Knights Game: Marshall Variation", "r1bq1rk1/pppp1ppp/5n2/1Bb1N3/3nP3/2N5/PPPP1PPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 c6d4 f3e5 f8c5 e1g1 e8g8", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Nxe5 Bc5 6. O-O O-O"),
  new FullOpening("C48", "Four Knights Game: Ranken Variation", "r1bqkb1r/1ppp1ppp/p1B2n2/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 a7a6 b5c6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 a6 5. Bxc6"),
  new FullOpening("C48", "Four Knights Game: Rubinstein Countergambit", "r1bqkb1r/pppp1ppp/5n2/4p3/3nP3/2N2N2/PPPPBPPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 c6d4 b5e2", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Be2"),
  new FullOpening("C48", "Four Knights Game: Rubinstein Countergambit, Henneberger Variation", "r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 g8f6 b1c3 b8c6 f1b5 c6d4 e1g1", "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. O-O"),
  new FullOpening("C48", "Four Knights Game: Rubinstein Countergambit, Maróczy Variation", "r1bq1rk1/ppp2ppp/1b1p1n2/4p3/N3P3/3P1B2/PPP2PPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 c6d4 b5e2 d4f3 e2f3 f8c5 e1g1 e8g8 d2d3 d7d6 c3a4 c5b6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Be2 Nxf3+ 6. Bxf3 Bc5 7. O-O O-O 8. d3 d6 9. Na4 Bb6"),
  new FullOpening("C48", "Four Knights Game: Spanish Variation", "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5"),
  new FullOpening("C48", "Four Knights Game: Spanish Variation, Classical Variation", "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bc5"),
  new FullOpening("C48", "Four Knights Game: Spanish Variation, Classical Variation, Marshall Gambit", "r1bq1rk1/pppp1ppp/5n2/2b1N3/B2nP3/2N5/PPPP1PPP/R1BQK2R w KQ -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 c6d4 b5a4 f8c5 f3e5 e8g8", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Ba4 Bc5 6. Nxe5 O-O"),
  new FullOpening("C48", "Four Knights Game: Spanish Variation, Rubinstein Variation", "r1bqkb1r/pppp1ppp/5n2/1B2p3/3nP3/2N2N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 c6d4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4"),
  new FullOpening("C48", "Four Knights Game: Spanish Variation, Rubinstein Variation", "r1b1kb1r/ppppqppp/5n2/1B2N3/3nPP2/2N5/PPPP2PP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 g8f6 b1c3 b8c6 f1b5 c6d4 f3e5 d8e7 f2f4", "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Nd4 5. Nxe5 Qe7 6. f4"),
  new FullOpening("C48", "Four Knights Game: Spanish Variation, Rubinstein Variation Accepted", "r1bqkb1r/pppp1ppp/5n2/1B2p3/3NP3/2N5/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 c6d4 f3d4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Nd4 5. Nxd4"),
  new FullOpening("C48", "Four Knights Game: Spielmann Variation", "r3kb1r/1pp2ppp/p1p1b3/3q4/3PN3/8/PPP2PPP/R1BQR1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 a7a6 b5c6 d7c6 f3e5 f6e4 c3e4 d8d4 e1g1 d4e5 f1e1 c8e6 d2d4 e5d5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 a6 5. Bxc6 dxc6 6. Nxe5 Nxe4 7. Nxe4 Qd4 8. O-O Qxe5 9. Re1 Be6 10. d4 Qd5"),
  new FullOpening("C49", "Four Knights Game: Alatortsev Variation", "r1b2rk1/ppp1qppp/2n2n2/1B1pp3/1b2P3/3P1N2/PPP1NPPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 d8e7 c3e2 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 Qe7 7. Ne2 d5"),
  new FullOpening("C49", "Four Knights Game: Double Spanish", "r1bqk2r/pppp1ppp/2n2n2/1B2p3/1b2P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4"),
  new FullOpening("C49", "Four Knights Game: Double Spanish", "r1bq1rk1/pppp1ppp/2n2n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3"),
  new FullOpening("C49", "Four Knights Game: Gunsberg Counterattack", "r1bq1rk1/pppp1ppp/2n5/1B1P4/1b2p3/5N2/PPPP1PPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 g8f6 b1c3 b8c6 f1b5 f8b4 e1g1 e8g8 c3d5 f6d5 e4d5 e5e4", "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Bb4 5. O-O O-O 6. Nd5 Nxd5 7. exd5 e4"),
  new FullOpening("C49", "Four Knights Game: Janowski Variation", "r1bq1rk1/ppp2ppp/2np1n2/1B2p3/4P3/2PP1N2/P1P2PPP/R1BQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 b4c3 b2c3 d7d6 f1e1", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 Bxc3 7. bxc3 d6 8. Re1"),
  new FullOpening("C49", "Four Knights Game: Nimzowitsch Variation", "r1bq1rk1/pppp1ppp/2B2n2/4p3/1b2P3/2N2N2/PPPP1PPP/R1BQ1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 b5c6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. Bxc6"),
  new FullOpening("C49", "Four Knights Game: Spanish Variation", "r1bq1rk1/pppp1ppp/2n2n2/1B2p3/4P3/2bP1N2/PPP2PPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 b4c3", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 Bxc3"),
  new FullOpening("C49", "Four Knights Game: Spanish Variation, Symmetrical Variation", "r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/2NP1N2/PPP2PPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6"),
  new FullOpening("C49", "Four Knights Game: Spanish Variation, Symmetrical Variation", "r2n1rk1/ppp1qppp/3p1n2/1B2p1B1/3PP1b1/2P2N2/P1P2PPP/R2QR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 d7d6 c1g5 b4c3 b2c3 d8e7 f1e1 c6d8 d3d4 c8g4", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6 7. Bg5 Bxc3 8. bxc3 Qe7 9. Re1 Nd8 10. d4 Bg4"),
  new FullOpening("C49", "Four Knights Game: Spanish Variation, Symmetrical Variation", "r1bq1rk1/ppp1nppp/3p1n2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 d7d6 c1g5 c6e7", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6 7. Bg5 Ne7"),
  new FullOpening("C49", "Four Knights Game: Spanish Variation, Symmetrical Variation", "r1bq1rk1/ppp2ppp/2np1n2/1B2p3/1b2P3/3P1N2/PPP1NPPP/R1BQ1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 d7d6 c3e2", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6 7. Ne2"),
  new FullOpening("C49", "Four Knights Game: Svenonius Variation", "r1bq1rk1/ppp2ppp/2n2n2/1B1pp3/4P3/2PP1N2/P1P2PPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 b4c3 b2c3 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 Bxc3 7. bxc3 d5"),
  new FullOpening("C49", "Four Knights Game: Symmetrical, Blake Variation", "r1b2rk1/pp2nppp/2pq1n2/3pp1B1/1b2P2N/1BNP4/PPP2PPP/R2Q1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 d7d6 c1g5 c6e7 f3h4 c7c6 b5c4 d6d5 c4b3 d8d6", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6 7. Bg5 Ne7 8. Nh4 c6 9. Bc4 d5 10. Bb3 Qd6"),
  new FullOpening("C49", "Four Knights Game: Symmetrical, Metger Unpin", "r1b2rk1/ppp1qppp/2np1n2/1B2p1B1/4P3/2PP1N2/P1P2PPP/R2Q1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 b1c3 g8f6 f1b5 f8b4 e1g1 e8g8 d2d3 d7d6 c1g5 b4c3 b2c3 d8e7", "1. e4 e5 2. Nf3 Nc6 3. Nc3 Nf6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6 7. Bg5 Bxc3 8. bxc3 Qe7"),
  new FullOpening("C49", "Four Knights Game: Symmetrical, Tarrasch Variation", "r2q1rk1/ppp2ppp/2npbn2/1B2p1B1/1b2P3/2NP1N2/PPP2PPP/R2Q1RK1 w - -", "e2e4 e7e5 g1f3 g8f6 b1c3 b8c6 f1b5 f8b4 e1g1 e8g8 d2d3 d7d6 c1g5 c8e6", "1. e4 e5 2. Nf3 Nf6 3. Nc3 Nc6 4. Bb5 Bb4 5. O-O O-O 6. d3 d6 7. Bg5 Be6"),
  new FullOpening("C50", "Four Knights Game: Italian Variation", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b1c3 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Nc3 Nf6"),
  new FullOpening("C50", "Giuoco Piano", "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5"),
  new FullOpening("C50", "Italian Game", "r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4", "1. e4 e5 2. Nf3 Nc6 3. Bc4"),
  new FullOpening("C50", "Italian Game: Anti-Fried Liver Defense", "r1bqkbnr/pppp1pp1/2n4p/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 h7h6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 h6"),
  new FullOpening("C50", "Italian Game: Classical Variation, Albin Gambit", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 e1g1 g8f6 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. c3"),
  new FullOpening("C50", "Italian Game: Deutz Gambit", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2BPP3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 e1g1 g8f6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d4"),
  new FullOpening("C50", "Italian Game: Giuoco Pianissimo", "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3"),
  new FullOpening("C50", "Italian Game: Giuoco Pianissimo, Canal Variation", "r1bqk2r/ppp2ppp/2np1n2/2b1p1B1/2B1P3/2NP1N2/PPP2PPP/R2QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 d2d3 g8f6 b1c3 d7d6 c1g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nf6 5. Nc3 d6 6. Bg5"),
  new FullOpening("C50", "Italian Game: Giuoco Pianissimo, Dubois Variation", "r1bqk1nr/pppp2pp/2n5/2b1p1N1/2B1Pp2/3P4/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 d2d3 f7f5 f3g5 f5f4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 f5 5. Ng5 f4"),
  new FullOpening("C50", "Italian Game: Giuoco Pianissimo, Italian Four Knights Variation", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2NP1N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 d2d3 g8f6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 Nf6 5. Nc3"),
  new FullOpening("C50", "Italian Game: Giuoco Pianissimo, Lucchini Gambit", "r1bqk1nr/pppp2pp/2n5/2b1pp2/2B1P3/3P1N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 d2d3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d3 f5"),
  new FullOpening("C50", "Italian Game: Giuoco Pianissimo, Normal", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d3 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3 Bc5"),
  new FullOpening("C50", "Italian Game: Hungarian Defense", "r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7"),
  new FullOpening("C50", "Italian Game: Hungarian Defense, Tartakower Variation", "r1bqk2r/ppppbppp/2n5/4P3/2Bpn3/2P2N2/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8e7 d2d4 e5d4 c2c3 g8f6 e4e5 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Be7 4. d4 exd4 5. c3 Nf6 6. e5 Ne4"),
  new FullOpening("C50", "Italian Game: Jerome Gambit", "r1bqk1nr/pppp1Bpp/2n5/2b1p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c4f7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. Bxf7+"),
  new FullOpening("C50", "Italian Game: Rosentreter Gambit", "r1bqk1nr/pppp1ppp/2n5/2b1p3/2BPP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. d4"),
  new FullOpening("C50", "Italian Game: Rousseau Gambit", "r1bqkbnr/pppp2pp/2n5/4pp2/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 f5"),
  new FullOpening("C50", "Italian Game: Blackburne-Kostić Gambit", "r1bqkbnr/pppp1ppp/8/4p3/2BnP3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 c6d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nd4"),
  new FullOpening("C51", "Italian Game: Evans Gambit", "r1bqk1nr/pppp1ppp/2n5/2b1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4"),
  new FullOpening("C51", "Italian Game: Evans Gambit", "r2qk1nr/ppp2ppp/1bnp4/8/2BPP1b1/2N2N2/P4PPP/R1BQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 b1c3 c8g4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. Nc3 Bg4"),
  new FullOpening("C51", "Italian Game: Evans Gambit Accepted", "r1bqk1nr/pppp1ppp/2n5/4p3/1bB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined", "r1bqk1nr/pppp1ppp/1bn5/4p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined", "r1bqk1nr/pppp1ppp/1bn5/4p3/PPB1P3/5N2/2PP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 a2a4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. a4"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined, Cordel Variation", "r1bqk1nr/pppp1ppp/1bn5/4p3/1PB1P3/5N2/PBPP1PPP/RN1QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 c1b2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. Bb2"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined, Hicken Variation", "r1bk2nr/pppp1Qpp/1b6/nP2q3/2B1P3/8/PBPP1PPP/RN2K2R b KQ -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 b4b5 c6a5 f3e5 d8g5 d1f3 g5e5 f3f7 e8d8 c1b2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Qg5 7. Qf3 Qxe5 8. Qxf7+ Kd8 9. Bb2"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined, Hirschbach Variation", "r1b1k1nr/pppp1ppp/1b6/nP2N1q1/2B1P3/8/P1PP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 b4b5 c6a5 f3e5 d8g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Qg5"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined, Lange Variation", "r1bqk2r/pppp1ppp/1b5n/nP2N3/2B1P3/8/P1PP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 b4b5 c6a5 f3e5 g8h6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Nh6"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined, Pavlov Variation", "r1b3r1/ppp2k1p/1b6/nP2B1q1/3PP3/8/P1PN1PPP/R2QK2R b KQ -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 b4b5 c6a5 f3e5 g8h6 d2d4 d7d6 c1h6 d6e5 h6g7 h8g8 c4f7 e8f7 g7e5 d8g5 b1d2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Nh6 7. d4 d6 8. Bxh6 dxe5 9. Bxg7 Rg8 10. Bxf7+ Kxf7 11. Bxe5 Qg5 12. Nd2"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined, Showalter Variation", "r1bqk1nr/1ppp1ppp/pbn5/4p3/PPB1P3/2N2N2/2PP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 a2a4 a7a6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. a4 a6 6. Nc3"),
  new FullOpening("C51", "Italian Game: Evans Gambit Declined, Vasquez Variation", "r1b3nr/ppppkBpp/1b6/nP2N1qQ/4P3/8/P1PP1PPP/RNB1K2R b KQ -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b6 b4b5 c6a5 f3e5 d8g5 c4f7 e8e7 d1h5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bb6 5. b5 Na5 6. Nxe5 Qg5 7. Bxf7+ Ke7 8. Qh5"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Anderssen Variation", "r1bqk1nr/ppppbppp/2n5/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4e7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Be7"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Anderssen Variation, Cordel Line", "r1bqk1nr/ppppbppp/8/n3p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4e7 d2d4 c6a5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Be7 6. d4 Na5"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Fontaine Countergambit", "r1bqk1nr/p1pp1ppp/2n5/1pb1p3/1PB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 b7b5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 b5"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Fraser Attack", "r2qk1nr/ppp2ppp/1bnp4/8/Q1BPP1b1/2N2N2/P4PPP/R1B2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 b1c3 c8g4 d1a4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. Nc3 Bg4 10. Qa4"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Fraser-Mortimer Attack", "r2q1knr/pppb1Bpp/1b1p4/n7/3PP3/2N2N2/P1Q2PPP/R1B2RK1 b - -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 b1c3 c8g4 d1a4 g4d7 a4b3 c6a5 c4f7 e8f8 b3c2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. Nc3 Bg4 10. Qa4 Bd7 11. Qb3 Na5 12. Bxf7+ Kf8 13. Qc2"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Göring Attack", "r1bqk1nr/ppp2ppp/1b1p4/n5B1/2BPP3/2N2N2/P4PPP/R2Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 b1c3 c6a5 c1g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. Nc3 Na5 10. Bg5"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Harding Variation", "r1bqk1nr/pppp1ppp/2n5/8/1bBPP3/5N2/P2B1PPP/RN1QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 c3d4 c5b4 c1d2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. cxd4 Bb4+ 8. Bd2"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Hein Countergambit", "r1bqk1nr/ppp2ppp/2n5/2bpp3/1PB1P3/5N2/P1PP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 d5"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Mayet Defense", "r1bqkbnr/pppp1ppp/2n5/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4f8", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bf8"),
  new FullOpening("C51", "Italian Game: Evans Gambit, McDonnell Defense", "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5"),
  new FullOpening("C51", "Italian Game: Evans Gambit, McDonnell Defense, Main Line", "r1bqk1nr/ppp2ppp/1bnp4/8/2BPP3/5N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Morphy Attack", "r1bqk1nr/ppp2ppp/1bnp4/8/2BPP3/2N2N2/P4PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 d7d6 c3d4 a5b6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. Nc3"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Mortimer-Evans Gambit", "r2q2nr/pppb1kpp/1b1p4/n7/3PP3/2N2N2/P1Q2PPP/R1B2RK1 w - -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 b1c3 c8g4 d1a4 g4d7 a4b3 c6a5 c4f7 e8f8 b3c2 f8f7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. Nc3 Bg4 10. Qa4 Bd7 11. Qb3 Na5 12. Bxf7+ Kf8 13. Qc2 Kxf7"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Paulsen Variation", "r1bqk2r/ppp1nppp/1b1p4/n2P4/2B1P3/5N2/PB3PPP/RN1Q1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 d4d5 c6a5 c1b2 g8e7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. d5 Na5 10. Bb2 Ne7"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Steinitz Variation", "r1bqk1nr/ppp3pp/1b1p1p2/n7/2BPP3/2N1BN2/P4PPP/R2Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 b1c3 c6a5 c1g5 f7f6 g5e3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. Nc3 Na5 10. Bg5 f6 11. Be3"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Stone-Ware Variation", "r1bqk1nr/pppp1ppp/2nb4/4p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4d6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bd6"),
  new FullOpening("C51", "Italian Game: Evans Gambit, Ulvestad Variation", "r1bqk1nr/ppp2ppp/1b1p4/n2P4/2B1P3/5N2/PB3PPP/RN1Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4c5 d2d4 e5d4 e1g1 d7d6 c3d4 c5b6 d4d5 c6a5 c1b2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Bc5 6. d4 exd4 7. O-O d6 8. cxd4 Bb6 9. d5 Na5 10. Bb2"),
  new FullOpening("C52", "Italian Game: Evans Gambit", "r1bqk1nr/ppp2ppp/2np4/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 e1g1 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O d6"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Alapin-Steinitz Variation", "r2qk1nr/ppp2ppp/2np4/b3p3/2BPP1b1/2P2N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 e1g1 d7d6 d2d4 c8g4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O d6 7. d4 Bg4"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Anderssen Defense", "r1bqk2r/pppp1ppp/2n2n2/b7/2BpP3/2P2N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 e1g1 g8f6 d2d4 e5d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O Nf6 7. d4 exd4"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Bronstein Defense", "r1bqk1nr/ppp2ppp/2np4/b3p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 d6"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Compromised Defense", "r1bqk1nr/pppp1ppp/2n5/b7/2B1P3/2p2N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 d4c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O dxc3"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Compromised Defense, Main Line", "r1b1k2r/ppppnppp/2n3q1/b3P3/2B5/BQN2N2/P4PPP/R4RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 d4c3 d1b3 d8f6 e4e5 f6g6 b1c3 g8e7 c1a3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O dxc3 8. Qb3 Qf6 9. e5 Qg6 10. Nxc3 Nge7 11. Ba3"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Compromised Defense, Potter Variation", "r1b1k2r/ppppnppp/2n3q1/b3P3/2B5/1QN2N2/P4PPP/R1BR2K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 d4c3 d1b3 d8f6 e4e5 f6g6 b1c3 g8e7 f1d1", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O dxc3 8. Qb3 Qf6 9. e5 Qg6 10. Nxc3 Nge7 11. Rd1"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Dufresne Defense", "r1bqk1nr/pppp1ppp/2n5/b7/2B1P3/2Pp1N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 d4d3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O d3"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Johner Defense", "r1bqk1nr/p1pp1ppp/2n5/bp6/2BpP3/2P2N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 b7b5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O b5"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Laroche Variation", "r1bqk2r/pppp1ppp/2n2n2/b3p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 Nf6"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Lasker Defense", "r1bqk1nr/ppp2ppp/1bnp4/4p3/2BPP3/2P2N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 e1g1 d7d6 d2d4 a5b6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O d6 7. d4 Bb6"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Leonhardt Countergambit", "r1bqk1nr/p1pp1ppp/2n5/bp2p3/2BPP3/2P2N2/P4PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 b7b5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 b5"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Levenfish Variation", "r1b1k1nr/pppq1ppp/1b6/n3N3/2B1P3/BQP5/P4PPP/RN3RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 d7d6 d1b3 d8d7 d4e5 d6e5 e1g1 a5b6 c1a3 c6a5 f3e5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 d6 7. Qb3 Qd7 8. dxe5 dxe5 9. O-O Bb6 10. Ba3 Na5 11. Nxe5"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Main Line", "r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Mieses Defense", "r1bqk2r/ppppnppp/2n5/b7/2BpP3/2P2N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 g8e7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O Nge7"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Pierce Defense", "r1bqk1nr/pppp1ppp/2n5/b7/2BpP3/2P2N2/P4PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Richardson Attack", "r1bq1rk1/pppp1ppp/2n2n2/b3N3/2BPP3/2P5/P4PPP/RNBQ1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 e1g1 g8f6 d2d4 e8g8 f3e5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O Nf6 7. d4 O-O 8. Nxe5"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Sanders-Alapin Variation", "r2qk1nr/pppb1ppp/2np4/b3p3/2BPP3/2P2N2/P4PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 e1g1 d7d6 d2d4 c8d7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O d6 7. d4 Bd7"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Slow Variation", "r1bqk1nr/pppp1ppp/2n5/b3p3/2B1P3/2P2N2/P2P1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. O-O"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Sokolsky Variation", "r1bqk1nr/ppp2ppp/2np4/b3p1B1/2BPP3/2P2N2/P4PPP/RN1QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 d7d6 c1g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 d6 7. Bg5"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Tartakower Attack", "r1bqk1nr/ppp2ppp/2np4/b3p3/2BPP3/1QP2N2/P4PPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 d7d6 d1b3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 d6 7. Qb3"),
  new FullOpening("C52", "Italian Game: Evans Gambit, Waller Attack", "r1bqk1nr/ppp2ppp/2np4/b7/2BpP3/1QP2N2/P4PPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 b2b4 c5b4 c2c3 b4a5 d2d4 e5d4 e1g1 d7d6 d1b3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. b4 Bxb4 5. c3 Ba5 6. d4 exd4 7. O-O d6 8. Qb3"),
  new FullOpening("C53", "Italian Game: Bird's Attack", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/1PB1P3/2P2N2/P2P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 b2b4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. b4"),
  new FullOpening("C53", "Italian Game: Classical Variation", "r1bqk1nr/pppp1ppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3"),
  new FullOpening("C53", "Italian Game: Classical Variation", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6"),
  new FullOpening("C53", "Italian Game: Classical Variation, Alexandre Gambit", "r1bqk1nr/pppp2pp/2n5/2b1pp2/2B1P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 f5"),
  new FullOpening("C53", "Italian Game: Classical Variation, Center Attack", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2BPP3/2P2N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4"),
  new FullOpening("C53", "Italian Game: Classical Variation, Center Holding Variation", "r1b1k1nr/ppppqppp/1bn5/4p3/2BPP3/2P2N2/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 d8e7 d2d4 c5b6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6"),
  new FullOpening("C53", "Italian Game: Classical Variation, Closed Variation", "r1b1k1nr/ppppqppp/2n5/2b1p3/2B1P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 d8e7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7"),
  new FullOpening("C53", "Italian Game: Classical Variation, Giuoco Pianissimo", "r1bqk2r/pppp1ppp/2n2n2/2b1p3/2B1P3/2PP1N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3"),
  new FullOpening("C53", "Italian Game: Classical Variation, Giuoco Pianissimo, Main Line", "r1bq1rk1/bpp2ppp/p1np1n2/4p3/4P3/1BPP1N1P/PP3PP1/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d3 d7d6 e1g1 e8g8 f1e1 a7a6 c4b3 c5a7 h2h3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d3 d6 6. O-O O-O 7. Re1 a6 8. Bb3 Ba7 9. h3"),
  new FullOpening("C53", "Italian Game: Classical Variation, Greco Gambit", "r1bqk2r/ppp2ppp/2n2n2/2bpP3/2Bp4/2P2N2/PP3PPP/RNBQK2R w KQkq d6", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8c5 c2c3 g8f6 e4e5 d7d5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. c3 Nf6 6. e5 d5"),
  new FullOpening("C53", "Italian Game: Classical Variation, Greco Gambit, Anderssen Variation", "r1bqk2r/ppp2ppp/2n5/1B1pP3/1b1Pn3/5N2/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 e4e5 d7d5 c4b5 f6e4 c3d4 c5b4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. e5 d5 7. Bb5 Ne4 8. cxd4 Bb4+"),
  new FullOpening("C53", "Italian Game: Classical Variation, La Bourdonnais Variation", "r1bqk1nr/ppp2ppp/1bnp4/8/2BPP3/5N2/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 d7d6 d2d4 e5d4 c3d4 c5b6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 d6 5. d4 exd4 6. cxd4 Bb6"),
  new FullOpening("C53", "Italian Game: Classical Variation, Tarrasch Variation", "r1b1k2r/1pp1qppp/pbnp1n2/4p3/P1BPP3/2P2N1P/1P3PP1/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 d8e7 d2d4 c5b6 e1g1 d7d6 a2a4 a7a6 h2h3 g8f6 f1e1", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6 6. O-O d6 7. a4 a6 8. h3 Nf6 9. Re1"),
  new FullOpening("C53", "Italian Game: Giuoco Piano, Eisinger Variation", "rnb1k1nr/ppppqppp/1b1P4/4p3/2B1P3/2P2N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 d8e7 d2d4 c5b6 d4d5 c6b8 d5d6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6 6. d5 Nb8 7. d6"),
  new FullOpening("C53", "Italian Game: Giuoco Piano, Ghulam-Kassim Variation", "r1bqk2r/pppp1ppp/2n5/2bBP3/8/2p2NK1/PP4PP/RNBQ3R b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 e4e5 f6e4 c4d5 e4f2 e1f2 d4c3 f2g3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. e5 Ne4 7. Bd5 Nxf2 8. Kxf2 dxc3+ 9. Kg3"),
  new FullOpening("C53", "Italian Game: Giuoco Piano, Mestel Variation", "r1b1k1nr/ppppqppp/1bn5/4p1B1/2BPP3/2P2N2/PP3PPP/RN1QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 d8e7 d2d4 c5b6 c1g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Qe7 5. d4 Bb6 6. Bg5"),
  new FullOpening("C53", "Italian Game: Scotch Gambit, Walbrodt-Baird Gambit", "r1bqk2r/pppp1ppp/2n2n2/2b5/2BpP3/2P2N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. O-O"),
  new FullOpening("C54", "Italian Game: Classical Variation, Greco Gambit, Greco Variation", "r1bqk2r/pppp1ppp/2n5/8/1bBP4/2n2N2/PP3PPP/R1BQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 e4c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Nxc3"),
  new FullOpening("C54", "Italian Game: Classical Variation, Greco Gambit, Main Line", "r1bqk2r/pppp1ppp/2n5/8/2BPn3/2b2N2/PP3PPP/R1BQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 b4c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Bxc3"),
  new FullOpening("C54", "Italian Game: Classical Variation, Greco Gambit, Moeller-Bayonet Attack", "r1bqk2r/ppp1nppp/3p1b2/3P4/2B1R1P1/5N2/PP3P1P/R1BQ2K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 b4c3 d4d5 c3f6 f1e1 c6e7 e1e4 d7d6 g2g4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Bxc3 9. d5 Bf6 10. Re1 Ne7 11. Rxe4 d6 12. g4"),
  new FullOpening("C54", "Italian Game: Classical Variation, Greco Gambit, Moeller-Therkatz Attack", "r1bqk2r/pppp1ppp/2n5/3P4/2B1n3/2b2N2/PP3PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 b4c3 d4d5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Bxc3 9. d5"),
  new FullOpening("C54", "Italian Game: Classical Variation, Greco Gambit, Traditional Line", "r1bqk2r/pppp1ppp/2n2n2/2b5/2BPP3/5N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4"),
  new FullOpening("C54", "Italian Game: Giuoco Piano, Aitken Variation", "r1bqk2r/pppp1ppp/2n5/8/2BP4/B1b2N2/P4PPP/R2Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 e4c3 b2c3 b4c3 c1a3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Nxc3 9. bxc3 Bxc3 10. Ba3"),
  new FullOpening("C54", "Italian Game: Giuoco Piano, Bernstein Variation", "r1bqk2r/ppp2ppp/2n5/3p4/2BP4/1Qb2N2/P4PPP/R1B2RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 e4c3 b2c3 b4c3 d1b3 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Nxc3 9. bxc3 Bxc3 10. Qb3 d5"),
  new FullOpening("C54", "Italian Game: Giuoco Piano, Cracow Variation", "r1bqk2r/pppp1ppp/2n2n2/8/1bBPP3/5N2/PP3PPP/RNBQ1K1R b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 e1f1", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Kf1"),
  new FullOpening("C54", "Italian Game: Giuoco Piano, Greco's Attack", "r1bqk2r/pppp1ppp/2n2n2/8/1bBPP3/2N2N2/PP3PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3"),
  new FullOpening("C54", "Italian Game: Giuoco Piano, Krause Variation", "r1bq3r/ppp3pp/5k2/3pN3/1n1Pn3/1Q3P2/PP4PP/RN2K2R b KQ -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 c1d2 f6e4 d2b4 c6b4 c4f7 e8f7 d1b3 d7d5 f3e5 f7f6 f2f3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Bd2 Nxe4 8. Bxb4 Nxb4 9. Bxf7+ Kxf7 10. Qb3+ d5 11. Ne5+ Kf6 12. f3"),
  new FullOpening("C54", "Italian Game: Giuoco Piano, Steinitz Variation", "r1bqk2r/ppp2ppp/2n5/3p4/2BPn3/B1P2N2/P4PPP/R2Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 b4c3 b2c3 d7d5 c1a3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Bxc3 9. bxc3 d5 10. Ba3"),
  new FullOpening("C54", "Italian Game: Giuoco Piano, Therkatz-Herzog Variation", "r1bq1rk1/ppp1nppN/3p4/3P4/2B1R3/8/PP3PPP/R2Q2K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 c2c3 g8f6 d2d4 e5d4 c3d4 c5b4 b1c3 f6e4 e1g1 b4c3 d4d5 c3f6 f1e1 c6e7 e1e4 d7d6 c1g5 f6g5 f3g5 e8g8 g5h7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. c3 Nf6 5. d4 exd4 6. cxd4 Bb4+ 7. Nc3 Nxe4 8. O-O Bxc3 9. d5 Bf6 10. Re1 Ne7 11. Rxe4 d6 12. Bg5 Bxg5 13. Nxg5 O-O 14. Nxh7"),
  new FullOpening("C55", "Italian Game: Giuoco Piano", "r1bqk2r/ppp2ppp/3p1n2/4p1B1/2BnP3/8/PPP2PPP/RN1Q1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 e1g1 f8c5 d2d4 c5d4 f3d4 c6d4 c1g5 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O Bc5 5. d4 Bxd4 6. Nxd4 Nxd4 7. Bg5 d6"),
  new FullOpening("C55", "Italian Game: Giuoco Piano, Holzhausen Attack", "r1b1k2r/ppp1qppp/5n2/4p1B1/2BnP3/2N5/PPP3PP/R2Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 e1g1 f8c5 d2d4 c5d4 f3d4 c6d4 c1g5 d7d6 f2f4 d8e7 f4e5 d6e5 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O Bc5 5. d4 Bxd4 6. Nxd4 Nxd4 7. Bg5 d6 8. f4 Qe7 9. fxe5 dxe5 10. Nc3"),
  new FullOpening("C55", "Italian Game: Giuoco Piano, Rosentreter Variation", "r1bqk2r/pppp1p2/5n1p/4p1p1/2BnPP1B/8/PPP3PP/RN1Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 e1g1 f8c5 d2d4 c5d4 f3d4 c6d4 c1g5 h7h6 g5h4 g7g5 f2f4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. O-O Bc5 5. d4 Bxd4 6. Nxd4 Nxd4 7. Bg5 h6 8. Bh4 g5 9. f4"),
  new FullOpening("C55", "Italian Game: Scotch Gambit", "r1bqkb1r/pppp1ppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O"),
  new FullOpening("C55", "Italian Game: Scotch Gambit Declined", "r1bqkb1r/ppp2ppp/2np1n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 d6"),
  new FullOpening("C55", "Italian Game: Scotch Gambit Declined", "r1bqk2r/pppp1ppp/2nb1n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 f8d6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 Bd6"),
  new FullOpening("C55", "Italian Game: Scotch Gambit, Janowski Defense", "r1bqkb1r/ppp2ppp/2np1n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 d7d6", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O d6"),
  new FullOpening("C55", "Italian Game: Scotch Gambit, Max Lange Attack", "r1bqk2r/pppp1ppp/2n2n2/2b5/2BpP3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 f8c5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5"),
  new FullOpening("C55", "Italian Game: Scotch Gambit, Max Lange Attack Accepted", "r2qk2r/ppp2pPp/2n1b3/2b5/2pp4/5N2/PPP2PPP/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 f8c5 e1g1 g8f6 d2d4 e5d4 e4e5 d7d5 e5f6 d5c4 f1e1 c8e6 f6g7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Bc5 4. O-O Nf6 5. d4 exd4 6. e5 d5 7. exf6 dxc4 8. Re1+ Be6 9. fxg7"),
  new FullOpening("C55", "Italian Game: Scotch Gambit, Max Lange Attack, Long Variation", "r3k2r/ppp2ppp/2n1bP2/2b2qN1/2ppN3/8/PPP2PPP/R1BQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 f8c5 e4e5 d7d5 e5f6 d5c4 f1e1 c8e6 f3g5 d8d5 b1c3 d5f5 c3e4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. e5 d5 7. exf6 dxc4 8. Re1+ Be6 9. Ng5 Qd5 10. Nc3 Qf5 11. Nce4"),
  new FullOpening("C55", "Italian Game: Scotch Gambit, Max Lange Attack, Spielmann Defense", "r1bqk2r/pppp1ppp/2n5/2b1P3/2Bp2n1/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 f8c5 e4e5 f6g4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. e5 Ng4"),
  new FullOpening("C55", "Italian Game: Scotch Gambit, de Riviere Defense", "r1bqk2r/ppppbppp/2n2n2/8/2BpP3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 f8e7", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Be7"),
  new FullOpening("C55", "Italian Game: Two Knights Defense", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6"),
  new FullOpening("C55", "Italian Game: Two Knights Defense", "r1bqkb1r/pppp1ppp/2n5/4p3/2BPn3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 Nxe4"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Keidansky Variation", "r1b1k2r/ppp2ppp/2N5/1B1pP3/4n2q/8/PPP2bPP/RNBQ1K1R w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e4e5 d7d5 c4b5 f6e4 f3d4 f8c5 d4c6 c5f2 e1f1 d8h4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. e5 d5 6. Bb5 Ne4 7. Nxd4 Bc5 8. Nxc6 Bxf2+ 9. Kf1 Qh4"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Max Lange Attack", "r1bqk2r/pppp1ppp/2n2n2/2b1P3/2Bp4/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 f8c5 e4e5", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Bc5 6. e5"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Max Lange Attack, Berger Variation", "2kr3r/ppp2ppp/1bn1bPq1/6N1/2ppNPP1/8/PPP4P/R1BQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1 f8c5 e4e5 d7d5 e5f6 d5c4 f1e1 c8e6 f3g5 d8d5 b1c3 d5f5 g2g4 f5g6 c3e4 c5b6 f2f4 e8c8", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Bc5 6. e5 d5 7. exf6 dxc4 8. Re1+ Be6 9. Ng5 Qd5 10. Nc3 Qf5 11. g4 Qg6 12. Nce4 Bb6 13. f4 O-O-O"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Max Lange Attack, Krause Variation", "r1bqk2r/pppp1ppp/2n5/2b1P3/2Bp2n1/2P2N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1 f8c5 e4e5 f6g4 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Bc5 6. e5 Ng4 7. c3"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Max Lange Attack, Loman Defense", "r2qk2r/ppp2p1p/2n1bPp1/2b3N1/2pp4/8/PPP2PPP/RNBQR1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 f8c5 e1g1 g8f6 e4e5 d7d5 e5f6 d5c4 f1e1 c8e6 f3g5 g7g6", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Bc5 5. O-O Nf6 6. e5 d5 7. exf6 dxc4 8. Re1+ Be6 9. Ng5 g6"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Max Lange Attack, Rubinstein Variation", "r3kb1r/ppp2ppp/2n1bP2/5qN1/2ppN3/8/PPP2PPP/R1BQR1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1 f8c5 e4e5 d7d5 e5f6 d5c4 f1e1 c8e6 f3g5 d8d5 b1c3 d5f5 c3e4 c5f8", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Bc5 6. e5 d5 7. exf6 dxc4 8. Re1+ Be6 9. Ng5 Qd5 10. Nc3 Qf5 11. Nce4 Bf8"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Modern Bishop's Opening", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2B1P3/3P1N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d3"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Open Variation", "r1bqkb1r/pppp1ppp/2n2n2/4p3/2BPP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4"),
  new FullOpening("C55", "Italian Game: Two Knights Defense, Perreux Variation", "r1bqkb1r/pppp1ppp/2n2n2/6N1/2BpP3/8/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 f3g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. Ng5"),
  new FullOpening("C56", "Italian Game: Scotch Gambit, Anderssen Attack", "r1b1kb1r/ppp2ppp/2n5/3q4/3pn3/2N2N2/PPP2PPP/R1BQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1 f6e4 f1e1 d7d5 c4d5 d8d5 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4 6. Re1 d5 7. Bxd5 Qxd5 8. Nc3"),
  new FullOpening("C56", "Italian Game: Scotch Gambit, Anderssen Attack, Main Line", "r3kb1r/ppp2ppp/2n1b3/3q2B1/3pN3/5N2/PPP2PPP/R2QR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1 f6e4 f1e1 d7d5 c4d5 d8d5 b1c3 d5a5 c3e4 c8e6 c1d2 a5d5 d2g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4 6. Re1 d5 7. Bxd5 Qxd5 8. Nc3 Qa5 9. Nxe4 Be6 10. Bd2 Qd5 11. Bg5"),
  new FullOpening("C56", "Italian Game: Scotch Gambit, Canal Variation", "r1bqkb1r/ppp2ppp/2n5/3p4/2Bpn3/2N2N2/PPP2PPP/R1BQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 f6e4 f1e1 d7d5 b1c3", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Nxe4 6. Re1 d5 7. Nc3"),
  new FullOpening("C56", "Italian Game: Scotch Gambit, Double Gambit Accepted", "r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4"),
  new FullOpening("C56", "Italian Game: Scotch Gambit, Nakhmanson Gambit", "r1bqkb1r/pppp1ppp/2n5/8/2Bpn3/2N2N2/PPP2PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 d2d4 e5d4 e1g1 f6e4 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. d4 exd4 5. O-O Nxe4 6. Nc3"),
  new FullOpening("C56", "Italian Game: Two Knights Defense, Yurdansky Attack", "r4b1r/ppp1kp2/2n1bN1p/q5p1/1P1p3B/5N2/P1P2PPP/R2QR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 d2d4 e5d4 f1c4 g8f6 e1g1 f6e4 f1e1 d7d5 c4d5 d8d5 b1c3 d5a5 c3e4 c8e6 c1g5 h7h6 g5h4 g7g5 e4f6 e8e7 b2b4", "1. e4 e5 2. Nf3 Nc6 3. d4 exd4 4. Bc4 Nf6 5. O-O Nxe4 6. Re1 d5 7. Bxd5 Qxd5 8. Nc3 Qa5 9. Nxe4 Be6 10. Bg5 h6 11. Bh4 g5 12. Nf6+ Ke7 13. b4"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Fegatello Attack, Leonhardt Variation", "r1bq1b1r/ppn3pp/2p1k3/3np3/2BPQ3/P1N5/1PP2PPP/R1B1K2R w KQ -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 f6d5 g5f7 e8f7 d1f3 f7e6 b1c3 c6b4 f3e4 c7c6 a2a3 b4a6 d2d4 a6c7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. Nxf7 Kxf7 7. Qf3+ Ke6 8. Nc3 Nb4 9. Qe4 c6 10. a3 Na6 11. d4 Nc7"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Fried Liver Attack", "r1bqkb1r/ppp2Npp/2n5/3np3/2B5/8/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 f6d5 g5f7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. Nxf7"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Fritz Variation", "r1bqkb1r/ppp2ppp/5n2/3Pp1N1/2Bn4/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nd4"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Fritz, Gruber Variation", "r1bqkb1r/p1p2ppp/8/1p1np3/3nN3/2P5/PP1P1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6d4 c2c3 b7b5 c4f1 f6d5 g5e4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nd4 6. c3 b5 7. Bf1 Nxd5 8. Ne4"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Kloss Gambit", "r1bqkb1r/ppp2ppp/5n2/3Pp1N1/1nB5/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6b4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nb4"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Knight Attack", "r1bqkb1r/pppp1ppp/2n2n2/4p1N1/2B1P3/8/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Knight Attack, Normal Variation", "r1bqkb1r/ppp2ppp/2n2n2/3pp1N1/2B1P3/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Lolli Attack", "r1bqkb1r/ppp2ppp/2n5/3np1N1/2BP4/8/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 f6d5 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. d4"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Pincus Variation", "r1bqk2r/ppp2ppp/2n5/3np1N1/1bBP4/8/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 f6d5 d2d4 f8b4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Nxd5 6. d4 Bb4+"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Ponziani-Steinitz Gambit", "r1bqkb1r/pppp1ppp/2n5/4p1N1/2B1n3/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Nxe4"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Traxler Counterattack", "r1bqk2r/pppp1ppp/2n2n2/2b1p1N1/2B1P3/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Traxler Counterattack, Bishop Sacrifice Line", "r1bqk2r/pppp1Bpp/2n2n2/2b1p1N1/4P3/8/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 f8c5 c4f7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Bxf7+"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Traxler Counterattack, King March Line", "r1bqk2r/pppp1Npp/2n5/4p3/2B1n3/4K3/PPPP2PP/RNBQ3R b kq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 f8c5 g5f7 c5f2 e1f2 f6e4 f2e3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Nxf7 Bxf2+ 6. Kxf2 Nxe4+ 7. Ke3"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Traxler Counterattack, Knight Sacrifice Line", "r1bqk2r/pppp1Npp/2n2n2/2b1p3/2B1P3/8/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 f8c5 g5f7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Nxf7"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Traxler Variation, Trencianske-Teplice Gambit", "r1bq3r/ppppkBpp/2n2n2/2b1p1N1/3PP3/8/PPP2PPP/RNBQK2R b KQ -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 f8c5 c4f7 e8e7 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 Bc5 5. Bxf7+ Ke7 6. d4"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Ulvestad Variation", "r1bqkb1r/p1p2ppp/2n2n2/1p1Pp1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 b7b5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 b5"),
  new FullOpening("C57", "Italian Game: Two Knights Defense, Ulvestad Variation, Kurkin Gambit", "r1bqkb1r/p1p2Np1/2n2n1p/1p1Pp3/8/8/PPPP1PPP/RNBQKB1R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 b7b5 c4f1 h7h6 g5f7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 b5 6. Bf1 h6 7. Nxf7"),
  new FullOpening("C58", "Italian Game: Two Knights Defense", "r1bqkb1r/p4ppp/2p2n2/n3p1N1/8/8/PPPPBPPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5e2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Be2"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Blackburne Variation", "r1bqkb1r/p4ppp/5n2/np2p1N1/8/5Q2/PPPP1PPP/RNB1K2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 d1f3 c6b5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Qf3 cxb5"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Colman Variation", "1rbqkb1r/p4ppp/2p2n2/nB2p1N1/8/5Q2/PPPP1PPP/RNB1K2R w KQk -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 d1f3 a8b8", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Qf3 Rb8"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Maróczy Variation", "r1bqk2r/ppp1bpp1/5n1p/3P4/2P1p3/5N2/PPP1QPPP/RNB1K2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 d2d3 h7h6 g5f3 e5e4 d1e2 a5c4 d3c4 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. d3 h6 7. Nf3 e4 8. Qe2 Nxc4 9. dxc4 Be7"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Paoli Variation", "r1b1kb1r/p1q2ppp/2p2n2/n3p1N1/8/3B1Q2/PPPP1PPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 d1f3 d8c7 b5d3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Qf3 Qc7 9. Bd3"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Polerio Defense", "r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/8/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Polerio Defense, Bishop Check Line", "r1bqkb1r/ppp2ppp/5n2/nB1Pp1N1/8/8/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Polerio Defense, Bogoljubov Variation", "r1bqkb1r/p4ppp/2p2n2/nB2p1N1/8/5Q2/PPPP1PPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 d1f3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Qf3"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Polerio Defense, Kieseritzky Variation", "r1bqkb1r/ppp2ppp/5n2/n2Pp1N1/2B5/3P4/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. d3"),
  new FullOpening("C58", "Italian Game: Two Knights Defense, Polerio Defense, Yankovich Variation", "r1bqk2r/ppp2pp1/5n1p/2bP4/2P1p3/8/PPPNQPPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 d2d3 h7h6 g5f3 e5e4 d1e2 a5c4 d3c4 f8c5 f3d2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. d3 h6 7. Nf3 e4 8. Qe2 Nxc4 9. dxc4 Bc5 10. Nfd2"),
  new FullOpening("C59", "Italian Game: Two Knights Defense, Knorre Variation", "r1b1k2r/p1q2pp1/2pb1n1p/n3N3/3Pp3/8/PPPBBPPP/RN1QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5e2 h7h6 g5f3 e5e4 f3e5 f8d6 d2d4 d8c7 c1d2", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Be2 h6 9. Nf3 e4 10. Ne5 Bd6 11. d4 Qc7 12. Bd2"),
  new FullOpening("C59", "Italian Game: Two Knights Defense, Polerio Defense, Göring Variation", "r1b1kb1r/p1q2pp1/2p2n1p/n3N3/4p3/8/PPPPBPPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5e2 h7h6 g5f3 e5e4 f3e5 d8c7", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Be2 h6 9. Nf3 e4 10. Ne5 Qc7"),
  new FullOpening("C59", "Italian Game: Two Knights Defense, Polerio Defense, Suhle Defense", "r1bqkb1r/p4pp1/2p2n1p/n3p1N1/8/8/PPPPBPPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5e2 h7h6", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Be2 h6"),
  new FullOpening("C59", "Italian Game: Two Knights Defense, Steinitz Variation", "r1bqkb1r/p4pp1/2p2n1p/n3p3/8/7N/PPPPBPPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1c4 g8f6 f3g5 d7d5 e4d5 c6a5 c4b5 c7c6 d5c6 b7c6 b5e2 h7h6 g5h3", "1. e4 e5 2. Nf3 Nc6 3. Bc4 Nf6 4. Ng5 d5 5. exd5 Na5 6. Bb5+ c6 7. dxc6 bxc6 8. Be2 h6 9. Nh3"),
  new FullOpening("C60", "Ruy Lopez", "r1bqkbnr/pppp1ppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5", "1. e4 e5 2. Nf3 Nc6 3. Bb5"),
  new FullOpening("C60", "Ruy Lopez: Alapin Defense", "r1bqk1nr/pppp1ppp/2n5/1B2p3/1b2P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8b4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bb4"),
  new FullOpening("C60", "Ruy Lopez: Alapin Defense, Alapin Gambit", "r1bqk1nr/ppp2ppp/2p5/b3p3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8b4 c2c3 b4a5 b5c6 d7c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bb4 4. c3 Ba5 5. Bxc6 dxc6"),
  new FullOpening("C60", "Ruy Lopez: Brentano Gambit", "r1bqkbnr/pppp1p1p/2n5/1B2p1p1/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g7g5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 g5"),
  new FullOpening("C60", "Ruy Lopez: Bulgarian Variation", "r1bqkbnr/1ppp1ppp/2n5/pB2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a5"),
  new FullOpening("C60", "Ruy Lopez: Cozio Defense", "r1bqkb1r/ppppnppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7"),
  new FullOpening("C60", "Ruy Lopez: Cozio Defense, Paulsen Variation", "r1bqkb1r/ppppnp1p/2n3p1/1B2p3/4P3/2N2N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8e7 b1c3 g7g6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nge7 4. Nc3 g6"),
  new FullOpening("C60", "Ruy Lopez: Cozio Defense, Tartakower Gambit", "r1bq1rk1/ppp1npbp/2n3p1/1B1p4/3NP3/2N1B3/PPPQ1PPP/R3K2R w KQ -", "e2e4 e7e5 g1f3 b8c6 f1b5 g7g6 d2d4 e5d4 f3d4 f8g7 c1e3 g8e7 b1c3 e8g8 d1d2 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6 4. d4 exd4 5. Nxd4 Bg7 6. Be3 Nge7 7. Nc3 O-O 8. Qd2 d5"),
  new FullOpening("C60", "Ruy Lopez: Fianchetto Defense", "r1bqkbnr/pppp1p1p/2n3p1/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g7g6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6"),
  new FullOpening("C60", "Ruy Lopez: Fianchetto Defense, Kevitz Gambit", "r1bqkbnr/pppp3p/2n3p1/1B2pp2/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g7g6 c2c3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6 4. c3 f5"),
  new FullOpening("C60", "Ruy Lopez: Lucena Variation", "r1bqk1nr/ppppbppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Be7"),
  new FullOpening("C60", "Ruy Lopez: Nürnberg Variation", "r1bqkbnr/pppp2pp/2n2p2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f6"),
  new FullOpening("C60", "Ruy Lopez: Pollock Defense", "r1bqkbnr/pppp1ppp/8/nB2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 c6a5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Na5"),
  new FullOpening("C60", "Ruy Lopez: Retreat Variation", "rnbqkbnr/pppp1ppp/8/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 c6b8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nb8"),
  new FullOpening("C60", "Ruy Lopez: Rotary-Albany Gambit", "r1bqkbnr/p1pp1ppp/1pn5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 b7b6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 b6"),
  new FullOpening("C60", "Ruy Lopez: Spanish Countergambit", "r1bqkbnr/ppp2ppp/2n5/1B1pp3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5"),
  new FullOpening("C60", "Ruy Lopez: Spanish Countergambit, Harding Countergambit, Fricke Gambit", "r1b1kbnr/ppp2ppp/2n5/1B1pN1q1/4P3/8/PPPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d5 f3e5 d8g5 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5 4. Nxe5 Qg5 5. O-O"),
  new FullOpening("C60", "Ruy Lopez: Spanish Countergambit, Harding Gambit", "r1b1kbnr/ppp2ppp/2N5/1B1p2q1/4P3/8/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d5 f3e5 d8g5 e5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d5 4. Nxe5 Qg5 5. Nxc6"),
  new FullOpening("C60", "Ruy Lopez: Vinogradov Variation", "r1b1kbnr/ppppqppp/2n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Qe7"),
  new FullOpening("C61", "Ruy Lopez: Bird Variation", "r1bqkbnr/pppp1ppp/8/1B2p3/3nP3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 c6d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4"),
  new FullOpening("C61", "Ruy Lopez: Bird Variation, Paulsen Variation", "r1bqkb1r/ppppnppp/8/1B6/3pP3/8/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 c6d4 f3d4 e5d4 e1g1 g8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nd4 4. Nxd4 exd4 5. O-O Ne7"),
  new FullOpening("C62", "Ruy Lopez: Old Steinitz Defense, Semi-Duras Variation", "r2qkbnr/pppb1ppp/2np4/1B2p3/2PPP3/5N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d6 d2d4 c8d7 c2c4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. c4"),
  new FullOpening("C62", "Ruy Lopez: Steinitz Defense", "r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6"),
  new FullOpening("C62", "Ruy Lopez: Steinitz Defense, Center Gambit", "r1bqkbnr/ppp2ppp/2np4/1B6/3pP3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d6 d2d4 e5d4 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 exd4 5. O-O"),
  new FullOpening("C62", "Ruy Lopez: Steinitz Defense, Nimzowitsch Attack", "r2qkb1r/pppb1ppp/2Bp1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d6 d2d4 c8d7 b1c3 g8f6 b5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. Nc3 Nf6 6. Bxc6"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense", "r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Classical Variation", "r1b1kbnr/ppp3pp/2N5/1B4q1/4p3/8/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 b1c3 f5e4 c3e4 d7d5 f3e5 d5e4 e5c6 d8g5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 d5 6. Nxe5 dxe4 7. Nxc6 Qg5"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Dyckhoff Variation", "r1bqkbnr/pppp2pp/2n5/1B2pp2/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Exchange Variation", "r1bqkbnr/pppp2pp/2B5/4pp2/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 b5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Bxc6"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Jaenisch Gambit Accepted", "r1bqkbnr/pppp2pp/2n5/1B2pP2/8/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 e4f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. exf5"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Kostić Defense", "r1bqk1nr/ppppb1pp/2n5/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 b1c3 f5e4 c3e4 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 Be7"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Möhring Variation", "r1b1kbnr/ppp3pp/2N5/1B1q4/4p3/8/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 b1c3 f5e4 c3e4 d7d5 f3e5 d5e4 e5c6 d8d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 d5 6. Nxe5 dxe4 7. Nxc6 Qd5"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Schönemann Attack", "r1bqkbnr/pppp2pp/2n5/1B2pp2/3PP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. d4"),
  new FullOpening("C63", "Ruy Lopez: Schliemann Defense, Tartakower Variation", "r1bqkb1r/pppp2pp/2n2n2/1B2p3/4N3/5N2/PPPP1PPP/R1BQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f7f5 b1c3 f5e4 c3e4 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 f5 4. Nc3 fxe4 5. Nxe4 Nf6"),
  new FullOpening("C64", "Ruy Lopez: Classical Defense, Benelux Variation", "r1bq1rk1/pppp1ppp/1bn2n2/1B2p3/3PP3/2P2N2/PP3PPP/RNBQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f8c5 c2c3 e8g8 d2d4 c5b6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. c3 O-O 6. d4 Bb6"),
  new FullOpening("C64", "Ruy Lopez: Classical Defense, Boden Variation", "r1b1k1nr/ppppqppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5 c2c3 d8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 Qe7"),
  new FullOpening("C64", "Ruy Lopez: Classical Defense, Zaitsev Variation", "r1bqk1nr/pppp1ppp/8/1Bb1p3/1P1nP3/5N2/P1PP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5 e1g1 c6d4 b2b4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. O-O Nd4 5. b4"),
  new FullOpening("C64", "Ruy Lopez: Classical Variation", "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5"),
  new FullOpening("C64", "Ruy Lopez: Classical Variation, Central Variation", "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3"),
  new FullOpening("C64", "Ruy Lopez: Classical Variation, Charousek Variation", "r1bqk1nr/pppp1ppp/1bn5/1B2p3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5 c2c3 c5b6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 Bb6"),
  new FullOpening("C64", "Ruy Lopez: Classical Variation, Cordel Gambit", "r1bqk1nr/pppp2pp/2n5/1Bb1pp2/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5 c2c3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 f5"),
  new FullOpening("C64", "Ruy Lopez: Classical Variation, Konikowski Gambit", "r1bqk1nr/ppp2ppp/2n5/1Bbpp3/4P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5 c2c3 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. c3 d5"),
  new FullOpening("C64", "Ruy Lopez: Classical Variation, Modern Main Line", "r1bq1rk1/pppp1ppp/1bn2n2/1B2p1B1/3PP3/2P2N2/PP3PPP/RN1Q1RK1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f8c5 c2c3 e8g8 d2d4 c5b6 c1g5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. c3 O-O 6. d4 Bb6 7. Bg5"),
  new FullOpening("C64", "Ruy Lopez: Classical Variation, Spanish Wing Gambit", "r1bqk1nr/pppp1ppp/2n5/1Bb1p3/1P2P3/5N2/P1PP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 f8c5 b2b4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Bc5 4. b4"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense", "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense", "r1bqkb1r/pppp1ppp/2n2n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Anderssen Variation", "r1bqkb1r/ppp2ppp/2Bp1n2/4p3/4P3/3P1N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 d2d3 d7d6 b5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 d6 5. Bxc6+"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Beverwijk Variation", "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Duras Variation", "r1bqkb1r/ppp2ppp/2np1n2/1B2p3/2P1P3/3P1N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 d2d3 d7d6 c2c4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 d6 5. c4"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Fishing Pole Variation", "r1bqkb1r/pppp1ppp/2n5/1B2p3/4P1n1/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6g4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Ng4"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Kaufmann Variation", "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/3PBN2/PPP2PPP/RN1QK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 d2d3 f8c5 c1e3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Bc5 5. Be3"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Mortimer Trap", "r1bqkb1r/pp1pnppp/2p2n2/1B2N3/4P3/3P4/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 d2d3 c6e7 f3e5 c7c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Ne7 5. Nxe5 c6"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Mortimer Variation", "r1bqkb1r/ppppnppp/5n2/1B2p3/4P3/3P1N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 d2d3 c6e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d3 Ne7"),
  new FullOpening("C65", "Ruy Lopez: Berlin Defense, Nyholm Attack", "r1bqkb1r/pppp1ppp/2n2n2/1B6/3pP3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 d2d4 e5d4 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. d4 exd4 5. O-O"),
  new FullOpening("C65", "Ruy Lopez: Classical Variation, Zukertort Gambit", "r1bqk2r/pppp1ppp/2n2n2/1Bb1p3/4P3/2P2N2/PP1P1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f8c5 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Bc5 5. c3"),
  new FullOpening("C65", "Ruy Lopez: Halloween Attack", "r1bqkb1r/pppp1ppp/2n2n2/1B2N3/4P3/8/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 f3e5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. Nxe5"),
  new FullOpening("C66", "Ruy Lopez: Berlin Defense, Closed Bernstein Variation", "r2qk2r/pppbbppp/2np1n2/1B2p1B1/3PP3/2N2N2/PPP2PPP/R2Q1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 d7d6 d2d4 c8d7 b1c3 f8e7 c1g5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 Be7 7. Bg5"),
  new FullOpening("C66", "Ruy Lopez: Berlin Defense, Closed Showalter Variation", "r2qk2r/pppbbppp/2Bp1n2/4p3/3PP3/2N2N2/PPP2PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 d7d6 d2d4 c8d7 b1c3 f8e7 b5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 Be7 7. Bxc6"),
  new FullOpening("C66", "Ruy Lopez: Berlin Defense, Closed Wolf Variation", "r2qkb1r/pppb1ppp/2np1n2/1B6/3pP3/2N2N2/PPP2PPP/R1BQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 d7d6 d2d4 c8d7 b1c3 e5d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 exd4"),
  new FullOpening("C66", "Ruy Lopez: Berlin Defense, Hedgehog Variation", "r2qk2r/pppbbppp/2np1n2/1B2p3/3PP3/2N2N2/PPP2PPP/R1BQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 d7d6 d2d4 c8d7 b1c3 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Bd7 6. Nc3 Be7"),
  new FullOpening("C66", "Ruy Lopez: Berlin Defense, Improved Steinitz Defense", "r1bqkb1r/ppp2ppp/2np1n2/1B2p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6"),
  new FullOpening("C66", "Ruy Lopez: Berlin Defense, Tarrasch Trap", "r2q1rk1/pppbbppp/2np1n2/1B2p3/3PP3/2N2N2/PPP2PPP/R1BQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 d7d6 d2d4 c8d7 b1c3 g8f6 e1g1 f8e7 f1e1 e8g8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 d6 4. d4 Bd7 5. Nc3 Nf6 6. O-O Be7 7. Re1 O-O"),
  new FullOpening("C66", "Ruy Lopez: Closed Berlin Defense, Chigorin Variation", "r1bqkb1r/pppn1ppp/2np4/1B2p3/3PP3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 d7d6 d2d4 f6d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O d6 5. d4 Nd7"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Berlin Wall", "r2k1b1r/pppb1ppp/2p5/4Pn2/8/2N2N2/PPP2PPP/R1B2RK1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 e4d6 b5c6 d7c6 d4e5 d6f5 d1d8 e8d8 b1c3 c8d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. Bxc6 dxc6 7. dxe5 Nf5 8. Qxd8+ Kxd8 9. Nc3 Bd7"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Cordel Variation", "r1bqk2r/p1ppbppp/2p5/4Pn2/8/5N2/PPP1QPPP/RNB2RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7 d1e2 e4d6 b5c6 b7c6 d4e5 d6f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 Nd6 7. Bxc6 bxc6 8. dxe5 Nf5"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Minckwitz Variation", "r1bqk2r/ppppbppp/2n5/1B2P3/4n3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7 d4e5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. dxe5"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Pillsbury Variation", "r1bqk2r/pnppbppp/2p5/4P3/8/1P3N2/P1P1QPPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7 d1e2 e4d6 b5c6 b7c6 d4e5 d6b7 b2b3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 Nd6 7. Bxc6 bxc6 8. dxe5 Nb7 9. b3"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Rio Gambit Accepted", "r1bqkb1r/pppp1ppp/2n5/1B2p3/4n3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Rio de Janeiro Variation", "r1bqk2r/ppppbppp/2n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Rio de Janeiro Variation", "r1bq1rk1/p1ppbppp/8/2p1P3/3B4/2N5/PPP1QPPP/R3R1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7 d1e2 e4d6 b5c6 b7c6 d4e5 d6b7 b1c3 e8g8 f1e1 b7c5 f3d4 c5e6 c1e3 e6d4 e3d4 c6c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 Nd6 7. Bxc6 bxc6 8. dxe5 Nb7 9. Nc3 O-O 10. Re1 Nc5 11. Nd4 Ne6 12. Be3 Nxd4 13. Bxd4 c5"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Rosenthal Variation", "r1bqkb1r/1ppp1ppp/p1n5/1B2p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 a7a6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 a6"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Trifunovic Variation", "r1bqk2r/ppp1bppp/2n5/1B1pp3/3Pn3/5N2/PPP1QPPP/RNB2RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7 d1e2 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 d5"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Winawer Attack", "r1bqk2r/pnppbppp/2p5/4P3/3N4/8/PPP1QPPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7 d1e2 e4d6 b5c6 b7c6 d4e5 d6b7 f3d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 Nd6 7. Bxc6 bxc6 8. dxe5 Nb7 9. Nd4"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, Zukertort Variation", "r1bqk2r/pnppbppp/2p5/4P3/2P5/5N2/PP2QPPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 f8e7 d1e2 e4d6 b5c6 b7c6 d4e5 d6b7 c2c4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Be7 6. Qe2 Nd6 7. Bxc6 bxc6 8. dxe5 Nb7 9. c4"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, l'Hermet Variation", "r1bqkb1r/pppp1ppp/2nn4/1B2p3/3P4/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 e4d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, l'Hermet Variation, Berlin Wall Defense", "r1bk1b1r/ppp2ppp/2p5/4Pn2/8/5N2/PPP2PPP/RNB2RK1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 e4d6 b5c6 d7c6 d4e5 d6f5 d1d8 e8d8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. Bxc6 dxc6 7. dxe5 Nf5 8. Qxd8+ Kxd8"),
  new FullOpening("C67", "Ruy Lopez: Berlin Defense, l'Hermet Variation, Westerinen Line", "r1bqkb1r/ppp2ppp/2p5/4P3/4n3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 e4d6 b5c6 d7c6 d4e5 d6e4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. Bxc6 dxc6 7. dxe5 Ne4"),
  new FullOpening("C67", "Ruy Lopez: Open Berlin Defense, Showalter Variation", "r1bqkb1r/pppp1ppp/2nn4/4p3/B2P4/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 e4d6 b5a4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. Ba4"),
  new FullOpening("C67", "Ruy Lopez: Open Berlin Defense, l'Hermet Variation", "r1bqkb1r/pppp1ppp/2nn4/1B2P3/8/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g8f6 e1g1 f6e4 d2d4 e4d6 d4e5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 Nf6 4. O-O Nxe4 5. d4 Nd6 6. dxe5"),
  new FullOpening("C68", "Ruy Lopez: Exchange Variation", "r1bqkbnr/1ppp1ppp/p1B5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6"),
  new FullOpening("C68", "Ruy Lopez: Exchange Variation, Alekhine Variation", "r1b1k1nr/1pp2ppp/p1pb4/8/3NP3/8/PPP2PPP/RNB1K2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 d2d4 e5d4 d1d4 d8d4 f3d4 f8d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. d4 exd4 6. Qxd4 Qxd4 7. Nxd4 Bd6"),
  new FullOpening("C68", "Ruy Lopez: Exchange Variation, Keres Variation", "r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nc3"),
  new FullOpening("C68", "Ruy Lopez: Exchange Variation, Lutikov Variation", "r1bqkbnr/2pp1ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 b7c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 bxc6"),
  new FullOpening("C68", "Ruy Lopez: Exchange Variation, Romanovsky Variation", "r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/2NP1N2/PPP2PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 b1c3 f7f6 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. Nc3 f6 6. d3"),
  new FullOpening("C68", "Ruy Lopez: Exchange, Alekhine Variation", "r3kbnr/1ppb1ppp/p1p5/8/3NP3/8/PPP2PPP/RNB1K2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 d2d4 e5d4 d1d4 d8d4 f3d4 c8d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. d4 exd4 6. Qxd4 Qxd4 7. Nxd4 Bd7"),
  new FullOpening("C69", "Ruy Lopez: Exchange Variation, Alapin Gambit", "r2qkbnr/1pp2pp1/p1p5/4p2p/4P1b1/5N1P/PPPP1PP1/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 e1g1 c8g4 h2h3 h7h5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bg4 6. h3 h5"),
  new FullOpening("C69", "Ruy Lopez: Exchange Variation, Bronstein Variation", "r1b1kbnr/1pp2ppp/p1pq4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 e1g1 d8d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Qd6"),
  new FullOpening("C69", "Ruy Lopez: Exchange Variation, Gligoric Variation", "r1bqkbnr/1pp3pp/p1p2p2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 e1g1 f7f6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O f6"),
  new FullOpening("C69", "Ruy Lopez: Exchange Variation, King's Bishop Variation", "r1bqk1nr/1pp2ppp/p1pb4/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 e1g1 f8d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O Bd6"),
  new FullOpening("C69", "Ruy Lopez: Exchange Variation, Normal Variation", "r1bqkbnr/1pp2ppp/p1p5/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5c6 d7c6 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Bxc6 dxc6 5. O-O"),
  new FullOpening("C70", "Ruy Lopez: Bird's Defense Deferred", "r1bqkbnr/1ppp1ppp/p7/4p3/B2nP3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 c6d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nd4"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense", "r1bqkbnr/1ppp1ppp/p1n5/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Alapin's Defense Deferred", "r1bqk1nr/1ppp1ppp/p1n5/4p3/Bb2P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 f8b4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Bb4"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Caro Variation", "r1bqkbnr/2pp1ppp/p1n5/1p2p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 b7b5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Classical Defense Deferred", "r1bqk1nr/1ppp1ppp/p1n5/2b1p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Bc5"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Cozio Defense", "r1bqkb1r/1pppnppp/p1n5/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nge7"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Fianchetto Defense Deferred", "r1bqkbnr/1ppp1p1p/p1n3p1/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g7g6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 g6"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Graz Variation", "r1bqk1nr/2pp1ppp/p1n5/1pb1p3/4P3/1B3N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 b7b5 a4b3 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Bc5"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Norwegian Variation", "r1bqkbnr/2pp1ppp/p7/np2p3/4P3/1B3N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 b7b5 a4b3 c6a5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Na5"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Norwegian Variation, Nightingale Gambit", "r1bqkbnr/2pp1Bpp/p7/np2p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 b7b5 a4b3 c6a5 b3f7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 Na5 6. Bxf7+"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Schliemann Defense Deferred", "r1bqkbnr/1ppp2pp/p1n5/4pp2/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 f5"),
  new FullOpening("C70", "Ruy Lopez: Morphy Defense, Schliemann Defense Deferred, Jaenisch Gambit Deferred", "r1bqkbnr/1ppp2pp/p1n5/4pP2/B7/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 f7f5 e4f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 f5 5. exf5"),
  new FullOpening("C71", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6"),
  new FullOpening("C71", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Nc3"),
  new FullOpening("C71", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r1bqkbnr/1pp2ppp/p1np4/4p3/B1P1P3/5N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 c2c4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c4"),
  new FullOpening("C71", "Ruy Lopez: Noah's Ark Trap", "r1bqkbnr/5ppp/p2p4/1pp5/3QP3/1B6/PPP2PPP/RNB1K2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 b7b5 a4b3 d7d6 d2d4 c6d4 f3d4 e5d4 d1d4 c7c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 b5 5. Bb3 d6 6. d4 Nxd4 7. Nxd4 exd4 8. Qxd4 c5"),
  new FullOpening("C72", "Ruy Lopez: Closed, Kecskemet Variation", "r2qbrk1/1pp1bppp/p1np1n2/4p3/B2PP3/2P2N2/PP1N1PPP/R1BQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 e1g1 c8d7 c2c3 g8f6 d2d4 f8e7 b1d2 e8g8 f1e1 d7e8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. O-O Bd7 6. c3 Nf6 7. d4 Be7 8. Nbd2 O-O 9. Re1 Be8"),
  new FullOpening("C72", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. O-O"),
  new FullOpening("C73", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r1bqkbnr/2p2ppp/p1pp4/4p3/3PP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 a4c6 b7c6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Bxc6+ bxc6 6. d4"),
  new FullOpening("C73", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r1bqkbnr/2p3pp/p1pp1p2/4p3/3PP3/5N2/PPP2PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 a4c6 b7c6 d2d4 f7f6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. Bxc6+ bxc6 6. d4 f6"),
  new FullOpening("C74", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r1bqkbnr/1pp2ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3"),
  new FullOpening("C74", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r2qkbnr/1pp3pp/p1np4/4pb2/B7/2P2N2/PP1P1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 c2c3 f7f5 e4f5 c8f5 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 f5 6. exf5 Bxf5 7. O-O"),
  new FullOpening("C74", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense, Siesta Variation", "r1bqkbnr/1pp3pp/p1np4/4pp2/B3P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 c2c3 f7f5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 f5"),
  new FullOpening("C75", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r2qkbnr/1ppb1ppp/p1np4/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 c2c3 c8d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 Bd7"),
  new FullOpening("C75", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense", "r2qkb1r/1ppbnppp/p1np4/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 d7d6 c2c3 c8d7 d2d4 g8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 d6 5. c3 Bd7 6. d4 Nge7"),
  new FullOpening("C76", "Ruy Lopez: Morphy Defense, Modern Steinitz Defense, Fianchetto Variation", "r2qkbnr/1ppb1p1p/p1np2p1/4p3/B2PP3/2P2N2/PP3PPP/RNBQK2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 g7g6 c2c3 a7a6 b5a4 d7d6 d2d4 c8d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 g6 4. c3 a6 5. Ba4 d6 6. d4 Bd7"),
  new FullOpening("C77", "Ruy Lopez: Morphy Defense, Anderssen Variation", "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3"),
  new FullOpening("C77", "Ruy Lopez: Morphy Defense, Bayreuth Variation", "r1bqkb1r/1ppp1ppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 a4c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Bxc6"),
  new FullOpening("C77", "Ruy Lopez: Morphy Defense, Duras Variation", "r1bqkb1r/1pp2ppp/p1np1n2/4p3/B1P1P3/3P1N2/PP3PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 d2d3 d7d6 c2c4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d3 d6 6. c4"),
  new FullOpening("C77", "Ruy Lopez: Morphy Defense, Jaffe Gambit", "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/2P2N2/PP1P1PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. c3"),
  new FullOpening("C77", "Ruy Lopez: Morphy Defense, Mackenzie Variation", "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. d4"),
  new FullOpening("C77", "Ruy Lopez: Morphy Defense, Tarrasch Variation", "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/2N2N2/PPPP1PPP/R1BQK2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Nc3"),
  new FullOpening("C77", "Ruy Lopez: Morphy Defense, Wormald Attack", "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB1K2R b KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 d1e2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Qe2"),
  new FullOpening("C77", "Ruy Lopez: Wormald Attack, Grünfeld Variation", "r2qk2r/2p1bppp/p1np1n2/1p2p3/3PP1b1/1BP2N2/PP2QPPP/RNB1K2R w KQkq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 d1e2 b7b5 a4b3 f8e7 d2d4 d7d6 c2c3 c8g4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. Qe2 b5 6. Bb3 Be7 7. d4 d6 8. c3 Bg4"),
  new FullOpening("C78", "Ruy Lopez: Brix Variation", "r1bqkb1r/1ppp1p1p/p1n2np1/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 g7g6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O g6"),
  new FullOpening("C78", "Ruy Lopez: Central Countergambit", "r1bqkb1r/1pp2ppp/p1n2n2/3pp3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d5"),
  new FullOpening("C78", "Ruy Lopez: Morphy Defense", "r1bqkb1r/1ppp1ppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O"),
  new FullOpening("C78", "Ruy Lopez: Morphy Defense", "r1bqkb1r/2p2ppp/p1np1n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 b7b5 a4b3 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 d6"),
  new FullOpening("C78", "Ruy Lopez: Morphy Defense, Arkhangelsk Variation", "r2qkb1r/1bpp1ppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 b7b5 a4b3 c8b7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 Bb7"),
  new FullOpening("C78", "Ruy Lopez: Morphy Defense, Neo-Arkhangelsk Variation", "r1bqk2r/1ppp1ppp/p1n2n2/2b1p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Bc5"),
  new FullOpening("C78", "Ruy Lopez: Morphy Defense, Wing Attack", "r1bqk2r/2ppbppp/p1n2n2/1p2p3/P3P3/1B3N2/1PPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 b7b5 a4b3 f8e7 a2a4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 Be7 7. a4"),
  new FullOpening("C78", "Ruy Lopez: Rabinovich Variation", "r1bq1k1r/2p2ppp/p4n2/1pbPR1N1/3n4/1B6/PPPP1PPP/RNBQ2K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 b7b5 a4b3 d7d6 f3g5 d6d5 e4d5 c6d4 f1e1 f8c5 e1e5 e8f8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O b5 6. Bb3 d6 7. Ng5 d5 8. exd5 Nd4 9. Re1 Bc5 10. Rxe5+ Kf8"),
  new FullOpening("C79", "Ruy Lopez: Morphy Defense, Steinitz Deferred", "r1bqkb1r/1pp2ppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6"),
  new FullOpening("C79", "Ruy Lopez: Morphy Defense, Steinitz Deferred", "r1bqkb1r/2p2ppp/p1pp4/4p3/3Pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 d7d6 a4c6 b7c6 d2d4 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6 6. Bxc6+ bxc6 7. d4 Nxe4"),
  new FullOpening("C79", "Ruy Lopez: Steinitz Defense Deferred, Boleslavsky Variation", "r1bqkb1r/2p3pp/p1p5/3pPp2/4n3/2N2N2/PPP2PPP/R1BQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 d7d6 a4c6 b7c6 d2d4 f6e4 f1e1 f7f5 d4e5 d6d5 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6 6. Bxc6+ bxc6 7. d4 Nxe4 8. Re1 f5 9. dxe5 d5 10. Nc3"),
  new FullOpening("C79", "Ruy Lopez: Steinitz Defense Deferred, Lipnitsky Variation", "r2qkb1r/2p2ppp/p1pp1n2/4p3/3PP1b1/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 d7d6 a4c6 b7c6 d2d4 c8g4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O d6 6. Bxc6+ bxc6 7. d4 Bg4"),
  new FullOpening("C80", "Ruy Lopez: Morphy Defense, Tartakower Variation", "r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPPQPPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d1e2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Qe2"),
  new FullOpening("C80", "Ruy Lopez: Open", "r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4"),
  new FullOpening("C80", "Ruy Lopez: Open", "r1bqkb1r/1ppp1ppp/p1n5/4p3/B2Pn3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4"),
  new FullOpening("C80", "Ruy Lopez: Open", "r1bqkb1r/2pp1ppp/p1n5/1p2p3/3Pn3/1B3N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3"),
  new FullOpening("C80", "Ruy Lopez: Open", "r1bqkb1r/2p2ppp/p1n5/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5"),
  new FullOpening("C80", "Ruy Lopez: Open, Berger Variation", "r1bqkb1r/2p2ppp/p7/1p1p4/P2pn3/1BN5/1PP2PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 a2a4 c6d4 f3d4 e5d4 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. a4 Nxd4 9. Nxd4 exd4 10. Nc3"),
  new FullOpening("C80", "Ruy Lopez: Open, Bernstein Variation", "r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPPN1PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 b1d2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. Nbd2"),
  new FullOpening("C80", "Ruy Lopez: Open, Bernstein Variation, Luther Line", "r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1B3N2/PPPN1PPP/R1B1QRK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 b1d2 f8c5 d1e1", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. Nbd2 Bc5 10. Qe1"),
  new FullOpening("C80", "Ruy Lopez: Open, Friess Attack", "r1bqkb1r/2pp1ppp/p1n5/1p2N3/B2Pn3/8/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 f3e5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Nxe5"),
  new FullOpening("C80", "Ruy Lopez: Open, Harksen Gambit", "r1bqkb1r/2p2ppp/p1n5/1p1pp3/2PPn3/1B3N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 c2c4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. c4"),
  new FullOpening("C80", "Ruy Lopez: Open, Karpov Gambit", "r2qkb1r/2p2ppp/p1n1b3/1pn1P1N1/3p4/1BP5/PP1N1PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 b1d2 e4c5 c2c3 d5d4 f3g5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. Nbd2 Nc5 10. c3 d4 11. Ng5"),
  new FullOpening("C80", "Ruy Lopez: Open, Knorre Variation", "r1bqkb1r/1ppp1ppp/p1n5/4p3/B3n3/2N2N2/PPPP1PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Nc3"),
  new FullOpening("C80", "Ruy Lopez: Open, Main Line", "r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6"),
  new FullOpening("C80", "Ruy Lopez: Open, Richter Variation", "r1bqkb1r/2pp1ppp/p1n5/1p1Pp3/B3n3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 d4d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. d5"),
  new FullOpening("C80", "Ruy Lopez: Open, Riga Variation", "r1bqkb1r/1ppp1ppp/p1n5/8/B2pn3/5N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 e5d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 exd4"),
  new FullOpening("C80", "Ruy Lopez: Open, Schlechter Defense", "r1bqkb1r/2p2ppp/p7/1p1pp3/P2nn3/1B3N2/1PP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 a2a4 c6d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. a4 Nxd4"),
  new FullOpening("C80", "Ruy Lopez: Open, Skipworth Gambit", "r1bqkb1r/1pp2ppp/p1n5/3pp3/B3n3/5N2/PPPP1PPP/RNBQR1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 f1e1 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. Re1 d5"),
  new FullOpening("C80", "Ruy Lopez: Open, Zukertort Variation", "r1bqkb1r/2p1nppp/p7/1p1pP3/4n3/1B3N2/PPP2PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c6e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Ne7"),
  new FullOpening("C81", "Ruy Lopez: Open, Howell Attack", "r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1B3N2/PPP1QPPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 d1e2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. Qe2"),
  new FullOpening("C81", "Ruy Lopez: Open, Howell Attack", "r2qk2r/2p1bppp/p1n1b3/1p1pP3/2P1n3/1B3N2/PP2QPPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 d1e2 f8e7 c2c4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. Qe2 Be7 10. c4"),
  new FullOpening("C81", "Ruy Lopez: Open, Howell Attack, Ekstrom Variation", "r4rk1/2pqbppp/p1n1b3/3pP3/2B1n3/5N2/PP2QPPP/RNBR2K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 d1e2 f8e7 f1d1 e8g8 c2c4 b5c4 b3c4 d8d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. Qe2 Be7 10. Rd1 O-O 11. c4 bxc4 12. Bxc4 Qd7"),
  new FullOpening("C82", "Ruy Lopez: Open", "r2qkb1r/2p2ppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3"),
  new FullOpening("C82", "Ruy Lopez: Open, Berlin Variation", "r2qkb1r/2p2ppp/p1n1b3/1pnpP3/8/1BP2N2/PP3PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 e4c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Nc5"),
  new FullOpening("C82", "Ruy Lopez: Open, Dilworth Variation", "r2q1rk1/2p2ppp/p1n1b3/1pbpP3/8/2P2N2/PPBN1nPP/R1BQ1RK1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8c5 b1d2 e8g8 b3c2 e4f2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Bc5 10. Nbd2 O-O 11. Bc2 Nxf2"),
  new FullOpening("C82", "Ruy Lopez: Open, Italian Variation", "r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Bc5"),
  new FullOpening("C82", "Ruy Lopez: Open, Motzko Attack", "r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BPQ1N2/PP3PPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8c5 d1d3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Bc5 10. Qd3"),
  new FullOpening("C82", "Ruy Lopez: Open, Motzko Attack, Nenarokov Variation", "r2qk2r/2p1nppp/p3b3/1pbpP3/4n3/1BPQ1N2/PP3PPP/RNB2RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8c5 d1d3 c6e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Bc5 10. Qd3 Ne7"),
  new FullOpening("C82", "Ruy Lopez: Open, St. Petersburg Variation", "r2qk2r/2p2ppp/p1n1b3/1pbpP3/4n3/1BP2N2/PP1N1PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8c5 b1d2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Bc5 10. Nbd2"),
  new FullOpening("C83", "Ruy Lopez: Open, Breslau Variation", "r2q1rk1/2p1bppp/p3b3/1p1pn3/3Nn3/1BP5/PP3PPP/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8e7 f1e1 e8g8 f3d4 c6e5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Be7 10. Re1 O-O 11. Nd4 Nxe5"),
  new FullOpening("C83", "Ruy Lopez: Open, Classical Defense", "r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Be7"),
  new FullOpening("C83", "Ruy Lopez: Open, Classical Defense, Main Line", "r2qk2r/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP3PPP/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 f8e7 f1e1 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 Be7 7. Re1 b5 8. Bb3 d5 9. dxe5 Be6 10. c3"),
  new FullOpening("C83", "Ruy Lopez: Open, Malkin Variation", "r2q1rk1/2p1bppp/p1n1b3/1p1pP3/4n3/1BP2N2/PP1NQPPP/R1B2RK1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8e7 b1d2 e8g8 d1e2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Be7 10. Nbd2 O-O 11. Qe2"),
  new FullOpening("C83", "Ruy Lopez: Open, Tarrasch Trap", "r4rk1/2pqb1pp/p1n1p3/1p1pP3/4R3/1BP5/PP3PPP/RNBQ2K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f6e4 d2d4 b7b5 a4b3 d7d5 d4e5 c8e6 c2c3 f8e7 f1e1 e8g8 f3d4 d8d7 d4e6 f7e6 e1e4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Nxe4 6. d4 b5 7. Bb3 d5 8. dxe5 Be6 9. c3 Be7 10. Re1 O-O 11. Nd4 Qd7 12. Nxe6 fxe6 13. Rxe4"),
  new FullOpening("C84", "Ruy Lopez: Closed", "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPP1PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7"),
  new FullOpening("C84", "Ruy Lopez: Closed, Basque Gambit", "r1bqk2r/1pppbppp/p1n5/4P3/B2pn3/2P2N2/PP3PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 d2d4 e5d4 e4e5 f6e4 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d4 exd4 7. e5 Ne4 8. c3"),
  new FullOpening("C84", "Ruy Lopez: Closed, Center Attack", "r1bqk2r/1pppbppp/p1n2n2/4p3/B2PP3/5N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d4"),
  new FullOpening("C84", "Ruy Lopez: Closed, Center Attack, Basque Gambit", "r1bqk2r/1pppbppp/p1n5/4P3/B3n3/2p2N2/PP3PPP/RNBQ1RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 d2d4 e5d4 e4e5 f6e4 c2c3 d4c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d4 exd4 7. e5 Ne4 8. c3 dxc3"),
  new FullOpening("C84", "Ruy Lopez: Closed, Martinez Variation", "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/3P1N2/PPP2PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. d3"),
  new FullOpening("C84", "Ruy Lopez: Closed, Morphy Attack", "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/2N2N2/PPPP1PPP/R1BQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 b1c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Nc3"),
  new FullOpening("C85", "Ruy Lopez: Closed, Delayed Exchange", "r1bqk2r/1pppbppp/p1B2n2/4p3/4P3/5N2/PPPP1PPP/RNBQ1RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 a4c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Bxc6"),
  new FullOpening("C86", "Ruy Lopez: Closed, Worrall Attack", "r1bqk2r/1pppbppp/p1n2n2/4p3/B3P3/5N2/PPPPQPPP/RNB2RK1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 d1e2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Qe2"),
  new FullOpening("C86", "Ruy Lopez: Closed, Worrall Attack, Castling Line", "r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 d1e2 b7b5 a4b3 e8g8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Qe2 b5 7. Bb3 O-O"),
  new FullOpening("C86", "Ruy Lopez: Closed, Worrall Attack, Delayed Castling Line", "r1bqk2r/2p1bppp/p1np1n2/1p2p3/4P3/1B3N2/PPPPQPPP/RNB2RK1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 d1e2 b7b5 a4b3 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Qe2 b5 7. Bb3 d6"),
  new FullOpening("C87", "Ruy Lopez: Closed, Averbakh Variation", "r1bqk2r/1pp1bppp/p1np1n2/4p3/B3P3/5N2/PPPP1PPP/RNBQR1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 d7d6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 d6"),
  new FullOpening("C88", "Ruy Lopez: Closed", "r1bqk2r/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3"),
  new FullOpening("C88", "Ruy Lopez: Closed", "r1bq1rk1/2ppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O"),
  new FullOpening("C88", "Ruy Lopez: Closed, Alekhine Gambit", "r4rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P1NQ2/PPB2PPP/R1B1R1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 c6a5 b3c2 c7c5 d2d4 d8c7 b1d2 e8g8 d2f1 c8g4 f1e3 g4f3 d1f3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 Na5 9. Bc2 c5 10. d4 Qc7 11. Nbd2 O-O 12. Nf1 Bg4 13. Ne3 Bxf3 14. Qxf3"),
  new FullOpening("C88", "Ruy Lopez: Closed, Anti-Marshall", "r1bq1rk1/2ppbppp/p1n2n2/1p2p3/P3P3/1B3N2/1PPP1PPP/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 a2a4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. a4"),
  new FullOpening("C88", "Ruy Lopez: Closed, Balla Variation", "r1b1k2r/2q1bppp/p2p1n2/npp1p3/P2PP3/2P2N2/1PB2PPP/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 c6a5 b3c2 c7c5 d2d4 d8c7 a2a4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 Na5 9. Bc2 c5 10. d4 Qc7 11. a4"),
  new FullOpening("C88", "Ruy Lopez: Closed, Leonhardt Variation", "rnb1k2r/2q1bp1p/p2p1n2/1ppPp1p1/4P3/2P2N1P/PPBN1PP1/R1BQR1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 c6a5 b3c2 c7c5 d2d4 d8c7 h2h3 a5c6 d4d5 c6b8 b1d2 g7g5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 Na5 9. Bc2 c5 10. d4 Qc7 11. h3 Nc6 12. d5 Nb8 13. Nbd2 g5"),
  new FullOpening("C88", "Ruy Lopez: Closed, Rosen Attack", "r1bqk2r/2p1bppp/p1np1n2/1p2p3/3PP3/1B3N2/PPP2PPP/RNBQR1K1 b kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. d4"),
  new FullOpening("C88", "Ruy Lopez: Closed, Trajkovic Counterattack", "r2qk2r/1bppbppp/p1n2n2/1p2p3/4P3/1B3N2/PPPP1PPP/RNBQR1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 c8b7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 Bb7"),
  new FullOpening("C88", "Ruy Lopez: Noah's Ark Trap", "r1bqk2r/4bppp/p2p1n2/1pp5/3QP3/1B6/PPP2PPP/RNB1R1K1 w kq -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 d2d4 c6d4 f3d4 e5d4 d1d4 c7c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. d4 Nxd4 9. Nxd4 exd4 10. Qxd4 c5"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack", "r1bq1rk1/2p1bppp/p1n2n2/1p1pp3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack, Main Line", "r1bq1rk1/4bppp/p1p5/1p1nR3/3P4/1BP5/PP3PPP/RNBQ2K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5 e4d5 f6d5 f3e5 c6e5 e1e5 c7c6 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. Nxe5 Nxe5 11. Rxe5 c6 12. d4"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack, Main Line, Spassky Variation", "5rk1/5ppp/p1pbr3/1p1n3q/P2P2b1/1BPQB1P1/1P1N1P1P/R3R1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5 e4d5 f6d5 f3e5 c6e5 e1e5 c7c6 d2d4 e7d6 e5e1 d8h4 g2g3 h4h3 c1e3 c8g4 d1d3 a8e8 b1d2 e8e6 a2a4 h3h5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. Nxe5 Nxe5 11. Rxe5 c6 12. d4 Bd6 13. Re1 Qh4 14. g3 Qh3 15. Be3 Bg4 16. Qd3 Rae8 17. Nd2 Re6 18. a4 Qh5"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack, Modern Main Line", "r1b2rk1/5ppp/p1pb4/1p1n4/3P4/1BP3Pq/PP3P1P/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5 e4d5 f6d5 f3e5 c6e5 e1e5 c7c6 d2d4 e7d6 e5e1 d8h4 g2g3 h4h3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. Nxe5 Nxe5 11. Rxe5 c6 12. d4 Bd6 13. Re1 Qh4 14. g3 Qh3"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack, Modern Variation", "r1bq1rk1/4bppp/p1p5/1p1nR3/8/1BP5/PP1P1PPP/RNBQ2K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5 e4d5 f6d5 f3e5 c6e5 e1e5 c7c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. Nxe5 Nxe5 11. Rxe5 c6"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack, Original Marshall Attack", "r1bq1rk1/2p1bppp/p4n2/1p2R3/8/1BP5/PP1P1PPP/RNBQ2K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5 e4d5 f6d5 f3e5 c6e5 e1e5 d5f6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. Nxe5 Nxe5 11. Rxe5 Nf6"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack, Re3 Variation", "r1bq1rk1/5ppp/p2b4/1p1p4/3P4/2P1R3/PP3PPP/RNBQ2K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5 e4d5 f6d5 f3e5 c6e5 e1e5 c7c6 b3d5 c6d5 d2d4 e7d6 e5e3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 Nxd5 10. Nxe5 Nxe5 11. Rxe5 c6 12. Bxd5 cxd5 13. d4 Bd6 14. Re3"),
  new FullOpening("C89", "Ruy Lopez: Marshall Attack, Steiner Variation", "r1bq1rk1/2p1bppp/p1n2n2/1p1P4/4p3/1BP2N2/PP1P1PPP/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d5 e4d5 e5e4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d5 9. exd5 e4"),
  new FullOpening("C90", "Ruy Lopez: Closed, Closed Defense", "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N2/PP1P1PPP/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O"),
  new FullOpening("C90", "Ruy Lopez: Closed, Lutikov Variation", "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/2P2N2/PPBP1PPP/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 b3c2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. Bc2"),
  new FullOpening("C90", "Ruy Lopez: Closed, Pilnik Variation", "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BPP1N2/PP3PPP/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 d2d3 d7d6 c2c3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. d3 d6 9. c3"),
  new FullOpening("C90", "Ruy Lopez: Closed, Suetin Variation", "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/PBP2N2/1P1P1PPP/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 a2a3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. a3"),
  new FullOpening("C91", "Ruy Lopez: Closed, Bogoljubov Variation", "r2q1rk1/2p1bppp/p1np1n2/1p2p3/3PP1b1/1BP2N2/PP3PPP/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 d2d4 c8g4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. d4 Bg4"),
  new FullOpening("C91", "Ruy Lopez: Closed, Yates Variation", "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/3PP3/1BP2N2/PP3PPP/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. d4"),
  new FullOpening("C91", "Ruy Lopez: Closed, Yates Variation, Short Attack", "r2q1rk1/2p1bppp/p1np1n2/1p2p3/P2PP1b1/1BP2N2/1P3PPP/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 d2d4 c8g4 a2a4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. d4 Bg4 10. a4"),
  new FullOpening("C92", "Ruy Lopez: Closed", "r1bq1rk1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3"),
  new FullOpening("C92", "Ruy Lopez: Closed, Flohr System", "r2q1rk1/1bp1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c8b7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Bb7"),
  new FullOpening("C92", "Ruy Lopez: Closed, Keres Defense", "r1bq1rk1/2pnbppp/p1np4/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 f6d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nd7"),
  new FullOpening("C92", "Ruy Lopez: Closed, Keres Defense", "r1bq1rk1/2p1bppp/2np1n2/pp2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d6 h2h3 a6a5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d6 9. h3 a5"),
  new FullOpening("C92", "Ruy Lopez: Closed, Kholmov Variation", "r2q1rk1/2p1bppp/p1npbn2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c8e6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Be6"),
  new FullOpening("C92", "Ruy Lopez: Closed, Smyslov-Breyer-Zaitsev Hybrid", "r2qrbk1/1bp2pp1/p1np1n1p/1p2p3/3PP3/PBP2N1P/1P1N1PP1/R1BQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c8b7 d2d4 f8e8 b1d2 e7f8 a2a3 h7h6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Bb7 10. d4 Re8 11. Nbd2 Bf8 12. a3 h6"),
  new FullOpening("C92", "Ruy Lopez: Closed, Zaitsev System", "r1bqr1k1/2p1bppp/p1np1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 f8e8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Re8"),
  new FullOpening("C93", "Ruy Lopez: Closed, Smyslov Defense", "r1bq1rk1/2p1bpp1/p1np1n1p/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 h7h6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 h6"),
  new FullOpening("C94", "Ruy Lopez: Morphy Defense, Breyer Defense", "rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BP2N1P/PP1P1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6b8", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nb8"),
  new FullOpening("C94", "Ruy Lopez: Morphy Defense, Breyer Defense, Quiet Variation", "rnbq1rk1/2p1bppp/p2p1n2/1p2p3/4P3/1BPP1N1P/PP3PP1/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6b8 d2d3", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nb8 10. d3"),
  new FullOpening("C95", "Ruy Lopez: Closed, Breyer", "rnbq1rk1/2p1bppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6b8 d2d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nb8 10. d4"),
  new FullOpening("C95", "Ruy Lopez: Closed, Breyer Defense", "r2q1rk1/1b1nbppp/p2p1n2/1pp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6b8 d2d4 b8d7 b1d2 c8b7 b3c2 c7c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nb8 10. d4 Nbd7 11. Nbd2 Bb7 12. Bc2 c5"),
  new FullOpening("C95", "Ruy Lopez: Closed, Breyer Defense", "r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP2N/1BP4P/PP3PP1/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6b8 d2d4 b8d7 f3h4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nb8 10. d4 Nbd7 11. Nh4"),
  new FullOpening("C95", "Ruy Lopez: Morphy Defense, Breyer Defense, Zaitsev Hybrid", "r1bq1rk1/2pnbppp/p2p1n2/1p2p3/3PP3/1BP2N1P/PP3PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6b8 d2d4 b8d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Nb8 10. d4 Nbd7"),
  new FullOpening("C96", "Ruy Lopez: Closed, Borisenko Variation", "r1bq1rk1/4bppp/p1np1n2/1pp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5 d2d4 a5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5 11. d4 Nc6"),
  new FullOpening("C96", "Ruy Lopez: Closed, Closed Defense", "r1bq1rk1/2p1bppp/p2p1n2/np2p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2"),
  new FullOpening("C96", "Ruy Lopez: Closed, Closed Defense", "r1bq1rk1/4bppp/p2p1n2/npp1p3/4P3/2P2N1P/PPBP1PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5"),
  new FullOpening("C96", "Ruy Lopez: Closed, Keres Defense", "r1bq1rk1/3nbppp/p2p4/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5 d2d4 f6d7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5 11. d4 Nd7"),
  new FullOpening("C96", "Ruy Lopez: Closed, Rossolimo Defense", "r1b2rk1/2q1bppp/p1pp1n2/np2p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 e8g8 c2c3 d7d6 h2h3 c6a5 b3c2 c7c6 d2d4 d8c7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 O-O 8. c3 d6 9. h3 Na5 10. Bc2 c6 11. d4 Qc7"),
  new FullOpening("C97", "Ruy Lopez: Closed, Chigorin Defense", "r1b2rk1/2q1bppp/p2p1n2/npp1p3/3PP3/2P2N1P/PPB2PP1/RNBQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5 d2d4 d8c7", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5 11. d4 Qc7"),
  new FullOpening("C97", "Ruy Lopez: Closed, Chigorin, Yugoslav System", "r3r1k1/2qbbp1p/p2p1np1/npp1p3/3PP3/2P1NN1P/PPB2PP1/R1BQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5 d2d4 d8c7 b1d2 c8d7 d2f1 f8e8 f1e3 g7g6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5 11. d4 Qc7 12. Nbd2 Bd7 13. Nf1 Rfe8 14. Ne3 g6"),
  new FullOpening("C98", "Ruy Lopez: Closed, Chigorin Defense", "r1b2rk1/2q1bppp/p1np1n2/1pp1p3/3PP3/2P2N1P/PPBN1PP1/R1BQR1K1 w - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5 d2d4 d8c7 b1d2 a5c6", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5 11. d4 Qc7 12. Nbd2 Nc6"),
  new FullOpening("C98", "Ruy Lopez: Closed, Chigorin Defense", "r1b2rk1/2q1bppp/p1np1n2/1pP1p3/4P3/2P2N1P/PPBN1PP1/R1BQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5 d2d4 d8c7 b1d2 a5c6 d4c5", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5 11. d4 Qc7 12. Nbd2 Nc6 13. dxc5"),
  new FullOpening("C99", "Ruy Lopez: Morphy Defense, Chigorin Defense, Panov System", "r1b2rk1/2q1bppp/p2p1n2/np2p3/3PP3/5N1P/PPBN1PP1/R1BQR1K1 b - -", "e2e4 e7e5 g1f3 b8c6 f1b5 a7a6 b5a4 g8f6 e1g1 f8e7 f1e1 b7b5 a4b3 d7d6 c2c3 e8g8 h2h3 c6a5 b3c2 c7c5 d2d4 d8c7 b1d2 c5d4 c3d4", "1. e4 e5 2. Nf3 Nc6 3. Bb5 a6 4. Ba4 Nf6 5. O-O Be7 6. Re1 b5 7. Bb3 d6 8. c3 O-O 9. h3 Na5 10. Bc2 c5 11. d4 Qc7 12. Nbd2 cxd4 13. cxd4"),
  new FullOpening("D00", "Amazon Attack", "rnbqkbnr/ppp1pppp/8/3p4/3P4/3Q4/PPP1PPPP/RNB1KBNR b KQkq -", "d2d4 d7d5 d1d3", "1. d4 d5 2. Qd3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit", "rnbqkbnr/ppp1pppp/8/3p4/3PP3/8/PPP2PPP/RNBQKBNR b KQkq -", "d2d4 d7d5 e2e4", "1. d4 d5 2. e4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit", "rnbqkbnr/ppp1pppp/8/8/3Pp3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3", "1. d4 d5 2. e4 dxe4 3. Nc3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit", "rnbqkb1r/ppp1pppp/5n2/3p4/3PP3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 b1c3 d7d5 e2e4", "1. d4 Nf6 2. Nc3 d5 3. e4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit Declined: Brombacher Countergambit", "rnbqkb1r/pp2pppp/5n2/2p5/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1c3 d7d5 e2e4 d5e4 f2f3 c7c5", "1. d4 Nf6 2. Nc3 d5 3. e4 dxe4 4. f3 c5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit Declined: Elbert Countergambit", "rnbqkb1r/ppp2ppp/5n2/4p3/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e7e5", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit Declined: Grosshans Defense", "rn1qkbnr/pppbpppp/8/8/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 c8d7", "1. d4 d5 2. e4 dxe4 3. Nc3 Bd7"),
  new FullOpening("D00", "Blackmar-Diemer Gambit Declined: Lamb Defense", "r1bqkb1r/ppp1pppp/2n2n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 b8c6", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 Nc6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit Declined: Langeheinecke Defense", "rnbqkb1r/ppp1pppp/5n2/8/3P4/2N1pP2/PPP3PP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1c3 d7d5 e2e4 d5e4 f2f3 e4e3", "1. d4 Nf6 2. Nc3 d5 3. e4 dxe4 4. f3 e3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit Declined: O'Kelly Defense", "rnbqkb1r/pp2pppp/2p2n2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 c7c6", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 c6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit Declined: Weinsbach Declination", "rnbqkb1r/ppp2ppp/4pn2/8/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e7e6", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 e6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Bogoljubov Defense, Mad Dog Attack", "rnbqk2r/ppp1ppbp/5np1/8/2BP3P/2N2N2/PPP3P1/R1BQK2R b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 g7g6 f1c4 f8g7 h2h4", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 g6 6. Bc4 Bg7 7. h4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Bogoljubov Variation", "rnbqkb1r/ppp1pp1p/5np1/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 g8f6 b1c3 d7d5 e2e4 d5e4 f2f3 e4f3 g1f3 g7g6", "1. d4 Nf6 2. Nc3 d5 3. e4 dxe4 4. f3 exf3 5. Nxf3 g6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Bogoljubov Variation, Kloss Attack", "rnbq1rk1/ppp1ppbp/5np1/8/2BP4/2N2N2/PPP3PP/R1BQ1R1K b - -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 g7g6 f1c4 f8g7 e1g1 e8g8 g1h1", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 g6 6. Bc4 Bg7 7. O-O O-O 8. Kh1"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Bogoljubov Variation, Nimzowitsch Attack", "rnbqk2r/ppp1ppbp/5np1/4N3/2BP4/2N5/PPP3PP/R1BQK2R b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 g7g6 f1c4 f8g7 f3e5", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 g6 6. Bc4 Bg7 7. Ne5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Bogoljubov Variation, Studier Attack", "rnbq1rk1/ppp1ppbp/5np1/8/2BP4/2N2N2/PPP3PP/R1B1QRK1 b - -", "d2d4 g8f6 b1c3 d7d5 e2e4 d5e4 f2f3 e4f3 g1f3 g7g6 f1c4 f8g7 e1g1 e8g8 d1e1", "1. d4 Nf6 2. Nc3 d5 3. e4 dxe4 4. f3 exf3 5. Nxf3 g6 6. Bc4 Bg7 7. O-O O-O 8. Qe1"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Diemer-Rosenberg Attack", "rnbqkbnr/ppp1pppp/8/8/3Pp3/4B3/PPP2PPP/RN1QKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 c1e3", "1. d4 d5 2. e4 dxe4 3. Be3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Euwe Defense", "rnbqkb1r/ppp2ppp/4pn2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 g8f6 b1c3 d7d5 e2e4 d5e4 f2f3 e4f3 g1f3 e7e6", "1. d4 Nf6 2. Nc3 d5 3. e4 dxe4 4. f3 exf3 5. Nxf3 e6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Euwe Defense, Zilbermints Gambit", "r1bqk2r/ppp1bppp/4pn2/6B1/3n4/2NB1N2/PPP3PP/R2Q1R1K b kq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 e7e6 c1g5 f8e7 f1d3 b8c6 e1g1 c6d4 g1h1", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 e6 6. Bg5 Be7 7. Bd3 Nc6 8. O-O Nxd4 9. Kh1"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Fritz Attack", "rnbqkbnr/ppp1pppp/8/8/2BPp3/8/PPP2PPP/RNBQK1NR b KQkq -", "d2d4 d7d5 e2e4 d5e4 f1c4", "1. d4 d5 2. e4 dxe4 3. Bc4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Gedult Gambit", "rnbqkbnr/ppp1pppp/8/8/3Pp3/5P2/PPP3PP/RNBQKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 f2f3", "1. d4 d5 2. e4 dxe4 3. f3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Kaulich Defense", "rnbqkb1r/pp2pppp/5n2/2p5/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 c7c5", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 c5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Lemberger Countergambit", "rnbqkb1r/ppp2ppp/5n2/3pp3/3PP3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "d2d4 d7d5 b1c3 g8f6 e2e4 e7e5", "1. d4 d5 2. Nc3 Nf6 3. e4 e5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Lemberger Countergambit, Diemer Attack", "rnbqkbnr/ppp2ppp/8/4p3/3Pp3/2N1B3/PPP2PPP/R2QKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 e7e5 c1e3", "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Be3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Lemberger Countergambit, Endgame Variation", "rnbqkbnr/ppp2ppp/8/4P3/4p3/2N5/PPP2PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 e7e5 d4e5", "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. dxe5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Lemberger Countergambit, Rassmussen Attack", "rnbqkbnr/ppp2ppp/8/4p3/3Pp3/2N5/PPP1NPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 e7e5 g1e2", "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Nge2"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Lemberger Countergambit, Simple Variation", "rnbqkbnr/ppp2ppp/8/4p3/3PN3/8/PPP2PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 e7e5 c3e4", "1. d4 d5 2. e4 dxe4 3. Nc3 e5 4. Nxe4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Lemberger Countergambit, Sneider Attack", "rnbqkbnr/ppp2ppp/8/4p2Q/3Pp3/2N5/PPP2PPP/R1B1KBNR b KQkq -", "e2e4 d7d5 d2d4 d5e4 b1c3 e7e5 d1h5", "1. e4 d5 2. d4 dxe4 3. Nc3 e5 4. Qh5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Netherlands Variation", "rnbqkbnr/ppp1p1pp/8/5p2/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "d2d4 f7f5 b1c3 d7d5 e2e4 d5e4", "1. d4 f5 2. Nc3 d5 3. e4 dxe4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Pietrowsky Defense", "r1bqkb1r/ppp1pppp/2n2n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 b8c6", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Nc6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Rasa-Studier Gambit", "rnbqkb1r/ppp1pppp/5n2/8/3Pp3/2N1B3/PPP2PPP/R2QKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 c1e3", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Be3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Ritter Defense", "rnbqkb1r/p1p1pppp/1p3n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 b7b6", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 b6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Rook Pawn Defense", "rnbqkb1r/ppp1ppp1/5n2/7p/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 h7h5", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 h5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Ryder Gambit", "rnbqkb1r/ppp1pppp/5n2/8/3P4/2N2Q2/PPP3PP/R1B1KBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 d1f3", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Qxf3"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Schlutter Defense", "r1bqkb1r/pppnpppp/5n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 b8d7", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Nbd7"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Tartakower Variation", "rn1qkb1r/ppp1pppp/5n2/5b2/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "e2e4 d7d5 d2d4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 c8f5", "1. e4 d5 2. d4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Bf5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Teichmann Variation", "rn1qkb1r/ppp1pppp/5n2/8/3P2b1/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "e2e4 d7d5 d2d4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 c8g4", "1. e4 d5 2. d4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 Bg4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Vienna Variation", "rn1qkb1r/ppp1pppp/5n2/5b2/3Pp3/2N2P2/PPP3PP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1c3 d7d5 e2e4 d5e4 f2f3 c8f5", "1. d4 Nf6 2. Nc3 d5 3. e4 dxe4 4. f3 Bf5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Zeller Defense", "rn1qkbnr/ppp1pppp/8/5b2/3Pp3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 c8f5", "1. d4 d5 2. e4 dxe4 3. Nc3 Bf5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Zeller Defense, Soller Attack", "rn1qkb1r/ppp1pppp/5n2/5b2/2BPp3/2N2P2/PPP3PP/R1BQK1NR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 c8f5 f2f3 g8f6 f1c4", "1. d4 d5 2. e4 dxe4 3. Nc3 Bf5 4. f3 Nf6 5. Bc4"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: Ziegler Defense", "rnbqkb1r/pp2pppp/2p2n2/8/3P4/2N2N2/PPP3PP/R1BQKB1R w KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 f2f3 e4f3 g1f3 c7c6", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. f3 exf3 5. Nxf3 c6"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: von Popiel Gambit", "rnbqkb1r/ppp1pppp/5n2/6B1/3Pp3/2N5/PPP2PPP/R2QKBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 c1g5", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Bg5"),
  new FullOpening("D00", "Blackmar-Diemer Gambit: von Popiel Gambit, Zilbermints Variation", "rn1qk2r/ppp2ppp/5pb1/1Q6/1b1Pp1P1/2N5/PPP2P1P/R3KBNR b KQkq -", "d2d4 d7d5 e2e4 d5e4 b1c3 g8f6 c1g5 c8f5 g5f6 e7f6 g2g4 f5g6 d1e2 f8b4 e2b5", "1. d4 d5 2. e4 dxe4 3. Nc3 Nf6 4. Bg5 Bf5 5. Bxf6 exf6 6. g4 Bg6 7. Qe2 Bb4 8. Qb5+"),
  new FullOpening("D00", "Queen's Pawn Game", "rnbqkbnr/ppp1pppp/8/3p4/3P4/8/PPP1PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5", "1. d4 d5"),
  new FullOpening("D00", "Queen's Pawn Game", "rnbqkbnr/ppp1pppp/8/3p4/3P4/4P3/PPP2PPP/RNBQKBNR b KQkq -", "d2d4 d7d5 e2e3", "1. d4 d5 2. e3"),
  new FullOpening("D00", "Queen's Pawn Game", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4P3/PPP2PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 e2e3 g8f6", "1. d4 d5 2. e3 Nf6"),
  new FullOpening("D00", "Queen's Pawn Game: Accelerated London System", "rnbqkbnr/ppp1pppp/8/3p4/3P1B2/8/PPP1PPPP/RN1QKBNR b KQkq -", "d2d4 d7d5 c1f4", "1. d4 d5 2. Bf4"),
  new FullOpening("D00", "Queen's Pawn Game: Chigorin Variation", "rnbqkbnr/ppp1pppp/8/3p4/3P4/2N5/PPP1PPPP/R1BQKBNR b KQkq -", "d2d4 d7d5 b1c3", "1. d4 d5 2. Nc3"),
  new FullOpening("D00", "Queen's Pawn Game: Chigorin Variation, Alburt Defense", "rn1qkbnr/ppp1pppp/8/3p1b2/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 b1c3 c8f5", "1. d4 d5 2. Nc3 Bf5"),
  new FullOpening("D00", "Queen's Pawn Game: Chigorin Variation, Anti-Veresov", "rn1qkbnr/ppp1pppp/8/3p4/3P2b1/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 b1c3 c8g4", "1. d4 d5 2. Nc3 Bg4"),
  new FullOpening("D00", "Queen's Pawn Game: Chigorin Variation, Fianchetto Defense", "rnbqk1nr/ppp1ppbp/6p1/3p4/3P4/2N2N2/PPP1PPPP/R1BQKB1R w KQkq -", "d2d4 g7g6 g1f3 f8g7 b1c3 d7d5", "1. d4 g6 2. Nf3 Bg7 3. Nc3 d5"),
  new FullOpening("D00", "Queen's Pawn Game: Chigorin Variation, Irish Gambit", "rnbqkbnr/pp2pppp/8/2pp4/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 b1c3 c7c5", "1. d4 d5 2. Nc3 c5"),
  new FullOpening("D00", "Queen's Pawn Game: Chigorin Variation, Shaviliuk Gambit", "rnbqkbnr/ppp2ppp/8/3pp3/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 b1c3 e7e5", "1. d4 d5 2. Nc3 e5"),
  new FullOpening("D00", "Queen's Pawn Game: Chigorin Variation, Shropshire Defense", "rnbqkbnr/ppp1ppp1/8/3p3p/3P4/2N5/PPP1PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 b1c3 h7h5", "1. d4 d5 2. Nc3 h5"),
  new FullOpening("D00", "Queen's Pawn Game: Hübsch Gambit", "rnbqkb1r/ppp1pppp/8/3p4/3Pn3/2N5/PPP2PPP/R1BQKBNR w KQkq -", "d2d4 g8f6 b1c3 d7d5 e2e4 f6e4", "1. d4 Nf6 2. Nc3 d5 3. e4 Nxe4"),
  new FullOpening("D00", "Queen's Pawn Game: Levitsky Attack", "rnbqkbnr/ppp1pppp/8/3p2B1/3P4/8/PPP1PPPP/RN1QKBNR b KQkq -", "d2d4 d7d5 c1g5", "1. d4 d5 2. Bg5"),
  new FullOpening("D00", "Queen's Pawn Game: Levitsky Attack, Welling Variation", "rn1qkbnr/ppp1pppp/8/3p2B1/3P2b1/8/PPP1PPPP/RN1QKBNR w KQkq -", "d2d4 d7d5 c1g5 c8g4", "1. d4 d5 2. Bg5 Bg4"),
  new FullOpening("D00", "Queen's Pawn Game: Mason Attack", "rnbqkbnr/ppp1pppp/8/3p4/3P1P2/8/PPP1P1PP/RNBQKBNR b KQkq -", "d2d4 d7d5 f2f4", "1. d4 d5 2. f4"),
  new FullOpening("D00", "Queen's Pawn Game: Morris Countergambit", "rnbqkbnr/pp2pppp/8/2pp4/3PPB2/8/PPP2PPP/RN1QKBNR b KQkq -", "d2d4 d7d5 c1f4 c7c5 e2e4", "1. d4 d5 2. Bf4 c5 3. e4"),
  new FullOpening("D00", "Queen's Pawn Game: Steinitz Countergambit", "rnbqkbnr/pp2pppp/8/2pp4/3P1B2/8/PPP1PPPP/RN1QKBNR w KQkq -", "d2d4 d7d5 c1f4 c7c5", "1. d4 d5 2. Bf4 c5"),
  new FullOpening("D00", "Queen's Pawn Game: Stonewall Attack", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/3BP3/PPP2PPP/RNBQK1NR b KQkq -", "d2d4 d7d5 e2e3 g8f6 f1d3", "1. d4 d5 2. e3 Nf6 3. Bd3"),
  new FullOpening("D00", "Queen's Pawn Game: Zurich Gambit", "rnbqkbnr/ppp1pppp/8/3p4/3P2P1/8/PPP1PP1P/RNBQKBNR b KQkq -", "d2d4 d7d5 g2g4", "1. d4 d5 2. g4"),
  new FullOpening("D01", "Queen's Pawn Game: Veresov Attack, Boyce Defense", "rnbqkb1r/ppp1pppp/8/3p2B1/3Pn3/2N5/PPP1PPPP/R2QKBNR w KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5 f6e4", "1. d4 Nf6 2. Nc3 d5 3. Bg5 Ne4"),
  new FullOpening("D01", "Queen's Pawn Game: Veresov Attack, Richter Variation", "rn1qkb1r/ppp1pppp/5n2/3p1bB1/3P4/2N2P2/PPP1P1PP/R2QKBNR b KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5 c8f5 f2f3", "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5 4. f3"),
  new FullOpening("D01", "Queen's Pawn Game: Veresov Attack, Two Knights System", "r1bqkb1r/pppnpppp/5n2/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5 b8d7 g1f3", "1. d4 Nf6 2. Nc3 d5 3. Bg5 Nbd7 4. Nf3"),
  new FullOpening("D01", "Queen's Pawn Game: Veresov Attack, Two Knights System, Grünfeld Defense", "r1bqkb1r/pppnpp1p/5np1/3p2B1/3P4/2N2N2/PPP1PPPP/R2QKB1R w KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5 b8d7 g1f3 g7g6", "1. d4 Nf6 2. Nc3 d5 3. Bg5 Nbd7 4. Nf3 g6"),
  new FullOpening("D01", "Queen's Pawn Game: Veresov Attack, Veresov Variation", "rn1qkb1r/ppp1pppp/5B2/3p1b2/3P4/2N5/PPP1PPPP/R2QKBNR b KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5 c8f5 g5f6", "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5 4. Bxf6"),
  new FullOpening("D01", "Rapport-Jobava System", "rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/2N5/PPP1PPPP/R2QKBNR b KQkq -", "d2d4 d7d5 b1c3 g8f6 c1f4", "1. d4 d5 2. Nc3 Nf6 3. Bf4"),
  new FullOpening("D01", "Richter-Veresov Attack", "rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/2N5/PPP1PPPP/R2QKBNR b KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5", "1. d4 Nf6 2. Nc3 d5 3. Bg5"),
  new FullOpening("D01", "Richter-Veresov Attack", "rn1qkb1r/ppp1pppp/5n2/3p1bB1/3P4/2N5/PPP1PPPP/R2QKBNR w KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5 c8f5", "1. d4 Nf6 2. Nc3 d5 3. Bg5 Bf5"),
  new FullOpening("D01", "Veresov Opening: Malich Gambit", "rnbqkb1r/pp2pp1p/5p2/2pP4/4p3/2N5/PPP2PPP/R2QKBNR b KQkq -", "d2d4 g8f6 b1c3 d7d5 c1g5 c7c5 g5f6 g7f6 e2e4 d5e4 d4d5", "1. d4 Nf6 2. Nc3 d5 3. Bg5 c5 4. Bxf6 gxf6 5. e4 dxe4 6. d5"),
  new FullOpening("D02", "London System: Poisoned Pawn Variation", "rnb1kb1r/pp2pppp/1q3n2/2pp4/3P1B2/2N1PN2/PPP2PPP/R2QKB1R b KQkq -", "d2d4 g8f6 g1f3 d7d5 c1f4 c7c5 e2e3 d8b6 b1c3", "1. d4 Nf6 2. Nf3 d5 3. Bf4 c5 4. e3 Qb6 5. Nc3"),
  new FullOpening("D02", "Queen's Gambit Declined: Baltic Defense, Pseudo-Slav", "rn1qkbnr/pp3ppp/2p1p3/3p1b2/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 g1f3 c8f5 c2c4 e7e6 b1c3 c7c6", "1. d4 d5 2. Nf3 Bf5 3. c4 e6 4. Nc3 c6"),
  new FullOpening("D02", "Queen's Pawn Game: Anti-Torre", "rn1qkbnr/ppp1pppp/8/3p4/3P2b1/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "g1f3 d7d5 d2d4 c8g4", "1. Nf3 d5 2. d4 Bg4"),
  new FullOpening("D02", "Queen's Pawn Game: Chandler Gambit", "rnbqkbnr/pp2pppp/8/3p4/3p4/5NP1/PPP1PPBP/RNBQK2R b KQkq -", "d2d4 d7d5 g1f3 c7c5 g2g3 c5d4 f1g2", "1. d4 d5 2. Nf3 c5 3. g3 cxd4 4. Bg2"),
  new FullOpening("D02", "Queen's Pawn Game: Chigorin Variation", "r1bqkbnr/ppp1pppp/2n5/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 g1f3 b8c6", "1. d4 d5 2. Nf3 Nc6"),
  new FullOpening("D02", "Queen's Pawn Game: Krause Variation", "rnbqkbnr/pp2pppp/8/2pp4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 g1f3 c7c5", "1. d4 d5 2. Nf3 c5"),
  new FullOpening("D02", "Queen's Pawn Game: Levitsky Attack, Euwe Variation, Modern Line", "rnb1kbnr/pp2ppp1/1qp4p/3p4/3P3B/5N2/PPP1PPPP/RN1QKB1R w KQkq -", "d2d4 d7d5 g1f3 c7c6 c1g5 h7h6 g5h4 d8b6", "1. d4 d5 2. Nf3 c6 3. Bg5 h6 4. Bh4 Qb6"),
  new FullOpening("D02", "Queen's Pawn Game: London System", "rnbqkb1r/ppp1pppp/5n2/3p4/3P1B2/5N2/PPP1PPPP/RN1QKB1R b KQkq -", "d2d4 d7d5 g1f3 g8f6 c1f4", "1. d4 d5 2. Nf3 Nf6 3. Bf4"),
  new FullOpening("D02", "Queen's Pawn Game: London System, Pterodactyl Variation", "rnb1k1nr/pp1pppbp/6p1/q7/3P1B2/5N2/PP2PPPP/RN1QKB1R w KQkq -", "d2d4 g7g6 g1f3 f8g7 c1f4 c7c5 c2c3 c5d4 c3d4 d8a5", "1. d4 g6 2. Nf3 Bg7 3. Bf4 c5 4. c3 cxd4 5. cxd4 Qa5+"),
  new FullOpening("D02", "Queen's Pawn Game: Symmetrical Variation", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 g1f3 g8f6", "1. d4 d5 2. Nf3 Nf6"),
  new FullOpening("D02", "Queen's Pawn Game: Symmetrical Variation, Pseudo-Catalan", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/5NP1/PPP1PP1P/RNBQKB1R b KQkq -", "d2d4 d7d5 g1f3 g8f6 g2g3", "1. d4 d5 2. Nf3 Nf6 3. g3"),
  new FullOpening("D02", "Queen's Pawn Game: Zilbermints Countergambit", "rnbqkb1r/p1p1pppp/5n2/1p1p4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 g1f3 g8f6 c2c4 b7b5", "1. d4 d5 2. Nf3 Nf6 3. c4 b5"),
  new FullOpening("D02", "Queen's Pawn Game: Zukertort Variation", "rnbqkbnr/ppp1pppp/8/3p4/3P4/5N2/PPP1PPPP/RNBQKB1R b KQkq -", "d2d4 d7d5 g1f3", "1. d4 d5 2. Nf3"),
  new FullOpening("D03", "Queen's Pawn Game: Torre Attack", "rnbqkb1r/ppp1pppp/5n2/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R b KQkq -", "d2d4 d7d5 g1f3 g8f6 c1g5", "1. d4 d5 2. Nf3 Nf6 3. Bg5"),
  new FullOpening("D03", "Queen's Pawn Game: Torre Attack, Gossip Variation", "rnbqkb1r/ppp1pppp/8/3p2B1/3Pn3/5N2/PPP1PPPP/RN1QKB1R w KQkq -", "d2d4 d7d5 g1f3 g8f6 c1g5 f6e4", "1. d4 d5 2. Nf3 Nf6 3. Bg5 Ne4"),
  new FullOpening("D03", "Queen's Pawn Game: Torre Attack, Grünfeld Variation", "rnbqkb1r/ppp1pp1p/5np1/3p2B1/3P4/5N2/PPP1PPPP/RN1QKB1R w KQkq -", "d2d4 d7d5 g1f3 g8f6 c1g5 g7g6", "1. d4 d5 2. Nf3 Nf6 3. Bg5 g6"),
  new FullOpening("D04", "Queen's Pawn Game: Colle System", "rnbqkb1r/ppp1pppp/5n2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R b KQkq -", "d2d4 d7d5 g1f3 g8f6 e2e3", "1. d4 d5 2. Nf3 Nf6 3. e3"),
  new FullOpening("D04", "Queen's Pawn Game: Colle System, Anti-Colle", "rn1qkb1r/ppp1pppp/5n2/3p1b2/3P4/4PN2/PPP2PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 g1f3 g8f6 e2e3 c8f5", "1. d4 d5 2. Nf3 Nf6 3. e3 Bf5"),
  new FullOpening("D04", "Queen's Pawn Game: Colle System, Grünfeld Formation", "rnbqk2r/ppp1ppbp/5np1/3p4/3P4/3BPN2/PPP2PPP/RNBQK2R w KQkq -", "d2d4 d7d5 g1f3 g8f6 e2e3 g7g6 f1d3 f8g7", "1. d4 d5 2. Nf3 Nf6 3. e3 g6 4. Bd3 Bg7"),
  new FullOpening("D05", "Queen's Pawn Game: Colle System", "rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/4PN2/PPP2PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 g1f3 g8f6 e2e3 e7e6", "1. d4 d5 2. Nf3 Nf6 3. e3 e6"),
  new FullOpening("D05", "Queen's Pawn Game: Colle System", "rnbqkb1r/ppp2ppp/4pn2/3p4/3P4/3BPN2/PPP2PPP/RNBQK2R b KQkq -", "d2d4 d7d5 g1f3 g8f6 e2e3 e7e6 f1d3", "1. d4 d5 2. Nf3 Nf6 3. e3 e6 4. Bd3"),
  new FullOpening("D05", "Queen's Pawn Game: Colle System, Traditional Colle", "rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/2PBPN2/PP3PPP/RNBQK2R b KQkq -", "d2d4 g8f6 g1f3 e7e6 e2e3 c7c5 f1d3 d7d5 c2c3", "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. c3"),
  new FullOpening("D05", "Queen's Pawn Game: Zukertort Variation", "rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/1P2PN2/P1PN1PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 g1f3 c7c5 e2e3 g8f6 b1d2 e7e6 b2b3", "1. d4 d5 2. Nf3 c5 3. e3 Nf6 4. Nbd2 e6 5. b3"),
  new FullOpening("D05", "Rubinstein Opening", "rnbqkb1r/pp3ppp/4pn2/2pp4/3P4/1P1BPN2/P1P2PPP/RNBQK2R b KQkq -", "d2d4 g8f6 g1f3 e7e6 e2e3 c7c5 f1d3 d7d5 b2b3", "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. b3"),
  new FullOpening("D05", "Rubinstein Opening: Bogoljubov Defense", "r1bq1rk1/pp3ppp/2nbpn2/2pp4/3P4/1P1BPN2/PBP2PPP/RN1Q1RK1 w - -", "d2d4 g8f6 g1f3 e7e6 e2e3 c7c5 f1d3 d7d5 b2b3 b8c6 e1g1 f8d6 c1b2 e8g8", "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. b3 Nc6 6. O-O Bd6 7. Bb2 O-O"),
  new FullOpening("D05", "Rubinstein Opening: Classical Defense", "r1bq1rk1/pp2bppp/2n1pn2/2pp4/3P4/1P1BPN2/PBP2PPP/RN1Q1RK1 w - -", "d2d4 g8f6 g1f3 e7e6 e2e3 c7c5 f1d3 d7d5 b2b3 b8c6 e1g1 f8e7 c1b2 e8g8", "1. d4 Nf6 2. Nf3 e6 3. e3 c5 4. Bd3 d5 5. b3 Nc6 6. O-O Be7 7. Bb2 O-O"),
  new FullOpening("D05", "Rubinstein Opening: Semi-Slav Defense", "r1bq1rk1/pppn1ppp/3bpn2/3p4/3P4/1P1BPN2/PBP2PPP/RN1Q1RK1 b - -", "d2d4 d7d5 g1f3 g8f6 e2e3 e7e6 f1d3 f8d6 e1g1 e8g8 b2b3 b8d7 c1b2", "1. d4 d5 2. Nf3 Nf6 3. e3 e6 4. Bd3 Bd6 5. O-O O-O 6. b3 Nbd7 7. Bb2"),
  new FullOpening("D06", "Queen's Gambit", "rnbqkbnr/ppp1pppp/8/3p4/2PP4/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 d7d5 c2c4", "1. d4 d5 2. c4"),
  new FullOpening("D06", "Queen's Gambit Declined: Austrian Attack, Salvio Countergambit", "rnbqkbnr/pp2pppp/8/2P5/2Pp4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 c7c5 d4c5 d5d4", "1. d4 d5 2. c4 c5 3. dxc5 d4"),
  new FullOpening("D06", "Queen's Gambit Declined: Austrian Defense", "rnbqkbnr/pp2pppp/8/2pp4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 c7c5", "1. d4 d5 2. c4 c5"),
  new FullOpening("D06", "Queen's Gambit Declined: Austrian Defense, Gusev Countergambit", "rnbqkb1r/pp2pppp/5n2/2pP4/3P4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 c7c5 c4d5 g8f6", "1. d4 d5 2. c4 c5 3. cxd5 Nf6"),
  new FullOpening("D06", "Queen's Gambit Declined: Austrian Defense, Haberditz Variation", "rnb1kb1r/pp2pppp/8/q1PP4/4n3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 c7c5 c4d5 g8f6 e2e4 f6e4 d4c5 d8a5", "1. d4 d5 2. c4 c5 3. cxd5 Nf6 4. e4 Nxe4 5. dxc5 Qa5+"),
  new FullOpening("D06", "Queen's Gambit Declined: Baltic Defense", "rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 c8f5", "1. d4 d5 2. c4 Bf5"),
  new FullOpening("D06", "Queen's Gambit Declined: Baltic Defense, Argentinian Gambit", "r2qkbnr/pp2pppp/2n5/8/Q2P4/8/PP2PPPP/RbB1KBNR w KQkq -", "d2d4 d7d5 c2c4 c8f5 c4d5 f5b1 d1a4 c7c6 d5c6 b8c6", "1. d4 d5 2. c4 Bf5 3. cxd5 Bxb1 4. Qa4+ c6 5. dxc6 Nxc6"),
  new FullOpening("D06", "Queen's Gambit Declined: Baltic Defense, Pseudo-Chigorin", "r2qkbnr/ppp2ppp/2n1p3/3p1b2/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c8f5 b1c3 e7e6 g1f3 b8c6", "1. d4 d5 2. c4 Bf5 3. Nc3 e6 4. Nf3 Nc6"),
  new FullOpening("D06", "Queen's Gambit Declined: Baltic Defense, Queen Attack", "rn1qkbnr/ppp1pppp/8/3p1b2/2PP4/1Q6/PP2PPPP/RNB1KBNR b KQkq -", "d2d4 d7d5 c2c4 c8f5 d1b3", "1. d4 d5 2. c4 Bf5 3. Qb3"),
  new FullOpening("D06", "Queen's Gambit Declined: Baltic Defense, Queen Attack Deferred", "rn1qkbnr/ppp2ppp/4p3/3p1b2/2PP4/1QN5/PP2PPPP/R1B1KBNR b KQkq -", "d2d4 d7d5 c2c4 c8f5 b1c3 e7e6 d1b3", "1. d4 d5 2. c4 Bf5 3. Nc3 e6 4. Qb3"),
  new FullOpening("D06", "Queen's Gambit Declined: Marshall Defense", "rnbqkb1r/ppp1pppp/5n2/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 g8f6", "1. d4 d5 2. c4 Nf6"),
  new FullOpening("D06", "Queen's Gambit Declined: Marshall Defense, Tan Gambit", "rnbqkb1r/pp2pppp/2p2n2/3P4/3P4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 g8f6 c4d5 c7c6", "1. d4 d5 2. c4 Nf6 3. cxd5 c6"),
  new FullOpening("D06", "Queen's Gambit Declined: Zilbermints Gambit", "rnbqkbnr/p1p1pppp/8/1p1p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 b7b5", "1. d4 d5 2. c4 b5"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense", "r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 b8c6", "1. d4 d5 2. c4 Nc6"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense", "r1bqkbnr/ppp1pppp/2n5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 b8c6 b1c3", "1. d4 d5 2. c4 Nc6 3. Nc3"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense", "r1bqkbnr/ppp1pppp/2n5/8/2pP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 b8c6 b1c3 d5c4", "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Exchange Variation", "r1b1kbnr/ppp1pppp/2n5/3q4/3P4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 b8c6 c4d5 d8d5", "1. d4 d5 2. c4 Nc6 3. cxd5 Qxd5"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Exchange Variation, Costa's Line", "r1b1k1nr/ppp2ppp/2n5/3q4/3p4/2B1P3/PP2NPPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 b8c6 c4d5 d8d5 e2e3 e7e5 b1c3 f8b4 c1d2 b4c3 d2c3 e5d4 g1e2", "1. d4 d5 2. c4 Nc6 3. cxd5 Qxd5 4. e3 e5 5. Nc3 Bb4 6. Bd2 Bxc3 7. Bxc3 exd4 8. Ne2"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Janowski Variation", "r1bqkbnr/ppp1pppp/2n5/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 b8c6 b1c3 d5c4 g1f3", "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. Nf3"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Lazard Gambit", "r1bqkbnr/ppp2ppp/2n5/3pp3/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 b8c6 g1f3 e7e5", "1. d4 d5 2. c4 Nc6 3. Nf3 e5"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Main Line", "r2qkbnr/ppp1pppp/2n5/3p4/2PP2b1/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 b8c6 g1f3 c8g4", "1. d4 d5 2. c4 Nc6 3. Nf3 Bg4"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Main Line, Alekhine Variation", "r2qkbnr/ppp1pppp/2n5/3p4/Q1PP2b1/5N2/PP2PPPP/RNB1KB1R b KQkq -", "d2d4 d7d5 c2c4 b8c6 g1f3 c8g4 d1a4", "1. d4 d5 2. c4 Nc6 3. Nf3 Bg4 4. Qa4"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Modern Gambit", "r1bqkb1r/ppp1pppp/2n2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 b8c6 b1c3 d5c4 g1f3 g8f6", "1. d4 d5 2. c4 Nc6 3. Nc3 dxc4 4. Nf3 Nf6"),
  new FullOpening("D07", "Queen's Gambit Declined: Chigorin Defense, Tartakower Gambit", "r1bqkbnr/ppp2ppp/2n5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 b8c6 b1c3 e7e5", "1. d4 d5 2. c4 Nc6 3. Nc3 e5"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit", "rnbqkbnr/ppp2ppp/8/3pp3/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e5", "1. d4 d5 2. c4 e5"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit, Balogh Variation", "r1b1kbnr/ppp1qppp/2n5/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 b1d2 d8e7", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 Qe7"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit, Janowski Variation", "r1bqkbnr/ppp3pp/2n2p2/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 b1d2 f7f6", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 f6"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit, Krenosz Variation", "r3k1nr/ppp1qppp/2n5/4P3/1bPp4/5N1P/PP1BPPP1/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 b1d2 c8g4 h2h3 g4f3 d2f3 f8b4 c1d2 d8e7", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2 Bg4 6. h3 Bxf3 7. Nxf3 Bb4+ 8. Bd2 Qe7"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit, Lasker Trap", "rnbqk1nr/ppp2ppp/8/4P3/1bP5/4p3/PP1B1PPP/RN1QKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 e2e3 f8b4 c1d2 d4e3", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. e3 Bb4+ 5. Bd2 dxe3"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit, Modern Line", "r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5N2/PP1NPPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 b1d2", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. Nbd2"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit, Normal Line", "rnbqkbnr/ppp2ppp/8/4P3/2Pp4/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3"),
  new FullOpening("D08", "Queen's Gambit Declined: Albin Countergambit, Tartakower Defense", "rnbqkbnr/pp3ppp/8/2p1P3/2Pp4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 c7c5", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 c5"),
  new FullOpening("D09", "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation", "r1bqkbnr/ppp2ppp/2n5/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 g2g3", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3"),
  new FullOpening("D09", "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Be6 Line", "r2qkbnr/ppp2ppp/2n1b3/4P3/2Pp4/5NP1/PP2PP1P/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 g2g3 c8e6", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Be6"),
  new FullOpening("D09", "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Bf5 Line", "r2qkbnr/ppp2ppp/2n5/4Pb2/2Pp4/5NP1/PP2PP1P/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 g2g3 c8f5", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Bf5"),
  new FullOpening("D09", "Queen's Gambit Declined: Albin Countergambit, Fianchetto Variation, Bg4 Line", "r2qkbnr/ppp2ppp/2n5/4P3/2Pp2b1/5NP1/PP2PP1P/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e5 d4e5 d5d4 g1f3 b8c6 g2g3 c8g4", "1. d4 d5 2. c4 e5 3. dxe5 d4 4. Nf3 Nc6 5. g3 Bg4"),
  new FullOpening("D10", "Slav Defense", "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 c7c6", "1. d4 d5 2. c4 c6"),
  new FullOpening("D10", "Slav Defense", "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3", "1. d4 d5 2. c4 c6 3. Nc3"),
  new FullOpening("D10", "Slav Defense", "rnbqkbnr/pp2pppp/2p5/8/2pP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 d5c4", "1. d4 d5 2. c4 c6 3. Nc3 dxc4"),
  new FullOpening("D10", "Slav Defense: Diemer Gambit", "rnbqkbnr/pp2pppp/2p5/3p4/2PPP3/8/PP3PPP/RNBQKBNR b KQkq -", "c2c4 c7c6 e2e4 d7d5 d2d4", "1. c4 c6 2. e4 d5 3. d4"),
  new FullOpening("D10", "Slav Defense: Exchange Variation", "rnbqkbnr/pp2pppp/2p5/3P4/3P4/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 d7d5 c2c4 c7c6 c4d5", "1. d4 d5 2. c4 c6 3. cxd5"),
  new FullOpening("D10", "Slav Defense: Slav Gambit, Alekhine Attack", "rnbqkbnr/pp2pppp/2p5/8/2pPP3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 d5c4 e2e4", "1. d4 d5 2. c4 c6 3. Nc3 dxc4 4. e4"),
  new FullOpening("D10", "Slav Defense: Winawer Countergambit", "rnbqkbnr/pp3ppp/2p5/3pp3/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 e7e5", "1. d4 d5 2. c4 c6 3. Nc3 e5"),
  new FullOpening("D10", "Slav Defense: Winawer Countergambit, Anti-Winawer Gambit", "rnbqkbnr/pp3ppp/2p5/3pp3/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 e7e5 e2e4", "1. d4 d5 2. c4 c6 3. Nc3 e5 4. e4"),
  new FullOpening("D11", "Slav Defense: Bonet Gambit", "rnbqkb1r/pp2pppp/2p2n2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 c1g5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Bg5"),
  new FullOpening("D11", "Slav Defense: Breyer Variation", "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/5N2/PP1NPPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1d2", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nbd2"),
  new FullOpening("D11", "Slav Defense: Modern Line", "rnbqkbnr/pp2pppp/2p5/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3", "1. d4 d5 2. c4 c6 3. Nf3"),
  new FullOpening("D11", "Slav Defense: Quiet Variation", "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/4PN2/PP3PPP/RNBQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3"),
  new FullOpening("D11", "Slav Defense: Quiet Variation, Pin Defense", "rn1qkb1r/pp2pppp/2p2n2/3p4/2PP2b1/4PN2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 c8g4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bg4"),
  new FullOpening("D12", "Slav Defense: Quiet Variation, Amsterdam Variation", "rn1qkb1r/pp1n1ppp/4p3/3pNb2/3P4/2N1P3/PP3PPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 c8f5 c4d5 c6d5 b1c3 e7e6 f3e5 f6d7", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bf5 5. cxd5 cxd5 6. Nc3 e6 7. Ne5 Nfd7"),
  new FullOpening("D12", "Slav Defense: Quiet Variation, Landau Variation", "rnq1kb1r/pp3ppp/4pn2/3p1b2/3P4/NQ2PN2/PP1B1PPP/R3KB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 c8f5 c4d5 c6d5 d1b3 d8c8 c1d2 e7e6 b1a3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bf5 5. cxd5 cxd5 6. Qb3 Qc8 7. Bd2 e6 8. Na3"),
  new FullOpening("D12", "Slav Defense: Quiet Variation, Schallopp Defense", "rn1qkb1r/pp2pppp/2p2n2/3p1b2/2PP4/4PN2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 c8f5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 Bf5"),
  new FullOpening("D13", "Slav Defense: Exchange Variation", "rnbqkb1r/pp2pppp/5n2/3p4/3P4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 c4d5 c6d5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. cxd5 cxd5"),
  new FullOpening("D13", "Slav Defense: Exchange Variation, Schallopp Variation", "rn1qkb1r/pp2pppp/5n2/3p1b2/3P4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 g1f3 g8f6 e2e3 c8f5 c2c4 c7c6 c4d5 c6d5 b1c3", "1. d4 d5 2. Nf3 Nf6 3. e3 Bf5 4. c4 c6 5. cxd5 cxd5 6. Nc3"),
  new FullOpening("D14", "Slav Defense: Exchange Variation, Symmetrical Line", "r2qkb1r/pp2pppp/2n2n2/3p1b2/3P1B2/2N2N2/PP2PPPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 c4d5 c6d5 b1c3 g8f6 g1f3 b8c6 c1f4 c8f5", "1. d4 d5 2. c4 c6 3. cxd5 cxd5 4. Nc3 Nf6 5. Nf3 Nc6 6. Bf4 Bf5"),
  new FullOpening("D14", "Slav Defense: Exchange Variation, Trifunovic Variation", "r2qk2r/pp3ppp/2n1pn2/3p1b2/1b1P1B2/1QN1PN2/PP3PPP/R3KB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 c4d5 c6d5 b1c3 b8c6 c1f4 c8f5 e2e3 e7e6 d1b3 f8b4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. cxd5 cxd5 5. Nc3 Nc6 6. Bf4 Bf5 7. e3 e6 8. Qb3 Bb4"),
  new FullOpening("D15", "Slav Defense: Alekhine Variation", "rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 e2e3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e3"),
  new FullOpening("D15", "Slav Defense: Chameleon Variation", "rnbqkb1r/1p2pppp/p1p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 a7a6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 a6"),
  new FullOpening("D15", "Slav Defense: Chameleon Variation, Advance System", "rnbqkb1r/1p2pppp/p1p2n2/2Pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 a7a6 c4c5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 a6 5. c5"),
  new FullOpening("D15", "Slav Defense: Geller Gambit", "rnbqkb1r/pp2pppp/2p2n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 e2e4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e4"),
  new FullOpening("D15", "Slav Defense: Geller Gambit", "rnbqkb1r/p3pppp/2p2n2/1p2P3/2pP4/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 e2e4 b7b5 e4e5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. e4 b5 6. e5"),
  new FullOpening("D15", "Slav Defense: Schlechter Variation", "rnbqkb1r/pp2pp1p/2p2np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 g7g6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 g6"),
  new FullOpening("D15", "Slav Defense: Süchting Variation", "rnb1kb1r/pp2pppp/1qp2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d8b6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 Qb6"),
  new FullOpening("D15", "Slav Defense: Three Knights Variation", "rnbqkb1r/pp2pppp/2p2n2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3"),
  new FullOpening("D15", "Slav Defense: Two Knights Attack", "rnbqkb1r/pp2pppp/2p2n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4"),
  new FullOpening("D16", "Slav Defense: Alapin Variation", "rnbqkb1r/pp2pppp/2p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4"),
  new FullOpening("D16", "Slav Defense: Smyslov Variation", "r1bqkb1r/pp2pppp/n1p2n2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 b8a6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Na6"),
  new FullOpening("D16", "Slav Defense: Soultanbeieff Variation", "rnbqkb1r/pp3ppp/2p1pn2/8/P1pP4/2N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 e7e6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 e6"),
  new FullOpening("D16", "Slav Defense: Steiner Variation", "rn1qkb1r/pp2pppp/2p2n2/8/P1pP2b1/2N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8g4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bg4"),
  new FullOpening("D17", "Slav Defense: Czech Variation", "rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5"),
  new FullOpening("D17", "Slav Defense: Czech Variation, Bled Attack", "rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP3N/2N5/1P2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 f3h4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Nh4"),
  new FullOpening("D17", "Slav Defense: Czech Variation, Carlsbad Variation", "r3kb1r/ppqn1ppp/2p2n2/4pb2/P1NP4/2N3P1/1P2PP1P/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 f3e5 b8d7 e5c4 d8c7 g2g3 e7e5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5 Nbd7 7. Nxc4 Qc7 8. g3 e5"),
  new FullOpening("D17", "Slav Defense: Czech Variation, Carlsbad Variation, Morozevich Variation", "r3kb1r/ppqn1p1p/2p5/4nbp1/P1N2B2/2N3P1/1P2PPBP/R2QK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 f3e5 b8d7 e5c4 d8c7 g2g3 e7e5 d4e5 d7e5 c1f4 f6d7 f1g2 g7g5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5 Nbd7 7. Nxc4 Qc7 8. g3 e5 9. dxe5 Nxe5 10. Bf4 Nfd7 11. Bg2 g5"),
  new FullOpening("D17", "Slav Defense: Czech Variation, Krause Attack", "rn1qkb1r/pp2pppp/2p2n2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 f3e5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5"),
  new FullOpening("D17", "Slav Defense: Czech Variation, Krause Attack, Fazekas Gambit", "r2qkb1r/pp2pppp/n1p2n2/4Nb2/P1pPP3/2N5/1P3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 f3e5 b8a6 e2e4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5 Na6 7. e4"),
  new FullOpening("D17", "Slav Defense: Czech Variation, Wiesbaden Variation", "rn1qkb1r/pp3ppp/2p1pn2/4Nb2/P1pP4/2N5/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 f3e5 e7e6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5 e6"),
  new FullOpening("D17", "Slav Defense: Czech Variation, Wiesbaden Variation, Sharp Line", "rn1qk2r/pp3ppp/2p1pn2/4Nb2/PbpPP3/2N2P2/1P4PP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 f3e5 e7e6 f2f3 f8b4 e2e4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. Ne5 e6 7. f3 Bb4 8. e4"),
  new FullOpening("D18", "Slav Defense: Czech Variation, Classical System", "rn1qkb1r/pp2pppp/2p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 e2e3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3"),
  new FullOpening("D18", "Slav Defense: Czech Variation, Lasker Variation", "r2qkb1r/pp2pppp/n1p2n2/5b2/P1pP4/2N1PN2/1P3PPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 e2e3 b8a6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3 Na6"),
  new FullOpening("D19", "Slav Defense: Czech Variation, Classical System, Main Line", "rn1q1rk1/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P2QPPP/R1B2RK1 b - -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 e2e3 e7e6 f1c4 f8b4 e1g1 e8g8 d1e2", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3 e6 7. Bxc4 Bb4 8. O-O O-O 9. Qe2"),
  new FullOpening("D19", "Slav Defense: Czech Variation, Dutch Variation", "rn1qk2r/pp3ppp/2p1pn2/5b2/PbBP4/2N1PN2/1P3PPP/R1BQ1RK1 b kq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 e2e3 e7e6 f1c4 f8b4 e1g1", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3 e6 7. Bxc4 Bb4 8. O-O"),
  new FullOpening("D19", "Slav Defense: Czech Variation, Dutch Variation, Sämisch Variation", "rn1q1rk1/pp3ppp/2p1p3/5b2/PbBPn1P1/2N1PN2/1P2QP1P/R1B2RK1 b - -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 d5c4 a2a4 c8f5 e2e3 e7e6 f1c4 f8b4 e1g1 e8g8 d1e2 f6e4 g2g4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 dxc4 5. a4 Bf5 6. e3 e6 7. Bxc4 Bb4 8. O-O O-O 9. Qe2 Ne4 10. g4"),
  new FullOpening("D20", "Queen's Gambit Accepted", "rnbqkbnr/ppp1pppp/8/8/2pP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4", "1. d4 d5 2. c4 dxc4"),
  new FullOpening("D20", "Queen's Gambit Accepted: Accelerated Mannheim Variation", "rnbqkbnr/ppp1pppp/8/8/Q1pP4/8/PP2PPPP/RNB1KBNR b KQkq -", "d2d4 d7d5 c2c4 d5c4 d1a4", "1. d4 d5 2. c4 dxc4 3. Qa4+"),
  new FullOpening("D20", "Queen's Gambit Accepted: Central Variation, Alekhine System", "rnbqkb1r/ppp1pppp/5n2/8/2pPP3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 g8f6", "1. d4 d5 2. c4 dxc4 3. e4 Nf6"),
  new FullOpening("D20", "Queen's Gambit Accepted: Central Variation, Greco Variation", "rnbqkbnr/p1p1pppp/8/1p6/2pPP3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 b7b5", "1. d4 d5 2. c4 dxc4 3. e4 b5"),
  new FullOpening("D20", "Queen's Gambit Accepted: Central Variation, McDonnell Defense", "rnbqkbnr/ppp2ppp/8/4p3/2pPP3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 e7e5", "1. d4 d5 2. c4 dxc4 3. e4 e5"),
  new FullOpening("D20", "Queen's Gambit Accepted: Central Variation, McDonnell Defense, Somov Gambit", "rnbqkbnr/ppp2ppp/8/4p3/2BPP3/8/PP3PPP/RNBQK1NR b KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 e7e5 f1c4", "1. d4 d5 2. c4 dxc4 3. e4 e5 4. Bxc4"),
  new FullOpening("D20", "Queen's Gambit Accepted: Central Variation, Modern Defense", "r1bqkbnr/ppp1pppp/2n5/8/2pPP3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 b8c6", "1. d4 d5 2. c4 dxc4 3. e4 Nc6"),
  new FullOpening("D20", "Queen's Gambit Accepted: Central Variation, Rubinstein Defense", "rnbqkbnr/pp2pppp/8/2p5/2pPP3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 c7c5", "1. d4 d5 2. c4 dxc4 3. e4 c5"),
  new FullOpening("D20", "Queen's Gambit Accepted: Central Variation, Rubinstein Defense, Yefimov Gambit", "rnbqkbnr/p3pppp/8/1ppP4/2p1P3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 c7c5 d4d5 b7b5", "1. d4 d5 2. c4 dxc4 3. e4 c5 4. d5 b5"),
  new FullOpening("D20", "Queen's Gambit Accepted: Linares Variation", "rnbqkb1r/p3pppp/5n2/1ppP4/2p1P3/2N5/PP3PPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 c7c5 d4d5 g8f6 b1c3 b7b5", "1. d4 d5 2. c4 dxc4 3. e4 c5 4. d5 Nf6 5. Nc3 b5"),
  new FullOpening("D20", "Queen's Gambit Accepted: Old Variation", "rnbqkbnr/ppp1pppp/8/8/2pP4/4P3/PP3PPP/RNBQKBNR b KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e3", "1. d4 d5 2. c4 dxc4 3. e3"),
  new FullOpening("D20", "Queen's Gambit Accepted: Old Variation, Billinger Gambit", "rnb1kbnr/ppp1qppp/8/8/2Bp4/PQ2P3/1P3PPP/RNB1K1NR b KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e3 e7e5 f1c4 e5d4 d1b3 d8e7 a2a3", "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. a3"),
  new FullOpening("D20", "Queen's Gambit Accepted: Old Variation, Christensen Gambit", "rnb1kbnr/ppp1qppp/8/8/2Bp4/1Q2PN2/PP3PPP/RNB1K2R b KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e3 e7e5 f1c4 e5d4 d1b3 d8e7 g1f3", "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Nf3"),
  new FullOpening("D20", "Queen's Gambit Accepted: Old Variation, Korchnoi Gambit", "rnb1kbnr/ppp1qppp/8/8/2Bp4/1Q2P3/PP3PPP/RNB2KNR b kq -", "d2d4 d7d5 c2c4 d5c4 e2e3 e7e5 f1c4 e5d4 d1b3 d8e7 e1f1", "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Kf1"),
  new FullOpening("D20", "Queen's Gambit Accepted: Old Variation, Novikov Gambit", "rnb1kbnr/ppp1qppp/8/8/2Bp4/1Q2P3/PP1N1PPP/R1B1K1NR b KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e3 e7e5 f1c4 e5d4 d1b3 d8e7 b1d2", "1. d4 d5 2. c4 dxc4 3. e3 e5 4. Bxc4 exd4 5. Qb3 Qe7 6. Nd2"),
  new FullOpening("D20", "Queen's Gambit Accepted: Saduleto Variation", "rnbqkbnr/ppp1pppp/8/8/2pPP3/8/PP3PPP/RNBQKBNR b KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4", "1. d4 d5 2. c4 dxc4 3. e4"),
  new FullOpening("D20", "Queen's Gambit Accepted: Schwartz Defense", "rnbqkbnr/ppp1p1pp/8/5p2/2pPP3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 d5c4 e2e4 f7f5", "1. d4 d5 2. c4 dxc4 3. e4 f5"),
  new FullOpening("D21", "Queen's Gambit Accepted: Alekhine Defense, Borisenko-Furman Variation", "rnbqkbnr/1pp1pppp/p7/8/2pPP3/5N2/PP3PPP/RNBQKB1R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 a7a6 e2e4", "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e4"),
  new FullOpening("D21", "Queen's Gambit Accepted: Godes Variation", "r1bqkbnr/pppnpppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 b8d7", "1. d4 d5 2. c4 dxc4 3. Nf3 Nd7"),
  new FullOpening("D21", "Queen's Gambit Accepted: Gunsberg Defense", "rnbqkbnr/pp2pppp/8/2p5/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 c7c5", "1. d4 d5 2. c4 dxc4 3. Nf3 c5"),
  new FullOpening("D21", "Queen's Gambit Accepted: Normal Variation", "rnbqkbnr/ppp1pppp/8/8/2pP4/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3", "1. d4 d5 2. c4 dxc4 3. Nf3"),
  new FullOpening("D21", "Queen's Gambit Accepted: Rosenthal Variation", "rnbqkbnr/ppp2ppp/4p3/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 e7e6", "1. d4 d5 2. c4 dxc4 3. Nf3 e6"),
  new FullOpening("D21", "Queen's Gambit Accepted: Slav Gambit", "rnbqkbnr/p1p1pppp/8/1p6/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 b7b5", "1. d4 d5 2. c4 dxc4 3. Nf3 b5"),
  new FullOpening("D22", "Queen's Gambit Accepted: Alekhine Defense", "rnbqkbnr/1pp1pppp/p7/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 a7a6", "1. d4 d5 2. c4 dxc4 3. Nf3 a6"),
  new FullOpening("D22", "Queen's Gambit Accepted: Alekhine Defense, Alatortsev Variation", "rn1qkbnr/1pp2ppp/p3p3/3P4/2B3b1/4PN2/PP3PPP/RNBQK2R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 a7a6 e2e3 c8g4 f1c4 e7e6 d4d5", "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e3 Bg4 5. Bxc4 e6 6. d5"),
  new FullOpening("D22", "Queen's Gambit Accepted: Alekhine Defense, Haberditz Variation", "rnbqkbnr/2p1pppp/p7/1p6/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 a7a6 e2e3 b7b5", "1. d4 d5 2. c4 dxc4 3. Nf3 a6 4. e3 b5"),
  new FullOpening("D23", "Queen's Gambit Accepted", "rnbqkb1r/ppp1pppp/5n2/8/2pP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6"),
  new FullOpening("D23", "Queen's Gambit Accepted: Mannheim Variation", "rnbqkb1r/ppp1pppp/5n2/8/Q1pP4/5N2/PP2PPPP/RNB1KB1R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 d1a4", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Qa4+"),
  new FullOpening("D24", "Queen's Gambit Accepted: Bogoljubov Defense", "rnbqkb1r/1pp1pppp/p4n2/8/2pPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 b1c3 a7a6 e2e4", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3 a6 5. e4"),
  new FullOpening("D24", "Queen's Gambit Accepted: Gunsberg Defense, Prianishenmo Gambit", "rnbqkb1r/pp3ppp/5n2/2ppP3/2p5/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 b1c3 c7c5 d4d5 e7e6 e2e4 e6d5 e4e5", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3 c5 5. d5 e6 6. e4 exd5 7. e5"),
  new FullOpening("D24", "Queen's Gambit Accepted: Showalter Variation", "rnbqkb1r/ppp1pppp/5n2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 b1c3", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. Nc3"),
  new FullOpening("D25", "Queen's Gambit Accepted: Janowski-Larsen Variation", "rn1qkb1r/ppp1pppp/5n2/8/2pP2b1/4PN2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 c8g4", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 Bg4"),
  new FullOpening("D25", "Queen's Gambit Accepted: Normal Variation", "rnbqkb1r/ppp1pppp/5n2/8/2pP4/4PN2/PP3PPP/RNBQKB1R b KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3"),
  new FullOpening("D25", "Queen's Gambit Accepted: Smyslov Variation", "rnbqkb1r/ppp1pp1p/5np1/8/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 g7g6", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 g6"),
  new FullOpening("D25", "Queen's Gambit Accepted: Winawer Defense", "rn1qkb1r/ppp1pppp/4bn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 c8e6", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 Be6"),
  new FullOpening("D26", "Queen's Gambit Accepted: Classical Defense", "rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQK2R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5"),
  new FullOpening("D26", "Queen's Gambit Accepted: Classical Defense, Normal Line", "rnbqkb1r/pp3ppp/4pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1 b kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O"),
  new FullOpening("D26", "Queen's Gambit Accepted: Classical Defense, Steinitz Variation, Development Variation", "r1bqkb1r/pp3ppp/2n1pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 b8c6", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O Nc6"),
  new FullOpening("D26", "Queen's Gambit Accepted: Classical Defense, Steinitz Variation, Exchange Variation", "rnbqkb1r/pp3ppp/4pn2/8/2Bp4/4PN2/PP3PPP/RNBQ1RK1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 c5d4", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O cxd4"),
  new FullOpening("D26", "Queen's Gambit Accepted: Classical, Furman Variation", "r1bqk2r/5ppp/p1n1pn2/1pb1P3/2B5/5N2/PP2QPPP/RNB2RK1 b kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 d1e2 a7a6 d4c5 f8c5 e1g1 b8c6 e3e4 b7b5 e4e5", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. Qe2 a6 7. dxc5 Bxc5 8. O-O Nc6 9. e4 b5 10. e5"),
  new FullOpening("D26", "Queen's Gambit Accepted: Normal Variation, Traditional System", "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/4PN2/PP3PPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6"),
  new FullOpening("D27", "Queen's Gambit Accepted: Classical Defense, Main Line", "rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP3PPP/RNBQ1RK1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6"),
  new FullOpening("D27", "Queen's Gambit Accepted: Classical Defense, Rubinstein Variation", "rnbqkb1r/1p3ppp/p3pn2/2p5/P1BP4/4PN2/1P3PPP/RNBQ1RK1 b kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6 a2a4", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6 7. a4"),
  new FullOpening("D27", "Queen's Gambit Accepted: Classical Defense, Russian Gambit", "rnbqkb1r/1p3ppp/p3pn2/2p5/2BPP3/5N2/PP3PPP/RNBQ1RK1 b kq -", "d2d4 d7d5 c2c4 d5c4 e2e3 g8f6 f1c4 e7e6 g1f3 c7c5 e1g1 a7a6 e3e4", "1. d4 d5 2. c4 dxc4 3. e3 Nf6 4. Bxc4 e6 5. Nf3 c5 6. O-O a6 7. e4"),
  new FullOpening("D27", "Queen's Gambit Accepted: Furman Variation", "rnbqk2r/1p3ppp/p3pn2/2b5/2B5/4PN2/PP3PPP/RNBQ1RK1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6 d4c5 f8c5", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6 7. dxc5 Bxc5"),
  new FullOpening("D28", "Queen's Gambit Accepted: Classical Defense, Alekhine System", "rnbqkb1r/1p3ppp/p3pn2/2p5/2BP4/4PN2/PP2QPPP/RNB2RK1 b kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6 d1e2", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6 7. Qe2"),
  new FullOpening("D28", "Queen's Gambit Accepted: Classical Defense, Alekhine System, No Main Line", "rnbqkb1r/5ppp/p3pn2/1pp5/2BP4/4PN2/PP2QPPP/RNB2RK1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6 d1e2 b7b5", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6 7. Qe2 b5"),
  new FullOpening("D28", "Queen's Gambit Accepted: Classical, Flohr Variation", "r3kb1r/1bq2ppp/p3pn2/1p1P4/2p5/2N1PN2/PPQ2PPP/R1BR2K1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6 d1e2 b7b5 c4b3 b8c6 f1d1 c5c4 b3c2 c6b4 b1c3 b4c2 e2c2 c8b7 d4d5 d8c7", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6 7. Qe2 b5 8. Bb3 Nc6 9. Rd1 c4 10. Bc2 Nb4 11. Nc3 Nxc2 12. Qxc2 Bb7 13. d5 Qc7"),
  new FullOpening("D29", "Queen's Gambit Accepted: Classical Defense, Alekhine System, Main Line", "rn1qkb1r/1b3ppp/p3pn2/1pp5/3P4/1B2PN2/PP2QPPP/RNB2RK1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6 d1e2 b7b5 c4b3 c8b7", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6 7. Qe2 b5 8. Bb3 Bb7"),
  new FullOpening("D29", "Queen's Gambit Accepted: Classical Defense, Alekhine System, Smyslov Variation", "r2qk2r/1b1n1ppp/p2bpn2/1pp5/3P4/1BN1PN2/PP2QPPP/R1BR2K1 w kq -", "d2d4 d7d5 c2c4 d5c4 g1f3 g8f6 e2e3 e7e6 f1c4 c7c5 e1g1 a7a6 d1e2 b7b5 c4b3 c8b7 f1d1 b8d7 b1c3 f8d6", "1. d4 d5 2. c4 dxc4 3. Nf3 Nf6 4. e3 e6 5. Bxc4 c5 6. O-O a6 7. Qe2 b5 8. Bb3 Bb7 9. Rd1 Nbd7 10. Nc3 Bd6"),
  new FullOpening("D30", "French Defense: Diemer-Duhm Gambit", "rnbqkbnr/ppp2ppp/4p3/8/2PPp3/8/PP3PPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 e2e4 d5e4", "1. d4 d5 2. c4 e6 3. e4 dxe4"),
  new FullOpening("D30", "Queen's Gambit Declined", "rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6", "1. d4 d5 2. c4 e6"),
  new FullOpening("D30", "Queen's Gambit Declined: Capablanca Variation", "rnbqkb1r/ppp2pp1/4pn1p/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 c1g5 h7h6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5 h6"),
  new FullOpening("D30", "Queen's Gambit Declined: Capablanca Variation", "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/4PN2/PP1N1PPP/R2QKB1R b KQkq -", "d2d4 g8f6 g1f3 e7e6 c2c4 d7d5 c1g5 c7c6 b1d2 b8d7 e2e3", "1. d4 Nf6 2. Nf3 e6 3. c4 d5 4. Bg5 c6 5. Nbd2 Nbd7 6. e3"),
  new FullOpening("D30", "Queen's Gambit Declined: Semmering Variation", "r1bqkb1r/pp1n1ppp/4pn2/2pp4/2PP4/3BPN2/PP1N1PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 e7e6 b1d2 b8d7 f1d3 c6c5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Nbd7 6. Bd3 c5"),
  new FullOpening("D30", "Queen's Gambit Declined: Spielmann Variation", "rnbqkb1r/pp3p1p/2p1pnp1/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 g1f3 g8f6 e2e3 c7c6 b1d2 g7g6", "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. e3 c6 5. Nbd2 g6"),
  new FullOpening("D30", "Queen's Gambit Declined: Stonewall Variation", "rnbqkb1r/pp4pp/2p1p3/3p1p2/2PPn3/3BPN2/PP1N1PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 e7e6 b1d2 f6e4 f1d3 f7f5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Ne4 6. Bd3 f5"),
  new FullOpening("D30", "Queen's Gambit Declined: Tarrasch Defense, Pseudo-Tarrasch", "rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 g1f3 c7c5", "1. d4 d5 2. c4 e6 3. Nf3 c5"),
  new FullOpening("D30", "Queen's Gambit Declined: Tarrasch Defense, Pseudo-Tarrasch Bishop Attack", "rnbqkbnr/pp3ppp/8/2pp2B1/3P4/5N2/PP2PPPP/RN1QKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 g1f3 c7c5 c4d5 e6d5 c1g5", "1. d4 d5 2. c4 e6 3. Nf3 c5 4. cxd5 exd5 5. Bg5"),
  new FullOpening("D30", "Queen's Gambit Declined: Traditional Variation", "rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/5N2/PP2PPPP/RN1QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 c1g5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5"),
  new FullOpening("D30", "Queen's Gambit Declined: Vienna Variation", "rnbqk2r/ppp2ppp/4pn2/3p2B1/1bPP4/5N2/PP2PPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 c1g5 f8b4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Bg5 Bb4+"),
  new FullOpening("D30", "Semi-Slav Defense: Quiet Variation", "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 e7e6 b1d2", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2"),
  new FullOpening("D30", "Semi-Slav Defense: Quiet Variation", "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/4PN2/PP1N1PPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 e2e3 e7e6 b1d2 b8d7", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. e3 e6 5. Nbd2 Nbd7"),
  new FullOpening("D31", "Queen's Gambit Declined: Alapin Variation", "rnbqkbnr/p1p2ppp/1p2p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 e7e6 c2c4 b7b6 b1c3 d7d5", "1. d4 e6 2. c4 b6 3. Nc3 d5"),
  new FullOpening("D31", "Queen's Gambit Declined: Charousek Variation", "rnbqk1nr/ppp1bppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7", "1. d4 d5 2. c4 e6 3. Nc3 Be7"),
  new FullOpening("D31", "Queen's Gambit Declined: Charousek Variation, Miladinovic Gambit", "rnbqk1nr/ppp1bppp/4p3/8/2PPp3/2N2P2/PP4PP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 e2e4 d5e4 f2f3", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. e4 dxe4 5. f3"),
  new FullOpening("D31", "Queen's Gambit Declined: Janowski Variation", "rnbqkbnr/1pp2ppp/p3p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 a7a6", "1. d4 d5 2. c4 e6 3. Nc3 a6"),
  new FullOpening("D31", "Queen's Gambit Declined: Queen's Knight Variation", "rnbqkbnr/ppp2ppp/4p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3", "1. d4 d5 2. c4 e6 3. Nc3"),
  new FullOpening("D31", "Queen's Gambit Declined: Semi-Slav, Abrahams Variation", "rnbqk1nr/5ppp/2p1p3/pp6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 d5c4 a2a4 f8b4 e2e3 b7b5 c1d2 a7a5", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. a4 Bb4 6. e3 b5 7. Bd2 a5"),
  new FullOpening("D31", "Queen's Gambit Declined: Semi-Slav, Junge Variation", "rnb1k1nr/p4ppp/1qp1p3/1p6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 g1f3 c7c6 b1c3 d5c4 a2a4 f8b4 e2e3 b7b5 c1d2 d8b6", "1. d4 d5 2. c4 e6 3. Nf3 c6 4. Nc3 dxc4 5. a4 Bb4 6. e3 b5 7. Bd2 Qb6"),
  new FullOpening("D31", "Queen's Gambit Declined: Semi-Slav, Koomen Variation", "rnb1k1nr/p3qppp/2p1p3/1p6/PbpP4/2N1PN2/1P1B1PPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 d5c4 e2e3 b7b5 a2a4 f8b4 c1d2 d8e7", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. e3 b5 6. a4 Bb4 7. Bd2 Qe7"),
  new FullOpening("D31", "Queen's Gambit Declined: Uhlmann Variation", "rnbq1rk1/ppp1bpp1/4pn1p/8/2pP3B/2N2N2/PP2PPPP/2RQKB1R w K -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 g1f3 g8f6 c1g5 h7h6 g5h4 e8g8 a1c1 d5c4", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bg5 h6 6. Bh4 O-O 7. Rc1 dxc4"),
  new FullOpening("D31", "Semi-Slav Defense: Accelerated Move Order", "rnbqkbnr/pp3ppp/2p1p3/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6", "1. d4 d5 2. c4 e6 3. Nc3 c6"),
  new FullOpening("D31", "Semi-Slav Defense: Anti-Noteboom, Stonewall Variation, Portisch Gambit", "rnbqkbnr/pp4pp/2p1p3/3p1p2/2PP2P1/2N1P3/PP3P1P/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 e2e3 f7f5 g2g4", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e3 f5 5. g4"),
  new FullOpening("D31", "Semi-Slav Defense: Gunderam Gambit", "rnbqkbnr/pp3ppp/2p1p3/8/2PPp3/2N2P2/PP4PP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 e2e4 d5e4 f2f3", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. f3"),
  new FullOpening("D31", "Semi-Slav Defense: Marshall Gambit", "rnbqkbnr/pp3ppp/2p1p3/3p4/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 e2e4", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4"),
  new FullOpening("D31", "Semi-Slav Defense: Marshall Gambit, Forgotten Variation", "rnbqk1nr/pp3ppp/2p1p3/8/1bPP4/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 e2e4 d5e4 c3e4 f8b4 e4c3", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. Nxe4 Bb4+ 6. Nc3"),
  new FullOpening("D31", "Semi-Slav Defense: Marshall Gambit, Main Line", "rnbqk1nr/pp3ppp/2p1p3/8/1bPPN3/8/PP1B1PPP/R2QKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 e2e4 d5e4 c3e4 f8b4 c1d2", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. Nxe4 Bb4+ 6. Bd2"),
  new FullOpening("D31", "Semi-Slav Defense: Marshall Gambit, Tolush Variation", "rnb1k1nr/pp3ppp/4p3/2B5/2P5/8/PP2BPqP/R2QK1NR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 e2e4 d5e4 c3e4 f8b4 c1d2 d8d4 d2b4 d4e4 f1e2 c6c5 b4c5 e4g2", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e4 dxe4 5. Nxe4 Bb4+ 6. Bd2 Qxd4 7. Bxb4 Qxe4+ 8. Be2 c5 9. Bxc5 Qxg2"),
  new FullOpening("D31", "Semi-Slav Defense: Noteboom Variation", "rnbqkbnr/pp3ppp/2p1p3/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 d5c4", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4"),
  new FullOpening("D31", "Semi-Slav Defense: Noteboom Variation, Abrahams Variation", "rnbqk1nr/p4ppp/4p3/1p6/2pP4/2P1PN2/5PPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 d5c4 a2a4 f8b4 e2e3 b4c3 b2c3 b7b5 a4b5 c6b5", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. a4 Bb4 6. e3 Bxc3+ 7. bxc3 b5 8. axb5 cxb5"),
  new FullOpening("D31", "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Gambit", "rnbqkbnr/pp3ppp/2p1p3/8/2pP4/2N2NP1/PP2PP1P/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 d5c4 g2g3", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. g3"),
  new FullOpening("D31", "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Variation", "rnbqkbnr/pp3ppp/2p1p3/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 d5c4 c1g5", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. Bg5"),
  new FullOpening("D31", "Semi-Slav Defense: Noteboom Variation, Anti-Noteboom Variation, Belyavsky Line", "rnbqkbnr/pp4pp/2p1pp2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 d5c4 c1g5 f7f6", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 dxc4 5. Bg5 f6"),
  new FullOpening("D32", "Queen's Gambit Declined: Tarrasch Defense", "rnbqkbnr/pp3ppp/8/2pp4/3P4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5"),
  new FullOpening("D32", "Tarrasch Defense", "rnbqkbnr/pp3ppp/4p3/2pp4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5", "1. d4 d5 2. c4 e6 3. Nc3 c5"),
  new FullOpening("D32", "Tarrasch Defense: Grünfeld Gambit", "r1bqkbnr/p4ppp/2n5/1pP5/N2p4/5N2/PP2PPPP/R1BQKB1R w KQkq b6", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 d4c5 d5d4 c3a4 b7b5", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. dxc5 d4 7. Na4 b5"),
  new FullOpening("D32", "Tarrasch Defense: Marshall Gambit", "rnbqkbnr/pp3ppp/8/2pp4/3PP3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 e2e4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. e4"),
  new FullOpening("D32", "Tarrasch Defense: Schara Gambit", "rnbqkbnr/pp3ppp/4p3/3P4/3p4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 c5d4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 cxd4"),
  new FullOpening("D32", "Tarrasch Defense: Tarrasch Gambit", "rnbqkbnr/p4ppp/8/1pP5/N2p4/8/PP2PPPP/R1BQKBNR w KQkq b6", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 d4c5 d5d4 c3a4 b7b5", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. dxc5 d4 6. Na4 b5"),
  new FullOpening("D32", "Tarrasch Defense: Two Knights Variation", "rnbqkbnr/pp3ppp/8/2pp4/3P4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3"),
  new FullOpening("D32", "Tarrasch Defense: von Hennig Gambit", "r2qkbnr/pp3ppp/2n1b3/3Q4/8/2N5/PP2PPPP/R1B1KBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 c5d4 d1d4 b8c6 d4d1 e6d5 d1d5 c8e6", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 cxd4 5. Qxd4 Nc6 6. Qd1 exd5 7. Qxd5 Be6"),
  new FullOpening("D33", "Tarrasch Defense: Prague Variation", "r1bqkb1r/pp3ppp/2n2n2/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6"),
  new FullOpening("D33", "Tarrasch Defense: Rubinstein System", "r1bqkbnr/pp3ppp/2n5/2pp4/3P4/2N2NP1/PP2PP1P/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3"),
  new FullOpening("D33", "Tarrasch Defense: Swedish Variation", "r1bqkbnr/pp3ppp/2n5/3p4/2pP4/2N2NP1/PP2PP1P/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 c5c4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 c4"),
  new FullOpening("D33", "Tarrasch Defense: Swedish Variation, Central Break", "r1bqkbnr/pp3ppp/2n5/3p4/2pPP3/2N2NP1/PP3P1P/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 c5c4 e2e4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 c4 7. e4"),
  new FullOpening("D33", "Tarrasch Defense: Wagner Variation", "r2qkb1r/pp3ppp/2n2n2/2pp4/3P2b1/2N2NP1/PP2PPBP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 c8g4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Bg4"),
  new FullOpening("D34", "Queen's Gambit Declined: Tarrasch Defense, Stoltz Variation", "r2q1rk1/p3bppp/1pn1bn2/2pp2B1/3P4/2N2NP1/PP2PPBP/2RQ1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 g1f3 c7c5 c4d5 e6d5 g2g3 b8c6 f1g2 g8f6 e1g1 f8e7 b1c3 e8g8 c1g5 c8e6 a1c1 b7b6", "1. d4 d5 2. c4 e6 3. Nf3 c5 4. cxd5 exd5 5. g3 Nc6 6. Bg2 Nf6 7. O-O Be7 8. Nc3 O-O 9. Bg5 Be6 10. Rc1 b6"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation", "r1bq1rk1/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Advance Variation", "r1bq1rk1/pp2bppp/2n2n2/3p2B1/2pP4/2N2NP1/PP2PPBP/R2Q1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c1g5 c5c4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. Bg5 c4"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Bogoljubov Variation", "r2q1rk1/pp2bppp/2n1bn2/3p2B1/2pP4/2N2NP1/PP2PPBP/2RQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 b1c3 c7c5 g1f3 d7d5 c4d5 e6d5 g2g3 b8c6 f1g2 f8e7 e1g1 e8g8 c1g5 c8e6 a1c1 c5c4", "1. d4 Nf6 2. c4 e6 3. Nc3 c5 4. Nf3 d5 5. cxd5 exd5 6. g3 Nc6 7. Bg2 Be7 8. O-O O-O 9. Bg5 Be6 10. Rc1 c4"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Carlsbad Variation", "r1bq1rk1/pp2bppp/2n2n2/2pp2B1/3P4/2N2NP1/PP2PPBP/R2Q1RK1 b - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c1g5", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. Bg5"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Chandler Variation", "r2qr1k1/pp2bpp1/2n1bn1p/3p4/3N4/2N1B1P1/PP2PPBP/2RQ1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c1g5 c5d4 f3d4 h7h6 g5e3 f8e8 a1c1 c8e6", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. Bg5 cxd4 10. Nxd4 h6 11. Be3 Re8 12. Rc1 Be6"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Classical Tarrasch Gambit", "r1bq1rk1/pp2bppp/2n2n2/2P5/3p4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 d4c5 d5d4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. dxc5 d4"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Endgame Variation", "r2q1rk1/pp2bppp/2n1bn2/2pp2B1/3P4/2N2NP1/PP2PPBP/R2Q1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c1g5 c8e6", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. Bg5 Be6"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Main Line", "r1bqr1k1/pp2bpp1/2n2n1p/3p4/3N4/2N1B1P1/PP2PPBP/R2Q1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c1g5 c5d4 f3d4 h7h6 g5e3 f8e8", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. Bg5 cxd4 10. Nxd4 h6 11. Be3 Re8"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Petursson Variation", "r1bqr1k1/pp2bppp/2n2n2/3p2B1/3N4/2N3P1/PP2PPBP/R2Q1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c1g5 c5d4 f3d4 f8e8", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. Bg5 cxd4 10. Nxd4 Re8"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Réti Variation", "r1bq1rk1/pp3ppp/2n2n2/2bp4/N7/5NP1/PP2PPBP/R1BQ1RK1 b - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 d4c5 e7c5 c3a4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. dxc5 Bxc5 10. Na4"),
  new FullOpening("D34", "Tarrasch Defense: Classical Variation, Spassky Variation", "r2q1rk1/pp2bpp1/2n2n1p/3p4/3N2b1/2N1B1P1/PP2PPBP/R2Q1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7 e1g1 e8g8 c1g5 c5d4 f3d4 h7h6 g5e3 c8g4", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7 8. O-O O-O 9. Bg5 cxd4 10. Nxd4 h6 11. Be3 Bg4"),
  new FullOpening("D34", "Tarrasch Defense: Prague Variation, Main Line", "r1bqk2r/pp2bppp/2n2n2/2pp4/3P4/2N2NP1/PP2PPBP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c5 c4d5 e6d5 g1f3 b8c6 g2g3 g8f6 f1g2 f8e7", "1. d4 d5 2. c4 e6 3. Nc3 c5 4. cxd5 exd5 5. Nf3 Nc6 6. g3 Nf6 7. Bg2 Be7"),
  new FullOpening("D35", "Queen's Gambit Declined: Exchange Variation", "rnbqkb1r/ppp2ppp/4pn2/3P4/3P4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c4d5", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5"),
  new FullOpening("D35", "Queen's Gambit Declined: Exchange Variation, Chameleon Variation", "r1bqrnk1/ppp1bppp/5n2/3p2B1/3P4/2NBP3/PPQ1NPPP/2KR3R b - -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c4d5 e6d5 c1g5 f8e7 e2e3 e8g8 f1d3 b8d7 d1c2 f8e8 g1e2 d7f8 e1c1", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5 Be7 6. e3 O-O 7. Bd3 Nbd7 8. Qc2 Re8 9. Nge2 Nf8 10. O-O-O"),
  new FullOpening("D35", "Queen's Gambit Declined: Exchange Variation, Positional Variation", "rnbqkb1r/ppp2ppp/5n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c4d5 e6d5 c1g5", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5"),
  new FullOpening("D35", "Queen's Gambit Declined: Exchange Variation, Positional Variation", "rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PP2PPPP/R2QKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c4d5 e6d5 c1g5 c7c6", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5 c6"),
  new FullOpening("D35", "Queen's Gambit Declined: Exchange Variation, Sämisch Variation", "r1bqkb1r/pppn1ppp/5n2/3p4/3P1B2/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 b8d7 c4d5 e6d5 c1f4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7 5. cxd5 exd5 6. Bf4"),
  new FullOpening("D35", "Queen's Gambit Declined: Harrwitz Attack", "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1f4", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bf4"),
  new FullOpening("D35", "Queen's Gambit Declined: Normal Defense", "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6", "1. d4 d5 2. c4 e6 3. Nc3 Nf6"),
  new FullOpening("D36", "Queen's Gambit Declined: Exchange Variation, Reshevsky Variation", "rnbqkb1r/pp3ppp/2p2n2/3p2B1/3P4/2N5/PPQ1PPPP/R3KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c4d5 e6d5 c1g5 c7c6 d1c2", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. cxd5 exd5 5. Bg5 c6 6. Qc2"),
  new FullOpening("D37", "Queen's Gambit Declined: Barmen Variation", "r1bqkb1r/pppn1ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 b8d7", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack", "rnbqk2r/ppp1bppp/4pn2/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1f4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack", "r1b2rk1/pp3ppp/2n1pn2/q1bp4/2P2B2/P1N1PN2/1PQ2PPP/3RKB1R b K -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1f4 e8g8 e2e3 c7c5 d4c5 e7c5 d1c2 b8c6 a1d1 d8a5 a2a3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4 O-O 6. e3 c5 7. dxc5 Bxc5 8. Qc2 Nc6 9. Rd1 Qa5 10. a3"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack", "r1b2rk1/pp3ppp/2n1pn2/q1bp4/2P2B2/P1N1PN2/1PQ2PPP/2KR1B1R b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1f4 e8g8 e2e3 c7c5 d4c5 e7c5 d1c2 b8c6 a2a3 d8a5 e1c1", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4 O-O 6. e3 c5 7. dxc5 Bxc5 8. Qc2 Nc6 9. a3 Qa5 10. O-O-O"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack, Fianchetto Defense", "rnbq1rk1/p1p1bppp/1p2pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 g1f3 g8f6 c1f4 e8g8 e2e3 b7b6", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bf4 O-O 6. e3 b6"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack, Main Line", "rnbq1rk1/pp3ppp/4pn2/2bp4/2P2B2/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1f4 e8g8 e2e3 c7c5 d4c5 e7c5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4 O-O 6. e3 c5 7. dxc5 Bxc5"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack, Orthodox Defense", "rnbq1rk1/pp2bppp/2p1pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 g1f3 f8e7 c1f4 e8g8 e2e3 c7c6", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 Be7 5. Bf4 O-O 6. e3 c6"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack, Two Knights Defense", "r1bq1rk1/pppnbppp/4pn2/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1f4 e8g8 e2e3 b8d7", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bf4 O-O 6. e3 Nbd7"),
  new FullOpening("D37", "Queen's Gambit Declined: Harrwitz Attack, Two Knights Defense, Blockade Line", "r1bq1rk1/pppnbppp/4pn2/2Pp4/3P1B2/2N1PN2/PP3PPP/R2QKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 g1f3 f8e7 c1f4 e8g8 e2e3 b8d7 c4c5", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Nf3 Be7 5. Bf4 O-O 6. e3 Nbd7 7. c5"),
  new FullOpening("D37", "Queen's Gambit Declined: Knight Defense, Alekhine Gambit", "r1bqkb1r/pppn1pp1/4pn1p/8/2pP3B/2N2N2/PP2PPPP/R2QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 b8d7 c1g5 h7h6 g5h4 d5c4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Nbd7 5. Bg5 h6 6. Bh4 dxc4"),
  new FullOpening("D37", "Queen's Gambit Declined: Miles Variation", "rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N2N2/PPQ1PPPP/R3KB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 d1c2", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. Qc2"),
  new FullOpening("D37", "Queen's Gambit Declined: Three Knights Variation", "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3"),
  new FullOpening("D37", "Queen's Gambit Declined: Vienna Variation", "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 d5c4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 dxc4"),
  new FullOpening("D37", "Queen's Gambit Declined: Vienna Variation, Quiet Variation", "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 d5c4 e2e3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 dxc4 5. e3"),
  new FullOpening("D38", "Queen's Gambit Declined: Ragozin Defense", "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8b4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4"),
  new FullOpening("D38", "Queen's Gambit Declined: Ragozin Defense, Alekhine Variation", "rnbqk2r/ppp2ppp/4pn2/3p4/QbPP4/2N2N2/PP2PPPP/R1B1KB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8b4 d1a4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Qa4+"),
  new FullOpening("D38", "Queen's Gambit Declined: Westphalian Variation", "r1bqk2r/pp1n1ppp/4pn2/2pp2B1/1bPP4/2N1PN2/PP3PPP/R2QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8b4 c1g5 b8d7 e2e3 c7c5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Bg5 Nbd7 6. e3 c5"),
  new FullOpening("D39", "Queen's Gambit Declined: Ragozin Defense, Vienna Variation", "rnbqk2r/ppp2ppp/4pn2/6B1/1bpP4/2N2N2/PP2PPPP/R2QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8b4 c1g5 d5c4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Bb4 5. Bg5 dxc4"),
  new FullOpening("D40", "Queen's Gambit Declined: Semi-Tarrasch Defense", "rnbqkb1r/pp3ppp/4pn2/2pp4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 c7c5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5"),
  new FullOpening("D40", "Queen's Gambit Declined: Semi-Tarrasch Defense, Levenfish Variation", "r1b2rk1/pp2qppp/2n1pn2/2bp4/2P1P3/2NB1N2/PP2QPPP/R1B2RK1 b - -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 g1f3 c7c5 e2e3 b8c6 f1d3 f8d6 e1g1 e8g8 d1e2 d8e7 d4c5 d6c5 e3e4", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c5 5. e3 Nc6 6. Bd3 Bd6 7. O-O O-O 8. Qe2 Qe7 9. dxc5 Bxc5 10. e4"),
  new FullOpening("D40", "Queen's Gambit Declined: Semi-Tarrasch Defense, Pillsbury Variation", "rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 g1f3 c7c5 c1g5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c5 5. Bg5"),
  new FullOpening("D40", "Queen's Gambit Declined: Semi-Tarrasch Defense, Symmetrical Variation", "r1bq1rk1/pp3ppp/2nbpn2/2pp4/2PP4/2NBPN2/PP3PPP/R1BQ1RK1 w - -", "c2c4 g8f6 b1c3 e7e6 g1f3 d7d5 e2e3 c7c5 d2d4 b8c6 f1d3 f8d6 e1g1 e8g8", "1. c4 Nf6 2. Nc3 e6 3. Nf3 d5 4. e3 c5 5. d4 Nc6 6. Bd3 Bd6 7. O-O O-O"),
  new FullOpening("D41", "Queen's Gambit Declined: Semi-Tarrasch Defense", "rnbqkb1r/pp3ppp/4pn2/2pP4/3P4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 c7c5 c4d5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5"),
  new FullOpening("D41", "Queen's Gambit Declined: Semi-Tarrasch Defense, Exchange Variation", "rnbqkb1r/pp3ppp/4p3/2pn4/3PP3/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 c7c5 c4d5 f6d5 e2e4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5 Nxd5 6. e4"),
  new FullOpening("D41", "Queen's Gambit Declined: Semi-Tarrasch Defense, Kmoch Variation", "rnbq1rk1/pp3ppp/4p3/1B6/3PP3/5N2/P2Q1PPP/R3K2R b KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 g1f3 c7c5 c4d5 f6d5 e2e4 d5c3 b2c3 c5d4 c3d4 f8b4 c1d2 b4d2 d1d2 e8g8 f1b5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Nf3 c5 5. cxd5 Nxd5 6. e4 Nxc3 7. bxc3 cxd4 8. cxd4 Bb4+ 9. Bd2 Bxd2+ 10. Qxd2 O-O 11. Bb5"),
  new FullOpening("D41", "Queen's Gambit Declined: Semi-Tarrasch Defense, Pillsbury Variation", "rnbqkb1r/pp3ppp/4p3/2pn4/3P4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 c7c5 c4d5 f6d5 e2e3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5 Nxd5 6. e3"),
  new FullOpening("D41", "Queen's Gambit Declined: Semi-Tarrasch Defense, San Sebastian Variation", "rnb1k2r/pp3ppp/4p3/q7/1b1PP3/5N2/P2B1PPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c4d5 f6d5 e2e4 d5c3 b2c3 c7c5 g1f3 c5d4 c3d4 f8b4 c1d2 d8a5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 c5 7. Nf3 cxd4 8. cxd4 Bb4+ 9. Bd2 Qa5"),
  new FullOpening("D42", "Queen's Gambit Declined: Semi-Tarrasch Defense, Main Line", "r1bqkb1r/pp3ppp/2n1p3/2pn4/3P4/2NBPN2/PP3PPP/R1BQK2R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 c7c5 c4d5 f6d5 e2e3 b8c6 f1d3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c5 5. cxd5 Nxd5 6. e3 Nc6 7. Bd3"),
  new FullOpening("D43", "Queen's Gambit Declined: Hastings Variation", "rnb1kb1r/pp3pp1/2p1pq1p/3p4/2PP4/1QN2N2/PP2PPPP/R3KB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 h7h6 g5f6 d8f6 d1b3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 h6 6. Bxf6 Qxf6 7. Qb3"),
  new FullOpening("D43", "Semi-Slav Defense", "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6"),
  new FullOpening("D43", "Semi-Slav Defense: Anti-Moscow Gambit", "rnbqkb1r/pp3pp1/2p1pn1p/3p4/2PP3B/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 h7h6 g5h4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 h6 6. Bh4"),
  new FullOpening("D44", "Semi-Slav Defense Accepted", "rnbqkb1r/pp3ppp/2p1pn2/6B1/2pP4/2N2N2/PP2PPPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 d5c4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4"),
  new FullOpening("D44", "Semi-Slav Defense: Botvinnik Variation", "rnbqkb1r/pp3ppp/2p1pn2/6B1/2pPP3/2N2N2/PP3PPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 d5c4 e2e4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4 6. e4"),
  new FullOpening("D44", "Semi-Slav Defense: Botvinnik Variation", "rnbqkb1r/p4p2/2p1pn1p/1p2P1N1/2pP3B/2N5/PP3PPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 d5c4 e2e4 b7b5 e4e5 h7h6 g5h4 g7g5 f3g5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4 6. e4 b5 7. e5 h6 8. Bh4 g5 9. Nxg5"),
  new FullOpening("D44", "Semi-Slav Defense: Botvinnik Variation, Alatortsev System", "rnbqkb1r/p4p2/2p1p2p/1p1nP1N1/2pP3B/2N5/PP3PPP/R2QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 c7c6 c1g5 d5c4 e2e4 b7b5 e4e5 h7h6 g5h4 g7g5 f3g5 f6d5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c6 5. Bg5 dxc4 6. e4 b5 7. e5 h6 8. Bh4 g5 9. Nxg5 Nd5"),
  new FullOpening("D44", "Semi-Slav Defense: Botvinnik Variation, Ekstrom Variation", "rnbqkb1r/p4p2/2p1pP1p/1p2N3/2pP3p/2N5/PP3PPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 d5c4 e2e4 b7b5 e4e5 h7h6 g5h4 g7g5 e5f6 g5h4 f3e5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4 6. e4 b5 7. e5 h6 8. Bh4 g5 9. exf6 gxh4 10. Ne5"),
  new FullOpening("D44", "Semi-Slav Defense: Botvinnik Variation, Lilienthal Variation", "r1bqkb1r/p2n1p2/2p1pn2/1p2P1B1/2pP4/2N3P1/PP3P1P/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 d5c4 e2e4 b7b5 e4e5 h7h6 g5h4 g7g5 f3g5 h6g5 h4g5 b8d7 g2g3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 dxc4 6. e4 b5 7. e5 h6 8. Bh4 g5 9. Nxg5 hxg5 10. Bxg5 Nbd7 11. g3"),
  new FullOpening("D44", "Semi-Slav Defense: Botvinnik Variation, Szabo Variation", "r1bqkb1r/p2n1p2/2p1pn2/1p2P1B1/2pP4/2N2Q2/PP3PPP/R3KB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 c7c6 c1g5 d5c4 e2e4 b7b5 e4e5 h7h6 g5h4 g7g5 f3g5 h6g5 h4g5 b8d7 d1f3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 c6 5. Bg5 dxc4 6. e4 b5 7. e5 h6 8. Bh4 g5 9. Nxg5 hxg5 10. Bxg5 Nbd7 11. Qf3"),
  new FullOpening("D45", "Semi-Slav Defense: Accelerated Meran Variation", "rnbqkb1r/1p3ppp/p1p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 a7a6", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 a6"),
  new FullOpening("D45", "Semi-Slav Defense: Main Line", "rnbqkb1r/pp3ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3"),
  new FullOpening("D45", "Semi-Slav Defense: Normal Variation", "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7"),
  new FullOpening("D45", "Semi-Slav Defense: Rubinstein System", "r1bqkb1r/pp1n1ppp/2p1pn2/3pN3/2PP4/2N1P3/PP3PPP/R1BQKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 g1f3 g8f6 e2e3 b8d7 f3e5", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. Nf3 Nf6 5. e3 Nbd7 6. Ne5"),
  new FullOpening("D45", "Semi-Slav Defense: Stoltz Variation", "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2N1PN2/PPQ2PPP/R1B1KB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 d1c2", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Qc2"),
  new FullOpening("D45", "Semi-Slav Defense: Stoltz Variation, Center Variation", "r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PPP3/2N2N2/PPQ2PPP/R1B1KB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 e2e3 b8d7 d1c2 f8d6 e3e4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. e3 Nbd7 6. Qc2 Bd6 7. e4"),
  new FullOpening("D45", "Semi-Slav Defense: Stoltz Variation, Center Variation, Mikhalchishin Line", "r1bqk2r/pp1n1ppp/2pb4/4P3/2P1Q3/5N2/PP3PPP/R1B1KB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 e2e3 b8d7 d1c2 f8d6 e3e4 d5e4 c3e4 f6e4 c2e4 e6e5 d4e5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. e3 Nbd7 6. Qc2 Bd6 7. e4 dxe4 8. Nxe4 Nxe4 9. Qxe4 e5 10. dxe5"),
  new FullOpening("D45", "Semi-Slav Defense: Stoltz Variation, Shabalov Attack", "r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP2P1/2N1PN2/PPQ2P1P/R1B1KB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 e2e3 b8d7 d1c2 f8d6 g2g4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. e3 Nbd7 6. Qc2 Bd6 7. g4"),
  new FullOpening("D45", "Semi-Slav Defense: Stonewall Defense", "rnbqkb1r/pp4pp/2p1p3/3p1p2/2PPn3/2NBPN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 g1f3 g8f6 c2c4 c7c6 b1c3 e7e6 e2e3 f6e4 f1d3 f7f5", "1. d4 d5 2. Nf3 Nf6 3. c4 c6 4. Nc3 e6 5. e3 Ne4 6. Bd3 f5"),
  new FullOpening("D46", "Semi-Slav Defense: Bogoljubov Variation", "r1bqk2r/pp1nbppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 f8e7", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Be7"),
  new FullOpening("D46", "Semi-Slav Defense: Chigorin Defense", "r1bqk2r/pp1n1ppp/2pbpn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 f8d6", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Bd6"),
  new FullOpening("D46", "Semi-Slav Defense: Main Line", "r1bqkb1r/pp1n1ppp/2p1pn2/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3"),
  new FullOpening("D46", "Semi-Slav Defense: Romih Variation", "r1bqk2r/pp1n1ppp/2p1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 f8b4", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 Bb4"),
  new FullOpening("D47", "Semi-Slav Defense: Meran Variation", "r1bqkb1r/p2n1ppp/2p1pn2/1p6/2BP4/2N1PN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5"),
  new FullOpening("D47", "Semi-Slav Defense: Meran Variation, Lundin Variation", "r1bqkb1r/p2n1ppp/2p1pn2/8/1p1P4/2NBPN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 e2e3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 b5b4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. e3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 b4"),
  new FullOpening("D47", "Semi-Slav Defense: Meran Variation, Wade Variation", "r2qkb1r/pb1n1ppp/2p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 c8b7", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 Bb7"),
  new FullOpening("D47", "Semi-Slav Defense: Meran Variation, Wade Variation, Kaidanov Gambit", "r2qkb1r/pb1n1ppp/4p3/3nP3/Np1N4/3B4/PP3PPP/R1BQ1RK1 b kq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 c8b7 e3e4 b5b4 c3a4 c6c5 e4e5 f6d5 e1g1 c5d4 f3d4", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 Bb7 9. e4 b4 10. Na4 c5 11. e5 Nd5 12. O-O cxd4 13. Nxd4"),
  new FullOpening("D47", "Semi-Slav Defense: Meran Variation, Wade Variation, Larsen Variation", "r2qkb1r/pb1n1ppp/4p3/2pnP3/Np1P4/3B1N2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 c8b7 e3e4 b5b4 c3a4 c6c5 e4e5 f6d5", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 Bb7 9. e4 b4 10. Na4 c5 11. e5 Nd5"),
  new FullOpening("D47", "Semi-Slav Defense: Semi-Meran Variation", "r1bqkb1r/pp1n1ppp/2p1pn2/8/2BP4/2N1PN2/PP3PPP/R1BQK2R b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4"),
  new FullOpening("D48", "Semi-Slav Defense: Meran Variation", "r1bqkb1r/3n1ppp/p1p1pn2/1p6/3P4/2NBPN2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6"),
  new FullOpening("D48", "Semi-Slav Defense: Meran Variation", "r1bqkb1r/3n1ppp/p3pn2/1pp5/3PP3/2NB1N2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5"),
  new FullOpening("D48", "Semi-Slav Defense: Meran Variation, Old Variation", "r1bqkb1r/3n1ppp/p3pn2/1pp1P3/3P4/2NB1N2/PP3PPP/R1BQK2R b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 e4e5", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. e5"),
  new FullOpening("D48", "Semi-Slav Defense: Meran Variation, Pirc Variation", "r1bqkb1r/3n1ppp/p1p1pn2/8/1p1PP3/2NB1N2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 c7c6 e2e3 g8f6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 b5b4", "1. d4 d5 2. c4 e6 3. Nc3 c6 4. e3 Nf6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 b4"),
  new FullOpening("D48", "Semi-Slav Defense: Meran Variation, Reynolds' Variation", "r1bqkb1r/3n1ppp/p3pn2/1ppP4/4P3/2NB1N2/PP3PPP/R1BQK2R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 e2e3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 d4d5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. e3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. d5"),
  new FullOpening("D49", "Semi-Slav Defense: Meran Variation, Blumenfeld Variation", "r1bqkb1r/3n1ppp/p3pn2/1N2P3/3p4/3B1N2/PP3PPP/R1BQK2R b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 e4e5 c5d4 c3b5", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. e5 cxd4 11. Nxb5"),
  new FullOpening("D49", "Semi-Slav Defense: Meran Variation, Rabinovich Variation", "r1bqkb1r/3n1ppp/p3p3/1N2P3/3p2n1/3B1N2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 e4e5 c5d4 c3b5 f6g4", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. e5 cxd4 11. Nxb5 Ng4"),
  new FullOpening("D49", "Semi-Slav Defense: Meran Variation, Rellstab Attack", "r3kb1r/5ppp/b3pn2/1p1qN1B1/3p4/3B4/PP2QPPP/R4RK1 b kq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 g1f3 e7e6 e2e3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 e4e5 c5d4 c3b5 d7e5 f3e5 a6b5 e1g1 d8d5 d1e2 c8a6 c1g5", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. Nf3 e6 5. e3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. e5 cxd4 11. Nxb5 Nxe5 12. Nxe5 axb5 13. O-O Qd5 14. Qe2 Ba6 15. Bg5"),
  new FullOpening("D49", "Semi-Slav Defense: Meran Variation, Sozin Variation", "r1bqkb1r/5ppp/p3pn2/1N2n3/3p4/3B1N2/PP3PPP/R1BQK2R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 e2e3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 e4e5 c5d4 c3b5 d7e5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. e3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. e5 cxd4 11. Nxb5 Nxe5"),
  new FullOpening("D49", "Semi-Slav Defense: Meran Variation, Sozin Variation", "r1bqkb1r/5ppp/4pn2/1p2N3/3p4/3B4/PP3PPP/R1BQ1RK1 b kq -", "d2d4 d7d5 g1f3 g8f6 c2c4 c7c6 b1c3 e7e6 e2e3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 e4e5 c5d4 c3b5 d7e5 f3e5 a6b5 e1g1", "1. d4 d5 2. Nf3 Nf6 3. c4 c6 4. Nc3 e6 5. e3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. e5 cxd4 11. Nxb5 Nxe5 12. Nxe5 axb5 13. O-O"),
  new FullOpening("D49", "Semi-Slav Defense: Meran Variation, Stahlberg Variation", "r1bqkb1r/5ppp/4pn2/1p2N3/3p4/3B1Q2/PP3PPP/R1B1K2R b KQkq -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 e7e6 g1f3 b8d7 f1d3 d5c4 d3c4 b7b5 c4d3 a7a6 e3e4 c6c5 e4e5 c5d4 c3b5 d7e5 f3e5 a6b5 d1f3", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 e6 5. Nf3 Nbd7 6. Bd3 dxc4 7. Bxc4 b5 8. Bd3 a6 9. e4 c5 10. e5 cxd4 11. Nxb5 Nxe5 12. Nxe5 axb5 13. Qf3"),
  new FullOpening("D50", "Queen's Gambit Declined: Been-Koomen Variation", "rnbqkb1r/pp3ppp/4pn2/2pp2B1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 c7c5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5"),
  new FullOpening("D50", "Queen's Gambit Declined: Modern Variation", "rnbqkb1r/ppp2ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5"),
  new FullOpening("D50", "Queen's Gambit Declined: Pseudo-Tarrasch Variation", "rnbqkb1r/pp3ppp/4pn2/2pP2B1/3P4/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 c7c5 c4d5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. cxd5"),
  new FullOpening("D50", "Queen's Gambit Declined: Pseudo-Tarrasch Variation, Canal Variation", "rnb1kb1r/pp3ppp/1q2pn2/2pP2B1/3P4/2N5/PP2PPPP/R2QKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 c7c5 c4d5 d8b6", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. cxd5 Qb6"),
  new FullOpening("D50", "Queen's Gambit Declined: Pseudo-Tarrasch Variation, Primitive Pillsbury Variation", "rnbqkb1r/pp3ppp/4pn2/3p2B1/2PQ4/2N2N2/PP2PPPP/R3KB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 c7c5 g1f3 c5d4 d1d4", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. Nf3 cxd4 6. Qxd4"),
  new FullOpening("D50", "Queen's Gambit Declined: Semi-Tarrasch Defense, Krause Variation", "rnbqkb1r/1p3ppp/p4n2/1N1pp1B1/Q1P5/2N5/PP2PPPP/R3KB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 c7c5 g1f3 c5d4 f3d4 e6e5 d4b5 a7a6 d1a4", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c5 5. Nf3 cxd4 6. Nxd4 e5 7. Ndb5 a6 8. Qa4"),
  new FullOpening("D51", "Queen's Gambit Declined: Alekhine Variation", "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PPP3/2N2N2/PP3PPP/R2QKB1R b KQkq -", "c2c4 e7e6 b1c3 d7d5 d2d4 g8f6 c1g5 b8d7 g1f3 c7c6 e2e4", "1. c4 e6 2. Nc3 d5 3. d4 Nf6 4. Bg5 Nbd7 5. Nf3 c6 6. e4"),
  new FullOpening("D51", "Queen's Gambit Declined: Capablanca Variation, Anti-Cambridge Springs Variation", "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/P1N1P3/1P3PPP/R2QKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7 e2e3 c7c6 a2a3", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6 6. a3"),
  new FullOpening("D51", "Queen's Gambit Declined: Manhattan Variation", "r1bqk2r/pppn1ppp/4pn2/3p2B1/1bPP4/2N1P3/PP3PPP/R2QKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7 e2e3 f8b4", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 Bb4"),
  new FullOpening("D51", "Queen's Gambit Declined: Modern Variation, Knight Defense", "r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7"),
  new FullOpening("D51", "Queen's Gambit Declined: Modern Variation, Knight Defense", "r1bqkb1r/pppn1ppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7 e2e3", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3"),
  new FullOpening("D51", "Queen's Gambit Declined: Modern Variation, Knight Defense", "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1P3/PP3PPP/R2QKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7 e2e3 c7c6", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6"),
  new FullOpening("D51", "Queen's Gambit Declined: Rochlin Variation", "r1b1kb1r/pp1n1ppp/2p1pn2/q2p4/2PP4/2N2N2/PP1BPPPP/2RQKB1R b Kkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7 g1f3 c7c6 a1c1 d8a5 g5d2", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. Nf3 c6 6. Rc1 Qa5 7. Bd2"),
  new FullOpening("D52", "Queen's Gambit Declined", "r1bqkb1r/pp1n1ppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 b8d7 e2e3", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3"),
  new FullOpening("D52", "Queen's Gambit Declined: Cambridge Springs Defense", "r1b1kb1r/pp1n1ppp/2p1pn2/q2p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 b8d7 e2e3 d8a5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3 Qa5"),
  new FullOpening("D52", "Queen's Gambit Declined: Cambridge Springs Defense", "r1b1kb1r/pp1n1ppp/2p1pn2/q2P2B1/3P4/2N1PN2/PP3PPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 b8d7 e2e3 d8a5 c4d5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3 Qa5 7. cxd5"),
  new FullOpening("D52", "Queen's Gambit Declined: Cambridge Springs Defense, Argentine Variation", "r1b2rk1/pp1n1ppp/2p1pn2/q2p4/1bPP3B/2N1P3/PPQN1PPP/R3KB1R b KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7 e2e3 c7c6 g1f3 d8a5 f3d2 f8b4 d1c2 e8g8 g5h4", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6 6. Nf3 Qa5 7. Nd2 Bb4 8. Qc2 O-O 9. Bh4"),
  new FullOpening("D52", "Queen's Gambit Declined: Cambridge Springs Defense, Bogoljubov Variation", "r1b1k2r/pp1n1ppp/2p1pn2/q2p2B1/1bPP4/2N1P3/PPQN1PPP/R3KB1R b KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 b8d7 e2e3 d8a5 f3d2 f8b4 d1c2", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3 Qa5 7. Nd2 Bb4 8. Qc2"),
  new FullOpening("D52", "Queen's Gambit Declined: Cambridge Springs Defense, Capablanca Variation", "r1b1kb1r/pp1n1ppp/2p1pB2/q2p4/2PP4/2N1PN2/PP3PPP/R2QKB1R b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 b8d7 e2e3 c7c6 g1f3 d8a5 g5f6", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Nbd7 5. e3 c6 6. Nf3 Qa5 7. Bxf6"),
  new FullOpening("D52", "Queen's Gambit Declined: Cambridge Springs Defense, Rubinstein Variation", "r1b1kb1r/pp1n1ppp/2p1pn2/q5B1/2pP4/2N1P3/PP1N1PPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 b8d7 e2e3 d8a5 f3d2 d5c4", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3 Qa5 7. Nd2 dxc4"),
  new FullOpening("D52", "Queen's Gambit Declined: Cambridge Springs Defense, Yugoslav Variation", "r1b1kb1r/pp1n1ppp/2p1p3/q2n2B1/3P4/2N1PN2/PP3PPP/R2QKB1R w KQkq -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 b1c3 e7e6 c1g5 b8d7 e2e3 d8a5 c4d5 f6d5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Nc3 e6 5. Bg5 Nbd7 6. e3 Qa5 7. cxd5 Nxd5"),
  new FullOpening("D53", "Queen's Gambit Declined", "rnbqk2r/ppp1bppp/4pn2/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR w KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7"),
  new FullOpening("D53", "Queen's Gambit Declined: Lasker Defense", "rnbqk2r/ppp1bppp/4p3/3p2B1/2PPn3/2N1P3/PP3PPP/R2QKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c1g5 f8e7 e2e3 f6e4", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 Ne4"),
  new FullOpening("D53", "Queen's Gambit Declined: Modern Variation, Heral Variation", "rnbqk2r/ppp1bppp/4pB2/3p4/2PP4/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 g5f6", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. Bxf6"),
  new FullOpening("D54", "Queen's Gambit Declined: Neo-Orthodox Variation", "rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1P3/PP3PPP/2RQKBNR b K -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c1g5 f8e7 e2e3 e8g8 a1c1", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 O-O 6. Rc1"),
  new FullOpening("D55", "Queen's Gambit Declined: Anti-Tartakower Variation", "rnbq1rk1/ppp1bpp1/4pB1p/3p4/2PP4/2N1PN2/PP3PPP/R2QKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 h7h6 g5f6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 h6 7. Bxf6"),
  new FullOpening("D55", "Queen's Gambit Declined: Anti-Tartakower Variation, Petrosian Variation", "r1bq1rk1/pp1n1pp1/2p1pb1p/8/2BP4/2N1PN2/PP3PPP/2RQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 h7h6 g5f6 e7f6 e2e3 e8g8 a1c1 c7c6 f1d3 b8d7 e1g1 d5c4 d3c4", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 h6 6. Bxf6 Bxf6 7. e3 O-O 8. Rc1 c6 9. Bd3 Nd7 10. O-O dxc4 11. Bxc4"),
  new FullOpening("D55", "Queen's Gambit Declined: Modern Variation, Normal Line", "rnbq1rk1/ppp1bppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3"),
  new FullOpening("D55", "Queen's Gambit Declined: Neo-Orthodox Variation", "rnbq1rk1/ppp1bpp1/4pn1p/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 h7h6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 h6"),
  new FullOpening("D55", "Queen's Gambit Declined: Neo-Orthodox Variation, Main Line", "rnbq1rk1/ppp1bpp1/4pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 h7h6 g5h4 e8g8 e2e3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 h6 6. Bh4 O-O 7. e3"),
  new FullOpening("D55", "Queen's Gambit Declined: Pillsbury Attack", "rn1q1rk1/pbp1bppp/1p3n2/3pN1B1/3P4/2NBP3/PP3PPP/R2QK2R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 b1c3 d7d5 c4d5 e6d5 c1g5 f8e7 e2e3 e8g8 f1d3 c8b7 f3e5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 d5 5. cxd5 exd5 6. Bg5 Be7 7. e3 O-O 8. Bd3 Bb7 9. Ne5"),
  new FullOpening("D56", "Queen's Gambit Declined: Lasker Defense", "rnbq1rk1/ppp1bpp1/4p2p/3p4/2PPn2B/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 g1f3 g8f6 c1g5 h7h6 g5h4 e8g8 e2e3 f6e4", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bg5 h6 6. Bh4 O-O 7. e3 Ne4"),
  new FullOpening("D56", "Queen's Gambit Declined: Lasker Defense, Russian Variation", "r4rk1/pp1bqpp1/2n1pn1p/2p5/2BP4/2N1PN2/PPQ2PPP/R2R2K1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 h7h6 g5h4 f6e4 h4e7 d8e7 d1c2 e4f6 f1d3 d5c4 d3c4 c7c5 e1g1 b8c6 f1d1 c8d7", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 h6 7. Bh4 Ne4 8. Bxe7 Qxe7 9. Qc2 Nf6 10. Bd3 dxc4 11. Bxc4 c5 12. O-O Nc6 13. Rfd1 Bd7"),
  new FullOpening("D56", "Queen's Gambit Declined: Lasker Defense, Teichmann Variation", "rnb2rk1/ppp1qpp1/4p2p/3p4/2PPn3/2N1PN2/PPQ2PPP/R3KB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 h7h6 g5h4 e8g8 e2e3 f6e4 h4e7 d8e7 d1c2", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 h6 6. Bh4 O-O 7. e3 Ne4 8. Bxe7 Qxe7 9. Qc2"),
  new FullOpening("D57", "Queen's Gambit Declined: Lasker Defense, Bernstein Variation", "rnb2rk1/ppp2pp1/3q3p/3p4/3P4/1QP1PN2/P4PPP/R3KB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c1g5 f8e7 e2e3 h7h6 g5h4 e8g8 g1f3 f6e4 h4e7 d8e7 c4d5 e4c3 b2c3 e6d5 d1b3 e7d6", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 h6 6. Bh4 O-O 7. Nf3 Ne4 8. Bxe7 Qxe7 9. cxd5 Nxc3 10. bxc3 exd5 11. Qb3 Qd6"),
  new FullOpening("D57", "Queen's Gambit Declined: Lasker Defense, Bernstein Variation, Mar del Plata Gambit", "rn1r2k1/ppp1qpp1/4b2p/3p4/2PP4/1Q2PN2/P4PPP/R3KB1R w KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 h7h6 g5h4 f6e4 h4e7 d8e7 c4d5 e4c3 b2c3 e6d5 d1b3 f8d8 c3c4 c8e6", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 h6 7. Bh4 Ne4 8. Bxe7 Qxe7 9. cxd5 Nxc3 10. bxc3 exd5 11. Qb3 Rd8 12. c4 Be6"),
  new FullOpening("D57", "Queen's Gambit Declined: Lasker Defense, Main Line", "rnb2rk1/ppp1qpp1/4p2p/3P4/3P4/2P1PN2/P4PPP/R2QKB1R b KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 g1f3 g8f6 c1g5 h7h6 g5h4 e8g8 e2e3 f6e4 h4e7 d8e7 c4d5 e4c3 b2c3", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bg5 h6 6. Bh4 O-O 7. e3 Ne4 8. Bxe7 Qxe7 9. cxd5 Nxc3 10. bxc3"),
  new FullOpening("D58", "Queen's Gambit Declined: Tartakower Defense", "rnbq1rk1/p1p1bpp1/1p2pn1p/3p4/2PP3B/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 h7h6 g5h4 e8g8 e2e3 b7b6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 h6 6. Bh4 O-O 7. e3 b6"),
  new FullOpening("D58", "Queen's Gambit Declined: Tartakower Defense, Exchange Variation", "rnbq1rk1/p1p1bpp1/1p3n1p/3p4/3P3B/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 g1f3 g8f6 c1g5 h7h6 g5h4 e8g8 e2e3 b7b6 c4d5 e6d5", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bg5 h6 6. Bh4 O-O 7. e3 b6 8. cxd5 exd5"),
  new FullOpening("D59", "Queen's Gambit Declined: Tartakower Defense", "rn3rk1/p1p1qpp1/1p2b2p/3p4/3P4/4PN2/PP3PPP/2RQKB1R w K -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 g1f3 g8f6 c1g5 h7h6 g5h4 e8g8 e2e3 b7b6 c4d5 f6d5 h4e7 d8e7 c3d5 e6d5 a1c1 c8e6", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bg5 h6 6. Bh4 O-O 7. e3 b6 8. cxd5 Nxd5 9. Bxe7 Qxe7 10. Nxd5 exd5 11. Rc1 Be6"),
  new FullOpening("D59", "Queen's Gambit Declined: Tartakower Defense, Makogonov Exchange Variation", "rnbq1rk1/p1p1bpp1/1p2p2p/3n4/3P3B/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 d7d5 c2c4 e7e6 b1c3 f8e7 g1f3 g8f6 c1g5 h7h6 g5h4 e8g8 e2e3 b7b6 c4d5 f6d5", "1. d4 d5 2. c4 e6 3. Nc3 Be7 4. Nf3 Nf6 5. Bg5 h6 6. Bh4 O-O 7. e3 b6 8. cxd5 Nxd5"),
  new FullOpening("D60", "Queen's Gambit Declined: Orthodox Defense", "r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7"),
  new FullOpening("D60", "Queen's Gambit Declined: Orthodox Defense, Botvinnik Variation", "r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2NBPN2/PP3PPP/R2QK2R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 f1d3", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Bd3"),
  new FullOpening("D60", "Queen's Gambit Declined: Orthodox Defense, Rauzer Variation", "r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/1QN1PN2/PP3PPP/R3KB1R b KQ -", "d2d4 d7d5 g1f3 g8f6 c2c4 e7e6 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 d1b3", "1. d4 d5 2. Nf3 Nf6 3. c4 e6 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Qb3"),
  new FullOpening("D61", "Queen's Gambit Declined: Orthodox Defense, Rubinstein Variation", "r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/R3KB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 d1c2", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Qc2"),
  new FullOpening("D62", "Queen's Gambit Declined: Orthodox Defense, Rubinstein Variation, Flohr Line", "r1bq1rk1/pp1nbppp/4pn2/2pP2B1/3P4/2N1PN2/PPQ2PPP/R3KB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 d1c2 c7c5 c4d5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Qc2 c5 8. cxd5"),
  new FullOpening("D63", "Queen's Gambit Declined: Orthodox Defense, Capablanca Variation", "r1bq1rk1/p1pnbppp/1p3n2/1B1p2B1/3P4/2N1PN2/PP3PPP/2RQK2R b K -", "g1f3 d7d5 d2d4 g8f6 c2c4 e7e6 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 b7b6 c4d5 e6d5 f1b5", "1. Nf3 d5 2. d4 Nf6 3. c4 e6 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 b6 8. cxd5 exd5 9. Bb5"),
  new FullOpening("D63", "Queen's Gambit Declined: Orthodox Defense, Henneberger Variation", "r1bq1rk1/1ppnbppp/p3pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R w K -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 a7a6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 a6"),
  new FullOpening("D63", "Queen's Gambit Declined: Orthodox Defense, Main Line", "r1bq1rk1/pppnbppp/4pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R b K -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1"),
  new FullOpening("D63", "Queen's Gambit Declined: Orthodox Defense, Main Line", "r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PP3PPP/2RQKB1R w K -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6"),
  new FullOpening("D63", "Queen's Gambit Declined: Orthodox Defense, Pillsbury Variation", "r1bq1rk1/p1pnbppp/1p3n2/3p2B1/3P4/2NBPN2/PP3PPP/2RQK2R b K -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 b7b6 c4d5 e6d5 f1d3", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 b6 8. cxd5 exd5 9. Bd3"),
  new FullOpening("D63", "Queen's Gambit Declined: Orthodox Defense, Swiss, Karlsbad Variation", "r1bq1rk1/1ppnbppp/p3pn2/3P2B1/3P4/2N1PN2/PP3PPP/2RQKB1R b K -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 a7a6 c4d5", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 a6 8. cxd5"),
  new FullOpening("D64", "Queen's Gambit Declined: Orthodox Defense, Rubinstein Attack", "r1bq1rk1/1p1nbppp/p1p1pn2/3p2B1/2PP4/P1N1PN2/1PQ2PPP/2R1KB1R b K -", "d2d4 g8f6 c2c4 e7e6 b1c3 d7d5 g1f3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 a2a3 a7a6 d1c2", "1. d4 Nf6 2. c4 e6 3. Nc3 d5 4. Nf3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. a3 a6 9. Qc2"),
  new FullOpening("D64", "Queen's Gambit Declined: Orthodox Defense, Rubinstein Attack", "r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R b K -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 d1c2", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. Qc2"),
  new FullOpening("D64", "Queen's Gambit Declined: Orthodox Defense, Rubinstein Attack", "r1bq1rk1/1p1nbppp/p1p1pn2/3p2B1/2PP4/2N1PN2/PPQ2PPP/2R1KB1R w K -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 d1c2 a7a6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. Qc2 a6"),
  new FullOpening("D64", "Queen's Gambit Declined: Orthodox Defense, Rubinstein Attack", "r1bq1rk1/pp1nbppp/2p1p3/3p2B1/2PPn3/2N1PN2/PPQ2PPP/2R1KB1R w K -", "g1f3 d7d5 d2d4 g8f6 c2c4 e7e6 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 d1c2 f6e4", "1. Nf3 d5 2. d4 Nf6 3. c4 e6 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. Qc2 Ne4"),
  new FullOpening("D65", "Queen's Gambit Declined: Orthodox Defense, Rubinstein Attack", "r1bq1rk1/1p1nbppp/p1p1pn2/3P2B1/3P4/2N1PN2/PPQ2PPP/2R1KB1R b K -", "d2d4 d7d5 g1f3 g8f6 c2c4 e7e6 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 d1c2 a7a6 c4d5", "1. d4 d5 2. Nf3 Nf6 3. c4 e6 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. Qc2 a6 9. cxd5"),
  new FullOpening("D66", "Queen's Gambit Declined: Orthodox Defense, Bd3 Line", "r1bq1rk1/pp1nbppp/2p1pn2/3p2B1/2PP4/2NBPN2/PP3PPP/2RQK2R b K -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 c7c6 f1d3", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 c6 8. Bd3"),
  new FullOpening("D66", "Queen's Gambit Declined: Orthodox Defense, Fianchetto Variation", "r1bq1rk1/p2nbppp/2p1pn2/1p4B1/2BP4/2N1PN2/PP3PPP/2RQK2R w K -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 c7c6 g1f3 f8e7 e2e3 b8d7 a1c1 e8g8 f1d3 d5c4 d3c4 b7b5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 c6 5. Nf3 Be7 6. e3 Nbd7 7. Rc1 O-O 8. Bd3 dxc4 9. Bxc4 b5"),
  new FullOpening("D67", "Queen's Gambit Declined: Orthodox Defense, Alekhine Variation", "r1b2rk1/pp1nqppp/2p1p3/3n4/2BPN3/4PN2/PP3PPP/2RQK2R b K -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 g5e7 d8e7 c3e4", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7 11. Ne4"),
  new FullOpening("D67", "Queen's Gambit Declined: Orthodox Defense, Bd3 Line", "r1b2rk1/pp1nqppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQK2R w K -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 g5e7 d8e7", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7"),
  new FullOpening("D67", "Queen's Gambit Declined: Orthodox Defense, Capablanca System", "r1bq1rk1/pp1nbppp/2p1p3/3n2B1/2BP4/2N1PN2/PP3PPP/2RQK2R w K -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5"),
  new FullOpening("D67", "Queen's Gambit Declined: Orthodox Defense, Janowski Variation", "r1bq1rk1/pp1nbppp/2p1p3/3n2B1/2BP3P/2N1PN2/PP3PP1/2RQK2R b K -", "d2d4 d7d5 g1f3 g8f6 c2c4 e7e6 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 h2h4", "1. d4 d5 2. Nf3 Nf6 3. c4 e6 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. h4"),
  new FullOpening("D67", "Queen's Gambit Declined: Orthodox Defense, Main Line", "r1b2rk1/pp1nqppp/2p1p3/3n4/2BP4/2N1PN2/PP3PPP/2RQ1RK1 b - -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 g5e7 d8e7 e1g1", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7 11. O-O"),
  new FullOpening("D68", "Queen's Gambit Declined: Orthodox Defense, Classical Variation", "r1b2rk1/pp1nqppp/2p5/4p3/2BP4/2R1PN2/PP3PPP/3Q1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 g5e7 d8e7 e1g1 d5c3 c1c3 e6e5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7 11. O-O Nxc3 12. Rxc3 e5"),
  new FullOpening("D68", "Queen's Gambit Declined: Orthodox Defense, Classical Variation", "r1b2rk1/pp1nqppp/2p5/4p3/2BP4/2R1PN2/PP3PPP/1Q3RK1 b - -", "d2d4 d7d5 c2c4 e7e6 g1f3 g8f6 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 g5e7 d8e7 e1g1 d5c3 c1c3 e6e5 d1b1", "1. d4 d5 2. c4 e6 3. Nf3 Nf6 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7 11. O-O Nxc3 12. Rxc3 e5 13. Qb1"),
  new FullOpening("D68", "Queen's Gambit Declined: Orthodox Defense, Classical Variation", "r1b2rk1/pp1nqppp/2p5/4p3/2BP4/2R1PN2/PPQ2PPP/5RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 b1c3 f8e7 c1g5 e8g8 e2e3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 g5e7 d8e7 e1g1 d5c3 c1c3 e6e5 d1c2", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. Nc3 Be7 5. Bg5 O-O 6. e3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7 11. O-O Nxc3 12. Rxc3 e5 13. Qc2"),
  new FullOpening("D69", "Queen's Gambit Declined: Orthodox Defense, Classical Variation", "r1b2rk1/pp3ppp/2p5/4q3/2B5/2R1P3/PP3PPP/3Q1RK1 w - -", "d2d4 d7d5 c2c4 e7e6 b1c3 g8f6 c1g5 f8e7 e2e3 e8g8 g1f3 b8d7 a1c1 c7c6 f1d3 d5c4 d3c4 f6d5 g5e7 d8e7 e1g1 d5c3 c1c3 e6e5 d4e5 d7e5 f3e5 e7e5", "1. d4 d5 2. c4 e6 3. Nc3 Nf6 4. Bg5 Be7 5. e3 O-O 6. Nf3 Nbd7 7. Rc1 c6 8. Bd3 dxc4 9. Bxc4 Nd5 10. Bxe7 Qxe7 11. O-O Nxc3 12. Rxc3 e5 13. dxe5 Nxe5 14. Nxe5 Qxe5"),
  new FullOpening("D70", "Neo-Grünfeld Defense", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 g2g3 d7d5", "1. d4 Nf6 2. c4 g6 3. g3 d5"),
  new FullOpening("D70", "Neo-Grünfeld Defense: Goglidze Attack", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/5P2/PP2P1PP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 f2f3 d7d5", "1. d4 Nf6 2. c4 g6 3. f3 d5"),
  new FullOpening("D71", "Neo-Grünfeld Defense: Exchange Variation", "rnbqk2r/ppp1ppbp/6p1/3n4/3P4/6P1/PP2PPBP/RNBQK1NR w KQkq -", "d2d4 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 d7d5 c4d5 f6d5", "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 d5 5. cxd5 Nxd5"),
  new FullOpening("D74", "Neo-Grünfeld Defense: Delayed Exchange Variation", "rnbq1rk1/ppp1ppbp/6p1/3n4/3P4/5NP1/PP2PPBP/RNBQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d5 c4d5 f6d5 e1g1", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d5 6. cxd5 Nxd5 7. O-O"),
  new FullOpening("D75", "Neo-Grünfeld Defense: Delayed Exchange Variation", "rnbq1rk1/pp2ppbp/6p1/2pn4/3P4/2N2NP1/PP2PPBP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 g1f3 e8g8 g2g3 d7d5 c4d5 f6d5 f1g2 c7c5 e1g1", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 O-O 5. g3 d5 6. cxd5 Nxd5 7. Bg2 c5 8. O-O"),
  new FullOpening("D75", "Neo-Grünfeld Defense: Delayed Exchange Variation", "rnbq1rk1/pp2ppbp/6p1/2Pn4/8/5NP1/PP2PPBP/RNBQ1RK1 b - -", "d2d4 g8f6 g1f3 g7g6 c2c4 f8g7 g2g3 e8g8 f1g2 d7d5 c4d5 f6d5 e1g1 c7c5 d4c5", "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. g3 O-O 5. Bg2 d5 6. cxd5 Nxd5 7. O-O c5 8. dxc5"),
  new FullOpening("D76", "Neo-Grünfeld Defense: Delayed Exchange Variation", "rnbq1rk1/ppp1ppbp/1n4p1/8/3P4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d5 c4d5 f6d5 e1g1 d5b6", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d5 6. cxd5 Nxd5 7. O-O Nb6"),
  new FullOpening("D77", "Neo-Grünfeld Defense: Classical Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 b - -", "d2d4 g8f6 g1f3 g7g6 g2g3 f8g7 f1g2 e8g8 e1g1 d7d5 c2c4", "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4"),
  new FullOpening("D77", "Neo-Grünfeld Defense: Classical Variation, Modern Defense", "rnbq1rk1/ppp1ppbp/5np1/8/2pP4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 g8f6 g1f3 g7g6 g2g3 f8g7 f1g2 e8g8 e1g1 d7d5 c2c4 d5c4", "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4 dxc4"),
  new FullOpening("D77", "Neo-Grünfeld Defense: Classical Variation, Polgar Variation", "r1bq1rk1/ppp1ppbp/2n2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 g8f6 g1f3 g7g6 g2g3 f8g7 f1g2 e8g8 e1g1 d7d5 c2c4 b8c6", "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d5 6. c4 Nc6"),
  new FullOpening("D78", "Neo-Grünfeld Defense: Classical Variation, Original Defense", "rnbq1rk1/pp2ppbp/2p2np1/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 c7c6 e1g1 d7d5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 c6 6. O-O d5"),
  new FullOpening("D79", "Neo-Grünfeld Defense: Ultra-delayed Exchange Variation", "rnbq1rk1/pp2ppbp/5np1/3p4/3P4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 c7c6 e1g1 d7d5 c4d5 c6d5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 c6 6. O-O d5 7. cxd5 cxd5"),
  new FullOpening("D80", "Grünfeld Defense", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5"),
  new FullOpening("D80", "Grünfeld Defense: Gibbon Gambit", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP2P1/2N5/PP2PP1P/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g2g4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. g4"),
  new FullOpening("D80", "Grünfeld Defense: Lundin Variation", "rnbqkb1r/pp2pp1p/6p1/2p3B1/2PPp3/8/PP1QPPPP/R3KBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1g5 f6e4 c3e4 d5e4 d1d2 c7c5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bg5 Ne4 5. Nxe4 dxe4 6. Qd2 c5"),
  new FullOpening("D80", "Grünfeld Defense: Lutikov Variation", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2P2/PP2P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 f2f3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. f3"),
  new FullOpening("D80", "Grünfeld Defense: Lutikov Variation, Murrey Attack", "rnbqkb1r/pp2pp1p/6p1/2pn4/N2P4/5P2/PP2P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 f2f3 c7c5 c4d5 f6d5 c3a4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. f3 c5 5. cxd5 Nxd5 6. Na4"),
  new FullOpening("D80", "Grünfeld Defense: Stockholm Variation", "rnbqkb1r/ppp1pp1p/5np1/3p2B1/2PP4/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1g5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bg5"),
  new FullOpening("D80", "Grünfeld Defense: Zaitsev Gambit", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP3P/2N5/PP2PPP1/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 h2h4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. h4"),
  new FullOpening("D81", "Grünfeld Defense: Russian Variation, Accelerated Variation", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/1QN5/PP2PPPP/R1B1KBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 d1b3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Qb3"),
  new FullOpening("D82", "Grünfeld Defense: Brinckmann Attack", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP1B2/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1f4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4"),
  new FullOpening("D83", "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit", "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/R2QKBNR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1f4 f8g7 e2e3 e8g8", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O"),
  new FullOpening("D83", "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit, Botvinnik Variation", "rn1q1rk1/pp2ppbp/4bnp1/2Pp4/2P2B2/2N1P3/PP3PPP/2RQKBNR w K -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1f4 f8g7 e2e3 e8g8 a1c1 c7c5 d4c5 c8e6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Rc1 c5 7. dxc5 Be6"),
  new FullOpening("D83", "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit, Capablanca Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1P3/PP3PPP/2RQKBNR b K -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1f4 f8g7 e2e3 e8g8 a1c1", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. Rc1"),
  new FullOpening("D83", "Grünfeld Defense: Brinckmann Attack, Reshevsky Gambit", "rnb2rk1/pp2ppbp/5np1/q1Pp4/2P2B2/2N1P3/PP3PPP/2RQKBNR w K -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1f4 f8g7 a1c1 e8g8 e2e3 c7c5 d4c5 d8a5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. Rc1 O-O 6. e3 c5 7. dxc5 Qa5"),
  new FullOpening("D84", "Grünfeld Defense: Brinckmann Attack, Grünfeld Gambit Accepted", "rnb2rk1/ppB1ppbp/6p1/3q4/3P4/4P3/PP3PPP/R2QKBNR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c1f4 f8g7 e2e3 e8g8 c4d5 f6d5 c3d5 d8d5 f4c7", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Bf4 Bg7 5. e3 O-O 6. cxd5 Nxd5 7. Nxd5 Qxd5 8. Bxc7"),
  new FullOpening("D85", "Grünfeld Defense: Exchange Variation", "rnbqkb1r/ppp1pp1p/6p1/3n4/3P4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5"),
  new FullOpening("D85", "Grünfeld Defense: Exchange Variation, Modern Exchange Variation", "rnbqk2r/ppp1ppbp/6p1/8/3PP3/2P2N2/P4PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 g1f3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Nf3"),
  new FullOpening("D85", "Grünfeld Defense: Exchange Variation, Modern Exchange Variation, Kramnik's Line", "rnbqk2r/pp2ppbp/6p1/2p5/3PP3/2P2N1P/P4PP1/R1BQKB1R b KQkq -", "d2d4 g8f6 g1f3 g7g6 c2c4 f8g7 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 c7c5 h2h3", "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. Nc3 d5 5. cxd5 Nxd5 6. e4 Nxc3 7. bxc3 c5 8. h3"),
  new FullOpening("D85", "Grünfeld Defense: Exchange Variation, Modern Exchange Variation, Pawn Grab Line", "r1bq1rk1/pp2pp1p/2n3p1/2pP4/4P3/2b2N2/P3BPPP/1RBQK2R w K -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 g1f3 c7c5 a1b1 e8g8 f1e2 b8c6 d4d5 g7c3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Nf3 c5 8. Rb1 O-O 9. Be2 Nc6 10. d5 Bxc3+"),
  new FullOpening("D85", "Grünfeld Defense: Exchange Variation, Nadanian Attack", "rnbqkb1r/ppp1pp1p/6p1/3n4/N2P4/8/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 c3a4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. Na4"),
  new FullOpening("D86", "Grünfeld Defense: Exchange Variation, Classical Variation", "rnbqk2r/ppp1ppbp/6p1/8/2BPP3/2P5/P4PPP/R1BQK1NR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4"),
  new FullOpening("D86", "Grünfeld Defense: Exchange Variation, Larsen Variation", "rnb2rk1/pppqppbp/6p1/8/2BPP3/2P5/P3NPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 e8g8 g1e2 d8d7", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 O-O 8. Ne2 Qd7"),
  new FullOpening("D86", "Grünfeld Defense: Exchange Variation, Larsen Variation", "rnb2rk1/p1pqppbp/1p4p1/8/2BPP3/2P5/P3NPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 e8g8 g1e2 d8d7 e1g1 b7b6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 O-O 8. Ne2 Qd7 9. O-O b6"),
  new FullOpening("D86", "Grünfeld Defense: Exchange Variation, Simagin's Improved Variation", "r1bq1rk1/ppp1ppbp/2n3p1/8/2BPP3/2P5/P3NPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 e8g8 g1e2 b8c6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 O-O 8. Ne2 Nc6"),
  new FullOpening("D86", "Grünfeld Defense: Exchange Variation, Simagin's Lesser Variation", "rnbq1rk1/p1p1ppbp/1p4p1/8/2BPP3/2P5/P3NPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 e8g8 g1e2 b7b6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 O-O 8. Ne2 b6"),
  new FullOpening("D87", "Grünfeld Defense: Exchange Variation, Seville Variation", "r2q1rk1/pp2pBbp/6p1/n1p5/3PP1b1/2P1BP2/P3N1PP/R2Q1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 c7c5 g1e2 b8c6 c1e3 e8g8 e1g1 c8g4 f2f3 c6a5 c4f7", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 c5 8. Ne2 Nc6 9. Be3 O-O 10. O-O Bg4 11. f3 Na5 12. Bxf7+"),
  new FullOpening("D87", "Grünfeld Defense: Exchange Variation, Spassky Variation", "rnbq1rk1/pp2ppbp/6p1/2p5/2BPP3/2P5/P3NPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 c7c5 g1e2 e8g8", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 c5 8. Ne2 O-O"),
  new FullOpening("D88", "Grünfeld Defense: Exchange Variation, Spassky Variation", "r1bq1rk1/pp2ppbp/2n3p1/8/2BPP3/4B3/P3NPPP/R2Q1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 c7c5 g1e2 b8c6 c1e3 e8g8 e1g1 c5d4 c3d4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 c5 8. Ne2 Nc6 9. Be3 O-O 10. O-O cxd4 11. cxd4"),
  new FullOpening("D89", "Grünfeld Defense: Exchange Variation, Sokolsky Variation", "r2q1rk1/pp2ppbp/4b1p1/n2P4/4P3/3BBP2/P3N1PP/R2Q1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 c7c5 g1e2 e8g8 e1g1 b8c6 c1e3 c8g4 f2f3 c6a5 c4d3 c5d4 c3d4 g4e6 d4d5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 c5 8. Ne2 O-O 9. O-O Nc6 10. Be3 Bg4 11. f3 Na5 12. Bd3 cxd4 13. cxd4 Be6 14. d5"),
  new FullOpening("D89", "Grünfeld Defense: Exchange Variation, Spassky Variation", "r2q1rk1/pp2ppbp/4b1p1/n7/3PP3/3BBP2/P3N1PP/R2Q1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 c4d5 f6d5 e2e4 d5c3 b2c3 f8g7 f1c4 c7c5 g1e2 b8c6 c1e3 e8g8 e1g1 c8g4 f2f3 c6a5 c4d3 c5d4 c3d4 g4e6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. cxd5 Nxd5 5. e4 Nxc3 6. bxc3 Bg7 7. Bc4 c5 8. Ne2 Nc6 9. Be3 O-O 10. O-O Bg4 11. f3 Na5 12. Bd3 cxd4 13. cxd4 Be6"),
  new FullOpening("D90", "Grünfeld Defense: Flohr Variation", "rnbqk2r/ppp1ppbp/5np1/3p4/Q1PP4/2N2N2/PP2PPPP/R1B1KB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1a4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qa4+"),
  new FullOpening("D90", "Grünfeld Defense: Three Knights Variation", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3"),
  new FullOpening("D90", "Grünfeld Defense: Three Knights Variation", "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7"),
  new FullOpening("D91", "Grünfeld Defense: Three Knights Variation, Petrosian System", "rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 c1g5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bg5"),
  new FullOpening("D92", "Grünfeld Defense: Three Knights Variation, Hungarian Attack", "rnbqk2r/ppp1ppbp/5np1/3p4/2PP1B2/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 c1f4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bf4"),
  new FullOpening("D93", "Grünfeld Defense: Three Knights Variation, Hungarian Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP1B2/2N1PN2/PP3PPP/R2QKB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 c1f4 e8g8 e2e3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Bf4 O-O 6. e3"),
  new FullOpening("D94", "Grünfeld Defense: Flohr Defense", "rn1q1rk1/pp2ppbp/2p2np1/3p1b2/2PP4/2NBPN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 g7g6 g1f3 f8g7 f1d3 e8g8 e1g1 c8f5", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 g6 5. Nf3 Bg7 6. Bd3 O-O 7. O-O Bf5"),
  new FullOpening("D94", "Grünfeld Defense: Makogonov Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/1PPP4/2N1PN2/P4PPP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 e2e3 e8g8 b2b4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. b4"),
  new FullOpening("D94", "Grünfeld Defense: Opocensky Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP1B1PPP/R2QKB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 e2e3 e8g8 c1d2", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Bd2"),
  new FullOpening("D94", "Grünfeld Defense: Smyslov Defense", "rn1q1rk1/pp2ppbp/2p2np1/3p4/2PP2b1/2NBPN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 d7d5 c2c4 c7c6 b1c3 g8f6 e2e3 g7g6 g1f3 f8g7 f1d3 e8g8 e1g1 c8g4", "1. d4 d5 2. c4 c6 3. Nc3 Nf6 4. e3 g6 5. Nf3 Bg7 6. Bd3 O-O 7. O-O Bg4"),
  new FullOpening("D94", "Grünfeld Defense: Three Knights Variation, Burille Variation", "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/2N1PN2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 e2e3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3"),
  new FullOpening("D94", "Grünfeld Defense: Three Knights Variation, Paris Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/2NBPN2/PP3PPP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 e2e3 e8g8 f1d3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Bd3"),
  new FullOpening("D95", "Grünfeld Defense: Botvinnik Variation", "rnbq1rk1/ppp2pbp/4pnp1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 e2e3 e8g8 d1b3 e7e6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Qb3 e6"),
  new FullOpening("D95", "Grünfeld Defense: Pachman Variation", "r1bq1rk1/pppnppbp/5np1/6N1/2BP4/1QN1P3/PP3PPP/R1B1K2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 e2e3 f8g7 d1b3 d5c4 f1c4 e8g8 g1f3 b8d7 f3g5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. e3 Bg7 5. Qb3 dxc4 6. Bxc4 O-O 7. Nf3 Nbd7 8. Ng5"),
  new FullOpening("D95", "Grünfeld Defense: Three Knights Variation, Vienna Variation", "rnbq1rk1/ppp1ppbp/5np1/3p4/2PP4/1QN1PN2/PP3PPP/R1B1KB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 e2e3 e8g8 d1b3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. e3 O-O 6. Qb3"),
  new FullOpening("D96", "Grünfeld Defense: Russian Variation", "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/1QN2N2/PP2PPPP/R1B1KB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3"),
  new FullOpening("D97", "Grünfeld Defense: Russian Variation", "rnbq1rk1/ppp1ppbp/5np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4"),
  new FullOpening("D97", "Grünfeld Defense: Russian Variation, Byrne Variation", "r1bq1rk1/ppp1ppbp/2n2np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 b8c6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 Nc6"),
  new FullOpening("D97", "Grünfeld Defense: Russian Variation, Hungarian Variation", "rnbq1rk1/1pp1ppbp/p4np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 a7a6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 a6"),
  new FullOpening("D97", "Grünfeld Defense: Russian Variation, Levenfish Variation", "rnbq1rk1/p1p1ppbp/1p3np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 b7b6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 b6"),
  new FullOpening("D97", "Grünfeld Defense: Russian Variation, Prins Variation", "r1bq1rk1/ppp1ppbp/n4np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 b8a6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 Na6"),
  new FullOpening("D97", "Grünfeld Defense: Russian Variation, Szabo Variation", "rnbq1rk1/pp2ppbp/2p2np1/8/2QPP3/2N2N2/PP3PPP/R1B1KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 c7c6", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 c6"),
  new FullOpening("D98", "Grünfeld Defense: Russian Variation, Keres Variation", "r2q1rk1/ppp1ppbp/1nn3p1/8/3PP1b1/2NQBN2/PP2BPPP/2KR3R b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 c8g4 c1e3 f6d7 f1e2 d7b6 c4d3 b8c6 e1c1", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 Bg4 8. Be3 Nfd7 9. Be2 Nb6 10. Qd3 Nc6 11. O-O-O"),
  new FullOpening("D98", "Grünfeld Defense: Russian Variation, Smyslov Variation", "rn1q1rk1/ppp1ppbp/5np1/8/2QPP1b1/2N2N2/PP3PPP/R1B1KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 c8g4", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 Bg4"),
  new FullOpening("D99", "Grünfeld Defense: Russian Variation, Smyslov Variation", "rn1q1rk1/pppnppbp/6p1/8/3PP1b1/1QN1BN2/PP3PPP/R3KB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 c8g4 c1e3 f6d7 c4b3", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 Bg4 8. Be3 Nfd7 9. Qb3"),
  new FullOpening("D99", "Grünfeld Defense: Russian Variation, Yugoslav Variation", "rn1q1rk1/pp1nppbp/6p1/2p5/3PP1b1/1QN1BN2/PP3PPP/R3KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 d7d5 g1f3 f8g7 d1b3 d5c4 b3c4 e8g8 e2e4 c8g4 c1e3 f6d7 c4b3 c7c5", "1. d4 Nf6 2. c4 g6 3. Nc3 d5 4. Nf3 Bg7 5. Qb3 dxc4 6. Qxc4 O-O 7. e4 Bg4 8. Be3 Nfd7 9. Qb3 c5"),
  new FullOpening("E00", "Catalan Opening", "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3", "1. d4 Nf6 2. c4 e6 3. g3"),
  new FullOpening("E00", "Catalan Opening", "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5", "1. d4 Nf6 2. c4 e6 3. g3 d5"),
  new FullOpening("E00", "Catalan Opening: Hungarian Gambit", "rnbqkb1r/pppp1ppp/5n2/4p3/2PP4/6P1/PP2PP1P/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 e6e5", "1. d4 Nf6 2. c4 e6 3. g3 e5"),
  new FullOpening("E00", "Indian Defense", "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/1Q6/PP2PPPP/RNB1KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 d1b3", "1. d4 Nf6 2. c4 e6 3. Qb3"),
  new FullOpening("E00", "Indian Defense: Devin Gambit", "rnbqkb1r/pppp1ppp/4pn2/8/2PP2P1/8/PP2PP1P/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g4", "1. d4 Nf6 2. c4 e6 3. g4"),
  new FullOpening("E00", "Indian Defense: Seirawan Attack", "rnbqkb1r/pppp1ppp/4pn2/6B1/2PP4/8/PP2PPPP/RN1QKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 c1g5", "1. d4 Nf6 2. c4 e6 3. Bg5"),
  new FullOpening("E01", "Catalan Opening: Closed", "rnbqkb1r/ppp2ppp/4pn2/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2"),
  new FullOpening("E01", "Catalan Opening: Open Defense, Tarrasch Defense", "r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/5NP1/PP2PPBP/RNBQK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 c7c5 g1f3 b8c6", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 c5 5. Nf3 Nc6"),
  new FullOpening("E02", "Catalan Opening: Open Defense", "rnbqkb1r/ppp2ppp/4pn2/8/Q1pP4/6P1/PP2PPBP/RNB1K1NR b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 d5c4 d1a4", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Qa4+"),
  new FullOpening("E03", "Catalan Opening: Open Defense", "r1bqkb1r/pppn1ppp/4pn2/8/2QP4/6P1/PP2PPBP/RNB1K1NR b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 d5c4 d1a4 b8d7 a4c4", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Qa4+ Nbd7 6. Qxc4"),
  new FullOpening("E03", "Catalan Opening: Open Defense, Alekhine Variation", "r1bqkb1r/1ppn1ppp/p3pn2/8/3P4/6P1/PPQ1PPBP/RNB1K1NR b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 d5c4 d1a4 b8d7 a4c4 a7a6 c4c2", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Qa4+ Nbd7 6. Qxc4 a6 7. Qc2"),
  new FullOpening("E04", "Catalan Opening: Open Defense", "rnbqkb1r/ppp2ppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 d5c4 g1f3", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3"),
  new FullOpening("E04", "Catalan Opening: Open Defense, Modern Sharp Variation", "r1bqk2r/ppp2ppp/2n1pn2/8/QbpP4/5NP1/PP2PPBP/RNB1K2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 d5c4 g1f3 b8c6 d1a4 f8b4", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3 Nc6 6. Qa4 Bb4+"),
  new FullOpening("E05", "Catalan Opening: Open Defense, Classical Line", "rnbqk2r/ppp1bppp/4pn2/8/2pP4/5NP1/PP2PPBP/RNBQK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 d5c4 g1f3 f8e7", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 dxc4 5. Nf3 Be7"),
  new FullOpening("E06", "Catalan Opening: Closed", "rnbqk2r/ppp1bppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQK2R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8e7 g1f3", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3"),
  new FullOpening("E07", "Catalan Opening: Closed", "r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8e7 g1f3 e8g8 e1g1 b8d7", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3 O-O 6. O-O Nbd7"),
  new FullOpening("E07", "Catalan Opening: Closed, Botvinnik Variation", "r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/2NQ1NP1/PP2PPBP/R1B2RK1 b - -", "c2c4 e7e6 g2g3 d7d5 f1g2 g8f6 g1f3 f8e7 d2d4 e8g8 b1c3 c7c6 e1g1 b8d7 d1d3", "1. c4 e6 2. g3 d5 3. Bg2 Nf6 4. Nf3 Be7 5. d4 O-O 6. Nc3 c6 7. O-O Nbd7 8. Qd3"),
  new FullOpening("E08", "Catalan Opening: Closed", "r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/1P3NP1/P1Q1PPBP/RNB2RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8e7 g1f3 e8g8 e1g1 b8d7 d1c2 c7c6 b2b3", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3 O-O 6. O-O Nbd7 7. Qc2 c6 8. b3"),
  new FullOpening("E08", "Catalan Opening: Closed", "r1bq1rk1/pppnbppp/4pn2/3p4/2PP4/5NP1/PPQ1PPBP/RNB2RK1 b - -", "g1f3 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8e7 e1g1 e8g8 d2d4 b8d7 d1c2", "1. Nf3 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. O-O O-O 6. d4 Nbd7 7. Qc2"),
  new FullOpening("E08", "Catalan Opening: Closed, Spassky Gambit", "r2q1rk1/pb1nbppp/2p1pn2/1p1p4/2PP4/1PN2NP1/P1Q1PPBP/R1BR2K1 w - -", "d2d4 g8f6 c2c4 e7e6 g2g3 f8e7 f1g2 d7d5 g1f3 e8g8 e1g1 b8d7 d1c2 c7c6 b2b3 b7b6 f1d1 c8b7 b1c3 b6b5", "1. d4 Nf6 2. c4 e6 3. g3 Be7 4. Bg2 d5 5. Nf3 O-O 6. O-O Nbd7 7. Qc2 c6 8. b3 b6 9. Rd1 Bb7 10. Nc3 b5"),
  new FullOpening("E08", "Catalan Opening: Closed, Zagoryansky Variation", "r1bq1rk1/p2nbppp/1pp1pn2/3p4/P1PP4/5NP1/1PQ1PPBP/RNBR2K1 b - -", "d2d4 e7e6 c2c4 g8f6 g1f3 d7d5 g2g3 f8e7 f1g2 e8g8 e1g1 b8d7 d1c2 c7c6 f1d1 b7b6 a2a4", "1. d4 e6 2. c4 Nf6 3. Nf3 d5 4. g3 Be7 5. Bg2 O-O 6. O-O Nbd7 7. Qc2 c6 8. Rd1 b6 9. a4"),
  new FullOpening("E09", "Catalan Opening: Closed Variation, Rabinovich Variation", "r1bq1rk1/p2nbppp/2p1pn2/1p1p4/2PP4/5NP1/PPQNPPBP/R1B2RK1 w - -", "d2d4 d7d5 c2c4 c7c6 g1f3 g8f6 d1c2 e7e6 b1d2 f8e7 g2g3 b8d7 f1g2 e8g8 e1g1 b7b5", "1. d4 d5 2. c4 c6 3. Nf3 Nf6 4. Qc2 e6 5. Nbd2 Be7 6. g3 Nbd7 7. Bg2 O-O 8. O-O b5"),
  new FullOpening("E09", "Catalan Opening: Closed Variation, Traditional Variation", "r1bq1rk1/p2nbppp/1pp1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 g2g3 f8e7 f1g2 e8g8 e1g1 b8d7 d1c2 c7c6 b1d2 b7b6", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. g3 Be7 5. Bg2 O-O 6. O-O Nbd7 7. Qc2 c6 8. Nbd2 b6"),
  new FullOpening("E09", "Catalan Opening: Closed, Main Line", "r1bq1rk1/pp1nbppp/2p1pn2/3p4/2PP4/5NP1/PPQNPPBP/R1B2RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 d7d5 g2g3 f8e7 f1g2 e8g8 e1g1 b8d7 d1c2 c7c6 b1d2", "1. d4 Nf6 2. c4 e6 3. Nf3 d5 4. g3 Be7 5. Bg2 O-O 6. O-O Nbd7 7. Qc2 c6 8. Nbd2"),
  new FullOpening("E09", "Catalan Opening: Closed, Sokolsky Variation", "r2q1rk1/3nbppp/bpp1pn2/p2p4/2PP4/1P3NP1/PBQNPPBP/R4RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g2g3 d7d5 f1g2 f8e7 g1f3 e8g8 e1g1 b8d7 d1c2 c7c6 b1d2 b7b6 b2b3 a7a5 c1b2 c8a6", "1. d4 Nf6 2. c4 e6 3. g3 d5 4. Bg2 Be7 5. Nf3 O-O 6. O-O Nbd7 7. Qc2 c6 8. Nbd2 b6 9. b3 a5 10. Bb2 Ba6"),
  new FullOpening("E10", "Blumenfeld Countergambit", "rnbqkb1r/pp1p1ppp/4pn2/2p5/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5", "1. d4 Nf6 2. c4 e6 3. Nf3 c5"),
  new FullOpening("E10", "Blumenfeld Countergambit", "rnbqkb1r/p2p1ppp/4pn2/1ppP4/2P5/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 b7b5", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5"),
  new FullOpening("E10", "Blumenfeld Countergambit Accepted", "rnbqkb1r/p5pp/4pn2/1Ppp4/8/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 b7b5 d5e6 f7e6 c4b5 d7d5", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5 5. dxe6 fxe6 6. cxb5 d5"),
  new FullOpening("E10", "Blumenfeld Countergambit: Dus-Khotimirsky Variation", "rnbqkb1r/p2p1ppp/4pn2/1ppP2B1/2P5/5N2/PP2PPPP/RN1QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 d4d5 b7b5 c1g5", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. d5 b5 5. Bg5"),
  new FullOpening("E10", "Blumenfeld Countergambit: Spielmann Variation", "rnbqkb1r/p2p1pp1/5n1p/1ppP2B1/8/5N2/PP2PPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 g1f3 e7e6 c2c4 c7c5 d4d5 b7b5 c1g5 e6d5 c4d5 h7h6", "1. d4 Nf6 2. Nf3 e6 3. c4 c5 4. d5 b5 5. Bg5 exd5 6. cxd5 h6"),
  new FullOpening("E10", "Indian Defense: Anti-Nimzo-Indian", "rnbqkb1r/pppp1ppp/4pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3", "1. d4 Nf6 2. c4 e6 3. Nf3"),
  new FullOpening("E10", "Indian Defense: Dzindzi-Indian Defense", "rnbqkb1r/1ppp1ppp/p3pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 a7a6", "1. d4 Nf6 2. c4 e6 3. Nf3 a6"),
  new FullOpening("E10", "Indian Defense: Döry Indian", "rnbqkb1r/pppp1ppp/4p3/8/2PPn3/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f6e4", "1. d4 Nf6 2. c4 e6 3. Nf3 Ne4"),
  new FullOpening("E10", "Tarrasch Defense: Symmetrical Variation", "r1bqkb1r/pp3ppp/2n1pn2/2pp4/2PP4/2N1PN2/PP3PPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 c7c5 e2e3 d7d5 b1c3 b8c6", "1. d4 Nf6 2. c4 e6 3. Nf3 c5 4. e3 d5 5. Nc3 Nc6"),
  new FullOpening("E11", "Bogo-Indian Defense", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+"),
  new FullOpening("E11", "Bogo-Indian Defense: Exchange Variation", "rnbqk2r/pppp1ppp/4pn2/8/2PP4/5N2/PP1bPPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 c1d2 b4d2", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Bxd2+"),
  new FullOpening("E11", "Bogo-Indian Defense: Grünfeld Variation", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/5N2/PP1NPPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 b1d2", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nbd2"),
  new FullOpening("E11", "Bogo-Indian Defense: Haiti Variation", "r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 c1d2 b8c6", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Nc6"),
  new FullOpening("E11", "Bogo-Indian Defense: Monticelli Trap", "rn1q1rk1/pbpp1ppp/1p2p3/6N1/2PP4/2n3P1/PPQ1PPBP/R3K2R b KQ -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8b4 c1d2 b4d2 d1d2 e8g8 b1c3 f6e4 d2c2 e4c3 f3g5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Bb4+ 6. Bd2 Bxd2+ 7. Qxd2 O-O 8. Nc3 Ne4 9. Qc2 Nxc3 10. Ng5"),
  new FullOpening("E11", "Bogo-Indian Defense: New England Variation", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/8/PP1NPPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 f3d2", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nfd2"),
  new FullOpening("E11", "Bogo-Indian Defense: Nimzowitsch Variation", "rnb1k2r/ppppqppp/4pn2/8/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 c1d2 d8e7", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Qe7"),
  new FullOpening("E11", "Bogo-Indian Defense: Retreat Variation", "rnbqk2r/ppppbppp/4pn2/8/2PP4/5N2/PP1BPPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 c1d2 b4e7", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 Be7"),
  new FullOpening("E11", "Bogo-Indian Defense: Vitolins Variation", "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 c1d2 c7c5", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 c5"),
  new FullOpening("E11", "Bogo-Indian Defense: Wade-Smyslov Variation", "rnbqk2r/1ppp1ppp/4pn2/p7/1bPP4/5N2/PP1BPPPP/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 c1d2 a7a5", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 a5"),
  new FullOpening("E12", "Nimzo-Indian Defense: Three Knights Variation, Duchamp Variation", "rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 b1c3 f8b4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4"),
  new FullOpening("E12", "Nimzo-Indian Defense: Three Knights Variation, Duchamp Variation, Modern Line", "rn1qk2r/pbpp1ppp/1p2pn2/6B1/1bPP4/2N5/PP1NPPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 b1c3 f8b4 c1g5 c8b7 f3d2", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4 5. Bg5 Bb7 6. Nd2"),
  new FullOpening("E12", "Queen's Indian Defense", "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6", "1. d4 Nf6 2. c4 e6 3. Nf3 b6"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov Variation", "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 b1c3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov Variation, Botvinnik Attack", "rn1qkb1r/pbpp1p2/1p2p2p/6pn/2PP4/2N2NB1/PP2PPPP/R2QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 b1c3 c8b7 c1g5 h7h6 g5h4 g7g5 h4g3 f6h5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb7 5. Bg5 h6 6. Bh4 g5 7. Bg3 Nh5"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation", "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Andersson Variation", "rn1qkb1r/pbpp1ppp/1p2p3/8/2PPn3/P1N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 f6e4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 Ne4"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Classical Variation", "rn1qkb1r/pbp2ppp/1p3n2/3p4/3P4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 d7d5 c4d5 e6d5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 exd5"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Hedgehog Variation", "rn1qkb1r/pbpp1p1p/1p2pnp1/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 g7g6", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 g6"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Kasparov Attack", "rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1PQ1PPPP/R1B1KB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 d7d5 c4d5 f6d5 d1c2", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 Nxd5 7. Qc2"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Main Line", "rn1qkb1r/pbp2ppp/1p2pn2/3p4/2PP4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 d7d5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Marco Defense", "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 f8e7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 Be7"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Modern Variation", "rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 d7d5 c4d5 f6d5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 Nxd5"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Petrosian Attack", "rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N1PN2/1P3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 d7d5 c4d5 f6d5 e2e3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 Nxd5 7. e3"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Polovodin Gambit", "rn1qkb1r/pbp2ppp/1p2p3/3n4/3PP3/P1N2N2/1P3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 b1c3 c8b7 a2a3 d7d5 c4d5 f6d5 e2e4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb7 5. a3 d5 6. cxd5 Nxd5 7. e4"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Rashkovsky Attack", "rn1qkb1r/pbp2ppp/1p2p3/3n4/Q2P4/P1N2N2/1P2PPPP/R1B1KB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 d7d5 c4d5 f6d5 d1a4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 Nxd5 7. Qa4+"),
  new FullOpening("E12", "Queen's Indian Defense: Kasparov-Petrosian Variation, Romanishin Attack", "rn1qkb1r/pbp2ppp/1p2p3/3n4/3P4/P1N2N2/1P1BPPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8b7 b1c3 d7d5 c4d5 f6d5 c1d2", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Bb7 5. Nc3 d5 6. cxd5 Nxd5 7. Bd2"),
  new FullOpening("E12", "Queen's Indian Defense: Miles Variation", "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP1B2/5N2/PP2PPPP/RN1QKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 c1f4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Bf4"),
  new FullOpening("E12", "Queen's Indian Defense: Petrosian Variation", "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/P4N2/1P2PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3"),
  new FullOpening("E12", "Queen's Indian Defense: Petrosian Variation, Farago Defense", "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/P4N2/1PQ1PPPP/RNB1KB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 a2a3 c8a6 d1c2 a6b7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. a3 Ba6 5. Qc2 Bb7"),
  new FullOpening("E13", "Queen's Indian Defense: Kasparov Variation", "rn1qk2r/pbpp1pp1/1p2pn1p/8/1bPP3B/2N2N2/PP2PPPP/R2QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 b1c3 f8b4 c1g5 h7h6 g5h4 c8b7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. Nc3 Bb4 5. Bg5 h6 6. Bh4 Bb7"),
  new FullOpening("E14", "Queen's Indian Defense: Averbakh Variation", "rn1q1rk1/pb1pbppp/1p2pn2/8/2PN4/1P1BP3/PB3PPP/RN1Q1RK1 b - -", "g1f3 c7c5 b2b3 g8f6 c1b2 e7e6 e2e3 f8e7 d2d4 e8g8 f1d3 b7b6 e1g1 c8b7 c2c4 c5d4 f3d4", "1. Nf3 c5 2. b3 Nf6 3. Bb2 e6 4. e3 Be7 5. d4 O-O 6. Bd3 b6 7. O-O Bb7 8. c4 cxd4 9. Nxd4"),
  new FullOpening("E14", "Queen's Indian Defense: Spassky System", "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/4PN2/PP3PPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 e2e3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. e3"),
  new FullOpening("E15", "Queen's Indian Defense: Buerger Variation", "rn1qkb1r/pb1p1ppp/1p3n2/2pp2N1/2P5/6P1/PP2PPBP/RNBQK2R b KQkq -", "g1f3 g8f6 c2c4 b7b6 d2d4 e7e6 g2g3 c8b7 f1g2 c7c5 d4d5 e6d5 f3g5", "1. Nf3 Nf6 2. c4 b6 3. d4 e6 4. g3 Bb7 5. Bg2 c5 6. d5 exd5 7. Ng5"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Traditional", "rn1qkb1r/pbpp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation", "rnbqkb1r/p1pp1ppp/1p2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Check Variation", "rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/1P3NP1/P3PP1P/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8a6 b2b3 f8b4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Check Variation, Intermezzo Line", "rn1qk2r/p1ppbppp/bp2pn2/8/2PP4/1P3NP1/P2BPP1P/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8a6 b2b3 f8b4 c1d2 b4e7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+ 6. Bd2 Be7"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Check Variation, Modern Line", "rn2k2r/p1ppqppp/bp2pn2/8/1bPP4/1P3NP1/P2BPP1P/RN1QKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8a6 b2b3 f8b4 c1d2 d8e7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3 Bb4+ 6. Bd2 Qe7"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation", "rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/5NP1/PP2PP1P/RNBQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8a6", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Nimzowitsch Attack", "rn1qkb1r/p1pp1ppp/bp2pn2/8/Q1PP4/5NP1/PP2PP1P/RNB1KB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8a6 d1a4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. Qa4"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Quiet Line", "rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8a6 b2b3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. b3"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Nimzowitsch Variation, Timman's Line", "rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1Q3NP1/PP2PP1P/RNB1KB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8a6 d1b3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Ba6 5. Qb3"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Rubinstein Variation", "rn1qkb1r/pb1p1ppp/1p3n2/2pp4/2P4N/6P1/PP2PPBP/RNBQK2R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 c7c5 d4d5 e6d5 f3h4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 c5 6. d5 exd5 7. Nh4"),
  new FullOpening("E15", "Queen's Indian Defense: Fianchetto Variation, Sämisch Variation", "rn1qkb1r/pb1p1ppp/1p2pn2/2p5/2PP4/5NP1/PP2PPBP/RNBQK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 c7c5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 c5"),
  new FullOpening("E16", "Queen's Indian Defense: Capablanca Variation", "rn1qk2r/pbpp1ppp/1p2pn2/8/1bPP4/5NP1/PP2PPBP/RNBQK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8b4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Bb4+"),
  new FullOpening("E16", "Queen's Indian Defense: Riumin Variation", "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP1BPPBP/RN1QK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8b4 c1d2 b4e7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Bb4+ 6. Bd2 Be7"),
  new FullOpening("E16", "Queen's Indian Defense: Yates Variation", "rn1qk2r/1bpp1ppp/1p2pn2/p7/1bPP4/5NP1/PP1BPPBP/RN1QK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 c1d2 a7a5 g2g3 b7b6 f1g2 c8b7", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Bd2 a5 5. g3 b6 6. Bg2 Bb7"),
  new FullOpening("E17", "Queen's Indian Defense: Anti-Queen's Indian System", "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 b1c3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. Nc3"),
  new FullOpening("E17", "Queen's Indian Defense: Classical Variation", "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQ1RK1 b kq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O"),
  new FullOpening("E17", "Queen's Indian Defense: Classical Variation, Polugaevsky Gambit", "rn1q1rk1/pbppbppp/1p3n2/3p4/2P4N/6P1/PP2PPBP/RNBQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1 e8g8 d4d5 e6d5 f3h4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O O-O 7. d5 exd5 8. Nh4"),
  new FullOpening("E17", "Queen's Indian Defense: Classical Variation, Taimanov Gambit", "rn1q1rk1/pbppbppp/1p3n2/3p4/2PN4/6P1/PP2PPBP/RNBQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1 e8g8 d4d5 e6d5 f3d4", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O O-O 7. d5 exd5 8. Nd4"),
  new FullOpening("E17", "Queen's Indian Defense: Euwe Variation", "rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/1P3NP1/P3PPBP/RNBQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1 e8g8 b2b3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O O-O 7. b3"),
  new FullOpening("E17", "Queen's Indian Defense: Fianchetto Variation, Kramnik Variation", "rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQR1K1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1 e8g8 f1e1", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O O-O 7. Re1"),
  new FullOpening("E17", "Queen's Indian Defense: Opocensky Variation", "rn1qk2r/pbppbppp/1p2p3/8/2PPn3/2N2NP1/PP1BPPBP/R2QK2R b KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 b1c3 f6e4 c1d2", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. Nc3 Ne4 7. Bd2"),
  new FullOpening("E17", "Queen's Indian Defense: Traditional Variation", "rn1qk2r/pbppbppp/1p2pn2/8/2PP4/5NP1/PP2PPBP/RNBQK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7"),
  new FullOpening("E18", "Queen's Indian Defense: Classical Variation, Tiviakov Defense", "r2q1rk1/pbppbppp/np2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "g1f3 g8f6 c2c4 e7e6 g2g3 b7b6 f1g2 c8b7 e1g1 f8e7 b1c3 e8g8 d2d4 b8a6", "1. Nf3 Nf6 2. c4 e6 3. g3 b6 4. Bg2 Bb7 5. O-O Be7 6. Nc3 O-O 7. d4 Na6"),
  new FullOpening("E18", "Queen's Indian Defense: Classical Variation, Traditional Variation", "rn1q1rk1/pbppbppp/1p2pn2/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1 e8g8 b1c3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O O-O 7. Nc3"),
  new FullOpening("E18", "Queen's Indian Defense: Classical Variation, Traditional Variation, Nimzowitsch Line", "rn1q1rk1/pbp1bppp/1p2pn2/3p4/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1 e8g8 b1c3 d7d5", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O O-O 7. Nc3 d5"),
  new FullOpening("E19", "Queen's Indian Defense: Classical Variation, Traditional Variation, Main Line", "rn1q1rk1/pbppbppp/1p2p3/8/2PP4/2Q2NP1/PP2PPBP/R1B2RK1 b - -", "d2d4 g8f6 c2c4 e7e6 g1f3 b7b6 g2g3 c8b7 f1g2 f8e7 e1g1 e8g8 b1c3 f6e4 d1c2 e4c3 c2c3", "1. d4 Nf6 2. c4 e6 3. Nf3 b6 4. g3 Bb7 5. Bg2 Be7 6. O-O O-O 7. Nc3 Ne4 8. Qc2 Nxc3 9. Qxc3"),
  new FullOpening("E20", "Nimzo-Indian Defense", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PP2PPPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4"),
  new FullOpening("E20", "Nimzo-Indian Defense: Kmoch Variation", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2P2/PP2P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 f2f3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. f3"),
  new FullOpening("E20", "Nimzo-Indian Defense: Mikenas Attack", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2NQ4/PP2PPPP/R1B1KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1d3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qd3"),
  new FullOpening("E20", "Nimzo-Indian Defense: Romanishin Variation", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N3P1/PP2PP1P/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 g2g3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. g3"),
  new FullOpening("E20", "Nimzo-Indian Defense: Romanishin Variation", "rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N2NP1/PP2PPBP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 g1f3 c7c5 g2g3 e8g8 f1g2", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. g3 O-O 6. Bg2"),
  new FullOpening("E20", "Nimzo-Indian Defense: Romanishin Variation, English Hybrid", "rnbq1rk1/pp3ppp/4p3/3n4/1b1N4/2N3P1/PP2PPBP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 g1f3 c7c5 g2g3 c5d4 f3d4 e8g8 f1g2 d7d5 c4d5 f6d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. g3 cxd4 6. Nxd4 O-O 7. Bg2 d5 8. cxd5 Nxd5"),
  new FullOpening("E21", "Nimzo-Indian Defense: Three Knights Variation", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 g1f3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3"),
  new FullOpening("E21", "Nimzo-Indian Defense: Three Knights Variation, Euwe Variation", "rnbqk2r/pp1p1ppp/4p3/2pP4/1bP1n3/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 g1f3 c7c5 d4d5 f6e4", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. d5 Ne4"),
  new FullOpening("E21", "Nimzo-Indian Defense: Three Knights Variation, Korchnoi Variation", "rnbqk2r/pp1p1ppp/4pn2/2pP4/1bP5/2N2N2/PP2PPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 g1f3 c7c5 d4d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Nf3 c5 5. d5"),
  new FullOpening("E21", "Nimzo-Indian Defense: Three Knights Variation, Shocron Gambit", "rnbqk2r/p2p1ppp/4pn2/1ppP4/1bP5/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 g1f3 f8b4 b1c3 c7c5 d4d5 b7b5", "1. d4 Nf6 2. c4 e6 3. Nf3 Bb4+ 4. Nc3 c5 5. d5 b5"),
  new FullOpening("E22", "Nimzo-Indian Defense: Spielmann Variation", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/1QN5/PP2PPPP/R1B1KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1b3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3"),
  new FullOpening("E23", "Nimzo-Indian Defense: Spielmann Variation, Karlsbad Variation", "r1bqk2r/pp1p1ppp/2n1p3/2P5/1bP5/1QN2N2/PP1nPPPP/R3KB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1b3 c7c5 d4c5 b8c6 g1f3 f6e4 c1d2 e4d2", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3 c5 5. dxc5 Nc6 6. Nf3 Ne4 7. Bd2 Nxd2"),
  new FullOpening("E23", "Nimzo-Indian Defense: Spielmann Variation, Romanovsky Gambit", "r1bqk2r/pp1p1ppp/2n1pn2/2P5/1bP5/1QN5/PP2PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1b3 c7c5 d4c5 b8c6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3 c5 5. dxc5 Nc6"),
  new FullOpening("E23", "Nimzo-Indian Defense: Spielmann Variation, Stahlberg Variation", "r1bqk2r/pp1p1ppp/2n1p3/2n5/1bP5/1QN2N2/PP1BPPPP/R3KB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1b3 c7c5 d4c5 b8c6 g1f3 f6e4 c1d2 e4c5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3 c5 5. dxc5 Nc6 6. Nf3 Ne4 7. Bd2 Nxc5"),
  new FullOpening("E23", "Nimzo-Indian Defense: Spielmann Variation, Stahlberg Variation", "r1bqk2r/pp1p2pp/2n1p3/2n2p2/1bP5/2N2NP1/PPQBPP1P/R3KB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1b3 c7c5 d4c5 b8c6 g1f3 f6e4 c1d2 e4c5 b3c2 f7f5 g2g3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qb3 c5 5. dxc5 Nc6 6. Nf3 Ne4 7. Bd2 Nxc5 8. Qc2 f5 9. g3"),
  new FullOpening("E24", "Nimzo-Indian Defense: Sämisch Variation, Accelerated", "rnbqk2r/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 a2a3 b4c3 b2c3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3"),
  new FullOpening("E24", "Nimzo-Indian Defense: Sämisch Variation, Botvinnik Variation", "rnbq1rk1/pp3ppp/4p3/2pn4/3P4/P1P1PP2/6PP/R1BQKBNR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 f2f3 d7d5 a2a3 b4c3 b2c3 c7c5 e2e3 e8g8 c4d5 f6d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. f3 d5 5. a3 Bxc3+ 6. bxc3 c5 7. e3 O-O 8. cxd5 Nxd5"),
  new FullOpening("E25", "Nimzo-Indian Defense: Sämisch Variation", "rnbqk2r/pp3ppp/4pn2/2pP4/3P4/P1P2P2/4P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 f2f3 d7d5 a2a3 b4c3 b2c3 c7c5 c4d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. f3 d5 5. a3 Bxc3+ 6. bxc3 c5 7. cxd5"),
  new FullOpening("E25", "Nimzo-Indian Defense: Sämisch Variation, Keres Variation", "rnbqk2r/pp3ppp/4p3/2Pn4/8/P1P2P2/4P1PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 f2f3 d7d5 a2a3 b4c3 b2c3 c7c5 c4d5 f6d5 d4c5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. f3 d5 5. a3 Bxc3+ 6. bxc3 c5 7. cxd5 Nxd5 8. dxc5"),
  new FullOpening("E25", "Nimzo-Indian Defense: Sämisch Variation, Romanovsky Variation", "rnbqk2r/pp4pp/4p3/2Pn1p2/8/P1P2P2/4P1PP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 f2f3 d7d5 a2a3 b4c3 b2c3 c7c5 c4d5 f6d5 d4c5 f7f5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. f3 d5 5. a3 Bxc3+ 6. bxc3 c5 7. cxd5 Nxd5 8. dxc5 f5"),
  new FullOpening("E26", "Nimzo-Indian Defense: Sämisch Variation", "rnbqk2r/pp1p1ppp/4pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 a2a3 b4c3 b2c3 c7c5 e2e3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 c5 6. e3"),
  new FullOpening("E26", "Nimzo-Indian Defense: Sämisch Variation, O'Kelly Variation", "rnbqk2r/p2p1ppp/1p2pn2/2p5/2PP4/P1P1P3/5PPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 a2a3 b4c3 b2c3 c7c5 e2e3 b7b6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 c5 6. e3 b6"),
  new FullOpening("E27", "Nimzo-Indian Defense: Sämisch Variation", "rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P5/4PPPP/R1BQKBNR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 a2a3 b4c3 b2c3 e8g8", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. a3 Bxc3+ 5. bxc3 O-O"),
  new FullOpening("E28", "Nimzo-Indian Defense: Sämisch Variation", "rnbq1rk1/pppp1ppp/4pn2/8/2PP4/P1P1P3/5PPP/R1BQKBNR b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 a2a3 b4c3 b2c3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. a3 Bxc3+ 6. bxc3"),
  new FullOpening("E29", "Nimzo-Indian Defense: Sämisch Variation", "r1bq1rk1/pp1p1ppp/2n1pn2/2p5/2PP4/P1PBP3/5PPP/R1BQK1NR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5 f1d3 b8c6 a2a3 b4c3 b2c3 e8g8", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Bd3 Nc6 6. a3 Bxc3+ 7. bxc3 O-O"),
  new FullOpening("E29", "Nimzo-Indian Defense: Sämisch Variation, Capablanca Variation", "r1bqnrk1/p2p1ppp/1pn1p3/2p5/2PPP3/P1PB4/4NPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5 f1d3 b8c6 a2a3 b4c3 b2c3 e8g8 g1e2 b7b6 e3e4 f6e8", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Bd3 Nc6 6. a3 Bxc3+ 7. bxc3 O-O 8. Ne2 b6 9. e4 Ne8"),
  new FullOpening("E30", "Nimzo-Indian Defense: Leningrad Variation", "rnbqk2r/pppp1ppp/4pn2/6B1/1bPP4/2N5/PP2PPPP/R2QKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 c1g5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5"),
  new FullOpening("E30", "Nimzo-Indian Defense: Leningrad Variation, Averbakh Gambit", "rnbqk2r/p2p1pp1/4pn1p/1ppP4/1bP4B/2N5/PP2PPPP/R2QKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 c1g5 h7h6 g5h4 c7c5 d4d5 b7b5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5 h6 5. Bh4 c5 6. d5 b5"),
  new FullOpening("E31", "Nimzo-Indian Defense: Leningrad Variation, Benoni Defense", "rnbqk2r/pp3pp1/3ppn1p/2pP4/1bP4B/2N5/PP2PPPP/R2QKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 c1g5 h7h6 g5h4 c7c5 d4d5 d7d6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Bg5 h6 5. Bh4 c5 6. d5 d6"),
  new FullOpening("E32", "Nimzo-Indian Defense: Classical Variation", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2"),
  new FullOpening("E32", "Nimzo-Indian Defense: Classical Variation, Keres Defense", "rnbq1rk1/p1pp1ppp/1p2pn2/8/2PP4/P1Q5/1P2PPPP/R1B1KBNR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 e8g8 a2a3 b4c3 c2c3 b7b6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 O-O 5. a3 Bxc3+ 6. Qxc3 b6"),
  new FullOpening("E32", "Nimzo-Indian Defense: Classical Variation, Vitolins-Adorjan Gambit", "rnbq1rk1/p1pp1ppp/4pn2/1p6/2PP4/P1Q5/1P2PPPP/R1B1KBNR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 e8g8 a2a3 b4c3 c2c3 b7b5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 O-O 5. a3 Bxc3+ 6. Qxc3 b5"),
  new FullOpening("E33", "Nimzo-Indian Defense: Classical Variation, Milner-Barry Variation", "r1bqk2r/ppp2ppp/2nppn2/8/1bPP4/2N2N2/PPQ1PPPP/R1B1KB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 b8c6 g1f3 d7d6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 Nc6 5. Nf3 d6"),
  new FullOpening("E33", "Nimzo-Indian Defense: Classical Variation, Zurich Variation", "r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N5/PPQ1PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 b8c6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 Nc6"),
  new FullOpening("E34", "Nimzo-Indian Defense: Classical Variation, Belyavsky Gambit", "rnb1k2r/ppp2ppp/5n2/4pq2/1b1P4/2N2N2/PP2PPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5 c4d5 d8d5 g1f3 d5f5 c2d1 e6e5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. cxd5 Qxd5 6. Nf3 Qf5 7. Qd1 e5"),
  new FullOpening("E34", "Nimzo-Indian Defense: Classical Variation, Noa Variation", "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/2N5/PPQ1PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5"),
  new FullOpening("E35", "Nimzo-Indian Defense: Classical Variation, Noa Variation", "rnbqk2r/ppp2ppp/5n2/3p4/1b1P4/2N5/PPQ1PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5 c4d5 e6d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. cxd5 exd5"),
  new FullOpening("E36", "Nimzo-Indian Defense: Classical Variation, Noa Variation", "rnbqk2r/ppp2ppp/4pn2/3p4/1bPP4/P1N5/1PQ1PPPP/R1B1KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5 a2a3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3"),
  new FullOpening("E36", "Nimzo-Indian Defense: Classical Variation, Noa Variation, Botvinnik Variation", "r1bqk2r/ppp2ppp/2n1pn2/3p4/2PP4/P1Q5/1P2PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5 a2a3 b4c3 c2c3 b8c6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Nc6"),
  new FullOpening("E36", "Nimzo-Indian Defense: Classical Variation, Noa Variation, Main Line", "rnbqk2r/ppp2ppp/4p3/3p4/2PPn3/P1Q5/1P2PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5 a2a3 b4c3 c2c3 f6e4", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Ne4"),
  new FullOpening("E37", "Nimzo-Indian Defense: Classical Variation, Noa Variation", "rnbqk2r/ppp2ppp/4p3/3p4/2PPn3/P7/1PQ1PPPP/R1B1KBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5 a2a3 b4c3 c2c3 f6e4 c3c2", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Ne4 7. Qc2"),
  new FullOpening("E37", "Nimzo-Indian Defense: Classical Variation, Noa Variation, San Remo Variation", "r1bqk2r/ppp2ppp/2n5/3pp3/2PPn3/P3P3/1PQ2PPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 d7d5 a2a3 b4c3 c2c3 f6e4 c3c2 b8c6 e2e3 e6e5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 d5 5. a3 Bxc3+ 6. Qxc3 Ne4 7. Qc2 Nc6 8. e3 e5"),
  new FullOpening("E38", "Nimzo-Indian Defense: Classical Variation, Berlin Variation", "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N5/PPQ1PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 c7c5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5"),
  new FullOpening("E38", "Nimzo-Indian Defense: Classical Variation, Berlin Variation, Steiner Variation", "rnbqk2r/pp1p1ppp/4pn2/2P5/2P5/2b5/PPQ1PPPP/R1B1KBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 c7c5 d4c5 b4c3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5 5. dxc5 Bxc3+"),
  new FullOpening("E39", "Nimzo-Indian Defense: Classical Variation, Berlin Variation, Macieja System", "rnbq1rk1/p2p1ppp/1p2pn2/2b5/2P2B2/P1N2N2/1PQ1PPPP/R3KB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 c7c5 d4c5 e8g8 a2a3 b4c5 g1f3 b7b6 c1f4", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5 5. dxc5 O-O 6. a3 Bxc5 7. Nf3 b6 8. Bf4"),
  new FullOpening("E39", "Nimzo-Indian Defense: Classical Variation, Berlin Variation, Pirc Variation", "rnbq1rk1/pp1p1ppp/4pn2/2P5/1bP5/2N5/PPQ1PPPP/R1B1KBNR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 d1c2 c7c5 d4c5 e8g8", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. Qc2 c5 5. dxc5 O-O"),
  new FullOpening("E40", "Nimzo-Indian Defense: Normal Line", "rnbqk2r/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3"),
  new FullOpening("E40", "Nimzo-Indian Defense: Normal Variation, Taimanov Variation", "r1bqk2r/pppp1ppp/2n1pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 b8c6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 Nc6"),
  new FullOpening("E41", "Nimzo-Indian Defense: Hübner Variation", "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5"),
  new FullOpening("E41", "Nimzo-Indian Defense: Hübner Variation, Main Line", "r1bqk2r/pp3ppp/2nppn2/2p5/2PP4/2PBPN2/P4PPP/R1BQK2R w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5 f1d3 b8c6 g1f3 b4c3 b2c3 d7d6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Bd3 Nc6 6. Nf3 Bxc3+ 7. bxc3 d6"),
  new FullOpening("E41", "Nimzo-Indian Defense: Normal Variation, Hübner Deferred", "rnbq1rk1/pp1p1ppp/4pn2/2p5/1bPP4/2N1PN2/PP3PPP/R1BQKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5 g1f3 e8g8", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Nf3 O-O"),
  new FullOpening("E42", "Nimzo-Indian Defense: Hübner Variation, Rubinstein Variation", "rnbqk2r/pp1p1ppp/4pn2/2p5/1bPP4/2N1P3/PP2NPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5 g1e2", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Ne2"),
  new FullOpening("E42", "Nimzo-Indian Defense: Hübner Variation, Rubinstein Variation, Main Line", "rnbq1rk1/pp1p1ppp/4pn2/8/1bPP4/P1N5/1P2NPPP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5 g1e2 c5d4 e3d4 e8g8 a2a3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Ne2 cxd4 6. exd4 O-O 7. a3"),
  new FullOpening("E42", "Nimzo-Indian Defense: Hübner Variation, Rubinstein Variation, Sherbakov Attack", "rnbq1rk1/pp1p1ppp/4pn2/2P5/1b1P4/2N5/PP2NPPP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 c7c5 g1e2 c5d4 e3d4 e8g8 c4c5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 c5 5. Ne2 cxd4 6. exd4 O-O 7. c5"),
  new FullOpening("E43", "Nimzo-Indian Defense: St. Petersburg Variation", "rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 b7b6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6"),
  new FullOpening("E44", "Nimzo-Indian Defense: Fischer Variation", "rnbqk2r/p1pp1ppp/1p2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 b7b6 g1e2", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6 5. Ne2"),
  new FullOpening("E45", "Nimzo-Indian Defense: Normal Variation, Bronstein (Byrne) Variation", "rn1qk2r/p1pp1ppp/bp2pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R w KQkq -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 b7b6 g1e2 c8a6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 b6 5. Ne2 Ba6"),
  new FullOpening("E46", "Nimzo-Indian Defense: Normal Variation", "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP3PPP/R1BQKBNR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O"),
  new FullOpening("E46", "Nimzo-Indian Defense: Reshevsky Variation", "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1P3/PP2NPPP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 g1e2", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Ne2"),
  new FullOpening("E46", "Nimzo-Indian Defense: Simagin Variation", "rnbq1rk1/ppp2ppp/3bpn2/3p4/2PP4/P1N1P3/1P2NPPP/R1BQKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 g1e2 d7d5 a2a3 b4d6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Ne2 d5 6. a3 Bd6"),
  new FullOpening("E47", "Nimzo-Indian Defense: Normal Variation, Bishop Attack", "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2NBP3/PP3PPP/R1BQK1NR b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3"),
  new FullOpening("E48", "Nimzo-Indian Defense: Normal Variation, Bishop Attack, Classical Defense", "rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2NBP3/PP3PPP/R1BQK1NR w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5"),
  new FullOpening("E48", "Nimzo-Indian Defense: Ragozin Defense", "r1bq1rk1/ppp2ppp/2n1pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 b8c6 e1g1", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 Nc6 7. O-O"),
  new FullOpening("E49", "Nimzo-Indian Defense: Normal Variation, Botvinnik System", "rnbq1rk1/ppp2ppp/4pn2/3p4/2PP4/P1PBP3/5PPP/R1BQK1NR b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 a2a3 b4c3 b2c3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. a3 Bxc3+ 7. bxc3"),
  new FullOpening("E50", "Nimzo-Indian Defense", "rnbq1rk1/pppp1ppp/4pn2/8/1bPP4/2N1PN2/PP3PPP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 g1f3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3"),
  new FullOpening("E51", "Nimzo-Indian Defense: Normal Variation, Ragozin Variation", "rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/2N1PN2/PP3PPP/R1BQKB1R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 g1f3 d7d5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3 d5"),
  new FullOpening("E51", "Nimzo-Indian Defense: Normal Variation, Sämisch Deferred", "rnbq1rk1/ppp2ppp/4pn2/3p4/1bPP4/P1N1PN2/1P3PPP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 g1f3 d7d5 a2a3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Nf3 d5 6. a3"),
  new FullOpening("E51", "Nimzo-Indian Defense: Ragozin Variation", "r1bq1rk1/ppp2ppp/2n1pn2/8/1bpP4/2NBPN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 b8c6 e1g1 d5c4", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 Nc6 7. O-O dxc4"),
  new FullOpening("E52", "Nimzo-Indian Defense: Normal Variation, Schlechter Defense", "rnbq1rk1/p1p2ppp/1p2pn2/3p4/1bPP4/2NBPN2/PP3PPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 b7b6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 b6"),
  new FullOpening("E53", "Nimzo-Indian Defense: Normal Variation, Gligoric System", "rnbq1rk1/pp3ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5"),
  new FullOpening("E53", "Nimzo-Indian Defense: Normal Variation, Gligoric System", "r1bq1rk1/pp1n1ppp/4pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 b8d7", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O Nbd7"),
  new FullOpening("E53", "Nimzo-Indian Defense: Normal Variation, Gligoric System, Keres Variation", "rnbq1rk1/p4ppp/1p2pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 b7b6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O b6"),
  new FullOpening("E54", "Nimzo-Indian Defense: Normal Variation, Gligoric System, Exchange at c4", "rnbq1rk1/pp3ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 d5c4 d3c4", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O dxc4 8. Bxc4"),
  new FullOpening("E54", "Nimzo-Indian Defense: Normal Variation, Gligoric System, Smyslov Variation", "rnb2rk1/pp2qppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 d5c4 d3c4 d8e7", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O dxc4 8. Bxc4 Qe7"),
  new FullOpening("E55", "Nimzo-Indian Defense: Normal Variation, Gligoric System, Bronstein Variation", "r1bq1rk1/pp1n1ppp/4pn2/2p5/1bBP4/2N1PN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 d5c4 d3c4 b8d7", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O dxc4 8. Bxc4 Nbd7"),
  new FullOpening("E56", "Nimzo-Indian Defense: Normal Variation, Gligoric System, Bernstein Defense", "r1bq1rk1/pp3ppp/2n1pn2/2pp4/1bPP4/2NBPN2/PP3PPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 b8c6", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O Nc6"),
  new FullOpening("E58", "Nimzo-Indian Defense: Normal Variation, Bernstein Defense, Exchange Line", "r1bq1rk1/pp3ppp/2n1pn2/2pp4/2PP4/P1PBPN2/5PPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 b8c6 a2a3 b4c3 b2c3", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O Nc6 8. a3 Bxc3 9. bxc3"),
  new FullOpening("E59", "Nimzo-Indian Defense: Normal Variation, Bernstein Defense", "r1bq1rk1/pp3ppp/2n1pn2/2p5/2BP4/P1P1PN2/5PPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 e7e6 b1c3 f8b4 e2e3 e8g8 f1d3 d7d5 g1f3 c7c5 e1g1 b8c6 a2a3 b4c3 b2c3 d5c4 d3c4", "1. d4 Nf6 2. c4 e6 3. Nc3 Bb4 4. e3 O-O 5. Bd3 d5 6. Nf3 c5 7. O-O Nc6 8. a3 Bxc3 9. bxc3 dxc4 10. Bxc4"),
  new FullOpening("E60", "Grünfeld Defense: Counterthrust Variation", "rnbqk2r/ppp1ppbp/5np1/3p4/2PP4/6P1/PP2PPBP/RNBQK1NR w KQkq -", "d2d4 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 d7d5", "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 d5"),
  new FullOpening("E60", "Indian Defense: Anti-Grünfeld, Adorjan Gambit", "rnbqkb1r/p1pppp1p/5np1/1p1P4/2P5/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 d4d5 b7b5", "1. d4 Nf6 2. c4 g6 3. d5 b5"),
  new FullOpening("E60", "Indian Defense: Anti-Grünfeld, Advance Variation", "rnbqkb1r/pppppp1p/5np1/3P4/2P5/8/PP2PPPP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 d4d5", "1. d4 Nf6 2. c4 g6 3. d5"),
  new FullOpening("E60", "Indian Defense: Anti-Grünfeld, Alekhine Variation", "rnbqkb1r/pppppp1p/5np1/8/2PP4/5P2/PP2P1PP/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 f2f3", "1. d4 Nf6 2. c4 g6 3. f3"),
  new FullOpening("E60", "Indian Defense: Anti-Grünfeld, Alekhine Variation, Leko Gambit", "rnbqkb1r/pppp1p1p/5np1/4p3/2PP4/5P2/PP2P1PP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 f2f3 e7e5", "1. d4 Nf6 2. c4 g6 3. f3 e5"),
  new FullOpening("E60", "Indian Defense: King's Indian Variation, Fianchetto Variation", "rnbqk2r/ppppppbp/5np1/8/2PP4/6P1/PP2PPBP/RNBQK1NR b KQkq -", "d2d4 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2", "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2"),
  new FullOpening("E60", "Indian Defense: West Indian Defense", "rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PP2PPPP/RNBQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6", "1. d4 Nf6 2. c4 g6"),
  new FullOpening("E60", "King's Indian Defense: Fianchetto Variation, Immediate Fianchetto", "rnbqkb1r/pppppp1p/5np1/8/2PP4/6P1/PP2PP1P/RNBQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 g2g3", "1. d4 Nf6 2. c4 g6 3. g3"),
  new FullOpening("E60", "King's Indian Defense: Fianchetto Variation, Pterodactyl Variation", "rnb1k2r/pp1pppbp/5np1/q1p5/2PP4/5NP1/PP2PPBP/RNBQK2R w KQkq -", "d2d4 g8f6 g1f3 g7g6 c2c4 f8g7 g2g3 c7c5 f1g2 d8a5", "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. g3 c5 5. Bg2 Qa5+"),
  new FullOpening("E60", "King's Indian Defense: Fianchetto Variation, Yugoslav System", "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/5NP1/PP2PPBP/RNBQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 c7c5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O c5"),
  new FullOpening("E60", "King's Indian Defense: Normal Variation, King's Knight Variation", "rnbqkb1r/pppppp1p/5np1/8/2PP4/5N2/PP2PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 g1f3 g7g6 c2c4", "1. d4 Nf6 2. Nf3 g6 3. c4"),
  new FullOpening("E60", "King's Indian Defense: Santasiere Variation", "rnbqk2r/ppppppbp/5np1/8/1PPP4/5N2/P3PPPP/RNBQKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 b2b4", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. b4"),
  new FullOpening("E60", "King's Indian Defense: Semi-Classical Variation, Benoni Variation", "rnbq1rk1/pp2ppbp/2pp1np1/8/2PP4/2N1PN2/PP2BPPP/R1BQK2R w KQ -", "c2c4 g8f6 g1f3 g7g6 d2d4 f8g7 b1c3 e8g8 e2e3 d7d6 f1e2 c7c6", "1. c4 Nf6 2. Nf3 g6 3. d4 Bg7 4. Nc3 O-O 5. e3 d6 6. Be2 c6"),
  new FullOpening("E60", "King's Indian Defense: Semi-Classical Variation, Hollywood Variation", "r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N1PN2/PP2BPPP/R1BQK2R w KQ -", "d2d4 g8f6 g1f3 g7g6 c2c4 f8g7 b1c3 e8g8 e2e3 d7d6 f1e2 b8c6", "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. Nc3 O-O 5. e3 d6 6. Be2 Nc6"),
  new FullOpening("E60", "Neo-Grünfeld Defense: Non- or Delayed Fianchetto", "rnbqkb1r/ppp1pp1p/5np1/3p4/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq -", "d2d4 g8f6 g1f3 g7g6 c2c4 d7d5", "1. d4 Nf6 2. Nf3 g6 3. c4 d5"),
  new FullOpening("E60", "Queen's Pawn, Mengarini Attack", "rnbqkb1r/pppppp1p/5np1/8/2PP4/8/PPQ1PPPP/RNB1KBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 d1c2", "1. d4 Nf6 2. c4 g6 3. Qc2"),
  new FullOpening("E61", "King's Indian Defense", "rnbqkb1r/pppppp1p/5np1/8/2PP4/2N5/PP2PPPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3", "1. d4 Nf6 2. c4 g6 3. Nc3"),
  new FullOpening("E61", "King's Indian Defense: Fianchetto Variation, Benjamin Defense", "rnb2rk1/pp2ppbp/1qpp1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 g1f3 g7g6 g2g3 f8g7 f1g2 e8g8 e1g1 d7d6 c2c4 c7c6 b1c3 d8b6", "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d6 6. c4 c6 7. Nc3 Qb6"),
  new FullOpening("E61", "King's Indian Defense: Semi-Classical Variation", "rnbq1rk1/ppp1ppbp/3p1np1/8/2PP4/2N1PN2/PP2BPPP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 g1f3 e8g8 e2e3 d7d6 f1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 O-O 5. e3 d6 6. Be2"),
  new FullOpening("E61", "King's Indian Defense: Semi-Classical Variation, Exchange Variation", "r1bq1rk1/pppn1pbp/5np1/4p3/2P5/2N1PN2/PP2BPPP/R1BQ1RK1 w - -", "c2c4 g7g6 b1c3 f8g7 e2e3 g8f6 d2d4 e8g8 g1f3 d7d6 f1e2 b8d7 e1g1 e7e5 d4e5 d6e5", "1. c4 g6 2. Nc3 Bg7 3. e3 Nf6 4. d4 O-O 5. Nf3 d6 6. Be2 Nbd7 7. O-O e5 8. dxe5 dxe5"),
  new FullOpening("E61", "King's Indian Defense: Semi-Classical Variation, Queenside Storm Line", "r1bq1rk1/pppn1pbp/3p1np1/4p3/1PPP4/2N1PN2/P3BPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 g1f3 e8g8 e2e3 d7d6 f1e2 b8d7 e1g1 e7e5 b2b4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 O-O 5. e3 d6 6. Be2 Nbd7 7. O-O e5 8. b4"),
  new FullOpening("E61", "King's Indian Defense: Smyslov Variation", "rnbqk2r/ppp1ppbp/3p1np1/6B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 g1f3 d7d6 c1g5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 d6 5. Bg5"),
  new FullOpening("E62", "King's Indian Defense: Fianchetto Variation, Delayed Fianchetto", "rnbqk2r/ppp1ppbp/3p1np1/8/2PP4/2N2NP1/PP2PP1P/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 g1f3 d7d6 g2g3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. Nf3 d6 5. g3"),
  new FullOpening("E62", "King's Indian Defense: Fianchetto Variation, Karlsbad Variation", "r1bq1rk1/ppp1ppbp/2np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 e8g8 b1c3 d7d6 g1f3 b8c6", "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 Nc6"),
  new FullOpening("E62", "King's Indian Defense: Fianchetto Variation, Kavalek Defense", "rnb2rk1/pp2ppbp/2pp1np1/q7/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 c7c6 b1c3 d8a5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O c6 7. Nc3 Qa5"),
  new FullOpening("E62", "King's Indian Defense: Fianchetto Variation, Larsen Defense", "rn1q1rk1/pp2ppbp/2pp1np1/5b2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 g1f3 g7g6 c2c4 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 c7c6 b1c3 c8f5", "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O c6 7. Nc3 Bf5"),
  new FullOpening("E62", "King's Indian Defense: Fianchetto Variation, Lesser Simagin (Spassky)", "r2q1rk1/ppp1ppbp/2np1np1/5b2/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 e8g8 b1c3 d7d6 g1f3 b8c6 e1g1 c8f5", "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 Nc6 7. O-O Bf5"),
  new FullOpening("E62", "King's Indian Defense: Fianchetto Variation, Simagin Variation", "r2q1rk1/ppp1ppbp/2np1np1/8/2PP2b1/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "g1f3 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 e8g8 e1g1 d7d6 d2d4 b8c6 b1c3 c8g4", "1. Nf3 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d6 6. d4 Nc6 7. Nc3 Bg4"),
  new FullOpening("E62", "King's Indian Defense: Fianchetto Variation, Uhlmann-Szabo System", "r1bq1rk1/ppp2pbp/2np1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8c6 b1c3 e7e5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nc6 7. Nc3 e5"),
  new FullOpening("E63", "King's Indian Defense: Fianchetto Variation, Panno Variation", "r1bq1rk1/1pp1ppbp/p1np1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8c6 b1c3 a7a6", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nc6 7. Nc3 a6"),
  new FullOpening("E63", "King's Indian Defense: Fianchetto Variation, Panno Variation, Blockade Line", "r1bq1rk1/1p3pbp/p2p1np1/n1pPp3/2P5/2N3P1/PPQNPPBP/R1B2RK1 w - e6", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8c6 b1c3 a7a6 d4d5 c6a5 f3d2 c7c5 d1c2 e7e5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nc6 7. Nc3 a6 8. d5 Na5 9. Nd2 c5 10. Qc2 e5"),
  new FullOpening("E63", "King's Indian Defense: Fianchetto Variation, Panno Variation, Donner Line", "1rbq1rk1/4pp1p/p2p1npb/n1pP4/2P5/2N3P1/PBQNPPBP/R4RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8c6 b1c3 a7a6 d4d5 c6a5 f3d2 c7c5 d1c2 a8b8 b2b3 b7b5 c1b2 b5c4 b3c4 g7h6", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nc6 7. Nc3 a6 8. d5 Na5 9. Nd2 c5 10. Qc2 Rb8 11. b3 b5 12. Bb2 bxc4 13. bxc4 Bh6"),
  new FullOpening("E63", "King's Indian Defense: Fianchetto Variation, Panno Variation, Korchnoi Line", "1rbq1rk1/2p1ppbp/p1np1np1/1p6/2PP4/2N1B1PP/PP1NPPB1/R2Q1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8c6 b1c3 a7a6 h2h3 a8b8 c1e3 b7b5 f3d2", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nc6 7. Nc3 a6 8. h3 Rb8 9. Be3 b5 10. Nd2"),
  new FullOpening("E64", "King's Indian Defense: Fianchetto Variation, Yugoslav Variation, Rare Line", "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 e8g8 b1c3 d7d6 g1f3 c7c5", "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 c5"),
  new FullOpening("E65", "King's Indian Defense: Fianchetto Variation, Yugoslav Variation", "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 c7c5 b1c3", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O c5 7. Nc3"),
  new FullOpening("E65", "King's Indian Defense: Fianchetto Variation, Yugoslav Variation, Exchange Line", "r1bq1rk1/pp2ppbp/2n2np1/2p5/2P5/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "g1f3 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 e8g8 e1g1 c7c5 d2d4 d7d6 b1c3 b8c6 d4c5 d6c5", "1. Nf3 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O c5 6. d4 d6 7. Nc3 Nc6 8. dxc5 dxc5"),
  new FullOpening("E66", "King's Indian Defense: Fianchetto Variation, Yugoslav Variation, Advance Line", "r1bq1rk1/pp2ppbp/2np1np1/2pP4/2P5/2N2NP1/PP2PPBP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 c7c5 b1c3 b8c6 d4d5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O c5 7. Nc3 Nc6 8. d5"),
  new FullOpening("E67", "King's Indian Defense: Fianchetto Variation, Classical Fianchetto", "r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8d7 b1c3 e7e5", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nbd7 7. Nc3 e5"),
  new FullOpening("E67", "King's Indian Defense: Fianchetto Variation, Debrecen Defense", "r1bq1rk1/pppnppbp/3p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 g2g3 f8g7 f1g2 e8g8 b1c3 d7d6 g1f3 b8d7", "1. d4 Nf6 2. c4 g6 3. g3 Bg7 4. Bg2 O-O 5. Nc3 d6 6. Nf3 Nbd7"),
  new FullOpening("E67", "King's Indian Defense: Fianchetto Variation, Double Fianchetto Attack", "r1bq1rk1/pppn1pbp/3p1np1/4p3/2PP4/1PN2NP1/P3PPBP/R1BQ1RK1 b - -", "d2d4 g8f6 g1f3 g7g6 g2g3 f8g7 f1g2 e8g8 e1g1 d7d6 c2c4 b8d7 b1c3 e7e5 b2b3", "1. d4 Nf6 2. Nf3 g6 3. g3 Bg7 4. Bg2 O-O 5. O-O d6 6. c4 Nbd7 7. Nc3 e5 8. b3"),
  new FullOpening("E67", "King's Indian Defense: Fianchetto Variation, Hungarian Variation", "r1bq1rk1/1ppnppbp/p2p1np1/8/2PP4/2N2NP1/PP2PPBP/R1BQ1RK1 w - -", "c2c4 g8f6 b1c3 g7g6 g2g3 f8g7 f1g2 e8g8 d2d4 d7d6 g1f3 b8d7 e1g1 a7a6", "1. c4 Nf6 2. Nc3 g6 3. g3 Bg7 4. Bg2 O-O 5. d4 d6 6. Nf3 Nbd7 7. O-O a6"),
  new FullOpening("E68", "King's Indian Defense: Fianchetto Variation, Classical Variation", "r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2NP1/PP3PBP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8d7 b1c3 e7e5 e2e4", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nbd7 7. Nc3 e5 8. e4"),
  new FullOpening("E68", "King's Indian Defense: Fianchetto Variation, Long Variation", "r1bqr1k1/1pp2pbp/3p1np1/p1n5/2PNP3/2N3PP/PP3PB1/R1BQR1K1 w - -", "d2d4 g8f6 g1f3 g7g6 c2c4 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8d7 b1c3 e7e5 e2e4 e5d4 f3d4 f8e8 h2h3 d7c5 f1e1 a7a5", "1. d4 Nf6 2. Nf3 g6 3. c4 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nbd7 7. Nc3 e5 8. e4 exd4 9. Nxd4 Re8 10. h3 Nc5 11. Re1 a5"),
  new FullOpening("E69", "King's Indian Defense: Fianchetto Variation, Classical Main Line", "r1bq1rk1/pp1n1pbp/2pp1np1/4p3/2PPP3/2N2NPP/PP3PB1/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 g1f3 f8g7 g2g3 e8g8 f1g2 d7d6 e1g1 b8d7 b1c3 e7e5 e2e4 c7c6 h2h3", "1. d4 Nf6 2. c4 g6 3. Nf3 Bg7 4. g3 O-O 5. Bg2 d6 6. O-O Nbd7 7. Nc3 e5 8. e4 c6 9. h3"),
  new FullOpening("E70", "King's Indian Defense: Accelerated Averbakh Variation", "rnbqk2r/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP3PPP/R2QKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 c1g5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Bg5"),
  new FullOpening("E70", "King's Indian Defense: Kramer Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2NPPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nge2"),
  new FullOpening("E70", "King's Indian Defense: Normal Variation", "rnbqk2r/ppppppbp/5np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4"),
  new FullOpening("E70", "King's Indian Defense: Normal Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP3PPP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6"),
  new FullOpening("E71", "King's Indian Defense: Makogonov Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N4P/PP3PP1/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 h2h3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. h3"),
  new FullOpening("E72", "King's Indian Defense: Normal Variation, Deferred Fianchetto", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N3P1/PP3P1P/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g2g3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. g3"),
  new FullOpening("E72", "King's Indian Defense: Pomar System", "rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N3P1/PP2NPBP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g2g3 e8g8 f1g2 e7e5 g1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. g3 O-O 6. Bg2 e5 7. Nge2"),
  new FullOpening("E73", "King's Indian Defense: Averbakh Variation", "rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5"),
  new FullOpening("E73", "King's Indian Defense: Averbakh Variation, Flexible Defense", "rnbq1rk1/ppp1ppb1/3p1npp/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 h7h6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 h6"),
  new FullOpening("E73", "King's Indian Defense: Averbakh Variation, Geller Defense", "r1bq1rk1/pppnppbp/3p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 b8d7", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Nbd7"),
  new FullOpening("E73", "King's Indian Defense: Averbakh Variation, Modern Defense", "r1bq1rk1/ppp1ppbp/n2p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 b8a6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Na6"),
  new FullOpening("E73", "King's Indian Defense: Averbakh Variation, Modern Defense, Burgess Line", "r1bq1rk1/pp2ppbp/n1pp1np1/6B1/2PPP3/2N5/PP1QBPPP/R3K1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 b8a6 d1d2 c7c6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Na6 7. Qd2 c6"),
  new FullOpening("E73", "King's Indian Defense: Averbakh Variation, Nc6 Defense", "r1bq1rk1/ppp1ppbp/2np1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 b8c6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 Nc6"),
  new FullOpening("E73", "King's Indian Defense: Averbakh Variation, Spanish Defense", "rnbq1rk1/1pp1ppbp/p2p1np1/6B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 a7a6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 a6"),
  new FullOpening("E73", "King's Indian Defense: Normal Variation, Standard Development", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N5/PP2BPPP/R1BQK1NR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2"),
  new FullOpening("E73", "King's Indian Defense: Semi-Averbakh System", "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1B3/PP2BPPP/R2QK1NR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1e3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Be3"),
  new FullOpening("E74", "King's Indian Defense: Averbakh Variation, Benoni Defense", "rnbq1rk1/pp2ppbp/3p1np1/2p3B1/2PPP3/2N5/PP2BPPP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 c7c5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 c5"),
  new FullOpening("E74", "King's Indian Defense: Averbakh Variation, Benoni Defense, Advance Variation", "rnbq1rk1/pp2ppbp/3p1np1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 c7c5 d4d5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 c5 7. d5"),
  new FullOpening("E74", "King's Indian Defense: Averbakh Variation, Benoni Defense, Exchange Variation", "rnbq1rk1/pp2ppbp/3p1np1/2P3B1/2P1P3/2N5/PP2BPPP/R2QK1NR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 c7c5 d4c5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 c5 7. dxc5"),
  new FullOpening("E75", "King's Indian Defense: Averbakh Variation, Main Line", "rnbq1rk1/pp3pbp/3ppnp1/2pP2B1/2P1P3/2N5/PP2BPPP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 c1g5 c7c5 d4d5 e7e6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. Bg5 c5 7. d5 e6"),
  new FullOpening("E76", "King's Indian Defense: Four Pawns Attack", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4"),
  new FullOpening("E76", "King's Indian Defense: Four Pawns Attack, Dynamic Attack", "rnbq1rk1/pp2ppbp/3p1np1/2pP4/2P1PP2/2N2N2/PP4PP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 e8g8 g1f3 c7c5 d4d5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 c5 7. d5"),
  new FullOpening("E76", "King's Indian Defense: Four Pawns Attack, Modern Defense", "r1bqk2r/ppp1ppbp/n2p1np1/8/2PPPP2/2N5/PP4PP/R1BQKBNR w KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 b8a6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 Na6"),
  new FullOpening("E77", "King's Indian Defense: Four Pawns Attack", "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPPP2/2N5/PP2B1PP/R1BQK1NR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f1e2 e8g8 f2f4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Be2 O-O 6. f4"),
  new FullOpening("E77", "King's Indian Defense: Four Pawns Attack, Florentine Gambit", "rnbq1rk1/pp3pbp/3p1np1/2ppP3/2P2P2/2N2N2/PP2B1PP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 e8g8 g1f3 c7c5 d4d5 e7e6 f1e2 e6d5 e4e5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 c5 7. d5 e6 8. Be2 exd5 9. e5"),
  new FullOpening("E77", "King's Indian Defense: Four Pawns Attack, Normal Attack", "rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1PP2/2N2N2/PP2B1PP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 e8g8 g1f3 c7c5 d4d5 e7e6 f1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 c5 7. d5 e6 8. Be2"),
  new FullOpening("E77", "King's Indian Defense: Six Pawns Attack", "r1bq1rk1/pp4bp/2nppnp1/2p5/2P1PPPP/2N5/PP2B3/R1BQK1NR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 e8g8 f1e2 c7c5 d4d5 e7e6 d5e6 f7e6 g2g4 b8c6 h2h4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Be2 c5 7. d5 e6 8. dxe6 fxe6 9. g4 Nc6 10. h4"),
  new FullOpening("E78", "King's Indian Defense: Four Pawns Attack, Fluid Attack", "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPPP2/2N2N2/PP2B1PP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 e8g8 g1f3 c7c5 f1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 c5 7. Be2"),
  new FullOpening("E79", "King's Indian Defense: Four Pawns Attack, Exchange Variation", "r1bq1rk1/pp2ppbp/2np1np1/8/2PNPP2/2N1B3/PP2B1PP/R2QK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f4 e8g8 g1f3 c7c5 f1e2 c5d4 f3d4 b8c6 c1e3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f4 O-O 6. Nf3 c5 7. Be2 cxd4 8. Nxd4 Nc6 9. Be3"),
  new FullOpening("E80", "King's Indian Defense: Sämisch Variation", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3"),
  new FullOpening("E81", "King's Indian Defense: Steiner Attack", "rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2P2/PP4PP/R2QKBNR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1g5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Bg5"),
  new FullOpening("E81", "King's Indian Defense: Sämisch Variation, Bobotsov-Korchnoi-Petrosian Variation", "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP2N1PP/R1BQKB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 g1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Nge2"),
  new FullOpening("E81", "King's Indian Defense: Sämisch Variation, Byrne Defense", "rnbq1rk1/1p2ppbp/p1pp1np1/8/2PPP3/2NBBP2/PP4PP/R2QK1NR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 c7c6 f1d3 a7a6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 c6 7. Bd3 a6"),
  new FullOpening("E81", "King's Indian Defense: Sämisch Variation, Normal Defense", "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2P2/PP4PP/R1BQKBNR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O"),
  new FullOpening("E81", "King's Indian Defense: Sämisch Variation, Sämisch Gambit", "rnbq1rk1/pp2ppbp/3p1np1/2p5/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 c7c5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 c5"),
  new FullOpening("E82", "King's Indian Defense: Sämisch Variation, Double Fianchetto", "rnbq1rk1/p1p1ppbp/1p1p1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 b7b6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 b6"),
  new FullOpening("E83", "King's Indian Defense: Sämisch Variation, Panno Formation", "r1bq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 b8c6 g1e2 a7a6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 Nc6 7. Nge2 a6"),
  new FullOpening("E83", "King's Indian Defense: Sämisch Variation, Ruban Variation", "1rbq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP2N1PP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 b8c6 g1e2 a8b8", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 Nc6 7. Nge2 Rb8"),
  new FullOpening("E83", "King's Indian Defense: Sämisch Variation, Yates Defense", "r1bq1rk1/ppp1ppbp/2np1np1/8/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 b8c6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 Nc6"),
  new FullOpening("E84", "King's Indian Defense: Sämisch Variation, Panno Main Line", "1rbq1rk1/1pp1ppbp/p1np1np1/8/2PPP3/2N1BP2/PP1QN1PP/R3KB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 b8c6 g1e2 a7a6 d1d2 a8b8", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 Nc6 7. Nge2 a6 8. Qd2 Rb8"),
  new FullOpening("E85", "King's Indian Defense: Sämisch Variation, Orthodox Variation", "rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BP2/PP4PP/R2QKBNR w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 e7e5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5"),
  new FullOpening("E86", "King's Indian Defense: Sämisch Variation", "rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N1BP2/PP2N1PP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 e7e5 g1e2 c7c6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5 7. Nge2 c6"),
  new FullOpening("E87", "King's Indian Defense: Sämisch Variation, Bronstein Defense", "rnb2rk1/ppp2pbp/3p2p1/3Pp3/2P1P2Q/2N1nP2/PP2K2P/R5NR b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 e7e5 d4d5 f6h5 d1d2 d8h4 g2g3 h5g3 d2f2 g3f1 f2h4 f1e3 e1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5 7. d5 Nh5 8. Qd2 Qh4+ 9. g3 Nxg3 10. Qf2 Nxf1 11. Qxh4 Nxe3 12. Ke2"),
  new FullOpening("E87", "King's Indian Defense: Sämisch Variation, Closed Variation", "rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N1BP2/PP4PP/R2QKBNR b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 e7e5 d4d5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5 7. d5"),
  new FullOpening("E87", "King's Indian Defense: Sämisch Variation, Orthodox Variation, Bronstein Variation", "rnb2rk1/ppp2pbp/3p2p1/3Pp3/2n1P2Q/2N2P2/PP2K2P/R5NR w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 e7e5 d4d5 f6h5 d1d2 d8h4 g2g3 h5g3 d2f2 g3f1 f2h4 f1e3 e1e2 e3c4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5 7. d5 Nh5 8. Qd2 Qh4+ 9. g3 Nxg3 10. Qf2 Nxf1 11. Qxh4 Nxe3 12. Ke2 Nxc4"),
  new FullOpening("E89", "King's Indian Defense: Sämisch Variation, Closed Variation, Main Line", "rnbq1rk1/pp3pbp/3p1np1/3pp3/2P1P3/2N1BP2/PP2N1PP/R2QKB1R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 f2f3 e8g8 c1e3 e7e5 g1e2 c7c6 d4d5 c6d5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. f3 O-O 6. Be3 e5 7. Nge2 c6 8. d5 cxd5"),
  new FullOpening("E90", "King's Indian Defense: Larsen Variation", "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N1BN2/PP3PPP/R2QKB1R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 c1e3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be3"),
  new FullOpening("E90", "King's Indian Defense: Normal Variation, Rare Defenses", "rnbqk2r/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP3PPP/R1BQKB1R b KQkq -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3"),
  new FullOpening("E90", "King's Indian Defense: Zinnowitz Variation", "rnbq1rk1/ppp1ppbp/3p1np1/6B1/2PPP3/2N2N2/PP3PPP/R2QKB1R b KQ -", "g1f3 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 d2d4 e8g8 c1g5", "1. Nf3 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. d4 O-O 6. Bg5"),
  new FullOpening("E91", "King's Indian Defense: Kazakh Variation", "r1bq1rk1/ppp1ppbp/n2p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 b8a6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 Na6"),
  new FullOpening("E91", "King's Indian Defense: Orthodox Variation", "rnbq1rk1/ppp1ppbp/3p1np1/8/2PPP3/2N2N2/PP2BPPP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2"),
  new FullOpening("E92", "King's Indian Defense: Exchange Variation", "rnbq1rk1/ppp2pbp/3p1np1/4P3/2P1P3/2N2N2/PP2BPPP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 d4e5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. dxe5"),
  new FullOpening("E92", "King's Indian Defense: Orthodox Variation", "rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5"),
  new FullOpening("E92", "King's Indian Defense: Orthodox Variation, Gligoric-Taimanov System", "rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N1BN2/PP2BPPP/R2QK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 c1e3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. Be3"),
  new FullOpening("E92", "King's Indian Defense: Petrosian Variation", "rnbq1rk1/ppp2pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 d4d5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. d5"),
  new FullOpening("E92", "King's Indian Defense: Petrosian Variation, Stein Defense", "rnbq1rk1/1pp2pbp/3p1np1/p2Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 d4d5 a7a5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. d5 a5"),
  new FullOpening("E93", "King's Indian Defense: Petrosian Variation, Keres Defense", "r1bq1rk1/pppn1pb1/3p3p/3Pp1pn/2P1P2P/2N2NB1/PP2BPP1/R2QK2R b KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 d4d5 b8d7 c1g5 h7h6 g5h4 g6g5 h4g3 f6h5 h2h4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. d5 Nbd7 8. Bg5 h6 9. Bh4 g5 10. Bg3 Nh5 11. h4"),
  new FullOpening("E93", "King's Indian Defense: Petrosian Variation, Normal Defense", "r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQK2R w KQ -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 d4d5 b8d7", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. d5 Nbd7"),
  new FullOpening("E94", "King's Indian Defense: Orthodox Variation", "rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O"),
  new FullOpening("E94", "King's Indian Defense: Orthodox Variation, Donner Defense", "rnbq1rk1/pp3pbp/2pp1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 c7c6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O c6"),
  new FullOpening("E94", "King's Indian Defense: Orthodox Variation, Glek Defense", "r1bq1rk1/ppp2pbp/n2p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8a6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Na6"),
  new FullOpening("E94", "King's Indian Defense: Orthodox Variation, Positional Defense", "r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 d7d6 b1c3 b8d7 e2e4 e7e5 g1f3 g7g6 f1e2 f8g7 e1g1 e8g8", "1. d4 Nf6 2. c4 d6 3. Nc3 Nbd7 4. e4 e5 5. Nf3 g6 6. Be2 Bg7 7. O-O O-O"),
  new FullOpening("E94", "King's Indian Defense: Orthodox Variation, Positional Defense, Closed Line", "r1bq1rk1/pppn1pbp/3p1np1/3Pp3/2P1P3/2N2N2/PP2BPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 b8d7 e1g1 e7e5 d4d5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 Nbd7 7. O-O e5 8. d5"),
  new FullOpening("E94", "King's Indian Defense: Orthodox Variation, Ukrainian Defense", "rnbq1rk1/1pp2pbp/3p1np1/p3p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 a7a5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O a5"),
  new FullOpening("E95", "King's Indian Defense: Orthodox Variation", "r1bq1rk1/pppn1pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQR1K1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8d7 f1e1", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nbd7 8. Re1"),
  new FullOpening("E96", "King's Indian Defense: Orthodox Variation, Positional Defense, Main Line", "r1bq1rk1/1p1n1pbp/2pp1np1/p3p3/2PPP3/2N2N2/PP3PPP/R1BQRBK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8d7 f1e1 c7c6 e2f1 a7a5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nbd7 8. Re1 c6 9. Bf1 a5"),
  new FullOpening("E97", "King's Indian Defense: Orthodox Variation, Aronin-Taimanov Defense", "r1bq1rk1/ppp2pbp/2np1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQ1RK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6"),
  new FullOpening("E97", "King's Indian Defense: Orthodox Variation, Bayonet Attack", "r1bq1rk1/ppp1npbp/3p1np1/3Pp3/1PP1P3/2N2N2/P3BPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 b2b4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. b4"),
  new FullOpening("E97", "King's Indian Defense: Orthodox Variation, Bayonet Attack, Sokolov's Line", "r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P3BPPP/R1BQR1K1 b - -", "g1f3 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 e8g8 d2d4 d7d6 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 b2b4 f6h5 f1e1", "1. Nf3 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 O-O 5. d4 d6 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. b4 Nh5 10. Re1"),
  new FullOpening("E97", "King's Indian Defense: Orthodox Variation, Bayonet Attack, Yepishin's Line", "r1bq1rk1/ppp1npbp/3p2p1/3Pp2n/1PP1P3/2N2N2/P1Q1BPPP/R1B2RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 b2b4 f6h5 d1c2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. b4 Nh5 10. Qc2"),
  new FullOpening("E97", "King's Indian Defense: Orthodox Variation, Korchnoi Attack", "r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N2N2/PP1BBPPP/R2Q1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 c1d2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. Bd2"),
  new FullOpening("E97", "King's Indian Defense: Orthodox Variation, Modern System", "r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP1NBPPP/R1BQ1RK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 f3d2", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. Nd2"),
  new FullOpening("E98", "King's Indian Defense: Orthodox Variation, Classical System", "r1bq1rk1/ppp1npbp/3p1np1/3Pp3/2P1P3/2N5/PP2BPPP/R1BQNRK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 f3e1", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. Ne1"),
  new FullOpening("E98", "King's Indian Defense: Orthodox Variation, Classical System, Kozul Gambit", "r1bq1rk1/pppn2bp/3p2n1/2PPp1p1/4Pp2/2N2P2/PP2BBPP/2RQNRK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 f3e1 f6d7 c1e3 f7f5 f2f3 f5f4 e3f2 g6g5 a1c1 e7g6 c4c5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. Ne1 Nd7 10. Be3 f5 11. f3 f4 12. Bf2 g5 13. Rc1 Ng6 14. c5"),
  new FullOpening("E98", "King's Indian Defense: Orthodox Variation, Classical System, Neo-Classical Line", "r1bq1rk1/pppnnpbp/3p2p1/3Pp3/2P1P3/2N1B3/PP2BPPP/R2QNRK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 f3e1 f6d7 c1e3", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. Ne1 Nd7 10. Be3"),
  new FullOpening("E99", "King's Indian Defense: Orthodox Variation, Classical System, Benko Attack", "r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P1P1/2N2P2/PP2B2P/R1BQNRK1 b - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 f3e1 f6d7 f2f3 f7f5 g2g4", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. Ne1 Nd7 10. f3 f5 11. g4"),
  new FullOpening("E99", "King's Indian Defense: Orthodox Variation, Classical System, Traditional Line", "r1bq1rk1/pppnn1bp/3p2p1/3Ppp2/2P1P3/2N2P2/PP2B1PP/R1BQNRK1 w - -", "d2d4 g8f6 c2c4 g7g6 b1c3 f8g7 e2e4 d7d6 g1f3 e8g8 f1e2 e7e5 e1g1 b8c6 d4d5 c6e7 f3e1 f6d7 f2f3 f7f5", "1. d4 Nf6 2. c4 g6 3. Nc3 Bg7 4. e4 d6 5. Nf3 O-O 6. Be2 e5 7. O-O Nc6 8. d5 Ne7 9. Ne1 Nd7 10. f3 f5"),
].sort(function (a, b) {
  // We want to longest uci to be first to prioritize them
  return b.uci.length - a.uci.length;
});